import React ,{ useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux'
import '../viewer/ViewClient.css';
import { useNavigate } from 'react-router-dom'
import Carousel from "react-multi-carousel";

import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { API_URL } from '../config.js'
import { assetUrl } from '../utils/Common'
import { setViewData, setViewFile, setBoothFileList, setBoothList, setCanvasRefresh, setLoading, setShowGrid } from '../actions/main'
import { setBoothHistory } from '../actions/booth_history'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

  const ColorButton = styled(Button)(({ theme }) => ({
    color: '#7c7c7c',
    minWidth:'55px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#c9c9c980',
    },
  }));

  const Button3D =  styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#22222250',
    },
    border:'2px solid white',
    float:'right'
  }));
function ToolBox(props) {
    const [sponsorList, setSponsorList] = useState([]);
    const [logos, setLogos] = useState([]);
    const [openMenu, setOpenMenu] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    
    const navigate = useNavigate()

    const openRef = useRef();

    useEffect(() => {
      axios.get('/getsponsorlist').then(res=>{
        console.log("getsponsorlist", res.data)
        setSponsorList(res.data)
      }).catch(error => {
          console.log("error", error);
      })
    }, [])

    useEffect(() => {  
      if(props.main.mainCanvas && props.main.boothList)  {
        var groups = props.main.mainCanvas.getObjects('group');
        var logos = [];
        for(var i = 0; i< groups.length; i++){  
          var group = groups[i]
          if(group && (group.get('class') == 'booth' || group.get('class') == 'booth-group')){                            
            var id = group.id;
            var priced = false
            var priceCheck = group.get('priceCheck');
            var premiumCheck = group.get('premiumCheck');
            // if(premiumCheck){
            //     priced = true
            // }
            // if(priceCheck && priceCheck.length > 0){
            //     priced = true
            // }
            if(group.get('sold')){
                priced = true
            }
            if(priced){
              var company_log_url = group.get('company_log_url');
              if(company_log_url){
                logos.push(company_log_url)
              }
            }
          }
        }
        setLogos(logos);
      }
    }, [props.main.boothList])

    const doSearch = ()=>{

    }

    var top_nav_show = true
    if(props.main.authInfo && props.main.authInfo.tokenInfo){
      if(props.main.authInfo.tokenInfo.HIDE_TOP_NAVIGATION){
        top_nav_show = false
      }
    }
    return (
      <>
      {top_nav_show &&
        <Box className="public" sx={{ flexGrow: 1, zIndex:2}}>
            <AppBar position="fixed">
                <Toolbar>
                    {/* <Box style={{width:'50%'}}>    
                      {logos && logos.length>0 &&
                        <Carousel
                          arrows={false}
                          infinite={true} 
                          showDots={false}
                          responsive={responsive}
                          autoPlay={true}
                          autoPlaySpeed={3000}
                          dotListClass="custom-dot-list-style"
                        >
                          {
                            logos.map((photo, index)=>{
                              return(
                                <div className='header-carousel-item'>
                                  <img src={photo}/>
                                </div>
                              )
                            })
                          }
                        </Carousel>
                      }
                    </Box>   */}
                    <Box style={{width:'100%'}}>
                      {sponsorList && sponsorList.length>0 &&
                        <Carousel
                          arrows={false}
                          infinite={true} 
                          showDots={false}
                          autoPlay={true}
                          autoPlaySpeed={3000}
                          responsive={responsive}
                          // dotListClass="custom-dot-list-style"
                        >
                          {
                            sponsorList.map((sponsor, index)=>{
                              return(
                                <a target="_blink" href={sponsor.url} key={index} className='header-carousel-item'>
                                  <img src={assetUrl(sponsor.logo)}/>
                                </a>
                              )
                            })
                          }
                        </Carousel>
                      }
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
      }
      </>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
      main: state.main,
      booth_history: state.booth_history.present,
      canUndo: state.booth_history.past.length > 1,
      canRedo: state.booth_history.future.length > 0
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {    
        setViewData: data=>dispatch(setViewData(data)),
        setViewFile: filename=>dispatch(setViewFile(filename)),
        setBoothList: data=>dispatch(setBoothList(data)),
        setBoothFileList: data=>dispatch(setBoothFileList(data)),
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        onUndo: () => dispatch(UndoActionCreators.undo()),
        onRedo: () => dispatch(UndoActionCreators.redo()),
        onClearHistory: () => dispatch(UndoActionCreators.clearHistory()),        
        setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),      
        setLoading:  data=>dispatch(setLoading(data)),
        setShowGrid:  data=>dispatch(setShowGrid(data))
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(ToolBox);

  



