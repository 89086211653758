import React ,{ useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { connect } from 'react-redux'
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import { fabric } from "fabric";
// import Cropper from 'react-easy-crop'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { API_URL } from '../../config.js'
import { setLoading } from '../../actions/main'
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { getTokenCookie } from '../../utils/Common'


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function Index(props) {
  const { onClose,  onChange, open } = props;
  const [searchVal, setSearchVal] = React.useState(false);
  const [searchedList, serSearchedList] = React.useState([]);
  let query = useQuery();
  
  useEffect(() => {
    if(open){
      setSearchVal(false)
      serSearchedList([])
    }
  }, [open])
  

  const onOk =async (e)=>{
    // props.onOk(boothCoCompanies)
  }


  const checkContainCocompany = (id)=>{
    var boothCoCompanies = (props.co_companies)
    console.log("boothCoCompanies", boothCoCompanies)
    for(var i = 0; i < boothCoCompanies.length; i++){
      var item = boothCoCompanies[i]
      if(item['id'] == id){
        return true
      }
    }
    return false
  }

  const doSearch = (searchVal)=>{
    if(searchVal){
      var url = `/getcrmcompanies?token=${getTokenCookie()}&company_name=${searchVal}`
      axios.get(url).then(res=>{
        if(res.data){
          console.log(res.data)
          var result = res.data
          if(result && result.DATA)
            serSearchedList(result.DATA)
          else{
            serSearchedList([])
          }
        }
        
      }).catch(error => {
          console.log("error", error);
      })

    }
    else
      serSearchedList([])
  }
  
  var boothCoCompanies = (props.co_companies)
  return (
    <Collapse in={open}>
      <Paper elevation={3} style={{padding:'20px', marginTop:20}}>
        <Box>
          <Box style={{border:'1px solid #e0e0e0', borderRadius:'4px'}}>
            <InputBase
                sx={{ ml: 1, flex: 1, width:'calc(100% - 50px)' }}
                placeholder="Search company"
                inputProps={{ 'aria-label': 'search booth' }}
                // value={searchVal}
                onBlur={
                    (e) => {
                        setSearchVal(e.target.value)
                    }
                }
                onKeyDown={
                    (e)=>{
                        if(e.keyCode == 13){
                            e.preventDefault();
                            doSearch(e.target.value)
                        }
                    }
                }
                onChange={
                  (e)=>{
                    // if(e.target.value == "" || !e.target.value)
                    {
                      doSearch(e.target.value)
                    }
                  }
                }
            />
            <IconButton size="small" type="button" onClick = {()=>doSearch(searchVal)} aria-label="search">
                <SearchIcon />
            </IconButton>
          </Box>
          <Box mt={3} style={{maxHeight: 300, overflowY:'auto'}}>
            <Table className="color-table"  aria-label="simple table">                
                <TableBody>
                {searchedList.map((row, index) => (
                    <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell sx={{paddingTop: '4px', paddingBottom:'4px'}}>{row[1]}</TableCell>
                    <TableCell width="80px" sx={{textAlign:'center', paddingTop: '4px', paddingBottom:'4px'}}>
                      {row[0]&&checkContainCocompany(row[0])?
                        <Button 
                          // onClick={
                          //   ()=>{
                          //     var index = 0;
                          //     for(var i = 0; i < boothCoCompanies.length; i++){
                          //       if(boothCoCompanies[i].id == row[0])
                          //       index = i;
                          //       break;
                          //     }
                             
                          //     let newArray = boothCoCompanies.slice()
                          //     newArray.splice(index, 1)
                          //     props.setBoothCoCompanies(newArray)
                          //   }
                          // }
                        >
                            <CheckIcon sx={{ color: 'green' }} />
                        </Button>:
                        <Button onClick={()=>{props.setBoothCoCompanies([...boothCoCompanies, {id:row[0], name:row[1]}])}}><AddIcon sx={{  }} /></Button>}
                      </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
          </Box>
        </Box>
        <Box mt={1} sx={{textAlign:'right'}}>
          <Button  onClick={onClose}><ExpandLessIcon/></Button>
        </Box>
      </Paper>
    </Collapse>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setLoading:  data=>dispatch(setLoading(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);