import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setPlanner } from '../../actions/main'
import { setOpenLogin,setOpenSignup,setSnackMsg, setOpenSnack, setOpenNewPassword } from '../../actions/ui'
import { NavLink } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import { uuid4 } from './sketch_right/utils';
import { parseTokenValue, getTokenPlanner } from '../../utils/Common'
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import {PaperComponent} from '../../utils/Common'

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function Index(props) {
  const { onClose, onOk, onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [formValue, setFormValue] = React.useState({});
  let query = useQuery();

  useEffect(() => {
  }, [])
  
  const onChangeValue = (e)=>{
    setFormValue({...formValue, [e.target.name]: e.target.value});
  }

  const handleSignup = (e)=>{
    e.preventDefault();
    if(!formValue.PASSWORD || formValue.PASSWORD != formValue.confirm_password){
      props.setOpenSnack(true);
      props.setSnackMsg("Invalid password");
      return false;
    }
    const _token = getTokenPlanner();
    var postData = 
    {
      PASSWORD: formValue.PASSWORD?formValue.PASSWORD:'',
      OTP:"yes"
    }
    var formdata = {
      query:`${ESHOW_URL()}?method=putPlanner&token=${_token}`,
      postData: postData,
      returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner&token=${_token}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
      const eshowResponse = res.data;
      if(eshowResponse.SUCCESS == true){
          
        props.setOpenSnack(true);
        props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Successfully created new planner");
        props.setOpenNewPassword(false)
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to sign up");
      }
    }).catch(error => {
        console.log("error", error);
    })
  }

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"} onClose={()=>{props.setOpenNewPassword(false)}} open={props.ui.openNewPassword}>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        

        <Box style={{}}>
          <IconButton style={{float:'right', padding:5}} color="inherit" onClick={()=>{props.setOpenSignup(false)}} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" gutterBottom style={{textAlign: 'center', fontSize: '25px',color: '#1565c0'}}>
            Set New Password
          </Typography>
        </Box>
        <form onSubmit={handleSignup}>
          <TextField
            name="PASSWORD"
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={onChangeValue}
          />
          <TextField
            name="confirm_password"
            margin="dense"
            label="Confirm Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={onChangeValue}
          />
          <Box mt={2} textAlign='center'>
            <Button variant="contained" color="primary" size="large" type="submit">
              Set
            </Button>
          </Box>
        </form>
        
      </DialogContent>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) ,
        setOpenLogin: data=>dispatch(setOpenLogin(data)) ,
        setOpenSignup: data=>dispatch(setOpenSignup(data)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),
        setPlanner: data=>dispatch(setPlanner(data)) ,
        setOpenNewPassword: data=>dispatch(setOpenNewPassword(data)) ,
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);