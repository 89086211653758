import * as React from 'react';
import { useEffect,useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';
import { setOpenCompanyProfile, setLeftBoothList, setBookmardUpdated, setOpenLogin, setLeftSelectedCategory, setLeftPage, setOpenCompanyModal, setEditCompany, setSnackMsg, setOpenSnack } from '../../actions/ui.js'
import { setBookmarkList } from '../../actions/main.js'
import Chip from '@mui/material/Chip';
import SliderCarousel from './SliderCarousel.js'
import Carousel from "react-multi-carousel";
import ReactPlayer from 'react-player'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import VideocamIcon from '@mui/icons-material/Videocam';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, redrawobjectborder, adjustobjectborder, checkifrotate, checkifrotatepoints, checkiftable, rotatePathObject, rotateObject, rotatePoints, checkifpathrotate, checkifbooth, compressJson, toggleDrawMode, unlockMap, lockMap, addText, centerFocus, redrawRulers, changeBorderColor, getGeometry, setObjectBG, getBGColor, checkIfSelectable, composeLoadObjects, initCanvas, afterCanvasLoad, px2unit, unit2Px, renderCoordinate, removeCoordinate, moveCoordinate, setObjectBorder} from '../../utils/CanvasTool.js'
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import  area  from 'area-polygon'
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { parseToken, assetUrl } from '../../utils/Common.js'
import { getTokenCookie, getTokenPlanner, getUrlToken } from '../../utils/Common.js'
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import Fade from '@mui/material/Fade';
import ShowMore from 'react-show-more';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FeaturedLabel from './FeaturedLabel.js';

import CloseIcon from '@mui/icons-material/Close';
const moment = require('moment-timezone');

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2.5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.5
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));


var booth;
var open;
var boothName = ''
var companyName;
var company_main_category;
var company_sub_category_displays;
var company_sub_category_podiums;
var company_sub_category_laptops;
var company_log_url;
var company_header;
var company_description;
var company_photos;
var company_videos;
var company_contact_street;
var company_contact_address2;
var company_contact_city;
var company_contact_state;
var company_contact_zip;
var company_contact_country;
var company_contact_fn;
var company_contact_ln;
var company_contact_phone_ext;
var company_contact_phone;
var company_facebook;
var company_twitter;
var company_snapchat;
var company_instagram
var company_linkdin;
var company_tiktok;
var company_call_link;
var company_call_label;
var company_website;
var toll_free;
var cell_phone;
var brand_name;
var company_name;
var fax;
var division;
var c_title;
var credentials;
var company_headerimg_url;
var company_bgimg_url;
var company_contact_email;
var company_tag_featured;
var company_tag_member;
var company_tag_3dbooth;
var bookmark
var company_tag_sponser;
var company_tag_level;
var company_category;
var companyTextFlag = false;
var backgroundColor = 'transparent'
var bgColor = "#dadada"

function Index(props) {
  const [tpage, setTpage]  = useState('');
  const [loaded, setLoaded] = useState(false)
  const [zIndex, setzIndex] = React.useState(1004);
  const [bookmark, setBookmark] = React.useState(false);
  const [exhibitor, setExhibitor] = React.useState(false);
  const [openProductDetails, setOpenProductDetails] = React.useState(false);
  const [openSpecialDetails, setOpenSpecialDetails] = React.useState(false);
  const [openReleaseDetails, setOpenReleaseDetails] = React.useState(false);
  const [openEventsDetails, setOpenEventsDetails] = React.useState(false);  
  const [openCallAction, setOpenCallAction] = React.useState(false);
  const [callFormValues, setCallFormValues] = React.useState(false);
  const [co_companies, setCo_companies] = React.useState(false);
  const scrollRef = useRef(null);
  
  

  var mainCanvas = props.main.mainCanvas
  var g_groupOptions = props.main.groupOptions

  const theme = useTheme();
  let query = useQuery();
  const getExhibitor = async (KEY_ID)=>{
    var result = null
    var token = false
    try{
      token =  getTokenPlanner();
      if(props.role=='admin'){
        token = getTokenCookie()
      }
      else{
        if(!token){
          token = getTokenCookie()
        }
      }
    }
    catch(e){

    }
    let booth = props.ui.openCompanyProfile.booth;
    var url = `${ESHOW_URL()}?method=getExhibitor&EXHIBITOR_KEY=${KEY_ID}&token=${token}&booth_key=${booth?.KEY_ID}`
    const response = await axiosInstance.get(url);
    if(response.status == 200){
      if(response.data?.SUCCESS == true){
        result = response.data?.EXHIBITOR;
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(response.data?.MESSAGE?response.data?.MESSAGE:"Something went wrong");
      }
        
    }
    else{
      props.setOpenSnack(true);
      props.setSnackMsg("Something went wrong");
    }
    return result

 }

  booth = props.ui.openCompanyProfile.booth;
  open = props.ui.openCompanyProfile.open;

  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var show_key = urlParams.get('show_key') 
  var urlToken = getUrlToken()    
  
  useEffect(() => {
    initForm()
  }, [])

  const resetForm = ()=>{
    company_videos = null
    company_contact_street = null;
    company_contact_address2 =null;
    company_contact_city = null;
    company_contact_state = null;
    company_contact_zip = null;
    company_contact_country = null;
    company_contact_fn = null;
    company_contact_ln = null;
    company_contact_phone = null;
    company_contact_phone_ext = null;
    company_facebook= null;
    company_twitter = null;
    company_snapchat = null;
    company_instagram = null;          
    company_linkdin = null;
    company_tiktok = null;
    company_call_label = null;
    company_call_link = null;
    company_website = null;
    toll_free = null;
    cell_phone = null
    brand_name = null;
    company_name = null;
    fax = null;
    division = null;
    c_title = null;
    credentials = null;
    company_headerimg_url = null;
    company_bgimg_url = null;
    company_tag_featured = null;
    company_tag_member = null;
    company_tag_3dbooth = null;
    company_tag_sponser = null;
    company_tag_level = null;
    setLoaded(false)
  }
  const initForm = async()=>{
    if(1){
      if(1){
        var exhibitorInfo = props.exhibitorInfo
        setExhibitor(exhibitorInfo)

        setCo_companies(exhibitorInfo?.SUBEXHIBITORS)
        companyName = exhibitorInfo?.['DIRECTORY']['COMPANY_NAME']
        var CATEGORY = exhibitorInfo?.['DIRECTORY']['CATEGORY'];
        company_category = CATEGORY
        var tmp = []
        if(CATEGORY && CATEGORY.length){
          for(var i = 0; i < CATEGORY.length; i++){
            tmp.push(CATEGORY[i]['ANSWER_KEY']);
          }
        }
        // company_category = tmp
        company_log_url = assetUrl(exhibitorInfo?.['DIRECTORY']['LOGO']);
        company_description = exhibitorInfo?.['DIRECTORY']['DESCRIPTION'];
        company_header = exhibitorInfo?.['HEADER']
        var photos = exhibitorInfo?.['DIRECTORY']['PHOTOS'];
        var photoUrls = []
        if(photos && photos.length > 0){
          for(var i = 0; i < photos.length; i++){
            if(photos[i].URL)
              photoUrls.push(assetUrl(photos[i].URL))
          }
        }
        company_photos = photoUrls;
        var videos = exhibitorInfo?.['DIRECTORY']['VIDEOS'];
        var videoUrls = []
        if(videos && videos.length > 0){
          for(var i = 0; i < videos.length; i++){
            // if(videos[i].URL)
            videoUrls.push(videos[i])
          }
        }
        company_videos = videoUrls
        company_contact_street = exhibitorInfo?.['DIRECTORY']['ADDRESS1'];
        company_contact_address2 = exhibitorInfo?.['DIRECTORY']['ADDRESS2'];
        company_contact_city = exhibitorInfo?.['DIRECTORY']['CITY'];
        company_contact_state = exhibitorInfo?.['DIRECTORY']['STATE'];
        company_contact_zip = exhibitorInfo?.['DIRECTORY']['ZIP_CODE'];
        company_contact_country = exhibitorInfo?.['DIRECTORY']['COUNTRY'];
        company_contact_fn = exhibitorInfo?.['DIRECTORY']['FIRST_NAME'];
        company_contact_ln = exhibitorInfo?.['DIRECTORY']['LAST_NAME'];
        company_contact_phone = exhibitorInfo?.['DIRECTORY']['PHONE'];
        company_contact_phone_ext = exhibitorInfo?.['DIRECTORY']['PHONE_EXT'];
        company_facebook= exhibitorInfo?.['DIRECTORY']['FACEBOOK'];
        company_twitter = exhibitorInfo?.['DIRECTORY']['TWITTER'];
        company_snapchat = exhibitorInfo?.['DIRECTORY']['SNAPCHAT'];
        company_instagram = exhibitorInfo?.['DIRECTORY']['INSTAGRAM'];          
        company_linkdin = exhibitorInfo?.['DIRECTORY']['LINKEDIN'];
        company_tiktok = exhibitorInfo?.['DIRECTORY']['TIKTOK'];
        company_call_label = exhibitorInfo?.['DIRECTORY']['ACTION_BUTTON_LABEL'];
        company_call_link = exhibitorInfo?.['DIRECTORY']['ACTION_BUTTON_LINK'];
        company_website = exhibitorInfo?.['DIRECTORY']['WEBSITE'];
        toll_free = exhibitorInfo?.['DIRECTORY']['TOLL_FREE'];
        cell_phone = exhibitorInfo?.['DIRECTORY']['CELL_PHONE'];
        brand_name = exhibitorInfo?.['DIRECTORY']['BRAND_NAME'];
        company_name = exhibitorInfo?.['DIRECTORY']['COMPANY_NAME'];
        fax = exhibitorInfo?.['DIRECTORY']['FAX'];
        division = exhibitorInfo?.['DIRECTORY']['DIVISION'];
        c_title = exhibitorInfo?.['DIRECTORY']['TITLE'];
        credentials = exhibitorInfo?.['DIRECTORY']['CREDENTIALS'];
        company_headerimg_url = exhibitorInfo?.['DIRECTORY']['HEADERIMAGE'];
        company_bgimg_url = exhibitorInfo?.['DIRECTORY']['BACKGROUND'];
        company_tag_featured = exhibitorInfo?.['DIRECTORY']['FEATURED'];
        company_tag_member = exhibitorInfo?.['DIRECTORY']['MEMBER'];
        company_tag_3dbooth = (exhibitorInfo?.['DIRECTORY']['3DMODEL']&&exhibitorInfo?.['DIRECTORY']['3DMODEL']!="")?true:false;
        company_tag_sponser = exhibitorInfo?.['DIRECTORY']['SPONSOR'];
        company_tag_level = exhibitorInfo?.['DIRECTORY']['BOOTHLEVEL'];
        setBookmark(exhibitorInfo?.['BOOKMARKED'])

      }
      
    setTimeout(()=>{
      if(!props.ui.openCompanyProfile.parent && props.ui.openCompanyProfile.scrollTop && scrollRef){
        scrollRef.current.getElementsByClassName("MuiPaper-root")[0].scrollTop = props.ui.openCompanyProfile.scrollTop
      }
    }, 1000)

    }
}
  
  const handleOpenCompanyModal=()=>{
    var booth = props.ui.openCompanyProfile.booth;
    var row = {}
    row['booth'] = booth

    props.setEditCompany(row)
    props.setOpenCompanyModal(true)
  }

  

  const fetchBookmarkList = ()=>{
    {
      var token = false
      try{
        token =  getTokenPlanner();
        if(props.role=='admin'){
          token = getTokenCookie()
        }
      }
      catch(e){

      }
      if(token){
        var url = `${ESHOW_URL()}?method=getPlanner&token=${token}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getPlanner&token=${token}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            if(result.SUCCESS){
              props.setBookmarkList(result.BOOKMARKS)
            }
            else{

            }
        }).catch(error => {
        })  
      }
    }
  }
  
  const confirmComapnyModal=()=>{

  }
  const callBookmarkApi= async (exhibitor, value)=>{  
    const _token = getTokenPlanner();
    var BOOKMARKS = props.main.bookmarkList?props.main.bookmarkList:[]
    if(value){
      var booths = ''
      if(exhibitor.BOOTHS){
        booths = exhibitor.BOOTHS
      }
      else if(exhibitor.BOOTH_NO){
        booths = exhibitor.BOOTH_NO
      }
      BOOKMARKS = [
        ...BOOKMARKS,
        {
          COMPANY_NAME: exhibitor?.COMPANY_NAME?exhibitor?.COMPANY_NAME:"",
          NOTES:"",
          STATUS:'',
          BOOTHS:booths,
          EXHIBITOR_KEY:exhibitor?.EXHIBITOR_KEY?exhibitor?.EXHIBITOR_KEY:"" 
        }
      ]
    }
    else{
      BOOKMARKS = BOOKMARKS.filter((item, index) => `${item.EXHIBITOR_KEY}` !== `${exhibitor.EXHIBITOR_KEY}`)
    }
    
    var postData =
    {
      SUCCESS:true,
      BOOKMARKS:BOOKMARKS
    };

    var formdata = {
      query:`${ESHOW_URL()}?method=putPlanner&token=${_token}`,
      postData: postData,
      returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }  
    var url = `${ESHOW_URL()}?method=putPlanner&token=${_token}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
      const eshowResponse = res.data;
      if(eshowResponse.SUCCESS == true){
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to save bookmark");
      }
      props.setBookmarkList(BOOKMARKS)
    }).catch(error => {
        console.log("error", error);
    })
  }

  const doBookmark = (booth)=>{
    var exhibitor = false
    var exhibitors = booth?.exhibitors
    if(exhibitors && exhibitors.length > 0){
      exhibitor = {...exhibitors[0]}
      exhibitor.BOOTHS = booth.booth_number
    }

    if(exhibitor){
      const _token = getTokenPlanner();
      if((props.role !='admin') && (!_token || !_token)){
        props.setOpenLogin(true)
        return false;
      }
      setBookmark(!bookmark)
      callBookmarkApi(exhibitor, !bookmark)
      // props.setBookmardUpdated(true)
    }
  }

  function searchTree(element, KEY_ID){
    if(element.KEY_ID == KEY_ID){
         return element;
    }else if (element.SUBCAT != null){
         var i;
         var result = null;
         for(i=0; result == null && i < element.SUBCAT.length; i++){
              result = searchTree(element.SUBCAT[i], KEY_ID);
         }
         return result;
    }
    return null;
  }

  const onClickCategory = (KEY_ID)=>{
    var categoryData = {
        KEY_ID: "0",
        TITLE: "Parent",
        SUBCAT: []
      }
    if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
        // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
        categoryData.SUBCAT = (props.main.categoryOptions)
    }
    else{
    
    }
    var name  = ""
    var element = searchTree(categoryData, KEY_ID)
    props.setOpenCompanyProfile(false)
    props.setLeftPage('category'); props.setLeftSelectedCategory(element)
  }


  const getCategoryName = (KEY_ID)=>{
    var categoryData = {
        KEY_ID: "0",
        TITLE: "Parent",
        SUBCAT: []
      }
    if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
        // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
        categoryData.SUBCAT = (props.main.categoryOptions)
    }
    else{
    
    }
    var name  = ""
    var element = searchTree(categoryData, KEY_ID)
    if(element){
        name = element.TITLE
    }
    return name;
  }


  const renderCoCompany = (co_company, index)=>{
    var element = []
    element.push(
      <Box key={index} display={'flex'} mb={2} style={{boxSizing:'border-box'}}>
        <Box style={{width:'80px', boxSizing:'border-box'}}>
          {co_company?.['LOGO'] &&
            <img style={{width:'80px', height:'80px', objectFit:'contain', borderRadius:'5px', overflow:'hidden'}} width="80px" height="80px" src={assetUrl(co_company?.['LOGO'])}/>
          }
        </Box>
        <Box display={'flex'} alignItems={'center'} style={{width:'calc(100% - 80px - 70px)', padding: '3px 12px', boxSizing:'border-box'}}>
          <div>
            <h4 style={{marginTop:0, marginBottom:6}}>
              <a className='sub-exhibtor-title' href="javascript:void(0)"
                onClick={()=>{
                  // console.log("scrollRef.current", scrollRef.current.getElementsByClassName("MuiPaper-root")[0].scrollTop)                  
                  resetForm()
                  props.setOpenCompanyProfile({booth:{exhibitors:[co_company]}, open:true, parent:props.ui.openCompanyProfile?.booth, scrollTop:(scrollRef?.current?(scrollRef.current.getElementsByClassName("MuiPaper-root")[0].scrollTop):0)})
                }}  
              >
                {co_company?.['COMPANY_NAME']}
              </a>
            </h4>
            <p style={{marginTop:0, marginBottom:6}}>Booth #: {co_company?.['BOOTH_NO']}</p>
            <p style={{marginTop:0, marginBottom:6}}>{co_company?.['TEXT_LINE']}</p>
            {props.role == "admin" &&
            <a className='sub-exhibtor-edit-btn' href="javascript:void(0)"
              onClick={()=>{
                props.setEditCompany({booth:{exhibitors:[co_company]}, sub:true})
                props.setOpenCompanyModal(true)
              }}  
            >
              Edit
            </a>
            }
          </div>
        </Box>
        <Box display={'flex'} alignItems={'center'} style={{width:'70px', padding: '3px 12px', boxSizing:'border-box'}} textAlign={'center'}>
          {props.role !== "admin" &&
            <IconButton edge="end" 
              onClick={()=>{
                var tmp_co_companies = [...co_companies]
                var newVal = !co_company.BOOKMARKED
                tmp_co_companies[index] = {...co_company, BOOKMARKED:newVal}
                setCo_companies(tmp_co_companies)
                const _token = getTokenPlanner();
                if((props.role !='admin') && (!_token || !_token)){
                  props.setOpenLogin(true)
                  return false;
                }
                callBookmarkApi(co_company, newVal)
              }}
            >
              {
                co_company.BOOKMARKED?
                <BookmarkIcon style={{color:'#e54839'}}/>:
                <BookmarkBorderOutlinedIcon/>
              }
            </IconButton>
          }
        </Box>
      </Box>
    )
    return element;
  }

  const findZoneForAbbreviation = (abbr)=>{
    const zoneNames = moment.tz.names();
    for (let zone of zoneNames) {
        const abbreviation = moment.tz(zone).format('z');
        if (abbreviation === abbr) {
            return zone;  // Return the first matching zone
        }
    }

    return null;  // Return null if no zone matches
  }

  const formatDate = (dateStr1, dateStr2, timezone)=>{
    let zoneName = 'America/Chicago';
    if(timezone){
      let tmp = findZoneForAbbreviation(timezone)
      if(tmp){
        zoneName = tmp; 
      }
    }

    const date1 = moment(dateStr1);
    date1.tz(zoneName);
    
    const date2 = moment(dateStr2);
    date2.tz(zoneName);

    return `${date1.format('MM/DD/YYYY')} ${date1.format('hh:mm A')} - ${date2.format('hh:mm A')} ${date1.format('(z)')}`;
  }

  

  const sendCallAction = ()=>{
    var email = openCallAction.EMAIL_NOTIFICATION
    // email = 'azuresnow55@gmail.com'
    if(email){
      var body = '';
      for(var i = 0; i <openCallAction?.QUESTIONS?.length; i++){
        var question = openCallAction?.QUESTIONS[i]
        if(question.QUESTION_TYPE == 'textline' || question.QUESTION_TYPE == 'radio' || question.QUESTION_TYPE == 'pulldown'){
          // formdata[question.QUESTION_NAME] = question.value
          body+=`${question.QUESTION_NAME}: ${question.value}<br>`
        }
        if(question.QUESTION_TYPE == 'checkbox'){
          var value = question.value
          var tmp = ""
          if(value){
            for(var x in value){
              if(value[x]){
                if(tmp !=""){
                  tmp+=", "
                }
                tmp+=x
              }
            }
          }
          // formdata[question.QUESTION_NAME] = tmp
          
          body+=`${question.QUESTION_NAME}: ${tmp}<br>`
        }
      }

      var _token = getTokenPlanner();
      if(!_token){
        _token = getTokenCookie()
      }
      console.log("token", _token)
      // return;
      var postData =
      {
        MAIL_TO:email,
        SUBJECT:'Request for information',
        BODY:body
      };

      
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }  
      var url = `${ESHOW_URL()}?method=putEmail&token=${_token}`
      axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
          props.setOpenSnack(true);
          props.setSnackMsg("Submitted successfully");
        }
        else{
          props.setOpenSnack(true);
          props.setSnackMsg("Something went wrong");
        }
      }).catch(error => {
          console.log("error", error);
      })      
    }
  }
  
  const renderCompanyHeader = (company_header)=>{
    if(!company_header) return (<></>)
    return(
      <Box mb={3}>
        <Carousel
          infinite={company_header.length>1} 
          showDots={company_header.length>1}
          arrows={company_header.length>1}
          responsive={responsive}
          dotListClass="custom-dot-list-style"
          className={company_header.length>1?'video-photo-hover':''}
        >
          {
            company_header.map((item, index)=>{
              if(item.MEDIA_TYPE == "Image"){
                return(
                  <div key={index} className='carousel-item'>
                    <img src={item.URL}/>
                  </div>
                )
              }
              else if(item.MEDIA_TYPE == "Video"){
                return(
                  <div key={index} className='carousel-item' style={{height:'auto'}}>
                    <ReactPlayer controls muted={true} playing={true} className="video" height="293px"  url={item.URL}/>
                  </div>
                )
              }
            })
          }
        </Carousel>
      </Box>
    )
  }

  const renderCoCompanies = (co_companies)=>{
        
    return(
        <div className=''>
          {
            co_companies?.map((co_company, index)=>{
              return(
                <div key={index}>
                  {renderCoCompany(co_company, index)}
                </div>
              )
            })
          }
        </div>
    )
  }
  
  var sales_bookmarks_show = true
  if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
    sales_bookmarks_show = false
  }
  var download_show = true
  var contact_show = true
  if(props.main.showSetup?.PUBLIC?.DOWNLOAD_LINKS == 'hide'){
    download_show = false
  }
  if(props.main.showSetup?.PUBLIC?.CONTACT_INFO == 'hide'){
    contact_show = false
  }

  return (
    <>
      {props.loaded &&
      <>
        <Box style={{clear:'both', maxWidth:'470px'}} mt={0} >
                    
          {((company_photos && company_photos.length>0) || (company_videos && company_videos.length>0)) &&
          <h3 class="directory-header" style={{marginBottom:'10px', marginTop:0}}>Videos & Photos</h3>
          }
          {company_photos && company_photos.length>0 &&
            <Carousel
              infinite={company_photos.length>1} 
              showDots={company_photos.length>1}
              arrows={company_photos.length>1}
              responsive={responsive}
              dotListClass="custom-dot-list-style"
              className={company_photos.length>1?'video-photo-hover':''}
            >
              {
                company_photos.map((photo, index)=>{
                  return(
                    <div key={index} className='carousel-item'>
                      <img src={photo}/>
                    </div>
                  )
                })
              }
            </Carousel>
          }
        </Box>
        {company_videos && company_videos.length>0 &&
        <Box mt={3}>
          <Box mt={3}>
              <Carousel
                infinite={company_videos.length>1} 
                showDots={company_videos.length>1}
                responsive={responsive}
                arrows={company_videos.length>1}
                dotListClass="custom-dot-list-style"
                className={company_videos.length>1?'video-photo-hover':''}
                autoPlay = {false}
              >
                {
                  company_videos.map((video, index)=>{
                    return(
                      <div key={index} className='carousel-item' style={{height:'auto', minHeight:'150px'}}>
                        <ReactPlayer controls className="video" url={video.URL} />
                        <div style={{position:'absolute', bottom:'61px', left: '12px', color:'white'}}>{video.TITLE}</div>
                      </div>
                    )
                  })
                }
              </Carousel>
          </Box>
        </Box>
        }
        <Box mt={2}>
          {(company_contact_fn||company_contact_ln||company_contact_email||company_contact_phone) &&
          <>
          <h3 class="directory-header" style={{marginBottom:'10px'}}>Contact Info</h3>
          </>
          }
          <Box mt={2}>
            {company_contact_fn} {company_contact_ln} {credentials}{company_contact_fn||company_contact_ln?<br/>:''} 
            {
              ((c_title && c_title !='') ) &&
              <>{c_title} <br/></>
            }
            {
              company_contact_phone && company_contact_phone !='' &&
              <>Work Phone: {company_contact_phone} {company_contact_phone_ext?'Ext:':''} {company_contact_phone_ext}{company_contact_phone||company_contact_phone_ext?<br/>:''}</>
            }
            {
              cell_phone && cell_phone !='' &&
              <>Cell Phone: {cell_phone}<br/></>
            }
            <br/>
            {
              company_name && company_name !='' &&
              <>{company_name}<br/></>
            }
            {
              brand_name && brand_name !='' &&
              <>{brand_name}<br/></>
            }
            {
              division && division !='' &&
              <>{division}<br/></>
            }
            {company_contact_street}{company_contact_street?<br/>:''}
            {company_contact_address2}{company_contact_address2?<br/>:''}
            {company_contact_city}{(company_contact_city&&(company_contact_state||company_contact_zip||company_contact_country)) &&<>,</>} {company_contact_state} {company_contact_zip}<br/>
            {company_contact_country}{company_contact_country?<br/>:''}
            <br/>
            {
              toll_free && toll_free !='' &&
              <>Toll Free: {toll_free}<br/></>
            }
            {
              fax && fax !='' &&
              <>Fax: {fax}<br/></>
            }
          </Box>
        </Box>
        <Box mt={2}>
          {(company_facebook||company_twitter||company_instagram||company_linkdin||company_tiktok) &&
          <Divider></Divider>
          }
          {/* <Box mt={3}><label><b>Soicial Links</b></label></Box>   */}
          <Box display={'flex'} justifyContent={'center'} mt={2} textAlign='center'>
            {company_facebook&&
            <Tooltip title="Facebook">
              <a className="social-icon" target="_blank" href={`https://www.facebook.com/${company_facebook}`}>
                <i class="fa-brands fa-facebook-f"></i>
              </a>
            </Tooltip>
            }
            {company_twitter&&
            <Tooltip title="Twitter">
              <a className="social-icon" target="_blank" href={`https://twitter.com/${company_twitter}`}>
                <i class="fa-brands fa-x-twitter"></i>
              </a>
            </Tooltip>
            }
            {company_instagram&&
            <Tooltip title="Instagram">
              <a className="social-icon" target="_blank" href={`https://www.instagram.com/${company_instagram}`}>
                <i class="fa-brands fa-instagram"></i>
              </a>
            </Tooltip>
            }
            {company_linkdin&&
            <Tooltip title="Linkedin">
              <a className="social-icon" target="_blank" href={`https://www.linkedin.com/company/${company_linkdin}`}>
                <i class="fa-brands fa-linkedin-in"></i>
              </a>
            </Tooltip>
            }
            {company_tiktok&&
            <Tooltip title="Tiktok">
              <a className="social-icon" target="_blank" href={`https://www.tiktok.com/@${company_tiktok}`}>
                <i class="fa-brands fa-tiktok"></i>
              </a>
            </Tooltip>
            }
            
          </Box>
        </Box>
        <Box mt={2} mb={3} style={{paddingBottom: 50}}>
          {(exhibitor?.['DIRECTORY']?.['WEBSITE']||(exhibitor?.['DIRECTORY']?.['FORM']&& company_call_label)) &&
          <Divider></Divider>
          }
          <Box mt={2}>
            <Box mt={1} mb={3} textAlign='center'>
              
            {exhibitor?.['DIRECTORY']?.['WEBSITE'] &&
            <>
              <Button className='site-button' style={{marginRight:'10px'}} variant="contained" href={`https://${exhibitor?.['DIRECTORY']?.['WEBSITE'].trim().replace("https://", "").replace("http://", "")}`} target='_blank'>
                Website
              </Button>
            </>
            }        
            {exhibitor?.['DIRECTORY']?.['FORM']&& company_call_label&& 
            <>     
              {exhibitor?.['DIRECTORY']?.['FORM']?
                <Button variant="contained" onClick={()=>{setOpenCallAction(exhibitor?.['DIRECTORY']?.['FORM'])}}>
                  {company_call_label?.trim()}
                </Button>:
                <Button variant="contained" target="_blank" href={company_call_link?company_call_link:'#'}>
                  {company_call_label?.trim()}
                </Button>
              }
            </>
            }
            </Box>
          </Box>
        </Box>
      </>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};
const mapDispatchProps = (dispatch) => {
  return {    
    setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
    setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
    setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
    setBookmardUpdated:   data=>dispatch(setBookmardUpdated(data)),
    setBookmarkList:  data=>dispatch(setBookmarkList(data)),
    setLeftSelectedCategory:  data=>dispatch(setLeftSelectedCategory(data)),
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setOpenCompanyModal:  data=>dispatch(setOpenCompanyModal(data)),
    setEditCompany: value=>dispatch(setEditCompany(value)),
    setSnackMsg: data=>dispatch(setSnackMsg(data)),
    setOpenSnack: data=>dispatch(setOpenSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
