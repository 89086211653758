import logo from './logo.svg';
import './App.css';
import MainViewer from './viewer/MainViewer';
import MainViewer3D from './viewer3d/MainViewer';
import Box from '@mui/material/Box';
import ToolBox from './viewer/ToolBox';
import AppLeftPanel from './viewer/component/AppLeftPanel';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
function App(props) {
  return (
    <>
      <div id="xhbt-main-container" className="App Client map-page">      
        <ToolBox {...props} page = 'home'/>
        <Box  sx={{ display: 'flex' }}>
          <AppLeftPanel/> 
          {
            (props.page == "2d") ?
            <MainViewer {...props}/>:
            <MainViewer3D {...props}/>
          }      
        </Box>
      </div>
    </>
  );
}

export default App;
