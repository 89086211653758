import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import {assetUrl} from '../../utils/Common'
import Carousel  from "react-multi-carousel";

const emails = ['username@gmail.com', 'user02@gmail.com'];

function Index(props) {
  const { onClose, onOk, onChange, open } = props;
  var mainLayers = props.main.mainLayers;

  useEffect(() => {
  }, [])
  
  const onChangeName = (e)=>{
    onChange(e)
  }
  
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
  var sponsorList = props.sponsorList
  return (
    <>
      {sponsorList && sponsorList.length>0 &&
          <Carousel
          arrows={false}
          infinite={true} 
          showDots={false}
          autoPlay={true}
          autoPlaySpeed={3000}
          responsive={responsive}
          // dotListClass="custom-dot-list-style"
          >
          {
              sponsorList.map((sponsor, index)=>{
              return(
                  <a target="_blink" href={(sponsor.url?.includes("https://")||sponsor.url?.includes("http://")?sponsor.url:(sponsor.url?`https://${sponsor.url}`:'javascript:void(0)'))} key={index} className='header-carousel-item'>
                  <img src={assetUrl(sponsor.logo)}/>
                  </a>
              )
              })
          }
          </Carousel>
      }
    </>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);