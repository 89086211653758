import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import '../../App.css';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { setAuthInfo, setLoading, setBookmarkList, setExhibitorList} from '../../actions/main'
import { setOpenCompanyProfile } from '../../actions/ui'
import { setCustomStyles } from '../../actions/ui'
import { setOpenLogin } from '../../actions/ui'
import { assetUrl } from '../../utils/Common'
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';
import ShowMore from 'react-show-more';
import FeaturedLabel from './FeaturedLabel';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import qs, { parse } from 'qs'
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { API_URL, ESHOW_URL } from '../../config.js'

function Index(props) { 
  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();

 
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fafafa',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: "#555555",

    borderRadius:0
  }));


  useEffect(() => {
    props.setOpenCompanyProfile(false)
  }, [])

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const openCompanyProfile=(exhibitor)=>{   
    var booth = {};
    booth.exhibitors = [exhibitor]
    booth.booth_number = exhibitor?.BOOTHS

    props.setOpenCompanyProfile({booth:booth, open:true})
  }


  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null
  var sales_bookmarks_show = true
  if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
    sales_bookmarks_show = false
  }
  const {item, boothFileList} = props
  return (
    <Item className='exhibitor_card' variant="outlined" square style={{minHeight: 290, position:'relative', padding:0}}>
      {sales_bookmarks_show&&
      <IconButton style={{position:'absolute', right: '17px', top:'5px', padding:5}} edge="end" onClick={()=>{ props.doBookmark(item)}}>
        {
          item.BOOKMARKED?
          <BookmarkSharpIcon style={{color:'#e54839'}}/>:
          <BookmarkBorderSharpIcon/>
        }
      </IconButton>
      }
      {item.FEATURED &&
      <Box>
        <FeaturedLabel moreClass='feet-content'/>
      </Box>
      }
      <Box mt={2} style={{padding:8}}>
        {item.LOGO&&
        <>
          {
            item.WEBSITE?
            <a style={{textAlign:'center', justifyContent:'center'}} target='_blank' href={item.WEBSITE}>
              <img style={{maxWidth:'100%'}} width="auto" height="90px" src={`${assetUrl(item.LOGO)}`}/>
            </a>:
            <>
              <img style={{maxWidth:'100%'}} width="auto" height="90px" src={`${assetUrl(item.LOGO)}`}/>
            </>
          }
        </>
        }
        <Box>
          <a href="javascript:void(0)" onClick={()=>{openCompanyProfile(item)}}><b style={{color:'black'}}>{item.COMPANY_NAME}</b></a>
        </Box>
        <Box mt={1}>
          <span>Booth: {item.BOOTHS}</span><br/>
          {item.MAP_NAME && boothFileList?.length > 1 && props.main.showSetup?.MAP_LOCATION == 'show' &&
          <span>Map: {item.MAP_NAME}</span>
          }
        </Box>
        <Box mt={1} style={{minHeight:'70px', textAlign:'left'}}>
          <ShowMore
                lines={5}
                more='Read More'
                less='Read Less'
                anchorClass='read-more'
              >
                {item?.['DESCRIPTION']}
            </ShowMore>
        </Box>
        <Box mt={1} style={{textAlign: 'center'}}>
          {
            item.CATEGORY?.map((category, catInd)=>{
              if(category.NET_CODE == 1003)
              return(
                <Chip className={classes.bubble} size="small" style={{margin: '0px 3px 3px', cursor:'pointer'}} key={catInd} label={category.ANSWER_TITLE} 
                  onClick={()=>{
                    if(props.setKeySelect)
                      props.setKeySelect('Product Categories');  
                    if(props.setPulldownValue)
                      props.setPulldownValue(category.ANSWER_KEY); 
                  }} color="primary" />
              )
            })
          }
        </Box>
      </Box>
    </Item>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setBookmarkList:  data=>dispatch(setBookmarkList(data)),
      setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
      setExhibitorList:  data=>dispatch(setExhibitorList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
