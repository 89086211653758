
import { parseToken, buildObjectProperty, convertRgbHex, getDefaultGroup, setCookie, getCookie, convertRgb,getTokenCookie,assetUrl, getCartCookie } from '../utils/Common'
import axiosInstance from '../utils/axiosInstance.js'
import { API_URL, ESHOW_URL, main_widow_offset_x, main_widow_offset_y  } from '../config.js'

export const saveLog = async (postData)=>{
    const options = {
        headers: {
            'body': '[{"company:","test"}]', 
            'Content-Type': 'application/json'
          }
    }
    var url = `${ESHOW_URL()}?method=putAuditLog&token=${getTokenCookie()}`
    var response = await axiosInstance.post(url, JSON.stringify(postData), options)
    if(response.status == 200){
        
    }
}