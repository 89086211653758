// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (message) => {
      const data = message.data;
      // console.log(nbr)
  
  
      postMessage(data);
    };
};