import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import  Carousel from 'react-material-ui-carousel'

function Index(props) {

  useEffect(() => {
  }, [])
  return (
    <Carousel {...props}>
      {props.children}
    </Carousel>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);