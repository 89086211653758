import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import {PaperComponent} from '../../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function Index(props) {
  const { onClose, open } = props;
  var mainLayers = props.main.mainLayers;
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    var target = props.main.menuControl.target;
    if(target && target.get('angle')){
      setAngle(target.get('angle'))
    }
    else{
      setAngle(0)
    }
  }, [open])
  
  const onChange = (e)=>{
    setAngle(e.target.value)
  }

  const onOk = (e)=>{
    if(angle)
      props.onOk(angle)
  }

  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'rotate-dialog-title'}}
      aria-labelledby="rotate-dialog-title"
      onClose={onClose} open={open}>
      <DialogTitle id='rotate-dialog-title' style={{display:'flex', alignItems:'center'}}><RotateLeftIcon style={{marginRight: 10}}/>Rotate</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        <TextField
          name="angle"
          margin="dense"
          label="Angle"
          type="text"
          fullWidth
          variant="standard"
          value={angle}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);