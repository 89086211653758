import React ,{ useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import { fabric } from "fabric";
import { SketchPicker, PhotoshopPicker  } from 'react-color'
import rgbHex from "rgb-hex";
import ColorLensIcon from '@mui/icons-material/ColorLens';
import {PaperComponent} from '../../utils/Common'


function ColorModal(props) {
  const { onClose, onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [color, setColor] = useState('#fff')

  useEffect(() => {
    if(open){
    }
  }, [open])
  
  const handleChangeComplete = (c) => {
    // setColor(color );
    setColor("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b))
  };

  const handleChange = (c, event) => {
    // setColor(color );
    setColor("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b))
  }

  const onOk = (e)=>{
    props.onOk(color)
  }


  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'color-dialog-title'}}
      aria-labelledby="color-dialog-title"
      onClose={onClose} open={open?open:false}>
      <DialogTitle id='color-dialog-title' style={{display:'flex', alignItems:'center'}}><ColorLensIcon style={{marginRight: 10}}/>Color</DialogTitle>
      <DialogContent>
        <Box mt={1}>
            <SketchPicker
              color={ color }
              onChange={ handleChange }
              onChangeComplete={ handleChangeComplete }
              open={true}
            />
        </Box>
       </DialogContent>
        <DialogActions>
          <Button onClick={onOk}>Ok</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(ColorModal);