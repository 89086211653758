
import {
  SET_BOOTH_PREFIX,
  SET_BOOTHSTART_NUMBER,
  SET_BOOTH_SUFFIX,
  SET_BOOTH_CNT,
  SET_BOOTH_ORDER,
  SET_BOOTH_SEQMODE,
} from '../actions/element';

var initVal = {
  boothPrefix:"",
  boothStartNumber:1,
  boothSuffix:'',
  boothCnt:1,
  boothOrder: "v_inc",
  boothSeqMode:"even",
}
export default function quote(state = initVal, {type, payload}) {
  switch (type) {
    case SET_BOOTH_PREFIX:
      return {
        ...state,
        boothPrefix: payload,
    }
    case SET_BOOTHSTART_NUMBER:
      return {
        ...state,
        boothStartNumber: payload,
    }
    case SET_BOOTH_SUFFIX:
      return {
        ...state,
        boothSuffix: payload,
    }
    case SET_BOOTH_CNT:
      return {
        ...state,
        boothCnt: payload,
    }
    case SET_BOOTH_ORDER:
      return {
        ...state,
        boothOrder: payload,
    }
    case SET_BOOTH_SEQMODE:
      return {
        ...state,
        boothSeqMode: payload,
    }
      default:
          return state;
  }
}