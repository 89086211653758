import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers/index.js';
import { createHashHistory } from 'history';

// axios.defaults.baseURL = INITIAL_DOMAIN
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const history = createHashHistory();
export function getHistory() {
  return history;
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      ReduxThunk
    ),
  )
);

