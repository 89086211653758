import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';
import { setLeftSearchVal, setOpenBoothList, setLeftPage } from '../../../actions/ui'
import { setCart } from '../../../actions/cart'
import InputBase from '@mui/material/InputBase';
import EditIcon from '@mui/icons-material/Edit';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Button from '@mui/material/Button';
import CompanyEditModal from '../CompanyEditModal'
import { useEffect,useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import BuyCartModal from '../BuyCartModal'

import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setActive3dObject } from '../../../actions/main';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { px2unit, unit2Px } from '../../../utils/CanvasTool'
import axios from 'axios';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { parseToken } from '../../../utils/Common'
import Fade from '@mui/material/Fade';

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  width: '38px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function BoothList(props) {
  let query = useQuery();
  const theme = useTheme();
  const [boothList, setBoothList] = React.useState([]);
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [filteredBoothList, setFilteredBoothList] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState(null);
  const [openBuyModal, setOpenBuyModal] = React.useState(false);
  const [reserved, setReserved] = React.useState(false);
  const [priceCheck , setPriceCheck] = React.useState([]);
  const [premiumCheck , setPremiumCheck] = React.useState('');
  const [discountCheck, setDiscountCheck] = React.useState('');
  
  const [editBooth, setEditBooth]  = React.useState(null);
  const main_widow_offset_x = 0;
  const main_widow_offset_y = 51;
  
  var pricingOptions = props.main.pricingOptions
  var premiumOptions = props.main.premiumOptions
  var discountOptions = props.main.discountOptions
  var groupOptions = props.main.groupOptions


  useEffect(() => {
    if(props.open)
    {
      setTimeout(function(){
        var cartList = props.cart.carts;

        // if(searchVal){
        //   var filteredBoothList = boothList.filter((booth)=>{
        //     var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
        //     return (str.toLowerCase().includes(searchVal.toLowerCase()))
        //   })
        //   setFilteredBoothList(filteredBoothList)
        // }
        // else{
        //   setFilteredBoothList(boothList)
        // }
        setFilteredBoothList(cartList)
        setBoothList(cartList)
        
        setLoadingFlag(false)
      }, 300)
      
    }
    


  }, [props.open, props.cart.carts])


  const doSearch = (searchVal)=>{
    if(searchVal){
      var filteredBoothList = boothList.filter((booth)=>{
        var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
        return (str.toLowerCase().includes(searchVal.toLowerCase()))
      })
      setFilteredBoothList(filteredBoothList)
    }
    else
      setFilteredBoothList(boothList)
  }


  const setActiveObject = (o) => {
    var mainCanvas = props.main.mainCanvas;
    // mainCanvas.getObjects().forEach(function(o) {
    //   if(o.id == "booth-"+id) {
    //     console.log("selected", o)
        mainCanvas.viewportTransform[0] = 1;
        mainCanvas.viewportTransform[3] = 1;
        mainCanvas.viewportTransform[4] = window.screen.width/2-o.left-o.width/2-main_widow_offset_x;
        mainCanvas.viewportTransform[5] = window.screen.height/2-o.top-o.height/2-main_widow_offset_y;
        window.scrollTo(0, 0)
        mainCanvas.requestRenderAll();
        mainCanvas.setActiveObject(o).renderAll();
        props.setActive3dObject(o)
    //   }      
    // })
  }

  // var boothList=[...props.main.boothList]
  // boothList.sort(function(a, b) {
  //   const nameA = a.boothName; // ignore upper and lowercase
  //   const nameB = b.boothName; // ignore upper and lowercase
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  
  //   // names must be equal
  //   return 0;
  // });
  // boothList.sort(function(a, b) {
  //   return a.boothName - b.boothName;
  // });

  var booth;
  var boothName;
  var boothSize;
  
  if(props.ui.openUnassignedProfile && props.main.mainCanvas){
    booth = props.ui.openUnassignedProfile.booth;
    
    boothSize = px2unit(booth.width, props.main.mainCanvas.convertRateX, props.main.mainCanvas.mapUnit).toFixed(0)+" x "+px2unit(booth.height, props.main.mainCanvas.convertRateX, props.main.mainCanvas.mapUnit).toFixed(0)
    boothName = booth.booth_number;
  }

  var total = 0;
  var pricingOptionsTmp = [];
  var premiumOptionsTmp = [];
  var discountOptionsTmp = [];
  console.log("filteredBoothList", filteredBoothList)
  for(var i = 0; i < filteredBoothList.length; i++){
    var booth = filteredBoothList[i]
    if(booth){
      for(var j = 0; j < priceCheck.length; j++){
        var row = priceCheck[j];
        var price = 0;      
        price = row.PRICE*1;
        total+=price
        if(row.PRICE_CATEGORY == "price"){
          pricingOptionsTmp.push(row)
        }
        else if(row.PRICE_CATEGORY == "premium"){
          premiumOptionsTmp.push(row)
        }
        else if(row.PRICE_CATEGORY == "discount"){
          discountOptionsTmp.push(row)
        }
      }
    }
  }
  return (
    <Fade in={true} timeout={1200}>
      <div className='appLeftSearch'>
        <Box className="">
            <Box display="flex" pt={2} pb={2} style={{width:'100%', justifyContent:'space-between', alignItems:'center'}}>
              
              <div style={{marginLeft:20}}></div>
              <h4 style={{width:'calc(100% - 40px)', margin:0}}>Shopping Cart</h4>
              <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
              {props.role == 'admin' ?          
                <IconButton
                    size="small"
                    edge="start"
                    aria-label="assign"
                    style={{float:'right'}}
                    onClick={()=>{props.setOpenBoothList(false)}}
                  >
                    <HighlightOffOutlinedIcon/>
                </IconButton>:
                <IconButton
                  size="small"
                  edge="start"
                  aria-label="assign"
                  style={{float:'right'}}
                  onClick={()=>{props.setLeftPage('home')}}
                >
                  <ChevronLeftIcon/>
                </IconButton>
                }
              </div>
            </Box>
            <Divider />
            {filteredBoothList&&
            <>
              <Table className="pricing-table" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {pricingOptionsTmp.map((row, index) => {
                        return(
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>{row.PRICE_LABEL}</TableCell>
                            <TableCell>${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                          </TableRow>
                        )
                      })}
                      
                      {premiumOptionsTmp.map((row, index) => {
                        return(
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>{row.PRICE_LABEL}</TableCell>
                            <TableCell>${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                          </TableRow>
                        )
                      })}

                      {discountOptionsTmp.map((row, index) => {
                        return(
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>{row.PRICE_LABEL}</TableCell>
                            <TableCell>(${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>(${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
              </Table>
              <p style={{textAlign:'right', paddingRight:'26px'}}><strong>Total:</strong> ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
            
              {/* <Box mb={2}>
                <Button style={{width:150}} variant="contained" onClick={()=>{
                  // setOpenBuyModal(true); 
                  }} >
                  Buy
                </Button>
              </Box> */}
            </>
            }
            
            <BuyCartModal
              open={openBuyModal}
              onClose={()=>{setOpenBuyModal(false);setReserved(false); props.setCart([])}}
              booths = {filteredBoothList}
              boothTexts = {[boothName]}
              reserved = {reserved}
              pricingOptions = {pricingOptions}
              premiumOptions = {premiumOptions}
              discountOptions = {discountOptions}
              groupOptions = {groupOptions}
              mapId = {query.get("map")}
            />
            <LoadingOverlay
                active={loadingFlag}
                styles={{
                    // spinner: (base) => ({
                    // ...base,
                    // width: '50px',
                    // '& svg circle': {
                    //     stroke: '#1976d2'
                    // }
                    // }),
                    overlay: (base) => ({
                    ...base,
                    fontSize:'18px',
                    color: 'rgb(5, 37, 51)',
                    // background: 'rgb(229 229 229 / 92%)',
                    background: 'transparent',
                    position:'absolute',
                    left:'50%',
                    top:'200px',
                    transform:'translateX(-50%)',
                    zIndex:1000000000
                    })
                }}
                spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
                // spinner
                // text='Loading ...'
                >
            </LoadingOverlay> 
        </Box>
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    log: state.log,
    cart: state.cart,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setLeftSearchVal: value=>dispatch(setLeftSearchVal(value)) ,
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setCart: value=>dispatch(setCart(value)) ,
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
  };
};

export default connect(mapStateToProps, mapDispatchProps)(BoothList);
