
import {
  SET_STORAGE
} from '../actions/local_storage';

var initVal = {
  storage: {}
}
export default function index(state = initVal, {type, payload}) {
  switch (type) {
    case SET_STORAGE:
      console.log("SET_STORAGE", payload)
      return {
        ...state,
        storage: {...state.storage, [payload.cname]: payload.cvalue}
    }
      default:
          return state;
  }
}