import logo from './logo.svg';
import './App.css';
import MainViewer from './vieweradmin3d/MainViewer';
import ToolBox from './viewer3d/ToolBox';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
function App(props) {
  return (
    <div className="App">
      <MainViewer {...props}/>
    </div>
  );
}

export default App;
