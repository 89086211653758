

import JWT from 'cf-jwt';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { changeSingleBG, getBGColor } from "./CanvasTool"
import { SET_SERVER_URL, SET_ESHOW_URL, INITIAL_DOMAIN } from "../config"
import { setLocalStorage } from "../actions/local_storage"
import { store } from "../store"
import { v4 as uuidv4 } from 'uuid';
const moment = require('moment-timezone');
export const uuidRegexExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const uuid_patterns = {
    1: /^[0-9A-F]{8}-[0-9A-F]{4}-1[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    2: /^[0-9A-F]{8}-[0-9A-F]{4}-2[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
    5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
    5: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i
  };

export const isUUID = (input, version) => {    
    if(typeof input === "string"){
        if(Object.keys(uuid_patterns).includes(typeof version === "string" ? version : String(version))){
            return uuid_patterns[version].test(input);
        } else {
            return Object.values(uuid_patterns).some(pattern => pattern.test(input));
        }
    }
    return false;
}

export const parseToken = async (query)=>{
    // console.log("store", store)
    var token = getTokenCookie()
    var jwt = {};
    if(token){
        // jwt = await JWT.verify(token, 'jwt', {
        //     algorithms: ['HS256'],
        // });
        // console.log("process.env.REACT_APP_INITIAL_DOMAIN",process.env.REACT_APP_INITIAL_DOMAIN)
        var url = `https://${process.env.REACT_APP_INITIAL_DOMAIN}/webservices/eshow/jwt_service.cfc?method=decode`
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          } 
        var result = await axios.get(url, options);
        jwt = result.data
        if(jwt.SERVER.map_server){
            // var map_server = 'mapbeta.goeshow.com'
            var map_server = jwt.SERVER.map_server
            SET_SERVER_URL(map_server?.replace("https://","").replace("www.",""))
            // axios.defaults.baseURL = `https://${map_server}:3000/`
            // axios.defaults.baseURL = `https://${map_server}/api/`
        }        
        if(jwt.API_DOMAIN){
            SET_ESHOW_URL(jwt.API_DOMAIN)
        }
        // console.log("jwt decode",jwt)
    }
    return jwt;
}


export const parseTokenValue = async (token)=>{
    var jwt = {};
    if(token){
        // jwt = await JWT.verify(token, 'jwt', {
        //     algorithms: ['HS256'],
        // });
        // var url = `/decodecftoken?token=${token}`
        var url = `https://${process.env.REACT_APP_INITIAL_DOMAIN}/webservices/eshow/jwt_service.cfc?method=decode`
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          } 
        var result = await axios.get(url, options);
        jwt = result.data
        // console.log("jwt decode",jwt)
    }
    return jwt;
}

export const listToTree = (arr = []) => {
    let map = {}, node, res = [], i;
    for (i = 0; i < arr.length; i += 1) {
       map[arr[i].id] = i;
       arr[i].children = [];
    };
    for (i = 0; i < arr.length; i += 1) {
       node = arr[i];
       if(!node.parent) node.parent = "0"
       if (node.parent !== "0") {
          if(map[node.parent]>=0 && arr[map[node.parent]])
            arr[map[node.parent]].children.push(node);
       }
       else {
          res.push(node);
       };
    };
    return res;
};

export const objectProperties = [
   'id',
   'class',
   'class1',
   'child_booths',
   'sold',
   'layer',
   'company',
   'boothGroup',
   'boothType',
   'priceCheck',
   'premiumCheck',
   'discountCheck',
   'priceFilter',
   'premiumFilter',
   'booth_number',
   'fontSizeCustom',
   'fontColorCustom',
   'fontStyleCustom',
   'title',
   'description',
   'borderColorCustom',
   'bgColorCustom',
   'borderSizeCustom',
   'borderPattern',
   'transparency',
   'groupped',
   'lockforever',
   'signed_pdf',
   'co_companies',
   'modified',
   'new_created',
   'uniq_id',
   'transparency',
   'visible',
   'scaleX',
   'scaleY',
   'boothCompanyObject',
   'boothContactObject',
   'boothSalesItemObject',
   'labelPosition',
   'deleteType',
   'deleteTime',
   'deleteUser',
   'KEY_ID',
   'sale_selected',
   'disabled',
   'deselectedColor',
   'exhibitors',
   'prices',
   'old_deleted',
   'hightlighted',,
   'holdBooth',
   'privateNote',
   'publicNote',
   'invite_code',
   'boothType',
   'zIndex',
   'dwg_left',
   'dwg_top',
   'dwg_width',
   'dwg_height',
   'sponsorshipGroup',
   'sponsorshipId',
   'forward',
];

export const buildObjectProperty=(target)=>{
   var output = {}
   for(var i = 0; i < objectProperties.length; i++){
      var objectName = objectProperties[i];
      if (target[objectName] !== undefined && target[objectName] !== 'undefined' && target[objectName] !== null && target[objectName] !== ""){
         output = {...output, [objectName]: target[objectName]}
      }
   }
   return output;
}

export const containsObject=(obj, list,key)=> {
   var i;
   for (i = 0; i < list?.length; i++) {
        var key1 = list[i][key];
        var key2 = obj[key]
        if(key1){
            key1 = (""+key1).toLowerCase()
        }
        if(key2){
            key2 = (""+key2).toLowerCase()
        }
       if (key1 === key2) {
           return list[i];
       }
   }

   return false;
}
export const download = (path, filename) => {
   // Create a new link
   const anchor = document.createElement('a');
   anchor.href = path;
   anchor.download = filename;
   anchor.style.display = 'none';

   // Append to the DOM
   document.body.appendChild(anchor);

   // Trigger `click` event
   anchor.click();

   // Remove element from DOM
   document.body.removeChild(anchor);
}; 




export const convertRgb=(str)=>{
    var returnVal = ""
    if(str && str != ""){
        var tmp = str.split(",")
        returnVal = `rgba(${tmp[0]},${tmp[1]},${tmp[2]},${tmp[3]?tmp[3]:0xff})`
    }
    // else{
    //     returnVal = `rgba(0,0,0,0xff})`
    // }
    return returnVal
}

export const convertRgbHex=(str)=>{
    var returnVal = ""
    if(str && str != ""){
        var tmp = str.split(",")
        var r = (tmp[0]*1).toString(16)
        if(r.length < 2) r = '0'+r
        var g = (tmp[1]*1).toString(16)
        if(g.length < 2) g = '0'+g
        var b = (tmp[2]*1).toString(16)
        if(b.length < 2) b = '0'+b
        returnVal = `#${r}${g}${b}`
    }
    return returnVal
}

export const convertRgbStringHex=(str)=>{
    var returnVal = ""
    if(str && str != ""){
        var tmp = str.replaceAll("rgba","").replaceAll("rgb","").replaceAll("(","").replaceAll(")","").split(",")
        if(tmp.length >= 3){
            var r = (tmp[0]*1)
            var g = (tmp[1]*1)
            var b = (tmp[2]*1)
            returnVal = `${r},${g},${b}`
        }
    }
    return returnVal
}

export const convertRgbHex2=(str)=>{
    var returnVal = ""
    if(str && str != ""){
        var tmp = str.replaceAll("rgba","").replaceAll("rgb","").replaceAll("(","").replaceAll(")","").split(",")
        if(tmp.length >= 3){
            var r = (tmp[0]*1).toString(16)
            if(r.length < 2) r = '0'+r
            var g = (tmp[1]*1).toString(16)
            if(g.length < 2) g = '0'+g
            var b = (tmp[2]*1).toString(16)
            if(b.length < 2) b = '0'+b
            returnVal = (`#${r}${g}${b}`).toUpperCase()
        }
    }
    return returnVal
}

export const getDefaultGroup=(groupOptions)=>{
    var defaultKey = ''
    if(groupOptions){
        for(var i = 0; i < groupOptions.length; i++){
            if(groupOptions[i].TITLE == "Default"){
                defaultKey = groupOptions[i].KEY_ID
            }
        }
    }
    return defaultKey;
}

export const setCookie = (cname, cvalue, exdays) =>{
    // console.log("setCookie", store)
    if(isLocalStorageAvailable()){
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/; SameSite=None; Secure";
    }
    else if(store){
        store.dispatch(setLocalStorage({cname, cvalue}))
    }
}

export const getCookie = (cname) =>{
    // console.log("getCookie", store)
    if(isLocalStorageAvailable()){
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    else if (store){
        const state = store.getState();
        return state.local_storage?.storage?.[cname]
    }
    else 
        return false;
}

export const setTokenCookie=(value)=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var show_key = urlParams.get('show_key')
    var sales_map = urlParams.get('sales_map')
    var tokenName = ""
    const path = window.location.pathname
    var role = "public"
    if(path.includes('/admin')){
        role = "admin"
    }
    if(role == "admin"){
        tokenName = `V3ADMINMAP-${show_key}`
    }
    else {
        if(sales_map){
            tokenName = `V3SALESMAP-${show_key}`
        }
        else{
            tokenName = `V3PUBLICMAP-${show_key}`
            tokenName = `V3PUBLICMAP`
        }
    }
    return setCookie(tokenName, value)
}

export const setCartCookie=(value)=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var show_key = urlParams.get('show_key')
    var tokenName = `cart_${show_key}`
    
    return setCookie(tokenName, value)
}

export const getUrlToken = ()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var paramToken = urlParams.get('token')
    var token = "";
    var pathArray = window.location.pathname.split('/');
    var urlToken = "";
    if(pathArray[1] && pathArray[2] && pathArray[3]){
        urlToken = pathArray[1]+"/"+pathArray[2]+"/"+pathArray[3]
    }

    if(paramToken){
        token = paramToken
    }
    else
        token = urlToken;
    
    return token
}

export const getTokenPlanner=()=>{
    var urlToken = getUrlToken()

    var tokenName = `token-${urlToken}`
    var _token = null;
    try{
        _token = JSON.parse(localStorageGetItem(tokenName));
    }
    catch(e){
        _token = null;
    }
    
    return _token
}

export const getTokenInfoPlanner=()=>{
    var urlToken = getUrlToken()
    var user_dataName = `user_data-${urlToken}`
    var user_data = null
    try{
        user_data = JSON.parse(localStorageGetItem(user_dataName));
    }
    catch(e){
        user_data = null;
    }
    
    return user_data
}

export const deleteTokenPlanner=()=>{
    var urlToken = getUrlToken()

    var tokenName = `token-${urlToken}`
    var user_dataName = `user_data-${urlToken}`
    
    // localStorage.removeItem(tokenName);
    // localStorage.removeItem(user_dataName);
    
    localStorageSetItem(tokenName, null);
    localStorageSetItem(user_dataName, null);
    
}

export const setTokenPlanner=(TOKEN, tokenInfo)=>{
    var urlToken = getUrlToken()
    var tokenName = `token-${urlToken}`
    var user_dataName = `user_data-${urlToken}`
    localStorageSetItem(tokenName, JSON.stringify(TOKEN));
    localStorageSetItem(user_dataName, JSON.stringify(tokenInfo));
}

export const getTokenCookie=()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var show_key = urlParams.get('show_key')
    var sales_map = urlParams.get('sales_map')
    var url_token = urlParams.get('token')
    var tokenName = ""
    const path = window.location.pathname
    var role = "public"
    var token = "";
    if(path.includes('/admin')){
        role = "admin"
    }
    if(window.location.href.includes('localhost:')){
        if(!token){
            if(role == "admin"){
                setTokenCookie('95475B13%2DEE3D%2DE911%2D80D1%2D001B21D7CC11')
            }
            else {
                if(sales_map){
                    setTokenCookie('C38A809C%2DDD42%2D9314%2D74C0%2D6355E09DC3B3')
                }
                else{
                    // setTokenCookie('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9%2EeyJnX3RwYWdlIjoiZGV2LmdvZXNob3cuY29tL1RNUDY2NkZCRjBBN0VDMjQ0OTFCNERDNTgwQjgyNUE1MjEwMDgxNDMzLmNmbT9wZz0iLCJTRVJWRVIiOnsiUzNfYnVja2V0IjoiZXNob3ctZHVjLXRlbXAiLCJTM19zZWNyZXQiOiJrdTNDZ1pLb05lN3JvdmozYXRQQVJZUFprMGFXdFVMSTY3bldqZlhsIiwibWFwX3NlcnZlciI6Imh0dHBzOi8vbWFwYmV0YS5nb2VzaG93LmNvbTozMDAxIiwiYWNjZXNzX2tleSI6IkFLSUEySk5WS1dBQ0czVElHRk82In0sImNsaWVudF9rZXkiOiJCQTJGMEUzMi1FMjUwLTQ4RkUtQjA5QS1DNzAwNjVFMkI3ODkiLCJzaG93X2tleSI6IkM5RUJDMzQxLTQ1MjItNEJFNi1CNEZBLUVCN0Y2MTMxMTgzQSIsInVzZXJfbmFtZSI6IlJhanUgUGF0ZWwiLCJ1c2VyX2tleSI6IjY2NkZCRjBBLTdFQzItNDQ5MS1CNERDLTU4MEI4MjVBNTIxMCIsIk9QVElPTlMiOnsiTUFQX0tFWSI6IjU4NzdGRTNCLTlBQjMtNEY5Ri1BRTVCLUEyMjU1N0VFQURFMSIsIkVYSElCSVRPUl9VUkwiOiJkZXYuZ29lc2hvdy5jb20vVE1QNjY2RkJGMEE3RUMyNDQ5MUI0REM1ODBCODI1QTUyMTAwODE0MzMuY2ZtP3BnPWV4aGliaXRvci9leGhpYml0b3JfcHJvZmlsZS5jZm0meHRlbXBsYXRlPTEmbWFzdGVyX2tleT17e2V4aGliaXRvcl9rZXl9fSJ9LCJTQUxFUyI6eyJNQVBfS0VZIjoiNTg3N0ZFM0ItOUFCMy00RjlGLUFFNUItQTIyNTU3RUVBREUxIiwiQk9PVEhfTElTVCI6ImhpZGUiLCJGRUFUVVJFRF9FWEhJQklUT1IiOiJoaWRlIiwiSEVBREVSX0lNQUdFIjoiIiwiM0RfQk9PVEhTIjoiaGlkZSIsIkZFQVRVUkVEIjoiaGlkZSIsIjNEX01BUCI6ImhpZGUifSwidHMiOiJNYXksIDIxIDIwMjMgMTk6MDA6MTIifQ%2Ezhz4MlNGjKdQWyYoZdQt7vwAeYURPUmIUcDF%2DOZE0NQ')
                }
            }
        }
    }
    
    if(role == "admin"){
        tokenName = `V3ADMINMAP-${show_key}`
        token = getCookie(tokenName);
    }
    else {
        if(0){
            if(sales_map){
                tokenName = `V3SALESMAP-${show_key}`
                token = getCookie(tokenName);
            }
            else{
                // tokenName = `V3PUBLICMAP-${show_key}`
                if(show_key)
                    tokenName = `V3PUBLICMAP-${show_key}`
                else
                    tokenName = `V3PUBLICMAP`
    
                // token = '/eshow_programming/midyear/2015'
                if(url_token){
                    token = url_token
                }
                else
                    token = getCookie(tokenName);
    
                    
                token = path
            }
        }
        if(url_token){
            token = url_token
        }
        else
            token = path;

    }
    
    return token
}

export const getCartCookie=()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var show_key = urlParams.get('show_key')
    var sales_map = urlParams.get('sales_map')
    var url_token = urlParams.get('token')
    var tokenName = ""
    const path = window.location.pathname
    var role = "public"
    var token = "";
    if(path.includes('/admin')){
        role = "admin"
    }
    
    
    tokenName = `cart_${show_key}`
    token = getCookie(tokenName);
    if(!token || token == ''){
        token = uuidv4()
        setCartCookie(token)
    }
    
    return token
}

export const resetCartCookie=()=>{
    setCartCookie('')

}

export const getPublicPath=()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var show_key = urlParams.get('show_key')
    var sales_map = urlParams.get('sales_map')
    var tokenName = `V3PUBLICMAP`

    // token = '/eshow_programming/midyear/2015'
    var token = getCookie(tokenName);
    if(token) {
        token = token.replace(/^\/+/, '');
    }
    return token
}


export const PaperComponent=(props)=>{
    return (
      <Draggable
        handle={`#${props.drag_id}`}
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

export const localStorageSetItem =(key, value)=>{
    setCookie(key, value)
}

export const localStorageGetItem =(key)=>{
    var value = getCookie(key)
    return value;
}

export const isLocalStorageAvailable=()=>{
    var standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test( userAgent ),
        chrome = /chrome/.test( userAgent ),
        ios = /iphone|ipod|ipad/.test( userAgent );
    // if (ios && !standalone && !safari && !chrome) {
    if (ios && !chrome) {
        return false
    }

    return true;
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

export const composeUrl = (public_path, pageName, menuList)=>{
    var output = public_path;
    output = `${public_path}/${pageName}`
    return output;
}

export const assetUrl = (url)=>{
    if(!url){
        return false
    }
    return url.replace("goeshow.com:3002/s3/", "goeshow.com/proxy/s3/")
    .replace("goeshow.com/api/tmp/", "goeshow.com/proxy/s3/map_bg/")
    .replace("http://12.41.127.199:3000", "https://mapbeta.goeshow.com/api")
            
}

export const safeTrim=(value)=>{
    if (value === null || value === undefined) {
        return '';  // or any other default value you prefer
    }
    return String(value).trim();
}

function isKiosk() {
    const kioskUserAgents = [
        /kiosk/i,
        /chrome\/58/i,
        /customkioskapp/i
    ];

    const userAgent = navigator.userAgent;

    return kioskUserAgents.some(pattern => pattern.test(userAgent));
}

export const detectEnvironment=()=>{
    if (isKiosk()) {
        console.log("Kiosk detected");
        return "kiosk";
    } else if (/Mobi|Android/i.test(navigator.userAgent)) {
        // console.log("Mobile device detected");
        return "mobile";
    } else {
        // console.log("Desktop device detected");
        return "desktop";
    }
}

export const fetchWithXMLHttpRequest=(url, method = 'GET', data = null)=>{
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      xhr.setRequestHeader("Accept", "image/apng");
      
      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseText);
        } else {
          reject(new Error(`Request failed with status ${xhr.status}`));
        }
      };
  
      xhr.onerror = function() {
        reject(new Error('Network error'));
      };
  
      xhr.send(data); // Send data if provided
    });
}

export const findZoneForAbbreviation = (abbr)=>{
    const zoneNames = moment.tz.names();
    for (let zone of zoneNames) {
        const abbreviation = moment.tz(zone).format('z');
        if (abbreviation === abbr) {
            return zone;  // Return the first matching zone
        }
    }

    return null;  // Return null if no zone matches
}

export  const formatDateRange = (dateStr1, dateStr2, timezone)=>{
    let zoneName = 'America/Chicago';
    if(timezone){
      let tmp = findZoneForAbbreviation(timezone)
      if(tmp){
        zoneName = tmp; 
      }
    }

    const date1 = moment(dateStr1);
    date1.tz(zoneName);
    
    const date2 = moment(dateStr2);
    date2.tz(zoneName);

    return `${date1.format('MM/DD/YYYY')} ${date1.format('hh:mm A')} - ${date2.format('hh:mm A')} ${date1.format('(z)')}`;
  }
