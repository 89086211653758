import React ,{ useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import LayersIcon from '@mui/icons-material/Layers';
import MenuIcon from '@mui/icons-material/Menu';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import BackupIcon from '@mui/icons-material/Backup';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import HistoryIcon from '@mui/icons-material/History';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux'
import LayerModal from './component/LayerModal'
import BoothImportModal from './component/BoothImportModal'
import GrouppingModal from './component/GrouppingModal'
import UnGrouppingModal from './component/UnGrouppingModal'
import FilenameModal from "./component/FilenameModal"
import { fabric } from "fabric";
import qs, { parse } from 'qs'
import './View.css';
import { useNavigate } from 'react-router-dom'
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';


import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { API_URL } from '../config.js'
import { setViewData, setViewFile, setBoothFileList, setBoothList, setCanvasRefresh, setLoading, to2D } from '../actions/main'
import { setBoothHistory } from '../actions/booth_history'

const ColorButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#22222250',
    },
  }));

const Button2D =  styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#22222250',
    },
    border:'2px solid white',
    float:'right',
    marginBottom:'2px'
  }));
function ToolBox(props) {
    const [dirContent, setDirContent] = useState(null);
    const [snackMsg, setSnackMsg] = useState('');
    const [openSnack, setOpenSnack] = useState(false);
    const [openLayerModal, setOpenLayerModal] = useState(false);
    const [openBoothImportModal, setOpenBoothImportModal] = useState(false);
    
    const [openGrouppingModal, setOpenGrouppingModal] = useState(false);
    const [openUnGrouppingModal, setOpenUnGrouppingModal] = useState(false);
    const [openFilenameModal, setOpenFilenameModal] = useState(false);
    const [ungroupCnt, setUngroupCnt] = useState(1);
    const [groupedItem,setGroupedItem] = useState(null);
    const [ungroupedItems,setUngroupedItems] = useState([]);
    const [grouppingBooth, setGrouppingBooth] = useState(0);
    const [ungrouppingBooth, setUngrouppingBooth] = useState({});
    const [undoFlag, setUndoFlag] = useState(false);
    const [saveFileName, setSaveFileName] = useState(null);
    const navigate = useNavigate()
    const openRef = useRef();
    var imageOffsetX, imageOffsetY;
    const mainLayers = props.main.mainLayers
    var boothFileList = props.main.boothFileList;

    const handleCloseSnack = ()=>{
        setOpenSnack(false);
    }
    
    const handleCloseBoothImportModal = ()=>{
        setOpenBoothImportModal(false)
    }   

    useEffect(() => {
        
    }, [])    
    
   
    const active_booth_file = props.main.active_booth_file

    return (
        <Box sx={{ flexGrow: 1, zIndex:2}}>
            <AppBar position="fixed">
                <Toolbar>
                    <Box>
                        <Box style={{textAlign:'left', paddingLeft: 0, paddingBottom:3, fontSize:13, fontWeight:'bold'}}>{props.main.saveFileName?props.main.saveFileName:(active_booth_file?active_booth_file.filename:"")}</Box>
                        <Box display="flex" pl={1}>
                            <Tooltip title="Zoom In">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={props.dollyIn}
                                    // sx={{ mr: 2 }}
                                >
                                    <ZoomInIcon />
                                </ColorButton>
                            </Tooltip>     
                            <Tooltip title="Zoom Out">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={props.dollyOut}
                                    // sx={{ mr: 2 }}
                                >
                                    <ZoomOutIcon />
                                </ColorButton>
                            </Tooltip>         
                            <Tooltip title="Toggle View">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={props.swichViewAngle}
                                    // sx={{ mr: 2 }}
                                >
                                    <CameraswitchIcon />
                                </ColorButton>
                            </Tooltip>         
                            <Tooltip title="Auto Fit on Screen">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={props.swichFullView}
                                    // sx={{ mr: 2 }}
                                >
                                    <FullscreenIcon />
                                </ColorButton>
                            </Tooltip>  
                            <Tooltip title="2D"> 
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>props.to2D(true)}
                                    // sx={{ mr: 2 }}
                                >
                                    2D
                                </ColorButton>
                            </Tooltip>                     
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <BoothImportModal
                open={openBoothImportModal}
                onClose={handleCloseBoothImportModal}
            />
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={snackMsg}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
        </Box>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
      main: state.main,
      booth_history: state.booth_history.present,
      canUndo: state.booth_history.past.length > 1,
      canRedo: state.booth_history.future.length > 0
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {    
        setViewData: data=>dispatch(setViewData(data)),
        setViewFile: filename=>dispatch(setViewFile(filename)),
        setBoothList: data=>dispatch(setBoothList(data)),
        setBoothFileList: data=>dispatch(setBoothFileList(data)),
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        onUndo: () => dispatch(UndoActionCreators.undo()),
        onRedo: () => dispatch(UndoActionCreators.redo()),
        onClearHistory: () => dispatch(UndoActionCreators.clearHistory()),        
        setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),    
        setLoading:  data=>dispatch(setLoading(data)),
        to2D:  data=>dispatch(to2D(data))        
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(ToolBox);

  



