import React ,{ useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import Box from '@mui/material/Box'; 
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import FontPicker from "font-picker-react";
import { connect } from 'react-redux'
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import {PaperComponent} from '../../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function FontPickerModal(props) {
  const { onClose,  open } = props;
  var mainLayers = props.main.mainLayers;
  const [activeFontFamily, setFont] = useState("Open Sans");

  useEffect(() => {
  }, [])
  
  const onChange = (e)=>{
    onChange(e)
  }
  const onOk = (e)=>{
    props.onOk(activeFontFamily)
  }

  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'fontsize-dialog-title'}}
      aria-labelledby="fontsize-dialog-title" 
      onClose={onClose} open={open}>
      <DialogTitle id='fontsize-dialog-title' style={{display:'flex', alignItems:'center'}}><FontDownloadOutlinedIcon style={{marginRight: 10}}/> Font Family</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        <Box style={{height: 215}}>
          <FontPicker
            apiKey="AIzaSyDPtqHOkdnMVZb6wrHcyz1Mwyn0Au-HCbI"
            activeFontFamily={activeFontFamily}
            onChange={(nextFont) => setFont(nextFont.family)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(FontPickerModal);