import React ,{ useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import './App.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToolBox from './viewer/ToolBox';
import axiosInstance from './utils/axiosInstance.js'
import { setAuthInfo, setLoading} from './actions/main'
import { parseToken, getTokenCookie, getTokenPlanner, setCookie, getUrlToken } from './utils/Common'
import { setCustomStyles } from './actions/ui'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import qs, { parse } from 'qs'
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setOpenLogin, setOpenSignup, setOpenResetPassword, setSnackMsg, setOpenSnack,setBookmardUpdated, setOpenNewPassword } from './actions/ui'
import { useParams } from 'react-router-dom'
import { API_URL, ESHOW_URL } from './config.js'
import Notfound from './viewer/component/Notfound.js';
import FadeLoader from 'react-spinners/FadeLoader'

function useQuery() {
    const { search } = useLocation();  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

var magicVerficationTimer = false
var magicVerifyInfo = false
var verifyCnt = 0;
function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();
  const [data, setData] = useState([]);
  const [formValue, setFormValue] = React.useState({});
  const [bookmarks, setBookmarks] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [startVerification, setStartVerification] = React.useState(false);

  var urlTokenVal = getUrlToken()
  const fetchData = ()=>{
    props.setLoading(true)
    const _token = getTokenPlanner();
    var url = `${ESHOW_URL()}?method=getPlanner`
    
    const options = {
      headers: {
        'Authorization': `Bearer ${_token}`
      }
    } 
    axios.get(url, options).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
          if(result.PLANNER){
            var tmp = {...result.PLANNER}
            tmp.PASSWORD = ""
            setFormValue(result.PLANNER)
            setBookmarks(result.BOOKMARKS)
          }
        }
        else{
          setFormValue({})
        }
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {    
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    setTabValue(0)
    fetchData()
  }, [id5, id4, id3, id2, id1, props.main[`planner-${urlTokenVal}`]])


  const startMagicVerfication = ()=>{
    magicVerficationTimer = setInterval(()=>{
      checkMagicVerfication()
    }, 4000);
  }
  const stopMagicVerfication = ()=>{
    setStartVerification(false)
    if(magicVerficationTimer){
      clearInterval(magicVerficationTimer)
      magicVerficationTimer = false;
    }
  }
  const checkMagicVerfication=()=>{
    verifyCnt++;
    if(verifyCnt == 225){ //15min * 60/4
      stopMagicVerfication()
      props.setOpenSnack(true);
      props.setSnackMsg("Failed to verify");
    }
    var postData = 
    {
      EMAIL: formValue.EMAIL,
    }
    const _token = getTokenPlanner();
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${_token}`
      } 
    }
    var url = `${ESHOW_URL()}?method=putUpdateEmail&token=${getTokenPlanner()}&key_id=${magicVerifyInfo.KEY_ID}`
    // var url = `${ESHOW_URL()}?method=putUpdateEmail&token=${getTokenCookie()}&access_type=verify&key_id=azuresnow55@gmail.com`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true && eshowResponse.DATA?.STATUS == 'Approved'){
          stopMagicVerfication()
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Successfully Verified");          
        }
        else{
          if(eshowResponse.DATA?.STATUS != 'Pending'){
            stopMagicVerfication()
            props.setOpenSnack(true);
            props.setSnackMsg("Failed to verify");
          }
        }
        // setProcessing1(false)
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  const onChangeValue = (e)=>{
    setFormValue({...formValue, [e.target.name]: e.target.value});
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    var postData = 
    {
      COMPANY_NAME: formValue.COMPANY_NAME?formValue.COMPANY_NAME:'',
      CITY: formValue.CITY?formValue.CITY:'',
      COUNTRY: formValue.COUNTRY?formValue.COUNTRY:'',
      FIRST_NAME: formValue.FIRST_NAME?formValue.FIRST_NAME:'',
      LAST_NAME: formValue.LAST_NAME?formValue.LAST_NAME:'',
      TITLE: formValue.TITLE?formValue.TITLE:'',
      EMAIL: formValue.EMAIL?formValue.EMAIL:'',
      ZIP_CODE: formValue.ZIP_CODE?formValue.ZIP_CODE:'',
      PHONE: formValue.PHONE?formValue.PHONE:'',
      STATE: formValue.STATE?formValue.STATE:'',
      KEY_ID: formValue.KEY_ID,
      ADDRESS1: formValue.ADDRESS1?formValue.ADDRESS1:'',
      ADDRESS2: formValue.ADDRESS2?formValue.ADDRESS2:''
    }
    const _token = getTokenPlanner();
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${_token}`
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner`
    axios.post(url, JSON.stringify(postData), options).then(async res=>{
      const result = res.data;
      if(result.SUCCESS == true){
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Successfully updated");
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Failed to update");
      }
      // props.setViewFile(filename);
    }).catch(error => {
        console.log("error", error);
    })
  }

  const handleSubmitPassword = (e)=>{
    e.preventDefault();
    
    if(!formValue.PASSWORD || formValue.PASSWORD==""||!formValue.CURRENT_PASSWORD || formValue.CURRENT_PASSWORD==""){
      props.setOpenSnack(true);
      props.setSnackMsg("Invalid password");
      return false;
    }
    if(formValue.PASSWORD && formValue.PASSWORD!="" && formValue.PASSWORD != formValue.confirm_password){
      props.setOpenSnack(true);
      props.setSnackMsg("Invalid password");
      return false;
    }
    var postData = 
    {
    }
    postData.PASSWORD = formValue.PASSWORD
    postData.CURRENT_PASSWORD = formValue.CURRENT_PASSWORD
    const _token = getTokenPlanner();
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${_token}`
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner`
    axios.post(url, JSON.stringify(postData), options).then(async res=>{
      const result = res.data;
      if(result.SUCCESS == true){
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Successfully updated");
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Failed to update");
      }
      // props.setViewFile(filename);
    }).catch(error => {
        console.log("error", error);
    })
  }

  const handleSubmitVerification = (e)=>{
    e.preventDefault();
    
    if(!formValue.EMAIL){
      props.setOpenSnack(true);
      props.setSnackMsg("Invalid email");
      return false;
    }
    var postData = 
    {
    }
    postData.EMAIL = formValue.EMAIL
    const _token = getTokenPlanner();
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${_token}`
      }
    }
    var url = `${ESHOW_URL()}?method=putUpdateEmail`    
    setStartVerification(true)
    axios.post(url, JSON.stringify(postData), options).then(async res=>{
      const result = res.data;
      if(result.SUCCESS){
        magicVerifyInfo = (result.DATA)
        startMagicVerfication()
      }
      // props.setViewFile(filename);
    }).catch(error => {
        console.log("error", error);   
        setStartVerification(false)        
    })
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  
  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null
  return (
    <div id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box style={{maxWidth: 1200, margin:'64px auto', paddingTop: 60, marginTop:(banner && banner != ''?(150+64):64)}}>
        
        {(props.main[`planner-${urlTokenVal}`] && props.main[`planner-${urlTokenVal}`].TOKEN) ?
        <Paper  elevation={2} style={{padding:'20px'}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label={<b>My Profile</b>} {...a11yProps(0)} />
              <Tab label={<b>Bookmarks</b>} {...a11yProps(1)} />
              <Tab label={<b>Password</b>} {...a11yProps(2)} />
              <Tab label={<b>Update Email</b>} {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <form style={{padding:15}} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>        
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="COMPANY_NAME"
                    margin="dense"
                    label="Company"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.COMPANY_NAME?formValue.COMPANY_NAME:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12} sm={6}>    
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="EMAIL"
                    margin="dense"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.EMAIL?formValue.EMAIL:""}
                    onChange={onChangeValue}
                    size="small"
                    disabled={true}
                  />
                </Grid>                    
                <Grid item xs={12} sm={6}>    
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="FIRST_NAME"
                    margin="dense"
                    label="First Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.FIRST_NAME?formValue.FIRST_NAME:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12} sm={6}>    
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="LAST_NAME"
                    margin="dense"
                    label="Last Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.LAST_NAME?formValue.LAST_NAME:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>                    
                <Grid item xs={12} sm={6}>    
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="TITLE"
                    margin="dense"
                    label="Title"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.TITLE?formValue.TITLE:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12}>      
                  <Box mt={2} textAlign='right'>
                    <Button variant="contained" color="primary" size="large" type="submit">
                      Update Planner
                    </Button>
                  </Box>
                </Grid> 
              </Grid>   
            </form>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>  
                <Box mt={4}>
                  <Box display="flex"  style={{justifyContent:'space-between', padding:'10px 16px', borderBottom:'1px solid #e0e0e0', background:props.ui?.customStyles?.['left-table-header-bg']?props.ui?.customStyles['left-table-header-bg']:'#4e7090', color:'white', alignItems:'center'}}>
                    <Box style={{width: '70%', textAlign:'left', paddingLeft: '1rem'}}><b>Exhibitor</b></Box>
                    <Box style={{width: '30%', textAlign:'left', paddingLeft: '12px'}}><b><LocationOnOutlinedIcon/></b></Box>
                  </Box>
                  <Box style={{maxHeight:'400px', overflowY:'auto', borderBottom:'1px solid #e0e0e0', borderLeft:'1px solid #e0e0e0', borderRight:'1px solid #e0e0e0'}}>
                    {bookmarks&&
                      <>
                        {
                          bookmarks.map((bookmark, key)=>{
                            return(
                              <Box key={key} display="flex"  style={{justifyContent:'space-between', padding:'10px 16px',alignItems:'center'}}>
                                <Box style={{width: '70%', textAlign:'left', paddingLeft: '1rem'}}>{(bookmark.COMPANY_NAME?(` ${bookmark.COMPANY_NAME}`):"")}</Box>
                                <Box style={{width: '30%', textAlign:'left', paddingLeft: '12px'}}>{(bookmark.BOOTHS?bookmark.BOOTHS:"")}</Box>
                              </Box>                              
                            )
                          })
                        }
                      </>
                    }
                  </Box>
                </Box>
              </Grid>    
            </Grid>   
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <form onSubmit={handleSubmitPassword} style={{maxWidth: 650, margin:'auto', padding:15}}>
              <Grid container spacing={2}>                
              <Grid item xs={12} sm={12}>        
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="CURRENT_PASSWORD"
                    margin="dense"
                    label="Current Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    onChange={onChangeValue}
                    autoComplete="new-password"
                    autocomplete="new-password"
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12} sm={12}>        
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="PASSWORD"
                    margin="dense"
                    label="New Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    onChange={onChangeValue}
                    autoComplete="new-password"
                    autocomplete="new-password"
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12} sm={12}>       
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="confirm_password"
                    margin="dense"
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12}>      
                  <Box mt={2} textAlign='right'>
                    <Button variant="contained" color="primary" size="large" type="submit">
                      Update Password
                    </Button>
                  </Box>
                </Grid> 
              </Grid>   
            </form>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <form style={{padding:15}} onSubmit={handleSubmitVerification}>
              <Grid container spacing={2}>   
                <Grid item xs={12} sm={6}>    
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="EMAIL"
                    margin="dense"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.EMAIL?formValue.EMAIL:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12}>      
                  <Box mt={2} textAlign='right'>
                    <Button variant="contained" color="primary" size="large" type="submit" style={{display:'flex', alignItems:'center'}}>
                      {startVerification?
                        <>                          
                          <div style={{lineHeight:0.85}}><ScaleLoader color={'white'} radius={1} margin={1} height={17} width={2}/></div>
                          <span style={{marginLeft:'15px'}}>Verify Email</span>
                        </>:
                        <>
                          Send Verification Link
                        </>
                      }

                    </Button>
                  </Box>
                </Grid> 
              </Grid>   
            </form>
          </TabPanel>
        </Paper>:
        <Notfound/>
        }
        {props.ui?.customStyles?.['loaded'] &&
        <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay>
        } 
      </Box>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setSnackMsg: data=>dispatch(setSnackMsg(data)),
      setOpenSnack: data=>dispatch(setOpenSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
