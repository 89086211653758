import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { countries, API_URL, ESHOW_URL } from '../../config.js'
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import BusinessIcon from '@mui/icons-material/Business';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import { pink } from '@mui/material/colors';
import qs, { parse } from 'qs'
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import  area  from 'area-polygon'
import { setObjectBG, px2unit, unit2Px, addText, checkCombinable, checkContainSold, getStrokeWidth, groupApartObjects } from '../../utils/CanvasTool'
import { getDefaultGroup, safeTrim, buildObjectProperty } from '../../utils/Common'
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import { uuid4 } from './sketch_right/utils';
import { v4 as uuidv4 } from 'uuid';
import { getTokenCookie } from '../../utils/Common'
import { fabric } from "fabric";
import {canvasApp, helperCanvas} from '../../utils/CanvasApp'
import {PaperComponent} from '../../utils/Common'
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import moment from 'moment';
import Popper from '@mui/material/Popper';
let GlobalPayments = window.GlobalPayments;
const emails = ['username@gmail.com', 'user02@gmail.com'];
let cardForm = false
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


var timeout = false;
var g_searchVal = ""
var combinedGroup = null;
var groupedItem = null;
var g_company = null;
var g_contact = null;
function AttachModal(props) {
  const { onClose, onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  
  const [snackMsg, setSnackMsg] = React.useState('');
  const [openSnack, setOpenSnack] = React.useState(false);
  const [sales_form, setSalesForm] = React.useState('');
  const [step, setStep] = React.useState(1);
  const [autoCombine, setAutoCombine] = React.useState(false)
  const [company, setCompany] = React.useState(null);
  const [contact, setContact] = React.useState(null);
  const [orgCompany, setOrgCompany] = React.useState(null);
  const [orgContact, setOrgContact] = React.useState(null);
  const [companies, setCompanies] = React.useState([]);  
  const [allContacts, setAllContacts] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [saleforms, setSaleforms] = React.useState([]);
  const [saleitems, setSaleitems] = React.useState([]);
  const [boothSaleItems, setBoothSaleItems] = React.useState([]);
  const [eshowExhibitor, setEshowExhibitor] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState("");
  const [searchContactVal, setSearchContactVal] = React.useState("");
  const [boothObjects, setBoothObjects] = React.useState([]);
  const [searchedList, serSearchedList] = React.useState([]);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [loadingFlag, setLoadingFlag] = React.useState(false);
  const [globalPayToken, setGlobalPayToken] = React.useState(false);
  const [paymentResult, setPaymentResult] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [salesItemModel, setSalesItemModel] = React.useState(false);
  const [editSalesItem, setEditSalesItem] = React.useState(false);  
  const [selectedItems, setSelectedItems] = React.useState([]);
  
  var cart_show = false
  if(props.main.authInfo?.SHOPPING_CART  == 'show' || props.main.authInfo?.SALES?.SHOPPING_CART == 'show'){
      if(props.main.cartList?.length)
          cart_show = true
  }

  const styles = (theme) => ({
    popper: {
       width: "fit-content"
    }
  });
  
  const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };

  
  const filter = createFilterOptions();
  let query = useQuery();

  // const companies = [
  //   { label: 'The Shawshank Redemption', year: 1994 },
  //   { label: 'The Godfather', year: 1972 },
  // ];
  const handleChangeSalesForm = (event) => {
    setSalesForm(event.target.value);
  };

  useEffect(() => {
    if((props.boothTexts&& props.boothTexts.length > 1)){
      setStep(1)
    }
    else{
      setStep(2)
    }
    setAutoCombine(false)
    setSearchVal("")
    setSearchContactVal("")
    serSearchedList([])
  }, [open])

  
  useEffect(() => {
    g_searchVal = searchVal
  }, [searchVal])

  useEffect(() => {
    g_company = company
  }, [company])

  useEffect(() => {
    g_contact = contact
  }, [contact])
  
  useEffect(() => {
    if(globalPayToken){
      cardForm = window.GlobalPayments.ui.form({
        fields: {
           "card-number": {
              placeholder: "•••• •••• •••• ••••",
              target: "#card-number"
           },
           "card-expiration": {
              placeholder: "MM / YYYY",
              target: "#card-expiration"
           },
           "card-cvv": {
              placeholder: "•••",
              target: "#card-cvv"
           },
           "card-holder-name": {
              placeholder: "Jane Smith",
              target: "#card-holder-name"
           },
           "submit": {
              target: "#submit-button",
              value: "Pay Now",
           }
        },
        styles: {
          // add custom CSS styling
          "input[type=text]": {
            "margin-bottom": "20px",
            "padding": "12px",
            "border": "1px solid #ccc",
            "border-radius": "10px",
          },
            
            //adding focus on the fields when selected
          "input[type=text]:focus-visible, input[type=tel]:focus-visible": {
            "outline": "none !important",
            "border": "1px solid #71C5E8",
            "box-shadow": "0 0 4px 0 #71C5E8 inset",
          },
        
            //adding a radius on all number inputs
          "input[type=tel]": {
            "margin-bottom": "20px",
            "padding": "12px",
            "border": "1px solid #ccc",
            "border-radius": "10px"
          },
            
            //adding focus on the fields when selected
          "button[type=button]:focus-visible": {
            "background-color": "#71C5E8",
            "outline": "none !important",
            "border": "1px solid gray",
            "box-shadow": "0 -1px 4px 0 gray inset",
          },
        
            //adding styling to the submit button
          "button[type=button]": {
            "background-color": "#00AEEF",
            "color": "white",
            "padding": "12px",
            "margin": "10px",
            "border": "none",
            "border-radius": "10px",
            "cursor": "pointer",
            "font-size": "17px",
          },
        
            //adding hover color
          "button[type=button]:hover": {
            "background-color": "#71C5E8",
          }
        }
      });

      window.GlobalPayments.configure({
        accessToken: globalPayToken.token,
        apiVersion: "2021-03-22",
        env: "sandbox", // sandbox or "production"
        // merchantId:'MER_7e3e2c7df34f42819b3edee31022ee3f',
      });
      // method to notify that hosted fields have been initialized
      cardForm.ready(() => {
          console.log("Registration of all credit card fields occurred");
          
          //TODO: Add your successful message
      });
      
      // appending the token to the form as a hidden field and
      // submitting it to the server-side
      cardForm.on("token-success", async (resp) => {
          console.log("token-success", resp)
          // add payment token to form as a hidden input
          const token = document.createElement("input");
          // token.type = "hidden";
          // token.name = "payment-reference";
          // token.value = resp.paymentReference;
          // const form = document.getElementById("payment-form");
          // form.appendChild(token);
          let cartList = props.main.cartList
          console.log("cartList", cartList)
          if(props.assignType == 'sponsorships'){
            for(let i = 0; i < cartList?.length; i++){
              if(cartList[i]?.[0].VALUE == 'Total'){
                total= cartList[i][1].VALUE.replace("$","").replaceAll(",","")*1;
              }
            }
          }

          var formdata = 
            {
              paymentId:resp.paymentReference,
              paymentAmount: total,
              token: globalPayToken.token
            }
            var data = qs.stringify({
                request: JSON.stringify(formdata)
              });
            const options = {
                headers: {"content-type": 'application/x-www-form-urlencoded'}
            }
            setLoadingFlag(true)
            var response = await axiosInstance.post(`/processtransactions?token=${getTokenCookie()}`, data, options);
            if(response.status == 200){
              let data = response.data;
              if(data.status == "success"){
                // setSnackMsg("Payment succeed");
                // console.log("Payment succeed", data)
                // setPaymentResult(data)
                // setOpenSnack(true);
                setLoadingFlag(false)
                onOk()
              }
              else{
                setErrorMessage("Payment failed");
                setLoadingFlag(false)
              }
            }
            console.log("response", response)
            // setSnackMsg(reason);
            // setOpenSnack(true);
          // form.submit();
      });
    
      // add error handling if token generation is not successful
      cardForm.on("token-error", (resp) => {
          // TODO: Add your error handling
          console.log("token-error", resp)
          if(resp.error && resp.reasons[0]){
            let reason =  resp.reasons[0].message;
            setErrorMessage(reason);
          }
          setLoadingFlag(false)
      });
      cardForm.on("submit", "click", (resp) => {
        setLoadingFlag(true)
      });
    
      // field-level event handlers. example:
      cardForm.on("card-number", "register", () => {
          console.log("Registration of Card Number occurred");
              
      });
    }
  }, [globalPayToken])
  
  


  
  const getEshowBoothPrices = async(member_rate, eshowExhibitorParam = false)=>{
    let eshowExhibitorLocal = {...eshowExhibitor}
    if(eshowExhibitorParam){
      eshowExhibitorLocal = {...eshowExhibitorParam}
    }
    var newCompany = {...g_company}
    var newContact = {...g_contact}
    newContact.PARENT_KEY = newCompany.KEY_ID;
    var boothFileList = props.main.boothFileList;
    var activeFile
    for(var i = 0; i <boothFileList.length; i++){
        if(boothFileList[i]['active']){
            activeFile = boothFileList[i];
            break;
        }
    }
    var formdata = 
    {
        company:newCompany,
        contacts:newContact?[newContact]:[],
        sales_form:sales_form,
        booth_sale_items: boothSaleItems,
        booth_id: activeFile?.['id'],
        boothObjects:props.boothObjects
    }

    var groupedItem = combinedGroup
    var company = formdata.company
    var contacts = formdata.contacts
    var boothObjects = formdata.boothObjects
    var primary_booth = "";
    var booth_sale_items = formdata.booth_sale_items
    var booths = []
    var sales = []
    var exhibitor_contacts = []
    for(var i = 0; i < boothObjects?.length; i++){
        var booth = boothObjects[i];
        booths.push({
            BOOTH_NO: booth.booth_number,
            BOOTH_KEY: booth.KEY_ID,
            KEY_ID: uuidv4(),
        })
        if(primary_booth == ""){
            primary_booth = booth.booth_number
        }
        if(booth.booth_number*1 < primary_booth*1){
            primary_booth = booth.booth_number
        }
    }
    if(eshowExhibitorLocal?.SALES?.length){
      sales = [...eshowExhibitorLocal.SALES]
    }
    else{
      for(var i = 0; i < booth_sale_items.length; i++){
          if(booth_sale_items[i] && booth_sale_items[i].length > 0){
            for(var j = 0; j < booth_sale_items[i].length; j++){
              var booth_sale_item = booth_sale_items[i][j]
              sales.push({
                  KEY_ID:uuidv4(),
                  TYPE:booth_sale_item.discountFlag?"704":"407",
                  ACTIVE:"1",
                  PRICE_KEY:booth_sale_item.saleitem_id,
                  AMOUNT:(booth_sale_item.discountFlag?(-1):1)*booth_sale_item.saleitem_total,
                  QTY:booth_sale_item.saleitem_qty,
                  TITLE:booth_sale_item.saleitem_name,
                  PRODUCT_KEY:booth_sale_item.saleitem_product_id,
                  TOTAL:(booth_sale_item.discountFlag?(-1):1)*booth_sale_item.saleitem_total,
                  BOOTH_KEY:booth_sale_item.boothObject?.KEY_ID,
                  BOOTH_NUMBER:booth_sale_item.boothObject?.booth_number,                
                  BOOTH_SIZE:`${getBoothSize(booth_sale_item.boothObject)?.booth_width}x${getBoothSize(booth_sale_item.boothObject)?.booth_height}`,
                  BOOTH_SQFT:`${`${props.main?.mainCanvas?.mapUnit == 'feet'?'SqFt':'SqM'}`}: ${getSqrt(booth_sale_item.boothObject).toFixed(2)}`
              })
            }
          }
      }
    }
    // console.log("sales", sales); return;
    for(var i = 0; i < contacts.length; i++){
        var contact = contacts[i]
        exhibitor_contacts.push({
            ROLE_TYPE:contact.ROLE_TYPE?contact.ROLE_TYPE:"11",
            ACTIVE:1,
            PHONE_EXT:contact.PHONE_EXT?contact.PHONE_EXT:"",
            PHONE:contact.PHONE?contact.PHONE:"",
            KEY_ID:contact.KEY_ID?contact.KEY_ID:"",
            LAST_NAME:contact.LAST_NAME?contact.LAST_NAME:"",
            EMAIL:contact.EMAIL?contact.EMAIL:"",
            FIRST_NAME:contact.FIRST_NAME?contact.FIRST_NAME:"",
            CREDENTIALS:contact.CREDENTIALS?contact.CREDENTIALS:"",
            CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
            TITLE:contact.TITLE?contact.TITLE:"",
            CRM_CONTACT_KEY:contact.KEY_ID?contact.KEY_ID:"",
            TYPE:contact.TYPE?contact.TYPE:"605"
        })
    }
    var exhibitor_key = uuidv4().toUpperCase()
    var exhibitor = {
        ZIP_CODE:company.ZIP_CODE?company.ZIP_CODE:"",
        PHONE:company.PHONE?company.PHONE:"",
        STATE:company.STATE?company.STATE:"",
        STATUS:company.STATUS?company.STATUS:"3",
        COUNTRY:company.COUNTRY?company.COUNTRY:"",
        GROUP_KEY:exhibitor_key,
        ADDRESS1:company.ADDRESS1?company.ADDRESS1:"",
        ADDRESS2:company.ADDRESS2?company.ADDRESS2:"",
        DISPLAY_ORDER:company.DISPLAY_ORDER?company.DISPLAY_ORDER:"1",
        KEY_ID:exhibitor_key,
        PRIMARY_BOOTH:primary_booth,
        COMPANY_NAME:company.COMPANY_NAME?company.COMPANY_NAME:"",
        FORM_KEY:active_booth_file?.sales_form,
        CITY:company.CITY?company.CITY:"",
        BOOTH_SF:"100",
        ORDER_NUMBER:"",
        CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
        WEBSITE:"",
        BOOTHS:booths,
        CONTACTS:exhibitor_contacts,
        SALES: sales,
        MEMBER_RATE:member_rate,
        APPLICATION_DATE:company.APPLICATION_DATE?moment(company.APPLICATION_DATE).format("MM/DD/YYYY"):"",
        DISCOUNT_CODE:company.DISCOUNT_CODE?company.DISCOUNT_CODE:"",
    };
    var booths = [];
    if(groupedItem){
        booths = await props.onSaveIndividuals([groupedItem], null, props.boothObjects, "COMBINE", true)
    }
    else{           
        mainCanvas.discardActiveObject();             
        booths = await props.onSaveIndividuals(null, props.boothObjects, null, false, true)
        var sel = new fabric.ActiveSelection(props.boothObjects, {
            canvas: mainCanvas,
        });
        mainCanvas.setActiveObject(sel).renderAll();
    }
    var postData = {
        EXHIBITOR: exhibitor,
        BOOTHS: booths,
        SUCCESS: true,
    }
    if(groupedItem){
        postData.METHOD = "COMBINE"
    }
    console.log("postData", postData)
    const options = {
        headers: {
            'body': '[{"company:","test"}]', 
            'Content-Type': 'application/json'
          }
    }
    setLoadingFlag(true)
    var url = `${ESHOW_URL()}?method=getBoothPricing&token=${getTokenCookie()}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
            setEshowExhibitor(eshowResponse.EXHIBITOR)
        }
        else{
            setOpenSnack(true);
            setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to get price");
        }   
        setLoadingFlag(false)
    }).catch(error => {
        console.log("error", error);
    })
  }

  const getEshowPrices = async(member_rate, eshowExhibitorParam) =>{
    if(props.assignType == 'sponsorships'){
    }
    else{
      getEshowBoothPrices(member_rate,eshowExhibitorParam)
    }
  }

  const groupItemsPreview = (newBoothNumber=null, fromUndo, org_id)=>{
    if(!autoCombine && document.getElementById("previewSvg")) {
      document.getElementById("previewSvg").innerHTML = "";
      return;
    }
    var convertRateX= 1
    var convertRateY= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }
    var unitRate = 12; //1feet = 12inch
    if(mainCanvas.mapUnit == 'meter'){
      unitRate = 1;
    }
    var feetUnitSize = 1;       

    if(convertRateX){
      feetUnitSize = (unitRate/convertRateX)
    }

    if (mainCanvas && !mainCanvas.getActiveObject()) {
        return;
    }
    if (mainCanvas.getActiveObject().type !== 'activeSelection') {
        return;
    }
    var newBooths = [];
    var groupobject = mainCanvas.getActiveObject();
    var apartFlag = false;//checkifApart(groupobject);
    var objects = groupobject.getObjects();
    
    if(!checkCombinable(objects)){
        setSnackMsg("Failed to combine");
        setOpenSnack(true);
        return 
    }
    
    
    {        
        canvasApp.helperCanvas = mainCanvas
        var outpPutFontSize = 20
        var group = canvasApp.combineHelper.openCombineWindow(true,props.grouppingBooth);
        if(group){
          var rateX = 200/(group.width);
          var rateY = 150/(group.height);
  
          var fontSize = outpPutFontSize/Math.min(rateX, rateY)
          var textObjects = group.getObjects('text')
          for(var j = 0; j<  textObjects.length; j++){
              textObjects[j].set({fontSize:50/Math.max(convertRateX, 1)})
          }
          mainCanvas.renderAll()
          var decimal = 0;
          if((group.width)/feetUnitSize < 1) decimal = 2
        
          
          var f_text1 = new fabric.Text(px2unit(group.width, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
            left: 0,
            top: 0,
            fontSize:fontSize,
            class:'size'
          });   
          f_text1.left =  0 - f_text1.width/2
          f_text1.top =  0 - group.height/2 - f_text1.height*1.2
          group.add(f_text1);
          var f_text2 = new fabric.Text(px2unit(group.height, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
              left: 0,
              top: 0,
              fontSize:fontSize,
              class:'size'
          });    
          f_text2.left =  0+group.width/2+f_text2.height*0.2
          f_text2.top =   0 - f_text1.height/2
          group.add(f_text2);
          group.clipPath = null

          var drawermain = SVG();
          

          group.scaleX = Math.min(rateX, rateY)
          group.scaleY = Math.min(rateX, rateY)
          group.left = 552/2 - (group.width)*group.scaleX/2

          group.top = (f_text1.height)*group.scaleX;

          drawermain.svg(group.toSVG())
          console.log("group.toSVG()", group.toSVG())

          setTimeout(function(){
            if(document.getElementById("previewSvg")){
              document.getElementById("previewSvg").innerHTML = "";
            }
            drawermain.remove().addTo('#previewSvg').size('100%', '100%')
          },100)
        }
        if(group == false){
          apartFlag = true;
        }
        combinedGroup = group
    }
    if(checkContainSold(objects) && apartFlag){
        setSnackMsg("Failed to combine");
        setOpenSnack(true);
        return 
    }
    if(apartFlag){      
      newBoothNumber = "";
      newBoothNumber = props.grouppingBooth
      var group = groupApartObjects(groupobject, mainCanvas, props.main.groupOptions, newBoothNumber, org_id)
      if(0){
        var orgObject;
        var objectsInGroup = groupobject.getObjects();
        var booth_numbers = [];
        for(var i = 0; i < objects.length; i++){
          booth_numbers.push(objects[i].booth_number);
        }
        booth_numbers.sort(function(a, b) {
          if (a < b) {
              return -1;
          }
          if (a > b) {
              return 1;
          }
          
          // names must be equal
          return 0;
        })
        newBoothNumber = "";
        newBoothNumber = props.grouppingBooth

        objectsInGroup.forEach(function(object) {
          if(object.booth_number == newBoothNumber){
            orgObject = object; return;
          }
        });
        var boothId = "G_"+parseInt(Math.random()*(10000))

        var bgColor = "#dadada"
        var element = new fabric.Polygon(
          [
            {x: 0, y:0},
            {x: groupobject.width, y:0},
            {x: groupobject.width, y:groupobject.height},
            {x: 0, y:groupobject.height},
            {x: 0, y:0}
          ]
          , {
            class:"outline",
            left: groupobject.left,
            top: groupobject.top,
            fill: bgColor, 
            stroke: 'black',
            strokeWidth: getStrokeWidth(mainCanvas),
            borderColor: 'black',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
        });
        
        element.set({
            borderColor: '#ff7777',
            cornerColor: 'red',
            cornerSize: 6,
            transparentCorners: false
        });

        var group = new fabric.Group([element]).set({
            width: groupobject.width,
            height: groupobject.height,
            id: org_id?org_id:'booth-'+boothId,
            class: 'booth',
            boothGroup: getDefaultGroup(props.main.groupOptions),
            class1 : 'booth-group',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
        });	
        group.set({
            borderColor: '#ff7777',
            cornerColor: 'red',
            cornerSize: 6,
            transparentCorners: false,
            layer:'booth',
            booth_number:props.grouppingBooth?props.grouppingBooth:newBoothNumber
        });     
        
				group.set(buildObjectProperty(orgObject));   
      
        var newText = (orgObject.company?(orgObject.company+"\n"):"")+(props.grouppingBooth?props.grouppingBooth:newBoothNumber)
        addText(mainCanvas, group, newText)
      }
        if(group){
          var rateX = 200/(group.width);
          var rateY = 150/(group.height);
  
          var fontSize = outpPutFontSize/Math.min(rateX, rateY)
          var textObjects = group.getObjects('text')
          for(var j = 0; j<  textObjects.length; j++){
              textObjects[j].set({fontSize:50/Math.max(convertRateX, 1)})
          }
          mainCanvas.renderAll()
          var decimal = 0;
          if((group.width)/feetUnitSize < 1) decimal = 2
        

          var f_text1 = new fabric.Text(px2unit(group.width, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
            left: 0,
            top: 0,
            fontSize:fontSize,
            class:'size'
          });   
          f_text1.left =  0 - f_text1.width/2
          f_text1.top =  0 - group.height/2 - f_text1.height*1.2
          group.add(f_text1);
          var f_text2 = new fabric.Text(px2unit(group.height, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
              left: 0+group.width/2,
              top: 0,
              fontSize:fontSize,
              class:'size'
          });    
          f_text2.top =   0 - f_text1.height/2
          group.add(f_text2);
          group.clipPath = null

          var drawermain = SVG();
          

          group.scaleX = Math.min(rateX, rateY)
          group.scaleY = Math.min(rateX, rateY)
          group.left = 300/2 - (group.width)*group.scaleX/2

          group.top = (f_text1.height)*group.scaleX;

          drawermain.svg(group.toSVG())
          console.log()
          combinedGroup = group

          setTimeout(function(){
            document.getElementById("previewSvg").innerHTML = "";
            drawermain.remove().addTo('#previewSvg').size('100%', '100%')
            
          },100)
        }
    }
    
  }
  
  const updateBoothSaleItems = (booths, boothObjects)=>{
    setBoothObjects(boothObjects)
    var newBoothSaleItems = [];
    if(booths){
      for(var i = 0; i< booths.length; i++){
        var options = [];
        var pricingOptions = props.main.pricingOptions
        var premiumOptions = props.main.premiumOptions
        var discountOptions = props.main.discountOptions
        var total = 0;
        var booth = booths[i]
        var boothObject = boothObjects[i]
        if(booth && boothObject){
          var priceCheck = boothObject.priceCheck
          for(var k = 0; k <priceCheck?.length; k++){
            var row = priceCheck[k]              
            options.push(
              {
                saleitem_name:safeTrim(row.PRICE_LABEL), 
                price_type:row.PRICE_TYPE, 
                saleitem_id:row.PRICE_KEY,
                saleitem_product_id:row.PRODUCT_KEY,
                saleitem_price:row.PRICE, 
                saleitem_qty:1, 
                saleitem_total:(row.PRICE*(safeTrim(row?.PRICE_TYPE) == 'square ft' || safeTrim(row?.PRICE_TYPE) == 'sq_ft' || safeTrim(row?.PRICE_TYPE) == 'SqFt'?getSqrt(boothObject):1)).toFixed(2),
                booth: booths[i],
                type: row.PRICE_CATEGORY,
                boothObject: boothObjects[i],
                default: true,
                discountFlag: row.PRICE_CATEGORY=='discount'
              }
            )
          }
        }

        newBoothSaleItems.push(
          options
        )
      }
      setBoothSaleItems(newBoothSaleItems);
    }
  }
  const handleCloseSnack = ()=>{
    setOpenSnack(false);
}

  const doSearch = (name)=>{
    

    if(name && name != ''){
      if(timeout)
        clearTimeout(timeout);
      setLoadingFlag(true)
      timeout = setTimeout(async ()=> {        
        var url = `${ESHOW_URL()}?method=getCompany&token=${getTokenCookie()}&company_name=${name}`
        axiosInstance.get(url).then(res=>{
          setLoadingFlag(false)
          if(g_searchVal && g_searchVal!=''){
            if(res.data){
              console.log(res.data)
              var result = res.data
              if(result)
                serSearchedList(result)
              else{
                serSearchedList([])
              }
            }
          }
          else{
            serSearchedList([])
            setOpenDropdown(false)
          }
          
        }).catch(error => {
            console.log("error", error);
        })
        setOpenDropdown(true)
      }, 300);
    }
    else{
      serSearchedList([])
      setOpenDropdown(false)
    }
  }

  const doSearchContact = (name = null)=>{
    setLoadingFlag(true)
    if(timeout)
      clearTimeout(timeout);
    timeout = setTimeout(async ()=> {
      if(name && name!= ''){
        var tmp = [];
        for(var i = 0; i < allContacts.length; i++){
          if(((allContacts[i].FIRST_NAME?safeTrim(allContacts[i].FIRST_NAME):"")+" "+(allContacts[i].LAST_NAME?safeTrim(allContacts[i].LAST_NAME):"")).includes(name)){
            tmp.push(allContacts[i])
          }
        }
        setContacts(tmp)
      }
      else{      
        setContacts(allContacts);
      }
      setLoadingFlag(false)
    }, 300);
  }

  useEffect(() => {
    if(open && props.boothTexts && props.boothObjects){
      updateBoothSaleItems(props.boothTexts, props.boothObjects)
    }
  }, [open])

  useEffect(() => {
    if(autoCombine)
      groupItemsPreview();
  }, [props.grouppingBooth])


  useEffect(() => {
    var newMergedBooth = props.grouppingBooth;
    var newMergedBoothObject = false;
    combinedGroup = null
    if(autoCombine){ //groupping
      var booths = props.boothTexts;
      var boothObjects = props.boothObjects;
      if(booths && booths.length > 0){
        if(boothObjects && boothObjects.length > 0){
          for(var i = 0; i < boothObjects.length; i++){
            if(newMergedBooth == boothObjects[i].booth_number){
              newMergedBoothObject = boothObjects[i];
              break;
            }
          }
        }
        groupItemsPreview()
      }
      updateBoothSaleItems([newMergedBooth], [combinedGroup?combinedGroup:newMergedBoothObject]);
    }
    else{
      updateBoothSaleItems(props.boothTexts?props.boothTexts:[], props.boothObjects?props.boothObjects:[]);
    }
  }, [autoCombine,props.grouppingBooth])

  
  useEffect(() => {
    getEshowPrices(company?.MEMBER_RATE)
  }, [company?.APPLICATION_DATE, company?.DISCOUNT_CODE])

  const insertBoothSaleInfo = (boothIndex, rowIndex)=>{ 
    var booths = props.boothTexts
    var boothObjects = props.boothObjects
    var emptyrow = {
      AMOUNT: 0,
      ACTIVE: 1,
      BOOTH_KEY: false,
      BOOTH_NUMBER: false,
      KEY_ID: uuidv4(),
      // PRICE_KEY: false,
      PRODUCT_KEY: false,
      QTY: 1,
      TITLE: '',
      TOTAL: 0,
      TYPE: 407,
      new:true
    }
    var new_eshowExhibitor = {...eshowExhibitor}
    var sales  = new_eshowExhibitor?.SALES; 
    var newSales = [...sales.slice(0, new_eshowExhibitor?.SALES.length), emptyrow, ...sales.slice(new_eshowExhibitor?.SALES.length)]
    new_eshowExhibitor['SALES'] = newSales;
    setEshowExhibitor(new_eshowExhibitor);
  }
  const removeBoothSaleInfo = (rowIndex)=>{
    var new_eshowExhibitor = {...eshowExhibitor}
    var sales = [...new_eshowExhibitor?.SALES]
    sales[rowIndex].ACTIVE = 0;
    // var newSales = [...sales.slice(0, rowIndex), ...sales.slice(rowIndex+1)]
    var newSales = [...sales]
    new_eshowExhibitor['SALES'] = newSales;
    // setEshowExhibitor(new_eshowExhibitor);
    getEshowPrices(company.MEMBER_RATE, new_eshowExhibitor)
  }

  const updateBoothSaleInfo = (rowIndex, key, value)=>{   
    var new_eshowExhibitor = {...eshowExhibitor}
    var sales = [...new_eshowExhibitor['SALES']]
    sales[rowIndex]= {...sales[rowIndex], [key]:value} ;
    if(key == "saleitem_id" && value){
      var saleitems = getBoothSaleItems()
      for(var i = 0; i < saleitems.length; i++){
        // if(saleitems[i].KEY_ID == value.KEY_ID){
        if(saleitems[i].PRICE_KEY == value.PRICE_KEY){
          var price_type = saleitems[i]['COLUMNS']?.[0]?.PRICE_TYPE
          sales[rowIndex]= {
            ...sales[rowIndex], 
            TITLE:saleitems[i].name, 
            PRICE_KEY:saleitems[i].PRICE_KEY,
            PRODUCT_KEY:saleitems[i].PRODUCT_KEY, 
            AMOUNT:(saleitems[i].PRICE*(value?.discountFlag?(-1):(1))).toFixed(2), 
            // AMOUNT:(saleitems[i]['COLUMNS']?.[0]?.PRICE*1).toFixed(2), 
            QTY:1, 
            TOTAL:(saleitems[i].PRICE*1).toFixed(2),
          }  
        }
      }
    }
    new_eshowExhibitor['SALES'] = sales;
    // setEshowExhibitor(new_eshowExhibitor);
    getEshowPrices(company.MEMBER_RATE, new_eshowExhibitor)
  }

  const getSqrt= (booth)=>{
    if(!booth){
      return 0
    }
    var convertRateX= 1
    var convertRateY= 1
    var mainCanvas = props.main.mainCanvas
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }
    var booth_width = 0
    var booth_height = 0
    if(px2unit(booth.width, convertRateX, mainCanvas.mapUnit) >= 5){
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    else{
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    var sqrt = 0;
    if(booth.class1 == "custom-booth"){
        var objects = booth.getObjects('polyline');
        if(objects && objects.length > 0 ){
            var points = objects[0].points;
            var polyline = [];
            for(var i = 0; i < points.length; i++){
                polyline.push([px2unit(points[i].x, convertRateX, mainCanvas.mapUnit), px2unit(points[i].y, convertRateX, mainCanvas.mapUnit)])
            }
            sqrt = area(polyline)
        }
    }
    else{
        sqrt = (booth_width*booth_height)
    }
    return sqrt
  }

  const getBoothSize= (booth)=>{
    if(!booth){
      return 0
    }
    var convertRateX= 1
    var convertRateY= 1
    var mainCanvas = props.main.mainCanvas
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }
    var booth_width = 0
    var booth_height = 0
    if(px2unit(booth.width, convertRateX, mainCanvas.mapUnit) >= 5){
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    else{
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    return{
      booth_width, booth_height
    }
  }

  
  
  const handleAutoCombine=(value)=>{
    if(value){ //groupping
      if(props.grouppingBooth){
        return props.groupItems(props.grouppingBooth, true);
      }
    }
    else{ //ungroupping
      
    }
    // setAutoCombine(value)
    return false
  }

  useEffect(() => {
    if(step == 4){
      getEshowPrices(company.MEMBER_RATE)
      if(cart_show)
        getAccessToken()
    }
    if(step == 1){
      if(autoCombine)
        groupItemsPreview();
    }
  }, [step])
  

  const fetchSaleitems = ()=>{
    axiosInstance.get('/getsaleitems').then(res=>{
      if(res.data){
        console.log(res.data)
        setSaleitems(res.data);
      }
    }).catch(error => {
        console.log("error", error);
    })
  }

  
  const getAccessToken = ()=>{
    axiosInstance.get(`/getaccesstoken?token=${getTokenCookie()}`).then(res=>{
      if(res.data){
        console.log(res.data)
        if(res.data.status == 'success'){
          setGlobalPayToken(res.data.message)
        }
        else{
          setGlobalPayToken(false)
        }
      }
    }).catch(error => {
        console.log("error", error);
    })
  }
  
    
  const fetchContacts = (company_key)=>{
    if(company_key){
      axiosInstance.get(`${ESHOW_URL()}?method=getContact&token=${getTokenCookie()}&crm_company_key=${company_key}`).then(res=>{
        if(res.data){
          console.log(res.data)
          var result = res.data
          if(result){
            setAllContacts(result)
            setContacts(result)
          }
          else{
            setAllContacts([])
            setContacts([])
          }
        }
      }).catch(error => {
          console.log("error", error);
      })
    }
    else{      
      setAllContacts([])
      setContacts([]);
      setContact({
      })
    }
  }

  const setCompanyValue = (key, value)=>{
    var newCompany;
    if(company)
      newCompany = {...company, [key]:value}
    else
      newCompany = {[key]:value}
    setCompany(newCompany)
  }

  const setContactValue = (key, value)=>{
    var newContact;
    if(contact)
    newContact = {...contact, [key]:value}
    else
    newContact = {[key]:value}
    setContact(newContact)
  }


  const getCountryObject = (code)=>{
    for(var i=0; i<countries.length; i++){
      if(countries[i].code == code){
        return countries[i];
      }
    }
    return null;
  }
  
  const onChangeName = (e)=>{
    props.setGrouppingBooth(e.target.value);
  }

  const onSubmitPayment=()=>{
    
    if(!company){
      setOpenSnack(true);
      setSnackMsg("Company is required");
      return;
    }
    if(!contact){
      setOpenSnack(true);
      setSnackMsg("Contact is required");
      return;
    }
    if(!company.COMPANY_NAME){
      setOpenSnack(true);
      setSnackMsg("Company Name is required");
      return;
    }
    if(!company.ZIP_CODE){
      setOpenSnack(true);
      setSnackMsg("Company Zip is required");
      return;
    }
    if(!contact.FIRST_NAME){
      setOpenSnack(true);
      setSnackMsg("Contact First name is required");
      return;
    }
    if(!contact.LAST_NAME){
      setOpenSnack(true);
      setSnackMsg("Contact Last name is required");
      return;
    }
    if(!contact.EMAIL){
      setOpenSnack(true);
      setSnackMsg("Contact Email is required");
      return;
    }
    const elem = document.getElementById("submit-button");
    if (elem.onclick) {
      elem.onclick();
    } else if (elem.click) {
      elem.click();
    }
  }

  const onOk = async ()=>{
    if(!company){
      setOpenSnack(true);
      setSnackMsg("Company is required");
      return;
    }
    if(!contact){
      setOpenSnack(true);
      setSnackMsg("Contact is required");
      return;
    }
    if(!company.COMPANY_NAME){
      setOpenSnack(true);
      setSnackMsg("Company Name is required");
      return;
    }
    if(!company.ZIP_CODE){
      setOpenSnack(true);
      setSnackMsg("Company Zip is required");
      return;
    }
    if(!contact.FIRST_NAME){
      setOpenSnack(true);
      setSnackMsg("Contact First name is required");
      return;
    }
    if(!contact.LAST_NAME){
      setOpenSnack(true);
      setSnackMsg("Contact Last name is required");
      return;
    }
    if(!contact.EMAIL){
      setOpenSnack(true);
      setSnackMsg("Contact Email is required");
      return;
    }
    var newCompany = {...company}
    var newContact = {...contact}
    newContact.PARENT_KEY = newCompany.KEY_ID;
    groupedItem = handleAutoCombine(autoCombine);
    var boothFileList = props.main.boothFileList;
    var active_booth_file = props.main.active_booth_file
    if(active_booth_file){
      var formdata = 
      {
          company:newCompany,
          contacts:newContact?[newContact]:[],
          sales_form:sales_form,
          booth_sale_items: boothSaleItems,
          booth_id: active_booth_file['id'],
          boothObjects,
          eshowExhibitor:eshowExhibitor,
          paymentResult
      }
      console.log(formdata);
      props.onOk(formdata, groupedItem)
      if(cart_show)
        setStep(5)
    }
  }

  const getBoothSaleItems = ()=>{
    var options = [];
    var pricingOptions = props.main.pricingOptions
    var premiumOptions = props.main.premiumOptions
    var discountOptions = props.main.discountOptions
    var total = 0;

    {
      for(var j = 0; j < pricingOptions.length; j++){
        var pricingOption = pricingOptions[j]
        for(var k = 0; k < pricingOption['ROWS']?.length; k++){
          var row = pricingOption['ROWS']?.[k]
          row.type = "Price"
          row.PRODUCT_KEY = pricingOption['KEY_ID']
          row.name = `${safeTrim(pricingOption['TITLE'])} ${safeTrim(row['ROW_TITLE'])}`
          options.push(row)
          for(var kk = 0; kk < row['COLUMNS']?.length; kk++){
            var column = row['COLUMNS'][kk]
            let tmp = {...column};
            tmp.type = "Price"
            tmp.PRODUCT_KEY = pricingOption['KEY_ID']
            let price_type = column['PRICE_TYPE'];
            if(price_type) price_type = price_type.toUpperCase()
            let start_date = column['START_DATE']
            if(start_date){
              start_date = safeTrim(column['START_DATE'])
              start_date = moment(start_date).format("MMMM, DD YYYY")
            }
            tmp.name = `${safeTrim(column['HEADER'])} ${safeTrim(column['TFRAME'])} ${start_date} ${price_type!='PERCENTAGE'?('$'+column['PRICE']):(column['PRICE']+'%')}`
            options.push(tmp)
          }
        }
      }
      for(var j = 0; j < premiumOptions.length; j++){
        var pricingOption = premiumOptions[j]
        for(var k = 0; k < pricingOption['ROWS']?.length; k++){
          var row = pricingOption['ROWS']?.[k]
          row.type = "Premium"
          row.PRODUCT_KEY = pricingOption['KEY_ID']
          row.name = `${safeTrim(pricingOption['TITLE'])} ${safeTrim(row['ROW_TITLE'])}`
          options.push(row)
          for(var kk = 0; kk < row['COLUMNS']?.length; kk++){
            var column = row['COLUMNS'][kk]
            let tmp = {...column};
            tmp.type = "Price"
            tmp.PRODUCT_KEY = pricingOption['KEY_ID']
            let price_type = column['PRICE_TYPE'];
            if(price_type) price_type = price_type.toUpperCase()
            let start_date = column['START_DATE']
            if(start_date){
              start_date = safeTrim(column['START_DATE'])
              start_date = moment(start_date).format("MMMM, DD YYYY")
            }
            tmp.name = `${safeTrim(column['HEADER'])} ${safeTrim(column['TFRAME'])} ${start_date} ${price_type!='PERCENTAGE'?('$'+column['PRICE']):(column['PRICE']+'%')}`
            options.push(tmp)
          }
        }
      }
      for(var j = 0; j < discountOptions.length; j++){
        var pricingOption = discountOptions[j]
        for(var k = 0; k < pricingOption['ROWS']?.length; k++){
          var row = pricingOption['ROWS']?.[k]
          row.type = "Discount"
          row.discountFlag = true
          row.PRODUCT_KEY = pricingOption['KEY_ID']
          row.name = `${safeTrim(pricingOption['TITLE'])} ${safeTrim(row['ROW_TITLE'])}`
          options.push(row)
          for(var kk = 0; kk < row['COLUMNS']?.length; kk++){
            var column = row['COLUMNS'][kk]
            let tmp = {...column};
            tmp.discountFlag = true
            tmp.type = "Price"
            tmp.PRODUCT_KEY = pricingOption['KEY_ID']
            let price_type = column['PRICE_TYPE'];
            if(price_type) price_type = price_type.toUpperCase()
            let start_date = column['START_DATE']
            if(start_date){
              start_date = safeTrim(column['START_DATE'])
              start_date = moment(start_date).format("MMMM, DD YYYY")
            }
            tmp.name = `${safeTrim(column['HEADER'])} ${safeTrim(column['TFRAME'])} ${start_date} ${price_type!='PERCENTAGE'?('$'+column['PRICE']):(column['PRICE']+'%')}`
            options.push(tmp)
          }
        }
      }
    }
    console.log("options", options)
    return options;
  }


  var booths = props.boothTexts;
  console.log("contacts", contacts)
  console.log("company", company)
  console.log("boothSaleItems", boothSaleItems)
  var total = 0;
  var total_qty = 0;
  // if(boothSaleItems){
  //   for(var i = 0; i < boothSaleItems.length; i++){
  //     if(boothSaleItems[i] && boothSaleItems[i].length > 0){
  //       for(var j = 0; j < boothSaleItems[i].length; j++){
  //         if(boothSaleItems[i][j].discountFlag){
  //           total-= boothSaleItems[i][j].saleitem_total*1
  //         }
  //         else{
  //           var boothSaleItem = boothSaleItems[i]
  //           var row = boothSaleItems[i][j]
  //           total+= row.saleitem_total*1
  //         }

  //       }
  //     }
  //   }
  // }
  let cartList = props.main.cartList
  console.log("cartList", cartList)
  if(props.assignType == 'sponsorships'){
    for(let i = 0; i < cartList?.length; i++){
      if(cartList[i]?.[0].VALUE == 'Total'){
        total= cartList[i][1].VALUE.replace("$","").replaceAll(",","")*1;
      }
    }
  }
  else{
    if(eshowExhibitor?.SALES){
      for(var i = 0; i < eshowExhibitor?.SALES.length; i++){  
          total+= eshowExhibitor?.SALES[i].TOTAL*1;
          total_qty+= eshowExhibitor?.SALES[i].QTY*1;
      }
    }
  }

  var mainCanvas = props.main.mainCanvas
  var convertRateX= 1
  if(mainCanvas.convertRateX) {
      var convertRateX= Math.abs(mainCanvas.convertRateX)
  }
  var objects = props.main.mainCanvas.getObjects('group')
  var groups = mainCanvas.getObjects('group');
  var map_companies = [];
  var discountOptions = props.main.discountOptions
  var discountSelctOptions = [];
  discountSelctOptions.push(<MenuItem value=''>&nbsp;</MenuItem>)
  for(var j = 0; j < discountOptions.length; j++){
    var pricingOption = discountOptions[j]
    for(var k = 0; k < pricingOption['ROWS']?.length; k++){
      var row = pricingOption['ROWS']?.[k]
      row.type = "Discount"
      row.discountFlag = true
      row.PRODUCT_KEY = pricingOption['KEY_ID']
      row.name = `${safeTrim(pricingOption['TITLE'])} ${safeTrim(row['ROW_TITLE'])}`
      for(var kk = 0; kk < row['COLUMNS']?.length; kk++){
        var column = row['COLUMNS'][kk]
        let tmp = {...column};
        tmp.discountFlag = true
        tmp.type = "Price"
        tmp.PRODUCT_KEY = pricingOption['KEY_ID']
        let price_type = column['PRICE_TYPE'];
        if(price_type) price_type = price_type.toUpperCase()
        let start_date = column['START_DATE']
        if(start_date){
          start_date = safeTrim(column['START_DATE'])
          start_date = moment(start_date).format("MMMM, DD YYYY")
        }
        tmp.name = `${safeTrim(column['HEADER'])} ${safeTrim(column['TFRAME'])} ${start_date} ${price_type!='PERCENTAGE'?('$'+column['PRICE']):(column['PRICE']+'%')}`
        discountSelctOptions.push(<MenuItem key={`${j}-${k}-${kk}`} value={column['PRICE_KEY']}>{tmp.name}</MenuItem>)
      }
    }
  }
  for(var i = 0; i < groups.length; i++){
      var group = groups[i]
      if(group.class == 'booth' && group.sold){
        var exhibitors = group?.exhibitors
        if(exhibitors && exhibitors.length > 0){
          map_companies.push(exhibitors[0].COMPANY_KEY)
        }
      }
  }
  console.log("eshowExhibitor", eshowExhibitor)
  
  const active_booth_file = props.main?.active_booth_file
  const form_key = active_booth_file?.sales_form 
  let saleItemOptions = getBoothSaleItems()
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'attach-dialog-title'}}
      aria-labelledby="attach-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
      onClose={()=>onClose()} open={open}>
      <DialogTitle id='attach-dialog-title' style={{display:'flex', alignItems:'center'}}><AddLinkIcon style={{marginRight: 10}}/> Assign</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        
        {(step == 1) &&
        <Box mt={2}>
          {form_key && form_key != "" ?
            <>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked checked={autoCombine} onChange={(e)=>setAutoCombine(e.target.checked)}/>} label="Auto-Combine Booths" />
              </FormGroup>
              {!autoCombine?
              <div>
                <div style={{marginTop:10}}><strong>Booths</strong></div>
                <List>
                  {
                    props.boothObjects?.map((booth, index)=>{
                      return(
                        <ListItem key = {index} disablePadding>
                            <ListItemText primary={`${booth.booth_number} - ${px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")}x${px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")}`} />
                        </ListItem>
                      )
                    })
                  }
                </List>
              </div>:
              <FormControl margin="dense" variant="standard" fullWidth style={{maxWidth:'300px'}}>
                <InputLabel id="demo-simple-select-label">Booth Number</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.grouppingBooth}
                  label="Sales Form"
                  onChange={onChangeName}
                >
                  {
                    booths?.map((booth_number, index)=>{
                      return(
                        <MenuItem key={index} value={booth_number}>{booth_number}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
              }
              {autoCombine &&
              <div id="previewSvg" style={{marginTop: 20, width:'100%', height:'200px'}}>
                
              </div>
              }
            </>:
            <>
                <ErrorIcon  sx={{ color: pink[500] }} fontSize="large"/>
                <div style={{color: `${pink[500]}`}}>
                  Form Key is not Setup
                </div>
            </>
          }
        </Box>
        }
        {(step == 2) &&
        <Box mt={2}>
          {form_key && form_key != "" ?
          <>
            <h4 style={{marginBottom: '5px'}}>Select Company</h4>
            <Box style={{position:'relative'}} mb={3}>
              <Box style={{border:'1px solid #e0e0e0', borderRadius:'4px'}}>
                <InputBase
                    sx={{ ml: 1, flex: 1, width:'calc(100% - 50px)' }}
                    placeholder="Search company"
                    inputProps={{ 'aria-label': 'search booth' }}
                    value={searchVal}
                    onChange={
                      (e)=>{
                        // if(e.target.value == "" || !e.target.value)
                        {
                          setSearchVal(e.target.value)
                          doSearch(e.target.value)
                        }
                      }
                    }
                />
                <IconButton size="small" type="button" onClick = {()=>doSearch(searchVal)} aria-label="search">
                    <SearchIcon />
                </IconButton>
              </Box>
              <Box mt={3} sx={{height:'450px'}}>
                <Grid container spacing={2}>
                  <Grid item xs={4}
                      onClick={
                        ()=>{
                          var tmp = {KEY_ID:uuid4(),new:true}
                          setCompany({...tmp})                          
                          setOrgCompany({...tmp})
                          var tmp1 = {KEY_ID:uuid4(),new:true}
                          setContact({...tmp1})
                          setOrgContact({...tmp1})
                          setContacts([])
                          setStep(step+2)
                        }
                      }
                    > 
                      <Box className={`company-tile ${company?.new?"selected":""}`} p={2}>
                        <label style={{wordBreak:'break-all', cursor: 'pointer'}}>New Company</label>
                        <Box sx={{textAlign:'center'}}>
                          <AddCircleOutlineIcon sx={{marginTop: '3px'}} fontSize="small"/>
                        </Box>
                        
                      </Box>
                  </Grid>
                  {searchedList.map((row, index) => (
                    <Grid item xs={4}
                      key={index}
                      onClick={()=>{
                        var tmp = {
                          'KEY_ID' : row['KEY_ID'],
                          'COMPANY_NAME': row['COMPANY_NAME'],
                          'ADDRESS1':row['ADDRESS1'],
                          'ADDRESS2':row['ADDRESS2'],
                          'CITY':row['CITY'],
                          'STATE':row['STATE'],
                          'ZIP_CODE':row['ZIP_CODE']
                        }
                        setCompany({...tmp});
                        setOrgCompany({...tmp});
                        setSearchVal(row['COMPANY_NAME']?safeTrim(row['COMPANY_NAME']):""); 
                        setStep(step+1)
                        fetchContacts(row['KEY_ID'])
                        setContact({})
                      }} 
                      sx={{paddingTop: '4px', paddingBottom:'4px', cursor:'pointer'}}
                    > 
                      <Box className={`company-tile ${company?.KEY_ID == row['KEY_ID']?"selected":""}`} p={2} style={{background:((map_companies.includes(row['KEY_ID']) || row['EXHIBITOR'])?'#65e865':'inherit')}}>
                        <label style={{wordBreak:'break-all'}}>{row['COMPANY_NAME']}</label><br/>
                        {row['BOOTH_NO'] &&
                        <>
                        <label style={{wordBreak:'break-all', fontSize:13.5}}>Booth: {row['BOOTH_NO']}</label><br/>
                        </>
                        }
                        <BusinessIcon sx={{marginTop: '3px'}} fontSize="small"/>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </>:
          <>
              <ErrorIcon  sx={{ color: pink[500] }} fontSize="large"/>
              <div style={{color: `${pink[500]}`}}>
                Form Key is not Setup
              </div>
          </>
          }
        </Box>
        }
        {(step == 3) &&
        <Box mt={2}>
          <h4 style={{marginBottom: '3px'}}>Select Contact</h4>
          <Box style={{position:'relative'}} mb={3}>
            <Box style={{border:'1px solid #e0e0e0', borderRadius:'4px'}}>
              <InputBase
                  sx={{ ml: 1, flex: 1, width:'calc(100% - 50px)' }}
                  placeholder="Search contact"
                  inputProps={{ 'aria-label': 'search booth' }}
                  value={searchContactVal}
                  onChange={
                    (e)=>{
                      // if(e.target.value == "" || !e.target.value)
                      {
                        setSearchContactVal(e.target.value)
                        doSearchContact( e.target.value)
                      }
                    }
                  }
              />
              <IconButton size="small" type="button" onClick = {()=>doSearchContact(searchContactVal)} aria-label="search">
                  <SearchIcon />
              </IconButton>
            </Box>
            <Box mt={3} sx={{height:'450px'}}>
              <Grid container spacing={2}>
                <Grid item xs={4}
                    onClick={()=>{
                      setContacts([])
                      var tmp = {KEY_ID:uuid4(),new:true}
                      setContact({...tmp})
                      setOrgContact({...tmp})
                      setStep(step+1)
                    }
                  }
                  > 
                    <Box className={`company-tile ${company?.new?"selected":""}`} p={2}>
                      <label style={{wordBreak:'break-all'}}>New Contact</label>
                      <Box sx={{textAlign:'center'}}>
                        <AddCircleOutlineIcon sx={{marginTop: '3px'}} fontSize="small"/>
                      </Box>
                      
                    </Box>
                </Grid>
                {contacts.map((row, index) => (
                  <Grid item xs={4}
                    key={index}
                    onClick={()=>{
                        setContact({...row});
                        setOrgContact({...row});
                        setSearchContactVal((row['FIRST_NAME']?safeTrim(row['FIRST_NAME']):"")+" "+(row['LAST_NAME']?safeTrim(row['LAST_NAME']):"")); 
                        setStep(step+1)
                      }
                    } 
                    sx={{paddingTop: '4px', paddingBottom:'4px', cursor:'pointer'}
                    }
                  > 
                    <Box  className={`company-tile ${contact?.KEY_ID == row['KEY_ID']?"selected":""}`} p={2} style={{background:(row['SALES_CONTACT']?'#65e865':'inherit')}}>
                      <label style={{wordBreak:'break-all'}}>{(row['FIRST_NAME']?row['FIRST_NAME']:"")+" "+(row['LAST_NAME']?row['LAST_NAME']:"")}</label><br/>
                      <BusinessIcon sx={{marginTop: '3px'}} fontSize="small"/>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
        }
        {(step == 4) &&
        <Box>
          <Box mt="3">
            <h4 style={{marginBottom:0}}>Company</h4>
            <Grid container spacing={2}>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Company Name:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="COMPANY_NAME"
                  margin="dense"
                  label=""
                  disabled = {!company.new}
                  fullWidth
                  variant="standard"
                  value = {company?company.COMPANY_NAME:""}
                  onChange={(e)=>(setCompanyValue('COMPANY_NAME', e.target.value))}
                  size="small"
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Address1:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="ADDRESS1"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.ADDRESS1:""}
                  onChange={(e)=>(setCompanyValue('ADDRESS1', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Address2:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="ADDRESS2"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.ADDRESS2:""}
                  onChange={(e)=>(setCompanyValue('ADDRESS2', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>City:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="CITY"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.CITY:""}
                  onChange={(e)=>(setCompanyValue('CITY', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>State:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="STATE"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.STATE:""}
                  onChange={(e)=>(setCompanyValue('STATE', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Zip<span style={{color:'red'}}>*</span>:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="ZIP_CODE"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.ZIP_CODE:""}
                  onChange={(e)=>(setCompanyValue('ZIP_CODE', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
              {/* <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Code:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="filename"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.code:""}
                  onChange={(e)=>(setCompanyValue('code', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid> */}
              {/* <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Choose a country:</label>
              </Grid>
              <Grid item xs={8}> 
                <Autocomplete
                  sx={{ width: '100%' }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label} ({option.code})
                    </Box>
                  )}
                  value = {company?getCountryObject(company.country):null}
                  onChange={(event, newValue)=>(setCompanyValue('country', newValue.code))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label=""
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      size="small"
                    />
                  )}
                />
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        }
        {(step == 4) &&
        <Box mt={3}>
          <h4 style={{marginBottom:0}}>Contact</h4>
          <Grid container spacing={1}>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Salutation:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="SALUTATION"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.SALUTATION:""}
                onChange={(e)=>(setContactValue('SALUTATION', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>First name<span style={{color:'red'}}>*</span>:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="FIRST_NAME"
                margin="dense"
                label=""
                size = "small"
                disabled = {!contact?.new && (contact?.FIRST_NAME && contact?.FIRST_NAME !="")}
                fullWidth
                variant="standard"
                value = {contact?contact.FIRST_NAME:""}
                onChange={(e)=>(setContactValue('FIRST_NAME', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Last name<span style={{color:'red'}}>*</span>:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="LAST_NAME"
                margin="dense"
                label=""
                size = "small"
                disabled = {!contact?.new && (contact?.LAST_NAME && contact?.LAST_NAME !="")}
                fullWidth
                variant="standard"
                value = {contact?contact.LAST_NAME:""}
                onChange={(e)=>(setContactValue('LAST_NAME', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Title:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="TITLE"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.TITLE:""}
                onChange={(e)=>(setContactValue('TITLE', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Email<span style={{color:'red'}}>*</span>:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="EMAIL"
                margin="dense"
                label=""
                size = "small"
                disabled = {!contact?.new && (contact?.EMAIL && contact?.EMAIL !="")}
                fullWidth
                variant="standard"
                value = {contact?contact.EMAIL:""}
                onChange={(e)=>(setContactValue('EMAIL', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Phone:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="PHONE"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.PHONE:""}
                onChange={(e)=>(setContactValue('PHONE', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <label style={{fontWeight:'bold'}}>Application Options</label>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={0}>
                <FormControlLabel
                  sx={{marginLeft:0}}
                  value="start"
                  control={<Switch color="primary" checked={company?.MEMBER_RATE==1}
                  onChange={(e)=>{
                    setCompanyValue('MEMBER_RATE', company?.MEMBER_RATE==1?0:1)
                    getEshowPrices(company?.MEMBER_RATE==1?0:1)
                  }}/>}
                  label="Apply Member Rate"
                  labelPlacement="start"
                />
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              <Box mt={0}>
                <FormControlLabel
                  sx={{marginLeft:0}}
                  value="start"
                  control={<Switch color="primary" checked={company?.STATUS=='2'}
                  onChange={(e)=>{
                    setCompanyValue('STATUS', company?.STATUS=='2'?'3':'2')
                  }}/>}
                  label="Hold Exhibitor"
                  labelPlacement="start"
                />
              </Box>
            </Grid>
            
            <Grid item xs={6}>
              <Box mt={0} >
                <TextField
                  name="Application Date"
                  margin="dense"
                  label="Application Date"
                  size = "small"
                  fullWidth
                  variant="outlined"
                  type='date'
                  value = {company.APPLICATION_DATE?moment(company.APPLICATION_DATE).format("YYYY-MM-DD"):""}
                  onChange={(e)=>(setCompanyValue('APPLICATION_DATE', e.target.value))}
                  InputLabelProps = {{shrink: true}}
                  // onChange={}
                />
              </Box>
            </Grid>
            
            <Grid item xs={6}>
              <Box mt={0} >
                <FormControl margin="dense" size = "small" variant="outlined" fullWidth style={{maxWidth:'300px'}}>
                  <InputLabel id="demo-simple-select-label">Discount Code</InputLabel>
                  <Select
                    value = {company?company.DISCOUNT_CODE:""}
                    label="Discount Code"
                    onChange={(e)=>(setCompanyValue('DISCOUNT_CODE', e.target.value))}
                  >
                    {discountSelctOptions}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {/* <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Address1:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="ADDRESS1"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.ADDRESS1:""}
                onChange={(e)=>(setContactValue('ADDRESS1', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Address2:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="ADDRESS2"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.ADDRESS2:""}
                onChange={(e)=>(setContactValue('ADDRESS2', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>City:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="CITY"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.CITY:""}
                onChange={(e)=>(setContactValue('CITY', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>State:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="STATE"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.STATE:""}
                onChange={(e)=>(setContactValue('STATE', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Zip:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="ZIP_CODE"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.ZIP_CODE:""}
                onChange={(e)=>(setContactValue('ZIP_CODE', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid> */}
            
            {/* <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Code:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="filename"
                margin="dense"
                label=""
                
                fullWidth
                variant="standard"
                value = {contact?contact.code:""}
                onChange={(e)=>(setContactValue('code', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Choose a country:</label>
            </Grid>
            <Grid item xs={8}> 
              <Autocomplete
                sx={{ width: '100%' }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label} ({option.code})
                  </Box>
                )}
                value = {contact?getCountryObject(contact.country):null}
                onChange={(event, newValue)=>(setContactValue('country', newValue.code))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label=""
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid> */}
          </Grid>
        </Box>
        }
        {(step == 4) &&
        <>
        <Box mt={3}>
          <h4>Sales Item</h4>
          <Box mt={1} mb={2}>
            {/* <h5 style={{marginLeft:5, marginBottom:8, fontSize:13.5}}>
              Booth: {boothSaleItem[0]?boothSaleItem[0].booth:""} <span style={{fontWeight:'normal'}}>(Size: {getBoothSize(combinedGroup?combinedGroup:(boothSaleItem[0]?.boothObject))?.booth_width}x{getBoothSize(combinedGroup?combinedGroup:(boothSaleItem[0]?.boothObject))?.booth_height}, {`${props.main?.mainCanvas?.mapUnit == 'feet'?'SqFt':'SqM'}`}: {getSqrt(combinedGroup?combinedGroup:(boothSaleItem[0]?.boothObject)).toFixed(2)})</span>
            </h5> */}
            <Table  aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{padding:'5px'}} width="44%">Title</TableCell>
                  <TableCell sx={{padding:'5px', textAlign:'right'}} width="21%">Price</TableCell>
                  <TableCell sx={{padding:'5px', textAlign:'right'}} width="8%">Qty</TableCell>
                  <TableCell sx={{padding:'5px', textAlign:'right'}} width="21%">Total</TableCell>
                  <TableCell sx={{padding:'5px'}} width="6%"></TableCell>
                </TableRow>
              </TableHead>
              
              {
              props.assignType == 'sponsorships'?              
              <TableBody>
                {
                  cartList?.map((row, index2)=>{
                    if(row[0]?.PRODUCT_KEY){
                      return(
                        <TableRow key={index2}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{padding:'5px', borderBottom:'none'}} component="th" scope="row">
                            <Box pt={1} pb={1}>{row[0]?.VALUE} {row[1]?.VALUE}</Box>
                          </TableCell>
                          <TableCell sx={{padding:'5px', borderBottom:'none', textAlign:'right'}} component="th" scope="row">
                            <Box style={{fontSize:13}} pt={1} pb={1}>{row[2]?.VALUE}</Box>
                          </TableCell>
                          <TableCell sx={{padding:'5px', borderBottom:'none', textAlign:'right'}} component="th" scope="row">
                            <Box pt={1} pb={1}>1</Box>
                          </TableCell>
                          <TableCell sx={{padding:'5px', borderBottom:'none', textAlign:'right'}} component="th" scope="row">
                            <Box style={{fontSize:13}} pt={1} pb={1}>{row[2]?.VALUE}</Box>
                          </TableCell>
                        </TableRow>  
                      )
                    }
                  })
                }
              </TableBody>:
              <TableBody>     
                {
                  eshowExhibitor?.SALES?.map((row, index2) => {
                    return(  
                      <>       
                        {
                        Boolean(row.ACTIVE) &&
                        <TableRow key={index2}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{padding:'5px', borderBottom:'none'}} component="th" scope="row">
                            {(!row.new || 1)?
                            <Box pt={1} pb={1}>{row.TITLE}{row.DESCRIPTION?<br/>:''}<span >{row.DESCRIPTION}</span></Box>:
                            <Autocomplete
                              sx={{ width: '100%' }}
                              PopperComponent={PopperMy}
                              options={getBoothSaleItems()}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              getOptionDisabled={(option) => option.ROW_NO > 0}
                              renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0, fontWeight:'bold' } }} {...props}>
                                  <span style={{fontWeight:(option.ROW_NO > 0?'bold':'normal')}}>{option.name?option.name:''}</span>
                                </Box>
                              )}
                              disalble={true}
                              value={row.PRICE_KEY}
                              onChange={(event, newValue) => {
                                if(newValue?.ROW_NO > 0)
                                  return
                                updateBoothSaleInfo(index2, 'saleitem_id', newValue)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  size="small"
                                  label=""
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                            }
                          </TableCell>
                          <TableCell sx={{padding:'5px', textAlign:'right', borderBottom:'none'}}>
                            {row.AMOUNT*1 < 0?
                            <>(- ${(row.AMOUNT*(-1)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</>:
                            <>${(row.AMOUNT*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
                            }
                          </TableCell>
                          <TableCell sx={{padding:'5px', textAlign:'right', borderBottom:'none'}}>
                            {row.QTY}
                          </TableCell>
                          <TableCell sx={{padding:'5px', textAlign:'right', borderBottom:'none'}}>
                            {row.AMOUNT*1 < 0?
                            <>(- ${(row.TOTAL*(-1)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</>:
                            <>${(row.TOTAL*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
                            }                                  
                          </TableCell>
                          <TableCell sx={{padding:'5px', borderBottom:'none'}}>
                            {/* {!row.default && */}
                            <Box display='flex' style={{alignItems:'center'}}>
                              {/* {boothSaleItem.length > 1 && */}
                              {
                                row.BOOTH_KEY?
                                <IconButton>
                                  <EditIcon
                                    onClick={
                                      ()=>{
                                        setSalesItemModel(true)
                                        setEditSalesItem(row)
                                      }
                                    }
                                    fontSize="small"
                                  />
                                </IconButton>:                                
                                <IconButton>
                                  <RemoveCircleOutlineOutlinedIcon
                                    onClick={
                                      ()=>{
                                        removeBoothSaleInfo(index2)
                                      }
                                    }
                                    fontSize="small"
                                  />
                                </IconButton>                                
                              }
                              {/* } */}
                            </Box>
                            {/* } */}
                          </TableCell>
                        </TableRow>  
                        }          
                        {row.BOOTH_NUMBER && row.BOOTH_NUMBER != "" &&
                        <TableRow key={`booth-${index2}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell colSpan={5} sx={{padding:'5px'}} component="th" scope="row">                              
                            <h5 style={{marginTop:0, marginBottom:8, fontSize:13.5}}>
                              Booth: {row.BOOTH_NUMBER} <span style={{fontWeight:'normal'}}>(Size: {row.SIZE_X}x{row.SIZE_Y}, {`${props.main?.mainCanvas?.mapUnit == 'feet'?'SqFt':'SqM'}`}: {(row.SF_FT*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</span>
                            </h5>
                          </TableCell>
                        </TableRow> 
                        }  
                      </>
                    )
                  })
                }
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{padding:'5px'}} component="th" scope="row">
                    
                  </TableCell>
                  <TableCell sx={{padding:'5px', textAlign:'center'}}>
                    
                  </TableCell>
                  <TableCell sx={{padding:'5px', textAlign:'center'}}>
                    
                  </TableCell>
                  <TableCell sx={{padding:'5px', textAlign:'center'}}>
                    
                    
                  </TableCell>
                  <TableCell sx={{padding:'5px'}}>
                    <Box display='flex' style={{alignItems:'center'}}>
                      <IconButton>
                        <AddCircleOutlineOutlinedIcon
                          onClick={
                            ()=>{
                              setSalesItemModel(true)
                              setEditSalesItem(false)
                            }
                          }
                          fontSize="small"
                        />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>  
              </TableBody>
              }
            </Table>
          </Box>
          <Box mt={2} style={{textAlign:'right'}}>
            <strong style={{marginRight:50}}>Total: ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
          </Box>
          {/* <Box mt={1} mb={2}>
            {
              (combinedGroup?[combinedGroup]:props.boothObjects)?.map((row, index2) => {
                return (
                  <h5 style={{marginLeft:5, marginBottom:8, fontSize:13.5}}>
                    Booth: {row.booth_number} <span style={{fontWeight:'normal'}}>(Size: {getBoothSize(row)?.booth_width}x{getBoothSize(row)?.booth_height}, {`${props.main?.mainCanvas?.mapUnit == 'feet'?'SqFt':'SqM'}`}: {getSqrt(row).toFixed(2)})</span>
                  </h5>
                )
              })
            }
          </Box> */}
          {/* <Box mt={2} style={{textAlign:'right'}}>
            <strong style={{marginRight:32}}>Total Qty: {(total_qty).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
          </Box> */}
        </Box>
        {(globalPayToken && cart_show) &&
        <Box mt={3}>
          <form id="payment-form" method="post" action="process_payment">
            <h4>Payment Details</h4>
            <label for="card-number">Card Number</label>
            <div id="card-number"></div>
            <label for="card-expiration">Expiry Date</label>
            <div id="card-expiration"></div>
            <label for="card-cvv">Security Code</label>
            <div id="card-cvv"></div>
            <label for="card-holder-name">Cardholder Name</label>
            <div id="card-holder-name"></div>
          </form>
        </Box>
        }
        </>
        }
        
        {(step == 5) &&
        <Box style={{ textAlign: 'center', marginTop: '20px', marginBottom: '50px'}}>
          <Box sx={{fontWeight:'bold'}}>
            {props.confirmation}
          </Box>
          <Box mt={5}>
            <Button variant='contained' onClick={onClose}>Close Form</Button>
          </Box>
        </Box>
        }
        <LoadingOverlay
          active={loadingFlag}
          styles={{
            wrapper:{
              position: 'absolute',
              width: '100px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },

              // spinner: (base) => ({
              // ...base,
              // width: '50px',
              // '& svg circle': {
              //     stroke: '#1976d2'
              // }
              // }),
            overlay: (base) => ({
              ...base,
              fontSize:'18px',
              color: 'rgb(5, 37, 51)',
              // background: 'rgb(229 229 229 / 92%)',
              background: 'transparent',
              position:'absolute',
              zIndex:1000000000
            })
          }}
          spinner = {
          <ScaleLoader 
              color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'}
              radius={2.5} 
              margin={2.5} 
              height={40} 
              width={5}
          />}
          // spinner
          // text='Loading ...'
          >
      </LoadingOverlay> 
      </DialogContent>
      { step<=4 &&
      <DialogActions>
        {step >1 && 
          <Button 
            disabled={!form_key || form_key == ""}
            onClick={()=>{
            if(step == 2){
             if((props.boothTexts&& props.boothTexts.length > 1)){
               setStep(step-1)
             }
             else{
               setStep(2)
             }
            }
            else
             setStep(step-1)
           }
         }>Back</Button>
        }
        {step < 4 &&
          <Button
            disabled={!form_key || form_key == "" || (!company?.KEY_ID && step==2) || (!contact?.KEY_ID && step==3)}
            onClick={()=>{
             if(step == 1){
              if((props.boothTexts&& props.boothTexts.length > 1)){
                setStep(step+1)
              }
              else{
                setStep(2)
              }
             }
             else
              setStep(step+1)
            }
          }>Next</Button>
        }
        {step == 4 &&
          <>
            {
              (globalPayToken && cart_show)?
                <div id="submit-button"></div>:
                <Button onClick={onOk}>Ok</Button>
            }
          </>          
        }

        <Button onClick={()=>onClose(groupedItem)}>Cancel</Button>
      </DialogActions>
      }
      <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            message={snackMsg}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
      />
      {errorMessage && 
      <Dialog
        open={open}
        onClose={()=>setErrorMessage(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='xs'
      >
        <DialogTitle sx={{color:'white', background:'#b50d0d'}} id="alert-dialog-title">
          Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box mt={2}>
              {errorMessage}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setErrorMessage(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      }
      {salesItemModel && 
        <Dialog
          open={open}
          onClose={()=>setSalesItemModel(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle sx={{color:'white', background:'#4975af'}} id="alert-dialog-title">
            Select Sales Items
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box mt={2}>
                <List>
                  {
                    saleItemOptions?.map((saleItem, index)=>{
                      return(
                        <ListItem key = {index} disablePadding style={{cursor:(saleItem.ROW_NO > 0?'auto':'pointer'), background:(selectedItems.filter(value=>value?.PRICE_KEY == saleItem?.PRICE_KEY && !saleItem.ROW_NO).length>0?'#aedbe3':'white')}} 
                          onClick={()=>{
                            if(!saleItem.ROW_NO){
                              // if(selectedItems.filter(value=>value.PRICE_KEY == saleItem.PRICE_KEY).length>0){
                              //   let tmp = selectedItems.filter(function( item ) {
                              //     return item.PRICE_KEY != saleItem.PRICE_KEY;
                              //   });
                              //   setSelectedItems(tmp)
                              // }
                              // else{
                              //   let tmp = [...selectedItems, saleItem]
                              //   setSelectedItems(tmp)
                              // }
                              if(selectedItems.filter(value=>value.PRICE_KEY == saleItem.PRICE_KEY).length>0){
                                let tmp = selectedItems.filter(function( item ) {
                                  return item.PRICE_KEY != saleItem.PRICE_KEY;
                                });
                                setSelectedItems(tmp)
                              }
                              else{
                                let tmp = [ saleItem]
                                setSelectedItems(tmp)
                              }
                            }
                          }}
                        >
                            <ListItemText primary={<span style={{fontWeight:(saleItem.ROW_NO > 0?'bold':'normal')}}>{saleItem.name}</span>} />
                        </ListItem>
                      )
                    })
                  }
                </List>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={()=>{
                var new_eshowExhibitor = {...eshowExhibitor}
                var sales  = new_eshowExhibitor?.SALES; 
                var newSales = [...sales]
                for(var i = 0; i < selectedItems?.length; i++){
                  var emptyrow = {
                    ACTIVE: 1,
                    BOOTH_KEY: false,
                    BOOTH_NUMBER: false,
                    KEY_ID: uuidv4(),
                    QTY: 1,
                    TITLE: selectedItems[i].name,
                    TYPE: 407,
                    new:true,                    
                    PRICE_KEY:selectedItems[i].PRICE_KEY,
                    PRODUCT_KEY:selectedItems[i].PRODUCT_KEY, 
                    AMOUNT:(selectedItems[i].PRICE*(selectedItems[i]?.discountFlag?(-1):(1))).toFixed(2), 
                    TOTAL:(selectedItems[i].PRICE*(selectedItems[i]?.discountFlag?(-1):(1))).toFixed(2), 
                  }
                  if(editSalesItem){
                    for(let i = 0; i < newSales?.length; i++){
                      if(newSales[i].PRICE_KEY == editSalesItem.PRICE_KEY){
                        newSales[i] = {...newSales[i], PRICE_KEY:emptyrow.PRICE_KEY, PRODUCT_KEY:emptyrow.PRODUCT_KEY, NEW:true}
                      }
                    }
                  }
                  else{
                    newSales = [...newSales, emptyrow]
                  }
                }
                new_eshowExhibitor['SALES'] = newSales;
                getEshowPrices(company.MEMBER_RATE, new_eshowExhibitor)
                setSelectedItems([])
                setSalesItemModel(false)
              }}>Ok
            </Button>
            <Button onClick={()=>setSalesItemModel(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      }
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(AttachModal);