/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "../fabrictool";
import { getSnapPosition, addText, getFirstZoom } from '../../../../../utils/CanvasTool'
import { v4 as uuidv4 } from 'uuid';

const fabric = require("fabric").fabric;

class Index extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    this._width = 1/getFirstZoom(canvas);
    this._color = props.lineColor;
    this.props = props
  }

  doMouseDown(o) {
    this.isDown = true;
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);
    var newPoint = pointer
    if(canvas.snapToGrid)
      newPoint = getSnapPosition(canvas, pointer)
    var points = [newPoint.x, newPoint.y, newPoint.x, newPoint.y];
    var strokeWidth = 1/getFirstZoom(canvas)
    this.line = new fabric.Line(points, {
      strokeWidth: strokeWidth,
      fill: this._color,
      stroke: this._color,
      originX: "center",
      originY: "center",
      selectable: false,
      evented: false,
    });   

    this.head = new fabric.Triangle({
      fill: this._color,
      left: newPoint.x,
      top: newPoint.y,
      originX: "center",
      originY: "center",
      height: 3 * this._width,
      width: 3 * this._width,
      selectable: false,
      evented: false,
      angle: 90,
    }); 

    canvas.add(this.line);
    canvas.add(this.head);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);
    var newPoint = pointer
    if(canvas.snapToGrid)
      newPoint = getSnapPosition(canvas, pointer)
    this.line.set({ x2: newPoint.x, y2: newPoint.y });
    this.line.setCoords();

    let x_delta = newPoint.x - this.line.x1;
    let y_delta = newPoint.y - this.line.y1;

    this.head.set({
      left: newPoint.x,
      top: newPoint.y,
      angle: 90 + (Math.atan2(y_delta, x_delta) * 180) / Math.PI,
    });

    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    let canvas = this._canvas;

    canvas.remove(this.line);
    canvas.remove(this.head);
    let arrow = new fabric.Group([this.line, this.head]).set({      
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
    });
    arrow.set({
      layer:'annotation',
      class1: 'arrow'
    })
    arrow.set({id:Date.now()})   
    canvas.add(arrow);
    var convertRateX= 1
    if(canvas.convertRateX) {
        var convertRateX= Math.abs(canvas.convertRateX)
    }
    var f_text = new fabric.Text("", {              
      left: 0-arrow.width/2, 
      top:0-arrow.height/2,
      fontSize:30/convertRateX,
      class:'title'
    });
    arrow.add(f_text);
    arrow.new_created = true;
    arrow.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
    arrow.KEY_ID = uuidv4()
    this.props.setBoothHistory({boothList:this.props.main.boothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[arrow]}})
    this.props.onDrawDone(arrow)   
  }

  doMouseOut(o) {
    this.isDown = false;
    this.props.onDrawDone(false)   
  }
}

export default Index;
