/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "../fabrictool";
import { v4 as uuidv4 } from 'uuid';

const fabric = require("fabric").fabric;

class Aisle extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.props = props
  }

  doMouseDown(o) {
    let canvas = this._canvas;
    this.isDown = true;
    let pointer = canvas.getPointer(o.e);
    this.startX = pointer.x;
    this.startY = pointer.y;
    this.rect = new fabric.Rect({
      left: this.startX,
      top: this.startY,
      originX: "left",
      originY: "top",
      width: pointer.x - this.startX,
      height: pointer.y - this.startY,
      stroke: this._color,
      strokeWidth: this._width,
      fill: this._fill,
      transparentCorners: false,
      selectable: false,
      evented: false,
      strokeUniform: true,
      noScaleCache: false,
      angle: 0,
    });
    canvas.add(this.rect);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    let canvas = this._canvas;
    let pointer = canvas.getPointer(o.e);
    if (this.startX > pointer.x) {
      this.rect.set({ left: Math.abs(pointer.x) });
    }
    if (this.startY > pointer.y) {
      this.rect.set({ top: Math.abs(pointer.y) });
    }
    this.rect.set({ width: Math.abs(this.startX - pointer.x) });
    this.rect.set({ height: Math.abs(this.startY - pointer.y) });
    this.rect.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    
    
  }

  endDraw(){
    if(this.rect && this.rect.width>0 && this.rect.height>0){
      //   let canvas = this._canvas;
      //   canvas.setActiveObject(this.rect)
      //   canvas.renderAll();
      let canvas = this._canvas;

      var group = new fabric.Group().set({
        left: this.rect.left,
        top: this.rect.top,
        width: this.rect.width,
        height: this.rect.height,
        class: 'aisle',
        preserveObjectStacking: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
      });	
      this.rect.set({
        left:-this.rect.width/2,
        top:-this.rect.height/2
      })
      group.add(this.rect);
      canvas.remove(this.rect);

      var options = {};
      let iText = new fabric.Text("Aisle", options);
      let opts = {
        left: (0 - 5*8/2),
        top: (0 - 16/2),
      };
      Object.assign(options, opts); 
      iText.set({
        left: options.left,
        top: options.top,
        fontSize:16
      });

      group.add(iText);
      
            
      group.set({
        borderColor: '#ff7777',
        cornerColor: 'red',
        cornerSize: 6,
        transparentCorners: false
      });
      group.set({id:Date.now()})      
      canvas.add(group);
      // canvas.bringToFront(iText)
      canvas.setActiveObject(group)
      canvas.renderAll();
      group.new_created = true; 
      group.uniq_id = Date.now() + parseInt(Math.random()*10000000000)  
      group.KEY_ID = uuidv4()
      this.props.setBoothHistory({boothList:this.props.main.boothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[group]}})
    }
  }
}

export default Aisle;
