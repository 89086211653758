import React ,{ useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { getThemeProps } from '@mui/system';
import { setLeftPage } from '../../../actions/ui'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { setOpenLogin, setLeftBoothList, setBookmardUpdated, setOpenBoothList } from '../../../actions/ui'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import Exhibitors from "./Exhibitors"
import { setActive3dObject, setExhibitorList } from '../../../actions/main';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { getTokenCookie, getTokenPlanner, getUrlToken } from '../../../utils/Common'
import Fade from '@mui/material/Fade';

import qs, { parse } from 'qs'
import axios from 'axios';
import axiosInstance from '../../../utils/axiosInstance.js'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { highlightObject, checkIfSelectable, deselectHighlightObject } from '../../../utils/CanvasTool'
import { API_URL, S3_URL, ESHOW_URL } from '../../../config.js'

const drawerWidth = 240;


const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
var highlightedBooths = [];
var g_boothList = []
function Index(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const main_widow_offset_x = 0;
  const main_widow_offset_y = 51;
  const [searchVal, setSearchVal] = useState("");
  const [boothList, setBoothList] = useState([]);
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  let query = useQuery();

  const getBoothList = ()=>{
    setLoadingFlag(true)
    setLoaded(false)
    var active_booth_file = props.main.active_booth_file
    var token = getTokenCookie();   
    var map_key;   
    if(props.main.authInfo?.role != 'admin') {
      try{     
          var _token = getTokenPlanner();
          if(_token){
            token = _token
          }
      }
      catch(e){
        console.log("token parse", e)
      }
    }
    else{
    }
    map_key = active_booth_file?.KEY_ID
    if(query.get("map")){
      map_key = query.get("map");
    }
    map_key=false
    var url = `${ESHOW_URL()}?method=getExhibitorList&token=${token}${map_key?('&map_key='+map_key):''}`
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            props.setExhibitorList(result)
            if(result.EXHIBITORS){
              var boothList = [];
              for(var i = 0; i< result.EXHIBITORS.length; i++){
                var item = result.EXHIBITORS[i]
                if(!item?.SETUP?.LIST_PENDING_EXHIBITORS && item.STATUS == 2)
                  continue;
                boothList.push(
                  {
                    booth: item['BOOTHS']?item['BOOTHS']:"",
                    BOOTHS: item['BOOTHS']?item['BOOTHS']:"",
                    name:item['BOOTHS']?item['BOOTHS']:"",
                    priced:false,
                    id:"",
                    EXHIBITOR_KEY:item['EXHIBITOR_KEY']?item['EXHIBITOR_KEY']:"",
                    company:item['COMPANY_NAME']?item['COMPANY_NAME']:"",
                    bookmark:item['BOOKMARKED']?item['BOOKMARKED']:"",
                    MAP_NAME:item['MAP_NAME']?item['MAP_NAME']:"",
                    MAP_KEY:item['MAP_KEY']?item['MAP_KEY']:"",
                  }
                )
              }            
              boothList.sort(function(a, b) {
                const nameA = (a.company+" "+a.name).toLowerCase(); // ignore upper and lowercase
                const nameB = (b.company+" "+a.name).toLowerCase(); // ignore upper and lowercase
                
                return `${nameA}`.localeCompare(`${nameB}`)
              });
              props.setLeftBoothList(boothList)
              g_boothList = boothList
              highlightedBooths = [];
              for(var i = 0; i < boothList.length; i++){
                if(boothList[i].bookmark){
                  var booths = getBoothByNumber(boothList[i].booth)
                  for(var j = 0; j < booths.length; j++){
                    var booth = booths[j]
                    if(booth){
                      highlightObject(booth, props.main, true);
                      console.log("highlightObject", booth)
                      highlightedBooths.push(booth)
                    }
                  }
                }
              }
              props.main.mainCanvas.renderAll();
            }
            setLoadingFlag(false)
            setLoaded(true)
        }
        else{
          setLoadingFlag(false)
        }
    }).catch(error => {
      setLoadingFlag(false)
    })  
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var show_key = urlParams.get('show_key')     
  var urlToken = getUrlToken()
  useEffect(() => {
    getBoothList();
    return () => {
      for(var i = 0; i < highlightedBooths.length; i++){
        var booth = highlightedBooths[i]
        deselectHighlightObject(booth, props.main);
        // props.main.mainCanvas.renderAll();
        // console.log("deselectHighlightObject", booth)
      }
      props.main.mainCanvas.renderAll();
    };
  }, [props.main[`planner-${urlToken}`]])

  
  
  useEffect(() => {
    if(props.main.boothLoaded)
      getBoothList();
  }, [props.main.boothLoaded])

  
  
  const getBoothByNumber = (number)=>{
    var objects = props.main.mainCanvas.getObjects('group')
    var output = [];
    for(var i = 0; i < objects.length; i++){
      if(checkIfSelectable(objects[i])){
        if(objects[i].class=='booth'){
          if(objects[i].booth_number == number){
            output.push(objects[i])
          }
        }
      }
    }
    return output
  }

  var bookmarkList = props.ui.leftBoothList?.filter((EXHIBITOR)=>{
    return EXHIBITOR.bookmark
  }) 
  var bookmarkCnt = bookmarkList?.length

  return (
    <Fade in={true} timeout={1200}>
      <div className='appLeftSearch'>
        <Box display="flex" style={{alignItems:'center', justifyContent:'space-between'}} pt={2}  pb={2}>
          
          <div style={{marginLeft:20}}></div>
          <Box textAlign="left">
            <Box display="flex">
              <h4 style={{textTransform:'capitalize', margin:0}}>
                Bookmarks
              </h4> 
              <span style={{color:'#a0a0a0',  marginLeft:5}}>({bookmarkCnt?bookmarkCnt:0})</span>
            </Box>
          </Box>
          <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
          {props.role == 'admin' ?          
            <IconButton
                size="small"
                edge="start"
                aria-label="assign"
                style={{float:'right'}}
                onClick={()=>{props.setOpenBoothList(false)}}
              >
                <HighlightOffOutlinedIcon/>
            </IconButton>:
            <IconButton
              size="small"
              edge="start"
              aria-label="assign"
              style={{float:'right'}}
              onClick={()=>{props.setLeftPage('home')}}
            >
              <ChevronLeftIcon/>
            </IconButton>
            }
          </div>
        </Box>
        <Divider/>
        {loaded &&
        <Exhibitors boothList={boothList} setBoothList={setBoothList} role={props.role} bookmark = {1} open={loaded}/>
        }
        <LoadingOverlay
            active={loadingFlag}
            styles={{
                // spinner: (base) => ({
                // ...base,
                // width: '50px',
                // '& svg circle': {
                //     stroke: '#1976d2'
                // }
                // }),
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'absolute',
                left:'50%',
                top:'200px',
                transform:'translateX(-50%)',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return { 
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
    setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
    setBookmardUpdated:   data=>dispatch(setBookmardUpdated(data)),
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
    setExhibitorList:  data=>dispatch(setExhibitorList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
