import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';
import { setLeftSearchVal, setOpenBoothList, setLeftPage, setOpenSetBoothModal } from '../../../actions/ui'
import InputBase from '@mui/material/InputBase';
import EditIcon from '@mui/icons-material/Edit';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Button from '@mui/material/Button';
import CompanyEditModal from '../CompanyEditModal'
import { useEffect,useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import  area  from 'area-polygon'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setActive3dObject } from '../../../actions/main';
import { px2unit, unit2Px } from '../../../utils/CanvasTool'
import Fade from '@mui/material/Fade';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  width: '38px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

function BoothList(props) {
  const theme = useTheme();
  const [openCompanyModal, setOpenCompanyModal] = React.useState(false);
  const [boothList, setBoothList] = React.useState([]);
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [filteredBoothList, setFilteredBoothList] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  
  
  const [editBooth, setEditBooth]  = React.useState(null);
  const main_widow_offset_x = 0;
  const main_widow_offset_y = 51;
  
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if(props.ui.openBoothList){
      setLoadingFlag(true)
      setTimeout(function(){
        if(props.main.mainCanvas){
          var boothList = [];
          var objects = props.main.mainCanvas.getObjects('group');
          var convertRateX= 1
          if(props.main.mainCanvas.convertRateX) {
              var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
          }
          for(var i = 0; i< objects.length; i++){
            var object = objects[i]
            var row = {}
            if(object.get('class')=="table"){
              console.log("table", object)
              if(!object.class1) continue
              row['name'] = object.class1
              row['groupName'] = object.boothGroup?object.boothGroup:"Ungrouped"
              row['seats'] =1
              var class1 = object.class1;
              // if(id){
              //   // table-cv_Block_6_27__20_ROUND_20_TABLE_503
              //   var tmp = id.split("_");
              //   var index = tmp.indexOf('ROUND')
              //   if(index >=0) {
              //     var pathCnt = tmp[index + 1];
              //     row['seats'] = pathCnt/2
              //   }
              // }
              if(class1){
                // table-cv_Block_6_27__20_ROUND_20_TABLE_503
                var tmp = class1.split("_");
                if(tmp.length >=2) {
                  var pathCnt = tmp[1];
                  row['seats'] = pathCnt
                }
              }
              // var chairGroup = object.getObjects('group')
              // if(chairGroup && chairGroup.length > 0){
              //   var chairs = chairGroup[0].getObjects('path');
              //   if(chairs){
              //     row['seats'] = chairs.length;
              //   }
              // }
              // else{
              //   var chairs = object.getObjects('path');
              //   if(chairs){
              //     row['seats'] = chairs.length;
              //   }
              // }
              row['booth'] = object             
              boothList.push(row)
            }
          }
        }
      
        boothList.sort(function(a, b) {
          var a_val = (a.name)?a.name:''
          var b_val = (b.name)?b.name:''
          return a_val.toString().localeCompare(b_val.toString(), 'en', { numeric: true })
        });
        
        if(searchVal){
          var filteredBoothList = boothList.filter((booth)=>{
            var str = booth.name?booth.name:""
            return (str.toLowerCase().includes(searchVal.toLowerCase()))
          })
          setFilteredBoothList(filteredBoothList)
        }
        else{
          setFilteredBoothList(boothList)
        }

        setBoothList(boothList)
        
        setLoadingFlag(false)
      }, 100)
    }
  }, [props.ui.openBoothList,props.main.canvasRefresh])


  const doSearch = (searchVal)=>{
    if(searchVal){
      var filteredBoothList = boothList.filter((booth)=>{
        var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
        return (str.toLowerCase().includes(searchVal.toLowerCase()))
      })
      setFilteredBoothList(filteredBoothList)
    }
    else
      setFilteredBoothList(boothList)
  }
  const setActiveObject = (o) => {
    var mainCanvas = props.main.mainCanvas;
    mainCanvas.viewportTransform[0] = 1;
    mainCanvas.viewportTransform[3] = 1;
    mainCanvas.viewportTransform[4] = window.screen.width/2-o.left-o.width/2-main_widow_offset_x;
    mainCanvas.viewportTransform[5] = window.screen.height/2-o.top-o.height/2-main_widow_offset_y;
    window.scrollTo(0, 0)
    mainCanvas.requestRenderAll();
    mainCanvas.setActiveObject(o).renderAll();
    props.setActive3dObject(o)
  }
  var sumTable = 0;
  var sumChair = 0;
  const getGroupByName = (filteredBoothList)=>{
    let groupByName = filteredBoothList.reduce((r, a) => {
      r[a.groupName] = [...r[a.groupName] || [], a];
      return r;
     }, {});
    var output = [];
    for(var groupName in groupByName){
      var items = groupByName[groupName];
      var itemChairsCnt = 0;
      for(var i = 0; i < items.length; i++){
        itemChairsCnt+=  items[i].seats*1
      }
      sumTable+= items.length
      sumChair+= itemChairsCnt
      output.push({groupName, items: groupByName[groupName], itemChairsCnt: itemChairsCnt})
    }
    console.log("groupByName", output)
    return output;
  }

  var groupByName = getGroupByName(filteredBoothList)
  return (
    <Fade in={true} timeout={1200}>
      <div className='appLeftSearch'>
        <Box className="">
            <Box display="flex" pt={2} pb={2} style={{width:'100%', justifyContent:'space-between'}}>
              
                <div style={{marginLeft:20}}></div>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search table"
                    inputProps={{ 'aria-label': 'search table' }}
                    // value={searchVal}
                    onBlur={
                        (e) => {
                            setSearchVal(e.target.value)
                        }
                    }
                    onKeyDown={
                        (e)=>{
                            if(e.keyCode == 13){
                                e.preventDefault();
                                doSearch(e.target.value)
                            }
                        }
                    }
                    onChange={
                      (e)=>{
                        if(e.target.value == "" || !e.target.value){
                          doSearch(e.target.value)
                        }
                      }
                    }
                />
                <IconButton size="small" type="button" onClick = {()=>doSearch(searchVal)} aria-label="search">
                    <SearchIcon />
                </IconButton>
              <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
                {props.role == 'admin' ?          
                  <IconButton
                      size="small"
                      edge="start"
                      aria-label="assign"
                      style={{float:'right'}}
                      onClick={()=>{props.setOpenBoothList(false)}}
                    >
                      <HighlightOffOutlinedIcon/>
                  </IconButton>:
                  <IconButton
                    size="small"
                    edge="start"
                    aria-label="assign"
                    style={{float:'right'}}
                    onClick={()=>{props.setLeftPage('home')}}
                  >
                    <ChevronLeftIcon/>
                  </IconButton>
                }
              </div>
            </Box>
            <Divider />
            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                <Tab label={<b>Table Inventory</b>} {...a11yProps(0)} />
                <Tab label={<b>Summary</b>} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Box display="flex"  style={{justifyContent:'space-between', padding:'10px 16px', borderBottom:'1px solid #e0e0e0'}}>
                <Box style={{width: '70%', textAlign:'left'}}><b>Table Number</b></Box>
                <Box style={{width: '30%', textAlign:'left'}}><b>Seats</b></Box>
              </Box>
              {
                groupByName.map((group, index) => (
                  <Box>
                    <Box key={index} style={{textAlign:'left', padding:'10px 18px', borderBottom:'1px solid #e0e0e0'}}><b>{group.groupName}</b></Box>
                    <Box>
                      {
                        group.items.map((table, table_index) => (
                          <ListItem key={table_index}>
                            <ListItemText 
                              primary={
                                <Box display="flex" style={{justifyContent:'space-between'}}>
                                  <Box style={{width: '70%', textAlign:'left'}}><label>{table.name}</label></Box>
                                  <Box style={{width: '30%', textAlign:'left'}}><label>{table.seats}</label></Box>
                                </Box>
                              } 
                            />
                            
                          </ListItem>
                        ))
                      }
                    </Box>
                  </Box>
                ))
              }
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box display="flex"  style={{justifyContent:'space-between', padding:'10px 16px', borderBottom:'1px solid #e0e0e0'}}>
                <Box style={{width: '60%', textAlign:'left'}}><b>Table Group</b></Box>
                <Box style={{width: '20%', textAlign:'left'}}><b>Qty</b></Box>
                <Box style={{width: '20%', textAlign:'left'}}><b>Seats</b></Box>
              </Box>
              {
                groupByName.map((group, index) => (
                  <Box key={index}>
                    <Box>                    
                      <ListItem key={index}>
                        <ListItemText 
                          primary={
                            <Box display="flex" style={{justifyContent:'space-between'}}>
                              <Box style={{width: '60%', textAlign:'left', wordBreak:'break-word'}}><label>{group.groupName}</label></Box>
                              <Box style={{width: '20%', textAlign:'left'}}><label>{group.items.length}</label></Box>
                              <Box style={{width: '20%', textAlign:'left'}}><label>{(group.itemChairsCnt*1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label></Box>
                            </Box>
                          } 
                        />                          
                      </ListItem>
                    </Box>
                  </Box>
                ))
              }
              <Box>
                <Box>                    
                  <ListItem>
                    <ListItemText 
                      primary={
                        <Box display="flex" style={{justifyContent:'space-between'}}>
                          <Box style={{width: '60%', textAlign:'left', wordBreak:'break-word'}}><label>Total</label></Box>
                          <Box style={{width: '20%', textAlign:'left'}}><label>{sumTable}</label></Box>
                          <Box style={{width: '20%', textAlign:'left'}}><label>{(sumChair*1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label></Box>
                        </Box>
                      } 
                    />                          
                  </ListItem>
                </Box>
              </Box>
            </TabPanel>
            
            <LoadingOverlay
                active={loadingFlag}
                styles={{
                    // spinner: (base) => ({
                    // ...base,
                    // width: '50px',
                    // '& svg circle': {
                    //     stroke: '#1976d2'
                    // }
                    // }),
                    overlay: (base) => ({
                    ...base,
                    fontSize:'18px',
                    color: 'rgb(5, 37, 51)',
                    // background: 'rgb(229 229 229 / 92%)',
                    background: 'transparent',
                    position:'absolute',
                    left:'50%',
                    top:'200px',
                    transform:'translateX(-50%)',
                    zIndex:1000000000
                    })
                }}
                spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
                // spinner
                // text='Loading ...'
                >
            </LoadingOverlay> 
        </Box>
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setLeftSearchVal: value=>dispatch(setLeftSearchVal(value)) ,
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setOpenSetBoothModal:  data=>dispatch(setOpenSetBoothModal(data)),
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
  };
};

export default connect(mapStateToProps, mapDispatchProps)(BoothList);
