import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import logo from './logo.svg';
import './App.css';
import MainViewer from './viewer/MainViewer';
import MainViewer3D from './viewer3d/MainViewer';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ToolBox from './viewer/ToolBox';
import AppLeftPanel from './viewer/component/AppLeftPanel';
import { setAuthInfo, setLoading} from './actions/main'
import { setOpenCompanyProfile, setCustomStyles } from './actions/ui'
import { parseToken, getTokenCookie, setCookie,assetUrl } from './utils/Common'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import qs, { parse } from 'qs'
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile';
import { useParams } from 'react-router-dom'
import { API_URL, ESHOW_URL } from './config.js'

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [page, setPage] = React.useState(1);
  const page_size = 24;
  
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const processAuth = async ()=>{  
    var tokenInfo = await parseToken(query)     
    await fetchCustomStyles();           
    var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
    var user_key = tokenInfo.user_key?tokenInfo.user_key:'';
    var BOOTH_PRICING = tokenInfo.BOOTH_PRICING?tokenInfo.BOOTH_PRICING:'';
    var SHOPPING_CART = tokenInfo.SHOPPING_CART?tokenInfo.SHOPPING_CART:'';
    var SALES = tokenInfo.SALES
    props.setAuthInfo({show_key, user_key, SALES,BOOTH_PRICING,SHOPPING_CART, authenticated: (tokenInfo.show_key && tokenInfo.user_key?3:2), tokenInfo})
    if(!(tokenInfo.show_key && tokenInfo.user_key)){ 
        return
    }
  }

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    console.log("fetchCustomStyles")
    var url  = `${ESHOW_URL()}?method=getCSS`
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    }  
    
    const response = await axios.get(url, options)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){
                console.log("result.CSS", data.CSS)                    
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const openCompanyProfile=(exhibitor)=>{   
    var booth = {};
    booth.exhibitors = [{EXHIBITOR_KEY: exhibitor.EXHIBITOR_ID}]
    booth.booth_number = exhibitor?.BOOTHS

    props.setOpenCompanyProfile({booth:booth, open:true})
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fafafa',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display:'block'
  }));

  const fetchData = ()=>{    
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    props.setLoading(true)
    let page_key = ''
    for(var i = 0; i < props.ui.menuList?.length; i++){
      var menu = props.ui.menuList[i];      
        if((menu.URL == 'V3_CUSTOM1' || menu.URL == 'V3_CUSTOM2') && !menu.EXTERNAL_URL){
          if(public_token.includes(menu.PAGE_NAME)){
            page_key = menu.KEY_ID
          }
        }
    }

    var url = `${ESHOW_URL()}?method=getPageContent&page_key=${page_key}` 
    
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    axios.get(url, options).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
          if(result.PAGE){
            setData(result.PAGE)
          }
        }
        else{
          setData([])
        }
        setDataFetched(true)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {
    // if(props.urlToken){
    //     var public_token = `/${id1}/${id2}/${id3}`
    //     setCookie('V3PUBLICMAP', public_token+(id4?`/${id4}`:''))
    // }
    // processAuth()
    props.setOpenCompanyProfile(false)
    fetchData()
  }, [id5, id4, id3, id2, id1])


  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null

  return (
    <Box id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box className="xhbt-data-box" style={{maxWidth: 1200, margin:'64px auto', paddingLeft: 10, paddingRight:10, marginTop:(banner && banner != ''?(150+64):64)}}>
        <Box textAlign={'left'}>
          {
            <div dangerouslySetInnerHTML={{__html: data.HEADER_TEXT}}></div>
          }
        </Box>
      </Box>
      <LeftProfile groupOptions = {[]}/>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
