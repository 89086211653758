import undoable from 'redux-undo'

import {
  SET_BOOTH_DATA,
} from '../actions/booth_history';

const booth = (state = {
  boothList:[]
}, {type, payload}) =>{
  switch (type) {      
      case SET_BOOTH_DATA:
        // console.log("SET_BOOTH_DATA", payload)
        return {
          ...state,
          ...payload
        }
      default:
          return state;
  }
}

const undoableBooth = undoable(booth)

export default undoableBooth