import React ,{ useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import { fabric } from "fabric";
// import Cropper from 'react-easy-crop'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { API_URL } from '../../config.js'
import { setLoading } from '../../actions/main'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CropIcon from '@mui/icons-material/Crop';
import {PaperComponent} from '../../utils/Common'

function BackgroundModal(props) {
  const { onClose,  onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [imgSrc, setImgSrc] = useState('')
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const cropperRef = useRef(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    // console.log(croppedArea, croppedAreaPixels)
  }, [])

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  useEffect(() => {
    if(open){
      setCrop({ x: 0, y: 0 }) // Makes crop preview update between images.
      setZoom(1)
      setImgSrc(props.imgSrc)
    }
  }, [open])
  

  const onOk =async (e)=>{
    // const croppedImage = await getCroppedImg(
    //   imgSrc,
    //   croppedAreaPixels,
    //   0
    // )
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const dataUrl = cropper.getCroppedCanvas().toDataURL();
    cropper.getCroppedCanvas().toBlob(function(croppedImage) {
      var filename = "bg.png"
      // const reader = new FileReader();
      var dataImg = croppedImage.slice(0, croppedImage.size);
      props.onOk(dataImg, dataUrl)
    });

    
  }

  

  return (
    <Dialog 
      fullWidth={true}
      maxWidth={"md"}
      onClose={onClose} open={open}>
      <DialogContent>
        <Box>
          <Box mt={1} style={{position:'relative', width: '100%', height:'500px'}}>
            {Boolean(imgSrc) && 
              // <Cropper
              //   image={imgSrc}
              //   crop={crop}
              //   zoom={zoom}
              //   aspect={4 / 4}
              //   onCropChange={setCrop}
              //   onCropComplete={onCropComplete}
              //   onZoomChange={setZoom}
              // />
              // <ReactCrop 
              // style={{ height: 400, width: "100%" }}
              //   crop={crop} 
              //   onChange={c => setCrop(c)}
              // >
              //   <img src={imgSrc} />
              // </ReactCrop>
              <Cropper
                className={props.shape == 'circle'?'circle':''}
                src={imgSrc}
                style={{ height: 450, width: "100%" }}
                // Cropper.js options
                aspectRatio={props.initialAspectRatio? props.initialAspectRatio: 4 / 4}
                initialAspectRatio={props.initialAspectRatio? props.initialAspectRatio: 4 / 4}
                guides={false}
                crop={onCrop}
                ref={cropperRef}
                autoCropArea = {props.autoCropArea? props.autoCropArea: 0.8}
              />
            }
            
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setLoading:  data=>dispatch(setLoading(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(BackgroundModal);