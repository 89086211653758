import axios from 'axios';
export const SET_VIEW_DATA = 'SET_VIEW_DATA'
export const SET_VIEW_File = 'SET_VIEW_File'
export const SET_MAIN_CANVAS = 'SET_MAIN_CANVAS'
export const SET_G_GRID = 'SET_G_GRID'
export const SET_BG_CANVAS = 'SET_BG_CANVAS'
export const SET_BG_IMG_CANVAS = 'SET_BG_IMG_CANVAS'
export const SET_TOP_RULER = 'SET_TOP_RULER'
export const SET_LEFT_RULER = 'SET_LEFT_RULER'
export const SET_LAYERS = 'SET_LAYERS'
export const SET_PLANNER = 'SET_PLANNER'
export const SET_BOOKMARK_LIST = 'SET_BOOKMARK_LIST'
export const SET_IMPORT_LAYERS = 'SET_IMPORT_LAYERS'
export const SET_BG_INFO = 'SET_BG_INFO'
export const SET_BOOTH_INFO = 'SET_BOOTH_INFO'
export const SET_MAIN_SVG = 'SET_MAIN_SVG'
export const SET_SHOW_GRID = 'SET_SHOW_GRID'
export const SET_TURNON_SNAP = 'SET_TURNON_SNAP'
export const SET_BOOTH_LIST = 'SET_BOOTH_LIST'
export const SET_BOOTH_MG_INFO = 'SET_BOOTH_MG_INFO'
export const SET_BOOTH_FILE_LIST = 'SET_BOOTH_FILE_LIST'
export const SET_CANCAS_REFRESH = 'SET_CANCAS_REFRESH'
export const SET_LOADING = 'SET_LOADING'
export const SET_BOOTH_FILE_LIST_UPDATED = 'SET_BOOTH_FILE_LIST_UPDATED'
export const SET_TO_2D = 'SET_TO_2D'
export const SET_ACTIVE_BOOTH_FILE = 'SET_ACTIVE_BOOTH_FILE'
export const SET_ACTIVE_MAP_KEY = 'SET_ACTIVE_MAP_KEY'
export const SET_GRID_ANGLE = 'SET_GRID_ANGLE'
export const SET_GRID_SIZE = 'SET_GRID_SIZE'
export const SET_UNIT = 'SET_UNIT'
export const SET_TRIGER_DRAW_GRID = 'SET_TRIGER_DRAW_GRID'
export const SET_MENU_CONTROL = 'SET_MENU_CONTROL'
export const SET_DRAGGING_TARGET = 'SET_DRAGGING_TARGET'
export const SET_LAYER_BOOT_OUTLINE = 'SET_LAYER_BOOT_OUTLINE'
export const SET_LAYER_BOOT_NUMBER = 'SET_LAYER_BOOT_NUMBER'
export const SET_LAYER_ANNOTATION = 'SET_LAYER_ANNOTATION'
export const SET_LAYER_SPONSORSHIPS = 'SET_LAYER_SPONSORSHIPS'
export const SET_LAYER_VENUE = 'SET_LAYER_VENUE'
export const SET_LAYER_ORIGINAL = 'SET_LAYER_ORIGINAL'
export const SET_LAYER_DELETED = 'SET_LAYER_DELETED'
export const SET_TOP_DRAWING = 'SET_TOP_DRAWING'
export const SET_ELEMENT_DRAWING = 'SET_ELEMENT_DRAWING'
export const SET_BOOTH_LOADED = 'SET_BOOTH_LOADED'
export const SET_GRID_OFFSET = 'SET_GRID_OFFSET'
export const SET_TRIGGER_RULER = 'SET_TRIGGER_RULER'
export const RESET = 'RESET'
export const SET_TRIGGER_CENTER_FOCUS = 'SET_TRIGGER_CENTER_FOCUS'
export const SET_SAVE_FILE_NAME = 'SET_SAVE_FILE_NAME'
export const SET_DWG_IMPORTED = 'SET_DWG_IMPORTED'
export const SET_PRICING_OPTIONS = 'SET_PRICING_OPTIONS'
export const SET_PREMIUM_OPTIONS = 'SET_PREMIUM_OPTIONS'
export const SET_DISCOUNT_OPTIONS = 'SET_DISCOUNT_OPTIONS'
export const SET_GROUP_OPTIONS = 'SET_GROUP_OPTIONS'
export const SET_CART_LIST = 'SET_CART_LIST'
export const SET_SPONSORSHIPS_OPTIONS = 'SET_SPONSORSHIPS_OPTIONS'
export const SET_EXHIBITOR_LIST = 'SET_EXHIBITOR_LIST'
export const SET_CATEGORY_OPTIONS = 'SET_CATEGORY_OPTIONS'
export const SET_FEE_GROUP_OPTIONS = 'SET_FEE_GROUP_OPTIONS'
export const SET_FEE_CATEGORY_OPTIONS = 'SET_FEE_CATEGORY_OPTIONS'
export const SET_AUTH_INFO = 'SET_AUTH_INFO'
export const SET_ACTIVE_3D_OBJECT = 'SET_ACTIVE_3D_OBJECT'
export const SET_ORG_JSON = 'SET_ORG_JSON'
export const SET_SHOW_SETUP = 'SET_SHOW_SETUP'
export const SET_REMOVE_OBJECTS = 'SET_REMOVE_OBJECTS'
export const SET_MAP_REMOVE_OBJECTS = 'SET_MAP_REMOVE_OBJECTS'
export const SET_FIRST_MAP_CONTENT = 'SET_FIRST_MAP_CONTENT'
export const SET_MECHANT_ACCOUNT = 'SET_MECHANT_ACCOUNT'
export const SET_FIRST_BG_CONTENT = 'SET_FIRST_BG_CONTENT'
export const SET_OVERVIEW_OBJECT = 'SET_OVERVIEW_OBJECT'
export const SET_CUSTOM_PAGE_EXHIBITOR_LIST  = 'SET_CUSTOM_PAGE_EXHIBITOR_LIST'

export const setMapRemoveObjects = data =>({
    type:SET_MAP_REMOVE_OBJECTS,
    payload:data
});

export const setRemoveObjects = data =>({
    type:SET_REMOVE_OBJECTS,
    payload:data
});

export const setPricingOptions = data =>({
    type:SET_PRICING_OPTIONS,
    payload:data
});

export const setMechantAccount = data =>({
    type:SET_MECHANT_ACCOUNT,
    payload:data
});

export const setAuthInfo = data =>({
    type:SET_AUTH_INFO,
    payload:data
});

export const setActive3dObject= data =>({
    type:SET_ACTIVE_3D_OBJECT,
    payload:data
});

export const setOrgJson= data =>({
    type:SET_ORG_JSON,
    payload:data
});

export const setShowSetup = data =>({
    type:SET_SHOW_SETUP,
    payload:data
});

export const setPremiumOptions = data =>({
    type:SET_PREMIUM_OPTIONS,
    payload:data
});

export const setDiscountOptions = data =>({
    type:SET_DISCOUNT_OPTIONS,
    payload:data
});

export const setGroupOptions = data =>({
    type:SET_GROUP_OPTIONS,
    payload:data
});

export const setCartList = data =>({
    type:SET_CART_LIST,
    payload:data
});

export const setSponsorshipsOptions = data =>({
    type:SET_SPONSORSHIPS_OPTIONS,
    payload:data
});

export const setExhibitorList = data =>({
    type:SET_EXHIBITOR_LIST,
    payload:data
});

export const setFeeGroupOptions = data =>({
    type:SET_FEE_GROUP_OPTIONS,
    payload:data
});

export const setFeeCategoryOptions = data =>({
    type:SET_FEE_CATEGORY_OPTIONS,
    payload:data
});

export const setCategoryOptions = data =>({
    type:SET_CATEGORY_OPTIONS,
    payload:data
});
export const setSaveFileName = data =>({
    type:SET_SAVE_FILE_NAME,
    payload:data
});

export const setGridOffset = data =>({
    type:SET_GRID_OFFSET,
    payload:data
});
export const setBoothLoaded = data =>({
    type:SET_BOOTH_LOADED,
    payload:data
});
export const setTopDrawing = data =>({
    type:SET_TOP_DRAWING,
    payload:data
});
export const setElementDrawing = data =>({
    type:SET_ELEMENT_DRAWING,
    payload:data
});
export const setBoothOutlineLayer = data =>({
    type:SET_LAYER_BOOT_OUTLINE,
    payload:data
});
export const setBoothNumberLayer = data =>({
    type:SET_LAYER_BOOT_NUMBER,
    payload:data
});
export const setAnnotationLayer = data =>({
    type:SET_LAYER_ANNOTATION,
    payload:data
});
export const setSponsorshipsLayer = data =>({
    type:SET_LAYER_SPONSORSHIPS,
    payload:data
});
export const setVenueLayer = data =>({
    type:SET_LAYER_VENUE,
    payload:data
});
export const setOriginalLayer = data =>({
    type:SET_LAYER_ORIGINAL,
    payload:data
});

export const setDeletedLayer = data =>({
    type:SET_LAYER_DELETED,
    payload:data
});

export const setMenuControl = data =>({
    type:SET_MENU_CONTROL,
    payload:data
});

export const setLoading = data =>({
    type:SET_LOADING,
    payload:data
});

export const setViewData = data =>({
    type:SET_VIEW_DATA,
    payload:data
});

export const setViewFile = data =>({
    type:SET_VIEW_File,
    payload:data
});

export const setMainCanvas = data =>({
    type:SET_MAIN_CANVAS,
    payload:data
});
export const setgGrid = data =>({
    type:SET_G_GRID,
    payload:data
});

export const setBgCanvas = data =>({
    type:SET_BG_CANVAS,
    payload:data
});
export const setBgImgCanvas = data=>({
    type:SET_BG_IMG_CANVAS,
    payload:data
})
export const setLeftRuler = data =>({
    type:SET_LEFT_RULER,
    payload:data
});

export const setTopRuler = data =>({
    type:SET_TOP_RULER,
    payload:data
});

export const setMainLayers = data =>({
    type:SET_LAYERS,
    payload:data
});

export const setPlanner = data =>({
    type:SET_PLANNER,
    payload:data
});

export const setBookmarkList = data =>({
    type:SET_BOOKMARK_LIST,
    payload:data
});

export const setImportLayers = data =>({
    type:SET_IMPORT_LAYERS,
    payload:data
});

export const setBgInfo = data=>({
    type:SET_BG_INFO,
    payload:data
});

export const setBoothInfo = data=>({
    type:SET_BOOTH_INFO,
    payload:data
});

export const setMainSvg = data =>({
    type:SET_MAIN_SVG,
    payload:data
})

export const setShowGrid = data =>({
    type:SET_SHOW_GRID,
    payload:data
})

export const setSnapToGrid = data =>({
    type:SET_TURNON_SNAP,
    payload:data
})


export const setGridAngle = data =>({
    type:SET_GRID_ANGLE,
    payload:data
})

export const setGridSize = data =>({
    type:SET_GRID_SIZE,
    payload:data
})

export const setUnit = data =>({
    type:SET_UNIT,
    payload:data
})

export const setBoothList = data =>({
    type:SET_BOOTH_LIST,
    payload:data
});

export const setActiveBoothFile = data =>({
    type:SET_ACTIVE_BOOTH_FILE,
    payload:data
});


export const setActiveMapKey = data =>({
    type:SET_ACTIVE_MAP_KEY,
    payload:data
});

export const setBoothMgInfo = data =>({
    type:SET_BOOTH_MG_INFO,
    payload:data
});


export const setBoothFileList = data =>({
    type:SET_BOOTH_FILE_LIST,
    payload:data
});

export const setBoothFileListUpdated = data =>({
    type:SET_BOOTH_FILE_LIST_UPDATED,
    payload:data
});

export const setCanvasRefresh = data =>({
    type:SET_CANCAS_REFRESH,
    payload:data
});

export const to2D = (data) =>({
    type:SET_TO_2D,
    payload:data
});



export const setDraggingTarget = data =>({
    type:SET_DRAGGING_TARGET,
    payload:data
});

export const setTriggerRuler = data =>({
    type:SET_TRIGGER_RULER,
    payload:data
});


export const resetMain = data =>({
    type:RESET,
    payload:data
});

export const setTriggerCenterFocus = data =>({
    type:SET_TRIGGER_CENTER_FOCUS,
    payload:data
});


export const setDwgImported = data =>({
    type:SET_DWG_IMPORTED,
    payload:data
});

export const setFirstMapContent = data =>({
    type:SET_FIRST_MAP_CONTENT,
    payload:data
});

export const setFirstBgContent = data =>({
    type:SET_FIRST_BG_CONTENT,
    payload:data
});

export const setOverviewObject = data =>({
    type:SET_OVERVIEW_OBJECT,
    payload:data
});

export const setCustomPageExhibitorlist = data =>({
    type:SET_CUSTOM_PAGE_EXHIBITOR_LIST,
    payload:data
});