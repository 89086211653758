import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';
import { setLeftSearchVal, setOpenBoothList, setLeftPage, setOpenSponsorshipProfile, setOpenCompanyProfile, setOpenUnassignedProfile, setSnackMsg, setOpenSnack } from '../../../actions/ui'
import { setCartList } from '../../../actions/main'
import { setCart } from '../../../actions/cart'
import InputBase from '@mui/material/InputBase';
import EditIcon from '@mui/icons-material/Edit';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Button from '@mui/material/Button';
import CompanyEditModal from '../CompanyEditModal'
import { useEffect,useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import BuyCartModal from '../BuyCartModal'
import { v4 as uuidv4 } from 'uuid';

import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setActive3dObject } from '../../../actions/main';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { px2unit, unit2Px, searchObjectByKey } from '../../../utils/CanvasTool'
import { ESHOW_URL } from '../../../config.js'
import { getTokenCookie, getTokenPlanner, getCartCookie, resetCartCookie } from '../../../utils/Common.js'
import axios from 'axios';
import axiosInstance from '../../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import CloseIcon from '@mui/icons-material/Close';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { parseToken } from '../../../utils/Common'
import Fade from '@mui/material/Fade';
import AttachModal from '../AttachModal'

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  width: '38px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function BoothList(props) {
  let query = useQuery();
  const theme = useTheme();
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [openAttachModal, setOpenAttachModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  let cartList = props.main.cartList  
  const active_booth_file = props.main.active_booth_file

  useEffect(() => {
    if(props.open)
    {
      getCart()
    }
  }, [props.open, props.cart.carts])

  const handleOpenAttachModal = ()=>{
    setOpenAttachModal(true)
  }

  const handleCloseAttachModal = (groupedItem)=>{
    setOpenAttachModal(false)
  }

  const handleOkAttachModal = async(formdata, groupedItem = null)=>{
    var company = formdata.company
    var contacts = formdata.contacts
    var eshowExhibitor = formdata.eshowExhibitor
    var boothObjects = formdata.boothObjects
    var paymentResult = formdata.paymentResult
    var paymentMessage = paymentResult?.message
    var primary_booth = "";
    let payment = {
      DATETIME:paymentMessage?.time_created,
      TOKEN:paymentMessage?.accesstoken?.token,
      TRANS_ID:paymentMessage?.id,
      AMOUNT:paymentMessage?.amount,
      STATUS:paymentMessage?.status,
      RESPONSE:paymentResult?.message?JSON.stringify(paymentResult?.message):''
    }
    // var booth_sale_items = formdata.booth_sale_items
    if(1){
        var booths = []
        var sales = []
        let cartList = props.main.cartList
        console.log("cartList", cartList)

        for(let i = 0; i < cartList?.length; i++){
          if(cartList[i]?.[0].PRODUCT_KEY){
            
            let object = searchObjectByKey(cartList[i]?.[0].BOOTH_KEY, props.main.mainCanvas)
            if(object){
              var saveBooth = {
                BOOTH_NO: object.booth_number,
                FABRICJS:JSON.stringify(object),
                ACTIVE: 1,
                STATUS: object.holdBooth?2:1,
                KEY_ID:object.KEY_ID,
              }
              booths.push(saveBooth)
            }
          }
        }

        for(let i = 0; i < cartList?.length; i++){
          if(cartList[i]?.[0].PRODUCT_KEY){
            sales.push(
              {
                BOOTH_NUMBER:cartList[i]?.[0]?.['VALUE']?.replace("$","")?.replaceAll(",",""),
                QTY:cartList[i]?.[0].QTY,
                AMOUNT:cartList[i]?.[2]?.['VALUE']?.replace("$","")?.replaceAll(",",""),
                TOTAL:cartList[i]?.[2]?.['VALUE']?.replace("$","")?.replaceAll(",",""),
                KEY_ID:uuidv4(),
                PRODUCT_KEY:cartList[i]?.[0].PRODUCT_KEY,
                PRICE_KEY:cartList[i]?.[0].PRICE_KEY,
                TITLE:cartList[i]?.[0].VALUE,
                TYPE:407,
              }
            )
          }
        }

        var exhibitor_contacts = []
        var contactName = ""
        for(var i = 0; i < contacts.length; i++){
            var contact = contacts[i]
            exhibitor_contacts.push({
                ROLE_TYPE:contact.ROLE_TYPE?contact.ROLE_TYPE:"11",
                ACTIVE:1,
                PHONE_EXT:contact.PHONE_EXT?contact.PHONE_EXT:"",
                PHONE:contact.PHONE?contact.PHONE:"",
                KEY_ID:contact.SALES_CONTACT?(contact.EXISTING_CONTACT):uuidv4(),
                LAST_NAME:contact.LAST_NAME?contact.LAST_NAME:"",
                EMAIL:contact.EMAIL?contact.EMAIL:"",
                FIRST_NAME:contact.FIRST_NAME?contact.FIRST_NAME:"",
                CREDENTIALS:contact.CREDENTIALS?contact.CREDENTIALS:"",
                CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
                TITLE:contact.TITLE?contact.TITLE:"",
                CRM_CONTACT_KEY:contact.KEY_ID?contact.KEY_ID:"",
                TYPE:contact.TYPE?contact.TYPE:"605"
            })
            contactName = contact.TITLE?.trim()
        }
        var exhibitor_key = uuidv4().toUpperCase()
        var exhibitor = {
            ZIP_CODE:company.ZIP_CODE?company.ZIP_CODE:"",
            PHONE:company.PHONE?company.PHONE:"",
            STATE:company.STATE?company.STATE:"",
            STATUS:company.STATUS?company.STATUS:"3",
            COUNTRY:company.COUNTRY?company.COUNTRY:"",
            GROUP_KEY:exhibitor_key,
            ADDRESS1:company.ADDRESS1?company.ADDRESS1:"",
            ADDRESS2:company.ADDRESS2?company.ADDRESS2:"",
            DISPLAY_ORDER:company.DISPLAY_ORDER?company.DISPLAY_ORDER:"1",
            KEY_ID:exhibitor_key,
            PRIMARY_BOOTH:primary_booth,
            COMPANY_NAME:company.COMPANY_NAME?company.COMPANY_NAME:"",
            FORM_KEY:active_booth_file?.sales_form,
            CITY:company.CITY?company.CITY:"",
            BOOTH_SF:"100",
            ORDER_NUMBER:"",
            CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
            WEBSITE:"",
            BOOTHS:booths,
            CONTACTS:exhibitor_contacts,
            SALES: sales,
            PAYMENT: payment
        };
    }
       
    var postData = {
        EXHIBITOR: exhibitor,
        BOOTHS: [],
        SUCCESS: true,
    }
    const options = {
        headers: {
            'body': '[{"company:","test"}]', 
            'Content-Type': 'application/json'
          }
    }
    var url = `${ESHOW_URL()}?method=putExhibitor&token=${getTokenCookie()}`
    //test
    // console.log("postData", postData)
    // return;
    //test              
    setLoadingFlag(true)
    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
          // props.setOpenSnack(true);
          // props.setSnackMsg("Assigned successfully");
          // setOpenAttachModal(false)
          // emptyCart();
          checkSubmit()
        }
        else{
          setLoadingFlag(false)
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to assign");
        }   
    }).catch(error => {
        console.log("error", error);
        setLoadingFlag(false)
    })
  }

  const checkSubmit = async()=>{
    var active_booth_file = props.main.active_booth_file
      
      var  map_key = active_booth_file.KEY_ID;
      var  form_key;
      if(props.main.authInfo){
          form_key = active_booth_file.form_key
      }
      var query = `${ESHOW_URL()}?method=getCart&form_key=${form_key}&token=${getTokenCookie()}` 
      var parent_key = props.main.authInfo.SALES?.PARENT_KEY
      if(parent_key && parent_key!= ''){
        query = `${query}&parent_key=${parent_key}`
      }    
      else{
        parent_key = getCartCookie();
        query = `${query}&parent_key=${parent_key}`
      }
      var url = query;

      axiosInstance.get(url).then(res=>{
          var result = res.data;
          var data =result.CART 
          // props.setCartList(data)
          setConfirmation(result.CONFIRMATION)
          resetCartCookie()
          setLoadingFlag(false) 
          props.setCartList([])
      }).catch(error => {
        setLoadingFlag(false)
      })  
  }
  const getCart = ()=>{
    var active_booth_file = props.main.active_booth_file
      
      var  map_key = active_booth_file.KEY_ID;
      var  form_key;
      if(props.main.authInfo){
          form_key = active_booth_file.form_key
      }
      var query = `${ESHOW_URL()}?method=getCart&form_key=${form_key}&token=${getTokenCookie()}` 
      var parent_key = props.main.authInfo.SALES?.PARENT_KEY
      if(parent_key && parent_key!= ''){
        query = `${query}&parent_key=${parent_key}`
      }    
      else{
        parent_key = getCartCookie();
        query = `${query}&parent_key=${parent_key}`
      }
      var url = query;

      axiosInstance.get(url).then(res=>{
          var result = res.data;
          // if(result.SUCCESS){  
              if(result.CART){     
                  var data =result.CART 
                  props.setCartList(data)
                  setLoadingFlag(false)
              }
          // }
          else{
            props.setCartList([])
            setLoadingFlag(false)
          }
      }).catch(error => {
      })  
  }
  const emptyCart = ()=>{
    resetCartCookie()
    getCart()
  }

  const onRemove = (product) =>{      
    var active_booth_file = props.main.active_booth_file
    
    var  map_key = active_booth_file.KEY_ID;
    var  booths = product.BOOTH_KEY;
    var  form_key;
    if(props.main.authInfo){
        form_key = active_booth_file.form_key
    }
    var query = `${ESHOW_URL()}?method=remove_booth&form_key=${form_key}&booths=${booths}&booth_key=${booths}&map_key=${map_key}&token=${getTokenCookie()}`    
    
    if(product.ROW_ID){
      query = `${query}&row_id=${product.ROW_ID}`
    }
    var parent_key = props.main.authInfo.SALES?.PARENT_KEY
    if(parent_key && parent_key!= ''){
      query = `${query}&parent_key=${parent_key}`
    }    
    else{
      parent_key = getCartCookie();
      query = `${query}&parent_key=${parent_key}`
    }
    var purchase_url = '';
    purchase_url = props.main.authInfo.SALES?.PURCHASE_URL
    if(purchase_url && purchase_url!= ''){
      query = `${query}&${purchase_url}`
    }

    var url = query;
    var formdata = 
    {  
       query:query              
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    setLoadingFlag(true)
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        setLoadingFlag(false)
        // props.setLoading(false)
        if(result.SUCCESS){              
          getCart()
        }
        else{
        }
        if(result.MESSAGE){
          props.setOpenSnack(true);
          props.setSnackMsg((result.MESSAGE?(" "+result.MESSAGE):""));
        }
    }).catch(error => {
      setLoadingFlag(false)
      props.setOpenSnack(true);
      props.setSnackMsg("Something went wrong");
        //console.log("error", error);
    })  
  }

  return (
    <Fade in={true} timeout={1200}>
      <div className='appLeftSearch'>
        <Box className="">
            <Box display="flex" pt={2} pb={2} style={{width:'100%', justifyContent:'space-between', alignItems:'center'}}>
              
              <div style={{marginLeft:20}}></div>
              <h4 style={{width:'calc(100% - 40px)', margin:0}}>Shopping Cart</h4>
              <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
              {props.role == 'admin' ?          
                <IconButton
                    size="small"
                    edge="start"
                    aria-label="assign"
                    style={{float:'right'}}
                    onClick={()=>{props.setOpenBoothList(false)}}
                  >
                    <HighlightOffOutlinedIcon/>
                </IconButton>:
                <IconButton
                  size="small"
                  edge="start"
                  aria-label="assign"
                  style={{float:'right'}}
                  onClick={()=>{props.setLeftPage('home')}}
                >
                  <ChevronLeftIcon/>
                </IconButton>
                }
              </div>
            </Box>
            <Divider />
            {cartList&&
            <>
              <Table className="pricing-table" aria-label="simple table">
                  
                  <TableBody>
                      {cartList.map((row, index) => {
                        return(
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            {
                              row.map((item, index1) => {
                                return(
                                  <TableCell 
                                    colSpan={item.COLSPAN?item.COLSPAN:1}
                                    style={
                                      {
                                        fontWeight:(item.FONT_WEIGHT?item.FONT_WEIGHT:'normal'),
                                        width:(item.WIDTH+'px'),
                                        textAlign:(item.TEXT_ALIGN?item.TEXT_ALIGN:'left'),
                                      }
                                    } 
                                    key={index1}>{item.VALUE}
                                  </TableCell>
                                )
                              })
                            }
                            <TableCell>
                            {row[0]?.PRODUCT_KEY &&
                            <IconButton
                              size="small"
                              edge="start"
                              aria-label="assign"
                              onClick={()=>{onRemove(row[0])}}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>                              
                            }
                            </TableCell>
                          </TableRow>
                        )
                      })}
                     
                  </TableBody>
              </Table>
            </>
            }
            
            <Box mt={2} display={'flex'} justifyContent={'center'}>
             <Button
              onClick={()=>{      
                props.setOpenBoothList(false)
                props.setOpenUnassignedProfile(false)
                props.setOpenCompanyProfile(false)
                if(props.ui.historySponsorshipProfile){            
                  props.setOpenSponsorshipProfile({...props.ui.historySponsorshipProfile, open:true})
                }         
              }}
              className='continue-shopping-button' style={{margin:'0 5px'}} variant="outlined" >
                 Continue Shopping
              </Button>
             <Button
              onClick={()=>{
                emptyCart();
              }}
              className='empty-cart-button' style={{margin:'0 5px'}} variant="outlined" >
                 Empty Cart
              </Button>
             <Button
              disabled = {!(cartList?.length)}
              onClick={()=>{
                handleOpenAttachModal()
              }}
              className='proceed-checkout-button' style={{margin:'0 5px'}} variant="contained" >
                 Proceed to Checkout
              </Button>
            </Box>
            
            
            {openAttachModal&&
            <AttachModal
                open={openAttachModal}
                onClose={handleCloseAttachModal}
                onOk={handleOkAttachModal}
                boothObjects = {[]}
                boothTexts = {''}
                grouppingBooth = {false}
                assignType='sponsorships'
                confirmation={confirmation}
                // setGrouppingBooth={setGrouppingBooth}
                // onSaveIndividuals = {onSaveIndividuals}
            />
            }

            <LoadingOverlay
                active={loadingFlag}
                styles={{
                    // spinner: (base) => ({
                    // ...base,
                    // width: '50px',
                    // '& svg circle': {
                    //     stroke: '#1976d2'
                    // }
                    // }),
                    overlay: (base) => ({
                    ...base,
                    fontSize:'18px',
                    color: 'rgb(5, 37, 51)',
                    // background: 'rgb(229 229 229 / 92%)',
                    background: 'transparent',
                    position:'absolute',
                    left:'50%',
                    top:'200px',
                    transform:'translateX(-50%)',
                    zIndex:1000000000
                    })
                }}
                spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
                // spinner
                // text='Loading ...'
                >
            </LoadingOverlay> 
        </Box>
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    log: state.log,
    cart: state.cart,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setLeftSearchVal: value=>dispatch(setLeftSearchVal(value)) ,
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setCart: value=>dispatch(setCart(value)) ,
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
    setCartList: value=>dispatch(setCartList(value)),
    setOpenUnassignedProfile:  data=>dispatch(setOpenUnassignedProfile(data)),
    setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
    setOpenSponsorshipProfile:  data=>dispatch(setOpenSponsorshipProfile(data)),
    setSnackMsg: data=>dispatch(setSnackMsg(data)),
    setOpenSnack: data=>dispatch(setOpenSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(BoothList);
