import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import Paper from '@mui/material/Paper';
import { SwatchesPicker , PhotoshopPicker  } from 'react-color'
import { convertRgbHex, getDefaultGroup, convertRgbHex2 } from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

const PaperComponent=(props)=>{
  return (
    <Paper {...props}  sx={{width:'370px', height:'535px'}} />
  );
}

function FilenameModal(props) {
  const { onClose, onOk, onChange, open } = props;
  var mainLayers = props.main.mainLayers;

  useEffect(() => {
  }, [])
  
  const onChangeName = (e)=>{
    onChange(e)
  }
  var color = (props.color?convertRgbHex2(props.color):'')
  var presetColors= [
    ['#B71C1C','#D32F2F','#F44336','#E57373','#FFCDD2','#0D47A1','#1976D2','#1976D2','#64B5F6','#BBDEFB','#33691E','#689F38','#8BC34A','#AED581','#DCEDC8','#BF360C','#E64A19','#FF5722','#FF8A65','#FFCCBC'],
    ['#880E4F','#C2185B','#E91E63','#F06292','#F8BBD0','#01579B','#0288D1','#03A9F4','#4FC3F7','#B3E5FC','#827717','#AFB42B','#CDDC39','#DCE775','#F0F4C3','#3E2723','#5D4037','#795548','#A1887F','#D7CCC8'],
    ['#4A148C','#7B1FA2','#9C27B0','#BA68C8','#E1BEE7','#006064','#0097A7','#00BCD4','#4DD0E1','#B2EBF2','#F57F17','#FBC02D','#FFEB3B','#FFF176','#FFF9C4','#263238','#455A64','#607D8B','#90A4AE','#CFD8DC'],
    ['#311B92','#512DA8','#673AB7','#9575CD','#D1C4E9','#004D40','#00796B','#009688','#4DB6AC','#B2DFDB','#FF6F00','#FFA000','#FFC107','#FFD54F','#FFECB3','#000000','#525252','#969696','#D9D9D9','#FFFFFF'],
    ['#1A237E','#303F9F','#3F51B5','#7986CB','#C5CAE9','#194D33','#388E3C','#4CAF50','#81C784','#C8E6C9','#E65100','#F57C00','#FF9800','#FFB74D','#FFE0B2'],
  ]
  var flag = false;
  for(var i = 0; i < presetColors.length; i++){
    for(var j = 0; j < presetColors[i].length; j++){
      if( presetColors[i][j] == color){
        flag = true; 
      }
    }
  }
  if(!flag){
    presetColors= [
      ['#B71C1C','#D32F2F','#F44336','#E57373','#FFCDD2','#0D47A1','#1976D2','#1976D2','#64B5F6','#BBDEFB','#33691E','#689F38','#8BC34A','#AED581','#DCEDC8','#BF360C','#E64A19','#FF5722','#FF8A65','#FFCCBC'],
      ['#880E4F','#C2185B','#E91E63','#F06292','#F8BBD0','#01579B','#0288D1','#03A9F4','#4FC3F7','#B3E5FC','#827717','#AFB42B','#CDDC39','#DCE775','#F0F4C3','#3E2723','#5D4037','#795548','#A1887F','#D7CCC8'],
      ['#4A148C','#7B1FA2','#9C27B0','#BA68C8','#E1BEE7','#006064','#0097A7','#00BCD4','#4DD0E1','#B2EBF2','#F57F17','#FBC02D','#FFEB3B','#FFF176','#FFF9C4','#263238','#455A64','#607D8B','#90A4AE','#CFD8DC'],
      ['#311B92','#512DA8','#673AB7','#9575CD','#D1C4E9','#004D40','#00796B','#009688','#4DB6AC','#B2DFDB','#FF6F00','#FFA000','#FFC107','#FFD54F','#FFECB3','#000000','#525252','#969696','#D9D9D9','#FFFFFF'],
      ['#1A237E','#303F9F','#3F51B5','#7986CB','#C5CAE9','#194D33','#388E3C','#4CAF50','#81C784','#C8E6C9','#E65100','#F57C00','#FF9800','#FFB74D','#FFE0B2', `${color}`],
    ]
  }
  console.log("presetColors",presetColors)
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'colorpicker-dialog-title'}}
      aria-labelledby="colorpicker-dialog-title"
      onClose={onClose} 
      open={props.open}>
      <DialogTitle id='filename-dialog-title' style={{display:'flex', alignItems:'center'}}>Set Color</DialogTitle>
      <DialogContent>
        <SwatchesPicker 
            height ={400}
            className="group-color-picker"
            color={ props.color }
            onChange={ props.onChange }
            onChangeComplete={ props.onChangeComplete}
            colors  = {presetColors}
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(FilenameModal);