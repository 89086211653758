import React ,{ useEffect, useRef, useState } from 'react';
import { fabric } from "fabric-with-gestures";
import axios from 'axios';
import qs, { parse } from 'qs'
import { connect } from 'react-redux'
import './View.css';
import { useNavigate } from 'react-router-dom'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import MoonLoader from 'react-spinners/MoonLoader'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { renderBorder, redrawobjectborder, adjustobjectborder, setObjectBG, changeBorderColor, changeSingleBG, getBGColor, addText, composeLoadObjects, initCanvas, afterCanvasLoad, changeBorderWidth, px2unit, unit2Px,addLocationIcon,removeLocationIcon,locationScaling,LightenDarkenColor,getBoothByIcon, toggleDrawMode} from '../utils/CanvasTool'
import Box from '@mui/material/Box';
import Notfound from './component/Notfound'
import { makeStyles } from '@mui/styles';
import h337 from "heatmap.js";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { VideoSeekSlider } from "react-video-seek-slider";
import "react-video-seek-slider/styles.css"
import html2canvas from "html2canvas"
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";
import moment from 'moment';
import { API_URL, ESHOW_URL, LOCATION_WIDTH } from '../config.js'
import { setBoothLoaded, setMainCanvas,setBgImgCanvas, setMainSvg, setImportLayers, setMainLayers, setBoothList, setBoothFileList, setCanvasRefresh, setLoading, setActiveBoothFile, setMenuControl, setShowGrid, resetMain, setPricingOptions, setPremiumOptions, setDiscountOptions, setGroupOptions, setCategoryOptions, setAuthInfo, setFirstMapContent, setFirstBgContent, setShowSetup, setExhibitorList } from '../actions/main'
import { setSnackMsg, setOpenSnack, setCustomStyles } from '../actions/ui'
import { setBoothHistory } from '../actions/booth_history'
import { setOpenCompanyProfile, setOpenUnassignedProfile, resetUI, setOpenBoothList, setLeftPage } from '../actions/ui'
import Snackbar from '@mui/material/Snackbar';
import { parseToken, buildObjectProperty, download, containsObject, convertRgbHex, convertRgb, getDefaultGroup, uuidRegexExp, isUUID, setCookie, getCookie, getTokenCookie, getTokenPlanner} from '../utils/Common'
var leftRuler, topRuler
var g_zoomTimeout =false
var g_carts;
var g_ajaxFailCnt = 0
var watermark = 0;
var bgImgCanvas
var g_groupOptions
var g_mouseUpFlag = false
var g_authInfo = false
var pinchFlag = false
var zoomStartScale = 1;
var preScale = 1;
var pausePanning;
var intervalPlay = false
var heatmapInstance = false
var rec;
function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

var mediaRecorder = false

function saveFile(recordedChunks){

    const blob = new Blob(recordedChunks, {
    type: 'video/webm'
    });
    let filename = window.prompt('Enter file name'),
        downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${filename}.webm`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(blob); // clear from memory
    document.body.removeChild(downloadLink);
}

  var g_leadPlayPos = 0;
  var hitmapdata = [];
function MainViewer(props) {
    const navigate = useNavigate()
    let query = useQuery();
    const imgRef = useRef(null);
    const [snackMsg, setSnackMsg] = useState('');
    const [openSnack, setOpenSnack] = useState(false);
    const [maincanvas_width, setMaincanvasWidth] = useState(false)
    const [maincanvas_height, setMaincanvasHeight] = useState(false)
    const [leadData, setLeadData] = useState([])
    const [heatmapSetup, setHeatmapSetup] = useState(false)    
    const [played, setPlayed] = useState(false);
    const [leadPlayPos, setLeadPlayPos] = useState(0);
    var boothFileList = props.main.boothFileList;
    var authInfo = props.main.authInfo
    var active_booth_file = props.main.active_booth_file
    
    const useStyles = makeStyles((theme) => ({
        btnLabel: {
          color: props.ui?.customStyles?.['secondary-menu-icon-color']?props.ui?.customStyles['secondary-menu-icon-color']:'white',
          "&:hover": {
            color: props.ui?.customStyles?.['secondary-menu-icon-color-hover']?props.ui?.customStyles['secondary-menu-icon-color-hover']:'#eeeeee',
          },
          "&.active": {
            color: props.ui?.customStyles?.['secondary-menu-icon-color-hover']?props.ui?.customStyles['secondary-menu-icon-color-hover']:'#eeeeee',
          },
        },
        footer:{
            background:props.ui?.customStyles?.['footer-bg-color']?(props.ui?.customStyles['footer-bg-color']+"!important"):'white',
        }
    }));
    const classes = useStyles();

    const fetchLeads = (map_key)=>{       
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          } 
        var url = `${ESHOW_URL()}?method=getLeads&map_key=${map_key?map_key:""}`;
        
        axios.get(url, options).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                setLeadData(result)
            }
            else{
            }
            props.setLoading(false) 
        }).catch(error => {
            props.setLoading(false) 
        })  
    }

    const fetchHeatmapSetup = (map_key)=>{       
        
        // props.setLoading(true) 
        var url = `${ESHOW_URL()}?method=getHeatmapSetup&map_key=${map_key?map_key:""}`;
        // var url = `${ESHOW_URL()}?method=getLeads&token=${getTokenCookie()}&map_key=${map_key?map_key:""}`;
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axios.get(url, options).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                setHeatmapSetup(result)
            }
            else{
            }
        }).catch(error => {
        })  
    }

    const handleCloseSnack = ()=>{
        setOpenSnack(false);
    }    
   
    const fetchSetup =async (map_key)=>{
        var url = `${ESHOW_URL()}?method=getSetup&map_key=${map_key}`
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        const response = await axios.get(url, options)
        var result = false;
        if(response.status == 200){
            var data = response.data
            if(data.SUCCESS == true){
                result = data
            }
        }
        else{
            console.log("")
        }
        return result;
    }

    useEffect(() => {
        if(authInfo.authenticated == 3){
            var paddinTop = 55;
            var paddingBottom = 60;
            var paddingLeft = 0;
            
            setMaincanvasWidth(window.innerWidth-paddingLeft)
            setMaincanvasHeight(window.innerHeight-paddinTop-paddingBottom)
            document.getElementsByTagName("body")[0].style.overflowY='hidden'
            g_authInfo = authInfo
        }
        return () => {
            document.getElementsByTagName("body")[0].style.overflowY='auto'
        };
    }, [authInfo.authenticated])

    const getPositionLead = (lead)=>{
        var location  = lead.location
        var mapWidth = heatmapSetup?.CANVAS?.SIZE_X*1
        var mapHeight = heatmapSetup?.CANVAS?.SIZE_Y*1
        var offsetX = heatmapSetup?.CANVAS?.OFFSET_X*1
        var offsetY = heatmapSetup?.CANVAS?.OFFSET_Y*1
        var scaleX = 1
        var scaleY = 1
        if(heatmapSetup?.CANVAS?.SCALE_X && heatmapSetup?.CANVAS?.SCALE_Y){
            scaleX = heatmapSetup?.CANVAS?.SIZE_X/heatmapSetup?.CANVAS?.SCALE_X
            scaleY = heatmapSetup?.CANVAS?.SIZE_Y/heatmapSetup?.CANVAS?.SCALE_Y
        }

        if(location && mapWidth && mapHeight){
            var tmp = location.split(",")
            if(tmp.length == 2){
                var rate = maincanvas_height/mapHeight
                var p = {
                    x:tmp[0]*rate*scaleX + imgRef.current.offsetLeft + offsetX,
                    y:tmp[1]*rate*scaleY +imgRef.current.offsetTop + offsetY
                }
                return p
            }
        }
        return false
    }
    
    const getLeadsPerMinute = (startInd)=>{
        var output = [];
        var leadFeeds = leadData?.LEADS;
        var lead = leadFeeds[startInd]
        var end_date = moment(lead['create_date']).add(1, 'minutes').add(1, 'hours')
        for(var i = startInd; i < leadFeeds?.length; i++){
            var lead = leadFeeds[i];
            if(end_date.isAfter(lead['create_date'])){
                output.push({...lead})
            }
            else{
                break
            }
        }
        return output
    }

    const onPlay=async (save = false)=>{
        if(save){
            
            var destinationCanvas = document.getElementById("video-canvas")
            const chunks = []; 
            const stream = destinationCanvas.captureStream(); // grab our canvas MediaStream
            rec = new MediaRecorder(stream); // init the recorder
            rec.ondataavailable = function (e) {
                if (e.data.size > 0) {
                    chunks.push(e.data);
                }  
            };    
            rec.onstop = e => saveFile(chunks);

            rec.start();

        }
        setPlayed(true)
        setLeadPlayPos(0)
        g_leadPlayPos = 0;
        
        if(heatmapInstance){
            heatmapInstance.setData({
                max: 0,
                min: 0,
                data: []
            });
            heatmapInstance.repaint() 
        }  
        else{
            heatmapInstance = h337.create({
                container: document.querySelector('.canvas-viewer'),
                radius: 20
            });
        }

        hitmapdata = [];


        intervalPlay = setInterval(async () => {
            var leadFeeds = leadData?.LEADS;
            var endFlag = false;
            if(g_leadPlayPos < leadFeeds?.length){
                // hitmapdata = [];
                var leadsBulk = getLeadsPerMinute(g_leadPlayPos)
                console.log("leadsBulk length", leadsBulk.length)
                for(var bulkInd =0; bulkInd < leadsBulk?.length; bulkInd++){
                    if(g_leadPlayPos >= leadFeeds?.length){                        
                        endFlag = 1;
                        break;
                    }
                    var lead = leadFeeds[g_leadPlayPos]
                    var position = getPositionLead(lead);
                    if(position){
                        console.log("addData", {
                            x: position.x,
                            y: position.y,
                            value: 1
                        })
                        var newItem = 
                        {
                            x: position.x?.toFixed(0),
                            y: position.y?.toFixed(0),
                            value: 1
                        }
                        var found = false;
                        for(var i = 0; i < hitmapdata?.length; i++){
                            if(hitmapdata[i].x == newItem.x && hitmapdata[i].y == newItem.y){
                                hitmapdata[i].value +=10;
                                found = true; break;
                            }
                        }
                        if(!found){
                            hitmapdata.push(newItem)
                        }
                        // heatmapInstance.addData();
                    }
                    g_leadPlayPos++;
                    console.log("bulkInd", bulkInd)
                    console.log("g_leadPlayPos", g_leadPlayPos)
                }
                var data = {
                    max: hitmapdata.length,
                    min: 0,
                    data: hitmapdata
                  };
                heatmapInstance.setData(data);
                setLeadPlayPos(g_leadPlayPos)
                
                if(rec){
                    const element = document.querySelector("#canvas-viewer");  
                    html2canvas(element,{ logging: true, letterRendering: 1, useCORS: true }).then( ( canvas ) => {
                        console.log("canvas", canvas)
                        var destinationCanvas = document.getElementById("video-canvas")
                        var destCtx = destinationCanvas.getContext('2d');
                        destCtx.drawImage(canvas, 0, 0);

                        // downloadCanvas( canvas, 'screenshot' );
                    });
                }
            }
            else{
                endFlag = 1;
            }
            if(endFlag){      
                if(rec){
                    rec.stop();  
                    rec = false
                }  
                var getData = heatmapInstance.getData()
                // console.log("getData", getData);
                setPlayed(false)
                if(intervalPlay)
                    clearInterval(intervalPlay);

            }
        }, 200); 
    }
    

    const loadBoothById=async (id= null, boothFileList)=>{        
        var activeFile;
        for(var i = 0; i <boothFileList.length; i++){
            if(boothFileList[i]['KEY_ID'] == id){
                activeFile = boothFileList[i];
                break;
            }
        }
        if(id && activeFile){    
            var mapJS = activeFile.MAPJS;
            mapJS = mapJS.replaceAll('\r\n', ' ')
            mapJS = JSON.parse(mapJS)
            if(!mapJS) mapJS = {}
            var settingData = await fetchSetup(activeFile.KEY_ID);
            console.log("setupData", settingData)
            var convertRateX= Math.abs(mapJS.convertRateX)
            var mapWidthPx = unit2Px(settingData?.CANVAS?.SIZE_X, convertRateX, mapJS.mapUnit)
            var mapHeightPx = unit2Px(settingData?.CANVAS?.SIZE_Y, convertRateX, mapJS.mapUnit)
            var tmp = {
                mapWidth:settingData?.CANVAS?.SIZE_X, 
                mapHeight:settingData?.CANVAS?.SIZE_Y,
                mapUnit:settingData?.GENERAL?.UNIT?settingData?.GENERAL?.UNIT:'feet', 
                gridSize:settingData?.CANVAS?.GRID_SIZE?settingData?.CANVAS?.GRID_SIZE:10, 
                showGrid:settingData?.CANVAS?.GRID_ON, 
                snapToGrid: settingData?.CANVAS?.GRID_SNAP, 
                mapOffsetX: settingData?.MAP_OFFSET?.OFFSET_X, 
                mapOffsetY: settingData?.MAP_OFFSET?.OFFSET_Y, 
                gridAngle: settingData?.CANVAS?.GRID_ANGLE,
                boothfile_brand: settingData?.GENERAL?.BRAND_LOGO,
                header_banner: settingData?.GENERAL?.HEADER_BANNER,
                header_color: settingData?.GENERAL?.HEADER_COLOR,
                // sales_form: sales_form,
                event_home: settingData?.GENERAL?.HOME_LINK,
                form_key: settingData?.FORMS?.[0]?.KEY_ID,
                form_title: settingData?.FORMS?.[0]?.TITLE,
                endX:mapWidthPx,
                endY:mapHeightPx,
                startX:0,
                startY:0,
            }

            var content
            mapJS = {...mapJS, ...tmp}
            if(mapJS.mainContent && mapJS.mainContent != ""){
                content = JSON.parse(mapJS.mainContent)
            }                
            
            props.setActiveBoothFile({...activeFile, ...mapJS})
            // props.setLoading(false)
            return;
            
        }
    }

    useEffect(()=>{
        {
            if(!boothFileList || (boothFileList && boothFileList.length==0)){
                fetchboothlist()                

            }         
        }
    }, [])    


    const fetchboothlist=async (save_id=false)=>{   
        props.setLoading(true) 
        var url = `${ESHOW_URL()}?method=getMaps`;
        const options = {
            headers: {
              'Authorization': `Bearer ${getTokenCookie()}`
            }
          }  
        axios.get(url, options).then(res=>{
            const result = res.data;
            console.log(res.data)
            var tmp = [...res.data];
            var lastFile = null
            if(tmp.length > 0){
                tmp.sort(function (a, b) {
                    let fa = (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1,
                    fb = (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                  });
                lastFile = tmp[0]
            }

            props.setBoothFileList(tmp)

            var map_key;
            if(query.get("map")){   
                map_key =  query.get("map")       
            }
            else{
                map_key =  getLastFileKeyId(tmp)
            }
            loadBoothById(map_key, tmp);    
            fetchHeatmapSetup(map_key)         
            fetchLeads(map_key);
        }).catch(error => {
        })      
    }

    const getLastFileKeyId=(boothFileList)=>{
        var tmp = [...boothFileList];
        var lastFile = null
        if(tmp.length > 0){
            tmp.sort(function (a, b) {
                let fa = (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1,
                fb = (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            lastFile = tmp[0]
        }
        if(query.get('sales_map')){
            if(query.get('map')){
                lastFile = query.get('map')
            }
        }
        else{
            var lastFileSaved  = getCookie('last_map')    
            if(lastFileSaved){
                for(var i = 0; i <tmp.length; i++){
                    var mapJS = tmp[i].MAPJS;
                    try {
                        mapJS = mapJS.replaceAll('\r\n', ' ')
                        mapJS = JSON.parse(mapJS)
                        if((""+tmp[i]['KEY_ID']).toLowerCase() == (""+lastFileSaved).toLowerCase() && lastFileSaved){
                            lastFile = tmp[i]
                        }
                    }
                    catch(e){
                        continue;
                    }
                }
            }
        }
        return lastFile?lastFile['KEY_ID']:false
    }


    var sales_auto_refresh = false
    // var paddinTop = 51;
    var paddinTop = 0;
    var status_red
    var status_yellow
    var status_green
    var sales_sponsor_show = true
    if(props.main.authInfo && props.main.authInfo.SALES){
      
      if(props.main.authInfo.SALES.AUTO_REFRESH == 'show' || props.main.authInfo.SALES.AUTO_REFRESH == true){
        sales_auto_refresh = true
        // if(ajaxFailCnt == 0){
            status_red = "/asset/green.png"
        // }else if(ajaxFailCnt == 1){
            status_yellow = "/asset/yellow.png"
        // }else if(ajaxFailCnt > 1){
            status_green = "/asset/red.png"
        // }
      }
    }
    return (
        <>
        {authInfo.authenticated == 2&&
            <Notfound/>
        }
        {authInfo.authenticated == 3&& maincanvas_width && maincanvas_height &&
            <>
                <Box className="main-container"  sx={{ flexGrow: 1, height:'100vh'}}>
                    <div className="canvas-viewer"  id="canvas-viewer" style={{paddingTop: 55, paddingBottom:60, width:'100%'}}>
                        <div style={{textAlign:'center'}}>
                            <div style={{margin:'auto'}}>
                                {heatmapSetup?.CANVAS?.HEATMAP_BACKGROUND &&
                                <img id="headmap-img" style={{border:'1px solid #cdc9c9'}} ref={imgRef} src={heatmapSetup?.CANVAS?.HEATMAP_BACKGROUND} with="auto" height={`${maincanvas_height}px`}/>
                                }        
                            </div>
                        </div>
                    </div>             
                    <Box className='play-slider' display='flex' alignItems='center'> 
                        <Button title="play" 
                            className='heat-play_button'
                            disabled={!leadData?.LEADS?.length}
                            style={{color:'#3a4e59', minWidth:'unset', margin:'0 12px'}}
                            onClick={
                                ()=>{
                                    if(played){        
                                        if(rec){
                                            rec.stop();  
                                            rec = false
                                        }
                                        if(mediaRecorder) {                
                                            mediaRecorder?.stop();
                                            mediaRecorder = false;
                                        }  
                                        setPlayed(false)
                                        if(intervalPlay)
                                            clearInterval(intervalPlay);
                                    }
                                    else{
                                        onPlay()
                                    }
                                }
                            }
                        >{played?<PauseCircleOutlineOutlinedIcon/>:<PlayCircleOutlinedIcon/>}</Button>
                        <Box style={{width:'calc(100% - 128px)', height:'18px', marginLeft:'8px'}}>
                            <VideoSeekSlider
                                max={leadData?.LEADS?.length}
                                currentTime={leadPlayPos}
                                // onChange={setCurrentTime}
                                secondsPrefix="00:00:"
                                minutesPrefix="00:"
                            />
                        </Box>
                        <Button 
                            className='heat-play_button'
                            disabled={played || (!leadData?.LEADS?.length)}
                            style={{color:'#3a4e59', minWidth:'unset', margin:'0 12px'}}
                            title="save" onClick={()=>{onPlay(true)}}
                        >
                            <FileDownloadIcon/>
                        </Button>
                    </Box>
                </Box>
                <div>
                    <canvas id="video-canvas" width={`${maincanvas_width}`} height={`${maincanvas_height}`}></canvas>
                </div>
            </>
        }
       <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            message={snackMsg}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />
        {props.ui?.customStyles?.['loaded'] &&
        <LoadingOverlay
            active={props.main.loadingFlag || authInfo.authenticated == 1}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                zIndex:1000000000
                })
            }}
            spinner = {
            <ScaleLoader 
                color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'}
                radius={2.5} 
                margin={2.5} 
                height={40} 
                width={5}
            />}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
        </>
    );
  }
  
  
const mapStateToProps = (state) => {
    return {
      main: state.main,
      booth_history: state.booth_history.present,
      ui: state.ui,
      cart: state.cart,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {    
        setMainCanvas: canvas=>dispatch(setMainCanvas(canvas)),
        setMainLayers: layers=>dispatch(setMainLayers(layers)),
        setImportLayers: layers=>dispatch(setImportLayers(layers)),
        setMainSvg: svg=>dispatch(setMainSvg(svg)),
        setBoothList: data=>dispatch(setBoothList(data)),
        setBoothFileList: data=>dispatch(setBoothFileList(data)) ,
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),
        onClearHistory: () => dispatch(UndoActionCreators.clearHistory()),  
        setActiveBoothFile: data=>dispatch(setActiveBoothFile(data)),  
        setLoading:  data=>dispatch(setLoading(data)),
        setMenuControl:  data=>dispatch(setMenuControl(data)),
        setShowGrid:  data=>dispatch(setShowGrid(data)),
        setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
        setOpenBoothList:  data=>dispatch(setOpenBoothList(data)),
        setLeftPage:  data=>dispatch(setLeftPage(data)),
        setOpenUnassignedProfile:  data=>dispatch(setOpenUnassignedProfile(data)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),
        setCustomStyles: data=>dispatch(setCustomStyles(data)),
        setBoothLoaded: data=>dispatch(setBoothLoaded(data)),
        resetUI:  data=>dispatch(resetUI(data)),
        resetMain:  data=>dispatch(resetMain(data)),
        setPremiumOptions:  data=>dispatch(setPremiumOptions(data)),
        setPricingOptions:  data=>dispatch(setPricingOptions(data)),
        setDiscountOptions:  data=>dispatch(setDiscountOptions(data)),
        setGroupOptions:  data=>dispatch(setGroupOptions(data)),
        setCategoryOptions:  data=>dispatch(setCategoryOptions(data)),
        setAuthInfo:  data=>dispatch(setAuthInfo(data)),
        setFirstMapContent:  data=>dispatch(setFirstMapContent(data)),
        setBgImgCanvas: data=>dispatch(setBgImgCanvas(data)),
        setFirstBgContent:  data=>dispatch(setFirstBgContent(data)),
        setExhibitorList:  data=>dispatch(setExhibitorList(data)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(MainViewer);



  