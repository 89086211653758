import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';

const drawerWidth = 240;


const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

function BoothList(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const main_widow_offset_x = 0;
  const main_widow_offset_y = 64;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const setActiveObject = (id) => {
    var mainCanvas = props.main.mainCanvas;
    mainCanvas.getObjects().forEach(function(o) {
      if(o.id == "booth-"+id) {
        console.log("selected", o)
        mainCanvas.viewportTransform[0] = 1;
        mainCanvas.viewportTransform[3] = 1;
        mainCanvas.viewportTransform[4] = window.screen.width/2-o.left-o.width/2-main_widow_offset_x;
        mainCanvas.viewportTransform[5] = window.screen.height/2-o.top-o.height/2-main_widow_offset_y;
        window.scrollTo(0, 0)
        mainCanvas.requestRenderAll();
        mainCanvas.setActiveObject(o).renderAll();
      }      
    })
  }

  const boothList=props.main.boothList
  return (
    <Box className="sideview" sx={{ display: 'flex' }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        className="booth-list-slider"
      >
        <div>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <h4>BOOTH LIST</h4>
        </div>
        <Divider />
        {boothList&&
        <List>
          {boothList.map((booth, index) => (
            <ListItem button key={index} onClick={()=>setActiveObject(booth.id)}>            
              <ListItemText primary={booth.id+": "+booth.boothName} />
            </ListItem>
          ))}
        </List>
        }
      </Drawer>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
  };
};

export default connect(mapStateToProps, mapDispatchProps)(BoothList);
