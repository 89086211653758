import React ,{ useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import {SketchField, Tools} from './sketch_main';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

import Tooltip from '@mui/material/Tooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import DeselectIcon from '@mui/icons-material/Deselect';
import { setDraggingTarget, setBoothList, setCanvasRefresh, setTopDrawing, setSponsorshipsLayer } from '../../actions/main'
import { setPanFlag } from '../../actions/ui'
import { setBoothHistory } from '../../actions/booth_history'
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import BuildIcon from '@mui/icons-material/Build';
import ColorModal from "./ColorModal"
import { fabric } from "fabric";
import { px2unit, unit2Px, sendToBackGrid} from '../../utils/CanvasTool'


const actions = [
  { icon: <BorderColorIcon />, name: 'Copy' },
  { icon: <RemoveOutlinedIcon />, name: 'Save' },
  { icon: <ArrowRightAltOutlinedIcon />, name: 'Print' },
  { icon: <RectangleOutlinedIcon />, name: 'Share' },
  { icon: <CircleOutlinedIcon />, name: 'Share' },
  { icon: <TextFormatIcon />, name: 'Share' },
];

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#ffffffa0',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
    color:"white"
  },
}));

const MenuButton = styled(Button)(({ theme }) => ({
  color: '#4E7090',
  padding:'10px',
  width:'100%',
  textAlign:'left',
  justifyContent:'start',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#4E7090',
    color:"white"
  },
}));

function Index(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  // const [openDraw, setOpenDraw] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tool, setTool] = React.useState(null)
  const [_sketch,setSketch] = React.useState(null)
  const [fontSize, setFontSize] = React.useState(25);
  const [color, setColor] = React.useState('#000000');
  const [fillColor, setFillColor] = React.useState('#ff0000');
  
  const [lineWidth, setLineWidth] = React.useState(1);
  const [openColorModal, setOpenColorModal] = React.useState(null);
  const [openFillColorModal, setOpenFillColorModal] = React.useState(null);
  
  const openDraw = props.openDraw

  useEffect(() => {
    if(!openDraw){
      if(_sketch)
        _sketch.reset()
      setTool(null)
      
      setTimeout(function(){
        props.setCanvasRefresh(true); 
      },100)
    }
    else{
      setTool(null)
    }
    if(openDraw){
      props.setSponsorshipsLayer(true)
    }
  }, [openDraw])

  useEffect(() => {
    if(props.ui.panFlag){
      if(_sketch)
        _sketch.reset()
      setTool(null)
      props.setCanvasRefresh(true); 
    }
  }, [props.ui.panFlag])

  useEffect(() => {
    if(props.main.elementDrawing){     
    }
  }, [props.main.elementDrawing])

  useEffect(() => {
    if(tool){
      props.setPanFlag(false)
    }
    else{      
      
      if(_sketch)
        _sketch.reset()
      setTimeout(function(){
        props.setCanvasRefresh(true); 
      },100)
    }
  }, [tool])

  const onSetBGColor=(color)=>{

  }
  const onDrawDone = (object)=>{
    var mainCanvas = props.main.mainCanvas
    if(object){
      mainCanvas.setActiveObject(object)
      
      // var newSelectObj = new fabric.ActiveSelection([object], {canvas:mainCanvas});
      // mainCanvas.setActiveObject(newSelectObj);

      setTool(null)
  
      var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
      var realMapWidth;
      var realMapHeight;
      var realGrid;
      var realOffsetX;
      var realOffsetY;
  
      var offsetX = 0;
      var offsetY = 0;
      var mapWidth = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
      var mapHeight = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
      
      if(convertRateX && convertRateY){
          realMapWidth = mapWidth
          realMapHeight = mapHeight          
          realGrid = unit2Px(mainCanvas.gridSize?mainCanvas.gridSize:0, convertRateX, mainCanvas.mapUnit)
          realOffsetX = unit2Px(offsetX, convertRateX, mainCanvas.mapUnit)
          realOffsetY = unit2Px(offsetY, convertRateX, mainCanvas.mapUnit)          
          
          realOffsetX = mainCanvas.gridOffsetX?((mainCanvas.gridOffsetX % realGrid )-realGrid):50
          realOffsetY = mainCanvas.gridOffsetY?((mainCanvas.gridOffsetY % realGrid )-realGrid):50
          realOffsetX+= unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
          realOffsetY+= unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
      }
  
  
      if(mainCanvas.snapToGrid){
          var angle = object.get('angle');
          var gridAngle = mainCanvas.gridAngle?mainCanvas.gridAngle:0
          if(gridAngle != angle) return;
          var canvasCenter = new fabric.Point(50, 50);
          var objectOrigin = new fabric.Point(object.left, object.top);
          var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(-1))
          var left = Math.round((new_loc.x-realOffsetX) / realGrid) * realGrid+realOffsetX;
          var top = Math.round((new_loc.y-realOffsetY) / realGrid) * realGrid+realOffsetY;
  
          objectOrigin = new fabric.Point(left, top);
          new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(1))
  
          object.set({
            left: new_loc.x,
            top: new_loc.y
          });
      }

      object.set({
        lockScalingX: false,
        lockScalingY: false,
        lockRotation: false,
        snapAngle: 5,
        bgColorCustom: fillColor,
        borderColorCustom: color,
        layer:'sponsorships'
      })
      // sendToBackGrid(object, mainCanvas)
      mainCanvas.renderAll()
    }
    // object.set({selectable:false, evented: false})
  }

  

  return (
      <>
        
           
        {/* <Collapse orientation="horizontal" in={openDraw}>
          <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{height:'100%'}}>
            <Button className={tool==Tools.Pencil?"active":""} onClick={()=>{if(tool==Tools.Pencil) props.setCanvasRefresh(true); else {props.setTopDrawing(true);} setTool(tool!=Tools.Pencil?Tools.Pencil:null)}}><BorderColorIcon size="small"/></Button>
            <Button className={tool==Tools.Line?"active":""} onClick={()=>{if(tool==Tools.Line) props.setCanvasRefresh(true);else {props.setTopDrawing(true);} setTool(tool!=Tools.Line?Tools.Line:null)}}><RemoveOutlinedIcon size="small"/></Button>
            <Button className={tool==Tools.Arrow?"active":""} onClick={()=>{if(tool==Tools.Arrow) props.setCanvasRefresh(true);else {props.setTopDrawing(true);} setTool(tool!=Tools.Arrow?Tools.Arrow:null)}}><ArrowRightAltOutlinedIcon size="small"/></Button>
            <Button className={tool==Tools.Rectangle?"active":""} onClick={()=>{if(tool==Tools.Rectangle) props.setCanvasRefresh(true);else {props.setTopDrawing(true);} setTool(tool!=Tools.Rectangle?Tools.Rectangle:null)}}><RectangleOutlinedIcon size="small"/></Button>
            <Button className={tool==Tools.Circle?"active":""} onClick={()=>{if(tool==Tools.Circle) props.setCanvasRefresh(true);else {props.setTopDrawing(true);} setTool(tool!=Tools.Circle?Tools.Circle:null)}}><CircleOutlinedIcon size="small"/></Button>         
            <Button onClick={(e) => {_sketch.addText("Text"); setTool(null); props.setCanvasRefresh(true);}}><TextFormatIcon size="small"/></Button>
            
          </ButtonGroup>
        </Collapse> */}
        {Boolean(props.main.mainCanvas) && openDraw &&
        <>
          <SketchField             
            ref={(c) => setSketch(c)}
            canvas={props.main.mainCanvas}
            tool={tool} 
            main={props.main}
            setBoothList={props.setBoothList}
            setBoothHistory={props.setBoothHistory}
            lineColor={color}
            fillColor={fillColor}
            lineWidth={lineWidth}
            fontSize={fontSize*1}
            onDrawDone = {onDrawDone}
            />
            
          </>
        }
        {openDraw &&
        <Draggable
          handle=".handle"
          defaultPosition={{x: 0, y: 0}}
          position={null}
          grid={[2, 2]}
          scale={1}
          bounds="body"
          // onStart={this.handleStart}
          // onDrag={this.handleDrag}
          // onStop={this.handleStop}
          >
          <div className="draw-option">
            <Box display="flex" style={{justifyContent:'space-between', marginBottom: 10}}>
              <DragIndicatorIcon fontSize="small" style={{cursor:'move', width:20}} className="handle"/>
              <CloseIcon fontSize="small" style={{cursor:'pointer'}} onClick={()=> props.handleCloseDraw()}/>
            </Box>
            <Box style={{textAlign:'center'}}>
              <Box className="draw-buttons" display={'flex'} style={{marginBottom: 15, justifyContent:'center'}}>
                <Button className={tool==Tools.Pencil?"active":""} onClick={()=>{if(tool==Tools.Pencil) {setTool(null)} else {props.setTopDrawing(true); setTool(Tools.Pencil)}}}><BorderColorIcon size="small"/></Button>
                <Button className={tool==Tools.Line?"active":""} onClick={()=>{if(tool==Tools.Line) {setTool(null)} else {props.setTopDrawing(true); setTool(Tools.Line)}}}><RemoveOutlinedIcon size="small"/></Button>
                <Button className={tool==Tools.Arrow?"active":""} onClick={()=>{if(tool==Tools.Arrow) {setTool(null)} else {props.setTopDrawing(true); setTool(Tools.Arrow)}}}><ArrowRightAltOutlinedIcon size="small"/></Button>
                <Button className={tool==Tools.Rectangle?"active":""} onClick={()=>{if(tool==Tools.Rectangle) {setTool(null)} else {props.setTopDrawing(true); setTool(Tools.Rectangle)}}}><RectangleOutlinedIcon size="small"/></Button>
                <Button className={tool==Tools.Circle?"active":""} onClick={()=>{if(tool==Tools.Circle) {setTool(null)} else {props.setTopDrawing(true); setTool(Tools.Circle)}}}><CircleOutlinedIcon size="small"/></Button> 
                <Button className={tool==Tools.Textbox?"active":""} onClick={()=>{if(tool==Tools.Textbox) {setTool(null)} else {props.setTopDrawing(true); setTool(Tools.Textbox)}}}><TextFormatIcon size="small"/></Button>                 
                {/* <Button onClick={(e) => {_sketch.addText("Text"); setTool(null); }}><TextFormatIcon size="small"/></Button> */}
                
              </Box>
              <Box display={'flex'} style={{padding: '0 20px', alignItems: 'center', justifyContent: 'center'}}>
                <Box style={{width:'80%', textAlign:'left'}}>
                  <p style={{fontSize:13, color: 'rgba(0, 0, 0, 0.6)', margin:0, lineHeight: 1}}>Fill Color</p>
                  <div onClick={()=>setOpenFillColorModal(true)} style={{cursor:'pointer', backgroundColor: fillColor, width:45, height: 22, marginTop: '10px'}}></div>
                </Box>
                <Box style={{width:'80%', textAlign:'left'}}>
                  <p style={{fontSize:13, color: 'rgba(0, 0, 0, 0.6)', margin:0, lineHeight: 1}}>Font Color</p>
                  <div onClick={()=>setOpenColorModal(true)} style={{cursor:'pointer', backgroundColor: color, width:45, height: 22, marginTop: '10px'}}></div>
                </Box>
                <TextField style={{width:'80%', margin: '0px 10px 0 10px'}} label="Line width" variant="standard" value={lineWidth} onChange={(e)=>setLineWidth(e.target.value)}/>
                <TextField style={{width:'80%', margin: '0px 10px 0 10px'}} label="Font size" variant="standard" value={fontSize} onChange={(e)=>setFontSize(e.target.value)}/>
              </Box>
            </Box>
          </div>
        </Draggable>
        }
        
        <ColorModal open = {openColorModal} onClose={()=>setOpenColorModal(false)} onOk = {(color)=>{setColor(color); setOpenColorModal(false)} }/>
        <ColorModal open = {openFillColorModal} onClose={()=>setOpenFillColorModal(false)} onOk = {(color)=>{setFillColor(color); setOpenFillColorModal(false)} }/>
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),
    setTopDrawing: data=>dispatch(setTopDrawing(data)),
    setBoothHistory: data=>dispatch(setBoothHistory(data)),
    setBoothList: data=>dispatch(setBoothList(data)),
    setSponsorshipsLayer: value=>dispatch(setSponsorshipsLayer(value)) ,
    setPanFlag:  data=>dispatch(setPanFlag(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
