import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function Index(props) {
  const [holdBooth, setHoldBooth] = React.useState(false);  
  const { onClose, onOk, onChange, open } = props;
  var mainLayers = props.main.mainLayers;

  useEffect(() => {
  }, [])
  
  const onChangeName = (e)=>{
    onChange(e)
  }
  var target=props.main.menuControl?.target
  var booth_number = ''
  if(target){
    booth_number = target.booth_number
  }
  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'release-dialog-title'}}
      aria-labelledby="release-dialog-title" 
      onClose={onClose} open={open}>
      <DialogTitle id='release-dialog-title' style={{display:'flex', alignItems:'center'}}>Confirm</DialogTitle>
      <DialogContent>
          <div style={{marginTop: 30, lineHeight:1.5}}>You are about to release the booth: <b>{booth_number}</b>.<br/> Are you sure?</div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={2}>
                <FormControlLabel
                  value="start"
                  control={<Switch color="primary" checked={holdBooth} 
                  onChange={(event)=>{
                    setHoldBooth(event.target.checked)
                  }}/>}
                  label="Hold Booth"
                  labelPlacement="start"
                />
              </Box>
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e)=>onOk(holdBooth)}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);