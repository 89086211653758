import * as React from 'react';
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import {NestedMenuItem} from 'mui-nested-menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import { connect } from 'react-redux'
import { fabric } from "fabric";
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AdbIcon from '@mui/icons-material/Adb';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import MoveDownIcon from  '@mui/icons-material/MoveDown';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import {canvasApp, helperCanvas} from '../../utils/CanvasApp'
import BlockIcon from '@mui/icons-material/Block';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Popover from '@mui/material/Popover';
import { setMenuControl, setRemoveObjects, setMapRemoveObjects } from '../../actions/main'
import { setOpenAssignmentModal, setOpenReleaseModal, setOpenTransferModal, setSnackMsg, setOpenSnack } from '../../actions/ui'
import { setBoothHistory } from '../../actions/booth_history'
import { setBoothPrefix, setBoothStartNumber, setBoothSuffix, setBoothCnt, setBoothOrder, setBoothSeqMode } from '../../actions/element'
import { setLog, addLog } from '../../actions/log'
import LogoModal from './RightMenuComponents/LogoModal';
import BackgroundModal from './RightMenuComponents/BackgroundModal';
import ColorModal from './RightMenuComponents/ColorModal';
import FontColorModal from './RightMenuComponents/FontColorModal';
import FontSizeModal from './RightMenuComponents/FontSizeModal';
import FontPickerModal from './RightMenuComponents/FontPickerModal';
import RotateModal from './RightMenuComponents/RotateModal';
import BoothTypeModal from './RightMenuComponents/BoothTypeModal';
import InviteModal from './RightMenuComponents/InviteModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, setObjectBG, addText, px2unit, unit2Px, checkCombinable, checkContainSold, checkSelectionAssignable, checkSelectionUnAssignable} from '../../utils/CanvasTool'
import SetBoothPriceModal  from './SetBoothPriceModal';
import zIndex from '@mui/material/styles/zIndex';
import { v4 as uuidv4 } from 'uuid';
import ReleaseModal from './ReleaseModal';
import TransferModal from './TransferModal';
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import DeleteConfirm from './DeleteConfirm'
import { parseToken, buildObjectProperty, convertRgbHex, getDefaultGroup, setCookie, getCookie, convertRgb,getTokenCookie, objectProperties } from '../../utils/Common'
var _clipboard = null;
function RightMenu(props) {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [openLogoModal, setOpenLogoModal] = React.useState(false);
  const [openBgModal, setOpenBgModal] = React.useState(false);
  const [openBgColorModal, setOpenBgColorModal] = React.useState(false);
  const [openFontSizeModal, setOpenFontSizeModal] = React.useState(false);
  const [openTextColorModal, setOpenTextColorModal] = React.useState(false);
  const [openFontPickerModal, setOpenFontPickerModal] = React.useState(false);
  const [openInviteModal, setOpenInviteModal] = React.useState(false);
  const [openRotateModal, setOpenRotateModal] = React.useState(false);
  const [openBoothTypeModal, setOpenBoothTypeModal] = React.useState(false);
  const [openSetPriceModal, setOpenSetPriceModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)    
  // const open = Boolean(anchorEl);
  var groupOptions = props.main.groupOptions
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const handleClickedAssign = (event) => {
    // props.setOpenAssignmentModal(true);
    props.handleOpenAttachModal()
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedHold = (event) => {
    var mainCanvas = props.main.mainCanvas
    var target=props.main.menuControl.target
    var premiumCheck = target.get('premiumCheck')
    var priceCheck = target.get('priceCheck')
    var discountCheck = target.get('discountCheck')
    var exhibitors = target.exhibitors
    var boothGroup = target.boothGroup
    var bgColor = "#dadada"
    var objects = [];
    if(target.type === 'activeSelection'){
      objects = target.getObjects();
    }
    else{
      objects = [target]
    }
    for(var i = 0; i < objects.length; i++){
      var object = objects[i]
      boothGroup = object.boothGroup
      {
        for(var k = 0; k < groupOptions.length; k++){
            if(groupOptions[k].KEY_ID == boothGroup){
                var groupOption = groupOptions[k]
                bgColor = convertRgbHex(groupOption['RESERVED']);
            }
        }
      }
      setObjectBG(mainCanvas, object, bgColor)
      object.set({   
        // modified: true,
        deselectedColor: bgColor,
        holdBooth: 1
      })
    }
    
    mainCanvas.discardActiveObject();
    mainCanvas.renderAll()
    props.setMenuControl({...props.main.menuControl, open:false})
    // var individualObjs = [];
    // var allObjects = mainCanvas.getObjects('group');
    // for(var i = 0 ; i < allObjects.length; i++){
    //   for(var j = 0; j < objects.length; j++){
    //     if(allObjects[i].KEY_ID == objects[j].KEY_ID){
    //       individualObjs.push(allObjects[i]);
    //     }
    //   }
    //   if(allObjects.KEY_ID == )
    // }
    props.onSaveIndividuals(null, objects, null)
    // props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'hold', objects:objects}})
  };
  
  const handleClickedRelease = (event) => {
    var mainCanvas = props.main.mainCanvas
    var target=props.main.menuControl.target
    var premiumCheck = target.get('premiumCheck')
    var priceCheck = target.get('priceCheck')
    var discountCheck = target.get('discountCheck')
    var exhibitors = target.exhibitors
    var boothGroup = target.boothGroup
    var bgColor = "#dadada"
    var objects = [];
    var modifiedObjects = [];
    if(target.type === 'activeSelection'){
      modifiedObjects = target.getObjects();
    }
    else{
      objects = [target]
    }
    for(var i = 0; i < objects.length; i++){
      var object = objects[i]
      if(object.holdBooth){
        modifiedObjects.push(object)
        boothGroup = object.boothGroup
        {
          for(var k = 0; k < groupOptions.length; k++){
              if(groupOptions[k].KEY_ID == boothGroup){
                  var groupOption = groupOptions[k]
                  bgColor = convertRgbHex(groupOption['RESERVED']);
                  if(object.sold == '1'){
                    bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                  }
                  else if((priceCheck && priceCheck.length > 0) || (premiumCheck && premiumCheck.length > 0) || (discountCheck && discountCheck.length > 0)){
                      bgColor = convertRgbHex(groupOption['AVAILABLE']);
                  }
              }
          }
        }
        setObjectBG(mainCanvas, object, bgColor)
        object.set({   
          // modified: true,
          deselectedColor: bgColor,
          holdBooth: false
        })
      }
    }    
    mainCanvas.discardActiveObject();
    mainCanvas.renderAll()
    props.setMenuControl({...props.main.menuControl, open:false})
    props.onSaveIndividuals(null, modifiedObjects, null)
    // props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'hold', objects:objects}})
  };
  const handleClickedUnassign = (event) => {
    props.setOpenReleaseModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleCloseReleaseModal = (event) => {
    props.setOpenReleaseModal(false);
  };
  const confirmReleaseModal = (holdBooth)=>{
    var mainCanvas = props.main.mainCanvas
    var target=props.main.menuControl.target
    var exhibitors = target?.exhibitors
    var company_KEY_ID
    if(exhibitors && exhibitors.length > 0){
        company_KEY_ID = exhibitors[0].EXHIBITOR_KEY
    }
    if(!company_KEY_ID){      
      props.setOpenSnack(true);
      props.setSnackMsg("Failed to Transfer");
      return
    }
    var postData = {
      EXHIBITOR_KEY:company_KEY_ID,
      HOLD_BOOTH:holdBooth,
      BOOTH_NUMBER:target.booth_number
    }
   
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putBoothRelease&token=${getTokenCookie()}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
          props.setOpenSnack(true);
          props.setSnackMsg("Released successfully");
          target.sold = false
          target.exhibitors = false
          target.company = false
          target.holdBooth = holdBooth
          var boothGroup = target.boothGroup;
          if(target.holdBooth){
            var bgColor = "#dadada"
            for(var k = 0; k < groupOptions.length; k++){
                if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                    var groupOption = groupOptions[k]
                        bgColor = convertRgbHex(groupOption['RESERVED']);
                }
            }
            target.deselectedColor = target.orgBg = bgColor
            setObjectBG(mainCanvas, target, bgColor)
          }
          else{
              var bgColor = "#dadada"
              if(target.priceCheck && target.priceCheck.length > 0){
                  for(var k = 0; k < groupOptions.length; k++){
                      if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                          var groupOption = groupOptions[k]
                              bgColor = convertRgbHex(groupOption['AVAILABLE']);
                      }
                  }
              }
              target.deselectedColor = target.orgBg = bgColor
              setObjectBG(mainCanvas, target, bgColor)
          }
          var textObjects = target.getObjects('text');
          for(var k = 0; k< textObjects.length; k++){
            target.remove(textObjects[k])
          }
          addText(mainCanvas, target, target.booth_number)
          props.onSaveIndividuals(null, [target], null)
          mainCanvas.renderAll()
        }
        else{
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to Release");
        }
    }).catch(error => {
        console.log("error", error);
    })
    props.setOpenReleaseModal(false);
  }
  const handleClickedTransfer = (event) => {
    props.setOpenTransferModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleCloseTransferModal = (event) => {
    props.setOpenTransferModal(false);
  };
  const confirmTransferModal = (booth_transfer_number, holdBooth)=>{
    var mainCanvas = props.main.mainCanvas
    var target=props.main.menuControl.target
    var exhibitors = target?.exhibitors
    var company_KEY_ID
    if(exhibitors && exhibitors.length > 0){
        company_KEY_ID = exhibitors[0].EXHIBITOR_KEY
    }
    if(!company_KEY_ID){      
      props.setOpenSnack(true);
      props.setSnackMsg("Failed to Transfer");
      return
    }
    if(!booth_transfer_number){
      props.setOpenSnack(true);
      props.setSnackMsg("Select a Transfer booth");
      return
    }
    var booth_transfer;
    var objects = props.main.mainCanvas.getObjects();
    for(var i = 0; i< objects.length; i++){
      var object = objects[i]
      if(object.get('class')=="booth" ){
        if(object.booth_number == booth_transfer_number){
          booth_transfer = object; break;
        }
      }
    }
    if(!booth_transfer){
      props.setOpenSnack(true);
      props.setSnackMsg("Select a Transfer booth");
      return
    }
    var postData = {
      EXHIBITOR_KEY:company_KEY_ID,
      HOLD_BOOTH:holdBooth,
      BOOTH_NUMBER:target.booth_number,
      BOOTH_TRANSFER:booth_transfer_number
    }
    var formdata = {
      query:`${ESHOW_URL()}?method=putBoothTransfer&token=${getTokenCookie()}`,
      postData: postData,
      returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putBoothTransfer&token=${getTokenCookie()}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
          props.setOpenSnack(true);
          props.setSnackMsg("Transfered successfully");
          booth_transfer.sold = target.sold
          booth_transfer.exhibitors = target.exhibitors
          // booth_transfer.holdBooth = holdBooth
          booth_transfer.company = target.company
          target.sold = false
          target.exhibitors = false
          target.company = false
          target.holdBooth = holdBooth
          var boothGroup = target.boothGroup;
          if(target.holdBooth){
            var bgColor = "#dadada"
            for(var k = 0; k < groupOptions.length; k++){
                if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                    var groupOption = groupOptions[k]
                        bgColor = convertRgbHex(groupOption['RESERVED']);
                }
            }
            target.deselectedColor = target.orgBg = bgColor
            setObjectBG(mainCanvas, target, bgColor)
          }
          else{
              var bgColor = "#dadada"
              if(target.priceCheck && target.priceCheck.length > 0){
                  for(var k = 0; k < groupOptions.length; k++){
                      if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                          var groupOption = groupOptions[k]
                              bgColor = convertRgbHex(groupOption['AVAILABLE']);
                      }
                  }
              }
              target.deselectedColor = target.orgBg = bgColor
              setObjectBG(mainCanvas, target, bgColor)
          }
          var textObjects = target.getObjects('text');
          for(var k = 0; k< textObjects.length; k++){
            target.remove(textObjects[k])
          }
          addText(mainCanvas, target, target.booth_number)
          mainCanvas.renderAll()

          var boothGroup = booth_transfer.boothGroup;
          var exhibitors = booth_transfer.exhibitors
          if(booth_transfer.holdBooth){
              var bgColor = "#dadada"
              for(var k = 0; k < groupOptions.length; k++){
                  if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                      var groupOption = groupOptions[k]
                          bgColor = convertRgbHex(groupOption['RESERVED']);
                  }
              }
              booth_transfer.deselectedColor = booth_transfer.orgBg = bgColor
              setObjectBG(mainCanvas, booth_transfer, bgColor)
          }
          else if(booth_transfer.sold){
              var bgColor = "#dadada"
              for(var k = 0; k < groupOptions.length; k++){
                  if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                      var groupOption = groupOptions[k]
                      bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                  }
              }
              booth_transfer.deselectedColor = booth_transfer.orgBg = bgColor
              setObjectBG(mainCanvas, booth_transfer, bgColor)
              var textObjects = booth_transfer.getObjects('text');
              for(var k = 0; k< textObjects.length; k++){
                  booth_transfer.remove(textObjects[k])
              }
          }
          else{
              var bgColor = "#dadada"
              if(booth_transfer.priceCheck && booth_transfer.priceCheck.length > 0){
                  for(var k = 0; k < groupOptions.length; k++){
                      if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                          var groupOption = groupOptions[k]
                              bgColor = convertRgbHex(groupOption['AVAILABLE']);
                      }
                  }
              }
              booth_transfer.deselectedColor = booth_transfer.orgBg = bgColor
              setObjectBG(mainCanvas, booth_transfer, bgColor)
          }
          addText(mainCanvas, booth_transfer, (booth_transfer.company?((booth_transfer.company+"").trim()+"\n"):"")+""+booth_transfer.booth_number)
          mainCanvas.renderAll()
        }
        else{
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to Transfer");
        }
    }).catch(error => {
        console.log("error", error);
    })
    props.setOpenTransferModal(false);
  }
  const handleClickedLogo = (event) => {
    setOpenLogoModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedBG = (event) => {
    setOpenBgModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedBgColor = (event) => {
    setOpenBgColorModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedFontSize = (event) => {
    setOpenFontSizeModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedFontColor = (event) => {
    setOpenTextColorModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedFontPicker = (event) => {
    setOpenFontPickerModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedRotate = (event) => {
    setOpenRotateModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedBoothType = (event) => {
    setOpenBoothTypeModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedBoothPricing = (event) => {
    setOpenSetPriceModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  
  const handleClickedCopy = (event) => {
    props.setMenuControl({...props.main.menuControl, open:false})
    var target=props.main.menuControl.target
    if(target){
      target.clone(function(cloned) {
        _clipboard = cloned;
        _clipboard.set(buildObjectProperty(target))
      }, objectProperties);
      props.main.mainCanvas.renderAll();
    }
  };
  const handleClickedPaste = (event) => {
    console.log("event", event)
    props.setMenuControl({...props.main.menuControl, open:false})
    var target=props.main.menuControl.target
    var zoom = props.main.mainCanvas.getZoom();
    
    if(_clipboard){
      // target.clone(function(cloned) {
      //   var _clipboard = cloned;
        _clipboard.clone(function(clonedObj) {          
          clonedObj.set(buildObjectProperty(_clipboard))
          var canvas = props.main.mainCanvas;
          canvas.discardActiveObject();
          // var newP = fabric.util.transformPoint(new fabric.Point((props.main.menuControl.offsetX?props.main.menuControl.offsetX:0), (props.main.menuControl.offsetY?props.main.menuControl.offsetY:0)), mainCanvas.viewportTransform);

          // clonedObj.set({
          //   left: newP.x,
          //   top: newP.y, 
          //   evented: true,
          // });
          clonedObj.set({
            top: (props.main.menuControl.offsetY?props.main.menuControl.offsetY:0), 
            left: (props.main.menuControl.offsetX?props.main.menuControl.offsetX:0),
            evented: true
          });
          if (clonedObj.type === 'activeSelection') {
            // active selection needs a reference to the canvas.

              var boothPrefix = props.element.boothPrefix;
              var boothNumber = props.element.boothStartNumber*1;
              var boothSuffix = props.element.boothSuffix;
              var g_boothSeqMode = props.element.g_boothSeqMode;
  
              clonedObj.canvas = canvas;
              var objects = [];
              clonedObj.forEachObject(function(obj) {
                if(obj.class == "booth"){
                
                  if(boothNumber){
                    var boothNumberFinal = boothPrefix+""+boothNumber+""+boothSuffix
                  }
                  obj.set({id:'booth-'+Date.now()})
                  objects.push(obj)
                  var textobjects = obj.getObjects('text');
                  for(var j = 0; j<  textobjects.length; j++){
                      textobjects[j].set('text', boothNumberFinal)
                  }
                  obj.set({booth_number:boothNumberFinal})
                  
                  if(g_boothSeqMode == 'sequential'){
                    boothNumber+=1;
                  }
                  else{
                    boothNumber+=2;
                  }
                }
                canvas.add(obj);
                obj.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
                obj.KEY_ID = uuidv4()
                obj.new_created = true;
              });
              props.setBoothStartNumber(boothNumber)
              // this should solve the unselectability
              clonedObj.setCoords();
              clonedObj.new_created = true;
              clonedObj.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
              clonedObj.KEY_ID = uuidv4()
            props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:objects}})
          } else {
            if(clonedObj.class == "booth"){
              clonedObj.set({id:'booth-'+Date.now()})
              var boothPrefix = props.element.boothPrefix;
              var boothNumber = props.element.boothStartNumber*1;
              var boothSuffix = props.element.boothSuffix;
              var g_boothSeqMode = props.element.g_boothSeqMode;
              if(boothNumber){
                var boothNumberFinal = boothPrefix+""+boothNumber+""+boothSuffix
    
                var textobjects = clonedObj.getObjects('text');
                for(var j = 0; j<  textobjects.length; j++){
                    textobjects[j].set('text', boothNumberFinal)
                }
                clonedObj.set({booth_number:boothNumberFinal})
    
                if(g_boothSeqMode == 'sequential'){
                  boothNumber+=1;
                }
                else{
                  boothNumber+=2;
                }
                props.setBoothStartNumber(boothNumber)
              }
            }
            clonedObj.set({

            })
            canvas.add(clonedObj);
            clonedObj.new_created = true;
            clonedObj.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
            clonedObj.KEY_ID = uuidv4()
            props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[clonedObj]}})
          }
          _clipboard.top += 10;
          _clipboard.left += 10;
          canvas.setActiveObject(clonedObj);
          canvas.requestRenderAll();
          props.main.mainCanvas.renderAll();
          snapTo(clonedObj)
          
        }, objectProperties);
      // }, ['class', 'class1']);
      props.main.mainCanvas.renderAll();
    }
  };
  
  const handleClickedDelete = (event)=>{    
    props.setMenuControl({...props.main.menuControl, open:false})
    setOpenDeleteModal(true)
  }

  
  const handleCloseDeleteModal=()=>{
    setOpenDeleteModal(false)
  }

  const confirmDeleteModal = ()=>{
    props.setMenuControl({...props.main.menuControl, open:false})
    var target=props.main.menuControl.target
    if(target){
      if(target.type === 'activeSelection'){
        var removeObjects = [...props.main.removeObjects]
        var mapRemoveObjects = [...props.main.mapRemoveObjects]
        var objects = target.getObjects();
        
        props.main.mainCanvas.discardActiveObject();
        props.main.mainCanvas.renderAll();

        for(var i = 0; i < objects.length; i++){
          var object = objects[i];
          props.main.mainCanvas.remove(object);
          props.main.mainCanvas.renderAll();
          if(object.class == "booth"){
            props.addLog({
              BOOTH_KEY:object.KEY_ID,
              KEY_ID:uuidv4(),
              action: 'delete',
              date: new Date().toLocaleString(),
              description: "deleted booth "+object.booth_number,
              user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
            })
          }
          removeObjects = [...removeObjects, object.uniq_id]
          
          object.deleteType = 'deleted'
          object.deleteTime = new Date().toLocaleString()
          object.deleteUser =  (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
          mapRemoveObjects = [...mapRemoveObjects, object.toDatalessObject()]
        }
        props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'deleted',  target:target}})
        props.setRemoveObjects(removeObjects)
        props.setMapRemoveObjects(mapRemoveObjects)
      }
      else{
        props.main.mainCanvas.remove(target);
        props.main.mainCanvas.renderAll();
        if(target.class == "booth"){
          props.addLog({
            BOOTH_KEY:target.KEY_ID,
            KEY_ID:uuidv4(),
            action: 'delete',
            date: new Date().toLocaleString(),
            description: "deleted booth "+target.booth_number,
            user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
          })
        }
        var removeObjects = [...props.main.removeObjects, target.uniq_id]
        target.deleteType = 'deleted'
        target.deleteTime = new Date().toLocaleString()
        target.deleteUser =  (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
        var mapRemoveObjects = [...props.main.mapRemoveObjects, target.toDatalessObject()]
        props.setRemoveObjects(removeObjects)
        props.setMapRemoveObjects(mapRemoveObjects)
        console.log("mapRemoveObjects", mapRemoveObjects)
        props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'deleted',  target:target}})
      }
      props.main.mainCanvas.discardActiveObject();
      props.main.mainCanvas.renderAll();
    }
    setOpenDeleteModal(false)
  }
  const handleClickedInvite = (event) => {
    setOpenInviteModal(true);
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedAlign = (event) => {  
    var mainCanvas = props.main.mainCanvas  
    var target=props.main.menuControl.target
    if(target){
      var angle = target.get('angle');
      mainCanvas.set({
        gridAngle: angle
      })

      var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
      // var canvasCenter = new fabric.Point(50, 50);
      var canvasCenter = new fabric.Point(mainCanvas.getWidth()/2, mainCanvas.getHeight()/2);
      var objectOrigin = new fabric.Point(target.left, target.top);
      var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(-1))
      mainCanvas.set({
        gridOffsetX: new_loc.x-(unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)),
        gridOffsetY: new_loc.y-(unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit))
      })
    
      mainCanvas.renderAll();
      redrawGrid(props.main.g_grid, mainCanvas, (mainCanvas.showGrid && mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);

      mainCanvas.setZoom(mainCanvas.getZoom()*1.000000001)
      mainCanvas.renderAll();
    }
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  const handleClickedForward= (event) => {
    var target=props.main.menuControl.target
    if(target){
      var zIndex = props.main.mainCanvas.getObjects().indexOf(target);
      props.main.mainCanvas.bringToFront(target);
      props.main.mainCanvas.renderAll();
      target.modified = true
      target.forward = true
      props.setMenuControl({...props.main.menuControl, open:false})
      props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'forward', object:target, zIndex:zIndex }})
    }
  };
  const handleClickedBackward= (event) => {
    var target=props.main.menuControl.target
    if(target){
      var zIndex = props.main.mainCanvas.getObjects().indexOf(target);
      props.main.mainCanvas.sendToBack(target);
      props.main.mainCanvas.discardActiveObject()
      if(target.get('type') == 'group'){
        var textObjects = target.getObjects('text');
        for(var i = 0; i< textObjects.length; i++){
          textObjects[i].bringToFront();
          textObjects[i].bringForward()
          props.main.mainCanvas.renderAll();
        }
      }
      target.modified = true
      target.forward = false
      props.main.mainCanvas.renderAll();
      props.setMenuControl({...props.main.menuControl, open:false})
      props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'backward', object:target, zIndex: zIndex}})
    }
  };  
  const handleClickedGroup= (event) => {
    var target=props.main.menuControl.target
    if(target){
      if (!props.main.mainCanvas.getActiveObject()) {
        return;
      }
      if (props.main.mainCanvas.getActiveObject().type !== 'activeSelection') {
        return;
      }
      var newgroup = props.main.mainCanvas.getActiveObject().toGroup();
      newgroup.set({groupped: true})
      newgroup.set({id: Date.now()})
      // props.main.mainCanvas.discardActiveObject();

      newgroup.set({
        fill:'black',
        lockScalingX: true,
        lockScalingY: true,
        borderColor: '#ff7777',
        cornerColor: 'red',
        cornerSize: 6,
        transparentCorners: false
      })
      newgroup.set({dirty: true})
      newgroup.new_created = true;
      newgroup.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
      newgroup.KEY_ID = uuidv4()
    }
    props.main.mainCanvas.renderAll();
    props.setMenuControl({...props.main.menuControl, open:false})
    props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'group', group:newgroup.id}})
  };
  
  const handleClickedUnGroup= (event) => {
    if (!props.main.mainCanvas.getActiveObject()) {
      return;
    }
    if (props.main.mainCanvas.getActiveObject().type !== 'group' || props.main.mainCanvas.getActiveObject().get('groupped') !== true) {
      return;
    }
    var objects = props.main.mainCanvas.getActiveObject().getObjects()
    props.main.mainCanvas.getActiveObject().toActiveSelection();
    props.main.mainCanvas.requestRenderAll()
    for(var i = 0; i < objects.length; i++){
      objects[i].modified = true;
      // objects[i].uniq_id = Date.now() + parseInt(Math.random()*10000000000)
      // objects[i].KEY_ID = uuidv4()
    }
    props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'ungroup', objects:objects}})
    props.setMenuControl({...props.main.menuControl, open:false})
  };

  const handleClickedLock= (event) => {
    var target=props.main.menuControl.target
    if(target){
      target.set({
        lockMovementX:true,
        lockMovementY:true,
        lockRotation:true,
        lockScalingX:true,
        lockScalingY:true,
        modified:true
        // selectable:false
      })
      props.main.mainCanvas.renderAll();
    }
    props.setMenuControl({...props.main.menuControl, open:false})
  };
  
  const handleClickedUnLock= (event) => {
    var target=props.main.menuControl.target
    if(target){
      target.set({
        lockMovementX:false,
        lockMovementY:false,
        lockRotation:false,
        lockScalingX:false,
        lockScalingY:false,
        selectable:true,
        modified:true
      })
      props.main.mainCanvas.renderAll();
    }
    props.setMenuControl({...props.main.menuControl, open:false})
  };

  const onSetBGColor=(color)=>{
    var target=props.main.menuControl.target
    setObjectBG(props.main.mainCanvas, target, color)
    target.modified = true
    props.main.mainCanvas.renderAll();
    setOpenBgColorModal(false)
  }

  const onSetTextSize=(size)=>{
    var target=props.main.menuControl.target
    // if(target.get('type') == "text" || target.get('type') == "textbox")
    {
      target.set({        
        fontSize:size,
      })
      target.modified = true
      props.main.mainCanvas.renderAll();
    }
    setOpenFontSizeModal(false)
  }

  
  const onSetTextColor=(color)=>{
    var target=props.main.menuControl.target
    // if(target.get('type') == "text" || target.get('type') == "textbox")
    {
      target.set({        
        stroke:color,
        fill: color,
      })
      target.modified = true
      props.main.mainCanvas.renderAll();
    }
    setOpenTextColorModal(false)
  }

  const onSetTextFont=(fontFamily)=>{
    var target=props.main.menuControl.target
    // if(target.get('type') == "text" || target.get('type') == "textbox")
    {
      target.set({        
        fontFamily:fontFamily,
      })
      target.modified = true
      props.main.mainCanvas.renderAll();
    }
    setOpenFontPickerModal(false)
  }

  const handleClickedFlipX= (event) => {
    var target=props.main.menuControl.target
    var orgValue = target.get('flipX')
    if(target){
      target.set({
        flipX:!target.get('flipX')
      })
      target.modified = true
      props.main.mainCanvas.renderAll();
    }
    props.setMenuControl({...props.main.menuControl, open:false})
    target.modified = true;
    props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'modified', transform:{original:{flipX:orgValue}}, target:target}})
  };

  const handleClickedFlipY= (event) => {
    var target=props.main.menuControl.target
    var orgValue = target.get('flipY')
    if(target){
      target.set({
        flipY:!target.get('flipY')
      })
      target.modified = true
      props.main.mainCanvas.renderAll();
    }
    props.setMenuControl({...props.main.menuControl, open:false})
    target.modified = true;
    props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'modified', transform:{original:{flipY:orgValue}}, target:target}})
  };
  
  
  const onSetRotate = (angle) => {
    var target=props.main.menuControl.target
    var orgValue = target.get('angle')
    {
      target.set({        
        angle:angle,
      })
      target.modified = true
      props.main.mainCanvas.renderAll();
      console.log('target', target)
    }
    target.modified = true;
    props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'modified', transform:{original:{angle:orgValue}}, target:target}})
    setOpenRotateModal(false);
  };

  const onSetInvite = (code) => {
    var target=props.main.menuControl.target
    {
      target.set({        
        inviteCode:code,
      })
      props.main.mainCanvas.renderAll();
    }
    setOpenInviteModal(false);
  };

  const onSetBoothType = (boothType) => {
    var target=props.main.menuControl.target
    var objects = []
    if(target.type === 'activeSelection'){
      objects = target.getObjects();
    }
    else{
      objects = [target]
    }
    for(var i = 0; i < objects.length; i++){
      var object = objects[i]
      object.set({        
        boothType:boothType,
      })
      object.modified = true
      props.main.mainCanvas.renderAll();
    }
    props.setMenuControl({...props.main.menuControl, open:false})
  };

  const snapTo = (target)=>{
    var edgedetection = 10 //pixels to snap
    const mainCanvas = props.main.mainCanvas
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      var realMapWidth;
      var realMapHeight;
      var realGrid;
      var realOffsetX;
      var realOffsetY;

      var mapWidth = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
      var mapHeight = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
      
      if(convertRateX){
        // realMapWidth = (mapWidth*unitRate/convertRateX)
        // realMapHeight = (mapHeight*unitRate/convertRateY)
        realMapWidth = mapWidth
        realMapHeight = mapHeight
        realGrid = unit2Px(mainCanvas.gridSize?mainCanvas.gridSize:0, convertRateX, mainCanvas.mapUnit)
        realOffsetX = mainCanvas.gridOffsetX?((mainCanvas.gridOffsetX % realGrid )-realGrid):50
        realOffsetY = mainCanvas.gridOffsetY?((mainCanvas.gridOffsetY % realGrid )-realGrid):50
        realOffsetX+= unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
        realOffsetY+= unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
    }

    if(props.main.mainCanvas.snapToGrid){
      //console.log('realOffsetX', realOffsetX)
      var angle = target.get('angle');
      var gridAngle = mainCanvas.gridAngle?mainCanvas.gridAngle:0
      if(gridAngle != angle) return;
      var canvasCenter = new fabric.Point(mainCanvas.getWidth()/2, mainCanvas.getHeight()/2);
      var objectOrigin = new fabric.Point(target.left, target.top);
      var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(-1))
      var left = Math.round((new_loc.x-realOffsetX) / realGrid) * realGrid+realOffsetX;
      var top = Math.round((new_loc.y-realOffsetY) / realGrid) * realGrid+realOffsetY;

      objectOrigin = new fabric.Point(left, top);
      new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(1))

      target.set({
        left: new_loc.x,
        top: new_loc.y
      });
      target.modified = true

    }
    else{          
      // var obj = target;
      // obj.setCoords(); //Sets corner position coordinates based on current angle, width and height
      
      var canvasWidth = document.getElementById('canvas').width
      var canvasHeight = document.getElementById('canvas').height;
      var objects = props.main.mainCanvas.getObjects();
      var activeObject = target;
      for(var i = 0; i< objects.length; i++){
          var targ = objects[i]
  
          if(targ === activeObject) continue;
          if(targ.get('class') != 'booth' && targ.get('class') != 'booth-group' && targ.get('class') != 'door' && targ.get('class') != 'wall'){
              continue
          }
          var active_oCoords = activeObject.aCoords
          var targ_oCoords = targ.aCoords

          // Standard snapping when within range
          if(targ.get('class') == 'wall'){
              if(Math.abs(active_oCoords.tl.x - targ_oCoords.tr.x) < edgedetection) {
                  //console.log("left", targ)
                  activeObject.left = targ.left + targ.width;
                  break;
              }
              else if(Math.abs(active_oCoords.tr.x - targ_oCoords.tl.x) < edgedetection) {
                  //console.log("right", targ)
                  activeObject.left = targ.left - activeObject.width;
                  break;
              }                
              else if(Math.abs(active_oCoords.br.y - targ_oCoords.tr.y) < edgedetection) {
                  //console.log("bottom", targ)
                  activeObject.top = targ.top - activeObject.height;
                  break;
              }
              else if(Math.abs(targ_oCoords.br.y - active_oCoords.tr.y) < edgedetection) {
                  //console.log("top", targ)
                  activeObject.top = targ.top + targ.height;
                  break;
              }
          }
          else{
              if(Math.abs(active_oCoords.tl.x - targ_oCoords.tr.x) < edgedetection && Math.abs(targ_oCoords.tr.y - active_oCoords.tl.y) < edgedetection) { //target right 1,0
                  //console.log("left", targ)
                  activeObject.left = targ_oCoords.tr.x //targ.left + targ.width;
                  // if(Math.abs(activeObject.top - targ.top) < edgedetection) {
                      activeObject.top = targ_oCoords.tr.y;
                  // }
                  break;
              }
              else if(Math.abs(active_oCoords.tl.x - targ_oCoords.bl.x) < edgedetection && Math.abs(targ_oCoords.bl.y - active_oCoords.tl.y) < edgedetection) { //target bottom 0,1
                  //console.log("top", targ)
                  activeObject.top = targ_oCoords.bl.y//targ.top + targ.height;
                  // if(Math.abs(activeObject.left - targ.left) < edgedetection) {
                      activeObject.left = targ_oCoords.bl.x;
                  // }
                  break;
              }
              else if(Math.abs(active_oCoords.bl.x - targ_oCoords.tl.x) < edgedetection && Math.abs(active_oCoords.bl.y - targ_oCoords.tl.y) < edgedetection) { //target top 0,0
                  //console.log("bottom", targ)
                  activeObject.top = targ.top +(active_oCoords.tl.y - active_oCoords.bl.y);
                  // if(Math.abs(activeObject.left - targ.left) < edgedetection) {
                      activeObject.left = targ.left + (active_oCoords.tl.x - active_oCoords.bl.x);
                  // }
                  break;
              }
              else if(Math.abs(active_oCoords.tr.x - targ_oCoords.tl.x) < edgedetection && Math.abs(active_oCoords.tr.y - targ_oCoords.tl.y) < edgedetection) {//target left 0,0
                  //console.log("right", targ)
                  activeObject.left = targ.left - (active_oCoords.tr.x - active_oCoords.tl.x);
                  // if(Math.abs(activeObject.top - targ.top) < edgedetection) {
                      activeObject.top = targ.top + (active_oCoords.tl.y - active_oCoords.tr.y);
                  // }
                  break;
              }                
          }
          targ.modified = true
      }
    }
  }

  const hasHold = (target)=>{
    var holded = false
    if(target.type == 'activeSelection'){
      var objects = target.getObjects();
      for(var i = 0; i < objects.length; i++){
        if(objects[i].holdBooth){
          holded = true;
          break;
        }
      }
    }
    else{
      holded = target.holdBooth
    }
    return holded
  }
  
  var objectType = "general";
  var combined = false;
  var target=props.main.menuControl.target
  var isTable = false
  var combineable = false
  var assignable = false
  var unassignable = false
  

  if(target){
    var premiumCheck = target.get('premiumCheck')
    var priceCheck = target.get('priceCheck')
    var discountCheck = target.get('discountCheck')
    console.log("type", target.get('type'))
    if(target.get('class') == "booth" || target.get('class') == "booth-group" || target.get('layer') == "sponsorships" ){
      objectType = "booth"
      if(target.child_booths && target.child_booths.length > 0){
        combined = true;
      }
      if((priceCheck && priceCheck.length > 0) || (premiumCheck && premiumCheck.length > 0) || (discountCheck && discountCheck.length > 0))
        assignable = true
    }
    if(target.get('type') == "text" || target.get('type') == "textbox" || target.get('type') == 'i-text'){
      objectType = "text"
    }
    
    if(target.get('company')){
      objectType = "company"
      unassignable = true
    }
    if(target.get('type') == 'activeSelection'){
      objectType = "activeSelection"
      combineable = checkCombinable(target.getObjects())  
      if(combineable && checkContainSold(target.getObjects()) ){
        canvasApp.helperCanvas = props.main.mainCanvas
        var group = canvasApp.combineHelper.openCombineWindow(true,props.grouppingBooth); 
        if(!group) {
          combineable = false
        }
      }
      assignable = checkSelectionAssignable(target.getObjects())
      unassignable = false
    }
    if(target.get('groupped')){
      objectType = "groupped"
    }
    if(target.get('class') == 'table'){
      isTable = true;
    }
  }
  else{
    objectType = "empty"
  }

  return (
    <>  
      {props.main.menuControl.open&&
      <Popover
          // id={id}
          sx={{zIndex:10006}}
          open={props.main.menuControl.open?props.main.menuControl.open:false}
          // anchorEl={anchorEl}
          onClose={()=>props.setMenuControl(false)}
          anchorPosition={{ top: (props.main.menuControl.top?props.main.menuControl.top:0), left: (props.main.menuControl.left?props.main.menuControl.left:0) }}
          anchorReference={'anchorPosition'}
        >
        {/* <Paper sx={{ width: 320, maxWidth: '100%' }}> */}
          <MenuList>
            {(objectType != "company") && isTable == false && assignable && 
            <MenuItem onClick={(event)=>handleClickedAssign(event)}>
              <ListItemIcon>
                <AddLinkIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>Assign</ListItemText>
            </MenuItem>
            }
            {(objectType=="activeSelection" || objectType == "booth") && objectType != "company" &&
            <>
              {hasHold(target)?
              <MenuItem onClick={(event)=>handleClickedRelease(event)}>
                <ListItemIcon>
                  <BlockIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Release Booth</ListItemText>
              </MenuItem>:
              <MenuItem onClick={(event)=>handleClickedHold(event)}>
                <ListItemIcon>
                  <BlockIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Hold</ListItemText>
              </MenuItem>
              }
            </>
            }
            { unassignable &&
            <MenuItem onClick={(event) => handleClickedUnassign(event)}>
              <ListItemIcon>
                <DeleteOutlinedIcon fontSize="small"/>      
              </ListItemIcon>
              <ListItemText>Unassign</ListItemText>
            </MenuItem>
            }
            {objectType == "company" &&
            <MenuItem onClick={(event) => handleClickedTransfer(event)}>
              <ListItemIcon>
                <MoveDownIcon fontSize="small"/>              
              </ListItemIcon>
              <ListItemText>Transfer</ListItemText>
            </MenuItem>
            }
            {objectType == "company" &&
            <MenuItem onClick={(event) => handleClickedLogo(event)}>
              <ListItemIcon>
                <AddPhotoAlternateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logo</ListItemText>
            </MenuItem>
            }
            {objectType == "general" && isTable == false &&
            <MenuItem onClick={(event) => handleClickedBgColor(event)}>
              <ListItemIcon>
                <ColorLensIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Background color</ListItemText>
            </MenuItem>
            }
            {objectType == "general" &&
            <MenuItem  onClick={(event) => handleClickedForward(event)}>
              <ListItemIcon>
                <MoveUpIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Move to foreground</ListItemText>
            </MenuItem>
            }
            {objectType == "general" &&
            <MenuItem  onClick={(event) => handleClickedBackward(event)}>
              <ListItemIcon>
                <MoveDownIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Move to background</ListItemText>
            </MenuItem>
            }
            {objectType == "general" && isTable == false &&
            <MenuItem onClick={(event) => handleClickedBG(event)}>
              <ListItemIcon>
                <AddPhotoAlternateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Background image</ListItemText>
            </MenuItem>
            }
            
            {objectType != "empty" && 
            <>
              {props.main.menuControl.target&& props.main.menuControl.target.get('lockMovementX') ?
                <MenuItem onClick={(event) => handleClickedUnLock(event)}>
                  <ListItemIcon>
                    <LockOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>UnLock</ListItemText>
                </MenuItem>:
                <MenuItem onClick={(event) => handleClickedLock(event)}>
                  <ListItemIcon>
                    <LockOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Lock</ListItemText>
                </MenuItem>
              }
            </>
            }
            {objectType == "text" &&
            <MenuItem onClick={(event) => handleClickedFontSize(event)}>
              <ListItemIcon>
                <StraightenOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Size</ListItemText>
            </MenuItem>
            }
            {objectType == "text" &&
            <MenuItem onClick={(event) => handleClickedFontColor(event)}>
              <ListItemIcon>
                <ColorLensIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Color</ListItemText>
            </MenuItem>
            }
            {objectType == "text" &&
            <MenuItem onClick={(event) => handleClickedFontPicker(event)}>
              <ListItemIcon>
                <FontDownloadOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Font</ListItemText>
            </MenuItem>
            }
            {/* {objectType != "empty" && isTable == false && 
            <MenuItem onClick={(event) => handleClickedFlipY(event)} >
              <ListItemIcon>
                <SwapVertIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Flip vertical</ListItemText>
            </MenuItem>
            }
            {objectType != "empty" && isTable == false && 
            <MenuItem onClick={(event) => handleClickedFlipX(event)}>
              <ListItemIcon>
                <SwapHorizIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Flip horizontal</ListItemText>
            </MenuItem>
            }
            {objectType != "empty" && isTable == false && 
            <MenuItem onClick={(event) => handleClickedRotate(event)}>
              <ListItemIcon>
                <RotateLeftIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Rotate</ListItemText>
            </MenuItem>
            } */}
            {objectType != "empty" && 
            <MenuItem onClick={(event) => handleClickedCopy(event)}>
              <ListItemIcon>
                <ContentCopyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copy</ListItemText>
            </MenuItem>
            }
            {_clipboard &&
            <MenuItem onClick={(event) => handleClickedPaste(event)}>
              <ListItemIcon>
                <ContentPasteOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Paste</ListItemText>
            </MenuItem>
            }
            {objectType != "empty" && objectType != "company" &&
            <MenuItem onClick={(event) => handleClickedDelete(event)}>
              <ListItemIcon>
                <DeleteForeverIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            }
            {/* {objectType=='groupped' &&
              <MenuItem onClick={(event)=>handleClickedUnGroup(event)}>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText>UnGroup</ListItemText>
              </MenuItem>
            }
            {objectType=="activeSelection" &&
              <MenuItem onClick={(event)=>handleClickedGroup(event)}>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText>Group</ListItemText>
              </MenuItem>
            } */}
            {(objectType=="activeSelection" || objectType == "booth" || objectType == "company") &&
            <>
              <MenuItem onClick={(event) => handleClickedBoothPricing(event)}>
                <ListItemIcon>
                  <MonetizationOnOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Pricing</ListItemText>
              </MenuItem> 
            </>
            }
            {/* <Divider /> */}
            {/* {(objectType == "booth" || objectType == "company") &&
            <MenuItem onClick={(event) => handleClickedInvite(event)}>
              <ListItemIcon>
                <PersonAddAltIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Invite Code</ListItemText>
            </MenuItem>
            } */}
            {(objectType=="activeSelection" || objectType == "booth" || objectType == "company") &&
            <NestedMenuItem
              style={{paddingLeft: 16, paddingRight:16}}
              leftIcon={<ModeEditOutlinedIcon style={{marginRight:7}} fontSize="small"/>}
              label="Booth type"
              parentMenuOpen={props.main.menuControl.open?props.main.menuControl.open:false}
            >   
                <MenuItem onClick={(event) => onSetBoothType("")}>
                  <ListItemText>None</ListItemText>
                </MenuItem>
                <MenuItem onClick={(event) => onSetBoothType("Island")}>
                  <ListItemText>Island</ListItemText>
                </MenuItem>
                <MenuItem onClick={(event) => onSetBoothType("End cap")}>
                  <ListItemText>End cap</ListItemText>
                </MenuItem>
                <MenuItem onClick={(event) => onSetBoothType("Inline")}>
                  <ListItemText>Inline</ListItemText>
                </MenuItem>
                <MenuItem onClick={(event) => onSetBoothType("Corner")}>
                  <ListItemText>Corner</ListItemText>
                </MenuItem>
                <MenuItem onClick={(event) => onSetBoothType("Peninsula")}>
                  <ListItemText>Peninsula</ListItemText>
                </MenuItem>
            </NestedMenuItem>
            }
            {objectType != "empty" && 
            <MenuItem onClick={(event) => handleClickedAlign(event)}>
              <ListItemIcon>
                <AlignHorizontalLeftIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Align Grid</ListItemText>
            </MenuItem>
            }
            {isTable == false &&
            <NestedMenuItem
              style={{paddingLeft: 16, paddingRight:16}}
              leftIcon={<ModeEditOutlinedIcon style={{marginRight:7}} fontSize="small"/>}
              label="Edit"
              parentMenuOpen={props.main.menuControl.open?props.main.menuControl.open:false}
            >              
              {/* {objectType == "activeSelection" && */}
              <MenuItem
              disabled = {objectType !== "activeSelection" || !combineable}
              onClick={
                (event) => {
                    props.handleOpenGrouppingModal()
                    props.setMenuControl({...props.main.menuControl, open:false})
                  }
                }
              >
                <ListItemIcon>
                  <WindowOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Combine</ListItemText>
              </MenuItem>
              {/* } */}
              {/* {objectType == "booth" && combined == true&& */}
              <MenuItem 
              onClick={
                (event) => {
                    props.setOpenUnGrouppingModal(true)
                    props.setMenuControl({...props.main.menuControl, open:false})
                  }
                }
                disabled={!(objectType == "booth" && combined == true)}
              >
                <ListItemIcon>
                  <GridViewOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Uncombine</ListItemText>
              </MenuItem>
              {/* } */}
            </NestedMenuItem>
            }
            {(objectType=="activeSelection" || objectType == "booth" || objectType == "company") &&
              <MenuItem 
                onClick={
                  (event) => {
                      props.setOpenBoothGroupModal(true)
                      props.setMenuControl({...props.main.menuControl, open:false})
                    }
                  }
              >
                <ListItemIcon>
                  <TableViewOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Assign Booth Group</ListItemText>
              </MenuItem>
            }
          </MenuList>
          
        {/* </Paper> */}
      </Popover>
      }
      <LogoModal open = {openLogoModal} onClose={()=>setOpenLogoModal(false)}/>
      <BackgroundModal open = {openBgModal} onClose={()=>setOpenBgModal(false)}/>
      <ColorModal open = {openBgColorModal} onClose={()=>setOpenBgColorModal(false)} onOk = {onSetBGColor}/>
      <FontSizeModal open = {openFontSizeModal} onClose={()=>setOpenFontSizeModal(false)} onOk = {onSetTextSize}/>
      <FontColorModal open = {openTextColorModal} onClose={()=>setOpenTextColorModal(false)} onOk = {onSetTextColor}/>    
      <FontPickerModal open = {openFontPickerModal} onClose={()=>setOpenFontPickerModal(false)} onOk = {onSetTextFont}/>   
      <InviteModal open = {openInviteModal} onClose={()=>setOpenInviteModal(false)} onOk = {onSetInvite}/>   
      <RotateModal open = {openRotateModal} onClose={()=>setOpenRotateModal(false)} onOk = {onSetRotate}/>      
      <BoothTypeModal open = {openBoothTypeModal} onClose={()=>setOpenBoothTypeModal(false)} onOk = {onSetBoothType}/>  
      <SetBoothPriceModal open = {openSetPriceModal} onClose={()=>setOpenSetPriceModal(false)} 
        onOk = {
          ()=>{
            props.setBoothHistory({content: (props.main.mainCanvas.toDatalessObject())})
            setOpenSetPriceModal(false)
          }
        }
      />   
      <ReleaseModal
          open={props.ui.openReleaseModal?props.ui.openReleaseModal:false}
          onClose={handleCloseReleaseModal}
          onOk={confirmReleaseModal}
      />       
      <TransferModal
          open={props.ui.openTransferModal?props.ui.openTransferModal:false}
          onClose={handleCloseTransferModal}
          onOk={confirmTransferModal}
      />       
      
      <DeleteConfirm
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onOk={confirmDeleteModal}
          title="Are you sure you want to delete?"
      />
    </>
  );
}

const mapStateToProps = (state) => {
    return {
      main: state.main,
      element: state.element,
      ui: state.ui,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setMenuControl:  data=>dispatch(setMenuControl(data)),
      setBoothHistory: data=>dispatch(setBoothHistory(data)),
      setBoothStartNumber: data=>dispatch(setBoothStartNumber(data)),
      addLog:  data=>dispatch(addLog(data)),
      setRemoveObjects:  data=>dispatch(setRemoveObjects(data)),
      setMapRemoveObjects:  data=>dispatch(setMapRemoveObjects(data)),
      setOpenAssignmentModal:  data=>dispatch(setOpenAssignmentModal(data)),
      setOpenReleaseModal:  data=>dispatch(setOpenReleaseModal(data)),
      setOpenTransferModal:  data=>dispatch(setOpenTransferModal(data)),
      setSnackMsg: data=>dispatch(setSnackMsg(data)),
      setOpenSnack: data=>dispatch(setOpenSnack(data))        
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(RightMenu);