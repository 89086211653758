export const SET_BOOTH_PREFIX = 'SET_BOOTH_PREFIX'
export const SET_BOOTHSTART_NUMBER = 'SET_BOOTHSTART_NUMBER'
export const SET_BOOTH_SUFFIX = 'SET_BOOTH_SUFFIX'
export const SET_BOOTH_CNT = 'SET_BOOTH_CNT'
export const SET_BOOTH_ORDER = 'SET_BOOTH_ORDER'
export const SET_BOOTH_SEQMODE = 'SET_BOOTH_SEQMODE'

export const setBoothPrefix = data =>({
    type:SET_BOOTH_PREFIX,
    payload:data
});

export const setBoothStartNumber = data =>({
    type:SET_BOOTHSTART_NUMBER,
    payload:data
});

export const setBoothSuffix = data =>({
    type:SET_BOOTH_SUFFIX,
    payload:data
});

export const setBoothCnt = data =>({
    type:SET_BOOTH_CNT,
    payload:data
});

export const setBoothOrder = data =>({
    type:SET_BOOTH_ORDER,
    payload:data
});

export const setBoothSeqMode = data =>({
    type:SET_BOOTH_SEQMODE,
    payload:data
});