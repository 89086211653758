
import * as THREE from 'three';
import { fabric } from "fabric";

import { OrbitControls } from '../utils/OrbitControls'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import polygonClipping from 'polygon-clipping'
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import ScaleLoader from 'react-spinners/ScaleLoader'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { assetUrl } from './Common'
// import SpriteText from 'three-spritetext';
var base_height = 0.5
export const renderBooth=async (scene, camera, content, props, boothModel, font, meshes, table6Model, table8Model, table10Model)=>{
   if(scene){
       for(var i = scene.children.length-1; i>0; i--) {
           if(scene.children[i].name ==="booth")
               scene.remove(scene.children[i])
       }
       // var minLeft = 1000000;
       // var maxLeft = 0;
       // var minTop = 1000000;
       // var maxTop = 0;
       // for(var i = 0; i< content.objects.length; i++){
       //     var booth = content.objects[i]
       //     if(minLeft> booth['left']){
       //         minLeft = booth['left']
       //     }
       //     if(maxLeft< booth['top']){
       //         maxLeft = booth['top']
       //     }
       //     if(minTop> booth['left']){
       //         minTop = booth['left']
       //     }
       //     if(maxTop< booth['top']){
       //         maxTop = booth['top']
       //     }
       // }
       // var width = maxLeft - minLeft;
       // var height = maxTop - minTop;
       var convertRateX= 1
       var convertRateY= 1
       if(props.main.mainCanvas.convertRateX) {
           var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
           var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
       }
       var gridOffsetX = props.main.mainCanvas.gridOffsetX
       var gridOffsetY = props.main.mainCanvas.gridOffsetY
       var gridSize = props.main.mainCanvas.gridSize
       var unitRate = 12; //1feet = 12inch
       if(props.main.mainCanvas.mapUnit == 'meter'){
            unitRate = 1;
       }
   
       var mapWidth = props.main.mainCanvas.mapWidth*1
       var default_grid = 10;
       if(mapWidth > 1000){
           default_grid = 50
       }
       if(mapWidth > 10000){
           default_grid = 100
       }
       console.log("default_grid", default_grid)
       var gridWidth;
       if(convertRateX){
           gridWidth = (gridSize*unitRate/Math.max(convertRateX, 1))
       }
       console.log(realOffsetX)
       var realOffsetX = gridOffsetX?((gridOffsetX % gridWidth )-gridWidth):50
       var realOffsetY = gridOffsetY?((gridOffsetY % gridWidth )-gridWidth):50

       var width = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
       var height = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
       var startX = 0-width/2 -50;
       var startZ = 0-height/2 -50;
       
       
       var text_materials = [
           new THREE.MeshPhongMaterial( { color: 0xffffff, flatShading: true } ), // front
           new THREE.MeshPhongMaterial( { color: 0xffffff } ) // side
       ];
       var text_material = new THREE.MeshPhongMaterial( { color: 0xffffff, flatShading: true } )
       
       var text_materials2 = [
           new THREE.MeshPhongMaterial( { color: 0xffffff, flatShading: true } ), // front
           new THREE.MeshPhongMaterial( { color: 0xffffff } ) // side
       ];
       var convertRateX= 1
       var convertRateY= 1
       if(props.main.mainCanvas.convertRateX) {
           var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
           var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
       }
       console.log("convertRateX", convertRateX)
       console.log("booths", content.objects)
       var mesh_printed = false
       // center
        // var geometry = new THREE.BoxGeometry( 500, 10, 500 );
        // var material = new THREE.MeshStandardMaterial( { color: 'red', roughness: 0.5 } );
        
        // mesh = new THREE.Mesh( geometry, material );
        // mesh.castShadow = true;
        // mesh.position.x = 0;
        // mesh.position.y = 0;
        // mesh.position.z = 0;
        // scene.add( mesh );
        // meshes.push(mesh)

       for(var i = 0; i< content.objects.length; i++){
           var booth = content.objects[i]
           if(booth.layer == 'deleted') continue;
           if(booth['class'] == 'booth'){
               
               if(booth.booth_number == "461"){
                   console.log("booth-cv_433", booth)
               }
               var company_threemodel_url;
               if(booth.exhibitors){
                company_threemodel_url = booth.exhibitors[0]?.['3DMODEL']
                if(company_threemodel_url){
                    company_threemodel_url = company_threemodel_url.trim();
                }
               }
               if(booth.sold && company_threemodel_url && company_threemodel_url!=""){
                   // const loader = new THREE.ObjectLoader();
                   // boothModel = await loader.loadAsync( `${booth.company_threemodel_url}`);
                   const loader = new GLTFLoader();
                   var assetsPath = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/2666677/';
                    assetsPath = "/"
                //    var model = await loader.loadAsync( `${assetsPath}3d/booth1/scene.gltf`);
                    try{
                    var model = await loader.loadAsync( `${assetUrl(company_threemodel_url)}`);
                    if(model){
                        boothModel = model.scene
                        
                        boothModel.traverse(function (child) {
                                if ((child).isMesh) {
                                    const m = child
                                    console.log("child", child)
                                    if(m.name == "Cube001"){
                                        // const color = new THREE.Color( 0.21952596306800842, 0.20507857203483582, 0.1946178525686264 );
                                        // m.material.color = color
                                        // m.material.roughness = 1


                                    }
                                    // m.receiveShadow = true
                                    // m.castShadow = true
                                    // // m.material.color = new THREE.Color(0xffffff)
                                    // m.material.metalness =0
                                    // m.position.set( 0, 0, 0 );
                                    // if(m.material.map) m.material.map.anisotropy = 16
                                }
                                // if ((child ).isLight) {
                                //     const l = child
                                //     // l.castShadow = true
                                //     // l.shadow.bias = -0.003
                                //     // l.shadow.mapSize.width = 2048
                                //     // l.shadow.mapSize.height = 2048
                                //     l.extensions = {}
                                //     l.extensions.KHR_lights_punctual = {
                                //         "light" : 0
                                //     }
                                // }
                            })
                        console.log("boothModel", boothModel)
                        var box = new THREE.Box3().setFromObject( boothModel );
                        let measure = new THREE.Vector3();
                        box.getSize(measure)
                        console.log( booth['width'], measure );
                        
                        if(measure.x > 0 && measure.z>0){
                            var scale = Math.min(booth['width']/measure.x, booth['height']/measure.z)
                            boothModel.scale.set(scale, scale, scale)
                        }
                        var model = boothModel.clone()
                        //    model.position.x = startX+booth['left']+booth['width']/2-4.65/convertRateX;
                        //    model.position.y = 0.1/convertRateX;
                        //    model.position.z = startZ+booth['top']+booth['height']/2+2.05/convertRateX;
                        
                        model.position.x = startX+booth['left']+booth['width']/2;
                        model.position.y = 0;
                        model.position.y = -5*base_height/Math.max(convertRateX, 1);
                        model.position.y = 0;
                        model.position.z = startZ+booth['top']+booth['height']/2;
                        if(booth['angle'] && booth['angle'] != 0){
                            var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                            var axis = new THREE.Vector3( 0, 1, 0 )
                            // mesh.rotation.y = -Math.PI*2*booth['angle']/360;
                            rotateAboutPoint(model, basepoint, axis, -Math.PI*2*booth['angle']/360, false )
                        }
                        model.booth_id = booth.id;
                        model.class = "booth"
                        scene.add(model)
                        meshes.push(model)                    
                    }
                    if(0){ //bounderay test
                        var geometry = new THREE.BoxGeometry( booth['width'], base_height/Math.max(convertRateX, 1), booth['height'] );
                        var material = new THREE.MeshStandardMaterial( { color: 'red' } );
                        var bounderymesh = new THREE.Mesh( geometry, material );
                        bounderymesh.castShadow = true;
                        bounderymesh.position.x = startX+booth['left']+geometry.parameters.width/2;
                        bounderymesh.position.y = base_height/2/Math.max(convertRateX, 1);
                        bounderymesh.position.z = startZ+booth['top']+geometry.parameters.depth/2;
                        bounderymesh.name = "booth"
                        if(booth['angle']){
                            var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                            var axis = new THREE.Vector3( 0, 1, 0 )
                            // mesh.rotation.y = -Math.PI*2*booth['angle']/360;
                                if(booth['angle'] && booth['angle'] != 0){
                                    rotateAboutPoint(bounderymesh, basepoint, axis, -Math.PI*2*booth['angle']/360, false )
                                }
                        }
                        scene.add( bounderymesh );
                        meshes.push(bounderymesh)
                        console.log("bounderymesh.position", bounderymesh.position)
                    }
                    }
                    catch(e){
                        console.log("3d loading error", e)
                    }
               }
               else{
                    var points = [];
                    for(var j = 0; j< booth.objects.length; j++){
                        var object = booth.objects[j]
                        if(object.type == "polygon"){
                            var poly_points = object.points;   
                            for(var pathInd = 0; pathInd < poly_points.length; pathInd++){
                                var pointX = poly_points[pathInd]['x']
                                var pointY = poly_points[pathInd]['y']
                                // if(object.pathOffset){
                                //     pointX = pointX - (object.pathOffset.x + object.left)
                                //     pointY = pointY - (object.pathOffset.y + object.top)
                                // }
                                points.push( new THREE.Vector2( pointX, pointY) );
                            }
                            break;
                        }
                    }            
    
                    const extrudeSettings = { depth: base_height/Math.max(convertRateX, 1), bevelEnabled: false, bevelSegments: 0, steps: 1, bevelSize: 0, bevelThickness: 0 };
                    // var geometry = new THREE.BufferGeometry().setFromPoints( points );
                    const shape = new THREE.Shape( points );
                    const color = new THREE.Color( 0.21952596306800842, 0.20507857203483582, 0.1946178525686264 );
                    var mesh = addShape(scene, shape, extrudeSettings, (booth['sold'] == "1")?0xe01010:color, startX+booth['left'], 0, startZ+booth['top'], 0, 0, 0, 1 , (booth['sold'] == "1")?1:0.5);
                    if(!mesh_printed)
                        console.log("mesh print", mesh);
                    mesh_printed = true;
                    var geometry = new THREE.BoxGeometry( booth['width'], base_height/Math.max(convertRateX, 1), booth['height'] );
                    var material = new THREE.MeshStandardMaterial( { color: 0x817d7a } );
                    if(0){ //bounderay test
                        var geometry = new THREE.BoxGeometry( booth['width'], base_height/Math.max(convertRateX, 1), booth['height'] );
                        var material = new THREE.MeshStandardMaterial( { color: 'red' } );
                        var bounderymesh = new THREE.Mesh( geometry, material );
                        bounderymesh.castShadow = true;
                        bounderymesh.position.x = startX+booth['left']+geometry.parameters.width/2;
                        bounderymesh.position.y = 0;
                        bounderymesh.position.z = startZ+booth['top']+geometry.parameters.depth/2;
                        bounderymesh.name = "booth"
                        if(booth['angle']){
                            var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                            var axis = new THREE.Vector3( 0, 1, 0 )
                            // mesh.rotation.y = -Math.PI*2*booth['angle']/360;
                                if(booth['angle'] && booth['angle'] != 0){
                                    rotateAboutPoint(bounderymesh, basepoint, axis, -Math.PI*2*booth['angle']/360, false )
                                }
                        }
                        scene.add( bounderymesh );
                        meshes.push(bounderymesh)
                        console.log("bounderymesh.position", bounderymesh.position)
                    }

                    var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                    var axis = new THREE.Vector3( 0, 1, 0 )
                    var axis1 = new THREE.Vector3( 1, 0, 0 )
                    if(booth['angle'] && booth['angle'] != 0){
                    rotateAboutPoint(mesh, basepoint, axis, -Math.PI*2*(booth['angle']?booth['angle']:0)/360, false )
                    }
                    var basepoint1 = new THREE.Vector3( 0, 0, 0)
                    
                    if(booth['angle'] && booth['angle'] != 0){
                    basepoint1.applyAxisAngle(axis, -Math.PI*2*(booth['angle']?booth['angle']:0)/360); // rotate the POSITION
                    }
                    basepoint1.add(basepoint); // re-add the offset
                    
                    rotateAboutPoint(mesh, basepoint1, axis1,Math.PI/2, false )
                    var heightOffset = new THREE.Vector3( 0, base_height/Math.max(convertRateX, 1), 0)
                    mesh.position.add(heightOffset)
    
                    mesh.booth_id = booth.id;
                    mesh.class = "booth"
                    points = [];
                    if(booth.booth_number == "828"){
                        console.log("booth-cv_2569", booth)
                    }
                    if(booth.booth_number == "829"){
                        console.log("booth-cv_2569", booth)
                    }
                    if(booth.id == "booth-cv_1739"){
                        console.log("booth-cv_1739", booth)
                    }
                    for(var j = 0; j< booth.objects.length; j++){
                        var object = booth.objects[j]
                        if(object.type == "polygon"){
                            var poly_points = object.points;   
                            for(var pathInd = 0; pathInd < poly_points.length; pathInd++){
                                var pointX = poly_points[pathInd]['x']
                                var pointY = poly_points[pathInd]['y']
                                // if(object.pathOffset){
                                //     pointX = pointX - (object.pathOffset.x + object.left)
                                //     pointY = pointY - (object.pathOffset.y + object.top)
                                // }
                                points.push( new THREE.Vector3( startX+booth['left']+pointX, base_height/Math.max(convertRateX, 1), startZ+booth['top']+pointY ) );
                            }
                        }
                    }            
    
                    // console.log("points1", points)
                    geometry = new THREE.BufferGeometry().setFromPoints( points );
                    material = new THREE.LineBasicMaterial( { color: '#f0f0f0', linewidth: 10 } );
                    // material = new THREE.LineBasicMaterial( { color: 'red', linewidth: 10 } );
                    const line = new THREE.Line( geometry, material );
                    var basepoint = new THREE.Vector3( startX+booth['left'], base_height/Math.max(convertRateX, 1), startZ+booth['top'])
                    var axis = new THREE.Vector3( 0, 1, 0 )
                    // mesh.rotation.y = -Math.PI*2*booth['angle']/360;
                    if(booth['angle'] && booth['angle'] != 0){
                    rotateAboutPoint(line, basepoint, axis, -Math.PI*2*(booth['angle']?booth['angle']:0)/360, false )
                    }
                    scene.add( line );
                   var textobjects = [];
                   for(var j = 0; j< booth.objects.length; j++){
                       var object = booth.objects[j]
                       if(object.type == "text"){
                           textobjects.push(object)
                       }
                   }            
                   var boothName = "";
                //    textobjects.sort(function(a, b) {
                //        const nameA = a.left; // ignore upper and lowercase
                //        const nameB = b.left; // ignore upper and lowercase
                //        if (nameA < nameB) {
                //            return -1;
                //        }
                //        if (nameA > nameB) {
                //            return 1;
                //        }
                       
                //        // names must be equal
                //        return 0;
                //    })
                //    textobjects.sort(function(a, b) {
                //        const nameA = a.left; // ignore upper and lowercase
                //        const nameB = b.left; // ignore upper and lowercase
                //        return nameA - nameB;
                //    })
                //    if(textobjects && textobjects[0]){
                //        var txt = "";
                //        for(var j = 0; j < textobjects.length; j++){
                //            txt+=(txt==""?"":" ")+textobjects[j].text
                //        }
                //        boothName = txt;
                //    }
                    boothName = booth.booth_number
                   if(booth.sold && booth.company){
                       boothName = booth.booth_number+"\n"+booth.company
                   }
                   boothName = wordWrap(boothName, booth['width']*Math.max(convertRateX, 1)/10);
                   var textGeo = new TextGeometry( boothName, {

                       font: font,
                       size: 80,
                       height: 2,
                       curveSegments: 5,
                       bevelEnabled: true,
                       bevelThickness: 5,
                       bevelSize: 4,
                       bevelOffset: 0,
                       bevelSegments: 2
   
                   } );
   
                   textGeo.computeBoundingBox();
                   var textWidth = 0;
                   var textHeight = 0;
                   var textScaleX = 0.14/Math.max(convertRateX, 1)
                   var textScaleY = 0.14/Math.max(convertRateY, 1)
                   var sizeBox = new THREE.Vector3();
                   textGeo.boundingBox.getSize(sizeBox)
                   textWidth = sizeBox.x * textScaleX
                   textHeight = sizeBox.y * textScaleX
                   if(booth.booth_number == "434"){
                    console.log("textGeo.boundingBox textWidth", textWidth);
                    console.log("textGeo.boundingBox textHeight", textHeight);
                   }

                   var marginX = 7/Math.max(convertRateX, 1)
                   var marginZ = 17/Math.max(convertRateX, 1)

                   var textOffsetX = marginX
                   var textOffsetZ = marginZ
                   if(booth.labelPosition == 'center'){
                    textOffsetX = booth.width/2 - textWidth/2
                    textOffsetZ = booth.height - marginZ - textHeight
                   }
                   else if(booth.labelPosition == 'bottom-right'){
                    textOffsetX = booth.width - marginX - textWidth
                    textOffsetZ = booth.height - marginZ - textHeight
                   }
                   else{ //bottom-left
                    textOffsetX = marginX
                    textOffsetZ = booth.height - marginZ - textHeight
                   }
   
                   const centerOffset = - 0.5 * ( textGeo.boundingBox.max.x - textGeo.boundingBox.min.x );
                   var textMesh1 = new THREE.Mesh( textGeo, text_material );
                   
                   // const textMesh1 = new SpriteText(boothName, 5, 'white', false, 0, 'white', 'Arial', 40);
                   textMesh1.position.x = startX+booth['left']+textOffsetX;
                   textMesh1.position.y = base_height/Math.max(convertRateX, 1);
                   textMesh1.position.z = startZ+booth['top']+textOffsetZ;
                   textMesh1.scale.set(textScaleX, textScaleX, textScaleX)
                   // textMesh1.rotation.x = -Math.PI/2;
                   // textMesh1.rotation.y = Math.PI * 2;
                   var axis1 = new THREE.Vector3( 1, 0, 0 )
                   if(booth['angle']){
                       // textMesh1.rotation.y = -Math.PI*2*booth['angle']/360;
                       // var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                       var axis = new THREE.Vector3( 0, 1, 0 )
                       // mesh.rotation.y = -Math.PI*2*booth['angle']/360;
                       // axis.applyAxisAngle( new THREE.Vector3( 0, 1, 0 ), -Math.PI*2*booth['angle']/360 )
                       // basepoint.applyAxisAngle( new THREE.Vector3( 0, 1, 0 ), -Math.PI*2*booth['angle']/360 )
                       // var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                       // rotateAboutPoint(textMesh1, basepoint, axis, -Math.PI*2*booth['angle']/360, false )
                       // axis1.applyAxisAngle( axis, -Math.PI*2*booth['angle']/360 )
                       
                       // var basepoint = new THREE.Vector3( startX+booth['left']+3, 0.5, startZ+booth['top']-20)
                       // var axis = new THREE.Vector3( 0, 0, 1 )
                       // console.log("newaxis", axis.applyAxisAngle( new THREE.Vector3( 1, 0, 0 ), Math.PI/2 ))
                       // axis.applyAxisAngle( new THREE.Vector3( 1, 0, 0 ), -Math.PI/2 );
                       // mesh.rotation.y = -Math.PI*2*booth['angle']/360;
                       // rotateAboutPoint(textMesh1, basepoint, axis, -Math.PI*2*booth['angle']/360, false )
                       
                       var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                       
                       if(booth['angle'] && booth['angle'] != 0){
                        rotateAboutPoint(textMesh1, basepoint, axis, -Math.PI*2*booth['angle']/360, false )
                       }
                       // textMesh1.rotation.x = -Math.PI/2;
                       var basepoint1 = new THREE.Vector3( startX+booth['left']+7/Math.max(convertRateX, 1), base_height/Math.max(convertRateX, 1), startZ+booth['top']+17/Math.max(convertRateX, 1))
                       basepoint1.sub(basepoint); // remove the offset
                       basepoint1.applyAxisAngle(axis, -Math.PI*2*booth['angle']/360); // rotate the POSITION
                       basepoint1.add(basepoint); // re-add the offset

                        rotateAboutPoint(textMesh1, basepoint1, axis1,-Math.PI/2, false )

                   }
                   else{
                       var basepoint1 = new THREE.Vector3( startX+booth['left']+textOffsetX, base_height/Math.max(convertRateX, 1), startZ+booth['top']+textOffsetZ)
                       
                        rotateAboutPoint(textMesh1, basepoint1, axis1,-Math.PI/2, false )
                   }



                   scene.add(textMesh1);

                   // scene.add( textMesh1 );
                   
                   meshes.push(textMesh1)

                   // if(booth.sold && booth.company){
                   //     var textGeo2 = new TextGeometry( booth.company, {
                   //         font: font,
                   //         size: 80,
                   //         height: 2,
                   //         curveSegments: 5,
                   //         bevelEnabled: true,
                   //         bevelThickness: 5,
                   //         bevelSize: 4,
                   //         bevelOffset: 0,
                   //         bevelSegments: 2
       
                   //     } );
       
                   //     textGeo2.computeBoundingBox();
                   //     var textMesh2 = new THREE.Mesh( textGeo2, text_materials2 );
                   //     textGeo2.position.x = startX+booth['left']+3;
                   //     textGeo2.position.y = 2;
                   //     textGeo2.position.z = startZ+booth['top']+22;
                   //     textGeo2.scale.set(0.07, 0.07, 0.07)
   
                   //     textMesh2.rotation.x = -Math.PI/2;
                   //     // textMesh1.rotation.y = Math.PI * 2;
   
                   //     scene.add( textMesh2 );
                       
                   //     meshes.push(textMesh2)
                   // }
               }

               
               // geometry = new THREE.BoxGeometry( booth['width']-80, 20, booth['height']-80 );
               // material = new THREE.MeshPhongMaterial( { color: 0x052533 } );

               // mesh = new THREE.Mesh( geometry, material );
               // mesh.castShadow = true;
               // mesh.position.x = startX+booth['left']+booth['width']/2;
               // mesh.position.y = 20;
               // mesh.position.z = startZ+booth['top']+booth['height']/2;
               // mesh.name = "booth"
               // scene.add( mesh );
               // colliders.push(mesh)
               meshes.push(mesh)
           }
           // if(booth['class1'] == 'booth-group'){
           //     for(var j = 0; j< booth.child_booths.length; j++){
           //         var child_booth = booth.child_booths[j]
           //         if(child_booth['class'] == 'booth'){
           //             var geometry = new THREE.BoxGeometry( child_booth['width'], 1, child_booth['height'] );
           //             var material = new THREE.MeshPhongMaterial( { color: (booth['sold'] == "1")?0xff0000:0x817d7a } );
               
           //             mesh = new THREE.Mesh( geometry, material );
           //             mesh.castShadow = true;
           //             mesh.position.x = startX+child_booth['left']+booth['left']+booth['width']/2+child_booth['width']/2;
           //             mesh.position.y = 0;
           //             mesh.position.z = startZ+child_booth['top']+booth['top']+booth['height']/2+child_booth['height']/2;
           //             mesh.name = "booth"
           //             scene.add( mesh );
           //             meshes.push(mesh)
                       
           //             // if(j == 0){
           //             //     geometry = new THREE.BoxGeometry( child_booth['width']-80, 20, child_booth['height']-80 );
           //             //     material = new THREE.MeshPhongMaterial( { color: 0x052533 } );
                   
           //             //     mesh = new THREE.Mesh( geometry, material );
           //             //     mesh.castShadow = true;
           //             //     mesh.position.x = startX+child_booth['left']+booth['left']+booth['width']/2+child_booth['width']/2;
           //             //     mesh.position.y = 50;
           //             //     mesh.position.z = startZ+child_booth['top']+booth['top']+booth['height']/2+child_booth['height']/2;
           //             //     mesh.name = "booth"
           //             //     scene.add( mesh );
           //             //     colliders.push(mesh)
           //             //     meshes.push(mesh)
           //             // }
           //             if(j == 0){   
           //                 if(booth.sold){                             
           //                     var model = boothModel.clone()
           //                     model.position.x = startX+child_booth['left']+booth['left']+booth['width']/2+child_booth['width']/2;
           //                     model.position.y = 0;
           //                     model.position.z = startZ+child_booth['top']+booth['top']+booth['height']/2+child_booth['height']/2;
           //                     scene.add(model)
           //                 }
           //             }
           //         }
           //     }
           // }
           if(booth['class'] == 'table'){
               if(booth['class1'] == 'table_6'){
                    var model = table6Model.clone()   
                }
               else if(booth['class1'] == 'table_8'){
                   var model = table8Model.clone()     
               }
               else if(booth['class1'] == 'table_10'){
                   var model = table10Model.clone()   
               }
               if(booth['class1'] == 'table_6' || booth['class1'] == 'table_8' || booth['class1'] == 'table_10'){                   
                    var box = new THREE.Box3().setFromObject( model );
                    let measure = new THREE.Vector3();
                    box.getSize(measure)
                    
                    if(measure.x > 0 && measure.z>0){
                        var scale = Math.min(booth['width']/measure.x, booth['height']/measure.z)
                        model.scale.set(scale, scale, scale)
                    }
                    model.position.x = startX+booth['left']+booth['width']/2;
                    model.position.y = 0;
                    model.position.z = startZ+booth['top']+booth['height']/2;
                    if(booth['angle'] && booth['angle'] != 0){
                        var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                        var axis = new THREE.Vector3( 0, 1, 0 )
                        // mesh.rotation.y = -Math.PI*2*booth['angle']/360;
                        rotateAboutPoint(model, basepoint, axis, -Math.PI*2*booth['angle']/360, false )
                    }
                    model.booth_id = booth.id;
                    meshes.push(model)
                    scene.add(model)
                    if(0){ //bounderay test
                        var geometry = new THREE.BoxGeometry( booth['width'], base_height/Math.max(convertRateX, 1), booth['height'] );
                        var material = new THREE.MeshStandardMaterial( { color: 'red' } );
                        var bounderymesh = new THREE.Mesh( geometry, material );
                        bounderymesh.castShadow = true;
                        bounderymesh.position.x = startX+booth['left']+booth['width']/2;
                        bounderymesh.position.y = 0;
                        bounderymesh.position.z = startZ+booth['top']+booth['height']/2;
                        bounderymesh.name = "booth"
                        if(booth['angle']){
                            var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                            var axis = new THREE.Vector3( 0, 1, 0 )
                            // mesh.rotation.y = -Math.PI*2*booth['angle']/360;
                                if(booth['angle'] && booth['angle'] != 0){
                                    rotateAboutPoint(bounderymesh, basepoint, axis, -Math.PI*2*booth['angle']/360, false )
                                }
                        }
                        scene.add( bounderymesh );
                        meshes.push(bounderymesh)
                    }
               }
               else{
                var geometry = new THREE.BoxGeometry( booth['width'], base_height/Math.max(convertRateX, 1), booth['height'] );
                const color = new THREE.Color( 0.21952596306800842, 0.20507857203483582, 0.1946178525686264 );
                var material = new THREE.MeshStandardMaterial( { color: color, roughness: 0.5 } );
                
                mesh = new THREE.Mesh( geometry, material );
                mesh.castShadow = true;
                mesh.position.x = startX+booth['left']+booth['width']/2;
                mesh.position.y = base_height/2/Math.max(convertRateX, 1);
                mesh.position.z = startZ+booth['top']+booth['height']/2;
                mesh.name = "booth"
                if(booth['angle']){
                    var basepoint = new THREE.Vector3( startX+booth['left'], 0, startZ+booth['top'])
                    var axis = new THREE.Vector3( 0, 1, 0 )
                    // mesh.rotation.y = -Math.PI*2*booth['angle']/360;
                        if(booth['angle'] && booth['angle'] != 0){
                            rotateAboutPoint(mesh, basepoint, axis, -Math.PI*2*booth['angle']/360, false )
                        }
                }
                mesh.booth_id = booth.id;
                scene.add( mesh );
                meshes.push(mesh)
               }
           }
           if(booth['class'] == 'wall'){
                var wall_height = 5*unitRate/Math.max(convertRateX, 1); //5feet = 1.524m
                var wall_width = 0.8*unitRate/Math.max(convertRateX, 1); //0.8feet = 0.24m
               console.log("wall", booth)
               var points = booth.objects[0].points;
               var wall_startX = startX
               var wall_startZ = startZ
               for(var pInd = 0; pInd<= points.length-2; pInd+=2){
                   var point1 = points[pInd];
                   var point2 = points[pInd+1];
                   var  length = Math.sqrt((point2.x-point1.x) * (point2.x-point1.x) + (point2.y-point1.y) * (point2.y-point1.y))+wall_width/2;
                   var angleRadians = Math.atan2(point2.y - point1.y, point2.x - point1.x);

                   var geometry = new THREE.BoxGeometry( length, wall_height*2, wall_width );
                   var material = new THREE.MeshPhysicalMaterial( { color: 0x808082} );
                   mesh = new THREE.Mesh( geometry, material );
                   mesh.castShadow = true;
                   mesh.depthTest = false;
                   
                   //if it is wall dont add the base point(booth['left'], booth['top'])
                   mesh.position.set(wall_startX+point1.x+wall_width/2+length/2, wall_height, wall_startZ+point1.y)
                   var basepoint = new THREE.Vector3( wall_startX+point1.x+wall_width/2, 0, wall_startZ+point1.y)
                   var axis = new THREE.Vector3( 0, 1, 0 )
                   rotateAboutPoint(mesh, basepoint, axis, -angleRadians, false )
                   
                   mesh.name = "booth"

                   scene.add( mesh );
                   meshes.push(mesh)
                   // break;
               }
               // var geometry = new THREE.BoxGeometry( booth['width'], 1, booth['height'] );
               // var material = new THREE.MeshPhongMaterial( { color: (booth['sold'] == "1")?0xff0000:0x817d7a } );
               
               // mesh = new THREE.Mesh( geometry, material );
               // mesh.castShadow = true;
               // mesh.position.x = startX+booth['left']+booth['width']/2;
               // mesh.position.y = 0;
               // mesh.position.z = startZ+booth['top']+booth['height']/2;
               // mesh.name = "booth"
               // scene.add( mesh );
               // meshes.push(mesh)

               // const points = [];
               // for(var j = 0; j< booth.objects.length; j++){
               //     var object = booth.objects[j]
               //     if(object.type == "path"){
               //         var path = object.path;   
               //         for(var pathInd = 0; pathInd < path.length; pathInd++){
               //             points.push( new THREE.Vector3( startX+booth['left']+path[pathInd][1], 0.6, startZ+booth['top']+path[pathInd][2] ) );
               //         }
               //     }
               // }                   
               // geometry = new THREE.BufferGeometry().setFromPoints( points );
               // material = new THREE.LineBasicMaterial( { color: 0xffffff, linewidth: 10 } );
               // const line = new THREE.Line( geometry, material );
               // scene.add( line );
               // if(booth.sold){
               //     var model = boothModel.clone()
               //     model.position.x = startX+booth['left']+booth['width']/2;
               //     model.position.y = 0;
               //     model.position.z = startZ+booth['top']+booth['height']/2;
               //     scene.add(model)
               // }
               
           }
       }
       try {
            var objects = props.main.mainCanvas.getObjects('group');
            var annotations = [];
            for(var i = 0; i < objects.length; i++){
                if(objects[i].layer == "annotation" || objects[i].layer == "sponsorships"){
                    var booth = objects[i]
                    if(booth['layer'] == 'annotation' || booth['layer'] == 'sponsorships'){
                        try {
                            var bgurl = booth.toDataURL()
                            var bg_width = 0;
                            var bg_height = 0;
        
                            bg_width = booth.width
                            bg_height = booth.height
        
                            var bg_loader = new THREE.TextureLoader();
                
                            var bg_startX = startX+booth['left']+booth['width']/2;
                            var bg_startZ = startZ+booth['top']+booth['height']/2;
        
                            var material_bg = new THREE.MeshStandardMaterial({
                                map: bg_loader.load(bgurl), transparent: true
                            });
                
                            // combine our image geometry and material into a mesh
                            var bg_mesh = new THREE.Mesh(new THREE.PlaneGeometry(bg_width, bg_height), material_bg);
                            bg_mesh.position.set(bg_startX,0.2/Math.max(convertRateX, 1),bg_startZ)
                            bg_mesh.rotation.x = - Math.PI / 2;
                
                            bg_mesh.receiveShadow = false;
                            meshes.push(bg_mesh)
                            scene.add(bg_mesh);
                            
                        }
                        catch(e){
                            console.log(e)
                        }
                    }
                }
            }
           
        }
        catch(e){
            console.log(e)
        }
    //    var selectedObject = props.main.mainCanvas.getActiveObject();
    //    if(selectedObject){
    //     var left = selectedObject.left;
    //     var top = selectedObject.top;
    //     camera.position.set( left, 1000, top )
    //     camera.lookAt(scene.position)
    //    }
       
   }
}
function rotateAboutPoint(obj, point, axis, theta, pointIsWorld){
   pointIsWorld = (pointIsWorld === undefined)? false : pointIsWorld;

   if(pointIsWorld){
       obj.parent.localToWorld(obj.position); // compensate for world coordinate
   }

   obj.position.sub(point); // remove the offset
   obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
   obj.position.add(point); // re-add the offset

   if(pointIsWorld){
       obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
   }

   obj.rotateOnAxis(axis, theta); // rotate the OBJECT
}

function wordWrap(str, maxWidth) {
   var newLineStr = "\n"; 
   var done = false; 
   var res = '';
   if(str){
    while (str.length > maxWidth) {                 
        var found = false;
        // Inserts new line at first whitespace of the line
        for (var i = maxWidth - 1; i >= 0; i--) {
            if (testWhite(str.charAt(i))) {
                res = res + [str.slice(0, i), newLineStr].join('');
                str = str.slice(i + 1);
                found = true;
                break;
            }
        }
        // Inserts new line at maxWidth position, the word is too long to wrap
        if (!found) {
            res += [str.slice(0, maxWidth), newLineStr].join('');
            str = str.slice(maxWidth);
        }
 
    }
   }

   return res + str;
}
// function addLineShape( shape, color, x, y, z, rx, ry, rz, s ) {

//    // lines

//    shape.autoClose = true;

//    const points = shape.getPoints();
//    const spacedPoints = shape.getSpacedPoints( 50 );

//    const geometryPoints = new THREE.BufferGeometry().setFromPoints( points );
//    const geometrySpacedPoints = new THREE.BufferGeometry().setFromPoints( spacedPoints );

//    // solid line

//    let line = new THREE.Line( geometryPoints, new THREE.LineBasicMaterial( { color: color } ) );
//    line.position.set( x, y, z - 25 );
//    line.rotation.set( rx, ry, rz );
//    line.scale.set( s, s, s );
//    scene.add( line );

//    // line from equidistance sampled points

//    line = new THREE.Line( geometrySpacedPoints, new THREE.LineBasicMaterial( { color: color } ) );
//    line.position.set( x, y, z + 25 );
//    line.rotation.set( rx, ry, rz );
//    line.scale.set( s, s, s );
//    scene.add( line );

//    // vertices from real points

//    let particles = new THREE.Points( geometryPoints, new THREE.PointsMaterial( { color: color, size: 4 } ) );
//    particles.position.set( x, y, z + 75 );
//    particles.rotation.set( rx, ry, rz );
//    particles.scale.set( s, s, s );
//    scene.add( particles );

//    // equidistance sampled points

//    particles = new THREE.Points( geometrySpacedPoints, new THREE.PointsMaterial( { color: color, size: 4 } ) );
//    particles.position.set( x, y, z + 125 );
//    particles.rotation.set( rx, ry, rz );
//    particles.scale.set( s, s, s );
//    scene.add( particles );

// }

function addShape(scene, shape, extrudeSettings, color, x, y, z, rx, ry, rz, s,roughness ) {

   // flat shape with texture
   // note: default UVs generated by THREE.ShapeGeometry are simply the x- and y-coordinates of the vertices

   let geometry = new THREE.ShapeGeometry( shape );

   // let mesh = new THREE.Mesh( geometry, new THREE.MeshPhongMaterial( { side: THREE.DoubleSide } ) );
   // mesh.position.set( x, y, z - 175 );
   // mesh.rotation.set( rx, ry, rz );
   // mesh.scale.set( s, s, s );
   // scene.add( mesh );

   // // flat shape

   // geometry = new THREE.ShapeGeometry( shape );

   // mesh = new THREE.Mesh( geometry, new THREE.MeshPhongMaterial( { color: color, side: THREE.DoubleSide } ) );
   // mesh.position.set( x, y, z - 125 );
   // mesh.rotation.set( rx, ry, rz );
   // mesh.scale.set( s, s, s );
   // scene.add( mesh );

   // extruded shape

   geometry = new THREE.ExtrudeGeometry( shape, extrudeSettings );

   var mesh = new THREE.Mesh( geometry, new THREE.MeshStandardMaterial( { color: color, roughness: roughness, minFilter :THREE.LinearMipMapLinearFilter  } ) );
   mesh.position.set( x, y, z  );
   mesh.rotation.set( rx, ry, rz );
   mesh.scale.set( s, s, s );
   scene.add( mesh );
   return mesh
   // addLineShape( shape, color, x, y, z, rx, ry, rz, s );

}

function testWhite(x) {
   var white = new RegExp(/^\s$/);
   return white.test(x.charAt(0));
}

export const markActiveBooth = (camera, controls, meshes, active3dObject, viewAngle, g_mapWidth=1000, g_mapHeight=1000)=>{
    console.log("markActiveBooth", active3dObject)
    const color = new THREE.Color( 0.21952596306800842, 0.20507857203483582, 0.1946178525686264 );
    if(active3dObject){
        for (var i = 0; i < meshes.length; i++){
            if(meshes[i] && meshes[i].class == "booth"){
                if(meshes[i].booth_id && meshes[i].booth_id == (active3dObject.id)){
                    if(meshes[i].material)
                        meshes[i].material.color.set(0x25d000);
                    meshes[i].selected = 1
                    if(viewAngle == "isometic")
                        camera.position.set( meshes[i].position.x+Math.max(g_mapWidth, g_mapHeight)/2, Math.max(g_mapWidth, g_mapHeight)/2, meshes[i].position.z+Math.max(g_mapWidth, g_mapHeight)/2 )
                    else{
                        camera.position.set( meshes[i].position.x, Math.max(g_mapWidth, g_mapHeight)/2, meshes[i].position.z )
                    }
                    camera.zoom = 1;
                    camera.updateProjectionMatrix();
                    // controls.target.set( meshes[i].position.x, meshes[i].position.y, meshes[i].position.z );
                    controls.target.set(meshes[i].position.x, 0, meshes[i].position.z)
                    // controls.target.set( 0, 25, 0 );
                    controls.enableZoom = true;
                    // controls.enableRotate = false;
                    controls.mouseButtons = {
                        LEFT: THREE.MOUSE.PAN,
                        RIGHT: THREE.MOUSE.ROTATE,
                        MIDDLE: THREE.MOUSE.DOLLY,
                    }

                    controls.update();  
                }
                else{   
                    if(meshes[i].selected == 1){
                        meshes[i].selected = 0
                        if(meshes[i].material)
                            meshes[i].material.color.set((meshes[i]['sold'] == "1")?0xe01010:color);
                    }
                }
            }
        }
    }
    console.log("markActiveBooth done")
}