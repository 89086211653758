import logo from './logo.svg';
import './App.css';
import HeatViewer from './viewer/HeatViewer';
import MainViewer3D from './viewer3d/MainViewer';
import Box from '@mui/material/Box';
import ToolBox from './viewer/ToolBox';
import AppLeftPanel from './viewer/component/AppLeftPanel';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
function App(props) {
  console.log("render client app")
  return (
    <div id="xhbt-main-container" className="App Client">
      <Box>
        <HeatViewer {...props}/>
      </Box>
    </div>
  );
}

export default App;
