import * as React from 'react';
import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setShowGrid, setUnit, setBoothInfo, setLoading, setPricingOptions, setPremiumOptions, setDiscountOptions, setCategoryOptions, setGroupOptions, setBoothFileList, setMechantAccount } from '../../actions/main'

import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { fabric } from "fabric";
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, meter2feet, feet2meter, renderBorder} from '../../utils/CanvasTool'
import rgbHex from "rgb-hex";
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DeleteConfirm from './DeleteConfirm'
import moment from 'moment';

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";

import { parseToken, listToTree } from '../../utils/Common'
import { getTokenCookie } from '../../utils/Common'
import { uuid4 } from './sketch_main/utils';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#b71c1cff',
  '&:hover': {
    backgroundColor: 'red',
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

var handleCloseDeleteModal
var confirmDeleteModal
function Index(props) {
  const { onClose,  onChange, open } = props;
  let query = useQuery();
  const [openDeleteModal, setOpenDeleteModal] = useState(false)    
  const [deleteTitle, setDeleteTitle] = useState(false) 
  const [deleteItem, setDeleteItem] = useState(false)      
  const [deleteIndex, setDeleteIndex] = useState(false)      
  const [openAddPriceSaleItem, setOpenAddPriceSaleItem]  = React.useState(false);
  const [openEditPriceSaleItem, setOpenEditPriceSaleItem]  = React.useState(false);
  const [editGroupItem, setEditGroupItem] = React.useState({});
  const [editPriceSaleItem, setEditPriceSaleItem] = React.useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
 

  useEffect(() => {
    if(open){
      fetchPricingOptions()
    }
  }, [open])

  useEffect(() => {
    if(props.refresh){
      fetchPricingOptions() 
    }
  }, [props.refresh])

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }

  const fetchPricingOptions =async ()=>{
    props.setLoading(true)
    var url = `${ESHOW_URL()}?method=getSales&product_type=Premiums&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getSales&product_type=Premiums&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            if(result.PREMIUMS){  
                data = result.PREMIUMS
                data.sort(function (a, b) {
                    return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                });   
                props.setPremiumOptions(data)
            }
        }
        else{
        }
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  const savePriceSaleItem =async ()=>{
    var postData = editPriceSaleItem
    console.log("postData", postData);
    var url = `${ESHOW_URL()}?method=putSales&product_type=Premiums&token=${getTokenCookie()}`
    var formdata = {
      query:`${ESHOW_URL()}?method=putSales&product_type=Premiums&token=${getTokenCookie()}`,
      returnResponse: true,
      postData: {
        SUCCESS: true,
        PREMIUMS: [postData],
        returnResponse:true
      }
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
        const response = res.data;
        if(response.SUCCESS){
          setOpenSnack(true);
          setSnackMsg(response.MESSAGE?response.MESSAGE:"Success");
          fetchPricingOptions();
        }
        else{
          
          var msg = "Something went wrong"
          if(response.LOG && response.LOG[0] && response.LOG[0]['MESSAGE']){
            msg = response.LOG[0]['MESSAGE']
          }
          setOpenSnack(true);
          setSnackMsg(msg);
        }            
        setOpenAddPriceSaleItem(false)
        setOpenEditPriceSaleItem(false)
        
    }).catch(error => {
        console.log("error", error);
    })

  }

  const handleOpenAddPriceSaleItem = ()=>{
    // if(!openAddPriceSaleItem){
      setEditPriceSaleItem({
        KEY_ID:'',
        DISPLAY_GROUP: "",
        DISPLAY_ORDER: props.main.pricingOptions.length,
        ACTIVE: 1,
        DESCRIPTION: "",
        CATEGORY: "",
        TITLE: "",
        BOOTH_GROUP: "",
        QTY_TYPE: "",
        ROWS: []
      });
    // }
    setOpenAddPriceSaleItem(true)
    setOpenEditPriceSaleItem(false)
  }

  const handleOpenEditPriceSaleItem = (row)=>{
    setEditPriceSaleItem(row);    
    setOpenAddPriceSaleItem(false)
    setOpenEditPriceSaleItem(true)
  }

  const handleEditPriceSaleItemValue = (key, value)=>{
    setEditPriceSaleItem({...editPriceSaleItem, [key]:value});
  }

  const handleEditPriceSaleItemRowValue = (key, index, value)=>{
    setEditPriceSaleItem(
      {
        ...editPriceSaleItem, 
        ROWS: editPriceSaleItem.ROWS.map(
            (row, i) => i === index ? {...row, [key]: value}
                                    : row
        )
      }
    );
  }

  const handleEditPriceSaleItemColumValue = (key, index_row, index_column, value)=>{
    setEditPriceSaleItem(
      {
        ...editPriceSaleItem, 
        ROWS: editPriceSaleItem.ROWS.map(
            (row, i) => i === index_row ? 
              {...row, 
                COLUMNS: row.COLUMNS.map(
                  (column, j) => j === index_column ? {...column, [key]: value}
                                          : column
                )
              }                                    
              : row
        )
      }
    );
  }

  const handleAddPriceSaleItemRow = ()=>{
    setEditPriceSaleItem({
      ...editPriceSaleItem, 
      ROWS: [
        ...(editPriceSaleItem.ROWS?editPriceSaleItem.ROWS:[]),
        {
          ACTIVE:1,
          ROW_TITLE:"", 
          ROW_NO:editPriceSaleItem.ROWS?editPriceSaleItem.ROWS.length:0, 
          START_DATE: "",
          END_DATE: "",
          KEY_ID: '', 
          NONPROFIT: "",
          FILTER_TYPE: "",
          TFRAME: "",
          NONMEMBER: "",
          MEMBER: "",
          BOOTH_GROUP: "",
          DATE_RANGE:1,
          COLUMNS:[
            {
              PRICE: "",
              PRICE_TYPE: "",
              COLUMN_NO: 1,
              HEADER: "",
            }
          ]
        }
      ]
    });
  }

  const handleDeletePriceSaleItemRow = (deleteItem, index)=>{
    setDeleteItem(deleteItem)
    setDeleteIndex(index)
    setDeleteTitle(`Are you sure you want to delete the row?`)
    handleCloseDeleteModal = handleCloseDeleteRowModal
    confirmDeleteModal = confirmDeleteRowModal
    setOpenDeleteModal(true)
  }

  const confirmDeleteRowModal = ()=>{
    var row = deleteItem
    var index = deleteIndex
    if(row.KEY_ID && row.KEY_ID != ""){
      setEditPriceSaleItem(
        {
          ...editPriceSaleItem, 
          ROWS: editPriceSaleItem.ROWS.map(
              (row, i) => i === index ? {...row, ACTIVE: 0}
                                      : row
          )
        }
      );
    }
    else{
      setEditPriceSaleItem({...editPriceSaleItem, 
        ROWS: [
          ...editPriceSaleItem.ROWS.slice(0, index),
          ...editPriceSaleItem.ROWS.slice(index + 1)
        ]
      });
    }
    setDeleteItem(false)
    setDeleteIndex(false)
    setOpenDeleteModal(false)
  }

  
  const handleCloseDeleteRowModal=()=>{
    setOpenDeleteModal(false)
  }

  const deletePriceSaleItem = (deleteItem, index)=>{
    setDeleteItem(deleteItem)
    setDeleteIndex(index)
    setDeleteTitle(`Are you sure you want to delete the premium?`)
    handleCloseDeleteModal = handleCloseDeleteItemModal
    confirmDeleteModal = confirmDeleteItemModal
    setOpenDeleteModal(true)
  }

  const confirmDeleteItemModal= ()=>{
    var tmp = {
      ACTIVE: "0",
      KEY_ID: deleteItem.KEY_ID
    }
    var postData = {
      SUCCESS: true,
      SALES: [tmp]
    }
    var url = `${ESHOW_URL()}?method=putSales&product_type=sales&token=${getTokenCookie()}`
    var formdata = {
      query:`${ESHOW_URL()}?method=putSales&product_type=sales&token=${getTokenCookie()}`,
      postData: postData,
      returnResponse:true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
        const response = res.data;
        if(response.SUCCESS){
          setOpenSnack(true);
          setSnackMsg(response.MESSAGE?response.MESSAGE:"Success");
          fetchPricingOptions();
          setDeleteItem(false)
          setOpenDeleteModal(false)
        }
        else{
          var msg = "Something went wrong"
          if(response.LOG && response.LOG[0] && response.LOG[0]['MESSAGE']){
            msg = response.LOG[0]['MESSAGE']
          }
          setOpenSnack(true);
          setSnackMsg(msg);
        }
        setOpenAddPriceSaleItem(false)
        setOpenEditPriceSaleItem(false)
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  
  const handleCloseDeleteItemModal=()=>{
    setOpenDeleteModal(false)
  }

  const renderForm =()=> {
    return(
      <Box mt={0}>
        <Collapse in={openAddPriceSaleItem || openEditPriceSaleItem}>
          
          <Paper elevation={3} style={{padding:'20px'}}>
            {openAddPriceSaleItem&&
            <h4 style={{marginTop:0}}>New Sale Item</h4>
            }
            {openEditPriceSaleItem&&
            <h4 style={{marginTop:0}}>Edit Sale Item({editPriceSaleItem.TITLE})</h4>
            }
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField        
                  style={{marginTop:'7px'}}        
                  name="TITLE"
                  label="Title"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={editPriceSaleItem.TITLE?editPriceSaleItem.TITLE:''}
                  onChange={(e)=>handleEditPriceSaleItemValue('TITLE', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>                    
              <Grid item xs={12}>
                <TextField        
                  style={{marginTop:'7px'}}        
                  name="DESCRIPTION"
                  label="Description"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={editPriceSaleItem.DESCRIPTION?editPriceSaleItem.DESCRIPTION:''}
                  onChange={(e)=>handleEditPriceSaleItemValue('DESCRIPTION', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>  
              <Grid item xs={4}>
                <FormControl variant="outlined"
                  size="small" fullWidth style={{marginTop:'7px'}}>
                  <InputLabel shrink={true} id="demo-simple-select-label">Fee Category</InputLabel>
                  <Select
                    input={<OutlinedInput notched label="Fee Category" />}
                    value={editPriceSaleItem.CATEGORY?editPriceSaleItem.CATEGORY:''}
                    onChange={(e)=>handleEditPriceSaleItemValue('CATEGORY', e.target.value)}
                  >
                    {
                      props.main.feeCategoryOptions.map((item, index)=>{
                        return(
                          <MenuItem key={index} value={item.KEY_ID}>{item.TITLE}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>    
              <Grid item xs={4}>
                <FormControl variant="outlined"
                  size="small" fullWidth style={{marginTop:'7px'}}>
                  <InputLabel shrink={true} id="demo-simple-select-label">Fee Group</InputLabel>
                  <Select
                    input={<OutlinedInput notched label="Fee Group" />}
                    value={editPriceSaleItem.DISPLAY_GROUP?editPriceSaleItem.DISPLAY_GROUP:''}
                    onChange={(e)=>handleEditPriceSaleItemValue('DISPLAY_GROUP', e.target.value)}
                  >
                    {
                      props.main.feeGroupOptions.map((item, index)=>{
                        return(
                          <MenuItem key={index} value={item.KEY_ID}>{item.TITLE}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>  
              <Grid item xs={4}>
                {/* <FormControl variant="outlined"
                  size="small" fullWidth style={{marginTop:'7px'}}>
                  <InputLabel shrink={true} id="demo-simple-select-label">Booth Group</InputLabel>
                  <Select
                    input={<OutlinedInput notched label="Booth Group" />}
                    value={editPriceSaleItem.BOOTH_GROUP?editPriceSaleItem.BOOTH_GROUP:''}
                    onChange={(e)=>handleEditPriceSaleItemValue('BOOTH_GROUP', e.target.value)}
                  >
                    {
                      props.main.groupOptions.map((item, index)=>{
                        return(
                          <MenuItem value={item.KEY_ID}>{item.TITLE}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl> */}
              </Grid>  
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {
                editPriceSaleItem.ROWS &&
                <>
                {
                  editPriceSaleItem.ROWS.map((row, index)=>{
                    return(
                      row.ACTIVE*1 == 1 ?
                      <Paper key={index} className="sales-row">
                        <h4 style={{marginTop:0, marginBottom:'16px', fontSize: 15}}>Row{`${index+1}`}</h4>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField        
                              style={{marginTop:'7px'}}        
                              name="ROW_TITLE"
                              label="Row Title"
                              fullWidth
                              variant="outlined"
                              size="small"
                              value={row.ROW_TITLE?row.ROW_TITLE:""}
                              onChange={(e)=>handleEditPriceSaleItemRowValue('ROW_TITLE', index, e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>          
                          <Grid item xs={4}>
                            <TextField        
                              style={{marginTop:'7px'}}        
                              name="PRICE"
                              label="PRICE"
                              type='number'
                              step={0.01}
                              fullWidth
                              variant="outlined"
                              size="small"
                              value={row?.COLUMNS?.[0].PRICE?row?.COLUMNS?.[0].PRICE:0}
                              onChange={(e)=>handleEditPriceSaleItemColumValue('PRICE', index, 0, e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>          
                          <Grid item xs={4}>
                            <FormControl variant="outlined"
                              size="small" fullWidth style={{marginTop:'7px'}}>
                              <InputLabel shrink={true}>Price type</InputLabel>
                              <Select
                                input={<OutlinedInput notched label="Price type" />}
                                value={row?.COLUMNS?.[0].PRICE_TYPE?row?.COLUMNS?.[0].PRICE_TYPE:''}
                                onChange={(e)=>handleEditPriceSaleItemColumValue('PRICE_TYPE', index, 0, e.target.value)}
                              >
                                <MenuItem value={'flat'}>flat</MenuItem>
                                <MenuItem value={'sq_ft'}>square ft</MenuItem>
                                <MenuItem value={'percentage'}>percentage</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel control={<Switch checked={row?.MEMBER*1 == 1} onChange={(e)=>handleEditPriceSaleItemRowValue('MEMBER', index, row?.MEMBER*1 == 1?"":"1")}/>} label="Member" />
                          </Grid>               
                          <Grid item xs={4}>
                            <FormControlLabel control={<Switch checked={row?.NONMEMBER*1 == 1} onChange={(e)=>handleEditPriceSaleItemRowValue('NONMEMBER', index, row?.NONMEMBER*1 == 1?"":"1")}/>} label="Nonmember" />
                          </Grid>                
                          <Grid item xs={4}>
                            <FormControlLabel control={<Switch checked={row?.NONPROFIT*1 == 1} onChange={(e)=>handleEditPriceSaleItemRowValue('NONPROFIT', index, row?.NONPROFIT*1 == 1?"":"1")}/>} label="Nonprofit" />
                          </Grid>    
                          <Grid item xs={3}>
                            <TextField                
                              name="START_DATE"
                              margin="dense"
                              label="Start date"
                              fullWidth
                              type='date'
                              variant="outlined"
                              size="small"
                              value={row?.START_DATE?moment(row?.START_DATE).format("YYYY-MM-DD"):''}
                              onChange={(e)=>handleEditPriceSaleItemRowValue('START_DATE', index, e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>               
                          <Grid item xs={3}>
                            <TextField                
                              name="END_DATE"
                              margin="dense"
                              label="End date"
                              fullWidth
                              type='date'
                              variant="outlined"
                              size="small"
                              value={row?.END_DATE?moment(row?.END_DATE).format("YYYY-MM-DD"):''}
                              onChange={(e)=>handleEditPriceSaleItemRowValue('END_DATE', index, e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>        
                          <Grid item xs={3}>
                            <TextField        
                            style={{marginTop:'7px'}}        
                              name="name"
                              margin="PROMO_CODE"
                              label="Promo code"
                              fullWidth
                              variant="outlined"
                              size="small"
                              value={row?.PROMO_CODE?row?.PROMO_CODE:''}
                              onChange={(e)=>handleEditPriceSaleItemRowValue('PROMO_CODE', index, e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{textAlign:'right'}}>
                            <Button variant="text" onClick={()=>{handleDeletePriceSaleItemRow(row, index)}}  startIcon={<RemoveCircleOutlineIcon />}><b style={{lineHeight:1}}>Delete Row</b></Button>
                          </Grid>
                        </Grid>  
                      </Paper> :
                      <></>
                    )
                  })
                }
                  
                </>
                }
                <Button variant="text" onClick={()=>{handleAddPriceSaleItemRow()}}  startIcon={<AddCircleOutlineIcon />}><b style={{lineHeight:1}}>Add New Row</b></Button>

              </Grid>   
              <Grid item xs={12} style={{textAlign:'right', marginTop: 10}}>
                <Button variant="contained" onClick={savePriceSaleItem} style={{marginRight: 10}}>Save</Button>                      
                {openEditPriceSaleItem&&
                <ColorButton variant="contained" onClick={()=>deletePriceSaleItem(editPriceSaleItem)} style={{marginRight: 10}}>Delete</ColorButton>
                }
                <Button variant="text" onClick={()=>{setOpenAddPriceSaleItem(false); setOpenEditPriceSaleItem(false)}}>Cancel</Button>
              </Grid>                         
            </Grid>
          </Paper>
        </Collapse>
      </Box>
    )
  }
  const getPricesByGroup = (pricingOptions)=>{
    let pricesbyGroup = pricingOptions.reduce((r, a) => {
      r[a.DISPLAY_GROUP_NAME] = [...r[a.DISPLAY_GROUP_NAME] || [], a];
      return r;
     }, {});
    
    var output = [];
    for(var display_group in pricesbyGroup){
      var items = pricesbyGroup[display_group];
      output.push({display_group, items})
    }
    output.sort(function(a, b) {
      const nameA = a.display_group; // ignore upper and lowercase
      const nameB = b.display_group; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });
    console.log("groupBySize", output)
    return output;
  }

  var pricingOptions = [];
  if(props.main.premiumOptions){
    pricingOptions = [...props.main.premiumOptions]
    console.log("pricingOptions", pricingOptions)
    for(var i = 0; i < pricingOptions.length; i++){
      pricingOptions[i]['DISPLAY_GROUP_NAME'] = '';
      for(var j = 0; j < props.main.feeGroupOptions?.length; j++){
        if(pricingOptions[i].DISPLAY_GROUP == props.main.feeGroupOptions[j].KEY_ID){
          pricingOptions[i]['DISPLAY_GROUP_NAME'] = props.main.feeGroupOptions[j].TITLE
        }
      }
    }
    pricingOptions = pricingOptions.sort(function (a, b) {
      if((a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 == (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1){
        return (a.DISPLAY_GROUP_NAME?a.DISPLAY_GROUP_NAME:'') - (b.DISPLAY_GROUP_NAME?b.DISPLAY_GROUP_NAME:'');
      }
      else
        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
    });   
  }
  var pricingOptionsByGroup = getPricesByGroup(pricingOptions)
  

  return (
    <>            
      <Table className="pricing-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
        <TableHead>
          <TableRow>
              <TableCell width="70%">Title</TableCell>
              {/* <TableCell width="40%">Description</TableCell> */}
              <TableCell>Price</TableCell>
              <TableCell>Price type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pricingOptionsByGroup.map((pricingOptions, g_Index) => (
            <>
              <TableRow key={g_Index}>
                <TableCell colSpan='3' style={{textAlign:'left', padding:'10px 18px', borderBottom:'1px solid #e0e0e0'}}><b>{pricingOptions.display_group}</b></TableCell>
              </TableRow>
              {pricingOptions.items.map((row, index) => (
                <>
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>                      
                      <a href="javascript:void(0)" onClick={()=>handleOpenEditPriceSaleItem(row)}>{row.TITLE}</a>
                    </TableCell>
                    <TableCell>{row.ROWS?.[0]?.COLUMNS?.[0]?.PRICE}</TableCell>
                    <TableCell>{row.ROWS?.[0]?.COLUMNS?.[0]?.PRICE_TYPE == 'sq_ft'?`${props.main?.mainCanvas?.mapUnit == 'feet'?'SqFt':'SqM'}`:row.ROWS?.[0]?.COLUMNS?.[0]?.PRICE_TYPE}</TableCell>
                    {/* <TableCell>
                      {row.DISPLAY_GROUP_NAME}
                    </TableCell> */}
                  </TableRow>
                  {(openEditPriceSaleItem && editPriceSaleItem.KEY_ID == row.KEY_ID) &&
                  <TableRow>
                    <TableCell colSpan={3}>
                    {renderForm()}
                    </TableCell>
                  </TableRow>
                  }
                </>
              ))}
            </>
          ))}
        </TableBody>
      </Table>
      <Box mt={2}>
        <Button variant="text" onClick={()=>{handleOpenAddPriceSaleItem()}}  startIcon={<AddIcon />}><b>Create New Sale Item</b></Button>
        
        {openAddPriceSaleItem &&
          <>
            {renderForm()}
          </>
        }
      </Box>
      <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={snackMsg}
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                  <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
          }
      />
      <DeleteConfirm
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onOk={confirmDeleteModal}
          title={deleteTitle}
      />
    </>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setUnit : value=>dispatch(setUnit(value)),
        setBoothInfo:  value=>dispatch(setBoothInfo(value)),
        setLoading:  data=>dispatch(setLoading(data)),
        setGroupOptions:  data=>dispatch(setGroupOptions(data)),
        setPricingOptions:  data=>dispatch(setPricingOptions(data)),
        setPremiumOptions:  data=>dispatch(setPremiumOptions(data)),
        setDiscountOptions:  data=>dispatch(setDiscountOptions(data)),
        setCategoryOptions:  data=>dispatch(setCategoryOptions(data)),
        setBoothFileList: data=>dispatch(setBoothFileList(data)) ,
        setMechantAccount: data=>dispatch(setMechantAccount(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);