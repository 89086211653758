import * as React from 'react';
import { fabric } from "fabric";
import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit,  setLoading } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { SwatchesPicker , PhotoshopPicker  } from 'react-color'
import rgbHex from "rgb-hex";
import axios from 'axios';
import qs, { parse } from 'qs'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, setObjectBG, resizeBooth, addText, addTableText, px2unit, unit2Px} from '../../utils/CanvasTool'

import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { getDefaultGroup } from '../../utils/Common'

import { parseToken } from '../../utils/Common'
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SetBoothModal(props) {
  
  let query = useQuery();
  const [snackMsg, setSnackMsg] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [activeObject, setActiveObject] = React.useState(null);
  const [priceCheck , setPriceCheck] = React.useState([]);
  const [premiumCheck , setPremiumCheck] = React.useState('');
  const [discountCheck, setDiscountCheck] = React.useState('');
  const [boothName , setBoothName] = React.useState('');
  const [boothWidth , setBoothWidth] = React.useState(0);
  const [boothHeight , setBoothHeight] = React.useState(0);
  const [boothLeft , setBoothLeft] = React.useState(0);
  const [boothTop , setBoothTop] = React.useState(0);
  const [boothLeftOffset , setBoothLeftOffset] = React.useState(0);
  const [boothTopOffset , setBoothTopOffset] = React.useState(0);
  const [boothGroup , setBoothGroup] = React.useState('');
  

  
  var mainCanvas = props.main.mainCanvas
  const { onClose, onOk , open } = props;
  var mainLayers = props.main.mainLayers;
  
  var pricingOptions = props.main.pricingOptions
  var premiumOptions = props.main.premiumOptions
  var discountOptions = props.main.discountOptions
  var groupOptions = props.main.groupOptions
  
  var activeFile;
  var boothFileList = props.main.boothFileList;
  for(var i = 0; i <boothFileList.length; i++){
      if(boothFileList[i]['active']){
          activeFile = boothFileList[i];
          break;
      }
  }

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }

  const onSet = ()=>{
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
    }
    if(activeObject && activeObject.type=="activeSelection"){
      var objects = activeObject.getObjects()
      for(var i = 0; i < objects.length; i++ ){
        objects[i].set({
          boothGroup: boothGroup,
          modified: true
        })
      }
    }
    else if(activeObject && activeObject.class=="table"){
      activeObject.set({
        booth_number: boothName,
        boothGroup: boothGroup,
        modified: true
      })
      mainCanvas.renderAll()
      var textObjects = activeObject.getObjects('text');
      for(var k = 0; k< textObjects.length; k++){
        activeObject.remove(textObjects[k])
      }

      addTableText(mainCanvas, activeObject, boothName)

    }
    mainCanvas.renderAll()
    onOk(activeObject);
  }
  
  const handlePriceCheck = (key)=>{
    var newPriceCheck
    if(priceCheck)
      newPriceCheck = [...priceCheck];
    else
      newPriceCheck = [];
    if(newPriceCheck.includes(key)){
      for( var i = 0; i < newPriceCheck.length; i++){ 
                                   
        if ( newPriceCheck[i] === key) { 
          newPriceCheck.splice(i, 1); 
          i--; 
        }
        
      setPriceCheck(newPriceCheck)
    }
    }
    else{
      setPriceCheck([...newPriceCheck, key])
    }
  }

  const handlePremiumCheck = (key)=>{
    var newPremiumCheck
    if(premiumCheck)
      newPremiumCheck = [...premiumCheck];
    else
      newPremiumCheck = [];
    if(newPremiumCheck.includes(key)){
      for( var i = 0; i < newPremiumCheck.length; i++){ 
                                   
        if ( newPremiumCheck[i] === key) { 
          newPremiumCheck.splice(i, 1); 
          i--; 
        }
        
      setPremiumCheck(newPremiumCheck)
    }
    }
    else{
      setPremiumCheck([...newPremiumCheck, key])
    }
  }

  const handleDiscountCheck = (key)=>{
    var newDiscountCheck
    if(discountCheck)
      newDiscountCheck = [...discountCheck];
    else
    newDiscountCheck = [];
    if(newDiscountCheck.includes(key)){
      for( var i = 0; i < newDiscountCheck.length; i++){ 
                                   
        if ( newDiscountCheck[i] === key) { 
          newDiscountCheck.splice(i, 1); 
          i--; 
        }
        
      setDiscountCheck(newDiscountCheck)
    }
    }
    else{
      setDiscountCheck([...newDiscountCheck, key])
    }
  }

  
  useEffect(() => {
    var mainCanvas = props.main.mainCanvas
    if(open && mainCanvas){      
      var activeObject = mainCanvas.getActiveObject();

      if(activeObject.type == "group" && (activeObject.class=="table")){
        var convertRateX= 1
        if(mainCanvas.convertRateX) {
            var convertRateX= Math.abs(mainCanvas.convertRateX)
        }
        
        setBoothName(activeObject.booth_number)
        setBoothWidth(px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit).toFixed(0))
        setBoothHeight(px2unit(activeObject.height, convertRateX, mainCanvas.mapUnit).toFixed(0))
        setBoothLeft(px2unit(activeObject.left-45, convertRateX, mainCanvas.mapUnit).toFixed(2))
        setBoothTop(px2unit(activeObject.top-45, convertRateX, mainCanvas.mapUnit).toFixed(2))
        setBoothGroup(activeObject.boothGroup?activeObject.boothGroup:"")
        setBoothLeftOffset(0)
        setBoothTopOffset(0)

        var tmp1 = activeObject.get('premiumCheck')
        if(tmp1){
          setPremiumCheck(tmp1)
        }
        else{
          setPremiumCheck([])
        }
        var tmp2 = activeObject.get('priceCheck')
        if(tmp2){
          setPriceCheck(tmp2)
        }
        else{
          setPriceCheck([])
        }
        var tmp3 = activeObject.get('discountCheck')
        if(tmp3){
          setDiscountCheck(tmp3)
        }
        else{
          setDiscountCheck([])
        }
      }
      else if(activeObject.type == "activeSelection"){
        var objects = activeObject.getObjects()
        var group = getDefaultGroup(props.main.groupOptions)
        for(var i = 0; i < objects.length; i++ ){
          if(objects[i].boothGroup != "Default"){
            group = null
          }
        }
        setBoothGroup(group)
        setPremiumCheck([])
        setDiscountCheck([])
        setPriceCheck([])
      }
      else{        
        setBoothGroup(null)
        setPremiumCheck([])
        setDiscountCheck([])
        setPriceCheck([])
      }

      setActiveObject(activeObject)
    }
  }  , [open])
  
  const onChangeName = (e)=>{
    setBoothName(e.target.value)
  }
  console.log("boothGroup", boothGroup)
  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'settable-dialog-title'}}
      aria-labelledby="settable-dialog-title"
      fullWidth={true}
      maxWidth={"md"} 
      onClose={onClose} 
      open={open}
    >
      <DialogTitle id='settable-dialog-title'>Table Setup</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container spacing={2} style={{marginTop:15}}>
            <Grid item xs={6}>
              <TextField
                name="boothGroup"
                margin="dense"
                label="Table Group"
                type="name"
                fullWidth
                variant="outlined"
                size="small"
                value={boothGroup}
                onChange={(e)=>{setBoothGroup( e.target.value); console.log(e.target.value)}}
                InputLabelProps={{
                  shrink: true,
                }}
              />        
            </Grid>
            <Grid item xs={6}>
              {activeObject && activeObject.type !='activeSelection' &&
              <TextField
                name="boothName"
                margin="dense"
                label="Table Number"
                type="name"
                fullWidth
                variant="outlined"
                size="small"
                value={boothName}
                onChange={onChangeName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              }
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSet}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={snackMsg}
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                  <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
          }
      />
    </Dialog>
    
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setLoading:  data=>dispatch(setLoading(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SetBoothModal);