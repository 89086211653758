import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function LayerModal(props) {
  const { onClose, selectedValue, open } = props;
  var mainLayers = props.main.mainLayers;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    // onClose(value);
    var tmp = [...mainLayers];
    for(var i = 0; i <tmp.length; i++){
        if(tmp[i]['id'] == value){
            tmp[i] = {...tmp[i], active:!tmp[i]['active']};
        }
    }
    props.setMainLayers(tmp)

  };

  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'layer-dialog-title'}}
      aria-labelledby="layer-dialog-title"
      onClose={handleClose} open={open}>
      <DialogTitle id='layer-dialog-title'>Layer Selection</DialogTitle>
      <List sx={{ pt: 0 }}>
        {mainLayers.map((layer, index) => (
          <ListItem button onClick={() => handleListItemClick(layer['id'])} key={index}>
            <ListItemAvatar>
                {layer['active']?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
            </ListItemAvatar>
            <ListItemText primary={layer['name']} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(LayerModal);