export const SET_LOG = 'SET_LOG'
export const ADD_LOG = 'ADD_LOG'
export const ADD_LOGS = 'ADD_LOGS'

export const setLog = data =>({
    type:SET_LOG,
    payload:data
});

export const addLog = data =>({
    type:ADD_LOG,
    payload:data
});

export const addLogs = data =>({
    type:ADD_LOGS,
    payload:data
});