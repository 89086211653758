import FabricCanvasTool from "../fabrictool";
import { getSnapPosition, addText, getFirstZoom } from '../../../../../utils/CanvasTool'

class Pencil extends FabricCanvasTool {
  configureCanvas(props) {
    this._canvas.isDrawingMode = true;
    var strokeWidth = 1/getFirstZoom(this._canvas)
    // this._canvas.freeDrawingBrush.width = props.lineWidth;
    this._canvas.freeDrawingBrush.width = strokeWidth;
    this._canvas.freeDrawingBrush.color = props.lineColor;
    this.props = props
  }
}

export default Pencil;
