import React ,{ useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux'
import { fabric } from "fabric";
import qs, { parse } from 'qs'
import './ViewClient.css';
import { useNavigate } from 'react-router-dom'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { API_URL, ESHOW_URL } from '../config.js'
import { setViewData,  setBoothFileList, setBoothList, setCanvasRefresh, setLoading, setShowGrid, setPlanner } from '../actions/main'
import { setOpenBoothList, setOpenLogin, setMenuList,setCustomStyles, setOpenCompanyModal } from '../actions/ui'
import { setBoothHistory } from '../actions/booth_history'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import LoginModal from './component/LoginModal'
import ErrorModal from './component/ErrorModal'
import SignupModal from './component/SignupModal'
import { getTokenCookie, getTokenPlanner , getTokenInfoPlanner, deleteTokenPlanner, convertRgb, composeUrl, assetUrl, getUrlToken} from '../utils/Common'
import { useParams } from 'react-router-dom'
import CompanyEditModal from './component/CompanyEditModal'
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { A } from '@svgdotjs/svg.js';
import ResetPasswordModal from './component/ResetPasswordModal';




  const ColorButton = styled(Button)(({ theme }) => ({
    color: '#7c7c7c',
    minWidth:'55px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#c9c9c980',
    },
  }));

  const Button3D =  styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#22222250',
    },
    border:'2px solid white',
    float:'right'
  }));

  
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function ToolBox(props) {
    const { id1, id2, id3, id4 } = useParams();
    // const [menuList, setMenuList] = useState([]);
    const [logos, setLogos] = useState([]);
    const [openMenu, setOpenMenu] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    let query = useQuery();
    const navigate = useNavigate()
    const openRef = useRef   
    const toolboxRef = useRef(null);
    const useStyles = makeStyles((theme) => ({
      navlinks: {
        marginLeft: 10,
        display: "flex",
        justifyContent:'end',
        marginTop:16
      },
      logo: {
        flexGrow: "1",
        cursor: "pointer",
      },
      link: {
        textDecoration: "none",
        color: props.ui?.customStyles?.['top-menu-color']?props.ui?.customStyles['top-menu-color']:'white',
        fontSize: "15.5px",
        fontWeight: 'bold',
        height:'unset',
        marginLeft: 5,
        borderBottom: "1px solid transparent",
        "&:hover": {
          color: props.ui?.customStyles?.['top-menu-color-hover']?props.ui?.customStyles['top-menu-color-hover']:'#eeeeee',
          borderBottom: "1px solid #eeeeee80",
        },
        "&.active": {
          color: props.ui?.customStyles?.['top-menu-color-hover']?props.ui?.customStyles['top-menu-color-hover']:'#eeeeee',
          borderBottom: "1px solid #eeeeee80",
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      menuIcon:{
        color: props.ui?.customStyles?.['left-drawer-menuicon-color']?(props.ui?.customStyles['left-drawer-menuicon-color']+"!important"):'white',
        "&:hover": {
          color: props.ui?.customStyles?.['left-drawer-menuicon-color-hover']?(props.ui?.customStyles['left-drawer-menuicon-color-hover']+"!important"):'white',
        }
      },
      '@media (max-width: 1400px)': {
        link: {
          marginLeft: 0,
          color:'#363636',
          fontWeight:'normal',
          width: '100%',
          "&:hover": {
            color: props.ui?.customStyles?.['top-menu-color-hover']?props.ui?.customStyles['top-menu-color-hover']:'#eeeeee',
            borderBottom: "1px solid #eeeeee80",
          },
          "&.active": {
            color: props.ui?.customStyles?.['top-menu-color-hover']?props.ui?.customStyles['top-menu-color-hover']:'#eeeeee',
            borderBottom: "1px solid #eeeeee80",
          },
        },
        link_button:{
          marginBottom: '10px'
        }
      },
    }));

    
    const classes = useStyles();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var show_key = urlParams.get('show_key')
    var urlToken = getUrlToken()
    
    const toggleMenuDrawer = (open) => (event) => {
      // if (
      //   event.type === "keydown" &&
      //   (event.key === "Tab" || event.key === "Shift")
      // ) {
      //   return;
      // }
      setOpenMenu(open);
    };   
    
    useEffect(() => {   
      try{     
        var urlToken = getUrlToken()
        const tokenInfo = getTokenInfoPlanner();
        if(tokenInfo){
          props.setPlanner({tokenInfo, key:urlToken})
        }
      }
      catch(e){
        console.log("token parse", e)
      }
      // fetchShowSetup()
      // fetchMenu()
      
    }, [])
    
    useEffect(() => {  
      if(props.main.mainCanvas && props.main.boothList)  {
        var groups = props.main.mainCanvas.getObjects('group');
        var logos = [];
        for(var i = 0; i< groups.length; i++){  
          var group = groups[i]
          if(group && (group.get('class') == 'booth' || group.get('class') == 'booth-group')){                            
            var id = group.id;
            var priced = false
            var priceCheck = group.get('priceCheck');
            var premiumCheck = group.get('premiumCheck');
            // if(premiumCheck){
            //     priced = true
            // }
            // if(priceCheck && priceCheck.length > 0){
            //     priced = true
            // }
            if(group.get('sold')){
                priced = true
            }
            if(priced){
              var company_log_url = group.get('company_log_url');
              if(company_log_url){
                logos.push(company_log_url)
              }
            }
          }
        }
        setLogos(logos);
      }
    }, [props.main.boothList])

    const doSearch = ()=>{

    }

    const handleDrawerToggle = () => {
      props.setOpenBoothList(!props.ui.openBoothList);
    };
    const handleDrawerOpen = () => {
      props.setOpenBoothList(true);
    };
    const handleDrawerClose = () => {
      props.setOpenBoothList(false);
    };

    var sales_header_show = true
    if(props.main.authInfo && props.main.authInfo.SALES){
      if(props.main.authInfo.SALES.MAP_TYPE == 'sales'){
        sales_header_show = false
      }
    }
    if(props.main.authInfo && props.main.authInfo.tokenInfo){
      if(props.main.authInfo.tokenInfo.HIDE_TOP_NAVIGATION){
        sales_header_show = false
      }
    }
    var bookmark_show = true
    var download_show = true
    var contact_show = true
    if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
      bookmark_show = false
    }
    if(props.main.showSetup?.PUBLIC?.DOWNLOAD_LINKS == 'hide'){
      download_show = false
    }
    if(props.main.showSetup?.PUBLIC?.CONTACT_INFO == 'hide'){
      contact_show = false
    }
    const _token = getTokenPlanner();
    var SHOW_NAME = props.main.showSetup?.SHOW_NAME?props.main.showSetup?.SHOW_NAME:"";
    var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
    if(banner == "undefined") banner = null
    var header_color = props.main.showSetup?.PUBLIC?.HEADER_COLOR;    
    var logo = props.main.showSetup?.PUBLIC?.LOGO;
    var public_path = ''
    if(props.urlToken){
      public_path = props.main.authInfo.tokenInfo?.ESHOW_PATH
    }
    return (
      <>
        <Box className="public" sx={{ flexGrow: 1, zIndex:2}}>    
          {sales_header_show&&
            <AppBar ref={toolboxRef} id='header-bar' position="fixed" style={{background:header_color?`${convertRgb(header_color)}`:'linear-gradient(#f0f0f0, #f0f0f0)'}}>
                      
                {banner && banner!='' && props.page != 'home' &&
                <Box style={{background:'white', height:'150px'}}>
                  <img style={{maxWidth:'100%', height: '150px', objectFit:'cover'}} src={assetUrl(banner)}/>
                </Box>
                }
                <Toolbar>
                    {props.main.authInfo.authenticated == 3&& 
                    <>
                      {props.page == 'home' || 1 ?
                      <>
                        <Box style={{width:'311px'}}> 
                          {logo&&
                          <div id="xhbt-header-logo" className='header-logo' style={{width: '100%', height:'65px',margin:0, border:'none', display:'flex', alignItems:'center'}}>
                            <img id="xhbt-header-logo-img" style={{ width: "auto", height:'100%' }} src={assetUrl(logo)}  />
                          </div>
                          }
                        </Box>
                      </>:                    
                      <Box style={{width:'350px'}}> </Box>
                      }
                      <Box
                        className='xhbt-main-menu-main-container'
                        style={{width:'calc(100% - 350px'}}
                      >
                        <div className={`${classes.navlinks}  xhbt-main-menu-container`}>
                          {
                            props.ui.menuList?.map((menu, index)=>{
                              
                              if(menu.EXTERNAL_URL){
                                return(
                                  <a key={index} target={menu.TAB=="new"?"_blank":""} 
                                    href={`https://${menu.EXTERNAL_URL?menu.EXTERNAL_URL.trim().replace("https://", ""):""}`} 
                                    className={`${classes.link} xhbt-main-menu-link`}>
                                    {menu.TITLE?menu.TITLE.trim():""}
                                  </a>
                                )
                              }                            
                              else if(menu.PAGE_NAME == 'Home'|| menu.PAGE_NAME == 'home'){
                                return(
                                  <a key={index} target={menu.TAB=="new"?"_blank":""} 
                                    href={`https://${menu.URL?menu.URL.trim().replace("https://", ""):""}`} 
                                    className={`${classes.link} xhbt-main-menu-link`}>
                                    {menu.TITLE?menu.TITLE.trim():""}
                                  </a>
                                )
                              }                            
                              else if(menu.URL?.includes("V3_MYPLANNER")){
                                if(_token && props.main[`planner-${urlToken}`] && props.main[`planner-${urlToken}`].TOKEN){
                                  return(
                                    <a key={index} target={menu.TAB=="new"?"_blank":""} 
                                      // to={`${public_path}/${menu.PAGE_NAME?menu.PAGE_NAME.trim():""}${query.get('show_key')?`?show_key=${query.get('show_key')}`:''}`} 
                                      href="javascript:void(0)"
                                      onClick={ (event) => 
                                        {
                                          if(!window.location.href?.includes(composeUrl(public_path, menu.PAGE_NAME.trim()))){
                                            event.preventDefault() 
                                            props.setLoading(true)
                                            setTimeout(()=>{
                                              navigate(`${composeUrl(public_path, menu.PAGE_NAME.trim())}${query.get('show_key')?`?show_key=${query.get('show_key')}`:''}`)
                                            }, 100)
                                          }
                                        }
                                      }
                                      className={`${classes.link} ${window.location.href.includes(composeUrl(public_path, menu.PAGE_NAME.trim()))?"active":""} xhbt-main-menu-link`}>
                                      {menu.TITLE?menu.TITLE.trim():""}
                                    </a>
                                  )
                                }
                                else{
                                  return(
                                    <a key={index} href="javascript:void(0)" 
                                      className={`${classes.link} ${window.location.href.includes(composeUrl(public_path, menu.PAGE_NAME.trim()))?"active":""} xhbt-main-menu-link`}
                                      onClick={()=>{
                                        props.setOpenLogin(true)
                                      }}
                                    >
                                      {menu.TITLE?menu.TITLE.trim():""}
                                    </a>
                                  )
                                }
                              }
                              else{
                                return(
                                  <a 
                                    key={index}
                                    target={menu.TAB=="new"?"_blank":""} 
                                    href="javascript:void(0)"
                                    onClick={ (event) => 
                                      {
                                        if(!window.location.href?.includes(composeUrl(public_path, menu.PAGE_NAME.trim()))){
                                          event.preventDefault() 
                                          props.setLoading(true)
                                          setTimeout(()=>{
                                            navigate(`${composeUrl(public_path, menu.PAGE_NAME.trim())}${query.get('show_key')?`?show_key=${query.get('show_key')}`:''}`)
                                          }, 100)
                                        }
                                      }
                                    }
                                    className={`${classes.link} ${window.location.href.includes(composeUrl(public_path, menu.PAGE_NAME.trim()))?"active":""} xhbt-main-menu-link`}>
                                    {menu.TITLE?menu.TITLE.trim():""}
                                  </a>
                                )
                              }
                            })
                          }
                          {props.main[`planner-${urlToken}`] && props.main[`planner-${urlToken}`].TOKEN && 
                            <a 
                              href="javascript:void(0)" 
                              onClick={ (event) => 
                                {
                                    event.preventDefault() 
                                    deleteTokenPlanner()
                                    props.setPlanner({tokenInfo:false, key:urlToken})
                                    if(window.location.href.toLowerCase().includes("myplanner") || window.location.href.toLowerCase().includes("my_planer")){
                                      navigate(`${public_path}/map${query.get('show_key')?`?show_key=${query.get('show_key')}${query.get('sales_map')?"&sales_map=1":""}`:`${query.get('sales_map')?"?sales_map=1":""}`}`)
                                    }
                                }
                              }
                            className={`${classes.link} xhbt-main-menu-link`}>
                              LOG OUT
                            </a>
                          }
                        </div>
                      </Box>
                      <Box
                        className='xhbt-main-menu-burger-icon'
                        sx={{
                          mr: 2,
                        }}
                        style={{width:'calc(100% - 350px', textAlign:'right'}}
                      >
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="open drawer"
                          onClick={toggleMenuDrawer(true)}
                        >
                          <MenuIcon />
                        </IconButton>
                      </Box>
                      <Drawer
                        anchor="right"
                        open={openMenu}
                        onClose={toggleMenuDrawer(false)}
                        onOpen={toggleMenuDrawer(true)}
                      >
                        {/* The inside of the drawer */}
                        <Box
                          sx={{
                            p: 2,
                            height: 1,
                            backgroundColor: "#f5f5f5"
                          }}
                        >
                          <IconButton sx={{ mb: 2 }}>
                            <CloseIcon onClick={toggleMenuDrawer(false)} />
                          </IconButton>

                          <Divider sx={{ mb: 2 }} />

                          <Box sx={{ mb: 2 }} className={classes.mobile}>
                            {
                              props.ui.menuList?.map((menu, index)=>{
                                if(menu.EXTERNAL_URL){
                                  return(
                                    <ListItemButton key={index} sx={{marginBottom: '10px'}}>
                                      <a target={menu.TAB=="new"?"_blank":""} 
                                        href={`https://${menu.EXTERNAL_URL?menu.EXTERNAL_URL.trim().replace("https://", ""):""}`} 
                                        className={`${classes.link} xhbt-main-menu-link`}>
                                        {menu.TITLE?menu.TITLE.trim():""}
                                      </a>
                                    </ListItemButton>
                                  )
                                }
                                else if(menu.PAGE_NAME == 'Home'|| menu.PAGE_NAME == 'home'){
                                  return(
                                    <ListItemButton key={index} sx={{marginBottom: '10px'}}>
                                      <a target={menu.TAB=="new"?"_blank":""} 
                                        href={`https://${menu.URL?menu.URL.trim().replace("https://", ""):""}`} 
                                        className={`${classes.link} xhbt-main-menu-link`}>
                                        {menu.TITLE?menu.TITLE.trim():""}
                                      </a>
                                    </ListItemButton>
                                  )
                                }                            
                                else if(menu.URL?.includes("V3_MYPLANNER")){
                                  if(_token && props.main[`planner-${urlToken}`] && props.main[`planner-${urlToken}`].TOKEN){
                                    return(
                                      <ListItemButton key={index} sx={{marginBottom: '10px'}}>
                                        <a target={menu.TAB=="new"?"_blank":""} 
                                          // to={`${public_path}/${menu.PAGE_NAME?menu.PAGE_NAME.trim():""}${query.get('show_key')?`?show_key=${query.get('show_key')}`:''}`} 
                                          href="javascript:void(0)"
                                          onClick={ (event) => 
                                            {
                                              if(!window.location.href?.includes(composeUrl(public_path, menu.PAGE_NAME.trim()))){
                                                event.preventDefault() 
                                                props.setLoading(true)
                                                setTimeout(()=>{
                                                  navigate(`${composeUrl(public_path, menu.PAGE_NAME.trim())}${query.get('show_key')?`?show_key=${query.get('show_key')}`:''}`)
                                                }, 100)
                                              }
                                            }
                                          }
                                          className={`${classes.link} ${window.location.href.includes(menu.PAGE_NAME.trim())?"active":""} xhbt-main-menu-link`}>
                                          {menu.TITLE?menu.TITLE.trim():""}
                                        </a>
                                      </ListItemButton>
                                    )
                                  }
                                  else{
                                    return(
                                      <ListItemButton key={index} sx={{marginBottom: '10px'}}>
                                        <a href="javascript:void(0)" 
                                          className={`${classes.link} ${window.location.href.includes(composeUrl(public_path, menu.PAGE_NAME.trim()))?"active":""} xhbt-main-menu-link`}
                                          onClick={()=>{
                                            props.setOpenLogin(true)
                                          }}
                                        >
                                          {menu.TITLE?menu.TITLE.trim():""}
                                        </a>
                                      </ListItemButton>
                                    )
                                  }
                                }
                                else{
                                  return(
                                    <ListItemButton key={index} sx={{marginBottom: '10px'}}>
                                      <a 
                                        target={menu.TAB=="new"?"_blank":""} 
                                        href="javascript:void(0)"
                                        onClick={ (event) => 
                                          {
                                            if(!window.location.href?.includes(composeUrl(public_path, menu.PAGE_NAME.trim()))){
                                              event.preventDefault() 
                                              props.setLoading(true)
                                              setTimeout(()=>{
                                                navigate(`${composeUrl(public_path, menu.PAGE_NAME.trim())}${query.get('show_key')?`?show_key=${query.get('show_key')}`:''}`)
                                              }, 100)
                                            }
                                          }
                                        }
                                        className={`${classes.link} ${window.location.href.includes(composeUrl(public_path, menu.PAGE_NAME.trim()))?"active":""} xhbt-main-menu-link`}>
                                        {menu.TITLE?menu.TITLE.trim():""}
                                      </a>
                                    </ListItemButton>
                                  )
                                }
                              })
                            }
                            {props.main[`planner-${urlToken}`] && props.main[`planner-${urlToken}`].TOKEN && 
                              <ListItemButton sx={{marginBottom: '10px'}}>
                                <a                                 
                                  href="javascript:void(0)" 
                                  onClick={ (event) => 
                                    {
                                        event.preventDefault() 
                                        deleteTokenPlanner()
                                        props.setPlanner({tokenInfo:false, key:urlToken})
                                        if(window.location.href.toLowerCase().includes("myplanner") || window.location.href.toLowerCase().includes("my_planer")){
                                          navigate(`${public_path}/map${query.get('show_key')?`?show_key=${query.get('show_key')}${query.get('sales_map')?"&sales_map=1":""}`:`${query.get('sales_map')?"?sales_map=1":""}`}`)
                                        }
                                    }
                                  }
                                className={`${classes.link} xhbt-main-menu-link`}>
                                  LOG OUT
                                </a>
                              </ListItemButton>
                            }
                          </Box>
                        </Box>
                      </Drawer>
                    </>
                    }
                </Toolbar>
            </AppBar>
          }
          {props.main.authInfo.authenticated == 3&&
            <>
            <LoginModal/>
            <SignupModal/>
            <ResetPasswordModal/>
            <ErrorModal/>
            </>
          }
        </Box>
      </>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
      booth_history: state.booth_history.present,
      canUndo: state.booth_history.past.length > 1,
      canRedo: state.booth_history.future.length > 0
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {    
        setViewData: data=>dispatch(setViewData(data)),
        setBoothList: data=>dispatch(setBoothList(data)),
        setBoothFileList: data=>dispatch(setBoothFileList(data)),
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        onUndo: () => dispatch(UndoActionCreators.undo()),
        onRedo: () => dispatch(UndoActionCreators.redo()),
        onClearHistory: () => dispatch(UndoActionCreators.clearHistory()),        
        setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),      
        setLoading:  data=>dispatch(setLoading(data)),
        setShowGrid:  data=>dispatch(setShowGrid(data)),
        setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
        setPlanner: value=>dispatch(setPlanner(value)) ,
        setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
        setMenuList: value=>dispatch(setMenuList(value)) ,
        setCustomStyles: data=>dispatch(setCustomStyles(data)),
        setOpenCompanyModal:  data=>dispatch(setOpenCompanyModal(data)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(ToolBox);

  



