import React ,{ useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import DeselectIcon from '@mui/icons-material/Deselect';
import Tooltip from '@mui/material/Tooltip';
import { purple } from '@mui/material/colors';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import {SketchField, Tools} from './sketch_right';
import { setDraggingTarget, setBoothList, setCanvasRefresh, setElementDrawing } from '../../actions/main'
import { setBoothPrefix, setBoothStartNumber, setBoothSuffix, setBoothCnt, setBoothOrder, setBoothSeqMode } from '../../actions/element'
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, redrawobjectborder, adjustobjectborder, addText, addTableText, feet2meter, meter2feet, px2unit, unit2Px} from '../../utils/CanvasTool'
import { setBoothHistory } from '../../actions/booth_history'
import { fabric } from "fabric";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { v4 as uuidv4 } from 'uuid';

const drawerWidth = 420;
const ToggleButton = styled(Button)(({ theme }) => ({
  color: 'inherit',
  backgroundColor: '#e7e7e7',
  '&:hover, &.active': {
    backgroundColor: '#114887',
    color: 'white'
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  borderColor:'white!important',
  backgroundColor: '#7D99B3',
  '&:hover': {
    backgroundColor: '#356080',
  },
  '&.active': {
    backgroundColor: '#355879',
  },
}));

const DragButton = styled(Button)(({ theme }) => ({
  color: 'inherit',
  fontSize: '13px',
  backgroundColor: 'transparent',
  '&:hover, &.active': {
    backgroundColor: '#e7e7e7',
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content .MuiTypography-root': {
    fontSize: 14.5
  },
  borderRadius:0,
  borderLeft: 0,
  borderRight: 0
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  float: 'left',
  marginTop: 6,
  marginBottom: 6
}));
var draggingTarget;
var draggingClass;
var dragImages = {};
 
var g_boothPrefix = "";
var g_boothStartNumber = 1;
var g_boothSuffix = "";
var g_boothCnt = 1;
var g_boothOrder = "v_inc";
var g_boothSeqMode = "sequential";
var g_boothNumberDuplicated = false

function ElementBox(props) {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState('panel1');
  const [element, setElement] = React.useState('panel1');
  const [tool, setTool] = React.useState(null)
  const [_sketch,setSketch] = React.useState(null)
  const [boothNumberDuplicated, setBoothNumberDuplicated]  = React.useState(false)
  const [snackMsg, setSnackMsg] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [openDraw, setOpenDraw] = React.useState(false);
  var open = props.open
  var mainCanvas = props.main.mainCanvas
  const handleCloseSnack = ()=>{
    setOpenSnack(false);
}

  const checkDuplication = ()=>{
    var mainCanvas = props.main.mainCanvas
    var boothNumber = g_boothStartNumber*1;
    for(var i = 0; i < g_boothCnt; i++){      
      var boothNumberFinal = g_boothPrefix+""+boothNumber+""+g_boothSuffix
      var objects = mainCanvas.getObjects();
      for(var j = 0; j < objects.length; j++){
          if(objects[j].booth_number == boothNumberFinal && boothNumberFinal){ 
            setBoothNumberDuplicated(true)
            return;
          }
      }
      if(g_boothSeqMode == 'sequential'){
        boothNumber+=1;
      }
      else{
        boothNumber+=2;
      }
      setBoothNumberDuplicated(false)
    }
  }
  // var img1 = new Image();
  // img1.style.width = '100px';
  // img1.style.height = '100px';
  // img1.width="200px";
  // img1.height="200px";
  
  var img1 = new Image(65, 65);
  // img2.width="200px";
  // img2.height="200px";
  img1.src = '/asset/toilet-location-icon.png';
  dragImages['toilet-location-icon'] = img1;

  var img2 = new Image(65, 65);
  // img2.width="200px";
  // img2.height="200px";
  img2.src = '/asset/food-location-icon.png';
  dragImages['food-location-icon'] = img2;

  var img3 = new Image(65, 65);
  // img3.width="200px";
  // img3.height="200px";
  img3.src = '/asset/female-toilet-location-icon.png';
  dragImages['female-toilet-location-icon'] = img3;

  var img4 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img4.src = '/asset/food-restaurant-icon.png';
  dragImages['food-restaurant-icon'] = img4;

  
  var img5 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img5.src = '/asset/arrow-round-right-black-icon.png';
  dragImages['arrow-round-right-black-icon'] = img5;

  
  var img6 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img6.src = '/asset/coffee-icon.png';
  dragImages['coffee-icon'] = img6;

  
  
  var img7 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img7.src = '/asset/glass-martini-icon.png';
  dragImages['glass-martini-icon'] = img7;

  
  
  var img8 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img8.src = '/asset/camera-icon.png';
  dragImages['camera-icon'] = img8;

  
  
  var img9 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img9.src = '/asset/charging-station-icon.png';
  dragImages['charging-station-icon'] = img9;

  
  
  var img10 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img10.src = '/asset/star-sharp-icon.png';
  dragImages['star-sharp-icon'] = img10;

  
  
  var img11 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img11.src = '/asset/shirt-icon.png';
  dragImages['shirt-icon'] = img11;

  
  
  var img12 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img12.src = '/asset/water-icon.png';
  dragImages['water-icon'] = img12;

  
  
  var img13 = new Image(65, 65);
  // img4.width="200px";
  // img4.height="200px";
  img13.src = '/asset/microphone-icon.png';
  dragImages['microphone-icon'] = img13;

  

  const handleDragStart=(e)=> {
    // if(tool){
    //   endDraw()
    //   setTool(null)
    // }
    // props.setElementDrawing(true);
      var images = document.querySelectorAll('.drag-element');
      [].forEach.call(images, function (img) {
          img.classList.remove('img_dragging');
      });
      e.target.classList.add('img_dragging');
      
      var imageOffsetX, imageOffsetY;
      // var imageOffset = $(this).offset();
      imageOffsetX = e.clientX - e.target.offsetLeft;
      imageOffsetY = e.clientY - e.target.offsetTop;
      draggingTarget = e.target.id;
      draggingClass = e.target.className;
      console.log("draggingTarget", draggingTarget);
      var img
      
      img = dragImages[draggingTarget];
      img.width = 65
      img.height = 65
      if(img)
        e.dataTransfer.setDragImage(img, 0, 0);

      // props.setDraggingTarget(draggingTarget)
  }
  
  const handleDragEnd=(e)=> {
    var images = document.querySelectorAll('.drag-element');
      [].forEach.call(images, function (img) {
          img.classList.remove('img_dragging');
      });
  }
  const handleDropOnCanvas=(opt)=> {
    console.log("drop", opt)
    var mainCanvas = props.main.mainCanvas
    console.log("mainCanvas", mainCanvas)
    var main_viewportTransform = mainCanvas.viewportTransform;
    if(!draggingTarget) return
    var imgId =draggingTarget;
    // var offset = $(canvasObject).offset();
    var x = (opt.e.offsetX - main_viewportTransform[4])/main_viewportTransform[0];
    var y = (opt.e.offsetY - main_viewportTransform[5])/main_viewportTransform[3];
  
    if(mainCanvas.snapToGrid){
      var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
      var realMapWidth;
      var realMapHeight;
      var realGrid;
      var realOffsetX;
      var realOffsetY;
      var mapWidth = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
      var mapHeight = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
      if(convertRateX){
        realMapWidth = mapWidth
        realMapHeight = mapHeight
        realGrid = unit2Px(mainCanvas.gridSize?mainCanvas.gridSize:0, convertRateX, mainCanvas.mapUnit)
        realOffsetX = mainCanvas.gridOffsetX?((mainCanvas.gridOffsetX % realGrid )-realGrid):50
        realOffsetY = mainCanvas.gridOffsetY?((mainCanvas.gridOffsetY % realGrid )-realGrid):50
        realOffsetX+= unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
        realOffsetY+= unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
      }

      var angle = (mainCanvas.gridAngle?mainCanvas.gridAngle:0)*1;
      var canvasCenter = new fabric.Point(50, 50);
      var objectOrigin = new fabric.Point(x, y);
      var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(-1))
      var left = Math.round((new_loc.x-realOffsetX) / realGrid) * realGrid+realOffsetX;
      var top = Math.round((new_loc.y-realOffsetY) / realGrid) * realGrid+realOffsetY;

      objectOrigin = new fabric.Point(left, top);
      new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(1))
      x = new_loc.x
      y = new_loc.y
    } 

    var convertRateX= 1
    var convertRateY= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }

    var boothId = "G_"+parseInt(Math.random()*(10000))

    if(imgId){
     var width
      

      var group = new fabric.Group().set({
        left: x,
        top: y,
        id: 'symbol-'+boothId,
        class: 'symbol',
        class1: imgId,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        layer:'annotation',
      });	
      var table_path = "";
      if(imgId){
        table_path = `/asset/${imgId}.svg`
        if(convertRateX){
          width = (120/convertRateX) //48inch
        }
      }
      
      var fabricpath = fabric.Image.fromURL(table_path,function(obj) {
        // var obj = fabric.util.groupSVGElements(objects, options);
        // obj.set({
        //   fill: 'transparent', 
        //   strokeWidth:3/convertRateX,
        //   stroke:'black',
        //   class:'path-group'
        // })
        // obj.scaleToHeight(mainCanvas.height-10)
            // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
        
        obj.scale(width/obj.width)
        obj.set({
            left:-obj.width*width/obj.width/2,
            top: -obj.height*width/obj.width/2,
        })
        .setCoords();
        var boothId = "G_"+parseInt(Math.random()*(10000))
        group.set({
          left: x,
          top: y,
          width: width,
          height:width,
          borderColor: '#ff7777',
          cornerColor: 'red',
          cornerSize: 6,
          transparentCorners: false,
          angle: mainCanvas.gridAngle*1
      });
        group.add(obj)
        
        addTableText(mainCanvas, group, '')
        group.new_created = true;
        group.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
        group.KEY_ID = uuidv4()
        mainCanvas.add(group).renderAll();
        props.setBoothHistory({boothList:props.main.boothList, content: (props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[group]}})
      });
    }

    return false;
  }

  useEffect(() => {
    g_boothPrefix = props.element.boothPrefix;
    g_boothStartNumber = props.element.boothStartNumber;
    g_boothSuffix = props.element.boothSuffix;
    g_boothCnt = props.element.boothCnt;
    g_boothOrder = props.element.boothOrder;
    g_boothSeqMode = props.element.boothSeqMode;
    g_boothNumberDuplicated = boothNumberDuplicated;
  }, [props.element.boothPrefix, props.element.boothStartNumber, props.element.boothSuffix, props.element.boothCnt, props.element.boothOrder, props.element.boothSeqMode, boothNumberDuplicated])


  
  useEffect(() => {
    if(!open){
      if(_sketch){
        _sketch.reset();
        props.setCanvasRefresh(true); setTool(null)
      }

    }
    if(open && props.main.mainCanvas){
      var images = document.querySelectorAll('.drag-element');
      [].forEach.call(images, function (img) {
          img.addEventListener('dragstart', handleDragStart, false);
          img.addEventListener('dragend', handleDragEnd, false);
      });
      props.main.mainCanvas.off('drop')            
      props.main.mainCanvas.on('drop', function(opt) {
            handleDropOnCanvas(opt)
      });  

      g_boothPrefix = props.element.boothPrefix;
      g_boothStartNumber = props.element.boothStartNumber;
      g_boothSuffix = props.element.boothSuffix;
      g_boothCnt = props.element.boothCnt;
      g_boothOrder = props.element.boothOrder;
      g_boothSeqMode = props.element.boothSeqMode;
    }
  }, [open])

  useEffect(() => {
    if(open && props.main.mainCanvas){
      var images = document.querySelectorAll('.drag-element');
      [].forEach.call(images, function (img) {
          img.addEventListener('dragstart', handleDragStart, false);
          img.addEventListener('dragend', handleDragEnd, false);
      });
      props.main.mainCanvas.off('drop')            
      props.main.mainCanvas.on('drop', function(opt) {
            handleDropOnCanvas(opt)
      });  
    }
  }, [tool])

  useEffect(() => {
    if(open && props.main.mainCanvas){      
      props.main.mainCanvas.off('drop')            
      props.main.mainCanvas.on('drop', function(opt) {
            handleDropOnCanvas(opt)
      });  
    }
  }, [props.main.boothList])
  
  useEffect(() => {
    if(props.main.topDrawing){  
      // setTool(null)   
      // endDraw();
    }
  }, [props.main.topDrawing])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const setActiveObject = (id) => {
    var mainCanvas = props.main.mainCanvas;
    mainCanvas.getObjects().forEach(function(o) {
      if(o.id == "booth-"+id) {
        mainCanvas.setActiveObject(o).renderAll();;
      }      
    })
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const toggleWall=()=>{
    if(tool && tool != Tools.Wall){
      endDraw()
      setTool(null);
      return
    }
    if(tool == Tools.Wall){
      _sketch.endDraw()
      _sketch.resetMouse()
      props.setCanvasRefresh(true)
    }
    else{      
      props.setElementDrawing(true);
    }
    setTool(tool!=Tools.Wall?Tools.Wall:null)
  }
  const toggleBooth=()=>{
    if(tool && tool != Tools.Booth){
      endDraw()
      setTool(null);
      return
    }
    if(tool == Tools.Booth){
      _sketch.endDraw()
      _sketch.resetMouse()
      props.setCanvasRefresh(true)
    }
    else{      
      props.setElementDrawing(true);
    }
    setTool(tool!=Tools.Booth?Tools.Booth:null)
  }
  const toggleAisle=()=>{
    if(tool && tool != Tools.Aisle){
      endDraw()
      setTool(null);
      return
    }
    if(tool == Tools.Aisle){
      _sketch.endDraw()
      _sketch.resetMouse()
      props.setCanvasRefresh(true)
    }
    else{      
      props.setElementDrawing(true);
    }
    setTool(tool!=Tools.Aisle?Tools.Aisle:null)
  }

  const endDraw=()=>{
    if(tool == Tools.Aisle || tool == Tools.Wall || tool == Tools.Booth){
      _sketch.endDraw()
      _sketch.resetMouse()
      props.setCanvasRefresh(true)
    }
  }

  
  const onDrawDone = (object)=>{
    var mainCanvas = props.main.mainCanvas
    if(object){
      mainCanvas.setActiveObject(object)
      
      // var newSelectObj = new fabric.ActiveSelection([object], {canvas:mainCanvas});
      // mainCanvas.setActiveObject(newSelectObj);

      setTool(null)
  
      var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
      var realMapWidth;
      var realMapHeight;
      var realGrid;
      var realOffsetX;
      var realOffsetY;
      var mapWidth = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
      var mapHeight = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
      if(convertRateX){
        realMapWidth = mapWidth
        realMapHeight = mapHeight
        realGrid = unit2Px(mainCanvas.gridSize?mainCanvas.gridSize:0, convertRateX, mainCanvas.mapUnit)

        realOffsetX = mainCanvas.gridOffsetX?((mainCanvas.gridOffsetX % realGrid )-realGrid):50
        realOffsetY = mainCanvas.gridOffsetY?((mainCanvas.gridOffsetY % realGrid )-realGrid):50
      }
  
  
      if(mainCanvas.snapToGrid){
          var angle = object.get('angle');
          var gridAngle = mainCanvas.gridAngle?mainCanvas.gridAngle:0
          if(gridAngle != angle) return;
          var canvasCenter = new fabric.Point(mainCanvas.getWidth()/2, mainCanvas.getHeight()/2);
          var objectOrigin = new fabric.Point(object.left, object.top);
          var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(-1))
          var left = Math.round((new_loc.x-realOffsetX) / realGrid) * realGrid+realOffsetX;
          var top = Math.round((new_loc.y-realOffsetY) / realGrid) * realGrid+realOffsetY;
  
          objectOrigin = new fabric.Point(left, top);
          new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(1))
  
          object.set({
            left: new_loc.x,
            top: new_loc.y
          });
      }
    }
  }
  var mapUnit = 'feet';
  if(mainCanvas){
    mapUnit = mainCanvas.mapUnit
  }
  
  return (
    <>
      <Draggable
        handle=".handle-main"
        defaultPosition={{x: 0, y: 0}}
        position={null}
        grid={[2, 2]}
        scale={1}
        bounds="body"
      >
        <div className="legend-option" style={{width: '310px'}}>
          <Box display="flex" style={{justifyContent:'space-between', marginBottom: 10}}>
            <DragIndicatorIcon fontSize="small" style={{cursor:'move', width:20}} className="handle-main"/>
            <CloseIcon fontSize="small" style={{cursor:'pointer'}} onClick={props.onClose}/>
          </Box>
          <Box sx={{ marginTop: '5px', marginLeft: '10px', marginRight: '10px' }}>
            <Grid container rowSpacing={0.5} columnSpacing={{ xs: 0.5, sm: 0.5, md: 0.5 }}>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="toilet-location-icon" draggable="true" className="booth drag-element" src="/asset/toilet-location-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3}  sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="food-location-icon" draggable="true" className="booth drag-element" src="/asset/food-location-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3}  sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="female-toilet-location-icon" draggable="true" className="booth drag-element" src="/asset/female-toilet-location-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="food-restaurant-icon" draggable="true" className="booth drag-element" src="/asset/food-restaurant-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="arrow-round-right-black-icon" draggable="true" className="booth drag-element" src="/asset/arrow-round-right-black-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="coffee-icon" draggable="true" className="booth drag-element" src="/asset/coffee-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="glass-martini-icon" draggable="true" className="booth drag-element" src="/asset/glass-martini-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="camera-icon" draggable="true" className="booth drag-element" src="/asset/camera-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="charging-station-icon" draggable="true" className="booth drag-element" src="/asset/charging-station-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="star-sharp-icon" draggable="true" className="booth drag-element" src="/asset/star-sharp-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="shirt-icon" draggable="true" className="booth drag-element" src="/asset/shirt-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="water-icon" draggable="true" className="booth drag-element" src="/asset/water-icon.png"/></DragButton>
                </div>
              </Grid>
              <Grid item xs={3} sx={{textAlign:'left'}}>
                <div style={{width:'65px', border:'1px solid #e0e0e0', borderRadius: '5px'}}>
                  <DragButton sx={{width:'100%', justifyContent:'start'}}><img width="100%" id="microphone-icon" draggable="true" className="booth drag-element" src="/asset/microphone-icon.png"/></DragButton>
                </div>
              </Grid>
            </Grid>
          </Box>
          {Boolean(props.main.mainCanvas) && open &&
            <SketchField             
              ref={(c) => setSketch(c)}
              canvas={props.main.mainCanvas}
              tool={tool} 
              lineWidth={1}
              main={props.main}
              setBoothList={props.setBoothList}
              setBoothHistory={props.setBoothHistory}
              onDrawDone = {onDrawDone}
            />
          }
        </div>
      </Draggable>
      <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            message={snackMsg}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    element: state.element
  };
};

const mapDispatchProps = (dispatch) => {
  return { 
    setDraggingTarget:  data=>dispatch(setDraggingTarget(data)),   
    setBoothHistory: data=>dispatch(setBoothHistory(data)),
    setBoothList: data=>dispatch(setBoothList(data)),
    setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),
    setElementDrawing: data=>dispatch(setElementDrawing(data)),
    setBoothPrefix: data=>dispatch(setBoothPrefix(data)),
    setBoothStartNumber: data=>dispatch(setBoothStartNumber(data)),
    setBoothSuffix: data=>dispatch(setBoothSuffix(data)),
    setBoothCnt: data=>dispatch(setBoothCnt(data)),
    setBoothOrder: data=>dispatch(setBoothOrder(data)),
    setBoothSeqMode: data=>dispatch(setBoothSeqMode(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(ElementBox);
