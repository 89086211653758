import * as React from 'react';
import LZString from 'lz-string';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setLoading, setRemoveObjects, setFirstMapContent } from '../../actions/main'
import { setLog, addLog } from '../../actions/log'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { countries, API_URL } from '../../config.js'
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, setObjectBG, compressJson, checkIfSelectable, px2unit, unit2Px} from '../../utils/CanvasTool'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import qs, { parse } from 'qs'
import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas'
import { setCart } from '../../actions/cart'
import { parseToken, buildObjectProperty, containsObject, convertRgbHex } from '../../utils/Common'
import {PaperComponent} from '../../utils/Common'

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
const emails = ['username@gmail.com', 'user02@gmail.com'];
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AttachModal(props) {
  let query = useQuery();
  const { onClose, onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [sales_form, setSalesForm] = React.useState('');
  const [step, setStep] = React.useState(1);
  const [autoCombine, setAutoCombine] = React.useState(false)
  const [company, setCompany] = React.useState(null);
  const [contact, setContact] = React.useState(null);
  const [companies, setCompanies] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [saleforms, setSaleforms] = React.useState([]);
  const [saleitems, setSaleitems] = React.useState([]);
  const [boothSaleItems, setBoothSaleItems] = React.useState([]);
  const [signedPdfName, setSignedPdfName] = React.useState(null);
  var sigCanvas;
  const filter = createFilterOptions();


  // const companies = [
  //   { label: 'The Shawshank Redemption', year: 1994 },
  //   { label: 'The Godfather', year: 1972 },
  // ];
  const handleChangeSalesForm = (event) => {
    setSalesForm(event.target.value);
  };

  useEffect(() => {
    setStep(1);
    setAutoCombine(false)
  }, [open])

  const updateBoothSaleItems = (booths)=>{
    var newBoothSaleItems = [];
    if(booths){
      for(var i = 0; i< booths.length; i++){
        newBoothSaleItems.push(
          [
            {
              saleitem: null,
              saleitem_name: null,
              saleitem_price: null,
              booth: booths[i]
            }
          ]
        )
      }
      setBoothSaleItems(newBoothSaleItems);
    }
  }
  useEffect(() => {
    if(props.boothTexts){
      updateBoothSaleItems(props.boothTexts)
    }
  }, [props.boothTexts])
  
  const onAddSignature= async (e)=>{
    var contract_pdf;
    var active_booth_file = props.main.active_booth_file
    if(active_booth_file){
      contract_pdf = active_booth_file.contract_pdf
    }

    if(contract_pdf){
      var filename = contract_pdf
      //     // const reader = new FileReader();
      // var data = file.slice(0, file.size);
      var data = ""
      if(sigCanvas){
        data = JSON.stringify(sigCanvas.getTrimmedCanvas().toDataURL('image/png'))
      }
      // var url = API_URL()+`addsignature?ax-file-path=/content/contract/&ax-file-name=${filename}&&start=0`
      // const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      // const response = await axios.post(url, data, headers);
      // if(response.status == 200){
      //   setSignedPdfName(response.data)
      //   // setContracPdfName(filename);
      // }

      var url = API_URL()+`addsignature?ax-file-path=/content/contract/&ax-file-name=${filename}&&start=0`
      var formdata = 
      {
          content:data,
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      axios.post(url, data, options).then(res=>{
          const result = res.data;
          setSignedPdfName(result)
      }).catch(error => {
          console.log("error", error);
      })

    }
  }

  const insertBoothSaleInfo = (boothIndex, rowIndex)=>{ 
    var booths = props.boothTexts
    var emptyrow = {
      saleitem: null,
      saleitem_name: null,
      saleitem_price: null,
      booth: booths[boothIndex]
    }   
    var newBoothSaleItems = [...boothSaleItems];
    var rows = [...newBoothSaleItems[boothIndex]]
    rows = [...rows.slice(0, rowIndex), emptyrow, ...rows.slice(rowIndex)]
    newBoothSaleItems[boothIndex] = rows;
    setBoothSaleItems(newBoothSaleItems);
  }
  const removeBoothSaleInfo = (boothIndex, rowIndex)=>{
    var newBoothSaleItems = [...boothSaleItems];
    var rows = [...newBoothSaleItems[boothIndex]]
    rows = [...rows.slice(0, rowIndex), ...rows.slice(rowIndex+1)]
    newBoothSaleItems[boothIndex] = rows;
    setBoothSaleItems(newBoothSaleItems);
  }

  const updateBoothSaleInfo = (boothIndex, rowIndex, key, value)=>{    
    var newBoothSaleItems = [...boothSaleItems];
    var rows = [...newBoothSaleItems[boothIndex]]
    rows[rowIndex]= {...rows[rowIndex], [key]:value} ;
    if(key == "saleitem" && value){
      for(var i = 0; i < saleitems.length; i++){
        if(saleitems[i]._id == value._id){
          rows[rowIndex]= {...rows[rowIndex], saleitem_price:saleitems[i].price} ;
        }
      }
    }
    newBoothSaleItems[boothIndex] = rows;
    setBoothSaleItems(newBoothSaleItems);
  }

  

  useEffect(() => {
    if(step == 1){
      fetchCompanies();
    }
    else if(step == 2){
      fetchContacts();
    }
  }, [step])
  
  const fetchSaleforms = ()=>{
    axios.get('/getsaleforms').then(res=>{
      if(res.data)
        setSaleforms(res.data);
        if(res.data.length == 1){
          setSalesForm(res.data[0]._id)
        }
    }).catch(error => {
        console.log("error", error);
    })
  }

  const fetchSaleitems = ()=>{
    axios.get('/getsaleitems').then(res=>{
      if(res.data){
        console.log(res.data)
        setSaleitems(res.data);
      }
    }).catch(error => {
        console.log("error", error);
    })
  }
  
  const fetchCompanies = ()=>{
    var boothFileList = props.main.boothFileList;
    
    
    axios.get(`/getcompanies?booth_id=${query.get("map")}`).then(res=>{
      if(res.data){
        var newValues = [];
        for(var i = 0; i< res.data.length; i++){
          var item = res.data[i]
          var saledBooths = null
          if(item['saledBooths'] && item['saledBooths'] !=""){
            saledBooths = item['saledBooths'];
          }
          newValues.push(
            { ...item, label: (item['name']?item['name']:'')+(saledBooths?` (Booth: ${saledBooths})`:'')+" "+(item['address1']?item['address1']:'')+" "+(item['address2']?item['address2']:"")+" "+(item['city']?item['city']:'')+" "+(item['zip']?item['zip']:'')+" "+(item['state']?item['state']:'')+" "+(item['code']?item['code']:'')+" "+(item['country']?item['country']:'') },
          )
        }
        setCompanies(newValues);
      }
    }).catch(error => {
        console.log("error", error);
    })
  }
  
  const fetchContacts = ()=>{
    if(company && company._id){
      axios.get(`/getcontacts?comany_id=${company._id}`).then(res=>{
        if(res.data){
          var newValues = [];
          for(var i = 0; i< res.data.length; i++){
            var item = res.data[i]
            newValues.push(
              { ...item, label: (item['name']?item['name']:'')+" "+(item['address1']?item['address1']:'')+" "+(item['address2']?item['address2']:"")+" "+(item['city']?item['city']:'')+" "+(item['zip']?item['zip']:'')+" "+(item['state']?item['state']:'')+" "+(item['code']?item['code']:'')+" "+(item['country']?item['country']:'') },
            )
          }
          setContacts(newValues);
        }
      }).catch(error => {
          console.log("error", error);
      })
    }
    else{      
      setContacts([]);
      setContact({
        'name': "",
        'address1':"",
        'address2':"",
        'city':"",
        'state':"",
        'zip':"",
        'code':"",
        'country':null
      })
    }
  }

  const setCompanyValue = (key, value)=>{
    var newCompany;
    if(company)
      newCompany = {...company, [key]:value}
    else
      newCompany = {[key]:value}
    setCompany(newCompany)
  }

  const setContactValue = (key, value)=>{
    var newContact;
    if(contact)
    newContact = {...contact, [key]:value}
    else
    newContact = {[key]:value}
    setContact(newContact)
  }


  const getCountryObject = (code)=>{
    for(var i=0; i<countries.length; i++){
      if(countries[i].code == code){
        return countries[i];
      }
    }
    return null;
  }
  
  const onChangeName = (e)=>{
    onChange(e)
  }

  const onOk = ()=>{
    
  }

  const onSaveBooth = ()=>{        
    {
      var mainCanvas = props.main.mainCanvas
      var booths = props.booths
      var groupOptions = props.groupOptions
      var boothNumbers = "";
      for(var i = 0; i < booths.length; i++){
        var group = booths[i]
        if(boothNumbers == ""){
          boothNumbers = group.booth_number
        }
        else{
          boothNumbers += ("," + group.booth_number)
        }
        group.set('sold', 1)
        var groupedId = group.get('id');
        groupedId = groupedId.replace("booth-","");
        if(signedPdfName){
          group.set({signed_pdf: signedPdfName})
        }
        group.set({company: company?.name});
        group.set({modified: true});
        mainCanvas.renderAll()
        var textObjects = group.getObjects('text');
        for(var txtInd = 0; txtInd< textObjects.length; txtInd++){
            if(textObjects[txtInd].get('class') == 'boothName'){
                textObjects[txtInd].set('text', (company?.name?(company?.name+"\n"):"")+textObjects[txtInd].get('text'));
                break;
            }
        }

        var boothGroup = group.boothGroup;
        var exhibitors = group.exhibitors
        var bgColor = "#dadada"
        for(var k = 0; k < groupOptions.length; k++){
            if(groupOptions[k].KEY_ID == boothGroup){
                var groupOption = groupOptions[k]
                  bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
            }
        }
        setObjectBG(mainCanvas, group, bgColor)
        mainCanvas.renderAll()
      }

      if(mainCanvas){
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
        var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
          const boothJson = (mainCanvas.toDatalessObject());
          var mainContent = {...boothJson};
          var newObjects = [];
          var updateObjects = [];
          // var removeObjects = props.main.removeObjects;
          var removeObjects = [];
          var objects = boothJson.objects
          var firstObjects = props.main.firstMapContent&&props.main.firstMapContent.objects?props.main.firstMapContent.objects:[]
          for(var i = 0; i < objects.length; i++){
            if(checkIfSelectable(objects[i])){
              if(!containsObject(objects[i], firstObjects, 'uniq_id')){
                  objects[i].left -=mapOffsetX;
                  objects[i].top -=mapOffsetY;
                  newObjects.push(objects[i])
              }
            }
          }
          for(var i = 0; i < firstObjects.length; i++){
            if(checkIfSelectable(objects[i])){
              if(!containsObject(firstObjects[i], objects, 'uniq_id')){
                  removeObjects.push(firstObjects[i])
              }
            }
          }
          for(var i = 0; i < objects.length; i++){
              // zIndexArray.push({uniq_id: objects[i].uniq_id, zIndex: i});
              if(checkIfSelectable(objects[i])){
                if(objects[i].new_created){
                    objects[i].new_created = false;
                    // updateObjects.push(objects[i])
                }
                else if(objects[i].modified){
                    objects[i].modified = false;
                    objects[i].zIndex = i;
                    objects[i].left -=mapOffsetX;
                    objects[i].top -=mapOffsetY;
                    updateObjects.push(objects[i])
                }
              }
          }
          mainCanvas.renderAll()
          mainContent.objects = [];
          objects = mainCanvas.getObjects();   
          for(var i = 0; i < objects.length; i++){
              // zIndexArray.push({uniq_id: objects[i].uniq_id, zIndex: i});
              if(objects[i].new_created){
                  objects[i].new_created = false;
                  // updateObjects.push(objects[i])
              }
              else if(objects[i].modified){
                  objects[i].modified = false;
                  objects[i].zIndex = i;
              }
          }
          mainCanvas.renderAll()
          console.log("newObjects", newObjects)
          console.log("updateObjects", updateObjects)
          console.log("removeObjects", removeObjects)
          // return;
    

          var url = `/updateboothcontent`
          var formdata = 
          {
              id:props.mapId,
              mainContent: JSON.stringify(mainContent),
              removeObjects:LZString.compressToBase64(JSON.stringify(removeObjects)),
              newObjects:LZString.compressToBase64(JSON.stringify(newObjects)),
              updateObjects:LZString.compressToBase64(JSON.stringify(updateObjects)),
          }
          var data = qs.stringify({
              request: JSON.stringify(formdata)
          });
          const options = {
              headers: {"content-type": 'application/x-www-form-urlencoded'}
          }
          props.setFirstMapContent(mainCanvas.toDatalessObject())   
          axios.post(url, data, options).then(res=>{
              const result = res.data;
              if(result.status == "success"){
                  props.setLoading(false)
                  props.addLog({
                    action: 'buy',
                    date: new Date().toLocaleString(),
                    description: "bought "+boothNumbers,
                    user: "User"
                })
              }
              else{
                  props.setLoading(false)
              }
          }).catch(error => {
              console.log("error", error);
          })
      }
      onClose()
      props.setCart([]);
    }
  }

  const onBuy=()=>{
    onSaveBooth()
  }

  var booths = props.boothTexts;
  var pricingOptions = props.pricingOptions
  var premiumOptions = props.premiumOptions
  var discountOptions = props.discountOptions
  //console.log("rerendering")
  var contract_pdf;
  var active_booth_file = props.main.active_booth_file
  if(active_booth_file){
    contract_pdf = active_booth_file.contract_pdf
  }
  var filteredBoothList = props.booths
  var total = 0;
  var pricingOptionsTmp = [];
  var premiumOptionsTmp = [];
  var discountOptionsTmp = [];
  console.log("filteredBoothList", filteredBoothList)
  for(var i = 0; i < filteredBoothList.length; i++){
    var booth = filteredBoothList[i]
    for(var j = 0; j < pricingOptions.length; j++){
      var row = pricingOptions[j]
      if(row._id && booth.priceCheck && booth.priceCheck.includes(row._id)){
        total+=row.price*1
        var searched = false;
        for(var k = 0; k < pricingOptionsTmp.length; k++){
          if(pricingOptionsTmp[k]._id == row._id){
            pricingOptionsTmp[k].qty+=1;
            searched = true
            break
          }
        }
        if(searched){

        }
        else{
          row['qty'] = 1;
          pricingOptionsTmp.push(row)
        }
      }
    }
    for(var j = 0; j < premiumOptions.length; j++){
      var row = premiumOptions[j]
      if(row._id && booth.priceCheck && booth.priceCheck.includes(row._id)){
        total+=row.price*1
        var searched = false;
        for(var k = 0; k < premiumOptionsTmp.length; k++){
          if(premiumOptions[k]._id == row._id){
            premiumOptionsTmp[k].qty+=1;
            searched = true
            break
          }
        }
        if(searched){

        }
        else{
          row['qty'] = 1;
          premiumOptionsTmp.push(row)
        }
      }
    }
    for(var j = 0; j < discountOptions.length; j++){
      var row = discountOptions[j]
      if(row._id && booth.discountCheck && booth.discountCheck.includes(row._id)){
        total+=row.price*1
        var searched = false;
        for(var k = 0; k < discountOptionsTmp.length; k++){
          if(discountOptions[k]._id == row._id){
            discountOptionsTmp[k].qty+=1;
            searched = true
            break
          }
        }
        if(searched){

        }
        else{
          row['qty'] = 1;
          discountOptionsTmp.push(row)
        }
      }
    }
  }
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'buycart-dialog-title'}}
      aria-labelledby="buycart-dialog-title"
      fullWidth={true}
      maxWidth={"md"}
      onClose={onClose} open={open}>
      <DialogTitle id='buycart-dialog-title' style={{display:'flex', alignItems:'center'}}><ShoppingCartIcon style={{marginRight: 10}}/> 
        {
          props.reserved?
          <>Reserve</>:
          <>Buy</>
        }
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        {(step == 1) &&
        <Box mt={2}>
            <Autocomplete
              disablePortal
              options={companies}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Company" />}
              value={(company&&company._id)?company:null}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  // (setCompanyValue('name',newValue))
                } else if (newValue && newValue.inputValue) {
                  setCompany({
                    'name': newValue.inputValue,
                    'address1':"",
                    'address2':"",
                    'city':"",
                    'state':"",
                    'zip':"",
                    'code':"",
                    'country':null
                  });
                }
                else{
                  setCompany(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    label: `Create New Company`,
                  });
                }
        
                return filtered;
              }}
            />
          <Grid container spacing={2}>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Company Name"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.name:""}
                onChange={(e)=>(setCompanyValue('name', e.target.value))}
                InputLabelProps = {{shrink: true}}
              />
            </Grid>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Address1"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.address1:""}
                onChange={(e)=>(setCompanyValue('address1', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Address2"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.address2:""}
                onChange={(e)=>(setCompanyValue('address2', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="City"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.city:""}
                onChange={(e)=>(setCompanyValue('city', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="State"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.state:""}
                onChange={(e)=>(setCompanyValue('state', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="Zip"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.zip:""}
                onChange={(e)=>(setCompanyValue('zip', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="Code"
                type="name"
                fullWidth
                variant="standard"
                value = {company?company.code:""}
                onChange={(e)=>(setCompanyValue('code', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={8}> 
              <Autocomplete
                sx={{ width: '100%' }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label} ({option.code})
                  </Box>
                )}
                value = {company?getCountryObject(company.country):null}
                onChange={(event, newValue)=>(setCompanyValue('country', newValue.code))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Choose a country"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        }
        {(step == 2) &&
        <Box mt={2}>
          <FormGroup>
            <Autocomplete
              disablePortal
              options={contacts}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Contact" />}
              value={(contact&&contact._id)?contact:null}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  // (setCompanyValue('name',newValue))
                } else if (newValue && newValue.inputValue) {
                  setContact({
                    'name': newValue.inputValue,
                    'address1':"",
                    'address2':"",
                    'city':"",
                    'state':"",
                    'zip':"",
                    'code':"",
                    'country':null
                  });
                }
                else{
                  setContact(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    label: `Create New Contact`,
                  });
                }
        
                return filtered;
              }}
            />
          </FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Contact Name"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.name:""}
                onChange={(e)=>(setContactValue('name', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Address1"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.address1:""}
                onChange={(e)=>(setContactValue('address1', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={12}> 
              <TextField
                name="filename"
                margin="dense"
                label="Address2"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.address2:""}
                onChange={(e)=>(setContactValue('address2', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="City"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.city:""}
                onChange={(e)=>(setContactValue('city', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="State"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.state:""}
                onChange={(e)=>(setContactValue('state', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="Zip"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.zip:""}
                onChange={(e)=>(setContactValue('zip', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4}> 
              <TextField
                name="filename"
                margin="dense"
                label="Code"
                type="name"
                fullWidth
                variant="standard"
                value = {contact?contact.code:""}
                onChange={(e)=>(setContactValue('code', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={8}> 
              <Autocomplete
                sx={{ width: '100%' }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label} ({option.code})
                  </Box>
                )}
                value = {contact?getCountryObject(contact.country):null}
                onChange={(event, newValue)=>(setContactValue('country', newValue.code))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Choose a country"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        }
        
        {(step == 3) &&
        <Box mt={2}>
          {filteredBoothList&&
          <>
            <Table className="pricing-table" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{backgroundColor: 'white',  color: '#4E7090'}}>Title</TableCell>
                    <TableCell style={{backgroundColor: 'white',  color: '#4E7090'}}>Price</TableCell>
                    <TableCell style={{backgroundColor: 'white',  color: '#4E7090'}}>Qty</TableCell>
                    <TableCell style={{backgroundColor: 'white',  color: '#4E7090'}}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>                  
                    {pricingOptionsTmp.map((row, index) => {
                      return(
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>${(row.price*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                          <TableCell>{row.qty}</TableCell>
                          <TableCell>${(row.price*row.qty).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        </TableRow>
                      )
                    })}
                    
                    {premiumOptionsTmp.map((row, index) => {
                      return(
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>${(row.price*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                          <TableCell>{row.qty}</TableCell>
                          <TableCell>${(row.price*row.qty).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        </TableRow>
                      )
                    })}

                    {discountOptionsTmp.map((row, index) => {
                      return(
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>(${(row.price*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</TableCell>
                          <TableCell>{row.qty}</TableCell>
                          <TableCell>(${(row.price*row.qty).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
            </Table>
            <p style={{textAlign:'right', paddingRight:'58px'}}><strong>Total:</strong> ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
          
          </>
          }
        </Box>
        }
        {(step == 4) &&
        <Box mt={2}>
          <Box mb={2}>
            <Box display="flex" style={{alignItems:'center'}}>
              <h4 style={{marginRight:10}}>
                Payment Option:
              </h4>
              <FormControl variant="standard" style={{width: 250}}>
                <Select
                  labelId="demo-simple-select-label"
                  // value={sales_form}
                  label="Sales Form"
                  // onChange={handleChangeSalesForm}
                >
                  <MenuItem value='credit'>Credit Card</MenuItem>
                  <MenuItem value='stripe'>Stripe</MenuItem>
                  <MenuItem value='paypal'>Paypal</MenuItem>
                </Select>
              </FormControl>
            </Box>
                  </Box>
        </Box>
        }
        {(step == 5) &&
        <Box mt={2}>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}> 
                <Box>
                  <h4>
                    Booth List:
                  </h4>
                  <List style={{paddingTop:0}}>
                    {
                      props.booths.map((booth, index)=>{
                        return(
                          <ListItem key = {index} disablePadding>
                            {/* <ListItemButton> */}
                              <ListItemText primary={"Booth: "+booth.booth_number} />
                            {/* </ListItemButton> */}
                          </ListItem>
                        )
                      })
                    }
                  </List>
                </Box>
                <Box>
                  <h4>
                    Company:
                  </h4>
                  {company?company.name:""}<br/>
                  {company?company.address1:""}<br/>
                  {company?company.address2:""}<br/>
                  {company?company.city:""} {company?company.state:""} {company?company.zip:""}
                </Box>
                
                <Box>
                  <h4>
                    Contact:
                  </h4>
                  {contact?contact.name:""}<br/>
                  {contact?contact.address1:""}<br/>
                  {contact?contact.address2:""}<br/>
                  {contact?contact.city:""} {contact?contact.state:""} {contact?contact.zip:""}
                </Box>
                
                {contract_pdf && 
                    <>
                      <Box>
                        <h4>
                          Signature:
                        </h4>
                        <div style={{border: '1px solid rgb(201 201 201)', width: '300px'}}>
                          <SignatureCanvas penColor='black'      
                            ref={(ref) => { sigCanvas = ref }}            
                            canvasProps={{width: 300, height: 100, className: 'sigCanvas'}} />
                        </div>
                        
                        <Box mt={2}>
                          <Button mt={2} variant="contained" onClick={onAddSignature}>
                            <>Add Signature</>                    
                          </Button>
                        </Box>
                      </Box>
                      
                    </>
                  }     
              </Grid>
              <Grid item xs={6}> 
                  {(contract_pdf || signedPdfName)   &&
                    <>
                      
                      <Box mt={2}>
                        
                        <iframe id="3566425" class="card" src={"https://docs.google.com/gview?embedded=true&url="+API_URL()+"/content/contract/"+(signedPdfName?("signature/"+signedPdfName):contract_pdf)} width='100%' height="500px">
                        </iframe>
                        {/* <iframe id="3566425" class="card" src={API_URL()+"/content/contract/"+contract_pdf}>
                        </iframe> */}
                      </Box>
                    </>
                  }     
              </Grid>
            </Grid>

          </Box>
        </Box>
        }
      </DialogContent>
      <DialogActions> 
        {step >1 && 
          <Button onClick={()=>{
             setStep(step-1)
           }
         }>Before</Button>
        }
        {step < 5 ?
          <Button onClick={()=>{
              setStep(step+1)
            }
          }>Next</Button>:
          <Button variant="contained" onClick={onBuy}>
            {
              <>Buy Now</>
            }
            
          </Button>
        }
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers))     ,
        setLoading:  data=>dispatch(setLoading(data)),
        setCart:  data=>dispatch(setCart(data)),
        setRemoveObjects:  data=>dispatch(setRemoveObjects(data)),
        addLog:  data=>dispatch(addLog(data)),
        setFirstMapContent:  data=>dispatch(setFirstMapContent(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(AttachModal);