import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import logo from './logo.svg';
import './App.css';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ToolBox from './viewer/ToolBox.js';
import { setAuthInfo, setLoading} from './actions/main.js'
import { setOpenCompanyProfile, setCustomStyles } from './actions/ui.js'
import { parseToken, getTokenCookie, setCookie,assetUrl } from './utils/Common.js'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import ShowMore from 'react-show-more';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile.js';
import { useParams } from 'react-router-dom'
import { API_URL, ESHOW_URL } from './config.js'
import ReactPlayer from 'react-player'
import FeaturedLabel from './viewer/component/FeaturedLabel.js';
import { makeStyles } from '@mui/styles';
import ReactReadMoreReadLess from "react-read-more-read-less";

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [page, setPage] = React.useState(1);
  const [keySelect, setKeySelect] = useState('')
  const [filteredItems, setFilteredItems] = useState([])
  const [searchOptions, setSearchOptions] = useState([]);
  const [pulldownValue, setPulldownValue] = useState(false);
  const [openReleaseDetails, setOpenReleaseDetails] = React.useState(false);
  const page_size = 24;
  const keywordInput = useRef(null);
  const selectRef = useRef(null);
  
  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const processAuth = async ()=>{  
    var tokenInfo = await parseToken(query)     
    await fetchCustomStyles();           
    var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
    var user_key = tokenInfo.user_key?tokenInfo.user_key:'';
    var BOOTH_PRICING = tokenInfo.BOOTH_PRICING?tokenInfo.BOOTH_PRICING:'';
    var SHOPPING_CART = tokenInfo.SHOPPING_CART?tokenInfo.SHOPPING_CART:'';
    var SALES = tokenInfo.SALES
    props.setAuthInfo({show_key, user_key, SALES,BOOTH_PRICING,SHOPPING_CART, authenticated: (tokenInfo.show_key && tokenInfo.user_key?3:2), tokenInfo})
    if(!(tokenInfo.show_key && tokenInfo.user_key)){ 
        return
    }
  }

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    console.log("fetchCustomStyles")
    var url  = `${ESHOW_URL()}?method=getCSS&token=${getTokenCookie()}`
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    }  
    const response = await axios.get(url, options)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){
                console.log("result.CSS", data.CSS)                    
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const openCompanyProfile=(exhibitor)=>{   
    var booth = {};
    booth.exhibitors = [{EXHIBITOR_KEY: exhibitor.EXHIBITOR_KEY}]
    booth.booth_number = exhibitor?.BOOTH_NO

    props.setOpenCompanyProfile({booth:booth, open:true})
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fafafa',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display:'block'
  }));

  const fetchData = ()=>{    
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    props.setLoading(true)
    var url = `${ESHOW_URL()}?method=getPressReleases` 
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    axios.get(url, options).then(res=>{
        const result = res.data;
        // if(result.SUCCESS){  
          if(result.PRESS){
            setData(result.PRESS)            
            setSearchOptions(result.SEARCH)
            if(result.SEARCH && result.SEARCH?.length){
              setKeySelect(result.SEARCH[0].LABEL)
            }
          }
        // }
        // else{
        //   setData([])
        // }
        setDataFetched(true)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {
    props.setOpenCompanyProfile(false)
    fetchData()
  }, [id5, id4, id3, id2, id1])

  useEffect(() => {
    if(data.length)
      doSearch()
  }, [data])

  
  useEffect(() => {
    if(data.length)
      doSearch()
  }, [keySelect, pulldownValue])
  
  const resetSearch=()=>{
    if(keywordInput?.current)
      keywordInput.current.value = ''
    if(selectRef?.current)
      selectRef.current.value = ''
    setFilteredItems(data)
  }
  const doSearch=(value)=>{
    var keyword = null
    let searchOption = getSearchOption(keySelect)
    if(value){
      keyword = value
    }
    else{
      if(searchOption && (searchOption.TYPE == 'pulldown' || searchOption.TYPE == 'checkbox')){
        keyword = pulldownValue
      }
      else{
        keyword = keywordInput?.current?.value
      }
    }
    if(!keyword) keyword = ''
    keyword = `${keyword}`
    var filteredItems = data;
    if(keyword == "" || keyword == '""' || !keyword){
      setFilteredItems(filteredItems)
      return;
    }
    if(keyword && keyword.trim() !== '') {
      filteredItems = data.filter((item)=>{
        var str = ''
        if(keySelect == "Description"){
          str = `${item.DESCRIPTION?item.DESCRIPTION:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Company"){
          str = `${item.COMPANY_NAME?item.COMPANY_NAME:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Product Categories"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }          
        else if(keySelect == "Booth"){
          str = `${item.BOOTH_NO?item.BOOTH_NO:''}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == "Map Name"){
          str = `${item.MAP_NAME?item.MAP_NAME:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "States"){
          str = `${item.STATE?item.STATE:''}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == "Countries"){
          str = `${item.COUNTRY?item.COUNTRY:'us'}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == 'Featured Exhibitors'){
          str = `${item.FEATURED?item.FEATURED:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Region of Services"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }     
        else if(keySelect == "Service Specialty"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }             
        else{
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }
      })      
    }
    setFilteredItems(filteredItems)
  }
  const getSearchOption = (value)=>{
    console.log("searchOptions", searchOptions)
    if (!value) return false;
    for(var i = 0; i < searchOptions?.length; i++){
      if(searchOptions[i].LABEL == value){
        return searchOptions[i]
      }
    }
    return false
  }

  const searchBox = ()=>{
    let searchOption = getSearchOption(keySelect)
    let dropdownOptions = [];
    if(searchOption && searchOption.TYPE == 'pulldown'){
      if(searchOption.ID == 'question' || searchOption.ID == 'product_category'){
        for(var i = 0; i < searchOption.ANSWERS?.length; i++){
          let title = searchOption.ANSWERS[i].answer_title
          if(title === true){
            title = "Yes"
          }
          if(title === false){
            title = "No"
          }
          dropdownOptions.push(
            {
              value:`${searchOption.ANSWERS[i].answer_key}`?.trim(),
              label:`${title}`?.trim()+(searchOption.ANSWERS[i].qty?(` (${searchOption.ANSWERS[i].qty})`):""),
            }
          )
        }
      }
      else if(searchOption.ID == 'map_name'){
        for(var i = 0; i < searchOption.MAPS?.length; i++){
          dropdownOptions.push(
            {
              value:`${searchOption.MAPS[i].TITLE}`?.trim(),
              label:`${searchOption.MAPS[i].TITLE}`?.trim(),
            }
          )
        }
      }
      else if(searchOption.ID == 'state'){
        for(var i = 0; i < searchOption.STATES?.length; i++){
          dropdownOptions.push(
            {
              value:`${searchOption.STATES[i].VALUE}`?.trim(),
              label:`${searchOption.STATES[i].TITLE}`?.trim()+(searchOption.STATES[i].QTY?(` (${searchOption.STATES[i].QTY})`):""),
            }
          )
        }
      }
      else if(searchOption.ID == 'booth'){
        for(var i = 0; i < searchOption.BOOTHS?.length; i++){
          dropdownOptions.push(
            {
              value:`${searchOption.BOOTHS[i].VALUE}`?.trim(),
              label:`${searchOption.BOOTHS[i].TITLE}`?.trim()+(searchOption.BOOTHS[i].QTY?(` (${searchOption.BOOTHS[i].QTY})`):""),
            }
          )
        }
      }
      else if(searchOption.ID == 'country'){
        for(var i = 0; i < searchOption.COUNTRIES?.length; i++){
          dropdownOptions.push(
            {
              value:`${searchOption.COUNTRIES[i].VALUE}`?.trim(),
              label:`${searchOption.COUNTRIES[i].TITLE}`?.trim()+(searchOption.COUNTRIES[i].QTY?(` (${searchOption.COUNTRIES[i].QTY})`):""),
            }
          )
        }
      }
      else if(searchOption.ID == 'company'){
        for(var i = 0; i < searchOption.COMPANIES?.length; i++){
          dropdownOptions.push(
            {
              value:`${searchOption.COMPANIES[i].company_name}`?.trim(),
              label:`${searchOption.COMPANIES[i].company_name}`?.trim()
            }
          )
        }
      }
    }
    if(searchOption && searchOption.TYPE == 'checkbox'){
      if(1){
        dropdownOptions.push(
          {
            value:1,
            label:'Yes',
          }
        )
        dropdownOptions.push(
          {
            value:0,
            label:'No',
          }
        )
      }
    }
    return (
      <Paper
        className='search-bar'
        component="form"
        sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', maxWidth: '750px' }}
      >
        <FormControl variant="outlined" style={{width:'170px', border:'none'}}>
          <Select
            className='key-select'
            style={{border:'none', outline:'none'}}
            // displayEmpty
            value={keySelect?keySelect:'Keyword'}
            onChange={(e)=>{
              setKeySelect(e.target.value); 
              if(e.target.value == 'New Products'){
                setPulldownValue(1);
              }
              else
                setPulldownValue(false);
              if(keywordInput?.current)
                keywordInput.current.value = ''
              if(selectRef?.current)
                selectRef.current.value = ''
            }}
          >
            {
              searchOptions.map((searchOption, index)=>{
                return(
                <MenuItem value={searchOption.LABEL} key={index}>{searchOption.LABEL}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        
        <Divider sx={{ height: '32px', m: 0.5 }} orientation="vertical" />
        
        {searchOption.TYPE == 'pulldown' || searchOption.TYPE == 'checkbox' ?
          <Select
            className='key-select'
            sx={{ flex: 1, width:300,border:'none', outline:'none', textAlign:'left'  }}
            // displayEmpty
            ref={selectRef}
            value = {pulldownValue}
            onChange={(e)=>{setPulldownValue(e.target.value)}}
          >
            {
              dropdownOptions.map((dropdownOption, index)=>{
                return(
                  <MenuItem value={dropdownOption.value} key={index}>{dropdownOption.label}</MenuItem>
                )
              })
            }
          </Select>:
          <InputBase
            sx={{ flex: 1, width:300  }}
            placeholder="Search by keyword"
            inputProps={{ 'aria-label': 'Search by keyword' }}
            onKeyDown={
              (e)=>{
                  if(e.keyCode == 13){
                      e.preventDefault();
                      doSearch()
                  }
              }
            }            
            onChange={
              (e)=>{
                doSearch()
              }
            }
            inputRef={keywordInput}
          />
        }
        {searchOption.TYPE != 'pulldown' && searchOption.TYPE != 'checkbox' &&
        <IconButton aria-label="delete" onClick={resetSearch} style={{padding:0, marginRight:5}}>
          <HighlightOffIcon  sx={{ p: '5px' }} />
        </IconButton>
        }
      </Paper>
    )
  }

  console.log("render client app")
  console.log("data", data);
  var pageData = paginate(filteredItems, page_size, page);
  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null
  var download_show = true
  var contact_show = true
  if(props.main.showSetup?.PUBLIC?.DOWNLOAD_LINKS == 'hide'){
    download_show = false
  }
  if(props.main.showSetup?.PUBLIC?.CONTACT_INFO == 'hide'){
    contact_show = false
  }

  return (
    <Box id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box className="xhbt-data-box" style={{maxWidth: 1200, margin:'64px auto', paddingLeft: 10, paddingRight:10, marginTop:(banner && banner != ''?(150+64):64)}}>
        {searchBox()}
        <Box mt={2}>
            {
              pageData.map((release, index)=>{
                return(
                  <Box key={index} display={'flex'} mb={2} style={{boxSizing:'border-box', textAlign:'left'}}>
                    <Box style={{width:'80px', boxSizing:'border-box'}}>
                      {release?.['HEADER_IMAGE'] &&
                        <img style={{width:'80px', height:'80px', objectFit:'contain', borderRadius:'5px', overflow:'hidden'}} width="80px" height="80px" src={release?.['HEADER_IMAGE']}/>
                      }
                    </Box>
                    <Box display={'flex'} alignItems={'center'} style={{width:'calc(100% - 80px)', padding: '3px 12px', boxSizing:'border-box'}}>
                      <div>
                        <h4 style={{marginTop:0, marginBottom:6}}>
                          <a className='sub-exhibtor-title' href="javascript:void(0)"
                            onClick={()=>{
                              setOpenReleaseDetails(release)
                            }}  
                          >
                            {release?.['TITLE']}
                          </a>
                        </h4>
                        {release?.['SUB_TITLE'] &&
                          <h5 style={{marginTop:0, marginBottom:6}}>{release?.['SUB_TITLE']} </h5>
                        }
                        <div
                          style={{marginTop:0, marginBottom:6}}
                        >
                          {/* <ShowMore
                            lines={5}
                            more='Read More'
                            less='Read Less'
                            anchorClass=''
                          >
                            {release?.['DESCRIPTION']} 
                          </ShowMore> */}
                          <ReactReadMoreReadLess
                              charLimit={250}
                              readMoreText={"Read more"}
                              readLessText={"Read less"}
                          >
                            {release?.['DESCRIPTION']} 
                          </ReactReadMoreReadLess>
                          {/* {release?.['DESCRIPTION']} */}
                        </div>                        
                        {release?.['COMPANY_NAME'] &&
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}>
                            <a href="javascript:void(0)" onClick={()=>{openCompanyProfile(release)}}>
                                <b >{release.COMPANY_NAME}</b><br/>
                            </a>
                          </p>
                        }                 
                        {release?.['BOOTH_NO'] &&
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}>Booth: {release?.['BOOTH_NO']} </p>
                        }
                        <p className='sub-title' style={{marginTop:0, marginBottom:6}}>{release?.['CITY']}, {release?.['STATE']} {release?.['COUNTRY']}  {release?.['PHONE']}</p>
                        {release.DOCUMENT &&  download_show &&
                        <Box mt={1}>
                          <a className='' style={{marginRight:'10px', color:'#0069aa'}} target='_blank' download={true} href={release.DOCUMENT}>                              
                            Download
                          </a>
                        </Box>
                        }
                      </div>
                    </Box>                     
                  </Box>
                )
              })
            }
            {
              (!data || data.length == 0) && dataFetched && 
              <Box style={{textAlign:'center', width:'100%'}}>
                <label>No Show Case Found.</label>
              </Box>
            }
        </Box>
      </Box>
      <Box mt={3} mb={2}>
        <Stack spacing={2}>
          <Pagination variant="outlined" shape="rounded" style={{justifyContent:'center'}} count={Math.ceil(data.length/page_size)} color="primary"  page={page} onChange={handleChangePage}/>
        </Stack>
      </Box>
      <LeftProfile groupOptions = {[]}/>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
        {
          openReleaseDetails &&         
          <Box className='exhibitor-details-popup press-releases'>
            {openReleaseDetails?.['HEADER_IMAGE'] &&
            <img  src={openReleaseDetails.HEADER_IMAGE}/>
            }
            <div>
              <IconButton className='exhibitor-details-close-btn' size="small" aria-label="close" color="inherit" onClick={()=>setOpenReleaseDetails(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <Box className='exhibitor-details-description' textAlign={'left'} p={2}>
              <h3 style={{marginTop:0}}>{openReleaseDetails.TITLE}</h3>              
              {openReleaseDetails?.['SUB_TITLE'] &&
                <h5 style={{marginTop:0, marginBottom:6}}>{openReleaseDetails?.['SUB_TITLE']} </h5>
              }
              <div className='sub-title' style={{marginTop:0, marginBottom:3}}>{openReleaseDetails?.['CITY']}, {openReleaseDetails?.['STATE']} {openReleaseDetails?.['COUNTRY']}  {openReleaseDetails?.['PHONE']}</div>
             
              {openReleaseDetails?.['COMPANY_NAME'] &&
                <p className='sub-title' style={{marginTop:0, marginBottom:3}}>{openReleaseDetails?.['COMPANY_NAME']} </p>
              }                 
              {openReleaseDetails?.['BOOTH_NO'] &&
                <p className='sub-title' style={{marginTop:0, marginBottom:3}}>Booth: {openReleaseDetails?.['BOOTH_NO']} </p>
              }
              {openReleaseDetails.DOCUMENT &&  download_show &&
              <Box mt={2} display={'flex'}>
                <a className='round-icon-btn' style={{marginRight:'10px'}} target='_blank' download href={openReleaseDetails.URL}>
                  <DownloadIcon style={{color:'white'}}/>
                </a>
              </Box>
              }
              <Box mt={2}>
                {openReleaseDetails.DESCRIPTION} 
              </Box>                    
            </Box>
          </Box>
        }
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
