import React ,{ useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import DeselectIcon from '@mui/icons-material/Deselect';
import Tooltip from '@mui/material/Tooltip';
import { purple } from '@mui/material/colors';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import {SketchField, Tools} from './sketch_right';
import { setDraggingTarget, setBoothList, setCanvasRefresh, setElementDrawing } from '../../actions/main'
import { setBoothPrefix, setBoothStartNumber, setBoothSuffix, setBoothCnt, setBoothOrder, setBoothSeqMode } from '../../actions/element'
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, redrawobjectborder, adjustobjectborder, addText, addTableText, feet2meter, meter2feet, px2unit, unit2Px, checkIfSelectable} from '../../utils/CanvasTool'
import { setBoothHistory } from '../../actions/booth_history'
import { fabric } from "fabric";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { v4 as uuidv4 } from 'uuid';
import { getDefaultGroup } from '../../utils/Common'
import { setLog, addLog, addLogs } from '../../actions/log'

const drawerWidth = 420;
const ToggleButton = styled(Button)(({ theme }) => ({
  color: 'inherit',
  backgroundColor: '#e7e7e7',
  '&:hover, &.active': {
    backgroundColor: '#114887',
    color: 'white'
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  borderColor:'white!important',
  backgroundColor: '#7D99B3',
  '&:hover': {
    backgroundColor: '#356080',
  },
  '&.active': {
    backgroundColor: '#355879',
  },
}));

const DragButton = styled(Button)(({ theme }) => ({
  color: 'inherit',
  fontSize: '13px',
  backgroundColor: 'transparent',
  '&:hover, &.active': {
    backgroundColor: '#e7e7e7',
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content .MuiTypography-root': {
    fontSize: 14.5
  },
  borderRadius:0,
  borderLeft: 0,
  borderRight: 0
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  float: 'left',
  marginTop: 6,
  marginBottom: 6
}));
var draggingTarget;
var draggingClass;
var dragImages = {};
 
var g_boothPrefix = "";
var g_boothStartNumber = 1;
var g_boothSuffix = "";
var g_boothCnt = 1;
var g_boothOrder = "v_inc";
var g_boothSeqMode = "even";
var g_boothNumberDuplicated = false

function ElementBox(props) {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState('panel1');
  const [element, setElement] = React.useState('panel1');
  const [tool, setTool] = React.useState(null)
  const [_sketch,setSketch] = React.useState(null)
  const [boothNumberDuplicated, setBoothNumberDuplicated]  = React.useState(false)
  const [snackMsg, setSnackMsg] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [openDraw, setOpenDraw] = React.useState(false);
  var open = props.open
  var mainCanvas = props.main.mainCanvas
  const handleCloseSnack = ()=>{
    setOpenSnack(false);
}

  const checkDuplication = ()=>{
    var mainCanvas = props.main.mainCanvas
    var boothNumber = g_boothStartNumber*1;
    for(var i = 0; i < g_boothCnt; i++){      
      var boothNumberFinal = g_boothPrefix+""+boothNumber+""+g_boothSuffix
      var firstObjects = props.main.firstMapContent&&props.main.firstMapContent.objects?props.main.firstMapContent.objects:[]
      var objects = [...firstObjects]
      for(var j = 0; j < objects.length; j++){
          if(objects[j].booth_number == boothNumberFinal && boothNumberFinal&&objects[j].class=="booth"){ 
            setBoothNumberDuplicated(true)
            return;
          }
      }
      objects = [...props.main.mapRemoveObjects] 
      for(var j = 0; j < objects.length; j++){
          if(objects[j].booth_number == boothNumberFinal && boothNumberFinal&&objects[j].class=="booth"){ 
            setBoothNumberDuplicated(true)
            return;
          }
      }
      objects = mainCanvas.getObjects();
      for(var j = 0; j < objects.length; j++){
          if(checkIfSelectable(objects[j])&&objects[j].booth_number == boothNumberFinal && boothNumberFinal&&objects[j].class=="booth"){ 
            setBoothNumberDuplicated(true)
            return;
          }
      }

      if(g_boothSeqMode == 'sequential'){
        boothNumber+=1;
      }
      else{
        boothNumber+=2;
      }
      setBoothNumberDuplicated(false)
    }
  }
  // var img1 = new Image();
  // img1.style.width = '100px';
  // img1.style.height = '100px';
  // img1.width="200px";
  // img1.height="200px";
  
  var img1 = new Image();
  // img2.width="200px";
  // img2.height="200px";
  img1.src = '/2d/table_8.png';
  dragImages['table_8'] = img1;

  var img2 = new Image();
  // img2.width="200px";
  // img2.height="200px";
  img2.src = '/2d/booth_10_10.png';
  dragImages['10_10'] = img2;

  var img3 = new Image();
  // img3.width="200px";
  // img3.height="200px";
  img3.src = '/2d/booth_10_20.png';
  dragImages['10_20'] = img3;

  var img4 = new Image();
  // img4.width="200px";
  // img4.height="200px";
  img4.src = '/2d/door.png';
  dragImages['door'] = img4;

  
  var img5 = new Image();
  // img3.width="200px";
  // img3.height="200px";
  img5.src = '/booth_small_icon.png';
  dragImages['booth'] = img5;

  

  const handleDragStart=(e)=> {
    // if(tool){
    //   endDraw()
    //   setTool(null)
    // }
    // props.setElementDrawing(true);
      var images = document.querySelectorAll('.drag-element');
      [].forEach.call(images, function (img) {
          img.classList.remove('img_dragging');
      });
      e.target.classList.add('img_dragging');
      
      var imageOffsetX, imageOffsetY;
      // var imageOffset = $(this).offset();
      imageOffsetX = e.clientX - e.target.offsetLeft;
      imageOffsetY = e.clientY - e.target.offsetTop;
      draggingTarget = e.target.id;
      draggingClass = e.target.className;
      console.log("draggingTarget", draggingTarget);
      var img
      if(draggingClass && draggingClass.includes("booth")){
        img = dragImages['booth'];
      }
      else if(draggingTarget == "door"){
        img = dragImages['door'];
      }
      // img.width = 100
      // img.height = 100
      if(img)
        e.dataTransfer.setDragImage(img, 0, 0);

      // props.setDraggingTarget(draggingTarget)
  }
  
  const handleDragEnd=(e)=> {
    var images = document.querySelectorAll('.drag-element');
      [].forEach.call(images, function (img) {
          img.classList.remove('img_dragging');
      });
  }
  const handleDropOnCanvas=(opt)=> {
    console.log("drop", opt)
    var mainCanvas = props.main.mainCanvas
    console.log("mainCanvas", mainCanvas)
    var main_viewportTransform = mainCanvas.viewportTransform;
    if(!draggingTarget) return
    var imgId =draggingTarget;
    // var offset = $(canvasObject).offset();
    var x = (opt.e.offsetX - main_viewportTransform[4])/main_viewportTransform[0];
    var y = (opt.e.offsetY - main_viewportTransform[5])/main_viewportTransform[3];
  
    if(mainCanvas.snapToGrid){
      var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
      var realMapWidth;
      var realMapHeight;
      var realGrid;
      var realOffsetX;
      var realOffsetY;
  
      var mapWidth = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
      var mapHeight = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
      if(convertRateX){
          realMapWidth = mapWidth
          realMapHeight = mapHeight
          realGrid = unit2Px(mainCanvas.gridSize?mainCanvas.gridSize:0, convertRateX, mainCanvas.mapUnit)
          realOffsetX = mainCanvas.gridOffsetX?((mainCanvas.gridOffsetX % realGrid )-realGrid):50
          realOffsetY = mainCanvas.gridOffsetY?((mainCanvas.gridOffsetY % realGrid )-realGrid):50
          realOffsetX+= unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
          realOffsetY+= unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
      }

      var angle = (mainCanvas.gridAngle?mainCanvas.gridAngle:0)*1;
      var canvasCenter = new fabric.Point(50, 50);
      var objectOrigin = new fabric.Point(x, y);
      var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(-1))
      var left = Math.round((new_loc.x-realOffsetX) / realGrid) * realGrid+realOffsetX;
      var top = Math.round((new_loc.y-realOffsetY) / realGrid) * realGrid+realOffsetY;

      objectOrigin = new fabric.Point(left, top);
      new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(1))
      x = new_loc.x
      y = new_loc.y
    } 

    var convertRateX= 1
    var convertRateY= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }

    if(draggingClass && draggingClass.includes('booth')){
        var dimention = imgId.split("_");
        var booth_width = unit2Px(dimention[0],convertRateX, mainCanvas.mapUnit);
        var booth_height = unit2Px(dimention[1],convertRateX, mainCanvas.mapUnit);
        if(g_boothNumberDuplicated){
          setOpenSnack(true);
          setSnackMsg("Booth Number exists already");
          return;
        }
        var newBoothList = [...props.main.boothList]
        var boothNumber = g_boothStartNumber*1;
        var addedElements = [];
        for(var i = 0; i < g_boothCnt; i++){
          var boothId = "G_"+parseInt(Math.random()*(10000))
          var incX = 0;
          var incY = 0;
          if(g_boothOrder=="h_inc")
            incX = booth_width*i;
          else if(g_boothOrder=="h_dec")
            incX = -booth_width*i;
          else if(g_boothOrder=="v_inc")
            incY = booth_height*i;
          else if(g_boothOrder=="v_dec")
            incY = -booth_height*i;


          var bgColor = "#dadada"
          var element = new fabric.Polygon(
            [
              {x: 0, y:0},
              {x: booth_width, y:0},
              {x: booth_width, y:booth_height},
              {x: 0, y:booth_height},
              {x: 0, y:0}
            ],
            {
              class:"outline",
              left: x+incX,
              top: y+incY,
              fill: bgColor, 
              stroke: 'black',
              // strokeWidth: 3/Math.max(convertRateX, 1),
              borderColor: 'black',
              lockRotation: true,
              lockScalingX: true,
              lockScalingY: true,
              // strokeUniform:true
          });


          // var element = new fabric.Rect({
          //   left: x+incX,
          //   top: y+incY,
          //   // fill: '#D81B60',
          //   width: booth_width,
          //   height: booth_height,
          //   strokeWidth: 3/Math.max(convertRateX, 1),
          //   stroke: "#880E4F",
          //   scaleX: 1,
          //   scaleY: 1,
          //   hasControls: true
          // });

          var group = new fabric.Group([element]).set({
            width: booth_width,
            height: booth_height,
            id: 'booth-'+boothId,
            class: 'booth',
            boothGroup: getDefaultGroup(props.main.groupOptions),
            class1 : 'rect-booth',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
          });	
          group.set({
              borderColor: '#ff7777',
              cornerColor: 'red',
              cornerSize: 6,
              hasBorders: false,
              transparentCorners: false,
              angle: mainCanvas.gridAngle*1
          });
          
          // element.clone(function(cloned) {
          //   group.clipPath = cloned                                    
          // });

          element.set({
              borderColor: '#ff7777',
              cornerColor: 'red',
              cornerSize: 6,
              transparentCorners: false
          });
          
          var boothNumberFinal = g_boothPrefix+""+boothNumber+""+g_boothSuffix
          
          if(g_boothSeqMode == 'sequential'){
            boothNumber+=1;
          }
          else{
            boothNumber+=2;
          }
          group.set({booth_number:boothNumberFinal})
          addText(mainCanvas, group, boothNumberFinal)

          
          group.new_created = true;
          group.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
          group.KEY_ID = uuidv4()
          mainCanvas.add(group).renderAll();

          

          mainCanvas.renderAll();
          var newBooth = {
              id: boothId,
              boothName : boothNumberFinal
          }
          newBoothList = [...newBoothList, newBooth]
          addedElements.push(group)
        }
        // if(g_boothSeqMode == 'sequential'){
        //   boothNumber+=1;
        // }
        // else{
        //   boothNumber+=2;
        // }
        var boothNames = "";
        var logs = [];
        for(var i = 0; i < addedElements?.length; i++){
          if(boothNames != "")
              boothNames +=", ";   
          boothNames +=addedElements[i].booth_number;  
          logs.push({
            BOOTH_KEY:addedElements[i].KEY_ID,
            KEY_ID:uuidv4(),
            action: 'add',
            date: new Date().toLocaleString(),
            description: "added booth: "+addedElements[i].booth_number,
            user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
          })     
        }
        
        if(logs.length){
          props.addLogs(logs)
        }
        var newSelectObj = new fabric.ActiveSelection(addedElements, {canvas:mainCanvas});
        mainCanvas.setActiveObject(newSelectObj);
        mainCanvas.requestRenderAll();  
        props.setBoothStartNumber(boothNumber)
        props.setBoothList(newBoothList);
        props.setBoothHistory({boothList:newBoothList, content: (props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:addedElements}})
    }

    return false;
  }

  useEffect(() => {
    g_boothPrefix = props.element.boothPrefix;
    g_boothStartNumber = props.element.boothStartNumber;
    g_boothSuffix = props.element.boothSuffix;
    g_boothCnt = props.element.boothCnt;
    g_boothOrder = props.element.boothOrder;
    g_boothSeqMode = props.element.boothSeqMode;
    g_boothNumberDuplicated = boothNumberDuplicated;
  }, [props.element.boothPrefix, props.element.boothStartNumber, props.element.boothSuffix, props.element.boothCnt, props.element.boothOrder, props.element.boothSeqMode, boothNumberDuplicated])


  
  useEffect(() => {
    if(!open){
      if(_sketch){
        _sketch.reset();
        props.setCanvasRefresh(true); setTool(null)
      }

    }
    if(open && props.main.mainCanvas){
      var images = document.querySelectorAll('.drag-element');
      [].forEach.call(images, function (img) {
          img.addEventListener('dragstart', handleDragStart, false);
          img.addEventListener('dragend', handleDragEnd, false);
      });
      props.main.mainCanvas.off('drop')            
      props.main.mainCanvas.on('drop', function(opt) {
            handleDropOnCanvas(opt)
      });  

      g_boothPrefix = props.element.boothPrefix;
      g_boothStartNumber = props.element.boothStartNumber;
      g_boothSuffix = props.element.boothSuffix;
      g_boothCnt = props.element.boothCnt;
      g_boothOrder = props.element.boothOrder;
      g_boothSeqMode = props.element.boothSeqMode;
    }
  }, [open])

  useEffect(() => {
    if(open && props.main.mainCanvas){
      var images = document.querySelectorAll('.drag-element');
      [].forEach.call(images, function (img) {
          img.addEventListener('dragstart', handleDragStart, false);
          img.addEventListener('dragend', handleDragEnd, false);
      });
      props.main.mainCanvas.off('drop')            
      props.main.mainCanvas.on('drop', function(opt) {
            handleDropOnCanvas(opt)
      });  
    }
  }, [tool])

  useEffect(() => {
    if(open && props.main.mainCanvas){      
      props.main.mainCanvas.off('drop')            
      props.main.mainCanvas.on('drop', function(opt) {
            handleDropOnCanvas(opt)
      });  
    }
  }, [props.main.boothList])
  
  useEffect(() => {
    if(props.main.topDrawing){  
      // setTool(null)   
      // endDraw();
    }
  }, [props.main.topDrawing])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const setActiveObject = (id) => {
    var mainCanvas = props.main.mainCanvas;
    mainCanvas.getObjects().forEach(function(o) {
      if(o.id == "booth-"+id) {
        mainCanvas.setActiveObject(o).renderAll();;
      }      
    })
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const toggleWall=()=>{
    if(tool && tool != Tools.Wall){
      endDraw()
      setTool(null);
      return
    }
    if(tool == Tools.Wall){
      _sketch.endDraw()
      _sketch.resetMouse()
      props.setCanvasRefresh(true)
    }
    else{      
      props.setElementDrawing(true);
    }
    setTool(tool!=Tools.Wall?Tools.Wall:null)
  }
  const toggleBooth=()=>{
    if(tool && tool != Tools.Booth){
      endDraw()
      setTool(null);
      return
    }
    if(tool == Tools.Booth){
      _sketch.endDraw()
      _sketch.resetMouse()
      props.setCanvasRefresh(true)
    }
    else{      
      props.setElementDrawing(true);
    }
    setTool(tool!=Tools.Booth?Tools.Booth:null)
  }
  const toggleAisle=()=>{
    if(tool && tool != Tools.Aisle){
      endDraw()
      setTool(null);
      return
    }
    if(tool == Tools.Aisle){
      _sketch.endDraw()
      _sketch.resetMouse()
      props.setCanvasRefresh(true)
    }
    else{      
      props.setElementDrawing(true);
    }
    setTool(tool!=Tools.Aisle?Tools.Aisle:null)
  }

  const endDraw=()=>{
    if(tool == Tools.Aisle || tool == Tools.Wall || tool == Tools.Booth){
      _sketch.endDraw()
      _sketch.resetMouse()
      props.setCanvasRefresh(true)
    }
  }

  
  const onDrawDone = (object)=>{
    var mainCanvas = props.main.mainCanvas
    if(object){
      mainCanvas.setActiveObject(object)
      
      // var newSelectObj = new fabric.ActiveSelection([object], {canvas:mainCanvas});
      // mainCanvas.setActiveObject(newSelectObj);

      setTool(null)
  
      var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
      var realMapWidth;
      var realMapHeight;
      var realGrid;
      var realOffsetX;
      var realOffsetY;
      var mapWidth = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
      var mapHeight = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
      if(convertRateX){
          realMapWidth = mapWidth
          realMapHeight = mapHeight
          realGrid = unit2Px(mainCanvas.gridSize?mainCanvas.gridSize:0, convertRateX, mainCanvas.mapUnit)
  
          realOffsetX = mainCanvas.gridOffsetX?((mainCanvas.gridOffsetX % realGrid )-realGrid):50
          realOffsetY = mainCanvas.gridOffsetY?((mainCanvas.gridOffsetY % realGrid )-realGrid):50
      }
  
  
      if(mainCanvas.snapToGrid){
          var angle = object.get('angle');
          var gridAngle = mainCanvas.gridAngle?mainCanvas.gridAngle:0
          if(gridAngle != angle) return;
          var canvasCenter = new fabric.Point(mainCanvas.getWidth()/2, mainCanvas.getHeight()/2);
          var objectOrigin = new fabric.Point(object.left, object.top);
          var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(-1))
          var left = Math.round((new_loc.x-realOffsetX) / realGrid) * realGrid+realOffsetX;
          var top = Math.round((new_loc.y-realOffsetY) / realGrid) * realGrid+realOffsetY;
  
          objectOrigin = new fabric.Point(left, top);
          new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(1))
  
          object.set({
            left: new_loc.x,
            top: new_loc.y
          });
      }
    }
  }
  var mapUnit = 'feet';
  if(mainCanvas){
    mapUnit = mainCanvas.mapUnit
  }
  
  return (
    <>
      <Draggable
        handle=".handle-main"
        defaultPosition={{x: 0, y: 0}}
        position={null}
        grid={[2, 2]}
        scale={1}
        bounds="body"
      >
        <div className="legend-option">
          <Box display="flex" style={{justifyContent:'space-between', marginBottom: 10}}>
            <DragIndicatorIcon fontSize="small" style={{cursor:'move', width:20}} className="handle-main"/>
            <CloseIcon fontSize="small" style={{cursor:'pointer'}} onClick={props.onClose}/>
          </Box>
          <Box sx={{ marginTop: '5px', marginLeft: '10px', marginRight: '10px' }}>            
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"10_10":"3_3"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?10:10/10*3}X{mapUnit == "feet"?10:10/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"10_20":"3_6"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?10:10/10*3}X{mapUnit == "feet"?20:20/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"10_30":"3_9"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?10:10/10*3}X{mapUnit == "feet"?30:30/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"10_40":"3_12"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?10:10/10*3}X{mapUnit == "feet"?40:40/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"20_20":"6_6"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?20:20/10*3}X{mapUnit == "feet"?20:20/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"20_30":"6_9"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?20:20/10*3}X{mapUnit == "feet"?30:30/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"20_40":"6_12"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?20:20/10*3}X{mapUnit == "feet"?40:40/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"20_60":"6_18"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?20:20/10*3}X{mapUnit == "feet"?60:60/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"30_20":"9_6"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?30:30/10*3}X{mapUnit == "feet"?20:20/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"30_30":"9_9"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?30:30/10*3}X{mapUnit == "feet"?30:30/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"30_40":"9_12"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?30:30/10*3}X{mapUnit == "feet"?40:40/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"30_50":"9_15"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?30:30/10*3}X{mapUnit == "feet"?50:50/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"30_60":"9_18"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?30:30/10*3}X{mapUnit == "feet"?60:60/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"40_40":"12_12"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?40:40/10*3}X{mapUnit == "feet"?40:40/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"40_60":"12_18"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?40:40/10*3}X{mapUnit == "feet"?60:60/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"40_80":"12_24"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?40:40/10*3}X{mapUnit == "feet"?80:80/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4}  sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"60_60":"18_18"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?60:60/10*3}X{mapUnit == "feet"?60:60/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"60_80":"18_24"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?60:60/10*3}X{mapUnit == "feet"?80:80/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"60_100":"18_30"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?60:60/10*3}X{mapUnit == "feet"?100:100/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"80_80":"24_24"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?80:80/10*3}X{mapUnit == "feet"?80:80/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"80_100":"24_30"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?80:80/10*3}X{mapUnit == "feet"?100:100/10*3}</DragButton>
                    </div>
                  </Grid>
                  <Grid item xs={4} sx={{textAlign:'left'}}>
                    <div style={{border:'1px solid #e0e0e0', borderRadius: '3px'}}>
                      <DragButton sx={{width:'100%', justifyContent:'start'}}><img id={mapUnit == "feet"?"100_100":"30_30"} draggable="true" className="booth drag-element" src="/booth_small_icon.png"/>&nbsp;{mapUnit == "feet"?100:100/10*3}X{mapUnit == "feet"?100:100/10*3}</DragButton>
                    </div>
                  </Grid>
                  
                  <Grid item xs={12}  sx={{textAlign:'left'}}>
                    <Tooltip title="Draw custom Booth">
                      <ToggleButton className={tool==Tools.Booth?"active":""} onClick={()=>toggleBooth()} sx={{width:'100%'}} startIcon={<BorderColorOutlinedIcon />}>Custom</ToggleButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}  sx={{textAlign:'left'}}>
                      <Button variant="contained" onClick={()=>setOpenDraw(true)} sx={{width:'100%'}} >Booth Sequence Setup</Button>
                    
                  </Grid>
                  <Grid item xs={12}  sx={{}}>   
                    <Box p={1}></Box>
                  </Grid>
                  {/* <Grid item xs={6} sx={{textAlign:'left'}}>
                      <img id="tool-text" draggable="true" src="/text.png"/>
                  </Grid> */}
                </Grid>
          </Box>
        </div>
      </Draggable>
      {Boolean(props.main.mainCanvas) && open &&
          <SketchField             
            ref={(c) => setSketch(c)}
            canvas={props.main.mainCanvas}
            tool={tool} 
            lineWidth={1}
            main={props.main}
            setBoothList={props.setBoothList}
            setBoothHistory={props.setBoothHistory}
            setCanvasRefresh={props.setCanvasRefresh}
            onDrawDone = {onDrawDone}
          />
        }
      <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            message={snackMsg}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />
      {openDraw &&
        <Draggable
          handle=".handle"
          defaultPosition={{x: 0, y: 0}}
          position={null}
          grid={[2, 2]}
          scale={1}
          bounds="body"
          // onStart={this.handleStart}
          // onDrag={this.handleDrag}
          // onStop={this.handleStop}
          >
            <Box className="booth-sequence-option">              
              <Box display="flex" style={{justifyContent:'space-between', marginBottom: 10}}>
                <DragIndicatorIcon fontSize="small" style={{cursor:'move', width:20}} className="handle"/>
                <CloseIcon fontSize="small" style={{cursor:'pointer'}} onClick={()=>setOpenDraw(false)}/>
              </Box>
              <Box>
                <Grid item xs={12}  sx={{}}>                  
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    <Grid item xs={3}  sx={{}}>
                      <TextField                
                        name="filename"
                        margin="dense"
                        label="Prefix"
                        // type="number"
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={props.element.boothPrefix}
                        onChange={(e)=>{
                          props.setBoothPrefix(e.target.value)
                        }}
                        onBlur = {checkDuplication}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}  sx={{}}>
                      <TextField                
                        name="filename"
                        margin="dense"
                        label="Number"
                        type="number"
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={props.element.boothStartNumber}
                        onChange={(e)=>{
                          props.setBoothStartNumber(e.target.value)
                        }}
                        onBlur = {checkDuplication}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}  sx={{}}>
                      <TextField                
                        name="filename"
                        margin="dense"
                        label="Suffix"
                        // type="number"
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={props.element.boothSuffix}
                        onChange={(e)=>{
                          props.setBoothSuffix(e.target.value)
                        }}
                        onBlur = {checkDuplication}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={4}  sx={{}}>
                      <TextField                
                        name="filename"
                        margin="dense"
                        label="How Many"
                        type="number"
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={props.element.boothCnt}
                        onChange={(e)=>{
                          props.setBoothCnt(e.target.value)
                        }}
                        onBlur = {checkDuplication}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}  sx={{}}>
                      <FormControl variant="outlined" fullWidth style={{marginTop:'7px'}}>
                        <Select
                          size="small"
                          defaultValue={'v_inc'}
                          // input={<OutlinedInput notched label="Price type" />}
                          onChange={(e)=>props.setBoothOrder(e.target.value)}
                        >
                          <MenuItem value={'v_inc'}>Vertical Increase</MenuItem>
                          <MenuItem value={'h_inc'}>Horizontal Increase</MenuItem>
                          <MenuItem value={'v_dec'}>Vertical Decrease</MenuItem>
                          <MenuItem value={'h_dec'}>Horizontal Decrease</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}  sx={{}}>
                      <FormControl variant="outlined" fullWidth style={{marginTop:'7px'}}>
                        <Select
                          size="small"
                          value={props.element.boothSeqMode}
                          onChange = {(e)=>{
                            props.setBoothSeqMode(e.target.value); 
                            // if(e.target.value == 'even')
                            //   props.setBoothStartNumber(2)
                            // else  if(e.target.value == 'odd')
                            //     props.setBoothStartNumber(1)

                          }}
                          onBlur = {checkDuplication}
                        >
                          <MenuItem value={'even'}>Step by Two</MenuItem>
                          <MenuItem value={'sequential'}>Sequential</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}  sx={{}}>
                      {boothNumberDuplicated &&
                      <p style={{color:'red', fontSize:14, fontWeight: 'bold'}}>Warning: Booth number duplicated!</p>
                      }
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Box>
            </Box>
        </Draggable>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    element: state.element
  };
};

const mapDispatchProps = (dispatch) => {
  return { 
    setDraggingTarget:  data=>dispatch(setDraggingTarget(data)),   
    setBoothHistory: data=>dispatch(setBoothHistory(data)),
    setBoothList: data=>dispatch(setBoothList(data)),
    setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),
    setElementDrawing: data=>dispatch(setElementDrawing(data)),
    setBoothPrefix: data=>dispatch(setBoothPrefix(data)),
    setBoothStartNumber: data=>dispatch(setBoothStartNumber(data)),
    setBoothSuffix: data=>dispatch(setBoothSuffix(data)),
    setBoothCnt: data=>dispatch(setBoothCnt(data)),
    setBoothOrder: data=>dispatch(setBoothOrder(data)),
    setBoothSeqMode: data=>dispatch(setBoothSeqMode(data)),
    addLog:  data=>dispatch(addLog(data)),
    addLogs:  data=>dispatch(addLogs(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(ElementBox);
