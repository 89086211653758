
import {
  SET_EXHIBITOR_LIST_SCROLL,
  SetPulldownValue,
  SetSearchOptions,
  SetKeySelect,
  SetFilteredItems,
  SetPageSetup,
} from '../actions/exhibitorlist';


var initVal = {
  exhibitorListScroll:0,
  pulldownValue:false,
  searchOptions:[],
  keySelect:'',
  filteredItems:[],
  pageSetup:[],
}
export default function index(state = initVal, {type, payload}) {
  switch (type) {  
    case SET_EXHIBITOR_LIST_SCROLL:
      return {
        ...state,
        exhibitorListScroll: payload,
    }    
    case SetPulldownValue:
      return {
        ...state,
        pulldownValue: payload,
    }     
    case SetSearchOptions:
      return {
        ...state,
        searchOptions: payload,
    }     
    case SetKeySelect:
      return {
        ...state,
        keySelect: payload,
    }     
    case SetFilteredItems:
      console.log("SetFilteredItems", payload)
      return {
        ...state,
        filteredItems: payload,
    }     
    case SetPageSetup:
      return {
        ...state,
        pageSetup: payload,
    }      
    default:
        return state;
  }
}