export const SET_CART = 'SET_CART'
export const ADD_CART = 'ADD_CART'

export const setCart = data =>({
    type:SET_CART,
    payload:data
});

export const addCart = data =>({
    type:ADD_CART,
    payload:data
});