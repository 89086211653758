import * as React from 'react';
import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit,  setLoading, setExhibitorList } from '../../actions/main.js'
import {setSnackMsg, setOpenSnack} from '../../actions/ui.js'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axiosInstance from '../../utils/axiosInstance.js'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import { ESHOW_URL } from '../../config.js'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SubExhibitorModal from './SubExhibitorModal'
import { v4 as uuidv4 } from 'uuid';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";

import { getTokenCookie, assetUrl } from '../../utils/Common.js'
import { getBoothByNumber } from '../../utils/CanvasTool.js'
import {PaperComponent} from '../../utils/Common.js'
import qs, { parse } from 'qs'


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function descendingComparator(a, b, orderBy) {
  var a_val = (a[orderBy])?a[orderBy]:''
  var b_val = (b[orderBy])?b[orderBy]:''
  return a_val.toString().localeCompare(b_val.toString(), 'en', { numeric: true })
  // a_val = a[orderBy]
  // b_val = b[orderBy]
  
  // if(a_val) a_val = a_val.trim()
  // if(b_val) b_val = b_val.trim()

  // var returnVal = 0
  // if (b_val < a_val) {
  //   returnVal = -1;
  // }
  // if (b_val > a_val) {
  //   returnVal = 1;
  // }
  
  // a_val = !isNaN(a_val)?a_val*1:a_val*1
  // b_val = !isNaN(b_val)?b_val*1:b_val*1
  // if (b_val < a_val) {
  //   returnVal = -1;
  // }
  // if (b_val > a_val) {
  //   returnVal = 1;
  // }
  // return returnVal;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    // if (order !== 0) {
      return order;
    // }
    // return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function SponsorModal(props) {
  const { onClose,  onChange, open } = props;
  const [filteredItems, setFilteredItems] = useState([])
  const [openCoCompanyModal, setOpenCoCompanyModal] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(false)

  useEffect(() => {
    if(open){
      fetchSponsorList();
    }
  }, [open])

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }


  const handleOkCoCompanyModal = async(formdata, booths)=>{
    var company = formdata.company
    var contacts = formdata.contacts
    var boothObjects = formdata.boothObjects
    var primary_booth = "";
    const active_booth_file = props.main.active_booth_file
    var group_key = "";
    group_key = selectedCompany?.key_id

    var exhibitor_contacts = []
    for(var i = 0; i < booths?.length; i++){
      var booth = booths[i]
      if(primary_booth == ""){
          primary_booth = booth.BOOTH_NO
      }
      if(booth.booth_number*1 < primary_booth*1){
          primary_booth = booth.BOOTH_NO
      }
    }
    // console.log("sales", sales); return;
    var contactName = ""
    for(var i = 0; i < contacts.length; i++){
        var contact = contacts[i]
        exhibitor_contacts.push({
            ROLE_TYPE:contact.ROLE_TYPE?contact.ROLE_TYPE:"11",
            ACTIVE:1,
            PHONE_EXT:contact.PHONE_EXT?contact.PHONE_EXT:"",
            PHONE:contact.PHONE?contact.PHONE:"",
            KEY_ID:contact.KEY_ID?contact.KEY_ID:"",
            LAST_NAME:contact.LAST_NAME?contact.LAST_NAME:"",
            EMAIL:contact.EMAIL?contact.EMAIL:"",
            FIRST_NAME:contact.FIRST_NAME?contact.FIRST_NAME:"",
            CREDENTIALS:contact.CREDENTIALS?contact.CREDENTIALS:"",
            CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
            TITLE:contact.TITLE?contact.TITLE:"",
            CRM_CONTACT_KEY:contact.KEY_ID?contact.KEY_ID:"",
            TYPE:contact.TYPE?contact.TYPE:"605"
        })
        contactName = contact.TITLE?.trim()
    }
    var exhibitor_key = uuidv4().toUpperCase()
    var exhibitor = {
        ZIP_CODE:company.ZIP_CODE?company.ZIP_CODE:"",
        PHONE:company.PHONE?company.PHONE:"",
        STATE:company.STATE?company.STATE:"",
        STATUS:company.STATUS?company.STATUS:"3",
        COUNTRY:company.COUNTRY?company.COUNTRY:"",
        GROUP_KEY:group_key,
        ADDRESS1:company.ADDRESS1?company.ADDRESS1:"",
        ADDRESS2:company.ADDRESS2?company.ADDRESS2:"",
        DISPLAY_ORDER:company.DISPLAY_ORDER?company.DISPLAY_ORDER:"1",
        KEY_ID:exhibitor_key,
        PRIMARY_BOOTH:primary_booth,
        COMPANY_NAME:company.COMPANY_NAME?company.COMPANY_NAME:"",
        FORM_KEY:active_booth_file?.sales_form,
        CITY:company.CITY?company.CITY:"",
        BOOTH_SF:"100",
        ORDER_NUMBER:"",
        CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
        WEBSITE:"",
        BOOTHS:booths,
        CONTACTS:exhibitor_contacts,
        SUBEXHIBITOR: "YES",
        // BOOTH_SPACE_RESELLER: true
    };
    var postData = {
        EXHIBITOR: exhibitor,
        SUCCESS: true,
    }
    console.log("postData", postData)
    // return;
    var submitdata = {
        query:`${ESHOW_URL()}?method=putExhibitor&token=${getTokenCookie()}`,
        postData: postData,
        returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(submitdata)
    });
    const options = {headers: {
      'body': '[{"company:","test"}]', 
      'Content-Type': 'application/json'
    }
    }
    props.setLoading(true)
    var url = `${ESHOW_URL()}?method=putExhibitor&token=${getTokenCookie()}`
    // var url = `/postCrm1`
    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
        const result = res.data;
        if(result.SUCCESS == true){
          fetchSponsorList();
          props.setOpenSnack(true);
          props.setSnackMsg("Added successfully");
          // props.setLoading(false)
          setOpenCoCompanyModal(false)
          setSelectedCompany(false)
      }
      else{
          props.setOpenSnack(true);
          props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Failed to add");
          props.setLoading(false)
      }   
    }).catch(error => {
        console.log("error", error);
    })
  }

  const fetchSponsorList = ()=>{
    
    props.setLoading(true)
    var url = `${ESHOW_URL()}?method=getReSellers&token=${getTokenCookie()}` 
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            if(result.RESELLERS){
              setFilteredItems(result.RESELLERS)
            }
            props.setLoading(false)
        }
        else{
          props.setLoading(false)
        }
    }).catch(error => {
      props.setLoading(false)
    })  
  }


  var searchedBooths = [];
  var searchedBoothTexts = [];
  var coBooths = [];
  if(selectedCompany){
    for(let i = 0; i < selectedCompany?.booths?.length; i++){      
      coBooths.push(
        {
          BOOTH_KEY:selectedCompany?.booths[i].booth_key,
          BOOTH_NO:selectedCompany?.booths[i].booth_no
        }
      )
    }
  }

  return (
    <>
      <Dialog 
        PaperComponent={PaperComponent}
        PaperProps={{drag_id:'sponsor-dialog-title'}}
        aria-labelledby="sponsor-dialog-title" 
        className='sponsor-modal'
        fullWidth={true}
        maxWidth={"md"}
        onClose={onClose} open={open}>
        <DialogTitle id='sponsor-dialog-title' style={{display:'flex', alignItems:'center'}}><AssignmentIndOutlinedIcon style={{marginRight: 10}}/>Co-Exhibitors</DialogTitle>
        <DialogContent>
          <Box mt={3}>   
              <Table className="color-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
                <TableBody>
                  {filteredItems.map((row, index)=> (
                    <>
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell style={{borderRight:'none'}}>
                          <b>{row.company_name}</b>
                        </TableCell>
                        <TableCell size="small" style={{textAlign:'center', padding:'4px 7px', width:30, borderLeft:'none'}}>
                          <IconButton size="small" aria-label="edit" onClick={()=>{setOpenCoCompanyModal(!openCoCompanyModal); setSelectedCompany(row)}}>
                            <ModeEditIcon fontSize='small' color="action"/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Table className="color-table" sx={{ width: '100%' , marginTop:0}} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{width:50}}>Logo</TableCell>
                              <TableCell style={{width:120}}>Booth Number</TableCell>
                              <TableCell>Company Name</TableCell>
                              <TableCell style={{width:120}}>Status</TableCell>
                            </TableRow>
                          </TableHead>
                            <TableBody>
                              {row.coexhibitors?.map((coexhibitor, index)=> (
                                <>
                                  <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>
                                      {coexhibitor.logo&&
                                        <img style={{ width: "60px", height:'auto' }} src={assetUrl(coexhibitor.logo)} alt="cropped" />
                                      }
                                    </TableCell>
                                    <TableCell>{coexhibitor.booth_no}</TableCell>
                                    <TableCell>{coexhibitor.company_name}</TableCell>
                                    <TableCell>{coexhibitor.status}</TableCell>
                                  </TableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
              
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
       
      </Dialog>
      {openCoCompanyModal&&
      <Dialog 
        PaperComponent={PaperComponent}
        PaperProps={{drag_id:'sponsor-coexhibitor-title'}}
        aria-labelledby="sponsor-coexhibitor-title" 
        className='coexhibitor-modal'
        fullWidth={true}
        maxWidth={"md"}open={openCoCompanyModal}
        onClose={()=>{setOpenCoCompanyModal(false)}}
      >
        <DialogTitle id='sponsor-coexhibitor-title' style={{display:'flex', alignItems:'center'}}><AssignmentIndOutlinedIcon style={{marginRight: 10}}/>New CoExhibitor</DialogTitle>
        <DialogContent>
          <SubExhibitorModal
              open={openCoCompanyModal}
              onClose={()=>{setOpenCoCompanyModal(false)}}
              onOk={handleOkCoCompanyModal}
              boothObjects = {searchedBooths}
              boothTexts = {searchedBoothTexts}
              coBooths = {coBooths}
              groupItems = {(val)=>{}}
              setGrouppingBooth={()=>{}}
          />
        </DialogContent>
      </Dialog>        
      }
    </>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setShowGrid: value=>dispatch(setShowGrid(value)),
        setGridAngle: value=>dispatch(setGridAngle(value)),
        setGridSize: value=>dispatch(setGridSize(value)),
        setUnit : value=>dispatch(setUnit(value)),
        setLoading:  data=>dispatch(setLoading(data)),
        setExhibitorList:  data=>dispatch(setExhibitorList(data)),
        setSnackMsg:  data=>dispatch(setSnackMsg(data)),
        setOpenSnack:  data=>dispatch(setOpenSnack(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SponsorModal);