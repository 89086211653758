import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setLoading, setGridSize, setGridAngle, setShowGrid, setUnit } from '../../actions/main'
import { setGlobalOpenSnack, setGlobalSnackMsg, setExhibitorUpdated, setSnackMsg, setOpenSnack } from '../../actions/ui'
import { setBoothHistory } from '../../actions/booth_history'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import IconButton from '@mui/material/IconButton';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import CropModal from './CropModal'
import CoCompanyModal from './CoCompanyModal'
import SubExhibitorModal from './SubExhibitorModal'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import ReactPlayer from 'react-player'
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import { assetUrl } from '../../utils/Common'
import { getTokenCookie } from '../../utils/Common'
import { v4 as uuidv4 } from 'uuid';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { uuid4 } from './sketch_right/utils';
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb:3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CompanyEditModal(props) {
  const [tabValue, setTabValue] = React.useState(0);
  const { onClose,  onChange, open } = props;

  const [formData, setFormData] = React.useState({});
  const [showSubCategory, setShowSubCategory] = React.useState(false);
  const [addNewPhoto, setAddNewPhoto] = React.useState(false);
  const [addNewVideo, setAddNewVideo] = React.useState(false);
  const [imgSrc, setImgSrc] = useState('')
  const [selectedCropItem, setSelectedTreeCropItem] = useState(null)
  const [openCropModal, setOpenCropModal] = useState('')  
  const [initialAspectRatio, setInitialAspectRatio] = useState(4/4) 
  const [openCoCompanyModal, setOpenCoCompanyModal] = useState(false)
  const [cropLogoData, setCropLogoData] = useState(null)
  const [cropPhotoData, setCropPhotoData] = useState(null)
  const [cropHeaderImgData, setCropHeaderImgData] = useState(null)
  const [cropProfileBgData, setCropProfileBgData] = useState(null)
  const [threeModelData, setThreeModelData] = useState(null)

  const [cropLogoPreviewData, setCropLogoPreviewData] = useState(null)
  const [cropPhotoPreviewData, setCropPhotoPreviewData] = useState(null)
  const [cropHeaderImgPreviewData, setCropHeaderImgPreviewData] = useState(null)
  const [cropProfileBgPreviewData, setCropProfileBgPreviewData] = useState(null)
  const [videoUrl, setVideoUrl] = useState('')
  const videoRef = useRef();
  const [expandedTree, setExpandedTree] = React.useState([]);
  const [selectedTree, setSelectedTree] = React.useState([]);
  const [exhibitorEshow, setExhibitorEshow] = React.useState({});
  let query = useQuery();

  const handleToggleTree = (event, nodeIds) => {
    if (!event.target.classList.contains("collapseIcon") && event.target.nodeName !== "svg" && event.target.nodeName !== "path") {
      return;
    }
    setExpandedTree(nodeIds);
  };

  const handleSelectTree = (event, nodeIds) => {
    if (event.target.nodeName === "svg") {
      return;
    }
    const first = nodeIds[0];
    if (selectedTree.includes(first)) {
      setSelectedTree(selectedTree.filter(id => id !== first));
    } else {
      setSelectedTree([first, ...selectedTree]);
    }
  };
  
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  function getChildById(node, KEY_ID) {
    let array = [];

    //returns an array of nodes ids: clicked node id and all children node ids
    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes.KEY_ID);
      // if (Array.isArray(nodes.children)) {
      //   nodes.children.forEach((node) => {
      //     array = [...array, ...getAllChild(node)];
      //     array = array.filter((v, i) => array.indexOf(v) === i);
      //   });
      // }
      return array;
    }

    //returns the node object that was selected
    function getNodeById(nodes, KEY_ID) {
      if (nodes.KEY_ID === KEY_ID) {
        return nodes;
      } else if (Array.isArray(nodes.SUBCAT)) {
        let result = null;
        nodes.SUBCAT.forEach((node) => {
          if (!!getNodeById(node, KEY_ID)) {
            result = getNodeById(node, KEY_ID);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, KEY_ID));
  }

  function getOnChange(checked, nodes) {
    //gets all freshly selected or unselected nodes
    var categoryData = {
      KEY_ID: "0",
      TITLE: "Parent",
      SUBCAT: []
    }
    if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
      // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
      categoryData.SUBCAT = (props.main.categoryOptions)
    }
    else{
  
    }

    const allNode = getChildById(categoryData, nodes.KEY_ID);
    //combines newly selected nodes with existing selection
    //or filters out newly deselected nodes from existing selection
    let array = checked
      ? [...selectedTree, ...allNode]
      : selectedTree.filter((value) => !allNode.includes(value));

      setSelectedTree(array);
  }

  const RenderTreeWithCheckboxes = (nodes) => {
    return (
      <TreeItem
        key={nodes.KEY_ID}
        nodeId={nodes.KEY_ID}
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedTree.some((item) => item === nodes.KEY_ID)}
                onChange={(event) =>
                  getOnChange(event.currentTarget.checked, nodes)
                }
                //onClick={(e) => e.stopPropagation()}
              />
            }
            label={<>{nodes.TITLE}</>}
            key={nodes.KEY_ID}
          />
        }
      >
        {Array.isArray(nodes.SUBCAT)
          ? nodes.SUBCAT.map((node) => RenderTreeWithCheckboxes(node))
          : null}
      </TreeItem>
    );
  };

 const getExhibitor = async (KEY_ID)=>{
    let booth = props.boothItem.booth
    var result = null
    var url = `${ESHOW_URL()}?method=getExhibitor&EXHIBITOR_KEY=${KEY_ID}&token=${getTokenCookie()}&booth_key=${booth?.KEY_ID}`
    const response = await axiosInstance.get(url);
    if(response.status == 200){
      result = response.data?.EXHIBITOR;
      setExhibitorEshow(result)
    }
    return result

 }


  useEffect(async () => {
    if(open){
      setCropLogoData(null)
      setCropPhotoData(null)
      setCropHeaderImgData(null)
      setCropProfileBgData(null)
      setThreeModelData(null)
      setCropProfileBgPreviewData(null)
      setVideoUrl('')
      setImgSrc(null)
      setSelectedTreeCropItem(null)
      props.setExhibitorUpdated(false)
      
      var exhibitors = props.boothItem.booth?.exhibitors
      var company_KEY_ID
      if(exhibitors && exhibitors.length > 0){
        company_KEY_ID = exhibitors[0].EXHIBITOR_KEY
      }
      if(company_KEY_ID){
        var exhibitorInfo = await getExhibitor(company_KEY_ID)
        var CATEGORY = exhibitorInfo?.['DIRECTORY']['CATEGORY'];
        var tmp = []
        if(CATEGORY && CATEGORY.length){
          for(var i = 0; i < CATEGORY.length; i++){
            tmp.push(CATEGORY[i]['ANSWER_KEY']);
          }
        }
        setSelectedTree(tmp)
        var formData = {};
        formData.company_log_url = exhibitorInfo?.['DIRECTORY']['LOGO'];
        formData.company_description = exhibitorInfo?.['DIRECTORY']['DESCRIPTION'];
        var photos = exhibitorInfo?.['DIRECTORY']['PHOTOS'];
        // var photoUrls = []
        // if(photos && photos.length > 0){
        //   for(var i = 0; i < photos.length; i++){
        //     if(photos[i].URL)
        //       photoUrls.push(photos[i].URL)
        //   }
        // }
        formData.company_photos = photos;
        var videos = exhibitorInfo?.['DIRECTORY']['VIDEOS'];
        // var videoUrls = []
        // if(videos && videos.length > 0){
        //   for(var i = 0; i < videos.length; i++){
        //     if(videos[i].URL)
        //     videoUrls.push(videos[i].URL)
        //   }
        // }
        formData.company_videos = videos
        formData.company_name = exhibitorInfo?.['DIRECTORY']['COMPANY_NAME'];
        formData.company_contact_street = exhibitorInfo?.['DIRECTORY']['ADDRESS1'];
        formData.company_contact_address2 = exhibitorInfo?.['DIRECTORY']['ADDRESS2'];
        formData.company_contact_city = exhibitorInfo?.['DIRECTORY']['CITY'];
        formData.company_contact_state = exhibitorInfo?.['DIRECTORY']['STATE'];
        formData.company_contact_zip = exhibitorInfo?.['DIRECTORY']['ZIP_CODE'];
        formData.company_contact_country = exhibitorInfo?.['DIRECTORY']['COUNTRY'];
        formData.company_contact_fn = exhibitorInfo?.['DIRECTORY']['FIRST_NAME'];
        formData.company_contact_ln = exhibitorInfo?.['DIRECTORY']['LAST_NAME'];
        formData.company_contact_email = exhibitorInfo?.['DIRECTORY']['EMAIL'];
        formData.company_contact_phone = exhibitorInfo?.['DIRECTORY']['PHONE'];
        formData.company_contact_phone_ext = exhibitorInfo?.['DIRECTORY']['PHONE_EXT'];
        formData.company_facebook= exhibitorInfo?.['DIRECTORY']['FACEBOOK'];
        formData.company_twitter = exhibitorInfo?.['DIRECTORY']['TWITTER'];
        formData.company_snapchat = exhibitorInfo?.['DIRECTORY']['SNAPCHAT'];
        formData.company_instagram = exhibitorInfo?.['DIRECTORY']['INSTAGRAM'];
        formData.company_linkdin = exhibitorInfo?.['DIRECTORY']['LINKEDIN'];
        formData.company_tiktok = exhibitorInfo?.['DIRECTORY']['TIKTOK'];
        formData.company_call_label = exhibitorInfo?.['DIRECTORY']['ACTION_BUTTON_LABEL'];
        formData.company_call_link = exhibitorInfo?.['DIRECTORY']['ACTION_BUTTON_LINK'];
        formData.company_website = exhibitorInfo?.['DIRECTORY']['WEBSITE'];
        formData.company_headerimg_url = exhibitorInfo?.['DIRECTORY']['HEADERIMAGE'];
        formData.company_bgimg_url = exhibitorInfo?.['DIRECTORY']['BACKGROUND'];
        formData.company_threemodel_url = exhibitorInfo?.['DIRECTORY']['3DMODEL'];
        formData.company_threemodel_name = exhibitorInfo?.['DIRECTORY']['3DMODEL'];
        formData.company_tag_featured = exhibitorInfo?.['DIRECTORY']['FEATURED'];
        formData.company_tag_member = exhibitorInfo?.['DIRECTORY']['MEMBER'];
        formData.company_tag_3dbooth = (exhibitorInfo?.['DIRECTORY']['3DMODEL']&&exhibitorInfo?.['DIRECTORY']['3DMODEL']!="")?1:0;
        formData.company_tag_sponser = exhibitorInfo?.['DIRECTORY']['SPONSOR'];
        formData.booth_space_reseller = exhibitorInfo?.['BOOTH_SPACE_RESELLER'];
        formData.exhibitor_type = exhibitorInfo?.['EXHIBITOR_TYPE'];
        
        formData.company_tag_level = exhibitorInfo?.['DIRECTORY']['BOOTHLEVEL'];
        formData.co_companies = [];
        setFormData(formData)
        if(formData.company_log_url){
          setCropLogoPreviewData(formData.company_log_url)
        }
        else{
          setCropLogoPreviewData(null)
        }
        if(formData.company_headerimg_url){
          setCropHeaderImgPreviewData(assetUrl(formData.company_headerimg_url))
        }
        else{
          setCropHeaderImgPreviewData(null)
        }
        if(formData.company_bgimg_url){
          setCropProfileBgPreviewData(assetUrl(formData.company_bgimg_url))
        }
        else{
          setCropProfileBgPreviewData(null)
        }
        
        setShowSubCategory(formData.company_main_category=="A/V Equipment")

      }

    }
  }, [open])
  
  const onChangeName = (e)=>{
    onChange(e)
  }

  const onOk = async (e)=>{
    if(cropLogoData) {
      var filename = "bg.png"
        // const reader = new FileReader();
      var data = cropLogoData.slice(0, cropLogoData.size);
      var filePath = `company/${uuidv4()}`
      var url = `${S3_URL()}uploadfile?filePath=${filePath}`
      props.setLoading(true)
      const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      const response = await axiosInstance.post(url, data, headers);
      if(response.status == 200){
        formData.company_log_url = `${S3_URL()}s3/${filePath}`
      }
    }
    if(cropHeaderImgData) {
      var filename = "bg.png"
        // const reader = new FileReader();
      var data = cropHeaderImgData.slice(0, cropHeaderImgData.size);
      var filePath = `company/${uuidv4()}`
      var url = `${S3_URL()}uploadfile?filePath=${filePath}`
      props.setLoading(true)
      const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      const response = await axiosInstance.post(url, data, headers);
      if(response.status == 200){
        formData.company_headerimg_url = `${S3_URL()}s3/${filePath}`
      }
    }
    if(cropProfileBgData) {
      var filename = "bg.png"
        // const reader = new FileReader();
      var data = cropProfileBgData.slice(0, cropProfileBgData.size);
      var filePath = `company/${uuidv4()}`
      var url = `${S3_URL()}uploadfile?filePath=${filePath}`
      props.setLoading(true)
      const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      const response = await axiosInstance.post(url, data, headers);
      if(response.status == 200){
        formData.company_bgimg_url = `${S3_URL()}s3/${filePath}`
      }
    }
    if(threeModelData){
      var filename = "three.json"
      var uploadpath = "three_models"
        // const reader = new FileReader();
      var data = threeModelData.slice(0, threeModelData.size);
      var filePath = `company/${uuidv4()}`
      var url = `${S3_URL()}uploadfile?filePath=${filePath}`
      props.setLoading(true)
      const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      const response = await axiosInstance.post(url, data, headers);
      if(response.status == 200){
        formData.company_threemodel_url =  `${S3_URL()}s3/${filePath}`
        formData.company_threemodel_name = threeModelData.name
      }
    }
    // formData.company_threemodel_url = null
    formData.company_category = selectedTree

    await submitExhibor(formData)
    props.setLoading(false);
    onClose()
  }

  const submitExhibor = async (formData)=>{
    var active_booth_file = props.main.active_booth_file
    var directory = {};
    directory.KEY_ID = exhibitorEshow?.DIRECTORY?.KEY_ID!=""?exhibitorEshow?.DIRECTORY?.KEY_ID:uuid4();
    directory.COMPANY_NAME = formData.company_name;
    directory.ADDRESS1 = formData.company_contact_street;
    directory.ADDRESS2 = formData.company_contact_address2;
    directory.CITY = formData.company_contact_city;
    directory.STATE = formData.company_contact_state;
    directory.ZIP_CODE = formData.company_contact_zip;
    directory.COUNTRY = formData.company_contact_country;
    directory.FIRST_NAME = formData.company_contact_fn;
    directory.LAST_NAME = formData.company_contact_ln;
    directory.EMAIL = formData.company_contact_email;
    directory.PHONE = formData.company_contact_phone;
    directory.PHONE_EXT = formData.company_contact_phone_ext;
    directory.FACEBOOK = formData.company_facebook;
    directory.TWITTER = formData.company_twitter;
    directory.SNAPCHAT = formData.company_snapchat;
    directory.INSTAGRAM = formData.company_instagram;
    directory.LINKEDIN = formData.company_linkdin;
    directory.TIKTOK = formData.company_tiktok;
    directory.ACTION_BUTTON_LABEL = formData.company_call_label;
    directory.ACTION_BUTTON_LINK = formData.company_call_link;
    directory.WEBSITE = formData.company_website;
    directory.HEADERIMAGE = formData.company_headerimg_url;
    directory.BACKGROUND = formData.company_bgimg_url;
    directory['3DMODEL'] = formData.company_threemodel_url;
    directory.FEATURED = formData.company_tag_featured;
    directory.MEMBER = formData.company_tag_member;
    directory.SPONSOR = formData.company_tag_sponser;
    directory.BOOTHLEVEL = formData.company_tag_level;
    directory.LOGO = formData.company_log_url;
    directory.DESCRIPTION = formData.company_description;
    directory.VIDEOS = formData.company_videos;
    directory.PHOTOS = formData.company_photos;
    var categories = [];
    for(var i = 0; i < formData.company_category?.length; i++){
      var ANSWER_KEY = formData.company_category[i]
      var element = searchTree(categoryData, ANSWER_KEY)
      if(element?.KEY_ID){
        var row = {
          KEY_ID: uuid4(),
          ANSWER_KEY:element.KEY_ID,
          ACTIVE:1,
          RESPONSE_TEXT:'',
          ANSWER_TITLE:element.TITLE
        }
        categories.push(row);
      }
    }
    if(categories.length){
      directory.CATEGORY = categories;
    }
    
    let tmp = {
      KEY_ID: exhibitorEshow?.KEY_ID,
      CONTACTS: exhibitorEshow?.CONTACTS?[...exhibitorEshow?.CONTACTS]:[],
      FORM_KEY:active_booth_file?.sales_form,
      CRM_COMPANY_KEY: exhibitorEshow?.CRM_COMPANY_KEY,   
      DIRECTORY: directory
    }
    if(formData.exhibitor_type != 'CoExhibitor'){
      tmp['BOOTH_SPACE_RESELLER'] = formData.booth_space_reseller
    }
    var postData = {
      EXHIBITOR:tmp,
      SUCCESS :true
    }
    console.log("postData", postData);
    var formdata = {
      query:`${ESHOW_URL()}?method=putExhibitor&token=${getTokenCookie()}`,
      returnResponse: true,
      postData: postData
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {headers: {
      'body': '[{"company:","test"}]', 
      'Content-Type': 'application/json'
    }
    }
    var url = `${ESHOW_URL()}?method=putExhibitor&token=${getTokenCookie()}`
    var response = await axiosInstance.post(url, JSON.stringify(postData), options)
    if(response.status == 200){
      var eshowResponse = response.data
      if(eshowResponse.SUCCESS == true){
        props.setGlobalOpenSnack(true);
        props.setGlobalSnackMsg("Updated successfully");
        props.setExhibitorUpdated(true)
      }
      else{
          props.setGlobalOpenSnack(true);
          props.setGlobalSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to update");
      }
    }
    else{      
      props.setGlobalOpenSnack(true);
      props.setGlobalSnackMsg("Updated successfully");
      props.setExhibitorUpdated(true)
      console.log("")
    }
  }

  const handleCloseCropModal=()=>{
    setOpenCropModal(false)
  }

  const confirmCropModal=(dataImg, dataUrl)=>{
    if(selectedCropItem == "logo"){
      setCropLogoData(dataImg)
      setCropLogoPreviewData(dataUrl)
    }
    if(selectedCropItem == "photo"){
      setCropPhotoData(dataImg)
      setCropPhotoPreviewData(dataUrl)
      handleAddPhoto(dataImg)
    }
    if(selectedCropItem == "header"){
      setCropHeaderImgData(dataImg)
      setCropHeaderImgPreviewData(dataUrl)
    }
    if(selectedCropItem == "profilebg"){
      setCropProfileBgData(dataImg)
      setCropProfileBgPreviewData(dataUrl)
    }
    setOpenCropModal(false)
  }

  const handleCloseCoCompanyModal=()=>{
    setOpenCoCompanyModal(false)
  }

  const confirmCoCompanyModal=(co_companies)=>{
    changeFormData('co_companies', co_companies)
    setOpenCoCompanyModal(false)
  }


  const changeFormData=(key, value)=>{
    var tmpFormData = {...formData, [key]:value}
    if(key === "company_main_category"){
      setShowSubCategory(value=="A/V Equipment")
    }
    setFormData(tmpFormData)
  }

  const handleAddPhoto = async (cropPhotoData)=>{
    if(!cropPhotoData) return;
    var filename = "bg.png"
      // const reader = new FileReader();
    var data = cropPhotoData.slice(0, cropPhotoData.size);
    var filePath = `company/${uuidv4()}`
    var url = `${S3_URL()}uploadfile?filePath=${filePath}`
    const headers = {headers: {'Content-Type': 'multipart/form-data'}};
    const response = await axiosInstance.post(url, data, headers);
    if(response.status == 200){
      var imgUrl = `${S3_URL()}s3/${filePath}`
      var tmp_formData = {...formData};
      var company_photos
      if(tmp_formData.company_photos){
        company_photos= [...formData.company_photos]
      }
      else{
        company_photos = [];
      }
      company_photos=[...company_photos, {URL:imgUrl, KEY_ID:uuidv4(), ACTIVE:1, new:true}]
      tmp_formData = {...tmp_formData ,company_photos:company_photos}
      setFormData(tmp_formData)
      setCropPhotoData(null)
      setCropPhotoPreviewData(null)
    }
    props.setLoading(false)

  }

  const handleAddVideo= ()=>{
    // var file = videoRef.current.files[0]
    // if(file){
    //   var filename = file.name
    //   var filetype = file.type.split("/").pop();
    //   // const reader = new FileReader();
    //   var data = file.slice(0, file.size);
    //   var url = API_URL()+`uploadasset?ax-file-name=${filename}&&filetype=${filetype}`

    //   var xhr = new XMLHttpRequest();
    //   xhr.open("POST", url);
    //   props.setLoading(true)

    //   xhr.setRequestHeader("Accept", "image/apng");
    //   // xhr.setRequestHeader("Content-Type", "text/plain");

    //   xhr.onreadystatechange = function () {
    //     if (xhr.readyState === 4) {
    //         console.log(xhr.responseText);
    //         if(xhr.responseText){
    //           var url = API_URL()+"bg_imgs/"+JSON.parse(xhr.responseText);
    //           var tmp_formData = {...formData};
    //           var company_videos
    //           if(tmp_formData.company_videos){
    //             company_videos= [...formData.company_videos]
    //           }
    //           else{
    //             company_videos = [];
    //           }
    //           company_videos=[...company_videos, url]
    //           tmp_formData = {...tmp_formData ,company_videos:company_videos}
    //           setFormData(tmp_formData)
    //           videoRef.current.value = null;
    //         }
    //     }
    //     props.setLoading(false);
    //   };

    //   xhr.send((data));
    //   props.setLoading(true);
    // }
    if(videoUrl){
      var tmp_formData = {...formData};
      var company_videos
      if(tmp_formData.company_videos){
        company_videos= [...formData.company_videos]
      }
      else{
        company_videos = [];
      }
      company_videos=[...company_videos, {URL:videoUrl, KEY_ID:uuidv4(), ACTIVE:1, new: true}]
      tmp_formData = {...tmp_formData ,company_videos:company_videos}
      setFormData(tmp_formData)

      setVideoUrl('');
    }
  }
  
  const onSelectLogo = (e)=> {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        setImgSrc(reader.result.toString() || '')
        setInitialAspectRatio(4/4)
        setOpenCropModal(true)
        setSelectedTreeCropItem('logo')
        e.target.value=null;
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }

  const onSelectPhoto = (e)=> {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        setImgSrc(reader.result.toString() || '')
        setInitialAspectRatio(359/300)
        setOpenCropModal(true)
        setSelectedTreeCropItem('photo')
        e.target.value=null;
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }
  const onSelectHeaderImage = (e)=>{
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        setImgSrc(reader.result.toString() || '')
        setInitialAspectRatio(375/64)
        setOpenCropModal(true)
        e.target.value=null;
        setSelectedTreeCropItem('header')
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }
  
  const onSelectProfileBg = (e)=>{
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        setImgSrc(reader.result.toString() || '')
        setInitialAspectRatio(375/836)
        setOpenCropModal(true)
        e.target.value=null;
        setSelectedTreeCropItem('profilebg')
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }
  // const onSelectProfileBg = (e)=>{
  //   if (e.target.files && e.target.files.length > 0) {
  //     setCropProfileBgPreviewData(URL.createObjectURL(e.target.files[0]))
  //     setCropProfileBgData(e.target.files[0])
  //   }
  // }

  const onSelectThreeModel = (e)=>{
    if (e.target.files && e.target.files.length > 0) {
      setThreeModelData(e.target.files[0])
    }
  }

  
  const onSelectVideo = (e)=> {

  }

 
  var categoryData = {
    KEY_ID: "0",
    TITLE: "Parent",
    SUBCAT: []
  }
  if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
    // categoryData.children = listToTree(props.main.categoryOptions)
    categoryData.SUBCAT = (props.main.categoryOptions)
  }
  else{

  }

  function searchTree(element, KEY_ID){
    if(element.KEY_ID == KEY_ID){
         return element;
    }else if (element.SUBCAT != null){
         var i;
         var result = null;
         for(i=0; result == null && i < element.SUBCAT.length; i++){
              result = searchTree(element.SUBCAT[i], KEY_ID);
         }
         return result;
    }
    return null;
  }

  const getCategoryName = (KEY_ID)=>{
    var name  = ""
    var element = searchTree(categoryData, KEY_ID)
    if(element){
      name = element.ANSWER_TITLE
    }
    return name;
  }

  const handleOkCoCompanyModal = async(formdata, booths)=>{
    var company = formdata.company
    var contacts = formdata.contacts
    var boothObjects = formdata.boothObjects
    var primary_booth = "";
    const active_booth_file = props.main.active_booth_file
    var group_key = "";
    if(props.boothItem?.booth?.exhibitors && props.boothItem?.booth?.exhibitors?.length > 0){
      group_key = props.boothItem?.booth?.exhibitors[0].EXHIBITOR_KEY
    }

    var exhibitor_contacts = []
    for(var i = 0; i < booths?.length; i++){
      var booth = booths[i]
      if(primary_booth == ""){
          primary_booth = booth.BOOTH_NO
      }
      if(booth.booth_number*1 < primary_booth*1){
          primary_booth = booth.BOOTH_NO
      }
    }
    // console.log("sales", sales); return;
    var contactName = ""
    for(var i = 0; i < contacts.length; i++){
        var contact = contacts[i]
        exhibitor_contacts.push({
            ROLE_TYPE:contact.ROLE_TYPE?contact.ROLE_TYPE:"11",
            ACTIVE:1,
            PHONE_EXT:contact.PHONE_EXT?contact.PHONE_EXT:"",
            PHONE:contact.PHONE?contact.PHONE:"",
            KEY_ID:contact.KEY_ID?contact.KEY_ID:"",
            LAST_NAME:contact.LAST_NAME?contact.LAST_NAME:"",
            EMAIL:contact.EMAIL?contact.EMAIL:"",
            FIRST_NAME:contact.FIRST_NAME?contact.FIRST_NAME:"",
            CREDENTIALS:contact.CREDENTIALS?contact.CREDENTIALS:"",
            CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
            TITLE:contact.TITLE?contact.TITLE:"",
            CRM_CONTACT_KEY:contact.KEY_ID?contact.KEY_ID:"",
            TYPE:contact.TYPE?contact.TYPE:"605"
        })
        contactName = contact.TITLE?.trim()
    }
    var exhibitor_key = uuidv4().toUpperCase()
    var exhibitor = {
        ZIP_CODE:company.ZIP_CODE?company.ZIP_CODE:"",
        PHONE:company.PHONE?company.PHONE:"",
        STATE:company.STATE?company.STATE:"",
        STATUS:company.STATUS?company.STATUS:"3",
        COUNTRY:company.COUNTRY?company.COUNTRY:"",
        GROUP_KEY:group_key,
        ADDRESS1:company.ADDRESS1?company.ADDRESS1:"",
        ADDRESS2:company.ADDRESS2?company.ADDRESS2:"",
        DISPLAY_ORDER:company.DISPLAY_ORDER?company.DISPLAY_ORDER:"1",
        KEY_ID:exhibitor_key,
        PRIMARY_BOOTH:primary_booth,
        COMPANY_NAME:company.COMPANY_NAME?company.COMPANY_NAME:"",
        FORM_KEY:active_booth_file?.sales_form,
        CITY:company.CITY?company.CITY:"",
        BOOTH_SF:"100",
        ORDER_NUMBER:"",
        CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
        WEBSITE:"",
        BOOTHS:booths,
        CONTACTS:exhibitor_contacts,
        SUBEXHIBITOR: "YES",
        // BOOTH_SPACE_RESELLER: true
    };
    var postData = {
        EXHIBITOR: exhibitor,
        SUCCESS: true,
    }
    console.log("postData", postData)
    // return;
    var submitdata = {
        query:`${ESHOW_URL()}?method=putExhibitor&token=${getTokenCookie()}`,
        postData: postData,
        returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(submitdata)
    });
    const options = {headers: {
      'body': '[{"company:","test"}]', 
      'Content-Type': 'application/json'
    }
    }
    props.setLoading(true)
    var url = `${ESHOW_URL()}?method=putExhibitor&token=${getTokenCookie()}`
    // var url = `/postCrm1`
    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
        const result = res.data;
        if(result.SUCCESS == true){
          getExhibitor(group_key)
          props.setOpenSnack(true);
          props.setSnackMsg("Added successfully");
          props.setLoading(false)
          setOpenCoCompanyModal(false)
      }
      else{
          props.setOpenSnack(true);
          props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Failed to add");
          props.setLoading(false)
      }   
    }).catch(error => {
        console.log("error", error);
    })
  }
  
  var searchedBooths = [];
  var searchedBoothTexts = [];
  if(props.boothItem?.booth){
    searchedBooths.push(props.boothItem.booth)
    searchedBoothTexts.push(props.boothItem.booth?.booth_number)
  }
  
  var authInfo = props.main.authInfo

  console.log("categoryData", categoryData);
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'companyedit-dialog-title'}}
      aria-labelledby="companyedit-dialog-title"
      fullWidth={true}
      maxWidth={"md"}
      onClose={onClose} open={open}>
      <DialogTitle id='companyedit-dialog-title' style={{display:'flex', alignItems:'center'}}><SettingsIcon style={{marginRight: 10}}/>Company Profile</DialogTitle>
      <DialogContent>
        <Box pt={0}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label={<b>PROFILE</b>} {...a11yProps(0)} />
              {!props.boothItem.sub && false &&
              <Tab label={<b>SUB-EXHIBITOR</b>} {...a11yProps(1)} />
              }
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={2}><label><b>Category</b></label></Box>  
                <Box>
                  {
                    selectedTree.map((item, index)=>{
                      return (
                        <Chip key={index} style={{marginRight:10, marginBottom:10}} label={getCategoryName(item)} color="primary"/>
                      )
                    })
                  }
                </Box>
                <Box className="categoryBox"> 
                  <Box style={{width:'calc(100% - 50px)'}}>
                    <TreeView
                      defaultCollapseIcon={<ExpandMoreIcon className='collapseIcon' />}
                      defaultExpandIcon={<ChevronRightIcon className='collapseIcon'/>}
                      expanded={expandedTree}
                      // selected={selectedTree}
                      onNodeToggle={handleToggleTree}
                      // onNodeSelect={handleSelectTree}
                      // multiSelect
                    >
                      {
                        categoryData.SUBCAT.map((node) => RenderTreeWithCheckboxes(node))
                      }
                        
                    </TreeView>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mb={2}><label><b>Logo</b></label></Box>  
                <FormControl variant="outlined">
                  <input type="file" accept="image/*" onChange={onSelectLogo} />
                </FormControl>
                    
                {cropLogoPreviewData&&
                  <div className='preview-image'>
                    <img style={{ width: "100%" }} src={cropLogoPreviewData} alt="cropped" />
                  </div>
                }
              </Grid>
              
              <Grid item xs={12}>
                <Box><label><b>Description</b></label></Box>  
                <TextField                
                  name="company_description"
                  margin="dense"
                  // label="Description"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  value={formData.company_description?formData.company_description:''}
                  onChange={(e)=>changeFormData('company_description', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mb={2}><label><b>Photos</b></label></Box>  
                {formData.company_photos && formData.company_photos.length>0 &&
                <Carousel
                  infinite={false} 
                  responsive={responsive}
                >
                  {
                    formData.company_photos?.filter((item)=>{return item.ACTIVE*1 ==1})?.map((photo, index)=>{
                      return(
                        <div key={index} className='admin carousel-item' style={{position:'relative'}}>
                          <img key={index} src={assetUrl(photo.URL)}/>
                          <IconButton key={index} className="carousel-delbtn" size="small"
                            onClick={()=>{
                              var tmp_formData = {...formData};
                              var company_photos
                              if(tmp_formData.company_photos){
                                company_photos= [...formData.company_photos]
                              }
                              else{
                                company_photos = [];
                              }
                              var photoIndex;
                              for(var i = 0; i < company_photos?.length; i++){
                                if(company_photos[i].KEY_ID == photo.KEY_ID){
                                  photoIndex = i;
                                }
                              }
                              if(company_photos[photoIndex] && company_photos[photoIndex]['new']){
                                company_photos=[
                                  ...company_photos.slice(0, photoIndex),
                                  ...company_photos.slice(photoIndex + 1)
                                ]
                                tmp_formData = {...tmp_formData ,company_photos:company_photos}
                                setFormData(tmp_formData)
                              }
                              else{
                                company_photos[photoIndex]['ACTIVE'] = 0
                                tmp_formData = {...tmp_formData ,company_photos:company_photos}
                                setFormData(tmp_formData)
                              }
                            }}
                          ><DeleteForeverOutlinedIcon/></IconButton>
                        </div>
                      )
                    })
                  }
                </Carousel>
                }
                {1 &&
                  <Box>
                    <FormControl variant="outlined">
                      <input type="file" accept="image/*" onChange={onSelectPhoto} />
                    </FormControl>
                  </Box>
                }
                {/* {cropPhotoPreviewData&&
                  <div className='preview-image'>
                    <img src={cropPhotoPreviewData} alt="cropped" />
                  </div>
                }
                <Button variant="contained" size="small" onClick={()=>{handleAddPhoto()}} style={{marginTop:5}} startIcon={<AddIcon />}><b>Add Photo</b></Button> */}
              </Grid>
              
              <Grid item xs={12}>
                <Box mb={0}><label><b>Videos</b></label></Box> 
                {formData.company_videos && formData.company_videos.length>0 &&
                <Carousel
                  infinite={false} 
                  responsive={responsive}
                >
                  {
                    formData.company_videos?.filter((item)=>{return item.ACTIVE*1 ==1})?.map((video, index)=>{
                      return(
                        <div key={index} className='carousel-item' style={{position:'relative'}}>
                          <ReactPlayer key={index} controls className="video" url={video.URL} />
                          <IconButton key={index} className="carousel-delbtn" size="small"
                            onClick={()=>{
                              var tmp_formData = {...formData};
                              var company_videos
                              if(tmp_formData.company_videos){
                                company_videos= [...formData.company_videos]
                              }
                              else{
                                company_videos = [];
                              }
                              var videoIndex;
                              for(var i = 0; i < company_videos?.length; i++){
                                if(company_videos[i].KEY_ID == video.KEY_ID){
                                  videoIndex = i;
                                }
                              }
                              if(company_videos[videoIndex] && company_videos[videoIndex]['new']){
                                company_videos=[
                                  ...company_videos.slice(0, videoIndex),
                                  ...company_videos.slice(videoIndex + 1)
                                ]
                                tmp_formData = {...tmp_formData ,company_videos:company_videos}
                                setFormData(tmp_formData)
                              }
                              else if(company_videos[videoIndex] && !company_videos[videoIndex]['new']){
                                company_videos[videoIndex]['ACTIVE'] = 0
                                tmp_formData = {...tmp_formData ,company_videos:company_videos}
                                setFormData(tmp_formData)
                              }
                            }}
                          
                          ><DeleteForeverOutlinedIcon/></IconButton>
                        </div>
                      )
                    })
                  }
                </Carousel>
                }
              </Grid>
              
              <Grid item xs={6}>
                {1 &&
                  <Box>
                    {/* <FormControl variant="outlined">
                      <input type="file" accept="video/*" onChange={onSelectVideo} ref={videoRef}/>
                    </FormControl> */}
                    {/* <Divider>Or</Divider> */}
                    <TextField     
                      size="small"
                      name="videoUrl"
                      margin="dense"
                      label="Video Url"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={videoUrl}
                      onChange={(e)=>setVideoUrl(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                } 
                <Button variant="contained" size="small" onClick={()=>{handleAddVideo()}}  startIcon={<AddIcon />}><b>Add Video</b></Button>
              </Grid>
              <Grid item xs={12}>
                <Box><label><b>Company</b></label></Box> 
              </Grid>
              <Grid item xs={6}>
                <TextField                    
                  size="small"      
                  name="company_name"
                  margin="dense"
                  label="Company "
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_name?formData.company_name:''}
                  onChange={(e)=>changeFormData('company_name', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
              </Grid>
              <Grid item xs={6}>
                <TextField                    
                  size="small"      
                  name="company_contact_street"
                  margin="dense"
                  label="Address1"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_street?formData.company_contact_street:''}
                  onChange={(e)=>changeFormData('company_contact_street', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField                    
                  size="small"      
                  name="company_contact_address2"
                  margin="dense"
                  label="Address2"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_address2?formData.company_contact_address2:''}
                  onChange={(e)=>changeFormData('company_contact_address2', e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField                    
                  size="small"      
                  name="company_contact_city"
                  margin="dense"
                  label="City"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_city?formData.company_contact_city:''}
                  onChange={(e)=>changeFormData('company_contact_city', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField                    
                  size="small"      
                  name="company_contact_state"
                  margin="dense"
                  label="State"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_state?formData.company_contact_state:''}
                  onChange={(e)=>changeFormData('company_contact_state', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField                   
                  size="small"       
                  name="company_contact_zip"
                  margin="dense"
                  label="Zip"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_zip?formData.company_contact_zip:''}
                  onChange={(e)=>changeFormData('company_contact_zip', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField              
                  size="small"            
                  name="country"
                  margin="dense"
                  label="Country"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_country?formData.company_contact_country:''}
                  onChange={(e)=>changeFormData('company_contact_country', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box><label><b>Contact</b></label></Box> 
              </Grid>
              <Grid item xs={6}>
                <TextField         
                  size="small"         
                  name="company_contact_fn"
                  margin="dense"
                  label="First Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_fn?formData.company_contact_fn:''}
                  onChange={(e)=>changeFormData('company_contact_fn', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField          
                  size="small"                
                  name="last_name"
                  margin="dense"
                  label="Last Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_ln?formData.company_contact_ln:''}
                  onChange={(e)=>changeFormData('company_contact_ln', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField                       
                  size="small"   
                  name="email"
                  margin="dense"
                  label="Email"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_email?formData.company_contact_email:''}
                  onChange={(e)=>changeFormData('company_contact_email', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField                    
                  size="small"      
                  name="phone"
                  margin="dense"
                  label="Area Code"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_phone_ext?formData.company_contact_phone_ext:''}
                  onChange={(e)=>changeFormData('company_contact_phone_ext', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField                    
                  size="small"      
                  name="phone"
                  margin="dense"
                  label="Phone"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_contact_phone?formData.company_contact_phone:''}
                  onChange={(e)=>changeFormData('company_contact_phone', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              
              
              <Grid item xs={12}>
                <Box><label><b>Social media</b></label></Box>  
              </Grid>
              <Grid item xs={6}>
                <TextField                   
                  size="small"       
                  name="company_facebook"
                  margin="dense"
                  label="Facebook"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_facebook?formData.company_facebook:''}
                  onChange={(e)=>changeFormData('company_facebook', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField              
                  size="small"            
                  name="twitter"
                  margin="dense"
                  label="Twitter"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_twitter?formData.company_twitter:''}
                  onChange={(e)=>changeFormData('company_twitter', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <TextField                
                  size="small"          
                  name="company_snapchat"
                  margin="dense"
                  label="Snap Chat"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_snapchat?formData.company_snapchat:''}
                  onChange={(e)=>changeFormData('company_snapchat', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> */}
              <Grid item xs={6}>
                <TextField                
                  size="small"          
                  name="company_instagram"
                  margin="dense"
                  label="Instagram"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_instagram?formData.company_instagram:''}
                  onChange={(e)=>changeFormData('company_instagram', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField                
                  size="small"          
                  name="company_linkdin"
                  margin="dense"
                  label="LinkedIn"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_linkdin?formData.company_linkdin:''}
                  onChange={(e)=>changeFormData('company_linkdin', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />                
              </Grid>
              
              <Grid item xs={6}>
                  <TextField                
                    size="small"          
                    name="company_tiktok"
                    margin="dense"
                    label="TikTok"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formData.company_tiktok?formData.company_tiktok:''}
                    onChange={(e)=>changeFormData('company_tiktok', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              
              <Grid item xs={12}>
                
                <Box mb={2}><label><b>Call to action</b></label></Box>  
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField              
                      size="small"            
                      name="company_call_label"
                      margin="dense"
                      label="Label"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={formData.company_call_label?formData.company_call_label:''}
                      onChange={(e)=>changeFormData('company_call_label', e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField           
                      size="small"               
                      name="linkedin"
                      margin="dense"
                      label="Link"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={formData.company_call_link?formData.company_call_link:''}
                      onChange={(e)=>changeFormData('company_call_link', e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                
              </Grid>
              <Grid item xs={12}>
                <Box mb={2}><label><b>Website</b></label></Box>  
                <TextField              
                  size="small"            
                  name="company_website"
                  margin="dense"
                  label="Website url"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.company_website?formData.company_website:''}
                  onChange={(e)=>changeFormData('company_website', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box mb={2}><label><b>Header image</b></label></Box> 
                <FormControl variant="outlined" fullWidth>
                  <input type="file" accept="image/*" onChange={onSelectHeaderImage} />
                </FormControl> 
                {cropHeaderImgPreviewData&&
                  <div className='preview-contain-image' style={{position:'relative'}}>
                    <img style={{ width: "100%" }} src={cropHeaderImgPreviewData} alt="cropped" />
                    <IconButton className="carousel-delbtn" size="small"
                    onClick={()=>{
                      var tmp_formData = {...formData};
                      tmp_formData = {...tmp_formData ,company_headerimg_url:null}
                      setFormData(tmp_formData)
                      setCropHeaderImgData(null)
                      setCropHeaderImgPreviewData(null)
                    }}
                  
                  ><DeleteForeverOutlinedIcon/></IconButton>
                  </div>
                }
              </Grid>
              <Grid item xs={6}>
                <Box mb={2}><label><b>Profile background</b></label></Box>  
                <FormControl variant="outlined" fullWidth>
                  <input type="file" accept="image/*" onChange={onSelectProfileBg} />
                </FormControl> 
                {cropProfileBgPreviewData&&
                  <div className='preview-contain-image' style={{position:'relative'}}>
                    <img style={{ width: "100%" }} src={cropProfileBgPreviewData} alt="cropped" />
                    <IconButton className="carousel-delbtn" size="small"
                      onClick={()=>{
                        var tmp_formData = {...formData};
                        tmp_formData = {...tmp_formData ,company_bgimg_url:null}
                        setFormData(tmp_formData)
                        setCropProfileBgData(null)
                        setCropProfileBgPreviewData(null)
                      }}
                  
                    ><DeleteForeverOutlinedIcon/></IconButton>
                  </div>
                }
              </Grid>
              {authInfo.show_three_icon !='hide'&&
              <Grid item xs={12}>
                <Box mb={2}><label><b>3D model</b></label></Box>  
                <FormControl variant="outlined" fullWidth>
                  <input type="file" onChange={onSelectThreeModel} />
                </FormControl>
                {formData.company_threemodel_name && 
                <Box style={{padding:'10px 5px', display:'flex', alignItems:'center'}}>
                  <label>{formData.company_threemodel_name}</label>
                  <IconButton  size="small" style={{marginLeft:10}}
                      onClick={()=>{
                        var tmp_formData = {...formData};
                        tmp_formData = {...tmp_formData ,company_threemodel_name:null}
                        setFormData(tmp_formData)
                      }}
                  
                    ><DeleteForeverOutlinedIcon/></IconButton>
                </Box>
                }
                {/* {cropProfileBgPreviewData&&
                  <div className='preview-contain-image'>
                    <img style={{ width: "100%" }} src={cropProfileBgPreviewData} alt="cropped" />
                  </div>
                } */}
              </Grid>
              }
              <Grid item xs={12}>
                <Box mb={2}><label><b>Special Tags</b></label></Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox     
                        value = {true}                     
                        checked={formData.company_tag_featured?formData.company_tag_featured:false}
                        onChange={(e)=>changeFormData('company_tag_featured', !formData.company_tag_featured)}
                        name="company_tag_featured" 
                      />
                    }
                    label="Featured"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox     
                        value = {true}                     
                        checked={formData.company_tag_member?formData.company_tag_member:false}
                        onChange={(e)=>changeFormData('company_tag_member', !formData.company_tag_member)}
                        name="company_tag_member" 
                      />
                    }
                    label="Member"
                  />
                  {authInfo.show_three_icon !='hide'&&
                  <FormControlLabel
                    control={
                      <Checkbox     
                        value = {true}                     
                        checked={formData.company_tag_3dbooth?formData.company_tag_3dbooth:false}
                        onChange={(e)=>changeFormData('company_tag_3dbooth', !formData.company_tag_3dbooth)}
                        name="company_tag_3dbooth" 
                      />
                    }
                    label="3D Booth"
                  />
                  }
                  <FormControlLabel
                    control={
                      <Checkbox     
                        value = {true}                     
                        checked={formData.company_tag_sponser?formData.company_tag_sponser:false}
                        onChange={(e)=>changeFormData('company_tag_sponser', !formData.company_tag_sponser)}
                        name="company_tag_sponser" 
                      />
                    }
                    label="Sponsor"
                  />
                  <FormControl variant="outlined" fullWidth style={{width:'140px'}}>
                    <InputLabel shrink={true}>Level</InputLabel>
                    <Select
                      input={<OutlinedInput notched label="Level" />}
                      value={formData.company_tag_level?formData.company_tag_level:''}
                      onChange={(e)=>changeFormData('company_tag_level', e.target.value)}
                    >
                      <MenuItem value={''}>&nbsp;</MenuItem>
                      <MenuItem value={'Gold'}>Gold</MenuItem>
                      <MenuItem value={'Diamond'}>Diamond</MenuItem>
                      <MenuItem value={'Platinum'}>Platinum</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {formData.exhibitor_type != 'CoExhibitor' &&
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox     
                        value = {true}                     
                        checked={formData.booth_space_reseller?formData.booth_space_reseller:false}
                        onChange={(e)=>changeFormData('booth_space_reseller', !formData.booth_space_reseller)}
                        name="booth_space_reseller" 
                      />
                    }
                    label="Booth Space Reseller"
                  />
                </Box>
                }
              </Grid>              
            </Grid>
          </TabPanel>
          {false&&
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box>                  
                  {formData.co_companies &&
                    <Box mt={0}>
                      <Table className="pricing-table" sx={{ minWidth: 650 , marginTop:'0px'}} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {/* <TableCell>&nbsp;</TableCell> */}
                            <TableCell width="10%">No</TableCell>
                            <TableCell width="100%">Company Name</TableCell>
                            {/* <TableCell width="20%">Action</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {exhibitorEshow?.SUBEXHIBITORS?.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell sx={{paddingTop: '12px', paddingBottom:'12px'}}>{index+1}</TableCell>
                              <TableCell sx={{paddingTop: '12px', paddingBottom:'12px'}}>{row.COMPANY_NAME}</TableCell>
                              {/* <TableCell sx={{paddingTop: '4px', paddingBottom:'4px'}}>
                                <Button sx={{marginTop: '10px'}} variant="text" onClick={()=>{
                                  let newArray = formData.co_companies.slice()
                                  newArray.splice(index, 1)
                                  changeFormData('co_companies', newArray)
                                }}  >remove</Button>
                              </TableCell> */}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  }

                  <Button sx={{marginTop: '40px'}} variant="text" onClick={()=>{setOpenCoCompanyModal(!openCoCompanyModal)}}  startIcon={<AddIcon />}><b>Create New Co-Exhibitor</b></Button>
                  {openCoCompanyModal&&
                    <SubExhibitorModal
                        open={openCoCompanyModal}
                        onClose={()=>{setOpenCoCompanyModal(false)}}
                        onOk={handleOkCoCompanyModal}
                        boothObjects = {searchedBooths}
                        boothTexts = {searchedBoothTexts}
                        groupItems = {(val)=>{}}
                        setGrouppingBooth={()=>{}}
                    />
                  }
                  {/* {openCoCompanyModal &&
                  <CoCompanyModal
                      open={openCoCompanyModal}
                      onClose={handleCloseCoCompanyModal}
                      onOk={confirmCoCompanyModal}
                      co_companies = {formData.co_companies}
                      setBoothCoCompanies = {
                        (co_companies)=>{
                          changeFormData('co_companies', co_companies)
                        }
                      }
                  />
                  } */}
                  <Box style={{marginBottom: 80}}></Box>

                </Box>
              </Grid>
              
            </Grid>
          </TabPanel>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      <CropModal
          open={openCropModal}
          onClose={handleCloseCropModal}
          onOk={confirmCropModal}
          initialAspectRatio={initialAspectRatio}
          imgSrc={imgSrc}
      />
      
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setLoading:  data=>dispatch(setLoading(data)),
      setBoothHistory:  data=>dispatch(setBoothHistory(data)),
      setGlobalOpenSnack:  data=>dispatch(setGlobalOpenSnack(data)),
      setGlobalSnackMsg:  data=>dispatch(setGlobalSnackMsg(data)),
      setExhibitorUpdated:  data=>dispatch(setExhibitorUpdated(data)),
      setSnackMsg: data=>dispatch(setSnackMsg(data)),
      setOpenSnack: data=>dispatch(setOpenSnack(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(CompanyEditModal);