/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "../fabrictool";
// import { setCanvasRefresh } from '../../../../../actions/main'
// import { setBoothHistory } from '../../../../../actions/booth_history'
import { getSnapPosition, addText, getFirstZoom } from '../../../../../utils/CanvasTool'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import { getDefaultGroup } from '../../../../../utils/Common'

const fabric = require("fabric").fabric;

// function findTopPaddingForRoof(roofPoints) {
//   var result = 999999;
//   for (var f = 0; f < lineCounter; f++) {
//       if (roofPoints[f].y < result) {
//           result = roofPoints[f].y;
//       }
//   }
//   return Math.abs(result);
// }

// function findLeftPaddingForRoof(roofPoints) {
//   var result = 999999;
//   for (var i = 0; i < lineCounter; i++) {
//       if (roofPoints[i].x < result) {
//           result = roofPoints[i].x;
//       }
//   }
//   return Math.abs(result);
// }

class Booth extends FabricCanvasTool {
  lines = [];
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this.props = props
    
  }

  doMouseDown(o) {
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);
    // if(!this.isDown)
    if(this.lines.length > 1)
    {
      console.log('this.lines', this.lines);
      var line0 = this.lines?.[0]
      if(line0){
        var x1 = line0.x
        var y1 = line0.y
        
        var a = line0.x - pointer.x;
        var b = line0.y - pointer.y;
        var d1 = Math.sqrt( a*a + b*b );

        var radius = 5*3/Math.max(canvas.convertRateX, 1)/getFirstZoom(canvas);
        console.log('d1', d1);
        if(d1< radius){
          this.lines.push({ x: line0.x, y: line0.y });
          this.line.set({points: this.lines})
          this.isDown = false
          console.log("exit 1")
          if(this.circlePoint){
            canvas.remove(this.circlePoint);
          }

          this.endDraw()
          this.resetMouse()
          this.props.setCanvasRefresh(true)

          canvas.renderAll();
          return;
        }
        
        // a = this.lines[i].x2 - pointer.x;
        // b = this.lines[i].y2 - pointer.y;
        // var d2 = Math.sqrt( a*a + b*b );
        // if(d2< 20){
        //   this.line.set({ x2: this.lines[i].x2, y2: this.lines[i].y2 });
        //   canvas.renderAll();
        //   console.log("exit 2")
        //   this.isDown = false
        //   return;
        //   break;
        // }      
      }
    }

    this.isDown = true;
    if(!this.isMovePushed){  
      var newPoint = pointer
      if(canvas.snapToGrid)
        newPoint = getSnapPosition(canvas, pointer)
      var points = [{x: newPoint.x,y:newPoint.y}];
      var strokeWidth = 3/Math.max(canvas.convertRateX, 1)/getFirstZoom(canvas)

      this.line = new fabric.Polyline(points, {
        strokeWidth: strokeWidth,
        stroke: this._color,
        fill:'transparent',
        originX: "center",
        originY: "center",
        selectable: false,
        evented: false,
      });
      this.lines.push({x: newPoint.x,y:newPoint.y})
      canvas.add(this.line);
    }
    this.isMovePushed = false;
    {    
      this.isDown = true
    }
    
  }

  doMouseMove(o) {
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);
    // if (!this.isDown) 
    {
      var line0 = this.lines?.[0]
      if(line0){
        var x1 = line0.x
        var y1 = line0.y
        
        var a = line0.x - pointer.x;
        var b = line0.y - pointer.y;
        var d1 = Math.sqrt( a*a + b*b );

        var radius = 5*3/Math.max(canvas.convertRateX, 1)/getFirstZoom(canvas);
        var strokeWidth = 3/Math.max(canvas.convertRateX, 1)/getFirstZoom(canvas)
        // var convertRateX= Math.abs(canvas.convertRateX)
        // if(convertRateX){
        //   radius/= convertRateX
        // }

        if(d1< radius){
          if(this.circlePoint){
              canvas.remove(this.circlePoint);
              console.log("remove existing")
          }

          this.circlePoint = new fabric.Circle({
            radius: radius,
            stroke: "black",
            strokeWidth: strokeWidth,
            fill:'transparent',
            left: line0.x,
            top: line0.y,
            selectable: false,
            originX: "center",
            originY: "center",
            hoverCursor: "auto"
          });
          canvas.add(this.circlePoint);
          console.log("add")
          canvas.renderAll();
        }
        else{
          if(this.circlePoint){
            if(this.circlePoint.left==line0.x && this.circlePoint.top==line0.y){
              canvas.remove(this.circlePoint);
              console.log("remove")
              this.circlePoint = null;
              canvas.renderAll();
            }
          }
        }
        
        
      }

    }
    // else
    if (this.isDown)
    {
      var newPoint = pointer
      if(canvas.snapToGrid)
        newPoint = getSnapPosition(canvas, pointer)
      // this.line.set({ x2: newPoint.x, y2: newPoint.y });
      // this.line.setCoords()
      if(this.isMovePushed){
        if(this.lines?.length > 0){
          var tmp = { x: newPoint.x, y: newPoint.y };
          this.lines[this.lines?.length-1] = { ...tmp }
        }
      }
      else{
        var tmp = { x: newPoint.x, y: newPoint.y };
        this.lines.push({ ...tmp });
      }
      this.isMovePushed = true
      this.line.set({points:this.lines})
      console.log("this.lines", this.lines)
      // this.line.setCoords()
    };
    canvas.renderAll();
  }

  doMouseUp(o) {
    // this.isDown = false;
  }

  doMouseOut(o) {
    // this.isDown = false;
  }
  resetMouse=()=>{
    let canvas = this._canvas;
    canvas.off("mouse:down");
    canvas.off("mouse:move");
    canvas.off("mouse:up");
    canvas.off("mouse:out");
  }

  endDraw=()=>{
    let canvas = this._canvas;
    var points = [];
    if(this.lines.length >0){
      canvas.remove(this.line)
     
      var bgColor = "#dadada"
      var element = new fabric.Polygon(
        this.lines, {
          class:"outline",
          fill: bgColor, 
          stroke: 'black',
          strokeWidth: 3/Math.max(canvas.convertRateX, 1),
          borderColor: 'black',
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
      });
      this.lines = [];
      this.isMovePushed = false

      
      var boothId = "G_"+parseInt(Math.random()*(10000))
      var group = new fabric.Group([element]).set({
        width: element.width,
        height: element.height,
        id: 'booth-'+boothId,
        class: 'booth',
        boothGroup: getDefaultGroup(this.props.main.groupOptions),
        class1 : 'custom-booth',
        preserveObjectStacking: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        booth_number: "B"
      });	
      canvas.add(group);

      // group.add(element);

      // element.clone(function(cloned) {
      //   group.clipPath = cloned                                    
      // });
      
      addText(canvas, group, "B")
      group.set({
        borderColor: '#ff7777',
        cornerColor: 'red',
        cornerSize: 6,
        transparentCorners: false
      });

      canvas.setActiveObject(group)
      var newBooth = {
        id: boothId,
        boothName : "B"
      }
      const newBoothList = [...this.props.main.boothList, newBooth]
      this.props.setBoothList(newBoothList);
      group.new_created = true; 
      group.uniq_id = Date.now() + parseInt(Math.random()*10000000000)  
      group.KEY_ID = uuidv4()
      this.props.setBoothHistory({boothList:newBoothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[group]}})
      this.props.onDrawDone(group) 
      canvas.renderAll();

    }

  }

}



export default Booth;