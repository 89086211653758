/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "../fabrictool";
import { linearDistance } from "../../utils";
import { v4 as uuidv4 } from 'uuid';
import { getSnapPosition, addText, getFirstZoom } from '../../../../../utils/CanvasTool'

const fabric = require("fabric").fabric;

class Circle extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.props = props
  }

  doMouseDown(o) {
    let canvas = this._canvas;
    this.isDown = true;
    let pointer = canvas.getPointer(o.e);
    [this.startX, this.startY] = [pointer.x, pointer.y];
    var strokeWidth = 1/getFirstZoom(canvas)
    this.circle = new fabric.Circle({
      left: this.startX,
      top: this.startY,
      originX: "left",
      originY: "center",
      strokeWidth: strokeWidth,
      stroke: this._color,
      fill: this._fill,
      selectable: false,
      evented: false,
      radius: 1,
      layer:'annotation',
      class1: 'circle'
    });
    this.circle.set({id:Date.now()})    
    canvas.add(this.circle);
    this.props.setBoothHistory({boothList:this.props.main.boothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[this.circle]}})
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    let canvas = this._canvas;
    let pointer = canvas.getPointer(o.e);
    this.circle.set({
      radius:
        linearDistance(
          { x: this.startX, y: this.startY },
          { x: pointer.x, y: pointer.y }
        ) / 2,
      angle:0,
    });
    this.circle.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;let canvas = this._canvas;

    canvas.remove(this.circle);
    let circle = new fabric.Group([this.circle]).set({      
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true
    });
    circle.set({
      layer:'annotation',
      class1: 'circle'
    })
    circle.set({id:Date.now()})   
    canvas.add(circle);
    
    var convertRateX= 1
    if(canvas.convertRateX) {
        var convertRateX= Math.abs(canvas.convertRateX)
    }
    var f_text = new fabric.Text("", {              
      left: 0-circle.width/2, 
      top:0-circle.height/2,
      fontSize:30/convertRateX,
      class:'title'
    });
    circle.add(f_text);
    circle.new_created = true;
    circle.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
    circle.KEY_ID = uuidv4()
    this.props.setBoothHistory({boothList:this.props.main.boothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[circle]}})
    this.props.onDrawDone(circle)
  }
}

export default Circle;
