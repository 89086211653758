import React ,{ useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { connect } from 'react-redux'
import { setMainLayers, setShowGrid, setBoothNumberLayer, setBoothOutlineLayer, setAnnotationLayer, setOriginalLayer, setExhibitorList } from '../../../actions/main.js'
import { setLeftPage, setLeftSelectedCategory } from '../../../actions/ui.js'
import { setOpenLogin, setLeftBoothList, setBookmardUpdated, setOpenBoothList, setOpenBoothDrawer, setExhibitorSearchKey } from '../../../actions/ui.js'
import { setLoading } from '../../../actions/main.js'
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

function Index(props) {
  const [searchVal, setSearchVal] = React.useState("");
  const htmlElRef = React.useRef(null)


  const doSearch = (searchVal)=>{    
    return;
    if(!props.ui.openBoothList)
      props.setOpenBoothDrawer(true)
    props.setExhibitorSearchKey(searchVal)
    if(searchVal){
      // var filteredBoothList = boothList.filter((booth)=>{
      //   var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
      //   return (str.toLowerCase().includes(searchVal.toLowerCase()))
      // })
      // props.setLeftBoothList(filteredBoothList)
      props.setLeftPage('searched')
    }
    else{
      // props.setLeftBoothList([])
    }
  }


  return (      
      <>
        <Box className='xhbt-MuiBox-root search-div' textAlign='left'>
          
          {props.ui.openBoothList?
          <>
            <InputBase
                sx={{ ml: 0, mr:0, flex: 1, border: '1px solid #e3e3e3', padding: '3px 7px', borderRadius: '3px', borderRadius: '24px'}}
                placeholder="Search company or booth"
                inputProps={{ 'aria-label': 'search booth' }}
                value={props.ui.exhibitorSearchKey}
                ref={htmlElRef}
                autofocus={true}

                // onBlur={
                //     (e) => {
                //       props.setExhibitorSearchKey(e.target.value)
                //     }
                // }
                // onKeyDown={
                //     (e)=>{
                //         if(e.keyCode == 13){
                //             e.preventDefault();
                //             // doSearch(e.target.value)
                //         }
                //     }
                // }
                onChange={
                  (e)=>{
                    props.setExhibitorSearchKey(e.target.value)
                    // if(e.target.value == "" || !e.target.value){
                    //   doSearch(e.target.value)
                    // }
                  }
                }
            />            
            {/* <Button className={`${classes.searchBtn} search-attach` } onClick={()=>{doSearch(searchVal)}}  variant="contained" color="primary" sx={{ p: '7.5px', mr:1, width:'70px' }} aria-label="directions">
              Search
            </Button> */}
            <IconButton className={`search-inside` } size="small" type="button"
              onClick={()=>{doSearch(searchVal)}} aria-label="search" >
                  <SearchIcon />
              </IconButton>
            <IconButton className={`close-inside` } size="small" type="button"
              onClick={()=>{props.setExhibitorSearchKey("")}} aria-label="search" >
                  <CloseIcon />
            </IconButton>
          </>
          : <Box style={{height: 40}}></Box>
          }
        </Box>
      </>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
      cart: state.cart,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) ,
        setShowGrid: value=>dispatch(setShowGrid(value)) ,
        setBoothNumberLayer: value=>dispatch(setBoothNumberLayer(value)) ,
        setBoothOutlineLayer: value=>dispatch(setBoothOutlineLayer(value)) ,
        setAnnotationLayer: value=>dispatch(setAnnotationLayer(value)) ,
        setOriginalLayer: value=>dispatch(setOriginalLayer(value)) ,
        setLeftPage: value=>dispatch(setLeftPage(value)) ,
        setExhibitorSearchKey: value=>dispatch(setExhibitorSearchKey(value)) ,
        setLoading:  data=>dispatch(setLoading(data)),
        setLeftSelectedCategory: value=>dispatch(setLeftSelectedCategory(value)),
        setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
        setBookmardUpdated:   data=>dispatch(setBookmardUpdated(data)),
        setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
        setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
        setOpenBoothDrawer: value=>dispatch(setOpenBoothDrawer(value)) ,
        setExhibitorList:  data=>dispatch(setExhibitorList(data))
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);