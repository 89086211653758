import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';

const drawerWidth = 190;


const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'left',
  marginTop: 12
}));

function ElementBox(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const setActiveObject = (id) => {
    var mainCanvas = props.main.mainCanvas;
    mainCanvas.getObjects().forEach(function(o) {
      if(o.id == "booth-"+id) {
        mainCanvas.setActiveObject(o).renderAll();;
      }      
    })
  }

  return (
    <Box className="siderightview" sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
        className="booth-list-slider"
      >
        <div>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <h4>COMPONENTS</h4>
        </div>
        <Divider />
         <div className="toolbar">
              <div className="furniture" style={{marginBottom:10}}>
                  <img id="10_10" draggable="true" src="/10x10.png"/>
              </div>
              <div className="furniture" style={{marginBottom:10}}>
                  <img id="10_20" draggable="true" src="/10x20.png"/>
              </div>
              <div className="furniture" style={{marginBottom:10}}>
                  <img id="tool-text" draggable="true" src="/text.png"/>
              </div>
          </div> 
      </Drawer>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 0, ...(open && { display: 'none' }) }}
      >
        <AccountTreeIcon />
      </IconButton>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
  };
};

export default connect(mapStateToProps, mapDispatchProps)(ElementBox);
