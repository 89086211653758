import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';
import { setLeftSearchVal, setOpenBoothList, setLeftPage, setOpenCompanyModal, setEditCompany } from '../../../actions/ui'
import InputBase from '@mui/material/InputBase';
import EditIcon from '@mui/icons-material/Edit';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Button from '@mui/material/Button';
import CompanyEditModal from '../CompanyEditModal'
import { useEffect,useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Fade from '@mui/material/Fade';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setActive3dObject } from '../../../actions/main';


const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  width: '38px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

function BoothList(props) {
  const theme = useTheme();
  const [boothList, setBoothList] = React.useState([]);
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [filteredBoothList, setFilteredBoothList] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState(null);
  
  
  const [editBooth, setEditBooth]  = React.useState(null);
  const main_widow_offset_x = 0;
  const main_widow_offset_y = 51;
  

  useEffect(() => {
    if(props.ui.openBoothList){      
      setLoadingFlag(true)
      setTimeout(function(){
        if(props.main.mainCanvas){
          var boothList = [];
          var objects = props.main.mainCanvas.getObjects();
          for(var i = 0; i< objects.length; i++){
            var object = objects[i]
            if(object.get('class')=="booth-group"){
              var parent_booth_number = object.booth_number
              var sub_objects = object.getObjects('group');
              for(var j = 0; j < sub_objects.length; j++){
                var row = {}
                var sub_object = sub_objects[j];
                if(sub_object.get('class')=="booth"){
                  if(parent_booth_number != sub_object.booth_number){
                    row['name'] = sub_object.booth_number
                    boothList.push(row)
                  }
                }
              }
            }
          }
        }
      
        boothList.sort(function(a, b) {
          const nameA = a.name; // ignore upper and lowercase
          const nameB = b.name; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });
        boothList.sort(function(a, b) {
          return a.name - b.name;
        });

        if(searchVal){
          var filteredBoothList = boothList.filter((booth)=>{
            var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
            return (str.toLowerCase().includes(searchVal.toLowerCase()))
          })
          setFilteredBoothList(filteredBoothList)
        }
        else{
          setFilteredBoothList(boothList)
        }

        setBoothList(boothList)
        
        setLoadingFlag(false)
      }, 300)
    }
  }, [props.ui.openBoothList,props.main.canvasRefresh])


  const doSearch = (searchVal)=>{
    if(searchVal){
      var filteredBoothList = boothList.filter((booth)=>{
        var str = (booth.company ?(` (${booth.company})`):"")+(booth.name?booth.name:"")
        return (str.toLowerCase().includes(searchVal.toLowerCase()))
      })
      setFilteredBoothList(filteredBoothList)
    }
    else
      setFilteredBoothList(boothList)
  }


  const handleOpenCompanyModal=(index)=>{
    props.setEditCompany(filteredBoothList[index])
    props.setOpenCompanyModal(true)
    
  }



  // var boothList=[...props.main.boothList]
  // boothList.sort(function(a, b) {
  //   const nameA = a.boothName; // ignore upper and lowercase
  //   const nameB = b.boothName; // ignore upper and lowercase
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  
  //   // names must be equal
  //   return 0;
  // });
  // boothList.sort(function(a, b) {
  //   return a.boothName - b.boothName;
  // });

  return (
    <Fade in={true} timeout={1200}>
      <div className='appLeftSearch'>
        <Box className="">
            <Box display="flex" pt={2} pb={2} style={{width:'100%', justifyContent:'space-between'}}>
                
                <div style={{marginLeft:20}}></div>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search company or booth"
                    inputProps={{ 'aria-label': 'search booth' }}
                    // value={searchVal}
                    onBlur={
                        (e) => {
                            setSearchVal(e.target.value)
                        }
                    }
                    onKeyDown={
                        (e)=>{
                            if(e.keyCode == 13){
                                e.preventDefault();
                                doSearch(e.target.value)
                            }
                        }
                    }
                    onChange={
                      (e)=>{
                        if(e.target.value == "" || !e.target.value){
                          doSearch(e.target.value)
                        }
                      }
                    }
                />
                <IconButton size="small" type="button" onClick = {()=>doSearch(searchVal)} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Box>
            <Divider />
            {filteredBoothList&&
            <List>
              {filteredBoothList.map((booth, index) => (
                <>
                  {booth.name?
                    <ListItem button key={index} 
                      secondaryAction={
                        <>
                          {
                            booth.company && window.location.href.includes("/admin")?
                            <IconButton edge="end" onClick={()=>handleOpenCompanyModal(index)}>
                              {
                                <BorderColorOutlinedIcon/>
                              }
                            </IconButton>:
                            <></>
                          }
                        </>
                      }
                    >            
                      <ListItemText 
                        primary={
                          <Box display="flex">
                            <Box className="leftPrimaryTxt" style={{width:'80%'}}>
                              {(booth.name?booth.name:"")+(booth.company?(` (${booth.company})`):"")}
                            </Box>
                            {/* <Box pl={1} style={{width:'20%', fontSize:14.5, color: "#505050"}}>
                              <b>{(booth.name?booth.name:"")}</b>
                            </Box> */}
                          </Box>
                        } 
                      />
                    </ListItem>:
                    <></>
                  }
                </>
              ))}
            </List>
            }
            
            <LoadingOverlay
                active={loadingFlag}
                styles={{
                    // spinner: (base) => ({
                    // ...base,
                    // width: '50px',
                    // '& svg circle': {
                    //     stroke: '#1976d2'
                    // }
                    // }),
                    overlay: (base) => ({
                    ...base,
                    fontSize:'18px',
                    color: 'rgb(5, 37, 51)',
                    // background: 'rgb(229 229 229 / 92%)',
                    background: 'transparent',
                    position:'absolute',
                    left:'50%',
                    top:'200px',
                    transform:'translateX(-50%)',
                    zIndex:1000000000
                    })
                }}
                spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
                // spinner
                // text='Loading ...'
                >
            </LoadingOverlay> 
        </Box>
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setLeftSearchVal: value=>dispatch(setLeftSearchVal(value)) ,
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
    setOpenCompanyModal:  data=>dispatch(setOpenCompanyModal(data)),
    setEditCompany: value=>dispatch(setEditCompany(value))
  };
};

export default connect(mapStateToProps, mapDispatchProps)(BoothList);
