import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { store } from "./store"
import axios from 'axios';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

axios.defaults.baseURL = `https://${process.env.REACT_APP_site}/api/`


// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(config => {
  // Check if the URL contains a token
  const url = new URL(config.url, window.location.origin);
  const token = url.searchParams.get('token');

  if (token) {
    // Remove the token from the URL
    url.searchParams.delete('token');
    config.url = url.toString();

    // Add the token to the headers
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, error => {
  // Handle the error
  return Promise.reject(error);
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store} axiosInstance={axiosInstance}>
      <App/>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
