import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setPlanner } from '../../actions/main.js'
import { setOpenError } from '../../actions/ui.js'
import { NavLink } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import { parseTokenValue, getUrlToken } from '../../utils/Common.js'
import { getTokenCookie, setTokenPlanner } from '../../utils/Common.js'
import {PaperComponent} from '../../utils/Common.js'

import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function Index(props) {
  const { onClose, onOk, onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [formValue, setFormValue] = React.useState({});
  const [processing1, setProcessing1] = React.useState(false);
  const [processing2, setProcessing2] = React.useState(false);
  let query = useQuery();
  let clickedButton = '';
  useEffect(() => {
  }, [])

  const onChangeValue = (e)=>{
    setFormValue({...formValue, [e.target.name]: e.target.value});
  }
  
  return (
    <Dialog
      className='top-modal'
      fullWidth={true}
      maxWidth={"sm"} onClose={()=>{props.setOpenError(false);}} open={props.ui.error}>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        <Box style={{}}>
          <IconButton style={{float:'right', padding:5}} color="inherit" onClick={()=>{props.setOpenError(false);}} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" gutterBottom style={{textAlign: 'center', fontSize: '25px',color: '#1565c0'}}>
            Error
          </Typography>
        </Box>
        <Box textAlign={'center'} pt={3} pb={3}>
          <Box className='error-content'>
            {props.ui.error}
          </Box>
        </Box>
        <Box mt={2} textAlign='center' sx={{display:'flex', alignItems:'center', justifyContent:'end'}}>
          <Button variant="contained" color="primary" size="large" onClick={()=>{props.setOpenError(false);}}>
            Ok
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setOpenError: data=>dispatch(setOpenError(data)) ,
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);