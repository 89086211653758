import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Typography from '@mui/material/Typography';
import ListIcon from '@mui/icons-material/List';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setBoothFileList, resetMain } from '../../actions/main'
import { resetUI } from '../../actions/ui'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
const emails = ['username@gmail.com', 'user02@gmail.com'];
const MenuButton = styled(Button)(({ theme }) => ({
  color: '#4E7090',
  padding:'10px',
  width:'100%',
  textAlign:'left',
  justifyContent:'start',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#4E7090',
    color:"white"
  },
}));

function BoothImportModal(props) {
  const { onClose, selectedValue, open } = props;
  var boothFileList = props.main.boothFileList;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    // onClose(value);
    
    const active_booth_file = props.main.active_booth_file
    if(active_booth_file && active_booth_file.KEY_ID == value){
      return
    }
    var tmp = [...boothFileList];
    for(var i = 0; i <tmp.length; i++){
        if(tmp[i]['KEY_ID'] == value){
            tmp[i] = {...tmp[i], active:!tmp[i]['active']};
        }
        else{
          tmp[i] = {...tmp[i], active:false}
        }
    }
    props.resetMain();
    props.resetUI();
    // props.main.mainCanvas.off('object:moving')
    setTimeout(function(){
      props.setBoothFileList(tmp)
    }, 300)
    handleClose()
  };

  return (
    // <Dialog onClose={handleClose} open={open}>
    //   <DialogTitle style={{display:'flex', alignItems:'center'}}><FolderOpenIcon style={{marginRight: 10}}/> Select Booth File</DialogTitle>
    //   <List sx={{ pt: 0 }}>
    //     {boothFileList.map((boothFile) => (
    //       <ListItem button onClick={() => handleListItemClick(boothFile['id'])} key={boothFile['id']}>
    //         <ListItemText primary={<span style={boothFile['active']?{color:'green', fontWeight:'bold'}:{}}>{boothFile['filename']}</span>} />
    //       </ListItem>
    //     ))}
    //   </List>
    // </Dialog>
    <Menu
        id="dropdown-boot-open"
        anchorEl={props.anchorEl}
        open={open}
        onClose={props.onClose}
        MenuListProps={{
        'aria-labelledby': 'dropdown-booth-import-button',
        }}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: 'top'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'top'
        }}
        PaperProps={{
          onMouseEnter: () => {
            props.onOpen();
          },
          onMouseLeave: () => {
            props.onClose();
          }
      }}
      sx={{zIndex:1005}}
    >
      {boothFileList.map((boothFile, index) => (
        <MenuItem key={index} sx={{padding:0}}>
            <MenuButton
                size="small"
                edge="start"
                color="success"
                aria-label="assign"
                onClick={() => handleListItemClick(boothFile['KEY_ID'])} 
                key={index}
            >
                <span style={boothFile['active']?{color:'green', fontWeight:'bold'}:{}}>{boothFile['TITLE']}</span>
            </MenuButton>
        </MenuItem>
      ))}
    </Menu>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setBoothFileList: data=>dispatch(setBoothFileList(data)), 
      resetUI: data=>dispatch(resetUI(data)), 
      resetMain: data=>dispatch(resetMain(data)) 
      
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(BoothImportModal);