import * as React from 'react';
import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { setMainLayers, setLoading } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { SwatchesPicker , PhotoshopPicker  } from 'react-color'
import rgbHex from "rgb-hex";
import axios from 'axios';
import qs, { parse } from 'qs'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

import Divider from '@mui/material/Divider';
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#b71c1cff',
  '&:hover': {
    backgroundColor: 'red',
  },
}));

function Colorboard(props) {
  const { color } = props;

  return (
    <div style={{backgroundColor:color, width:'100%', height:'100%'}}
    >      
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function PriceModal(props) {
  const { onClose,  onChange, open } = props;
  var mainLayers = props.main.mainLayers;

  const [tabValue, setTabValue] = React.useState(0);
  const [unit, setUnit] = React.useState("feet");
  const [grid, setGrid] = React.useState(0);
  const [realGrid, setRealGrid] = React.useState(0);
  const [mapWidth, setMapWidth] = React.useState(0);
  const [mapHeight, setMapHeight] = React.useState(0);
  const [realMapWidth, setRealMapWidth] = React.useState(0);
  const [realMapHeight, setRealMapHeight] = React.useState(0);
  const [turnOnGrid, setTurnOnGrid] = React.useState(false);
  const [angle, setAngle] = React.useState(0);
  const [angleValue, setAngleValue] = React.useState(0);
  const [openAdd, setOpenAdd]  = React.useState(false);
  const [openEdit, setOpenEdit]  = React.useState(false);
  
  const [boothGroups , setBoothGroups] = React.useState([]);
  const [openAddPriceSaleItem, setOpenAddPriceSaleItem]  = React.useState(false);
  const [openEditPriceSaleItem, setOpenEditPriceSaleItem]  = React.useState(false);
  const [openAddPremiumSaleItem, setOpenAddPremiumSaleItem]  = React.useState(false);
  const [openEditPremiumSaleItem, setOpenEditPremiumSaleItem]  = React.useState(false);
  const [color, setColor] = useState('#fff')
  const [openColorPicker, setOpenColorPicker] = React.useState(false);
  const [editGroupItem, setEditGroupItem] = React.useState({});
  const [editPriceSaleItem, setEditPriceSaleItem] = React.useState({});
  const [editPremiumSaleItem, setEditPremiumSaleItem] = React.useState({});
  const [editGroupType, setEditGroupType]= React.useState(null);
  const [pricingOptions , setPricingOptions] = React.useState([]);
  const [premiumOptions , setPremiumOptions] = React.useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [priceFilter, setPriceFilter] = useState({})
  const [premiumFilter, setPremiumFilter] = useState({})
  const [priceCheck , setPriceCheck] = React.useState([]);
  const [premiumCheck , setPremiumCheck] = React.useState('');

  const handleEditPriceFilter=(key, value)=>{
    setPriceFilter({...priceFilter, [key]:value})
  }
  const handleEditPremiumFilter=(key, value)=>{
    setPremiumFilter({...premiumFilter, [key]:value})
    
  }

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }
  const handleChangeColorComplete = (c) => {
    // setColor(color );
    setOpenColorPicker(false);
    setColor("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a))
    if(editGroupType){
      setEditGroupItem({...editGroupItem, [editGroupType]:"#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)});
    }
  };

  const handleChangeColor = (c, event) => {
    // setColor(color );
    setColor("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a))
  }
  
  const handleOpenColorPicker = (groupType)=>{
    setOpenColorPicker(true);
    setEditGroupType(groupType)
  }


  const fetchGroupList = ()=>{
    var activeFile;
    var boothFileList = props.main.boothFileList;
    for(var i = 0; i <boothFileList.length; i++){
        if(boothFileList[i]['active']){
            activeFile = boothFileList[i];
            break;
        }
    }
    if(activeFile) {
      props.setLoading(true)
      axios.get(`/getboothgrouplist?boothId=${activeFile['id']}`).then(res=>{
        console.log(res.data)     
        if(res.data)     
          setBoothGroups(res.data)
        props.setLoading(false)
      }).catch(error => {
          console.log("error", error);
          props.setLoading(false)
      })
    }
  }
  const fetchPricingOptions = ()=>{
    var activeFile;
    var boothFileList = props.main.boothFileList;
    for(var i = 0; i <boothFileList.length; i++){
        if(boothFileList[i]['active']){
            activeFile = boothFileList[i];
            break;
        }
    }
    if(activeFile) {
      props.setLoading(true)
      axios.get(`/getpricesalelist?boothId=${activeFile['id']}`).then(res=>{
        console.log(res.data)     
        if(res.data){
          setPricingOptions(res.data)
        }     
        props.setLoading(false)
      }).catch(error => {
          console.log("error", error);
          props.setLoading(false)
      })
    }
  }
  const fetchPremiumOptions = ()=>{
    var activeFile;
    var boothFileList = props.main.boothFileList;
    for(var i = 0; i <boothFileList.length; i++){
        if(boothFileList[i]['active']){
            activeFile = boothFileList[i];
            break;
        }
    }
    if(activeFile) {
      props.setLoading(true)
      axios.get(`/getpremiumsalelist?boothId=${activeFile['id']}`).then(res=>{
        console.log(res.data)     
        if(res.data){
          setPremiumOptions(res.data)
        }     
        props.setLoading(false)
      }).catch(error => {
          console.log("error", error);
          props.setLoading(false)
      })
    }
  }
  useEffect(() => {
    if(open){
      fetchGroupList();
      fetchPricingOptions();
      fetchPremiumOptions();
      var premiumCheck
      var priceCheck = []
      if(props.booths && props.booths[0]){
        premiumCheck = props.booths[0].get('premiumCheck')
        if(premiumCheck){
          setPremiumCheck(premiumCheck)
        }
        else{
          setPremiumCheck(null)
        }
        var tmp = props.booths[0].get('priceCheck')
        if(tmp){
          // priceCheck = JSON.parse(tmp)
          setPriceCheck(tmp)
        }
        else{
          setPriceCheck([])
        }

        var priceFilter = props.booths[0].get('priceFilter')
        if(priceFilter){
          setPriceFilter(priceFilter)
        }
        else{
          setPriceFilter({})
        }
        
        var premiumFilter = props.booths[0].get('premiumFilter')
        if(premiumFilter){
          setPremiumFilter(premiumFilter)
        }
        else{
          setPremiumFilter({})
        }
      }
    }
  }, [open])
  
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const saveBoothGroup = ()=>{
    if(openAdd){
      var activeFile;
      var boothFileList = props.main.boothFileList;
      for(var i = 0; i <boothFileList.length; i++){
          if(boothFileList[i]['active']){
              activeFile = boothFileList[i];
              break;
          }
      }
      var url = `/saveboothgroup?boothId=${activeFile['id']}`
      var formdata = editGroupItem
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      axios.post(url, data, options).then(res=>{
          const result = res.data;
          if(result.status == "success"){
              setOpenSnack(true);
              setSnackMsg("Added successfully");
              fetchGroupList();
          }
          else{
              setOpenSnack(true);
              setSnackMsg("Failed to add");
              fetchGroupList();
          }
          setOpenAdd(false)
          setOpenEdit(false)
          
      }).catch(error => {
          console.log("error", error);
      })
    }
    else if(openEdit){
      var url = `/updateboothgroup`
      var formdata = editGroupItem
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      axios.post(url, data, options).then(res=>{
          const result = res.data;
          if(result.status == "success"){
              setOpenSnack(true);
              setSnackMsg("Updated successfully");
              fetchGroupList();
          }
          else{
              setOpenSnack(true);
              setSnackMsg("Failed to update");
              fetchGroupList();
          }
          setOpenAdd(false)
          setOpenEdit(false)
          
      }).catch(error => {
          console.log("error", error);
      })
    }
  }

  const deleteBoothGroup = (boothGroup)=>{
    var url = `/deleteboothgroup?_id=${boothGroup['_id']}`
    var formdata = editGroupItem
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axios.post(url, data, options).then(res=>{
        const result = res.data;
        if(result == "success"){
            setOpenSnack(true);
            setSnackMsg("Deleted successfully");
            fetchGroupList();
        }
        else{
            setOpenSnack(true);
            setSnackMsg("Failed to delete");
            fetchGroupList();
        }
        setOpenAdd(false)
        setOpenEdit(false)
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  const savePriceSaleItem = ()=>{
    if(openAddPriceSaleItem){
      var activeFile;
      var boothFileList = props.main.boothFileList;
      for(var i = 0; i <boothFileList.length; i++){
          if(boothFileList[i]['active']){
              activeFile = boothFileList[i];
              break;
          }
      }
      var url = `/savepricesaleitem?boothId=${activeFile['id']}`
      var formdata = editPriceSaleItem
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      axios.post(url, data, options).then(res=>{
          const result = res.data;
          if(result.status == "success"){
              setOpenSnack(true);
              setSnackMsg("Added successfully");
              fetchPricingOptions();
          }
          else{
              setOpenSnack(true);
              setSnackMsg("Failed to add");
              fetchPricingOptions();
          }
          setOpenAddPriceSaleItem(false)
          setOpenEditPriceSaleItem(false)
          
      }).catch(error => {
          console.log("error", error);
      })
    }
    else if(openEditPriceSaleItem){
      var url = `/updatepricesaleitem`
      var formdata = editPriceSaleItem
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      axios.post(url, data, options).then(res=>{
          const result = res.data;
          if(result.status == "success"){
              setOpenSnack(true);
              setSnackMsg("Updated successfully");
              fetchPricingOptions();
          }
          else{
              setOpenSnack(true);
              setSnackMsg("Failed to update");
              fetchPricingOptions();
          }
          setOpenAddPriceSaleItem(false)
          setOpenEditPriceSaleItem(false)
          
      }).catch(error => {
          console.log("error", error);
      })
    }
  }

  const deletePriceSaleItem= (boothGroup)=>{
    var url = `/deletepricesaleitem?_id=${boothGroup['_id']}`
    var formdata = editGroupItem
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axios.post(url, data, options).then(res=>{
        const result = res.data;
        if(result == "success"){
            setOpenSnack(true);
            setSnackMsg("Deleted successfully");
            fetchPricingOptions();
        }
        else{
            setOpenSnack(true);
            setSnackMsg("Failed to delete");
            fetchPricingOptions();
        }
        setOpenAddPriceSaleItem(false)
        setOpenEditPriceSaleItem(false)
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  const savePremiumSaleItem = ()=>{
    if(openAddPremiumSaleItem){
      var activeFile;
      var boothFileList = props.main.boothFileList;
      for(var i = 0; i <boothFileList.length; i++){
          if(boothFileList[i]['active']){
              activeFile = boothFileList[i];
              break;
          }
      }
      var url = `/savepremiumsaleitem?boothId=${activeFile['id']}`
      var formdata = editPremiumSaleItem
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      axios.post(url, data, options).then(res=>{
          const result = res.data;
          if(result.status == "success"){
              setOpenSnack(true);
              setSnackMsg("Added successfully");
              fetchPremiumOptions();
          }
          else{
              setOpenSnack(true);
              setSnackMsg("Failed to add");
              fetchPremiumOptions();
          }
          setOpenAddPremiumSaleItem(false)
          setOpenEditPremiumSaleItem(false)
          
      }).catch(error => {
          console.log("error", error);
      })
    }
    else if(openEditPremiumSaleItem){
      var url = `/updatepremiumsaleitem`
      var formdata = editPremiumSaleItem
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      axios.post(url, data, options).then(res=>{
          const result = res.data;
          if(result.status == "success"){
              setOpenSnack(true);
              setSnackMsg("Updated successfully");
              fetchPremiumOptions();
          }
          else{
              setOpenSnack(true);
              setSnackMsg("Failed to update");
              fetchPremiumOptions();
          }
          setOpenAddPremiumSaleItem(false)
          setOpenEditPremiumSaleItem(false)
          
      }).catch(error => {
          console.log("error", error);
      })
    }
  }

  const deletePremiumSaleItem= (boothGroup)=>{
    var url = `/deletepremiumsaleitem?_id=${boothGroup['_id']}`
    var formdata = editGroupItem
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axios.post(url, data, options).then(res=>{
        const result = res.data;
        if(result == "success"){
            setOpenSnack(true);
            setSnackMsg("Deleted successfully");
            fetchPremiumOptions();
        }
        else{
            setOpenSnack(true);
            setSnackMsg("Failed to delete");
            fetchPremiumOptions();
        }
        setOpenAddPremiumSaleItem(false)
        setOpenEditPremiumSaleItem(false)
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  const onOk = (e)=>{
    if(props.booths && props.booths[0]){
      props.booths[0].set({
        priceCheck: priceCheck,
        premiumCheck: (premiumCheck),
        // priceFilter: (priceFilter),
        // premiumFilter: (premiumFilter)
      })
    }
    onClose()
  }

  const handleOpenAdd = ()=>{
    // if(!openAdd){
      setEditGroupItem({
        name:'', sold:'#c2185bff', pending:'#ffeb3bff', available:'#4dd0e1ff', reserved:'#ff6f00ff', order: boothGroups.length
      });
    // }
    setOpenAdd(true)
    setOpenEdit(false)
  }

  const handleOpenEditGroup = (row)=>{
    setEditGroupItem(row);    
    setOpenAdd(false)
    setOpenEdit(true)
  }

  const handleEditItemValue = (key, value)=>{
    setEditGroupItem({...editGroupItem, [key]:value});
  }

  const handleOpenAddPriceSaleItem = ()=>{
    // if(!openAddPriceSaleItem){
      setEditPriceSaleItem({
        name:'', description:'', price:0, price_type:0, member:false, nonmember: false, nonprofit:false, start_date:'', end_date:'', promo_code:'', booth_group:'', order: pricingOptions.length
      });
    // }
    setOpenAddPriceSaleItem(true)
    setOpenEditPriceSaleItem(false)
  }

  const handleOpenEditPriceSaleItem = (row)=>{
    setEditPriceSaleItem(row);    
    setOpenAddPriceSaleItem(false)
    setOpenEditPriceSaleItem(true)
  }

  const handleEditPriceSaleItemValue = (key, value)=>{
    setEditPriceSaleItem({...editPriceSaleItem, [key]:value});
  }

  const handleOpenAddPremiumSaleItem = ()=>{
    // if(!openAddPremiumSaleItem){
      setEditPremiumSaleItem({
        name:'', description:'', price:0, price_type:0, member:false, nonmember: false, nonprofit:false, start_date:'', end_date:'', promo_code:'', booth_group:'', order: pricingOptions.length
      });
    // }
    setOpenAddPremiumSaleItem(true)
    setOpenEditPremiumSaleItem(false)
  }

  const handleOpenEditPremiumSaleItem = (row)=>{
    setEditPremiumSaleItem(row);    
    setOpenAddPremiumSaleItem(false)
    setOpenEditPremiumSaleItem(true)
  }

  const handleEditPremiumSaleItemValue = (key, value)=>{
    setEditPremiumSaleItem({...editPremiumSaleItem, [key]:value});
  }

  const handlePriceCheck = (key)=>{
    var newPriceCheck
    if(priceCheck)
      newPriceCheck = [...priceCheck];
    else
      newPriceCheck = [];
    if(newPriceCheck.includes(key)){
      for( var i = 0; i < newPriceCheck.length; i++){ 
                                   
        if ( newPriceCheck[i] === key) { 
          newPriceCheck.splice(i, 1); 
          i--; 
        }
        
      setPriceCheck(newPriceCheck)
    }
    }
    else{
      setPriceCheck([...newPriceCheck, key])
    }
  }

  if(boothGroups)
    boothGroups.sort(function (a, b) {
      console.log(a.order)
      console.log(b.order)
      return (a.order?a.order:0)*1 - (b.order?b.order:0)*1;
    });
  var boothGroupName
  if(props.booths && props.booths[0]){
    boothGroupName = props.booths[0].get('boothGroup')
  }
  

  
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'price-dialog-title'}}
      aria-labelledby="price-dialog-title"
      className='price-modal'
      fullWidth={true}
      maxWidth={"md"}
      onClose={onClose} open={open}>
      <DialogTitle id='price-dialog-title' style={{display:'flex', alignItems:'center'}}><MonetizationOnOutlinedIcon style={{marginRight: 10}}/>Booth Setup</DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label={<b>Groups</b>} {...a11yProps(0)} />
              <Tab label={<b>Prices</b>} {...a11yProps(1)} />
              <Tab label={<b>Premium Prices</b>} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel className="group-pannel" value={tabValue} index={0}>
              <label style={{fontSize:14.5}}>Booth Number:{props.boothTexts[0]}</label>
              
              <Table className="color-table" sx={{ minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="25%">Title</TableCell>
                    <TableCell width="15%">Sold</TableCell>
                    <TableCell width="15%">Hold</TableCell>
                    <TableCell width="15%">Available</TableCell>
                    <TableCell width="15%">Reserved</TableCell>
                    <TableCell width="15%">Assign<br/>Group</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {boothGroups.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <a href="javascript:void(0)" onClick={()=>handleOpenEditGroup(row)}>{row.name}</a>
                      </TableCell>
                      <TableCell style={{backgroundColor: row.sold}}></TableCell>
                      <TableCell style={{backgroundColor: row.pending}}></TableCell>
                      <TableCell style={{backgroundColor: row.available}}></TableCell>
                      <TableCell style={{backgroundColor: row.reserved}}></TableCell>
                      <TableCell><a href="javascript:void(0)" onClick={(e)=>props.handleSetBoothGroup(row.name, boothGroupName && boothGroupName==row.name?'unassign': 'assign')}>{boothGroupName && boothGroupName==row.name?'unassign': 'assign'}</a></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box mt={2}>
                <Button variant="text" onClick={()=>{handleOpenAdd()}}  startIcon={<AddIcon />}><b>Create New Booth Group</b></Button>
                <Collapse in={openAdd || openEdit}>
                  {openAdd&&
                  <h4>New Booth Group</h4>
                  }
                  {openEdit&&
                  <h4>Edit Booth Group ({editGroupItem.name})</h4>
                  }
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div className='subtitle'>
                      Booth Group Edit
                      </div>
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Group Name
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <TextField                
                        name="name"
                        margin="dense"
                        
                        fullWidth
                        variant="outlined"
                        value={editGroupItem.name}
                        onChange={(e)=>handleEditItemValue('name', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Display Order
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <TextField                
                        name="order"
                        margin="dense"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={editGroupItem.order?editGroupItem.order:0}
                        onChange={(e)=>handleEditItemValue('order', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className='subtitle'>
                        Exhibitor Booth Map Colors (RGB colors)
                      </div>
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Sold
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField                
                            name="sold"
                            margin="dense"
                            
                            fullWidth
                            variant="outlined"
                            value={editGroupItem.sold}
                            onChange={(e)=>handleEditItemValue('sold', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className = "item-color">
                          <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('sold')}>
                            <div className="color-icon" style={{backgroundColor:(editGroupItem.sold?editGroupItem.sold:'rgba(224, 224, 224, 1)')}}>
                            </div>
                            <ModeEditOutlineOutlinedIcon/> 
                          </div>                         
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={4} className="item-label">
                      Pending
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField                
                            name="pending"
                            margin="dense"
                            
                            fullWidth
                            variant="outlined"
                            value={editGroupItem.pending}
                            onChange={(e)=>handleEditItemValue('pending', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className = "item-color">
                          <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('pending')}>
                            <div className="color-icon" style={{backgroundColor:(editGroupItem.pending?editGroupItem.pending:'rgba(224, 224, 224, 1)')}}>
                            </div>
                            <ModeEditOutlineOutlinedIcon/> 
                          </div>                         
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <div className='subtitle'>
                        Booth Map Colors (RGB colors)
                      </div>
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Available
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField                
                            name="available"
                            margin="dense"
                            
                            fullWidth
                            variant="outlined"
                            value={editGroupItem.available}
                            onChange={(e)=>handleEditItemValue('available', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className = "item-color">
                          <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('available')}>
                            <div className="color-icon" style={{backgroundColor:(editGroupItem.available?editGroupItem.available:'rgba(224, 224, 224, 1)')}}>
                            </div>
                            <ModeEditOutlineOutlinedIcon/> 
                          </div>                         
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} className="item-label">
                      Reserved
                    </Grid>
                    <Grid item xs={8} className="item-field">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField                
                            name="reserved"
                            margin="dense"
                            
                            fullWidth
                            variant="outlined"
                            value={editGroupItem.reserved}
                            onChange={(e)=>handleEditItemValue('reserved', e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} className = "item-color">
                          <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>handleOpenColorPicker('reserved')}>
                            <div className="color-icon" style={{backgroundColor:(editGroupItem.reserved?editGroupItem.reserved:'rgba(224, 224, 224, 1)')}}>
                            </div>
                            <ModeEditOutlineOutlinedIcon/> 
                          </div>                         
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:'right', marginTop: 10}}>
                      <Button variant="contained" onClick={saveBoothGroup} style={{marginRight: 10}}>Save</Button>                      
                      {openEdit&&
                      <ColorButton variant="contained" onClick={()=>deleteBoothGroup(editGroupItem)} style={{marginRight: 10}}>Delete</ColorButton>
                      }
                      <Button variant="text" onClick={()=>{setOpenAdd(false); setOpenEdit(false)}}>Cancel</Button>
                    </Grid>
                  </Grid>
                </Collapse>
              </Box>
          </TabPanel>
          <TabPanel className="group-pannel" value={tabValue} index={1}>
            <label style={{fontSize:14.5}}>Booth Number:{props.boothTexts[0]}</label>
            
            <Table className="pricing-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Price type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricingOptions.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <input type='checkbox' checked={row._id && priceCheck && priceCheck.includes(row._id)} onClick={()=>{handlePriceCheck(row._id)}}/>
                    </TableCell>
                    <TableCell>                      
                      <a href="javascript:void(0)" onClick={()=>handleOpenEditPriceSaleItem(row)}>{row.name}</a>
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell>{row.price_type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box mt={2}>
              <Button variant="text" onClick={()=>{handleOpenAddPriceSaleItem()}}  startIcon={<AddIcon />}><b>Create New Sale Item</b></Button>
              <Collapse in={openAddPriceSaleItem || openEditPriceSaleItem}>
               
                <Paper elevation={3} style={{padding:'20px', marginTop:20}}>
                  {openAddPriceSaleItem&&
                  <h4 style={{marginTop:0}}>New Sale Item</h4>
                  }
                  {openEditPriceSaleItem&&
                  <h4 style={{marginTop:0}}>Edit Sale Item({editPriceSaleItem.name})</h4>
                  }
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="name"
                        label="Title"
                        fullWidth
                        variant="outlined"
                        value={editPriceSaleItem.name?editPriceSaleItem.name:''}
                        onChange={(e)=>handleEditPriceSaleItemValue('name', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>                    
                    <Grid item xs={12}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="description"
                        label="Description"
                        fullWidth
                        variant="outlined"
                        value={editPriceSaleItem.description?editPriceSaleItem.description:''}
                        onChange={(e)=>handleEditPriceSaleItemValue('description', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>          
                    <Grid item xs={4}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="price"
                        label="Price"
                        type='number'
                        step={0.01}
                        fullWidth
                        variant="outlined"
                        value={editPriceSaleItem.price?editPriceSaleItem.price:0}
                        onChange={(e)=>handleEditPriceSaleItemValue('price', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>          
                    <Grid item xs={4}>
                      <FormControl variant="outlined" fullWidth style={{marginTop:'7px'}}>
                        <InputLabel shrink={true}>Price type</InputLabel>
                        <Select
                          input={<OutlinedInput notched label="Price type" />}
                          value={editPriceSaleItem.price_type?editPriceSaleItem.price_type:''}
                          onChange={(e)=>handleEditPriceSaleItemValue('price_type', e.target.value)}
                        >
                          <MenuItem value={'flat'}>flat</MenuItem>
                          <MenuItem value={'square ft'}>square ft</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel control={<Switch checked={editPriceSaleItem.member} onChange={(e)=>handleEditPriceSaleItemValue('member', !editPriceSaleItem.member)}/>} label="Member" />
                    </Grid>               
                    <Grid item xs={4}>
                      <FormControlLabel control={<Switch checked={editPriceSaleItem.nonmember} onChange={(e)=>handleEditPriceSaleItemValue('nonmember', !editPriceSaleItem.nonmember)}/>} label="Nonmember" />
                    </Grid>                
                    <Grid item xs={4}>
                      <FormControlLabel control={<Switch checked={editPriceSaleItem.nonprofit} onChange={(e)=>handleEditPriceSaleItemValue('nonprofit', !editPriceSaleItem.nonprofit)}/>} label="Nonprofit" />
                    </Grid>    
                    <Grid item xs={3}>
                      <TextField                
                        name="name"
                        margin="dense"
                        label="Start date"
                        fullWidth
                        type='date'
                        variant="outlined"
                        value={editPriceSaleItem.start_date?editPriceSaleItem.start_date:''}
                        onChange={(e)=>handleEditPriceSaleItemValue('start_date', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>               
                    <Grid item xs={3}>
                      <TextField                
                        name="end_date"
                        margin="dense"
                        label="End date"
                        fullWidth
                        type='date'
                        variant="outlined"
                        value={editPriceSaleItem.end_date?editPriceSaleItem.end_date:''}
                        onChange={(e)=>handleEditPriceSaleItemValue('end_date', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>        
                    <Grid item xs={3}>
                      <TextField        
                      style={{marginTop:'7px'}}        
                        name="name"
                        margin="promo_code"
                        label="Promo code"
                        fullWidth
                        variant="outlined"
                        value={editPriceSaleItem.promo_code?editPriceSaleItem.promo_code:''}
                        onChange={(e)=>handleEditPriceSaleItemValue('promo_code', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>               
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth style={{marginTop:'7px'}}>
                        <InputLabel shrink={true} id="demo-simple-select-label">Booth Group</InputLabel>
                        <Select
                          input={<OutlinedInput notched label="Booth Group" />}
                          value={editPriceSaleItem.booth_group?editPriceSaleItem.booth_group:''}
                          onChange={(e)=>handleEditPriceSaleItemValue('booth_group', e.target.value)}
                        >
                          {
                            boothGroups.map((item, index)=>{
                              return(
                                <MenuItem key={index} value={item._id}>{item.name}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </Grid>  
                    <Grid item xs={12} style={{textAlign:'right', marginTop: 10}}>
                      <Button variant="contained" onClick={savePriceSaleItem} style={{marginRight: 10}}>Save</Button>                      
                      {openEditPriceSaleItem&&
                      <ColorButton variant="contained" onClick={()=>deletePriceSaleItem(editPriceSaleItem)} style={{marginRight: 10}}>Delete</ColorButton>
                      }
                      <Button variant="text" onClick={()=>{setOpenAddPriceSaleItem(false); setOpenEditPriceSaleItem(false)}}>Cancel</Button>
                    </Grid>                         
                  </Grid>
                </Paper>
              </Collapse>
            </Box>
          </TabPanel>
          <TabPanel className="group-pannel" value={tabValue} index={2}>
            <label style={{fontSize:14.5}}>Booth Number:{props.boothTexts[0]}</label>
            <Table className="pricing-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Price type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {premiumOptions.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <input type='checkbox' checked={premiumCheck&& premiumCheck == row._id} onClick={()=>{setPremiumCheck(row._id)}}/>
                    </TableCell>
                    <TableCell>                      
                      <a href="javascript:void(0)" onClick={()=>handleOpenEditPremiumSaleItem(row)}>{row.name}</a>
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell>{row.price_type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box mt={2}>
              <Button variant="text" onClick={()=>{handleOpenAddPremiumSaleItem()}}  startIcon={<AddIcon />}><b>Create New Premium Sale Item</b></Button>
              <Collapse in={openAddPremiumSaleItem || openEditPremiumSaleItem}>
               
                <Paper elevation={3} style={{padding:'20px', marginTop:20}}>
                  {openAddPremiumSaleItem&&
                  <h4 style={{marginTop:0}}>New Premium Sale Item</h4>
                  }
                  {openEditPremiumSaleItem&&
                  <h4 style={{marginTop:0}}>Edit Premium Sale Item({editPremiumSaleItem.name})</h4>
                  }
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="name"
                        label="Title"
                        fullWidth
                        variant="outlined"
                        value={editPremiumSaleItem.name?editPremiumSaleItem.name:''}
                        onChange={(e)=>handleEditPremiumSaleItemValue('name', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>                    
                    <Grid item xs={12}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="description"
                        label="Description"
                        fullWidth
                        variant="outlined"
                        value={editPremiumSaleItem.description?editPremiumSaleItem.description:''}
                        onChange={(e)=>handleEditPremiumSaleItemValue('description', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>          
                    <Grid item xs={4}>
                      <TextField        
                        style={{marginTop:'7px'}}        
                        name="price"
                        label="Price"
                        type='number'
                        step={0.01}
                        fullWidth
                        variant="outlined"
                        value={editPremiumSaleItem.price?editPremiumSaleItem.price:0}
                        onChange={(e)=>handleEditPremiumSaleItemValue('price', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>          
                    <Grid item xs={4}>
                      <FormControl variant="outlined" fullWidth style={{marginTop:'7px'}}>
                        <InputLabel shrink={true}>Price type</InputLabel>
                        <Select
                          input={<OutlinedInput notched label="Price type" />}
                          value={editPremiumSaleItem.price_type?editPremiumSaleItem.price_type:''}
                          onChange={(e)=>handleEditPremiumSaleItemValue('price_type', e.target.value)}
                        >
                          <MenuItem value={'flat'}>flat</MenuItem>
                          <MenuItem value={'square ft'}>square ft</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel control={<Switch checked={editPremiumSaleItem.member} onChange={(e)=>handleEditPremiumSaleItemValue('member', !editPremiumSaleItem.member)}/>} label="Member" />
                    </Grid>               
                    <Grid item xs={4}>
                      <FormControlLabel control={<Switch checked={editPremiumSaleItem.nonmember} onChange={(e)=>handleEditPremiumSaleItemValue('nonmember', !editPremiumSaleItem.nonmember)}/>} label="Nonmember" />
                    </Grid>                
                    <Grid item xs={4}>
                      <FormControlLabel control={<Switch checked={editPremiumSaleItem.nonprofit} onChange={(e)=>handleEditPremiumSaleItemValue('nonprofit', !editPremiumSaleItem.nonprofit)}/>} label="Nonprofit" />
                    </Grid>    
                    <Grid item xs={3}>
                      <TextField                
                        name="name"
                        margin="dense"
                        label="Start date"
                        fullWidth
                        type='date'
                        variant="outlined"
                        value={editPremiumSaleItem.start_date?editPremiumSaleItem.start_date:''}
                        onChange={(e)=>handleEditPremiumSaleItemValue('start_date', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>               
                    <Grid item xs={3}>
                      <TextField                
                        name="end_date"
                        margin="dense"
                        label="End date"
                        fullWidth
                        type='date'
                        variant="outlined"
                        value={editPremiumSaleItem.end_date?editPremiumSaleItem.end_date:''}
                        onChange={(e)=>handleEditPremiumSaleItemValue('end_date', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>        
                    <Grid item xs={3}>
                      <TextField        
                      style={{marginTop:'7px'}}        
                        name="name"
                        margin="promo_code"
                        label="Promo code"
                        fullWidth
                        variant="outlined"
                        value={editPremiumSaleItem.promo_code?editPremiumSaleItem.promo_code:''}
                        onChange={(e)=>handleEditPremiumSaleItemValue('promo_code', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>               
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth style={{marginTop:'7px'}}>
                        <InputLabel shrink={true} id="demo-simple-select-label">Booth Group</InputLabel>
                        <Select
                          input={<OutlinedInput notched label="Booth Group" />}
                          value={editPremiumSaleItem.booth_group?editPremiumSaleItem.booth_group:''}
                          onChange={(e)=>handleEditPremiumSaleItemValue('booth_group', e.target.value)}
                        >
                          {
                            boothGroups.map((item, index)=>{
                              return(
                                <MenuItem key={index} value={item._id}>{item.name}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </Grid>  
                    <Grid item xs={12} style={{textAlign:'right', marginTop: 10}}>
                      <Button variant="contained" onClick={savePremiumSaleItem} style={{marginRight: 10}}>Save</Button>                      
                      {openEditPremiumSaleItem&&
                      <ColorButton variant="contained" onClick={()=>deletePremiumSaleItem(editPremiumSaleItem)} style={{marginRight: 10}}>Delete</ColorButton>
                      }
                      <Button variant="text" onClick={()=>{setOpenAddPremiumSaleItem(false); setOpenEditPremiumSaleItem(false)}}>Cancel</Button>
                    </Grid>                         
                  </Grid>
                </Paper>
              </Collapse>
            </Box>
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      {openColorPicker &&
        <SwatchesPicker 
          height ={400}
          className="group-color-picker"
          color={ color }
          onChange={ handleChangeColorComplete }
          onChangeComplete={ handleChangeColorComplete }
        />
      }
      <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={snackMsg}
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                  <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
          }
      />
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setLoading:  data=>dispatch(setLoading(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(PriceModal);