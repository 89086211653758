export default {
  Wall: "Wall",
  Booth: "Booth",
  Aisle: "Aisle",
  Circle: "circle",
  Line: "line",
  Arrow: "arrow",
  Pencil: "pencil",
  Rectangle: "rectangle",
  RectangleLabel: "rectangle-label",
  Select: "select",
  Pan: "pan",
  Highlighter: "highlighter",
  DefaultTool: "default-tool",
};
