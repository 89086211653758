import FabricCanvasTool from "../fabrictool";
import { getSnapPosition, addText, getFirstZoom } from '../../../../../utils/CanvasTool'
import { v4 as uuidv4 } from 'uuid';
const fabric = require("fabric").fabric;



class Textbox extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this.props = props
  }

  doMouseUp(o) {
    var text = "Text";
    this.isDown = false;
    let canvas = this._canvas;
    var options = {};
    let iText = new fabric.Text(text, options);
    var pointer = canvas.getPointer(o.e);  
    let opts = {
      left: pointer.x,
      top: pointer.y,
    };
    Object.assign(options, opts);
    iText.set({
      left: options.left,
      top: options.top,
      fontSize:this.props.fontSize,
      fontSizeCustom:this.props.fontSize,
      borderColorCustom:this.props.lineColor,
      layer:'annotation',
      fill: this.props.lineColor
    });

    // canvas.add(iText);
    // canvas.bringToFront(iText)
    
    iText.set({id:Date.now()})

    let textGroup = new fabric.Group([iText]).set({      
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
    });
    textGroup.set({
      layer:'annotation',
      class1:'text',
      fontSize:this.props.fontSize,
      fontSizeCustom:this.props.fontSize,
      fontColorCustom: this.props.lineColor,
      title:text
    })
    textGroup.set({id:Date.now()})   
    canvas.add(textGroup);
    textGroup.new_created = true;
    textGroup.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
    textGroup.KEY_ID = uuidv4()
    this.props.setBoothHistory({boothList:this.props.main.boothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[textGroup]}})
    this.props.onDrawDone(textGroup)   
  }

}

export default Textbox;
