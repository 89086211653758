/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "../fabrictool";
import { getSnapPosition, addText, getFirstZoom } from '../../../../../utils/CanvasTool'
import { v4 as uuidv4 } from 'uuid';

const fabric = require("fabric").fabric;

class Line extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this.props = props
  }

  doMouseDown(o) {
    this.isDown = true;
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);    
    var newPoint = pointer
    if(canvas.snapToGrid)
      newPoint = getSnapPosition(canvas, pointer)
    var points = [newPoint.x, newPoint.y, newPoint.x, newPoint.y];
    var strokeWidth = 1/getFirstZoom(canvas)
    this.line = new fabric.Line(points, {
      strokeWidth: strokeWidth,
      fill: this._color,
      stroke: this._color,
      originX: "center",
      originY: "center",
      selectable: false,
      evented: false,
      layer:'annotation'
    });
    canvas.add(this.line);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);
    var newPoint = pointer
    if(canvas.snapToGrid)
      newPoint = getSnapPosition(canvas, pointer)
    this.line.set({ x2: newPoint.x, y2: newPoint.y });
    this.line.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;let canvas = this._canvas;

    canvas.remove(this.line);
    let line = new fabric.Group([this.line]).set({      
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
    });
    line.set({
      layer:'annotation',
      class1: 'line'
    })
    line.set({id:Date.now()})   
    canvas.add(line);
    var convertRateX= 1
    if(canvas.convertRateX) {
        var convertRateX= Math.abs(canvas.convertRateX)
    }
    var f_text = new fabric.Text("", {              
      left: 0-line.width/2, 
      top:0-line.height/2,
      fontSize:30/convertRateX,
      class:'title'
    });
    line.add(f_text);
    line.new_created = true;
    line.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
    line.KEY_ID = uuidv4()
    this.props.setBoothHistory({boothList:this.props.main.boothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[line]}})
    this.props.onDrawDone(line) 
  }

  doMouseOut(o) {
    this.isDown = false;
    this.props.onDrawDone(false)   
  }
}

export default Line;
