export const SET_EXHIBITOR_LIST_SCROLL = 'SET_EXHIBITOR_LIST_SCROLL'
export const SetPulldownValue = 'SetPulldownValue'
export const SetSearchOptions = 'SetSearchOptions'
export const SetKeySelect = 'SetKeySelect'
export const SetFilteredItems = 'SetFilteredItems'
export const SetPageSetup = 'SetPageSetup'


export const setExhibitorListScroll = data =>({
    type:SET_EXHIBITOR_LIST_SCROLL,
    payload:data
});

export const setPulldownValue = data =>({
    type:SetPulldownValue,
    payload:data
});

export const setSearchOptions = data =>({
    type:SetSearchOptions,
    payload:data
});

export const setKeySelect = data =>({
    type:SetKeySelect,
    payload:data
});

export const setFilteredItems = data =>({
    type:SetFilteredItems,
    payload:data
});

export const setPageSetup = data =>({
    type:SetPageSetup,
    payload:data
});

