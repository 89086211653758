import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SetBoothModal(props) {
  const { onClose, onOk, onChange, open, booth } = props;
  var mainLayers = props.main.mainLayers;

  useEffect(() => {
  }, [])
  
  const onChangeName = (e)=>{
    onChange(e.target.name, e.target.value)
  }
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'setbooth-dialog-title'}}
      aria-labelledby="setbooth-dialog-title"
      onClose={onClose} open={open}>
      <DialogTitle id='setbooth-dialog-title'>BOOTH: {booth.id}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        <TextField
          name="boothName"
          margin="dense"
          label="Booth Name"
          type="name"
          fullWidth
          variant="standard"
          value={booth.boothName}
          onChange={onChangeName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SetBoothModal);