
import {
  SET_LOG,
  ADD_LOG,
  ADD_LOGS
} from '../actions/log';

var initVal = {
  logs:[]
}
export default function log(state = initVal, {type, payload}) {
  switch (type) {
    case SET_LOG:
      return {
        ...state,
        logs: payload,
    }
    case ADD_LOG:
      return {
        ...state,
        logs: [...state.logs, payload],
    }
    case ADD_LOGS:
      return {
        ...state,
        logs: [...state.logs, ...payload],
    }
      default:
          return state;
  }
}