import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setPlanner } from '../../actions/main'
import { setOpenLogin, setOtpFlag, setOpenSignup, setOpenResetPassword, setSnackMsg, setOpenSnack,setBookmardUpdated, setOpenNewPassword, setOpenError } from '../../actions/ui'
import { NavLink } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import { parseTokenValue, getUrlToken } from '../../utils/Common'
import { getTokenCookie, setTokenPlanner } from '../../utils/Common'
import {PaperComponent} from '../../utils/Common'
import ScaleLoader from 'react-spinners/ScaleLoader'

import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
var magicVerficationTimer = false
var magicVerifyInfo = false
var verifyCnt = 0;
function Index(props) {
  const { onClose, onOk, onChange, open } = props;
  const [formValue, setFormValue] = React.useState({});
  const [processing1, setProcessing1] = React.useState(false);
  const [processing2, setProcessing2] = React.useState(false);
  const [startVerification, setStartVerification] = React.useState(false);
  let clickedButton = '';
  useEffect(() => {
    if(!props.ui.openLogin){
      stopMagicVerfication()
    }
  }, [props.ui.openLogin])

  const onChangeValue = (e)=>{
    setFormValue({...formValue, [e.target.name]: e.target.value});
  }
  const startMagicVerfication = ()=>{
    setStartVerification(true)
    magicVerficationTimer = setInterval(()=>{
      checkMagicVerfication()
    }, 4000);
  }
  const stopMagicVerfication = ()=>{
    setStartVerification(false)
    if(magicVerficationTimer){
      clearInterval(magicVerficationTimer)
      magicVerficationTimer = false;
    }
  }
  const checkMagicVerfication=()=>{
    verifyCnt++;
    if(verifyCnt == 225){ //15min * 60/4
      stopMagicVerfication()
      props.setOpenSnack(true);
      props.setSnackMsg("Failed to login");
    }
    var postData = 
    {
      EMAIL: formValue.email?formValue.email:'',
      PASSWORD: formValue.password?formValue.password:''
    }
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putLogin&token=${getTokenCookie()}&access_type=verify&key_id=${magicVerifyInfo.KEY_ID}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
          stopMagicVerfication()
          var tokenInfo = await parseTokenValue(eshowResponse.TOKEN)
          setTokenPlanner(eshowResponse.TOKEN, tokenInfo)
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Successfully logged in");
          props.setOpenLogin(false)
          props.setOtpFlag(false)
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          var show_key = urlParams.get('show_key') 
          var urlToken = getUrlToken()
          props.setPlanner({tokenInfo, key:urlToken})
          if(eshowResponse.OTP == true){
            props.setOpenNewPassword(true)
          }
          
        }
        else{
          if(eshowResponse.MESSAGE != 'Pending'){
            stopMagicVerfication()
            // props.setOpenSnack(true);
            // props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to login");
            props.setOpenLogin(false)
            props.setOpenError(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to login")
          }
        }
        // setProcessing1(false)
        
    }).catch(error => {
        console.log("error", error);
    })
  }

  const handleSignin = (event)=>{
    if (clickedButton == "submit_password") {
      event.preventDefault();
      // if(!formValue.password){
      //   props.setOpenSnack(true);
      //   props.setSnackMsg("Invalid password");
      //   return false;
      // }
      setProcessing1(true)
      var postData = 
      {
        EMAIL: formValue.email?formValue.email:'',
        PASSWORD: formValue.password?formValue.password:''
      }
      var formdata = {
        query:`${ESHOW_URL()}?method=putLogin&token=${getTokenCookie()}`,
        postData: postData,
        returnResponse: true
      }
      var data = JSON.stringify(
        postData
      );
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }
      var url = `${ESHOW_URL()}?method=putLogin&token=${getTokenCookie()}`
      // var url = `/postCrm1`
      axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
          const eshowResponse = res.data;
          if(eshowResponse.SUCCESS == true){
              
            var tokenInfo = await parseTokenValue(eshowResponse.TOKEN)
            setTokenPlanner(eshowResponse.TOKEN, tokenInfo)
            props.setOpenSnack(true);
            props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Successfully logged in");
            props.setOpenLogin(false)
            props.setOtpFlag(false)
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            var show_key = urlParams.get('show_key') 
            var urlToken = getUrlToken()
            props.setPlanner({tokenInfo, key:urlToken})
            if(eshowResponse.OTP == true){
              props.setOpenNewPassword(true)
            }
          }
          else{
            // props.setOpenSnack(true);
            // props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to login");
            props.setOpenLogin(false)
            props.setOpenError(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to login")
          }
          setProcessing1(false)
          
      }).catch(error => {
          console.log("error", error);
      })
    }
    else if (clickedButton == "submit_magic") {
      event.preventDefault();
      var postData = 
      {
        EMAIL: formValue.email?formValue.email:'',
        PASSWORD: formValue.password?formValue.password:''
      }
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }
      var url = `${ESHOW_URL()}?method=putLogin&token=${getTokenCookie()}&access_type=magic_link`
      // var url = `/postCrm1`
      axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
          const eshowResponse = res.data;
          if(0) {
            if(eshowResponse.SUCCESS == true){
                var tokenInfo = await parseTokenValue(eshowResponse.TOKEN)
                setTokenPlanner(eshowResponse.TOKEN, tokenInfo)
                props.setOpenSnack(true);
                props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Successfully logged in");
                props.setOpenLogin(false)
                props.setOtpFlag(false)
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                var show_key = urlParams.get('show_key') 
                var urlToken = getUrlToken()
                props.setPlanner({tokenInfo, key:urlToken})
                if(eshowResponse.OTP == true){
                  props.setOpenNewPassword(true)
                }
            }
            else{
              // props.setOpenSnack(true);
              // props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to login");
              props.setOpenLogin(false)
              props.setOpenError(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to login")
            }
          }
          if(eshowResponse.SUCCESS == true){
            magicVerifyInfo = (eshowResponse)
            startMagicVerfication()
          }
          else{
            props.setOpenLogin(false)
            props.setOpenError(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to login")
          }

          // setProcessing1(false)
          
      }).catch(error => {
          console.log("error", error);
      })
    }
  }


  const onChangeName = (e)=>{
    onChange(e)
  }
  return (
    <Dialog
      className='top-modal'
      fullWidth={true}
      maxWidth={"sm"} onClose={()=>{props.setOpenLogin(false);  props.setOtpFlag(false)}} open={props.ui.openLogin}>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        <Box style={{}}>
          <IconButton style={{float:'right', padding:5}} color="inherit" onClick={()=>{props.setOpenLogin(false); props.setOtpFlag(false)}} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" gutterBottom style={{textAlign: 'center', fontSize: '25px',color: '#1565c0'}}>
            {startVerification?'Verify Login':'Sign In'}
          </Typography>
          {props.ui.otpFlag &&
          <Typography variant="h5" gutterBottom style={{textAlign: 'center', fontSize: '20px',color: 'red'}}>
            Alert: Enter OTP code send to your email to reset password
          </Typography>
          }
        </Box>
        {
          startVerification?
          <Box>
            <Box mt={2}>
              <div dangerouslySetInnerHTML={{ __html: magicVerifyInfo.MESSAGE??'' }}></div>
            </Box>
            <Box mt={2} textAlign={'center'}>
            <ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2} margin={2} height={28} width={4}/>
            </Box>
          </Box>:
          <form onSubmit={handleSignin}>
            <TextField
              name="email"
              margin="dense"
              label="Email"
              type="name"
              fullWidth
              variant="standard"
              onChange={onChangeValue}
            />
            <TextField
              name="password"
              margin="dense"
              label={props.ui.otpFlag?"OTP Code":"Password"}
              type="password"
              fullWidth
              variant="standard"
              onChange={onChangeValue}
            />
            
            <Box mt={2} display='flex' style={{justifyContent:'space-between'}}>
              <Box mt={2} textAlign='center' sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Button variant="contained" color="primary" size="large" type="submit" value="submit_password" onClick={()=>{clickedButton ='submit_password'}}>
                  Login With Password
                </Button>
                {processing1 &&
                <CircularProgress 
                  sx={{
                  marginLeft:'10px'
                  }}
                  size={25}
                  thickness={4}
                />
                }
              </Box>
              <Box mt={2} textAlign='center' sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Button variant="contained" color="primary" size="large" type="submit" value="submit_magic" onClick={()=>{clickedButton ='submit_magic'}}>
                  Login With Magic Link
                </Button>
                {processing2 &&
                <CircularProgress 
                  sx={{
                  marginLeft:'10px'
                  }}
                  size={25}
                  thickness={4}
                />
                }
              </Box>
            </Box>
            <Box mt={3} display='flex' style={{justifyContent:'end'}}>
              <a style={{marginRight:'10px'}} href="javascript:void(0)" className="buttonLink" onClick={()=>{props.setOpenSignup(true); props.setOpenLogin(false); props.setOtpFlag(false)}}>
                Create new planner
              </a>
              <a href="javascript:void(0)" onClick={()=>{props.setOpenResetPassword(true); props.setOpenLogin(false); props.setOtpFlag(false)}}  className="buttonLink">Forgot Password</a>
            </Box>
          
          </form>
        }
      </DialogContent>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setOpenError: data=>dispatch(setOpenError(data)) ,
        setMainLayers: layers=>dispatch(setMainLayers(layers)) ,
        setOpenLogin: data=>dispatch(setOpenLogin(data)) ,
        setOtpFlag: data=>dispatch(setOtpFlag(data)) ,
        setOpenSignup: data=>dispatch(setOpenSignup(data)),
        setOpenResetPassword: data=>dispatch(setOpenResetPassword(data)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),
        setBookmardUpdated:  data=>dispatch(setBookmardUpdated(data)),
        setPlanner: data=>dispatch(setPlanner(data)) ,
        setOpenNewPassword: data=>dispatch(setOpenNewPassword(data)) ,
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);