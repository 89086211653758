import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';
import { setLeftSearchVal, setOpenBoothList, setLeftPage } from '../../../actions/ui'
import InputBase from '@mui/material/InputBase';
import EditIcon from '@mui/icons-material/Edit';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Button from '@mui/material/Button';
import CompanyEditModal from '../CompanyEditModal'
import { useEffect,useState,useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setActive3dObject } from '../../../actions/main';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  width: '38px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function descendingComparator(a, b, orderBy) {
  var a_val = (a[orderBy])?a[orderBy]:''
  var b_val = (b[orderBy])?b[orderBy]:''
  if(orderBy == 'date'){
    if(a_val && b_val)
      return new Date(a_val) - new Date(b_val);
    else
       return false
  }
  else{
    return a_val.toString().localeCompare(b_val.toString(), 'en', { numeric: true })
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    // if (order !== 0) {
      return order;
    // }
    // return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function BoothList(props) {
  const theme = useTheme();
  const [openCompanyModal, setOpenCompanyModal] = React.useState(false);
  const [boothList, setBoothList] = React.useState([]);
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [filteredBoothList, setFilteredBoothList] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('date');
  const keywordInput = useRef(null);
  
  const [editBooth, setEditBooth]  = React.useState(null);
  const page_size = 15;
  const main_widow_offset_x = 0;
  const main_widow_offset_y = 51;
  const handleChangePage = (event, value) => {
    setPage(value);
  }
  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  useEffect(() => {
    if(props.ui.openBoothList){
      setTimeout(function(){
        var boothList = props.log.logs;

        // if(searchVal){
        //   var filteredBoothList = boothList.filter((booth)=>{
        //     var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
        //     return (str.toLowerCase().includes(searchVal.toLowerCase()))
        //   })
        //   setFilteredBoothList(filteredBoothList)
        // }
        // else{
        //   setFilteredBoothList(boothList)
        // }
        var boothList = boothList.filter((booth)=>{
          return booth.action
        })
        setFilteredBoothList(boothList)
        setBoothList(boothList)
        
        setLoadingFlag(false)
      }, 300)
    }
  }, [props.ui.openBoothList,props.main.canvasRefresh])


  const doSearch = (searchVal)=>{
    // var searchVal = keywordInput.current.value
    if(searchVal){      
      var boothList = props.log.logs;
      var filteredBoothList = boothList.filter((booth)=>{
        var str = ((booth.date?(` ${booth.date}`):""))+(booth.user?booth.user:"")+(booth.description?booth.description:"")
        return (str.toLowerCase().includes(searchVal.toLowerCase())) && booth.action
      })
      setFilteredBoothList(filteredBoothList)
    }
    else{      
      var boothList = props.log.logs;
      var filteredBoothList = boothList.filter((booth)=>{
         return booth.action
      })
      setFilteredBoothList(filteredBoothList)
    }
  }

  const handleDrawerOpen = () => {
    props.setOpenBoothList(true);
  };

  const handleDrawerClose = () => {
    props.setOpenBoothList(false);
  };

  const handleOpenCompanyModal=(index)=>{
    setEditBooth(filteredBoothList[index])
    setOpenCompanyModal(true)
  }

  const handleCloseCompanyModal=(index)=>{
    setOpenCompanyModal(false)
  }

  const setActiveObject = (o) => {
    var mainCanvas = props.main.mainCanvas;
    // mainCanvas.getObjects().forEach(function(o) {
    //   if(o.id == "booth-"+id) {
    //     console.log("selected", o)
        mainCanvas.viewportTransform[0] = 1;
        mainCanvas.viewportTransform[3] = 1;
        mainCanvas.viewportTransform[4] = window.screen.width/2-o.left-o.width/2-main_widow_offset_x;
        mainCanvas.viewportTransform[5] = window.screen.height/2-o.top-o.height/2-main_widow_offset_y;
        window.scrollTo(0, 0)
        mainCanvas.requestRenderAll();
        mainCanvas.setActiveObject(o).renderAll();
        props.setActive3dObject(o)
    //   }      
    // })
  }

  // var boothList=[...props.main.boothList]
  // boothList.sort(function(a, b) {
  //   const nameA = a.boothName; // ignore upper and lowercase
  //   const nameB = b.boothName; // ignore upper and lowercase
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  
  //   // names must be equal
  //   return 0;
  // });
  // boothList.sort(function(a, b) {
  //   return a.boothName - b.boothName;
  // });

  const searchBox = ()=>{
    return (
      <Paper
        className='search-bar'
        sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', maxWidth:'370px', margin:'0 auto 10px' }}
      >
        
          <SearchIcon sx={{ p: '5px' }} />
        <InputBase
          sx={{ flex: 1, width:300  }}
          placeholder="Search by keyword"
          inputProps={{ 'aria-label': 'Search by keyword' }}
          // val={keyword}
          onChange={(e)=>doSearch(e.target.value)}
          inputRef={keywordInput}
        />
        {/* <Button onClick={doSearch} className='search-attach' variant="contained" color="primary" sx={{ p: '10px' }} aria-label="directions">
          Search
        </Button> */}
      </Paper>
    )
  }

  
  var sortData = stableSort(filteredBoothList, getComparator(order, orderBy))

  var pageData = paginate(sortData, page_size, page);
  console.log("pageData", pageData)
  return (
    <Fade in={true} timeout={1200}>
      <div className='appLeftSearch'>
        <Box className="">
        <Box display="flex" style={{alignItems:'center', justifyContent:'space-between'}} pt={2}  pb={2}>
            
                <div style={{marginLeft:20}}></div>
                <Box textAlign="left">
                  <Box display="flex">
                    <h4 style={{textTransform:'capitalize', margin:0}}>
                    Audit log
                    </h4> 
                    <span style={{color:'#a0a0a0',  marginLeft:5}}>({filteredBoothList?filteredBoothList.length:0})</span>
                  </Box>
                </Box>
                <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
                  {props.role == 'admin' ?          
                  <IconButton
                      size="small"
                      edge="start"
                      aria-label="assign"
                      style={{float:'right'}}
                      onClick={()=>{props.setOpenBoothList(false)}}
                    >
                      <HighlightOffOutlinedIcon/>
                  </IconButton>:
                  <IconButton
                    size="small"
                    edge="start"
                    aria-label="assign"
                    style={{float:'right'}}
                    onClick={()=>{props.setLeftPage('home')}}
                  >
                    <ChevronLeftIcon/>
                  </IconButton>
                  }
                </div>
            </Box>
            {searchBox()}
            <Divider />
            {pageData&&
            <Box>
              <Table className="color-table" sx={{ width: '100%'}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      // width="150px"
                      align={'center'}
                      sortDirection={orderBy === 'date' ? order : false}
                      style={{width:'150px', textAlign:'center'}}
                    >
                      <TableSortLabel
                        active={orderBy === 'date'}
                        direction={orderBy === 'date' ? order : 'asc'}
                        onClick={()=>createSortHandler('date')}
                      >
                        Date
                        {orderBy === 'date' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      // width="25%"
                      style={{width:'120px', textAlign:'center'}}
                      align={'center'}
                      sortDirection={orderBy === 'user' ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === 'user'}
                        direction={orderBy === 'user' ? order : 'asc'}
                        onClick={()=>createSortHandler('user')}
                      >
                        User
                        {orderBy === 'user' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{width:'calc(100% - 312px)', textAlign:'center'}}
                      align={'center'}
                      sortDirection={orderBy === 'action' ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === 'action'}
                        direction={orderBy === 'action' ? order : 'asc'}
                        onClick={()=>createSortHandler('action')}
                      >
                        Action
                        {orderBy === 'action' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageData.map((booth, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell size="small" style={{textAlign:'center', padding:'4px 7px', fontSize:13, height:'35px'}}>{(booth.date?(` ${booth.date}`):"")}</TableCell>
                      <TableCell size="small" style={{padding:'4px 7px', fontSize:13, height:'35px'}}>{(booth.user?(` ${booth.user}`):<></>)}</TableCell>
                      <TableCell size="small" style={{padding:'4px 7px', fontSize:13, height:'35px', whiteSpace:'pre-line', wordBreak:'break-all' }}><div style={{maxHeight:'35px', overflowY:'auto', whiteSpace:'pre-line', wordBreak:'break-all' }}>{(booth.description?booth.description:"")}</div></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            }
            <Box mt={2} mb={0}>
              <Stack spacing={2}>
                <Pagination variant="outlined" shape="rounded" style={{justifyContent:'center'}} count={Math.ceil(filteredBoothList.length/page_size)} color="primary"  page={page} onChange={handleChangePage}/>
              </Stack>
            </Box>
            
            <LoadingOverlay
                active={loadingFlag}
                styles={{
                    // spinner: (base) => ({
                    // ...base,
                    // width: '50px',
                    // '& svg circle': {
                    //     stroke: '#1976d2'
                    // }
                    // }),
                    overlay: (base) => ({
                    ...base,
                    fontSize:'18px',
                    color: 'rgb(5, 37, 51)',
                    // background: 'rgb(229 229 229 / 92%)',
                    background: 'transparent',
                    position:'absolute',
                    left:'50%',
                    top:'200px',
                    transform:'translateX(-50%)',
                    zIndex:1000000000
                    })
                }}
                spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
                // spinner
                // text='Loading ...'
                >
            </LoadingOverlay> 
        </Box>
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    log: state.log,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setLeftSearchVal: value=>dispatch(setLeftSearchVal(value)) ,
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
  };
};

export default connect(mapStateToProps, mapDispatchProps)(BoothList);
