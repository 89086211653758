/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "../fabrictool";
import { v4 as uuidv4 } from 'uuid';
import { getSnapPosition, addText, getFirstZoom } from '../../../../../utils/CanvasTool'

const fabric = require("fabric").fabric;

class Rectangle extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
    this.props = props
  }

  doMouseDown(o) {
    let canvas = this._canvas;
    this.isDown = true;
    let pointer = canvas.getPointer(o.e);
    this.startX = pointer.x;
    this.startY = pointer.y;
    var strokeWidth = 1/getFirstZoom(canvas)
    this.rect = new fabric.Rect({
      left: this.startX,
      top: this.startY,
      originX: "left",
      originY: "top",
      width: pointer.x - this.startX,
      height: pointer.y - this.startY,
      stroke: this._color,
      strokeWidth: strokeWidth,
      fill: this._fill,
      transparentCorners: false,
      selectable: false,
      evented: false,
      strokeUniform: true,
      noScaleCache: false,
      angle: 0,
      layer:'annotation'
    });
    this.rect.set({id:Date.now()})   
    canvas.add(this.rect);
    this.props.setBoothHistory({boothList:this.props.main.boothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[this.rect]}})
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    let canvas = this._canvas;
    let pointer = canvas.getPointer(o.e);
    if (this.startX > pointer.x) {
      this.rect.set({ left: Math.abs(pointer.x) });
    }
    if (this.startY > pointer.y) {
      this.rect.set({ top: Math.abs(pointer.y) });
    }
    this.rect.set({ width: Math.abs(this.startX - pointer.x) });
    this.rect.set({ height: Math.abs(this.startY - pointer.y) });
    this.rect.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;let canvas = this._canvas;

    canvas.remove(this.rect);
    let rect = new fabric.Group([this.rect]).set({      
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
    });
    rect.set({
      layer:'annotation',
      class1: 'rect'
    })
    rect.set({id:Date.now()})   
    canvas.add(rect);
    var convertRateX= 1
    if(canvas.convertRateX) {
        var convertRateX= Math.abs(canvas.convertRateX)
    }
    var f_text = new fabric.Text("", {              
      left: 0-rect.width/2, 
      top:0-rect.height/2,
      fontSize:30/convertRateX,
      class:'title'
    });
    rect.add(f_text);
    rect.new_created = true;
    rect.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
    rect.KEY_ID = uuidv4()
    this.props.setBoothHistory({boothList:this.props.main.boothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[rect]}})
    this.props.onDrawDone(rect)  
  }
}

export default Rectangle;
