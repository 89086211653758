
import {
  SET_CART,
  ADD_CART
} from '../actions/cart';

var initVal = {
  carts:[]
}
export default function log(state = initVal, {type, payload}) {
  switch (type) {
    case SET_CART:
      console.log("SET_CART")
      return {
        ...state,
        carts: payload,
    }
    case ADD_CART:
      return {
        ...state,
        carts: [...state.carts, payload],
    }
      default:
          return state;
  }
}