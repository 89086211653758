import * as React from 'react';
import { fabric } from "fabric";
import { useEffect,useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { setLog, addLog } from '../../actions/log'
import { setBoothHistory } from '../../actions/booth_history'
import { setMainLayers, setLoading, setMapRemoveObjects, setRemoveObjects } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { SwatchesPicker , PhotoshopPicker  } from 'react-color'
import rgbHex from "rgb-hex";
import axios from 'axios';
import qs, { parse } from 'qs'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListIcon from '@mui/icons-material/List';
import CheckIcon from '@mui/icons-material/Check';
import CompanyEditModal from './CompanyEditModal'
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputBase from '@mui/material/InputBase';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import { v4 as uuidv4 } from 'uuid';
import { changeBorderColor, changeBorderWidth, px2unit, unit2Px, getDuplicatedBooths, checkIfSelectable, findCombinedBooth, getSqrt } from '../../utils/CanvasTool'
import { setOpenLogin, setOpenSignup, setOpenResetPassword, setSnackMsg, setOpenSnack, setOpenCompanyModal, setEditCompany } from '../../actions/ui'
import {PaperComponent} from '../../utils/Common'

const emails = ['username@gmail.com', 'user02@gmail.com'];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function descendingComparator(a, b, orderBy) {
  if(orderBy == 'size') orderBy = 'sqft'
  var a_val = (a[orderBy])?a[orderBy]:''
  var b_val = (b[orderBy])?b[orderBy]:''
  return a_val.toString().localeCompare(b_val.toString(), 'en', { numeric: true })
  // a_val = a[orderBy]
  // b_val = b[orderBy]
  
  // if(a_val) a_val = a_val.trim()
  // if(b_val) b_val = b_val.trim()

  // var returnVal = 0
  // if (b_val < a_val) {
  //   returnVal = -1;
  // }
  // if (b_val > a_val) {
  //   returnVal = 1;
  // }
  
  // a_val = !isNaN(a_val)?a_val*1:a_val*1
  // b_val = !isNaN(b_val)?b_val*1:b_val*1
  // if (b_val < a_val) {
  //   returnVal = -1;
  // }
  // if (b_val > a_val) {
  //   returnVal = 1;
  // }
  // return returnVal;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    // if (order !== 0) {
      return order;
    // }
    // return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function BoothListModal(props) {
  const { onClose,  onChange, open } = props;
  var mainLayers = props.main.mainLayers;  
  const [editBooth, setEditBooth]  = useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [deletedboothlist, setDeletedboothlist] = useState([]);
  const [combinedboothlist, setCombinedboothlist] = useState([]);
  const [boothlist, setBoothList] = useState([]);
  const [duplicates, setDuplicates] = useState([]);

  const [keySelect, setKeySelect] = useState('Booth')
  const [keyword, setKeyword] = useState('')
  const [filteredItems, setFilteredItems] = useState([])
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const keywordInput = useRef(null);
  var mainCanvas = props.main.mainCanvas
  useEffect(() => {
    var mainCanvas = props.main.mainCanvas
    if(mainCanvas){
      
    }
  }, [])
  
  useEffect(() => {
    if(tabValue == 3){
      var tmp = getDuplicatedBooths(mainCanvas);
      setDuplicates(tmp)
    }
  }, [tabValue])


  useEffect(() => {
    if(open){
      getBoothList();
      getDeletedList()
    }
  }, [open, props.main.mapRemoveObjects])
  
  const onOk = (e)=>{
    onClose()
  }

  
  const confirmComapnyModal=()=>{

  }

  const getGroupName=(KEY_ID)=>{
    var groupOptions = props.main.groupOptions
    var groupName = ""
    for(var k = 0; k < groupOptions.length; k++){
      if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == KEY_ID){
          var groupName = groupOptions[k].TITLE?.trim()
          if(!groupName) groupName = ""
      }
    }
    return groupName;
  }

  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getBoothList=()=>{
    var boothlist = [];
    if(props.main.mainCanvas){
      
      var convertRateX= 1
      if(props.main.mainCanvas.convertRateX) {
          var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      }
      var objects = props.main.mainCanvas.getObjects();
      for(var i = 0; i< objects.length; i++){
        var object = objects[i]
        var row = {}
        if(object.get('class')=="booth" || object.get('class')=="booth-group"){
        //   var textobjects = object.getObjects('text');
        //   textobjects.sort(function(a, b) {
        //     const nameA = a.get('left'); // ignore upper and lowercase
        //     const nameB = b.get('left'); // ignore upper and lowercase
        //     if (nameA < nameB) {
        //       return -1;
        //     }
        //     if (nameA > nameB) {
        //       return 1;
        //     }
          
        //     // names must be equal
        //     return 0;
        //   })
        //   textobjects.sort(function(a, b) {
        //     const nameA = a.get('left'); // ignore upper and lowercase
        //     const nameB = b.get('left'); // ignore upper and lowercase
        //     return nameA - nameB;
        //   })
        //   if(textobjects && textobjects[0]){
        //       var txt = "";
        //       for(var j = 0; j < textobjects.length; j++){
        //           txt+=(txt==""?"":" ")+textobjects[j].get('text')
        //       }
        //       row['name'] = txt;
        //   }
          row['name'] = object.get('booth_number');
          row['priced'] = false
          var priceCheck = object.get('priceCheck');
          if(priceCheck && priceCheck.length > 0){
            row['priced'] = true
          }
          var premiumCheck = object.get('premiumCheck');
          if(premiumCheck && premiumCheck.length > 0){
            row['priced'] = true
          }
          if(object.get('sold')){
            row['priced'] = true
          }
          var width = px2unit(object.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
          var height = px2unit(object.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
          row['company'] = object.get('company')
          row['boothGroup'] = object.get('boothGroup')
          row['boothType'] = object.get('boothType')
          row['booth'] = object
          row['size'] = width+" x "+height;
          row['sqft'] = getSqrt(object, props)
          // if(row['company']){
          //   row['name'] = row['name'].replace(row['company'],"")
          //   row['name'] = row['name'].replace('\n',"")
          // }
          if(row['name'])
            boothlist.push(row)
        }
      }
    }

    boothlist.sort(function(a, b) {
      const nameA = a.name; // ignore upper and lowercase
      const nameB = b.name; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });
    boothlist.sort(function(a, b) {
      return a.name - b.name;
    });
    setBoothList(boothlist)
    setFilteredItems(boothlist)
  }




  const getDeletedList = ()=>{
    var tmpList = [];
    if(props.main.mainCanvas){
      
      var convertRateX= 1
      if(props.main.mainCanvas.convertRateX) {
          var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      }
      var objects = [...props.main.mapRemoveObjects]
      for(var j = 0; j < objects.length; j++){
        var row = {}
        var object = objects[j];
        if(object && object.type=="activeSelection"){
          var sub_objects = object.objects;
          for(var k = 0; k < sub_objects.length; k++){
            row = {}
            var sub_object = sub_objects[k];
            var width = px2unit(sub_object.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
            var height = px2unit(sub_object.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
            row['activeSelection'] = object
            row['object'] = sub_object
            row['name'] = sub_object.booth_number
            row['size'] = width+" x "+height;
            row['deleteType'] = object.deleteType
            row['deleteTime'] = object.deleteTime
            row['deleteUser'] = object.deleteUser
            tmpList.push(row)
          }
        }
        if(object && object.class=="booth"){
          {
            var width = px2unit(object.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
            var height = px2unit(object.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
            row['object'] = object
            row['name'] = object.booth_number
            row['size'] = width+" x "+height;
            row['deleteType'] = object.deleteType
            row['deleteTime'] = object.deleteTime
            row['deleteUser'] = object.deleteUser
            let combinedBooth = findCombinedBooth(object.booth_number, mainCanvas);
            if(combinedBooth){
              
              var width = px2unit(combinedBooth.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
              var height = px2unit(combinedBooth.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
              combinedBooth.size = width+" x "+height;
              row['combinedBooth'] = combinedBooth
            }
            tmpList.push(row)
          }
        }
      }
    }

    tmpList.sort(function(a, b) {
      const nameA = a.name; // ignore upper and lowercase
      const nameB = b.name; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });
    tmpList.sort(function(a, b) {
      return a.name - b.name;
    });
    setDeletedboothlist(tmpList.filter(item => item.deleteType != 'combined'))
    // setDeletedboothlist(tmpList)
    let tmp = tmpList.filter(item => item.deleteType == 'combined' && item.combinedBooth)
    let combinedboothlist = []; //[{parent: parent, children:children}]
    for(let i = 0; i< tmp?.length; i++){
      let searchedParent = false;
      let searchedParentIndex = 0;
      for(let j = 0; j < combinedboothlist?.length; j++){
        let parent = combinedboothlist[j].parent;
        // console.log("parent", parent, tmp[i].combinedBooth)
        if(parent?.booth_number == tmp[i].combinedBooth?.booth_number){
          searchedParent = combinedboothlist[j];
          searchedParentIndex = j
          break;
        }
      }
      if(!searchedParent){
        searchedParent = {}
        searchedParent.parent = tmp[i].combinedBooth;
        searchedParent.children = [];
        searchedParent.children[0] = tmp[i]
        combinedboothlist = [...combinedboothlist, searchedParent]
      }
      else{
        let children = [...searchedParent.children, tmp[i]];
        searchedParent.children = children
        combinedboothlist[searchedParentIndex] = searchedParent
      }
    }

    for(var i = 0; i < combinedboothlist?.length; i++){
      let parent = combinedboothlist[i].parent;
      let children = combinedboothlist[i].children;
      let child_booths = parent?.child_booths;
      let output = [...children]
      if(child_booths && child_booths?.length){
        for(var j = 0; j < child_booths?.length; j++){
          let searched = false
          for(k = 0; k < children?.length; k++){
            if(children[k].name == child_booths[j].booth_number){
              searched = true;
              break;
            }
          }
          if(!searched) {
            
            var width = px2unit(child_booths[j].width, convertRateX, mainCanvas.mapUnit).toFixed(0)
            var height = px2unit(child_booths[j].height, convertRateX, mainCanvas.mapUnit).toFixed(0)

            let item = {
              name: child_booths[j].booth_number,
              object: child_booths[j],
              size : width+" x "+height,
              deleteType: output[0].deleteType,
              deleteTime: output[0].deleteTime,
              deleteUser: output[0].deleteUser
            }
            output = [item, ...output]
          }
        }
      }
      combinedboothlist[i].children = output

    }
    setCombinedboothlist(combinedboothlist)
    console.log("combinedboothlist", combinedboothlist)
  }

  const handleOpenCompanyModal=(index)=>{    
    props.setEditCompany(boothlist[index])
    props.setOpenCompanyModal(true)
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const unDelete = (object, activeSelection)=>{
    var mainCanvas = props.main.mainCanvas

    var objects = mainCanvas.getObjects();
      for(var j = 0; j < objects.length; j++){
          if(checkIfSelectable(objects[i])&&objects[j].booth_number == object.booth_number&&objects[j].class=="booth"){ 
            props.setOpenSnack(true);           
            props.setSnackMsg("Booth Number exists already");
            return;
          }
      }

    var convertRateX= Math.abs(mainCanvas.convertRateX)
    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)

    // mainCanvas.add(object).renderAll()
    var mapRemoveObjects = [...props.main.mapRemoveObjects]
    if(activeSelection){
      for(var i = 0; i < mapRemoveObjects.length; i++){
        if(mapRemoveObjects[i].uniq_id == activeSelection.uniq_id){
          var sub_objects = mapRemoveObjects[i].objects.filter(item => item.uniq_id !== object.uniq_id)
          mapRemoveObjects[i].objects = sub_objects;
        }
      }
      object.left = object.left+activeSelection.left+activeSelection.width/2
      object.top = object.top+activeSelection.top+activeSelection.height/2
    }
    else{
      mapRemoveObjects = mapRemoveObjects.filter(item => item.uniq_id !== object.uniq_id)
    }
    var objects = [object]
    fabric.util.enlivenObjects(objects, function(objects) {
      // var origRenderOnAddRemove = mainCanvas.renderOnAddRemove;
      // mainCanvas.renderOnAddRemove = false;
      
      objects.forEach(function(o) {
          o.set({
              visible: 1,
              left: o.left+(o.old_deleted?mapOffsetX:0),
              top: o.top+(o.old_deleted?mapOffsetY:0)
              
          })
          changeBorderColor(o, 'black')
          changeBorderWidth(o, 3/Math.max(convertRateX, 1))
          mainCanvas.add(o);
          if(o.class == "booth"){
            props.addLog({
              BOOTH_KEY:o.KEY_ID,
              KEY_ID:uuidv4(),
              action: 'undelete',
              date: new Date().toLocaleString(),
              description: "undeleted booth "+o.booth_number,
              user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
            })
          }
          props.setBoothHistory({ content: (props.main.mainCanvas.toDatalessObject()), action:{type:'undeleted',  target:o}}) 
      });
      
      // mainCanvas.renderOnAddRemove = origRenderOnAddRemove;
      mainCanvas.renderAll(); 
                                
    });
    props.setMapRemoveObjects(mapRemoveObjects)
  }

  const unCombine = (activeObject)=>{
    if (!activeObject) {
        return;
    }
    if (activeObject.type !== 'group' || (activeObject.get('class') !== 'booth-group' && activeObject.get('class') !== 'booth')) {
        return;
    }
    if (activeObject.child_booths) {
      var objects = activeObject.child_booths;
      if(objects.length > 0){
        if(objects[0]._cacheCanvas == undefined && objects[0] !== null)
        {
            fabric.util.enlivenObjects(objects, function(objects) {
                objects.forEach(function(o) {
                    o.new_created = true
                    o.left += activeObject.left
                    o.top += activeObject.top
                    mainCanvas.add(o);
                });
                mainCanvas.renderAll();
                
                mainCanvas.remove(activeObject)      
                var removeObjects = [...props.main.removeObjects, activeObject.uniq_id]
                props.setRemoveObjects(removeObjects) 
                var mapRemoveObjects = [...props.main.mapRemoveObjects, activeObject.toDatalessObject()]
                
                for(var i = 0; i < objects.length; i++){
                    for(var j = 0; j < mapRemoveObjects.length; j++){
                        if(objects[i].booth_number == mapRemoveObjects[j].booth_number){
                            mapRemoveObjects.splice(j, 1)
                        }
                    }
                }
                props.setMapRemoveObjects(mapRemoveObjects)
            });
        }
        else{
            for(var i = 0; i < objects.length; i++){
                var objectA  = objects[i]; 
                objectA.modified = true
                objectA.left += activeObject.left
                objectA.top += activeObject.top
                mainCanvas.add(objectA)
            }
            mainCanvas.renderAll();
            mainCanvas.remove(activeObject).renderAll();
            var removeObjects = [...props.main.removeObjects, activeObject.uniq_id]
            props.setRemoveObjects(removeObjects)
            var mapRemoveObjects = [...props.main.mapRemoveObjects, activeObject.toDatalessObject()]
            for(var i = 0; i < objects.length; i++){
                for(var j = 0; j < mapRemoveObjects.length; j++){
                    if(objects[i].booth_number == mapRemoveObjects[j].booth_number){
                        mapRemoveObjects.splice(j, 1)
                    }
                }
              }
              props.setMapRemoveObjects(mapRemoveObjects)
        }    
      }
      mainCanvas.requestRenderAll();
      var orgName = activeObject.booth_number
      var newBoothNumber = ""
      for(var i = 0; i < objects.length; i++){
          if(objects[i].class == "booth"){
              if(objects[i].booth_number)
              newBoothNumber+=((newBoothNumber == ""?"":",") + objects[i].booth_number)
          }
      }
      if(1){
        props.addLog({
          BOOTH_KEY:activeObject.KEY_ID,
          KEY_ID:uuidv4(),
          action: 'uncombine',
          date: new Date().toLocaleString(),
          description: "uncombined "+orgName+" to "+newBoothNumber,
          user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
        })
        props.setBoothHistory({content: (mainCanvas.toDatalessObject()), action:{type:'uncombine', input:activeObject, output: objects}})
      }
    }
  }
  
  const doSearch=()=>{
    var keyword = keywordInput.current.value
    var filteredItems = boothlist;
    if((keySelect == 'Booth' || keySelect == 'Company' || keySelect == 'Group' || keySelect == 'Type') && keyword) {
      filteredItems = boothlist.filter((item)=>{
        var str = ''
        if(keySelect == 'Booth')
          str = item.name?item.name:''
        else if(keySelect == 'Company')
          str = item.company?item.company:''
        else if(keySelect == 'Group')
          str = item.boothGroup?item.boothGroup:''
        else if(keySelect == 'Type')
          str = item.boothType?item.boothType:''
        return (str.toLowerCase().includes(keyword.toLowerCase()))
      })
    }
    setFilteredItems(filteredItems)
  }

  const searchBox = ()=>{
    return (
      <Paper
        className='search-bar'
        component="form"
        sx={{ p: '0px 0px', display: 'flex', alignItems: 'center' }}
      >
        <FormControl variant="outlined" style={{width:'120px', border:'none'}}>
          <Select
            className='key-select'
            style={{border:'none', outline:'none'}}
            // displayEmpty
            value={keySelect?keySelect:''}
            onChange={(e)=>setKeySelect(e.target.value)}
          >
            <MenuItem value='Booth'>Booth</MenuItem>
            <MenuItem value='Company'>Company</MenuItem>
            <MenuItem value='Group'>Group</MenuItem>
            <MenuItem value='Type'>Type</MenuItem>
          </Select>
        </FormControl>
        
        <Divider sx={{ height: '32px', m: 0.5 }} orientation="vertical" />
        
        {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search"> */}
          <SearchIcon sx={{ p: '5px' }} />
        {/* </IconButton> */}
        <InputBase
          sx={{ flex: 1, width:300  }}
          placeholder="Search by keyword"
          inputProps={{ 'aria-label': 'Search by keyword' }}
          // val={keyword}
          // onChange={(e)=>setKeyword(e.target.value)}
          inputRef={keywordInput}
        />
        <Button onClick={doSearch} className='search-attach' variant="contained" color="primary" sx={{ p: '10px' }} aria-label="directions">
          Search
        </Button>
      </Paper>
    )
  }
  console.log("combinedboothlist", combinedboothlist)
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'boothlist-dialog-title'}}
      aria-labelledby="boothlist-dialog-title"
      className='price-modal'
      fullWidth={true}
      maxWidth={"md"}
      onClose={onClose} open={open}>
      <DialogTitle id='boothlist-dialog-title' style={{display:'flex', alignItems:'center'}}><ListIcon style={{marginRight: 10}}/>Booth Inventory</DialogTitle>
      <DialogContent>
        <Box pt={3}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label={<b>Active</b>} {...a11yProps(0)} />
              <Tab label={<b>Deleted</b>} {...a11yProps(1)} />
              <Tab label={<b>Combined</b>} {...a11yProps(2)} />
              <Tab label={<b>Duplicated</b>} {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            {searchBox()}
            <Box mt={2}>
              <Table className="color-table" sx={{ minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      // width="150px"
                      align={'center'}
                      sortDirection={orderBy === 'name' ? order : false}
                      style={{width:'130px', textAlign:'center'}}
                    >
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={()=>createSortHandler('name')}
                      >
                        Booth Number
                        {orderBy === 'name' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>    
                    <TableCell
                      // width="150px"
                      align={'center'}
                      sortDirection={orderBy === 'size' ? order : false}
                      style={{textAlign:'center', width:'calc((100% - 150px) * 0.25)' }}
                    >
                      <TableSortLabel
                        active={orderBy === 'size'}
                        direction={orderBy === 'size' ? order : 'asc'}
                        onClick={()=>createSortHandler('size')}
                      >
                        Booth Size
                        {orderBy === 'size' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>   
                    <TableCell
                      // width="150px"
                      align={'center'}
                      sortDirection={orderBy === 'sqft' ? order : false}
                      style={{textAlign:'center', width:'calc((100% - 150px) * 0.25)' }}
                    >
                      <TableSortLabel
                        active={orderBy === 'sqft'}
                        direction={orderBy === 'sqft' ? order : 'asc'}
                        onClick={()=>createSortHandler('sqft')}
                      >
                        SqM
                        {orderBy === 'sqft' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>                   
                    <TableCell
                      // width="25%"
                      style={{width:'calc((100% - 150px) * 0.25)', textAlign:'center'}}
                      align={'center'}
                      sortDirection={orderBy === 'boothGroup' ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === 'boothGroup'}
                        direction={orderBy === 'boothGroup' ? order : 'asc'}
                        onClick={()=>createSortHandler('boothGroup')}
                      >
                        Booth Group
                        {orderBy === 'boothGroup' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{width:'calc((100% - 150px) * 0.4)', textAlign:'center'}}
                      align={'center'}
                      sortDirection={orderBy === 'company' ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === 'company'}
                        direction={orderBy === 'company' ? order : 'asc'}
                        onClick={()=>createSortHandler('company')}
                      >
                        Company Name
                        {orderBy === 'company' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{width:'calc((100% - 150px) * 0.2)', textAlign:'center'}}
                      align={'center'}
                      sortDirection={orderBy === 'priced' ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === 'priced'}
                        direction={orderBy === 'priced' ? order : 'asc'}
                        onClick={()=>createSortHandler('priced')}
                      >
                        Priced
                        {orderBy === 'priced' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{width:'calc((100% - 150px) * 0.15)', textAlign:'center'}}
                      align={'center'}
                    >
                        Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(filteredItems, getComparator(order, orderBy)).map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell size="small" style={{textAlign:'center', padding:'4px 7px'}}>{row.name?row.name:''}</TableCell>                                      
                      <TableCell size="small" style={{textAlign: 'center', padding:'4px 7px'}}>{row.size?row.size:''}</TableCell>                                  
                      <TableCell size="small" style={{textAlign: 'center', padding:'4px 7px'}}>{row.sqft?row.sqft:''}</TableCell>
                      <TableCell size="small" style={{padding:'4px 7px'}}>{getGroupName(row.boothGroup)}</TableCell>
                      <TableCell size="small" style={{padding:'4px 7px'}}>{row.company?row.company:''}</TableCell>
                      <TableCell size="small" style={{textAlign:'center', padding:'4px 7px'}}>{row.priced?<CheckIcon fontSize="small" sx={{ color: 'green' }} />:''}</TableCell>
                      <TableCell size="small" style={{textAlign:'center', padding:'4px 7px'}}>
                        {row.priced && row.company &&
                        <IconButton size="small" aria-label="edit" onClick={()=>handleOpenCompanyModal(index)}>
                          <ModeEditIcon fontSize='small' color="action"/>
                        </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Table className="color-table" sx={{ minWidth: 650}} aria-label="simple table">
              <TableHead>
                <TableRow>                  
                  <TableCell style={{textAlign:'center', width:'130px'}}>Booth Number</TableCell>
                  <TableCell style={{textAlign:'center', width:'calc((100% - 150px) * 0.25)' }}>Booth Size</TableCell>
                  <TableCell style={{textAlign:'center', width:'calc((100% - 150px) * 0.25)'}}>Datetime</TableCell>
                  <TableCell style={{textAlign:'center', width:'calc((100% - 150px) * 0.3)'}}>User</TableCell>
                  <TableCell style={{textAlign:'center', width:'calc((100% - 150px) * 0.2)'}}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deletedboothlist.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >   
                    <TableCell size="small" style={{textAlign: 'center', padding:'4px 7px'}}>{row.name?row.name:''}</TableCell>                   
                    <TableCell size="small" style={{textAlign: 'center', padding:'4px 7px'}}>{row.size?row.size:''}</TableCell>
                    <TableCell size="small" style={{textAlign: 'center', padding:'4px 7px'}}>{row.deleteTime?row.deleteTime:''}</TableCell>
                    <TableCell size="small" style={{textAlign: 'center', padding:'4px 7px'}}>{row.deleteUser?row.deleteUser:''}</TableCell>
                    {/* <TableCell style={{textAlign: 'center'}}>{row.deleteType=='combined'?'Combined':'Deleted'}</TableCell> */}
                    <TableCell style={{textAlign: 'center', padding:'4px 7px'}}><Button sx={{padding:0}} onClick={()=>{unDelete(row.object, row.activeSelection)}}>Reactivate</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Table className="color-table" sx={{ minWidth: 650}} aria-label="simple table">
              <TableHead>
                <TableRow>                  
                  <TableCell style={{textAlign:'center', width:'130px'}}>Booth Number</TableCell>
                  <TableCell style={{textAlign:'center', width:'calc((100% - 150px) * 0.15)' }}>Booth Size</TableCell>
                  <TableCell style={{textAlign:'center', width:'calc((100% - 150px) * 0.15)' }}>Datetime</TableCell>
                  <TableCell style={{textAlign:'center', width:'calc((100% - 150px) * 0.15)' }}>User</TableCell>
                  <TableCell style={{textAlign:'center', width:'calc((100% - 150px) * 0.1)' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {combinedboothlist.map((combinedbooth, index) => (
                  <>
                  {
                    combinedbooth?.parent && 
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >                     
                      <TableCell style={{textAlign: 'center', fontWeight:'bold', padding:'4px 7px'}}>{combinedbooth.parent.booth_number?combinedbooth.parent.booth_number:''}</TableCell>
                      <TableCell style={{textAlign: 'center', fontWeight:'bold', padding:'4px 7px'}}>{combinedbooth.parent.size?combinedbooth.parent.size:''}</TableCell>
                      <TableCell style={{textAlign: 'center', padding:'4px 7px'}}></TableCell>
                      <TableCell style={{textAlign: 'center', padding:'4px 7px'}}></TableCell>                 
                      <TableCell style={{textAlign: 'center', padding:'4px 7px'}}><Button sx={{padding:0}} onClick={()=>{unCombine(combinedbooth.parent)}}>Uncombine</Button></TableCell>
                    </TableRow>
                  }
                  {combinedbooth?.children?.map((row, index1) => (
                  <TableRow
                    key={`${index1}-${index1}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >                     
                    <TableCell style={{textAlign: 'center', padding:'4px 7px'}}>{row.name?row.name:''}</TableCell>
                    <TableCell style={{textAlign: 'center', padding:'4px 7px'}}>{row.size?row.size:''}</TableCell>
                    <TableCell style={{textAlign: 'center', padding:'4px 7px'}}>{row.deleteTime?row.deleteTime:''}</TableCell>
                    <TableCell style={{textAlign: 'center', padding:'4px 7px'}}>{row.deleteUser?row.deleteUser:''}</TableCell>
                    <TableCell style={{textAlign: 'center', padding:'4px 7px'}}></TableCell>
                  </TableRow>
                  ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Box mt={2}>
              <Table className="color-table" sx={{ minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{textAlign:'center', width:'130px'}}
                      align={'center'}
                      sortDirection={orderBy === 'name' ? order : false}
                    >
                      Booth Number
                    </TableCell>
                    <TableCell
                      style={{textAlign:'center', width:'calc((100% - 150px) * 0.25)' }}
                      align={'center'}
                      sortDirection={orderBy === 'boothGroup' ? order : false}
                    >
                      Booth Group
                    </TableCell>
                    <TableCell
                      style={{textAlign:'center', width:'calc((100% - 150px) * 0.45)' }}
                      align={'center'}
                      sortDirection={orderBy === 'company' ? order : false}
                    >
                      Company Name
                    </TableCell>
                    <TableCell
                      style={{textAlign:'center', width:'calc((100% - 150px) * 0.15)' }}
                      align={'center'}
                      sortDirection={orderBy === 'priced' ? order : false}
                    >
                      Priced
                    </TableCell>
                    <TableCell
                      style={{textAlign:'center', width:'calc((100% - 150px) * 0.15)' }}
                      align={'center'}
                      sortDirection={orderBy === 'boothType' ? order : false}
                    >
                      Type
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {duplicates?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell style={{padding:'4px 7px'}}>{row.booth_number?row.booth_number:''}</TableCell>
                      <TableCell style={{padding:'4px 7px'}}>{getGroupName(row.boothGroup)}</TableCell>
                      <TableCell style={{padding:'4px 7px'}}>{row.company?row.company:''}</TableCell>
                      <TableCell style={{textAlign:'center', padding:'4px 7px'}}>{(row.priceCheck?.length||row.premiumCheck?.length||row.sold)?<CheckIcon sx={{ color: 'green' }} />:''}</TableCell>
                      <TableCell style={{padding:'4px 7px'}}>{row.boothType?row.boothType:''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
     

    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setLoading:  data=>dispatch(setLoading(data)),
        setMapRemoveObjects:  data=>dispatch(setMapRemoveObjects(data)),
        addLog:  data=>dispatch(addLog(data)),
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),
        setOpenCompanyModal:  data=>dispatch(setOpenCompanyModal(data)),
        setEditCompany: value=>dispatch(setEditCompany(value)),
        setRemoveObjects:  data=>dispatch(setRemoveObjects(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(BoothListModal);