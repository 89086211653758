export const SET_OPEN_COMPANY_PROFILE = 'SET_OPEN_COMPANY_PROFILE'
export const SET_OPEN_SPONSORSHIP_PROFILE = 'SET_OPEN_SPONSORSHIP_PROFILE'
export const SET_HISTORY_SPONSORSHIP_PROFILE = 'SET_HISTORY_SPONSORSHIP_PROFILE'
export const SET_OPEN_UNASSIGNED_PROFILE = 'SET_OPEN_UNASSIGNED_PROFILE'
export const SET_LEFT_PAGE = 'SET_LEFT_PAGE'
export const SET_OPEN_LOGIN = 'SET_OPEN_LOGIN'
export const SET_OPEN_ERROR = 'SET_OPEN_ERROR'
export const SET_OTP_FLAG = 'SET_OTP_FLAG'
export const SET_TOP_MENU = 'SET_TOP_MENU'
export const SET_OPEN_NEW_PASSWORD = 'SET_OPEN_NEW_PASSWORD'
export const SET_OPEN_SIGNUP = 'SET_OPEN_SIGNUP'
export const SET_OPEN_RESET_PASSWORD = 'SET_OPEN_RESET_PASSWORD'
export const SET_OPEN_SNACK = 'SET_OPEN_SNACK'
export const SET_SNACK_MSG = 'SET_SNACK_MSG'
export const SET_LEFT_SELECTED_CATEGORY = 'SET_LEFT_SELECTED_CATEGORY'
export const SET_LEFT_SEARCH_VAL = 'SET_LEFT_SEARCH_VAL'
export const SET_LEFT_BOOTHLIST = 'SET_LEFT_BOOTHLIST'
export const SET_BOOKMARD_UPDATED = 'SET_BOOKMARD_UPDATED'
export const SET_PAN_FLAG = 'SET_PAN_FLAG'
export const SET_LOCK_FLAG = 'SET_LOCK_FLAG'
export const RESET = 'RESET'
export const SET_OPEN_BOOTHLIST = 'SET_OPEN_BOOTHLIST'
export const SET_EXHIBITOR_SEARCH_KEY = 'SET_EXHIBITOR_SEARCH_KEY'
export const SET_EXHIBITOR_UPDATED = 'SET_EXHIBITOR_UPDATED'
export const SET_OPEN_FILE_NAME_MODAL = 'SET_OPEN_FILE_NAME_MODAL'
export const SET_OPEN_SETBOOTH_MODAL = 'SET_OPEN_SETBOOTH_MODAL'
export const SET_OPEN_TABLE_MODAL = 'SET_OPEN_TABLE_MODAL'
export const SET_OPEN_ASSIGN_MODAL = 'SET_OPEN_ASSIGN_MODAL'
export const SET_OPEN_TRANSFER_MODAL = 'SET_OPEN_TRANSFER_MODAL'
export const SET_OPEN_RELEASE_MODAL = 'SET_OPEN_RELEASE_MODAL'
export const SET_OPEN_BOOTH_DRAWER = 'SET_OPEN_BOOTH_DRAWER'
export const CLOSE_SNACK = 'CLOSE_SNACK'
export const OPEN_SNACK = 'OPEN_SNACK'
export const SNACK_MSG = 'SNACK_MSG'
export const SET_CUSTOM_STYLE = 'SET_CUSTOM_STYLE'
export const SET_OPEN_IMPORT_MODAL = 'SET_OPEN_IMPORT_MODAL'
export const SET_OPEN_COMPANY_MODAL = 'SET_OPEN_COMPANY_MODAL'
export const SET_EDIT_COMPANY = 'SET_EDIT_COMPANY'
export const SET_MOUSE_HOVER_ENABLE = 'SET_MOUSE_HOVER_ENABLE'
export const SET_EXHIBITOR_LIST_SCROLL = 'SET_EXHIBITOR_LIST_SCROLL'

export const setOpenImportmodal = data =>({
    type:SET_OPEN_IMPORT_MODAL,
    payload:data
});
export const setOtpFlag = data =>({
    type:SET_OTP_FLAG,
    payload:data
});
export const handleGlobalCloseSnack = data =>({
    type:CLOSE_SNACK,
    payload:data
});
export const setGlobalOpenSnack = data =>({
    type:OPEN_SNACK,
    payload:data
});
export const setGlobalSnackMsg = data =>({
    type:SNACK_MSG,
    payload:data
});

export const setOpenBoothDrawer = data =>({
    type:SET_OPEN_BOOTH_DRAWER,
    payload:data
});
export const setOpenSetTableModal = data =>({
    type:SET_OPEN_TABLE_MODAL,
    payload:data
});

export const setOpenAssignmentModal = data =>({
    type:SET_OPEN_ASSIGN_MODAL,
    payload:data
});

export const setOpenTransferModal = data =>({
    type:SET_OPEN_TRANSFER_MODAL,
    payload:data
});

export const setOpenReleaseModal = data =>({
    type:SET_OPEN_RELEASE_MODAL,
    payload:data
});

export const setOpenFilenameModal = data =>({
    type:SET_OPEN_FILE_NAME_MODAL,
    payload:data
});

export const setOpenSetBoothModal = data =>({
    type:SET_OPEN_SETBOOTH_MODAL,
    payload:data
});

export const setOpenCompanyProfile = data =>({
    type:SET_OPEN_COMPANY_PROFILE,
    payload:data
});

export const setOpenSponsorshipProfile = data =>({
    type:SET_OPEN_SPONSORSHIP_PROFILE,
    payload:data
});

export const setHistorySponsorshipProfile = data =>({
    type:SET_HISTORY_SPONSORSHIP_PROFILE,
    payload:data
});

export const setOpenUnassignedProfile = data =>({
    type:SET_OPEN_UNASSIGNED_PROFILE,
    payload:data
});
export const setLeftPage = data =>({
    type:SET_LEFT_PAGE,
    payload:data
});

export const setOpenError = data =>({
    type:SET_OPEN_ERROR,
    payload:data
});

export const setOpenLogin = data =>({
    type:SET_OPEN_LOGIN,
    payload:data
});

export const setMenuList = data =>({
    type:SET_TOP_MENU,
    payload:data
});

export const setOpenNewPassword = data =>({
    type:SET_OPEN_NEW_PASSWORD,
    payload:data
});

export const setOpenSignup = data =>({
    type:SET_OPEN_SIGNUP,
    payload:data
});

export const setOpenResetPassword = data =>({
    type:SET_OPEN_RESET_PASSWORD,
    payload:data
});

export const setOpenSnack = data =>({
    type:SET_OPEN_SNACK,
    payload:data
});

export const setSnackMsg = data =>({
    type:SET_SNACK_MSG,
    payload:data
});

export const setLeftSelectedCategory = data =>({
    type:SET_LEFT_SELECTED_CATEGORY,
    payload:data
});

export const setLeftSearchVal = data =>({
    type:SET_LEFT_SEARCH_VAL,
    payload:data
});

export const setLeftBoothList = data =>({
    type:SET_LEFT_BOOTHLIST,
    payload:data
});
export const setBookmardUpdated = data =>({
    type:SET_BOOKMARD_UPDATED,
    payload:data
});

export const setPanFlag = data =>({
    type:SET_PAN_FLAG,
    payload:data
});

export const setLockFlag  = data =>({
    type:SET_LOCK_FLAG,
    payload:data
});

export const resetUI = data =>({
    type:RESET,
    payload:data
});


export const setOpenBoothList = data =>({
    type:SET_OPEN_BOOTHLIST,
    payload:data
});

export const setExhibitorSearchKey = data =>({
    type:SET_EXHIBITOR_SEARCH_KEY,
    payload:data
});


export const setExhibitorUpdated = data =>({
    type:SET_EXHIBITOR_UPDATED,
    payload:data
});


export const setCustomStyles = data =>({
    type:SET_CUSTOM_STYLE,
    payload:data
});

export const setOpenCompanyModal = data =>({
    type:SET_OPEN_COMPANY_MODAL,
    payload:data
});

export const setEditCompany = data =>({
    type:SET_EDIT_COMPANY,
    payload:data
});

export const setMouseHoverEnable = data =>({
    type:SET_MOUSE_HOVER_ENABLE,
    payload:data
});

export const setExhibitorListScroll = data =>({
    type:SET_EXHIBITOR_LIST_SCROLL,
    payload:data
});

