import React,{ useEffect, useRef, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { connect } from 'react-redux'

import './index.css';
import ClientApp from './ClientApp';
import D2AdminApp from './D2AdminApp';
import D3AdminApp from './D3AdminApp';
import CustomRouter from './CustomRouter'
import StaticRouter from './StaticRouter'
import axios from 'axios';
import { API_URL, INITIAL_DOMAIN } from './config.js'
import { setSnackMsg, setOpenSnack, handleGlobalCloseSnack } from './actions/ui'
import CloseIcon from '@mui/icons-material/Close';

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
  };
}

var intervalHeartBeat = null;
function App(props) {
  useEffect(()=>{
    // intervalHeartBeat = setInterval(() => {
    //   var url = '/heartbeat'
    //   axios.get(url).then(res=>{
    //   }).catch(error => {
    //     props.setOpenSnack(true);
    //     props.setSnackMsg("Connection failed");
    //   })
    // }, 10*1000); 
      
    // return ()=>{
    //     if(intervalHeartBeat){
    //         clearInterval(intervalHeartBeat);
    //         intervalHeartBeat = null
    //     }
    // }
  }, [])

  return (
    <div>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" exact element={<ClientApp page ="2d"/>}/>
            <Route path="/V3_MAPS" exact element={<ClientApp page ="2d"/>}/>
            <Route path="/V3_SEARCH" exact element={<ExhibitorSearch page ="2d"/>}/>
            <Route path="/V3_FEATURED" exact element={<ExhibitorFeatured page ="2d"/>}/>
            <Route path="/V3_PRODUCTS" exact element={<ProductFeatured page ="2d"/>}/>
            <Route path="/V3_MYPLANNER" exact element={<MyPlanner page ="2d"/>}/> */}
            <Route path="/" exact element={<StaticRouter page ="2d" path="/"/>}/>
            <Route path="/V3_MAPS" exact element={<StaticRouter page ="2d" path="/V3_MAPS"/>}/>
            <Route path="/V3_SEARCH" exact element={<StaticRouter page ="2d" path="/V3_SEARCH"/>}/>
            <Route path="/V3_FEATURED" exact element={<StaticRouter page ="2d" path="/V3_FEATURED"/>}/>
            <Route path="/V3_PRODUCTS" exact element={<StaticRouter page ="2d" path="/V3_PRODUCTS"/>}/>
            <Route path="/V3_MYPLANNER" exact element={<StaticRouter page ="2d" path="/V3_MYPLANNER"/>}/>
            <Route path="/product_showcase" exact element={<StaticRouter page ="2d" path="/product_showcase"/>}/>
            <Route path="/V3_SPECIALS" exact element={<StaticRouter page ="2d" path="/V3_SPECIALS"/>}/>
            <Route path="/V3_CONTACTS" exact element={<StaticRouter page ="2d" path="/V3_CONTACTS"/>}/>
            <Route path="/V3_PRESS" exact element={<StaticRouter page ="2d" path="/V3_PRESS"/>}/>
            
            <Route path="/three" exact element={<ClientApp page="3d"/>}/>
            <Route path=":id1/:id2/three" exact element={<ClientApp page="3d" urlToken={true}/>}/>
            <Route path=":id1/:id2/:id3/three" exact element={<ClientApp page="3d" urlToken={true}/>}/>
            
            <Route path="/admin" exact element={<D2AdminApp />}/>
            <Route path="/admin/three" element={<D3AdminApp />}/>    
            
            <Route path=":id1/:id2/heatmap" element={<CustomRouter page ="2d" path="heatmap" urlToken={true}/>}/>
            <Route path=":id1/:id2/:id3/heatmap" element={<CustomRouter page ="2d" path="heatmap" urlToken={true}/>}/>

            <Route path=":id1/:id2" element={<CustomRouter page ="2d" urlToken={true}/>}></Route>
            <Route path=":id1/:id2/:id3" element={<CustomRouter page ="2d" urlToken={true}/>}>
              {/* <Route path=":id4" element={<CustomRouter page ="2d" urlToken={true}/>}/> */}
            </Route>
            <Route path=":id1/:id2/:id3/:id4" element={<CustomRouter page ="2d" urlToken={true}/>}>
            </Route>
            <Route path=":id1/:id2/:id3/:id4/:id5" element={<CustomRouter page ="2d" urlToken={true}/>}>
            </Route>
            <Route path=":id1/:id2/:id3/:id4/:id5/:id6" element={<CustomRouter page ="2d" urlToken={true}/>}>
            </Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
      <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          sx={{
            zIndex: 10001, 
          }}
          open={props.ui.openSnack}
          autoHideDuration={6000}
          onClose={props.handleGlobalCloseSnack}
          message={props.ui.snackMsg}
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={props.handleGlobalCloseSnack}>
                  <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
          }
      />
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    log: state.log,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setSnackMsg: data=>dispatch(setSnackMsg(data)),
    setOpenSnack: data=>dispatch(setOpenSnack(data)),
    handleGlobalCloseSnack:  data=>dispatch(handleGlobalCloseSnack(data))      
  };
};

export default connect(mapStateToProps, mapDispatchProps)(App);
