import React ,{ useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import { fabric } from "fabric";
// import Cropper from 'react-easy-crop'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import getCroppedImg from '../utils'
import { API_URL, S3_URL } from '../../config.js'
import { setLoading } from '../../actions/main'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CropModal from './CropModal'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import OutlinedInput from '@mui/material/OutlinedInput';
import ColorModal from "./ColorModal";
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, setObjectBG} from '../../utils/CanvasTool'

import {PaperComponent} from '../../utils/Common'


function Index(props) {
  const { onClose,  onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [selectedCropItem, setSelectedTreeCropItem] = useState(null)
  const [openCropModal, setOpenCropModal] = useState('')  
  const [cropLogoData, setCropLogoData] = useState(null)
  const [cropLogoPreviewData, setCropLogoPreviewData] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [imgSrc, setImgSrc] = useState('')
  const [size, setSize] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const cropperRef = useRef(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    // console.log(croppedArea, croppedAreaPixels)
  }, [])
  const [openColorModal, setOpenColorModal] = React.useState(null);
  const [openBgColorModal, setOpenBgColorModal] = React.useState(null);
  
  const [fontSizeCustom, setFontSizeCustom] = useState(16)
  const [fontStyleCustom, setFontStyleCustom] = useState("normal")
  const [bgColorCustom, setBgColorCustom] = useState('transparent')
  const [borderColorCustom, setBorderColorCustom] = useState('black')
  const [borderSizeCustom, setBorderSizeCustom] = useState(1)
  const [borderPattern, setBorderPattern] = useState('solid')
  const [title, setTitle] = useState("")
  const [transparency, setTransparency] = useState(4)
  const [description, setDescription] = useState("")
  const [fontColorCustom, setFontColorCustom] = useState('black')

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  useEffect(() => {
    if(open){
      setImgSrc(null)
      if(props.object.bg_img){
        setCropLogoPreviewData(props.object.bg_img)
      }
      else{
        setCropLogoPreviewData(null)
      }
      
      if(props.object.fontSizeCustom){
        setFontSizeCustom(props.object.fontSizeCustom)
      }
      if(props.object.fontStyleCustom){
        setFontStyleCustom(props.object.fontStyleCustom)
      }
      if(props.object.borderColorCustom){
        setBorderColorCustom(props.object.borderColorCustom)
      }
      if(props.object.bgColorCustom){
        setBgColorCustom(props.object.bgColorCustom)
      }
      
      if(props.object.borderSizeCustom){
        setBorderSizeCustom(props.object.borderSizeCustom)
      }
      if(props.object.borderPattern){
        setBorderPattern(props.object.borderPattern)
      }
      if(props.object.title){
        setTitle(props.object.title)
      }
      if(props.object.transparency){
        setTransparency(props.object.transparency)
      }
      if(props.object.description){
        setDescription(props.object.description)
      }
      if(props.object.fontColorCustom){
        setFontColorCustom(props.object.fontColorCustom)
      }
      
    }
  }, [open])
  
  function roundedCorners(ctx) {
    var rect = new fabric.Rect({
      left:0,
      top:0,
      rx:20 / this.scaleX,
      ry:20 / this.scaleY,
      width:this.width,
      height:this.height,
      fill:'#000000'
    });
    rect._render(ctx, false);
  }

  const onOk = async (e)=>{
    var outObject = props.object
    if(outObject && outObject.class1 == 'text'){
      let iText = new fabric.Text(title, {});
      iText.set({
        left: outObject.left,
        top: outObject.top,
        fontSize:fontSizeCustom, 
        fontStyle:fontStyleCustom,
        layer:'annotation',
        fill: fontColorCustom
      });
  
  
      let textGroup = new fabric.Group([iText]).set({      
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
      });
      textGroup.set({
        layer:'annotation',
        class1:'text',
        id:outObject.id,
        uniq_id:outObject.uniq_id
      })
      props.main.mainCanvas.remove(outObject)
      props.main.mainCanvas.add(textGroup).renderAll();
      outObject = textGroup;
    }

    setObjectBG(props.main.mainCanvas, outObject, bgColorCustom)

    if(cropLogoData) {
      var filename = "bg.png"
        // const reader = new FileReader();
      var data = cropLogoData.slice(0, cropLogoData.size);
      var filePath = `asset/${uuidv4()}`
      var url = `${S3_URL()}uploadfile?filePath=${filePath}`
      props.setLoading(true)
      const headers = {headers: {'Content-Type': 'multipart/form-data'}};
      const response = await axios.post(url, data, headers);
      if(response.status == 200){
        outObject.set({
          bg_img:`${S3_URL()}s3/${filePath}`, 
        })
        var objectImgs = outObject.getObjects('image');
        if(objectImgs){
          for(var i = 0; i < objectImgs.length; i++){
            outObject.remove(objectImgs[i])
          }
        }
        fabric.Image.fromURL(`${S3_URL()}s3/${filePath}`, function(img) {
          img.set('left', 0-outObject.width/2).set('top', 0-outObject.height/2).set({scaleX: outObject.width/img.width, scaleY: outObject.height/img.height});
          if(outObject.class1 == 'circle'){
            var sub_objects = outObject.getObjects('circle');
            if(sub_objects && sub_objects.length > 0){
              var circle  = sub_objects[0];
              circle.clone(function(cloned) {
                outObject.clipPath = cloned                                    
              });
            }
          }
          if(outObject.class1 == 'rect'){
            var sub_objects = outObject.getObjects('rect');
            if(sub_objects && sub_objects.length > 0){
              var circle  = sub_objects[0];
              circle.clone(function(cloned) {
                outObject.clipPath = cloned                                    
              });
            }
          }
          outObject.add(img);              
          img.sendToBack();
          props.main.mainCanvas.setZoom(props.main.mainCanvas.getZoom()*1.000000001)
          setObjectBG(props.main.mainCanvas, outObject, 'transparent')
          props.main.mainCanvas.renderAll()
        },{
          crossOrigin: 'anonymous'
        });
      }
      props.setLoading(false)
    }

    
    outObject.set({fontSizeCustom:fontSizeCustom})
    outObject.set({fontStyleCustom:fontStyleCustom})
    outObject.set({title:title})
    outObject.set({description:description})
    outObject.set({borderColorCustom:borderColorCustom})
    outObject.set({bgColorCustom:bgColorCustom})
    outObject.set({fontColorCustom:fontColorCustom})
    outObject.set({borderSizeCustom:borderSizeCustom})
    outObject.set({borderPattern:borderPattern})
    outObject.set({transparency: transparency})
    var textObjects = outObject.getObjects('text');
    for(var txtInd = 0; txtInd< textObjects.length; txtInd++){
      textObjects[txtInd].set({        
        fontSize:fontSizeCustom,
        fill: fontColorCustom
      })
      textObjects[txtInd].set({        
        fontStyle:fontStyleCustom,
      })
      textObjects[txtInd].set({        
        text:title,
      })
      if(title && outObject.class1 != 'text'){
        textObjects[txtInd].set({        
          left:-fontSizeCustom/4*title.length,
          top:-fontSizeCustom/2,
        })
      }
    }
    var objects = outObject.getObjects();
    for(var i = 0; i < objects.length; i++){
      if(objects[i].type != 'text'){
        objects[i].stroke=borderColorCustom
        objects[i].strokeWidth=borderSizeCustom
        if(borderPattern == 'solid'){
          objects[i].strokeDashArray=[]
        }
        else if(borderPattern == 'dashed'){
          objects[i].strokeDashArray=[2,2]
        }
      }
    }
    
    outObject.set({opacity: transparency/4})
    outObject.set({modified: true})
    props.main.mainCanvas.renderAll()
    // if(props.object.class1 == 'text'){
    //   var textObjects = props.object.getObjects('text');
    //   if(textObjects && textObjects.length > 0){
    //     props.object.width = textObjects[0].width;
    //     props.object.height = textObjects[0].height;
    //   }
    // }
    props.onOk()
  }

  
  const onSelectLogo = (e)=> {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        setImgSrc(reader.result.toString() || '')
        setOpenCropModal(true)
        setSelectedTreeCropItem('logo')
        e.target.value=null;
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const confirmCropModal=(dataImg, dataUrl)=>{
    if(selectedCropItem == "logo"){
      setCropLogoData(dataImg)
      setCropLogoPreviewData(dataUrl)
    }
    setOpenCropModal(false)
  }

  const handleCloseCropModal=()=>{
    setOpenCropModal(false)
  }

  var ratioCrop = 4/4;
  if(props.object.width && props.object.height){
    ratioCrop = props.object.width/props.object.height
  }
  var shape = '';
  if(props.object && props.object.class1 == 'circle'){
    shape = 'circle'
  }
  
  else if(props.object && props.object.class1 == 'text'){
    shape = 'text'
  }
  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'setdraw-dialog-title'}}
      aria-labelledby="setdraw-dialog-title" 
      fullWidth={true}
      maxWidth={"sm"}
      onClose={onClose} open={open}>
      <DialogTitle id='setdraw-dialog-title' style={{display:'flex', alignItems:'center'}}><AddPhotoAlternateIcon style={{marginRight: 10}}/>Setup</DialogTitle>
      <DialogContent>
        
        <Box mt={3}> 
          <Grid container spacing={2} style={{marginTop:15}}>
            <Grid item xs={6}>
              <TextField
                name="size"
                size="small"
                margin="dense"
                label="Font size"
                
                fullWidth
                variant="outlined"
                value={fontSizeCustom}
                onChange={(e)=>setFontSizeCustom(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth style={{marginTop:'7px'}}>
                <InputLabel shrink={true}>Font style</InputLabel>
                <Select
                  size="small"
                  defaultValue={'normal'}
                  value = {fontStyleCustom}
                  input={<OutlinedInput notched label="Font style" />}
                  onChange={(e)=>setFontStyleCustom(e.target.value)}
                >
                  <MenuItem value={'normal'}>Normal</MenuItem>
                  <MenuItem value={'italic'}>Italic</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {shape != 'text' &&
            <Grid item xs={6}>
              <p style={{fontSize:13, color: 'rgba(0, 0, 0, 0.6)', margin:0, lineHeight: 1}}>Fill color</p>
              <div onClick={()=>setOpenBgColorModal(true)} style={{cursor:'pointer', backgroundColor: bgColorCustom, width:45, height: 22, marginTop: '10px', border: '1px solid #d0d0d0'}}></div>
              
              <ColorModal open = {openBgColorModal} onClose={()=>setOpenBgColorModal(false)} onOk = {(color)=>{setBgColorCustom(color); setOpenBgColorModal(false)} }/>
              {/* <TextField
                name="size"
                size="small"
                margin="dense"
                label="Border color"
                
                fullWidth
                variant="outlined"
                value={borderColorCustom}
                onChange={(e)=>setBorderColorCustom(e.target.value)}
              /> */}
            </Grid>
            }
            {shape != 'text' &&
            <Grid item xs={6}>
              <p style={{fontSize:13, color: 'rgba(0, 0, 0, 0.6)', margin:0, lineHeight: 1}}>Border color</p>
              <div onClick={()=>setOpenColorModal(true)} style={{cursor:'pointer', backgroundColor: borderColorCustom, width:45, height: 22, marginTop: '10px', border: '1px solid #d0d0d0'}}></div>
              
              <ColorModal open = {openColorModal} onClose={()=>setOpenColorModal(false)} onOk = {(color)=>{setBorderColorCustom(color); setOpenColorModal(false)} }/>
              {/* <TextField
                name="size"
                size="small"
                margin="dense"
                label="Border color"
                
                fullWidth
                variant="outlined"
                value={borderColorCustom}
                onChange={(e)=>setBorderColorCustom(e.target.value)}
              /> */}
            </Grid>
            }
            {shape != 'text' &&
            <Grid item xs={6}>
              <TextField
                name="size"
                size="small"
                margin="dense"
                label="Border size"
                
                fullWidth
                variant="outlined"
                value={borderSizeCustom}
                onChange={(e)=>setBorderSizeCustom(e.target.value)}
              />
            </Grid>
            }
            {shape != 'text' &&
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth style={{marginTop:'7px'}}>
                <InputLabel shrink={true}>Border pattern</InputLabel>
                <Select
                  size="small"
                  defaultValue={'solid'}
                  value={borderPattern}
                  input={<OutlinedInput notched label="Border pattern" />}
                  onChange={(e)=>setBorderPattern(e.target.value)}
                >
                  <MenuItem value={'solid'}>solid</MenuItem>
                  <MenuItem value={'dashed'}>dashed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            }
            {shape == 'text' &&
            <Grid item xs={6}>
              <p style={{fontSize:13, color: 'rgba(0, 0, 0, 0.6)', margin:0, lineHeight: 1}}>Color</p>
              <div onClick={()=>setOpenColorModal(true)} style={{cursor:'pointer', backgroundColor: fontColorCustom, width:45, height: 22, marginTop: '10px', border: '1px solid #d0d0d0'}}></div>
              
              <ColorModal open = {openColorModal} onClose={()=>setOpenColorModal(false)} onOk = {(color)=>{setFontColorCustom(color); setOpenColorModal(false)} }/>
            </Grid>
            }
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth style={{marginTop:'7px'}}>
                <InputLabel shrink={true}>Transparency</InputLabel>
                <Select
                  size="small"
                  defaultValue={'1'}
                  value={transparency}
                  input={<OutlinedInput notched label="Transparency" />}
                  onChange={(e)=>setTransparency(e.target.value)}
                >
                  <MenuItem value={'1'}>1</MenuItem>
                  <MenuItem value={'2'}>2</MenuItem>
                  <MenuItem value={'3'}>3</MenuItem>
                  <MenuItem value={'4'}>4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="size"
                size="small"
                margin="dense"
                label="Title"
                
                fullWidth
                variant="outlined"
                value={title}
                onChange={(e)=>setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="size"
                size="small"
                margin="dense"
                label="Description"
                multiline
                
                rows = {4}
                fullWidth
                variant="outlined"
                value={description}
                onChange={(e)=>setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}> 
          <FormLabel>Background</FormLabel><br/>
          <FormControl variant="outlined">
            <input type="file" accept="image/*" onChange={onSelectLogo} />
          </FormControl>
              
          {cropLogoPreviewData&&
            <div className='preview-image' style={{height:'auto'}}>
              <img style={{ width: "100%" }} src={cropLogoPreviewData} alt="cropped" />
            </div>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      
      <CropModal
          open={openCropModal}
          onClose={handleCloseCropModal}
          onOk={confirmCropModal}
          imgSrc={imgSrc}
          aspectRatio= {ratioCrop}
          initialAspectRatio = {ratioCrop}
          shape = {shape}
      />
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setLoading:  data=>dispatch(setLoading(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);