import logo from './logo.svg';
import './App.css';
import MainViewerAdmin from './viewer/MainViewerAdmin';
function App(props) {
  return (
    <div className="App">
      {/* <ToolBoxAdmin {...props}/> */}
      <MainViewerAdmin {...props}/>
    </div>
  );
}

export default App;
