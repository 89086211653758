export const base_json = 
{
  "Drawing": {
    "Metadata": {
      "FileName": "c:/drawings/casper/one_of_simple.dwg",
      "Version": "33",
      "VersionName": "AutoCAD 2018/2019/2020/2021"
    },
    "Header": {
      "Filename": "c:/drawings/casper/one_of_simple.dwg",
      "File_DWG_Version": "AC1032",
      "ACAD_path": "",
      "CODEPAGE": "30",
      "MEASUREMENT": "kEnglish",
      "TDCREATE": "Thu Jan  2 10:31:16 2003",
      "TDUPDATE": "Thu Jul 16 03:24:45 2020",
      "ANGBASE": "0.0",
      "ANGDIR": "false",
      "ATTMODE": "1",
      "AUNITS": "0",
      "AUPREC": "0",
      "CECOLOR": "ByLayer",
      "CELTSCALE": "1.0",
      "CHAMFERA": "0.0",
      "CHAMFERB": "0.0",
      "CHAMFERC": "0.0",
      "CHAMFERD": "0.0",
      "CMLJUST": "0",
      "CMLSCALE": "1.0",
      "DIMADEC": "0",
      "DIMALT": "false",
      "DIMALTD": "2",
      "DIMALTF": "25.4",
      "DIMALTRND": "0.0",
      "DIMALTTD": "2",
      "DIMALTTZ": "0",
      "DIMALTU": "2",
      "DIMALTZ": "0",
      "DIMAPOST": "&quot;&quot;",
      "DIMASZ": "0.18",
      "DIMATFIT": "3",
      "DIMAUNIT": "0",
      "DIMAZIN": "0",
      "DIMBLK": "Null",
      "DIMBLK1": "Null",
      "DIMBLK2": "Null",
      "DIMCEN": "-0.09",
      "DIMCLRD": "ByBlock",
      "DIMCLRE": "ByBlock",
      "DIMCLRT": "ByBlock",
      "DIMDEC": "4",
      "DIMDLE": "0.0",
      "DIMDLI": "0.38",
      "DIMDSEP": "46",
      "DIMEXE": "0.18",
      "DIMEXO": "0.0625",
      "DIMFRAC": "0",
      "DIMGAP": "0.09",
      "DIMJUST": "0",
      "DIMLDRBLK": "Null",
      "DIMLFAC": "1.0",
      "DIMLIM": "false",
      "DIMLUNIT": "2",
      "DIMLWD": "kLnWtByBlock",
      "DIMLWE": "kLnWtByBlock",
      "DIMPOST": "&quot;&quot;",
      "DIMRND": "0.0",
      "DIMSAH": "false",
      "DIMSCALE": "1.0",
      "DIMSD1": "false",
      "DIMSD2": "false",
      "DIMSE1": "false",
      "DIMSE2": "false",
      "DIMSOXD": "false",
      "DIMTAD": "0",
      "DIMTDEC": "4",
      "DIMTFAC": "1.0",
      "DIMTIH": "true",
      "DIMTIX": "false",
      "DIMTM": "0.0",
      "DIMTOFL": "false",
      "DIMTOH": "true",
      "DIMTOL": "false",
      "DIMTOLJ": "1",
      "DIMTP": "0.0",
      "DIMTSZ": "0.0",
      "DIMTVP": "0.0",
      "DIMTXSTY": "Standard",
      "DIMTXT": "0.18",
      "DIMTZIN": "0",
      "DIMUPT": "false",
      "DIMZIN": "0",
      "DISPSILH": "false",
      "DRAWORDERCTL": "3",
      "ELEVATION": "0.0",
      "EXTMAX": {
        "x": "16.2341",
        "y": "8.32871",
        "z": "0"
      },
      "EXTMIN": {
        "x": "-0.201633",
        "y": "-0.143946",
        "z": "0"
      },
      "FACETRES": "0.5",
      "FILLETRAD": "0.0",
      "FILLMODE": "true",
      "INSBASE": {
        "x": "0",
        "y": "0",
        "z": "0"
      },
      "ISOLINES": "4",
      "LIMCHECK": "false",
      "LIMMAX": {
        "x": "12",
        "y": "9"
      },
      "LIMMIN": {
        "x": "0",
        "y": "0"
      },
      "LTSCALE": "1.0",
      "LUNITS": "2",
      "LUPREC": "4",
      "MAXACTVP": "64",
      "MIRRTEXT": "false",
      "ORTHOMODE": "false",
      "PDMODE": "0",
      "PDSIZE": "0.0",
      "PELEVATION": "0.0",
      "PELLIPSE": "false",
      "PEXTMAX": {
        "x": "0",
        "y": "0",
        "z": "0"
      },
      "PEXTMIN": {
        "x": "0",
        "y": "0",
        "z": "0"
      },
      "PINSBASE": {
        "x": "0",
        "y": "0",
        "z": "0"
      },
      "PLIMCHECK": "false",
      "PLIMMAX": {
        "x": "12",
        "y": "9"
      },
      "PLIMMIN": {
        "x": "0",
        "y": "0"
      },
      "PLINEGEN": "false",
      "PLINEWID": "0.0",
      "PROXYGRAPHICS": "1",
      "PSLTSCALE": "true",
      "PUCSNAME": "Null",
      "PUCSORG": {
        "x": "0",
        "y": "0",
        "z": "0"
      },
      "PUCSXDIR": {
        "x": "1",
        "y": "0",
        "z": "0"
      },
      "PUCSYDIR": {
        "x": "0",
        "y": "1",
        "z": "0"
      },
      "QTEXTMODE": "false",
      "REGENMODE": "true",
      "SHADEDGE": "3",
      "SHADEDIF": "70",
      "SKETCHINC": "0.1",
      "SKPOLY": "false",
      "SPLFRAME": "false",
      "SPLINESEGS": "8",
      "SPLINETYPE": "6",
      "SURFTAB1": "6",
      "SURFTAB2": "6",
      "SURFTYPE": "6",
      "SURFU": "6",
      "SURFV": "6",
      "TEXTQLTY": "50",
      "TEXTSIZE": "20",
      "THICKNESS": "0.0",
      "TILEMODE": "true",
      "TRACEWID": "0.05",
      "TREEDEPTH": "3020",
      "UCSNAME": "Null",
      "UCSORG": {
        "x": "0",
        "y": "0",
        "z": "0"
      },
      "UCSXDIR": {
        "x": "1",
        "y": "0",
        "z": "0"
      },
      "UCSYDIR": {
        "x": "0",
        "y": "1",
        "z": "0"
      },
      "UNITMODE": "0",
      "USERI1": "0",
      "USERI2": "0",
      "USERI3": "0",
      "USERI4": "0",
      "USERI5": "0",
      "USERR1": "0.0",
      "USERR2": "0.0",
      "USERR3": "0.0",
      "USERR4": "0.0",
      "USERR5": "0.0",
      "USRTIMER": "true",
      "VISRETAIN": "true",
      "WORLDVIEW": "true"
    },
    "Layers": [
      {
        "Name": "0",
        "On": "True",
        "Frozen": "False",
        "Color": "Foreground",
        "Linetype": "Continuous",
        "Lineweight": "kLnWtByLwDefault"
      }
    ],
    "Blocks": [
      {
        "Block_Name": "*Model_Space",
        "Filename": "",
        "Loaded": "True",
        "Overlay": "False",
        "External_Reference": "False",
        "Path_Name": "",
        "Origin": {
          "x": "0",
          "y": "0",
          "z": "0"
        },
        "Entities": [
         
        ]
      }
    ]
  }
}

export const base_entity = 
{
  "Type": "Polyline",
  "Handle": "139",
  "Layer": "0",
  "Color_Index": "256",
  "Color": "ByLayer",
  "Linetype": "ByLayer",
  "Normal": {
    "x": "0",
    "y": "0",
    "z": "1"
  },
  "Thickness": "0",
  "Has_Width": "False",
  "Constant_Width": "0",
  "Has_Bulges": "False",
  "Elevation": "0",
  "Closed": "False",
  "Vertices": [
  ]
}

export const base_text_entity =  
{
  "Type": "Text",
  "Handle": "143",
  "Layer": "0",
  "Color_Index": "256",
  "Color": "ByLayer",
  "Linetype": "ByLayer",
  "Normal": {
    "x": "0",
    "y": "0",
    "z": "1"
  },
  "Text_Height": "11",
  "Text_String": "",
  "Text_Position": {
  }
}