// src/api/axiosInstance.js
import axios from 'axios';
import { ESHOW_URL } from '../config.js'

// Create an Axios instance
axios.defaults.baseURL = `https://${process.env.REACT_APP_site}/api/`
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(config => {
    // Check if the URL contains a token
    const url = new URL(config.url, window.location.origin);
    const token = url.searchParams.get('token');
    let urlString = url.toString();
    if (token && (urlString?.includes(ESHOW_URL()))) {
        // Remove the token from the URL
        url.searchParams.delete('token');
        config.url = url.toString();

        // Add the token to the headers
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
}, error => {
  // Handle the error
  return Promise.reject(error);
});

export default axiosInstance;
