import React ,{ useEffect, useRef, useState } from 'react';
import LZString from 'lz-string';
import axios from 'axios';
import axiosInstance from '../utils/axiosInstance.js'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import BackupIcon from '@mui/icons-material/Backup';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import HistoryIcon from '@mui/icons-material/History';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AddLinkIcon from '@mui/icons-material/AddLink';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { connect } from 'react-redux'
import LayerModal from './component/LayerModal'
import BoothImportModal from './component/BoothImportModal'
import GrouppingModal from './component/GrouppingModal'
import UnGrouppingModal from './component/UnGrouppingModal'
import FilenameModal from "./component/FilenameModal"
import SetupModal from "./component/SetupModal"
import AttachModal from "./component/AttachModal"
import PriceModal from "./component/PriceModal"
import SponsorModal from "./component/SponsorModal"
import SetBoothModal from './component/SetBoothModal'
import SetTableModal from './component/SetTableModal'
import BoothListModal from "./component/BoothListModal"
import CoExhibitors from "./component/CoExhibitors"
import BackgroundModal from "./component/BackgroundModal"
import OverviewModal from "./component/OverviewModal"
import ImportModal from "./component/ImportModal"
import CompanyEditModal from './component/CompanyEditModal'
import DrawTool from "./component/DrawTool"
import SponsorTool from "./component/SponsorTool"

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { fabric } from "fabric";
import qs, { parse } from 'qs'
import './View.css';
import { useNavigate } from 'react-router-dom'
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import SettingsIcon from '@mui/icons-material/Settings';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ListIcon from '@mui/icons-material/List';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import PanToolIcon from '@mui/icons-material/PanTool';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { API_URL, ESHOW_URL,main_widow_offset_x,main_widow_offset_y, S3_URL } from '../config.js'
import { setViewData, setViewFile, setBoothList, setCanvasRefresh, setLoading,  resetMain, setSaveFileName, setRemoveObjects, setMapRemoveObjects, setFirstMapContent, setFirstBgContent } from '../actions/main'
import { setPanFlag,setLockFlag, resetUI, setOpenFilenameModal,setOpenSetBoothModal, setOpenAssignmentModal, setOpenBoothList, setLeftPage, setOpenUnassignedProfile, setOpenCompanyProfile, setOpenSponsorshipProfile, handleGlobalCloseSnack, setOpenImportmodal, setOpenCompanyModal, setEditCompany, setMouseHoverEnable } from '../actions/ui'
import { setLog, addLog } from '../actions/log'
import { setBoothHistory } from '../actions/booth_history'
import ExportFile from './component/ExportFile';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { convertRgbHex, getDefaultGroup } from '../utils/Common'
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, unlockMap, lockMap, setObjectBG, trimJson, compressJson, addText, checkCombinable, checkContainSold, checkIfSelectable, toggleDrawMode, getGeometry, px2unit, unit2Px, sendAnotationToBackGrid, getZindex, swichFullOverView, getStrokeWidth, groupApartObjects} from '../utils/CanvasTool'
import {canvasApp, helperCanvas} from '../utils/CanvasApp'
import LaunchIcon from '@mui/icons-material/Launch';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ReactComponent as LegendIcon } from '../svgs/legend.svg';
import RightMenu from './component/RightMenu';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import AddToQueueOutlinedIcon from '@mui/icons-material/AddToQueueOutlined';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import ElementTableBox from './component/ElementTableBox';
import ElementBoothBox from './component/ElementBoothBox';
import ElementSymbolBox from './component/ElementSymbolBox';
import ElementStructureBox from './component/ElementStructureBox';
import SetBoothGroupModal from './component/SetBoothGroupModal'
import { v4 as uuidv4 } from 'uuid';
import  area  from 'area-polygon'
import WorkerBuilder from './worker_builder';
import Worker from './worker_file';
import {
    BrowserRouter as Router,
    Link,
    useLocation
  } from "react-router-dom";
import { parseToken, buildObjectProperty, containsObject } from '../utils/Common'
import moment from 'moment';
import { getTokenCookie } from '../utils/Common'
import { markActiveBooth, markDeactiveBooth, changeBorderColor, changeBorderWidth, sendBackIntersects, checkifrectbooth } from '../utils/CanvasTool'
import { saveLog } from '../services/service.js'
function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#ffffffa0',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color:"white"
    },
}));

const MenuButton = styled(Button)(({ theme }) => ({
    color: '#4E7090',
    padding:'10px',
    width:'100%',
    textAlign:'left',
    justifyContent:'start',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#4E7090',
      color:"white"
    },
}));

const Button3D =  styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#22222250',
    },
    border:'2px solid white',
    float:'right' 
  }));

var g_lockFlag = false;
var g_fileSaved =false
var g_groupedItem = false;
var myWorker = new WorkerBuilder(Worker);
function ToolBox(props) {
    const [dirContent, setDirContent] = useState(null);
    const [snackMsg, setSnackMsg] = useState('');
    const [openSnack, setOpenSnack] = useState(false);
    const [openLayerModal, setOpenLayerModal] = useState(false);
    // const [openBoothImportModal, setOpenBoothImportModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [searchedBooths, setSearchedBooths] = useState([]);
    const [searchedBoothTexts, setSearchedBoothTexts] = useState([]);
    
    const [openDraw, setOpenDraw] = React.useState(false);
    const [openSponsorship, setOpenSponsorship] = React.useState(false);
    
    
    const [openGrouppingModal, setOpenGrouppingModal] = useState(false);
    const [openUnGrouppingModal, setOpenUnGrouppingModal] = useState(false);
    const [openSetup, setOpenSetup] = useState(false)
    const [ungroupCnt, setUngroupCnt] = useState(1);
    const [groupedItem,setGroupedItem] = useState(null);
    const [grouppingBooth, setGrouppingBooth] = useState(0);
    const [ungrouppingBooth, setUngrouppingBooth] = useState({});
    const [undoFlag, setUndoFlag] = useState(false);
    const [exportSvg, setExportSvg] = useState(null);
    const [mainDataUrl, setMainDataUrl] = useState(null);
    const [openAttachModal, setOpenAttachModal] = useState(false);
    const [openPriceModal, setOpenPriceModal] = useState(false);
    const [openSponsorModal, setOpenSponsorModal] = useState(false);
    const [openBoothListeModal, setOpenBoothListeModal] = useState(false);
    const [openCoExhibitorsModal, setOpenCoExhibitorsModal] = useState(false);    
    const [enableAttach, setEnableAttach] = useState(false);
    const [openBackgroundmodal, setOpenBackgroundmodal] = useState(false);
    const [openOverviewmodal, setOpenOverviewmodal] = useState(false);
    // const [openImportmodal, setOpenImportmodal] = useState(false);
    const [openElementTableBox, setOpenElementTableBox] = useState(false);
    const [openElementBoothBox, setOpenElementBoothBox] = useState(false);
    const [openElementSymbolBox, setOpenElementSymbolBox] = useState(false);
    const [openElementStructureBox, setOpenElementStructureBox] = useState(false);
    const [menuShowingDropdown, setMenuShowingDropdown] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElDropdownBoothOpen, setAnchorElDropdownBoothOpen] = React.useState(null);
    const [anchorElDropdownSetup, setAnchorElDropdownSetup] = React.useState(null);
    const [anchorElDropdownView, setAnchorElDropdownView] = React.useState(null);
    const [openBoothGroupModal, setOpenBoothGroupModal] = React.useState(null);
    const [openNewNameModal, setOpenNewNameModal] = React.useState(null);
    
    const openDropdown1 = Boolean(anchorEl);
    const openDropdownSetup = Boolean(anchorElDropdownSetup);
    const openDropdownView = Boolean(anchorElDropdownView);
    const openBoothImportModal = Boolean(anchorElDropdownBoothOpen);
    const theme = useTheme();
    const drawDropdownRef = useRef();
    const boothOpenDropdownRef = useRef();
    const setupDropdownRef = useRef();
    const viewDropdownRef = useRef();
    
    const handleClickDropdown1 = (event) => {
        setMenuShowingDropdown("draw")
        // setAnchorEl(event.currentTarget);
    };
    const handleCloseDropdown1 = (e) => {
        // console.log(e)
        // if(!e){
        //     setAnchorEl(null);
        // }
        
        // if (e.currentTarget.localName !== "ul") {
        //     const menu = document.getElementById("dropdown-menu1").children[2];
        //     const menuBoundary = {
        //       left: menu.offsetLeft,
        //       top: menu.offsetTop,
        //       right: menu.offsetLeft + menu.offsetWidth,
        //       bottom: menu.offsetTop + menu.offsetHeight
        //     };
        //     if (
        //       e.clientX >= menuBoundary.left &&
        //       e.clientX <= menuBoundary.right &&
        //       e.clientY <= menuBoundary.bottom &&
        //       e.clientY >= menuBoundary.top
        //     ) {
        //       return;
        //     }
        // }
        setMenuShowingDropdown(false)
        // setAnchorEl(null);
    };
    
    const handleClickDropdownBoothOpen = (event) => {
        setMenuShowingDropdown("boothopen")
    };
    const handleCloseBoothImportModal = (e) => {
        setMenuShowingDropdown(false)
    };
    
    const handleClickDropdownSetup = (event) => {
        setMenuShowingDropdown("setup")
    };
    const handleCloseDropdownSetup = (e) => {
        setMenuShowingDropdown(false)
    };
    
    const handleClickDropdownView = (event) => {
        setMenuShowingDropdown("view")
    };
    const handleCloseDropdownView = (e) => {
        setMenuShowingDropdown(false)
    };
    const handleCloseDraw = ()=>{
        setOpenDraw(false)
        sendAnotationToBackGrid(mainCanvas)
        toggleDrawMode(props.main.mainCanvas, "general")
    }

    const handleOpenDraw = ()=>{
        setOpenDraw(true)
        toggleDrawMode(props.main.mainCanvas, "draw")
    }

    

    const handleCloseSponsorship = ()=>{
        setOpenSponsorship(false)
        sendAnotationToBackGrid(mainCanvas)
        toggleDrawMode(props.main.mainCanvas, "general")
    }

    const handleOpenSponsorship = ()=>{
        setOpenSponsorship(true)
        // toggleDrawMode(props.main.mainCanvas, "draw")
    }


    const handleCloseCompanyModal=(index)=>{
        props.setOpenCompanyModal(false)
    }

    const confirmComapnyModal=()=>{

    }

    const navigate = useNavigate()
    let query = useQuery();

    var mainCanvas = props.main.mainCanvas;
    var bgImgCanvas = props.main.bgImgCanvas;
    var g_grid = props.main.g_grid

    const openRef = useRef();
    var imageOffsetX, imageOffsetY;
    const mainLayers = props.main.mainLayers
    var boothFileList = props.main.boothFileList;
    const mainSvg = props.main.mainSvg;
    var bgInfo = props.main.bgInfo;
    const active_booth_file = props.main.active_booth_file
    var groupOptions = props.main.groupOptions
    var authInfo = props.main.authInfo

    const handleOkSetBoothModal = (activeObject)=>{        
        
        props.setOpenSetBoothModal(false)
        props.setBoothHistory({content: (props.main.mainCanvas.toDatalessObject())})
        mainCanvas.renderAll()
    }
    const handleCloseSetBoothModal = ()=>{
        props.setOpenSetBoothModal(false)
    }

    const handleOkSetTableModal = (activeObject)=>{   
        props.setOpenSetTableModal(false)
        mainCanvas.renderAll()
    }
    const handleCloseSetTableModal = ()=>{
        props.setOpenSetTableModal(false)
    }

    

    const doSearch = ()=>{
        
        mainCanvas.discardActiveObject()
        var newSearchedBooths = [];
        var newSearchedBoothTexts = [];
        if(searchVal){
            var soldFlag = false;
            var searchedObjects = [];
            var searchValArray = searchVal.split(',')
            for(var keyIndex = 0; keyIndex < searchValArray.length; keyIndex++){
                var searchKey = searchValArray[keyIndex];
                if(searchKey) searchKey = searchKey.trim()
                var groups = mainCanvas.getObjects('group');
                // console.log("groups",groups)
                for(var i = 0; i < groups.length; i++){
                    var group = groups[i]
                    if(group.get('class') == 'booth' || group.get('class') == 'booth-group'){
                        var textobjects = group.getObjects('text');
                        for(var j = 0; j< textobjects.length; j++){
                            var txt  = textobjects[j].get('text');
                            if(txt && searchKey){
                                if(txt.toLowerCase() == searchKey.toLowerCase()){
                                    // console.log("found order", i);
                                    // console.log("found", group);
                                    searchedObjects.push(group);
                                    newSearchedBooths.push(group);
                                    newSearchedBoothTexts.push(searchKey)
                                    if(group.get('sold') == '1'){
                                        soldFlag = true;
                                    }
                                    // mainCanvas.setActiveObject(group);
                                    // mainCanvas.requestRenderAll();  
                                }
                            }
                        }
                    }
                }
            }
            if(searchedObjects.length > 0){
                setEnableAttach(!soldFlag);
                var newSelectObj = new fabric.ActiveSelection(searchedObjects, {canvas:mainCanvas});
                mainCanvas.setActiveObject(newSelectObj);
                mainCanvas.requestRenderAll();  
                var zoomX = window.screen.width/newSelectObj.width*0.2;
                var zoomY = window.screen.height/newSelectObj.height*0.2;
                mainCanvas.viewportTransform[0] = Math.min(zoomX, zoomY);
                mainCanvas.viewportTransform[3] = Math.min(zoomX, zoomY);
                mainCanvas.viewportTransform[4] = window.screen.width/2-(newSelectObj.left+newSelectObj.width/2)*Math.min(zoomX, zoomY)-main_widow_offset_x;
                mainCanvas.viewportTransform[5] = window.screen.height/2-(newSelectObj.top+newSelectObj.height/2)*Math.min(zoomX, zoomY)-main_widow_offset_y;
                window.scrollTo(0, 0)
                mainCanvas.requestRenderAll();
            }
        }
        else{

        }
        setSearchedBooths(newSearchedBooths);
        setSearchedBoothTexts(newSearchedBoothTexts);
    }
    const handleCloseSnack = ()=>{
        setOpenSnack(false);
    }

    const handleCloseLayerModal = ()=>{
        setOpenLayerModal(false)
    }
    
    
    const handleCloseFilenameModal = ()=>{
        props.setOpenFilenameModal(false)
    }

    const confirmSetup = (settingData, showSettingData)=>{
        onSaveSetting(settingData)
        onSaveShowSetting(showSettingData)
        setOpenSetup(false)
    }

    const handleCloseSetup = ()=>{
        setOpenSetup(false)
    }

    const handleCloseGrouppingModal = ()=>{
        setOpenGrouppingModal(false)
    }

    const handleOpenBackgroundModal = ()=>{
        setOpenBackgroundmodal(true);
    }

    const handleCloseBackgroundModal = ()=>{
        setOpenBackgroundmodal(false);
    }
    
    const handleOkBackgroundModal = () =>{
        props.setBoothHistory({content: (mainCanvas.toDatalessObject())})
        setOpenBackgroundmodal(false);
    }
    
    const handleOpenOverviewModal = ()=>{
        setOpenOverviewmodal(true);
    }
    
    const handleCloseOverviewModal = ()=>{
        setOpenOverviewmodal(false);
    }

    const handleOkOverviewModal = () =>{
        setOpenOverviewmodal(false);
    }

    const handleOpenImportModal = ()=>{
        props.setOpenImportmodal(true);
    }

    const handleCloseImportModal = ()=>{
        props.setOpenImportmodal(false);
    }

    const handleOkImportModal = () =>{
        // setOpenImportmodal(false);
    }
    
    const handleOpenExportModal = ()=>{
        // var dataURL = mainCanvas.toDataURL({
        //     format: 'png',
        // });
        var svg = mainCanvas.toSVG({
            width:'100%',
            height: '100%',
            viewBox: {
                x:0,
                y:0,
                width:3000,
                height:3000
            }
        });
        // var groups = mainCanvas.getObjects('group');
        // for(var i = 0; i< groups.length; i++){
        //     var f_text1 = new fabric.Text(groups[i].width.toFixed(0), {
        //         left: 0,
        //         top: 0+groups[i].height/2,
        //         fontSize:18,
        //     });    
        //     groups[i].add(f_text1);
        //     var f_text2 = new fabric.Text(groups[i].height.toFixed(0), {
        //         left: 0+groups[i].width/2,
        //         top: 0,
        //         fontSize:18,
        //     });    
        //     groups[i].add(f_text2);
        //     groups[i].visible = false
        // }
        // mainCanvas.requestRenderAll();

        // setMainDataUrl(dataURL);
        setExportSvg(svg);
        // console.log("mainCanvas",mainCanvas)
        setOpenExportModal(true)
    }
    
    const handleCloseExportModal = ()=>{
        setOpenExportModal(false)
    }

    const handleOpenAttachModal = ()=>{
        setGroupedItem(null);
        var boothFileList = props.main.boothFileList;
        var activeFile = props.main.active_booth_file
        if(!activeFile){
            alert("Save file first.")
            return false
        }
        else{            
            var tmp = ""
            if(searchedBoothTexts && searchedBoothTexts.length){
                var sorted = searchedBoothTexts.sort((a, b) =>a*1-b*1);
                tmp=sorted[0];
            }
            setGrouppingBooth(tmp)
            setOpenAttachModal(true)
        }
    }

    const handleOpenPriceModal = ()=>{
        
        var boothFileList = props.main.boothFileList;
        var activeFile = props.main.active_booth_file
        if(!activeFile){
            alert("Save file first.")
            return false
        }
        else
            setOpenPriceModal(true)
    }
    
    const handleOkPriceModal = ()=>{
        setOpenPriceModal(false)
    }

    const handleOpenSponsorModal = ()=>{        
        setOpenSponsorModal(true)
    }
    
    const handleOkSponsorModal = ()=>{
        setOpenSponsorModal(false)
    }    

    const handleOpenBoothListModal = ()=>{
        setOpenBoothListeModal(true)
    }
    
    const handleOkBoothListModal = ()=>{
        setOpenBoothListeModal(false)
    }

    const handleCloseBoothListModal = ()=>{
        setOpenBoothListeModal(false)
    }
    
    const handleOpenCoExhibitorsModal = ()=>{
        setOpenCoExhibitorsModal(true)
    } 

    const handleOkCoExhibitorsModal = ()=>{
        setOpenCoExhibitorsModal(false)
    }

    const handleCloseCoExhibitorsModal = ()=>{
        setOpenCoExhibitorsModal(false)
    }  
    
    const handleOkAttachModal = async(formdata, groupedItem = null)=>{
        var company = formdata.company
        var contacts = formdata.contacts
        var eshowExhibitor = formdata.eshowExhibitor
        var boothObjects = formdata.boothObjects
        var primary_booth = "";
        // var booth_sale_items = formdata.booth_sale_items
        if(1){
            var booths = []
            var sales = []
            var exhibitor_contacts = []
            for(var i = 0; i < boothObjects?.length; i++){
                var booth = boothObjects[i];
                booths.push({
                    BOOTH_NO: booth.booth_number,
                    BOOTH_KEY: booth.KEY_ID,
                    KEY_ID: uuidv4()
                })
                if(primary_booth == ""){
                    primary_booth = booth.booth_number
                }
                if(booth.booth_number*1 < primary_booth*1){
                    primary_booth = booth.booth_number
                }
            }
            // for(var i = 0; i < booth_sale_items.length; i++){
            //     if(booth_sale_items[i] && booth_sale_items[i].length > 0){
            //     for(var j = 0; j < booth_sale_items[i].length; j++){
            //         var booth_sale_item = booth_sale_items[i][j]
            //         sales.push({
            //             KEY_ID:uuidv4(),
            //             TYPE:booth_sale_item.discountFlag?"704":"407",
            //             ACTIVE:"1",
            //             PRICE_KEY:booth_sale_item.saleitem_id,
            //             AMOUNT:(booth_sale_item.discountFlag?(-1):1)*booth_sale_item.saleitem_total,
            //             QTY:booth_sale_item.saleitem_qty,
            //             TITLE:booth_sale_item.saleitem_name,
            //             PRODUCT_KEY:booth_sale_item.saleitem_product_id,
            //             TOTAL:(booth_sale_item.discountFlag?(-1):1)*booth_sale_item.saleitem_total,
            //             BOOTH_KEY:booth_sale_item.boothObject?.KEY_ID,
            //             BOOTH_NUMBER:booth_sale_item.boothObject?.booth_number,
        
            //         })
            //     }
            //     }
            // }
            // console.log("sales", sales); return;
            var contactName = ""
            for(var i = 0; i < contacts.length; i++){
                var contact = contacts[i]
                exhibitor_contacts.push({
                    ROLE_TYPE:contact.ROLE_TYPE?contact.ROLE_TYPE:"11",
                    ACTIVE:1,
                    PHONE_EXT:contact.PHONE_EXT?contact.PHONE_EXT:"",
                    PHONE:contact.PHONE?contact.PHONE:"",
                    KEY_ID:contact.SALES_CONTACT?(contact.EXISTING_CONTACT):uuidv4(),
                    LAST_NAME:contact.LAST_NAME?contact.LAST_NAME:"",
                    EMAIL:contact.EMAIL?contact.EMAIL:"",
                    FIRST_NAME:contact.FIRST_NAME?contact.FIRST_NAME:"",
                    CREDENTIALS:contact.CREDENTIALS?contact.CREDENTIALS:"",
                    CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
                    TITLE:contact.TITLE?contact.TITLE:"",
                    CRM_CONTACT_KEY:contact.KEY_ID?contact.KEY_ID:"",
                    TYPE:contact.TYPE?contact.TYPE:"605"
                })
                contactName = contact.TITLE?.trim()
            }
            var exhibitor_key = uuidv4().toUpperCase()
            var exhibitor = {
                ZIP_CODE:company.ZIP_CODE?company.ZIP_CODE:"",
                PHONE:company.PHONE?company.PHONE:"",
                STATE:company.STATE?company.STATE:"",
                STATUS:company.STATUS?company.STATUS:"3",
                COUNTRY:company.COUNTRY?company.COUNTRY:"",
                GROUP_KEY:exhibitor_key,
                ADDRESS1:company.ADDRESS1?company.ADDRESS1:"",
                ADDRESS2:company.ADDRESS2?company.ADDRESS2:"",
                DISPLAY_ORDER:company.DISPLAY_ORDER?company.DISPLAY_ORDER:"1",
                KEY_ID:exhibitor_key,
                PRIMARY_BOOTH:primary_booth,
                COMPANY_NAME:company.COMPANY_NAME?company.COMPANY_NAME:"",
                FORM_KEY:active_booth_file?.sales_form,
                CITY:company.CITY?company.CITY:"",
                BOOTH_SF:"100",
                ORDER_NUMBER:"",
                CRM_COMPANY_KEY:company.KEY_ID?company.KEY_ID:"",
                WEBSITE:"",
                BOOTHS:booths,
                CONTACTS:exhibitor_contacts,
                SALES: eshowExhibitor.SALES
            };
        }
        var booths = [];
        if(groupedItem){
            booths = await onSaveIndividuals([groupedItem], null, searchedBooths, "COMBINE", true)
        }
        else{           
            mainCanvas.discardActiveObject();             
            booths = await onSaveIndividuals(null, searchedBooths, null, false, true)
            var sel = new fabric.ActiveSelection(searchedBooths, {
                canvas: mainCanvas,
            });
            mainCanvas.setActiveObject(sel).renderAll();
        }
        var postData = {
            EXHIBITOR: exhibitor,
            BOOTHS: booths,
            SUCCESS: true,
        }
        if(groupedItem){
            postData.METHOD = "COMBINE"
        }
        // console.log("postData", postData)
        // return;
        var submitdata = {
            query:`${ESHOW_URL()}?method=putExhibitor&token=${getTokenCookie()}`,
            postData: postData,
            returnResponse: true
        }
        var data = qs.stringify({
            request: JSON.stringify(submitdata)
        });
        const options = {
            headers: {
                'body': '[{"company:","test"}]', 
                'Content-Type': 'application/json'
              }
        }
        props.setLoading(true)
        var url = `${ESHOW_URL()}?method=putExhibitor&token=${getTokenCookie()}`
        //test
        // setSnackMsg("Failed to assign");
        // props.setLoading(false)  
        // return;
        //test              
        axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
            const eshowResponse = res.data;
            if(eshowResponse.SUCCESS == true){
                var assignedExhibtor_Key = eshowResponse.KEY_ID
                setOpenSnack(true);
                setSnackMsg("Assigned successfully");
                if(searchedBooths){
                    for(var i = 0; i < searchedBooths.length; i++){
                        {
                            var group = searchedBooths[i]
                            if(group.get('class') == 'booth')
                            {
                                group.set('sold', 1)
                                group.set('sold_more', 1)
                                var groupedId = group.get('id');
                                groupedId = groupedId.replace("booth-","");
                                group.set({company: company?.COMPANY_NAME?company?.COMPANY_NAME?.trim():""});
                                group.set({boothCompanyObject: company})
                                group.set({exhibitors: [
                                    {
                                        COMPANY_KEY:company.KEY_ID?company.KEY_ID:"", 
                                        EXHIBITOR_KEY:assignedExhibtor_Key,
                                        SORT:1,
                                        TYPE: 'Primary',
                                        STATUS: exhibitor?.STATUS
                                    }
                                ]})
                                group.set({boothContactObject: contacts})
                                var textObjects = group.getObjects('text');
                                for(var k = 0; k< textObjects.length; k++){
                                    group.remove(textObjects[k])
                                }
                                addText(mainCanvas, group, (formdata.company?.COMPANY_NAME?(`${formdata.company?.COMPANY_NAME}`.trim()+"\n"):"")+group.booth_number)
                                
                                // group.modified = true;
                                // group.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
                                // props.setBoothHistory({content: (mainCanvas.toDatalessObject())})

                                props.addLog({
                                    BOOTH_KEY:group.KEY_ID,
                                    KEY_ID:uuidv4(),
                                    action: 'assign',
                                    date: new Date().toLocaleString(),
                                    description: "assigned booth "+group.booth_number+" to "+formdata.company?.COMPANY_NAME+" "+contactName,
                                    user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                                })
                                if(!groupedItem){
                                    var boothGroup = group.boothGroup;
                                    var bgColor = "#dadada"
                                    
                                    var exhibitors = group.exhibitors
                                    for(var k = 0; k < groupOptions.length; k++){
                                        if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                                            var groupOption = groupOptions[k]
                                            if(group.sold){
                                                if(company.STATUS == '2'){
                                                    bgColor = convertRgbHex(groupOption['PENDING']);
                                                }
                                                else{
                                                    bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                                                }
                                            }
                                        }
                                    }
                                    mainCanvas.renderAll()
                                    group.deselectedColor = group.orgBg = bgColor
                                    setObjectBG(mainCanvas, group, bgColor)
                                    mainCanvas.renderAll()
                                }
                            }
                            
                        }
                    }
                    if(groupedItem){
                        var groupedId = groupedItem.get('id');
                        groupedId = groupedId.replace("booth-","");
                        groupedItem.set({class: 'booth'});
                        groupedItem.set({company: company?.COMPANY_NAME?company?.COMPANY_NAME.trim():""});
                        groupedItem.set({boothCompanyObject: company})
                        groupedItem.set({exhibitors: [
                            {
                                COMPANY_KEY:company.KEY_ID?company.KEY_ID:"", 
                                EXHIBITOR_KEY:assignedExhibtor_Key,
                                SORT:1,
                                TYPE: 'Primary',
                                STATUS: exhibitor?.STATUS
                            }
                        ]})
                        groupedItem.set({boothContactObject: contacts})

                        groupedItem.set('sold', 1)
                        groupedItem.set('sold_more', 1)
                        var textObjects = groupedItem.getObjects('text');
                        for(var k = 0; k< textObjects.length; k++){
                            groupedItem.remove(textObjects[k])
                        }
                        addText(mainCanvas, groupedItem, (formdata.company?.COMPANY_NAME?(`${formdata.company?.COMPANY_NAME}`.trim()+"\n"):"")+groupedItem.booth_number)
                        

                        // groupedItem.modified = true;
                        // groupedItem.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
                        // props.setBoothHistory({content: (mainCanvas.toDatalessObject())})
                        var boothGroup = groupedItem.boothGroup;
                        var exhibitors = groupedItem.exhibitors
                        var bgColor = "#dadada"
                        for(var k = 0; k < groupOptions.length; k++){
                            if(groupOptions[k].KEY_ID == boothGroup){
                                var groupOption = groupOptions[k]
                                if(group.sold){
                                    if(company.STATUS == '2'){
                                        bgColor = convertRgbHex(groupOption['RESERVED']);
                                    }
                                    else{
                                        bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                                    }
                                }
                            }
                        }
                        mainCanvas.renderAll()
                        groupedItem.deselectedColor = groupedItem.orgBg = bgColor
                        setObjectBG(mainCanvas, groupedItem, bgColor)
                        mainCanvas.renderAll()

                    }
                    
                }
                setOpenAttachModal(false)
                
                mainCanvas.discardActiveObject().renderAll();  
                // props.setLoading(false)                
            }
            else{
                setOpenSnack(true);
                setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to assign");
                props.setLoading(false)                
            }   
            props.setLoading(false)
        }).catch(error => {
            console.log("error", error);
        })
    }

    const handleCloseAttachModal = (groupedItem)=>{
        setOpenAttachModal(false)
        setEnableAttach(false);
        if(groupedItem){
            unGroupItems(true, groupedItem)
        }

    }

    const handleClosePriceModal = ()=>{
        setOpenPriceModal(false)
        // setEnableAttach(false);
    }
    
    const handleCloseSponsorModal = ()=>{
        setOpenSponsorModal(false)
        // setEnableAttach(false);
    }
    
    
    const handleConfirmGrouppingModal = (combinedObject, newBoothNumber)=>{
        setOpenSnack(true);
        setSnackMsg("Combined");
        setOpenGrouppingModal(false)

        var groupobject = mainCanvas.getActiveObject();
        var objectsInGroup = groupobject.getObjects();

        var orgObject;
        objectsInGroup.forEach(function(object) {
            if(object.booth_number == newBoothNumber){
                orgObject = object; return;
            }
        });

        if(newBoothNumber && newBoothNumber.length>0){
			addText(mainCanvas, combinedObject, grouppingBooth?grouppingBooth:newBoothNumber)
            if(orgObject){
				combinedObject.set(buildObjectProperty(orgObject));
                combinedObject.modified = true;
				var bgColor = "#dadada"
				var objects = orgObject.getObjects('polygon')
				for(var i = 0; i < objects.length; i++){
					var bgObject = objects[i]; 
					bgColor = bgObject.fill;
					break;
				}
				setObjectBG(mainCanvas, combinedObject, bgColor)
			}
            else{
                combinedObject.new_created = true;
                combinedObject.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
            }

            combinedObject.set({booth_number:newBoothNumber})
            
            mainCanvas.requestRenderAll();
        }
    }

   

    const handleCloseUnGrouppingModal = ()=>{
        setOpenUnGrouppingModal(false)
    }
    
    const afterUngroupping = ()=>{
        setOpenSnack(true);
        setSnackMsg("Ungrouped");
        setOpenUnGrouppingModal(false)
    }

    
    const handleOpenGrouppingModal = ()=>{    
        var tmp = ""
        if(searchedBoothTexts && searchedBoothTexts.length){
            var sorted = searchedBoothTexts.sort((a, b) =>a*1-b*1);
            tmp=sorted[0];
        }
        setGrouppingBooth(tmp)
        setOpenGrouppingModal(true)
    }
    const handleSetBoothGroup = (groupname, flag)=>{
        if(searchedBooths && searchedBooths[0]){
            searchedBooths[0].set({
                boothGroup:(flag=='assign'?groupname:'')
            })
            mainCanvas.renderAll();
            handleClosePriceModal()
        }
    }
    const resetGridAlign = (e)=>{
        mainCanvas.set({
            gridOffsetX: 0,
            gridOffsetY: 0
        })        
        mainCanvas.renderAll();
        redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid && mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
    }
    
      
    useEffect(() => {
        myWorker.onmessage = (message) => {
            if (message) {
            //   console.log("Message from worker", message.data);
            }
          };
    }, []) 

    useEffect(() => {
        g_lockFlag = props.ui.lockFlag
    }, [props.ui.lockFlag]) 

    
    useEffect(() => {
        if(props.ui.openSetAssignModal){
            handleOpenAttachModal()
            props.setOpenAssignmentModal(false)
        }
    }, [props.ui.openSetAssignModal]) 
    

    const selectProcess=(selected)=>{
        var activeObject = mainCanvas.getActiveObject();
        if (mainCanvas.getActiveObject().type !== 'activeSelection') {

        }
        else
            selected = activeObject.getObjects();

        if(selected){
            var keys = [];
            var newSearchedBooths = [];
            var soldFlag = false;
            for(var i = 0; i< selected.length; i++){
                if(selected[i].get('class')=="booth" || selected[i].get('class')=="booth-group"){
                    
                    keys.push(selected[i].booth_number)
                    if(selected[i].get('sold') == '1'){
                        soldFlag = true;
                    }
                    newSearchedBooths.push()
                }
            }

            // console.log("keys before", keys)
            keys.sort(function(a, b) {
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                
                // names must be equal
                return 0;
            })
            
            setSearchedBoothTexts(keys);
            setSearchedBooths([...selected]);
            // if(!g_lockFlag)
                setEnableAttach(!soldFlag);
            // console.log("keys", keys)
        }
    }

    

    
    
    const exportJson = ()=>{
        // var objects = props.main.mainCanvas.getObjects('group');
        //     var annotations = [];
        //     for(var i = 0; i < objects.length; i++){
        //         if(objects[i].layer == "annotation"){
        //             annotations.push(objects[i])
        //         }
        //     }
        //     if(annotations.length){
        //         var sel = new fabric.ActiveSelection(annotations, {
        //             canvas: props.main.mainCanvas,
        //         });
        //         var bgurl = sel.toDataURL()
        //         console.log("sel", bgurl)
        //     }
        //     return;   

        const canvasJson = (mainCanvas.toDatalessObject());
        if(canvasJson){
            const data = trimJson(canvasJson);
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data)));
            var filename = props.main.saveFileName?props.main.saveFileName:(active_booth_file?active_booth_file.filename:"")
            if(filename == ""){
                filename = 'booth'
            }
            element.setAttribute('download', filename+'.json');
        
            element.style.display = 'none';
            document.body.appendChild(element);
        
            element.click();
        
            document.body.removeChild(element);
        }
    }
    useEffect(() => {
        if(mainCanvas){
            mainCanvas.off('selection:cleared');
            mainCanvas.on('selection:cleared', function(opt) {
                var evt = opt.e;
                if ((evt && evt.altKey === true) || props.ui.panFlag) {
                    return false
                }
                var deselected = opt.deselected;
                if(deselected){
                    for(var ind = 0; ind < deselected.length; ind++){
                        var group = deselected[ind];
                        markDeactiveBooth(mainCanvas, group, false)
                        if(!checkifrectbooth(group)){
                            sendBackIntersects(group, mainCanvas)
                        }
                    }
                    mainCanvas.setZoom(mainCanvas.getZoom()*1.00000001)
                    mainCanvas.renderAll()
                }
                setSearchedBooths([]);
                setEnableAttach(false);
            });  
            
            mainCanvas.off('selection:created');
            mainCanvas.on('selection:created', function(opt) {
                console.log('created')
                console.log(opt)
                console.log("zoom", mainCanvas.getZoom())
                if(mainCanvas.getActiveObject().layer == 'venue')
                {
                    mainCanvas.getActiveObject().set({
                        selectable: false,
                        evented: false,
                        hasBoder: false,
                        hasControls:false
                    })
                    return;
                }

                mainCanvas.getActiveObject().set({
                    lockScalingX: true,
                    lockScalingY: true,
                    borderColor: '#ff7777',
                    cornerColor: 'red',
                    cornerSize: 6,
                    transparentCorners: false,
                })
                if(mainCanvas.getActiveObject().layer == 'annotation')
                {
                    mainCanvas.getActiveObject().set({
                        lockScalingX: false,
                        lockScalingY: false,
                        lockRotation: false,
                        snapAngle: 5
                    })
                }
                else if(mainCanvas.getActiveObject().class == 'booth')
                {
                    mainCanvas.getActiveObject().set({
                        lockRotation: false,
                        snapAngle: 5
                    })
                }
                else{
                    mainCanvas.getActiveObject().set({
                        snapAngle: 5
                    })
                }
                

                if(g_lockFlag){
                    mainCanvas.getActiveObject().set({
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true
                    })
                }
                if (mainCanvas.getActiveObject().type == 'activeSelection') {                    
                    var target = mainCanvas.getActiveObject()
                    if(mainCanvas.mapLock != 1){
                        target.hasControls=true
                        target.hasBoder = true
                    }
                    else{
                        target.hasControls=false
                        target.hasBoder = false
                    }
                    // target.borderColor='transparent'
                    var active_objects = mainCanvas.getActiveObject().getObjects();
                    for(var ind = 0; ind < active_objects.length; ind++){
                        var group = active_objects[ind];
                        markActiveBooth(mainCanvas, group, false)
                    }
                    mainCanvas.renderAll()
                }
                else{
                    var target = mainCanvas.getActiveObject()
                    if(target.class == "booth"){
                        if(mainCanvas.mapLock != 1){
                            target.hasControls=true
                            target.hasBoder = true
                        }
                        else{
                            target.hasControls=false
                            target.hasBoder = false
                        }
                        // target.borderColor='transparent'
                    }
                    var active_object = mainCanvas.getActiveObject();
                    var group = active_object;
                    markActiveBooth(mainCanvas, group)
                   
                }

                var evt = opt.e;
                // if ((evt && evt.altKey === true) || props.ui.panFlag) {
                if ( props.ui.panFlag) {
                    return false
                }
                var selected = opt.selected;
                selectProcess(selected)
            });  

            mainCanvas.off('selection:updated');
            mainCanvas.on('selection:updated', function(opt) {
                // console.log('updated')                
                if(mainCanvas.getActiveObject().layer == 'venue')
                {
                    mainCanvas.getActiveObject().set({
                        selectable: false,
                        evented: false,
                        hasBoder: false,
                        hasControls:false
                    })
                    return;
                }
                var deselected = opt.deselected;
                if(deselected){
                    for(var ind = 0; ind < deselected.length; ind++){
                        var group = deselected[ind];
                        markDeactiveBooth(mainCanvas, group, false)
                        if(!checkifrectbooth(group)){
                            sendBackIntersects(group, mainCanvas)
                        }
                    }                    
                    mainCanvas.setZoom(mainCanvas.getZoom()*1.00000001)
                    mainCanvas.renderAll()
                }

                mainCanvas.getActiveObject().set({
                    lockScalingX: true,
                    lockScalingY: true,
                    borderColor: '#ff7777',
                    cornerColor: 'red',
                    cornerSize: 6,
                    transparentCorners: false,
                })
                
                if(mainCanvas.getActiveObject().layer == 'annotation' || mainCanvas.getActiveObject().layer == 'sponsorships')
                {
                    mainCanvas.getActiveObject().set({
                        lockScalingX: false,
                        lockScalingY: false,
                        lockRotation: false,
                        snapAngle: 5
                    })
                }
                else if(mainCanvas.getActiveObject().class == 'booth')
                {
                    mainCanvas.getActiveObject().set({
                        lockRotation: false,
                        snapAngle: 5
                    })
                }
                else{
                    mainCanvas.getActiveObject().set({
                        snapAngle: 5
                    })
                }
                

                if(g_lockFlag){
                    mainCanvas.getActiveObject().set({
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true
                    })
                }
                if (mainCanvas.getActiveObject().type == 'activeSelection') {
                    mainCanvas.getActiveObject().set({
                        lockScalingX: true,
                        lockScalingY: true,
                    })                    
                    var target = mainCanvas.getActiveObject()
                    if(mainCanvas.mapLock != 1){
                        target.hasControls=true
                        target.hasBoder = true
                    }
                    else{
                        target.hasControls=false
                        target.hasBoder = false
                    }
                    // target.borderColor='transparent'

                    var active_objects = mainCanvas.getActiveObject().getObjects();
                    for(var ind = 0; ind < active_objects.length; ind++){
                        var group = active_objects[ind];
                        if(group.hovered){
                            setObjectBG(mainCanvas, group, group.orgBg)
                            group.hovered = false
                        }
                        markActiveBooth(mainCanvas, group, false)
                    }                    
                    mainCanvas.renderAll()
                }
                else{
                    
                    var target = mainCanvas.getActiveObject()
                    if(target.class == "booth"){
                        if(mainCanvas.mapLock != 1){
                            target.hasControls=true
                            target.hasBoder = true
                        }
                        else{
                            target.hasControls=false
                            target.hasBoder = false
                        }
                        // target.borderColor='transparent'
                    }

                    var active_object = mainCanvas.getActiveObject();
                    var group = active_object;
                    markActiveBooth(mainCanvas, group)
                }

                var evt = opt.e;
                // if ((evt && evt.altKey === true) || props.ui.panFlag) {
                if ( props.ui.panFlag) {
                    return false
                }
                var selected = opt.selected;
                selectProcess(selected)
            });  
        }
    }, [mainCanvas, props.ui.panFlag])
    
    useEffect(() => {
        if(undoFlag){
            setUndoFlag(false)
            var content = props.booth_history.content
            if(content){
                var content = props.booth_history.content
                var action = props.booth_history.action
                if(action.type == 'new'){   
                    var action_objects =  action.objects                
                    var objects = mainCanvas.getObjects();
                    if(action_objects){
                        for(var objInd = 0; objInd<action_objects.length; objInd++){
                            var action_object = action_objects[objInd];
                            for(var i = 0; i< objects.length; i++){           
                                if(objects[i].get('KEY_ID') == action_object.KEY_ID && objects[i].get('layer') == action_object.layer){
                                    mainCanvas.remove(objects[i]).renderAll();
                                    var removeObjects = [...props.main.removeObjects, objects[i].uniq_id]
                                    props.setRemoveObjects(removeObjects)
                                    objects[i].deleteType = 'deleted'
                                    objects[i].deleteTime = new Date().toLocaleString()
                                    objects[i].deleteUser =  (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                                    var mapRemoveObjects = [...props.main.mapRemoveObjects, objects[i].toDatalessObject()]
                                    props.setMapRemoveObjects(mapRemoveObjects)
                                }
                            }
                        }
                    }
                }
                if(action.type == 'deleted'){   
                    var action_object =  action.target 
                    var convertRateX= Math.abs(mainCanvas.convertRateX)
                    if(action_object){
                        var removeObjects = [...props.main.removeObjects, action_object.uniq_id]
                        if(action_object.type == "activeSelection"){
                            var objects = action_object.getObjects()
                            for(var i = 0; i < objects.length; i++){
                                changeBorderColor(objects[i], 'black')
                                changeBorderWidth(objects[i], 3/Math.max(convertRateX, 1))
                                mainCanvas.add(objects[i]).renderAll();
                            }
                        }
                        else{
                            changeBorderColor(action_object, 'black')
                            changeBorderWidth(action_object, 3/Math.max(convertRateX, 1))
                            mainCanvas.add(action_object).renderAll();
                        }
                        removeObjects = removeObjects.filter(item => item !== action_object.uniq_id)
                            
                        props.setRemoveObjects(removeObjects)
                        
                        var mapRemoveObjects = [...props.main.mapRemoveObjects]
                        mapRemoveObjects = mapRemoveObjects.filter(item => item.uniq_id !== action_object.uniq_id)
                        props.setMapRemoveObjects(mapRemoveObjects)
                    }
                }
                
                if(action.type == 'undeleted'){   
                    var action_object =  action.target     
                    if(action_object){
                        
                        mainCanvas.remove(action_object).renderAll();
                        var removeObjects = [...props.main.removeObjects, action_object.uniq_id]
                        var mapRemoveObjects = [...props.main.mapRemoveObjects, action_object.toDatalessObject()]
                        props.setRemoveObjects(removeObjects)
                        props.setMapRemoveObjects(mapRemoveObjects)
                    }
                }
                if(action.type == 'updated'){
                    var target = action.target;
                    var orgObjects = action.orgObjects;
                    if(target){
                        mainCanvas.remove(target);
                        
                    }
                    if(orgObjects && orgObjects.length > 0){
                        fabric.util.enlivenObjects(orgObjects, function(orgObjects) {
                            orgObjects.forEach(function(o) {
                                markDeactiveBooth(mainCanvas, o, false)
                                mainCanvas.add(o);
                            });
                            mainCanvas.setZoom(mainCanvas.getZoom()*1.00000001)
                            mainCanvas.renderAll();                            
                        });
                    }
                } 
                if(action.type == 'modified'){
                    var transform = action.transform;
                    var target = action.target;
                    var original = transform.original;
                               
                    var objects = mainCanvas.getObjects();
                    
                    if(target){
                        if(target.type == 'activeSelection'){
                            target.set(original)
                            mainCanvas.renderAll();
                        }
                        else{
                            for(var i = 0; i< objects.length; i++){           
                                if(objects[i].get('id') == target.get('id')&& objects[i].get('layer') == target.layer){
                                    target.set(original)
                                    mainCanvas.renderAll();
                                }
                            }
                        }
                    }
                } 
                if(action.type=='combine'){ 
                    var input = action.input
                    var output = action.output

                    var objects = mainCanvas.getObjects();
                    var selected = false;
                    if(output){
                        for(var i = 0; i< objects.length; i++){           
                            if(objects[i].get('id') == output.id && objects[i].get('layer') == output.layer){
                                selected = objects[i]; break;
                            }
                        }
                    }

                    if(selected){                        
                        mainCanvas.setActiveObject(selected)
                        unGroupItems(true)
                    }
                }
                if(action.type=='uncombine'){
                    var input = action.input
                    var action_objects = action.output
                    var keys = [];
                    var newSearchedBooths = [];
                    var soldFlag = false;
                    var selected = [];
                    var objects = mainCanvas.getObjects();
                    if(action_objects){
                        for(var objInd = 0; objInd<action_objects.length; objInd++){
                            var action_object = action_objects[objInd];
                            for(var i = 0; i< objects.length; i++){           
                                if(objects[i].get('type')=='group' && objects[i].get('id') == action_object.id && objects[i].get('layer') == action_object.layer){
                                    selected.push(objects[i])
                                }
                            }
                        }
                    }


                    if(selected){
                        for(var i = 0; i< selected.length; i++){
                            if(selected[i].get('class')=="booth" || selected[i].get('class')=="booth-group"){
                                keys.push(selected[i].booth_number)
                                if(selected[i].get('sold') == '1'){
                                    soldFlag = true;
                                }
                                newSearchedBooths.push()
                            }
                        }

                        // console.log("keys before", keys)
                        keys.sort(function(a, b) {
                            if (a < b) {
                                return -1;
                            }
                            if (a > b) {
                                return 1;
                            }
                            
                            // names must be equal
                            return 0;
                        })
                        // console.log("keys", keys)
                    }
                    
                    var sel = new fabric.ActiveSelection(selected, {
                        canvas: mainCanvas,
                    });
                    mainCanvas.setActiveObject(sel);
                    groupItems(keys[0],true,input.id)

                    // if(output){
                    //     for(var ind = 0; ind<output.length; ind++){
                    //         mainCanvas.remove(output[ind]).renderAll();
                    //     }
                    // }
                    // if(input)
                    //     mainCanvas.add(input).renderAll();
                }
                if(action.type=='group'){
                    mainCanvas.discardActiveObject();
                    var groupid = action.group
                    var objects = mainCanvas.getObjects();
                    if(groupid){
                        for(var i = 0; i< objects.length; i++){           
                            if(objects[i].get('id') == groupid){
                                
                                mainCanvas.setActiveObject(objects[i])
                                mainCanvas.getActiveObject().toActiveSelection();
                                mainCanvas.renderAll();
                                break;
                            }
                        }
                    }
                }
                if(action.type=='ungroup'){
                    var objects = action.objects
                    var sel = new fabric.ActiveSelection(objects, {
                        canvas: mainCanvas,
                    });
                    sel.toGroup().set({groupped: true})
                    mainCanvas.requestRenderAll()
                }
                
                if(action.type=='backward' || action.type=='forward'){
                    mainCanvas.moveTo(action.object, action.zIndex)
                    action.object.moveTo(action.zIndex)
                    action.object.zIndex = action.zIndex
                    mainCanvas.renderAll()
                }
            }

            props.onUndo()
        }
    }, [undoFlag])
    
    const setBoothInfo = (canvas)=>{
        var activeFile = props.main.active_booth_file
        if(activeFile){
            canvas.set({
                convertRateX:activeFile['convertRateX'], 
                convertRateY:activeFile['convertRateY'], 
                convertOffsetX:activeFile['convertOffsetX'],  
                convertOffsetY:activeFile['convertOffsetY'], 
                startX:activeFile['startX'], 
                startY:activeFile['startY'], 
                endX:activeFile['endX'], 
                endY:activeFile['endY'],
                mapWidth:activeFile['mapWidth'],
                mapHeight:activeFile['mapHeight'],
                convertUnit:activeFile['convertUnit'],
                gridSize:activeFile['gridSize'],
                showGrid:activeFile['showGrid'],
            })
        }
    }

    
    const onChangeFile = (e)=>{
        var file = openRef.current.files[0]        
        // props.resetUI();
        // props.resetMain();
        setTimeout(async()=>{
            if(file){
                var filename = file.name
                let last_dot = filename.lastIndexOf('.')
                let ext = filename.slice(last_dot + 1)
                var now = new Date();
                let dwgName = filename.slice(0,last_dot-1)+""+moment().format("YYYYMMDD_HHmmss")+"."+ext
                console.log("dwgName", dwgName)
                props.setLoading(true)
                var data = file.slice(0, file.size);
                var filePath = `dwg/${uuidv4()}.${ext}`
                var url = `${S3_URL()}uploadfile?filePath=${filePath}`                
                props.setViewFile(null);
                const headers = {headers: {'Content-Type': 'multipart/form-data'}};
                const response = await axiosInstance.post(url, data, headers);
                if(response.status == 200){
                    props.setViewFile({dwgPath:`${S3_URL()}s3/${filePath}`, dwgName:dwgName});
                    props.setOpenImportmodal(true);
                }


                openRef.current.value = null;
            }
        }, 300)
    }
    const onSaveBooth = async ()=>{
        
        // const canvasJson1 = mainCanvas.toDatalessObject();
        // console.log("canvasJson",canvasJson1)        
        // const canvasJson2 = mainCanvas.toDatalessObject();
        // console.log("canvasJson",canvasJson2)    
        // return;    
        var activeFile = props.main.active_booth_file
        
        props.setLoading(true)
        setTimeout(function(){
            if(activeFile){
                
                if(mainCanvas){
                    var convertRateX= Math.abs(mainCanvas.convertRateX)
                    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
                    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
                    
                    objects = mainCanvas.getObjects();   
                    for(var i = 0; i < objects.length; i++){
                        if(!objects[i].KEY_ID){
                            objects[i].set({KEY_ID: uuidv4()})
                        }
                    }

                    const canvasJson = (mainCanvas.toDatalessObject());
                    const bgImgJson = (bgImgCanvas.toDatalessObject());
                    var mainContent = {...canvasJson};
                    var bgImgContent = {...bgImgJson};
                    var newObjects = [];
                    var updateObjects = [];
                    var saveBooths = [];
                    var saveColumns = [];
                    var deleteBooths = [];
                    var saveTables = [];
                    var saveAnnotations = [];
                    var saveBackbrounds = [];
                    var saveSponsorships = [];
                    var zIndexArray = [];
                    // var removeObjects = props.main.removeObjects;
                    var removeObjects = [];
                    
                    var objects = canvasJson.objects
                    var firstObjects = props.main.firstMapContent&&props.main.firstMapContent.objects?props.main.firstMapContent.objects:[]
                    var firstBackground = props.main.firstBgContent&&props.main.firstBgContent.backgroundImage?props.main.firstBgContent.backgroundImage:null
                    if(bgImgContent){
                        if(firstBackground && firstBackground.KEY_ID && !bgImgContent.backgroundImage){
                            saveBackbrounds.push({
                                // FABRICJS:"",
                                OBJECTJS: JSON.stringify({fileName:bgImgCanvas.bgFileName}),
                                ACTIVE: 0,
                                STATUS: 1,
                                // SIZE_X: getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).width,
                                // SIZE_Y: getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).height,
                                KEY_ID:firstBackground.KEY_ID,
                                OBJECT_TYPE:'background',
                                SUB_TYPE:10,
                                TYPE:619,
                                EXHIBITORS:[],
                                // URL:(bgImgContent.backgroundImage&&bgImgContent.backgroundImage.src?bgImgContent.backgroundImage.src:""),
                                // INSERT_POINT:`${getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).left},${getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).top}`
                            })    
                        }
                        else if(bgImgContent.backgroundImage){
                            if(bgImgContent.backgroundImage.modified){
                                bgImgContent.backgroundImage.modified = false;   
                                bgImgContent.backgroundImage.left -=mapOffsetX;
                                bgImgContent.backgroundImage.top -=mapOffsetY;                              
                                saveBackbrounds.push({
                                    FABRICJS:(bgImgContent.backgroundImage?JSON.stringify(bgImgContent.backgroundImage):""),
                                    OBJECTJS: JSON.stringify({fileName:bgImgCanvas.bgFileName}),
                                    ACTIVE: 1,
                                    STATUS: 1,
                                    // SIZE_X: getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).width,
                                    // SIZE_Y: getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).height,
                                    SIZE_X: bgImgContent?.backgroundImage?.width * bgImgContent?.backgroundImage?.scaleX,
                                    SIZE_Y: bgImgContent?.backgroundImage?.height * bgImgContent?.backgroundImage?.scaleY,
                                    KEY_ID: bgImgContent.backgroundImage.KEY_ID,
                                    OBJECT_TYPE:'background',
                                    SUB_TYPE:10,
                                    TYPE:619,
                                    EXHIBITORS:[],
                                    URL:(bgImgContent.backgroundImage&&bgImgContent.backgroundImage.src?bgImgContent.backgroundImage.src:""),
                                    INSERT_POINT:`${getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).left},${getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).top}`
                                })    
                            }
                        }
                    }

                    for(var i = 0; i < firstObjects.length; i++){ //delete
                        myWorker.postMessage([i]);
                        if(checkIfSelectable(firstObjects[i])){
                            if(firstObjects[i].booth_number == "2013" || firstObjects[i].booth_number == "2112"){
                                // console.log(firstObjects[i])
                            }
                            var object = containsObject(firstObjects[i], objects, 'KEY_ID')
                            if(!object){
                                
                                removeObjects.push(firstObjects[i].KEY_ID)
                                if(firstObjects[i].class=="booth"){  
                                    var mapRemoveObjects = [...props.main.mapRemoveObjects]
                                    var mapRemoveObject
                                    var objectJS = {}
                                    if(mapRemoveObjects){
                                        for(var k = 0; k < mapRemoveObjects.length; k++){
                                            if(mapRemoveObjects[k]['KEY_ID'] == firstObjects[i]['KEY_ID']){
                                                mapRemoveObject = mapRemoveObjects[k]
                                            }
                                        }
                                        if(mapRemoveObject){
                                            objectJS.deleteType = mapRemoveObject.deleteType
                                            objectJS.deleteTime = mapRemoveObject.deleteTime
                                            objectJS.deleteUser =  mapRemoveObject.deleteUser
                                        }
                                    }

                                    var exhibitors = [];
                                    var prices = [];
                                    saveBooths.push({
                                        // BOOTH_NO: firstObjects[i].booth_number,
                                        // FABRICJS:JSON.stringify(firstObjects[i]),
                                        OBJECTJS: JSON.stringify(objectJS),
                                        ACTIVE: 1,
                                        STATUS: 3,
                                        // SIZE_X: getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                        // SIZE_Y: getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                        KEY_ID:firstObjects[i].KEY_ID,
                                        // OBJECT_TYPE:'rect_booth',
                                        // SUB_TYPE:1,
                                        // TYPE:619,
                                        // EXHIBITORS:exhibitors,
                                        // PRICE:[],
                                        // INSERT_POINT:`${getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                    })
                                }
                                if(firstObjects[i].class=="venue"){  
                                    var mapRemoveObjects = [...props.main.mapRemoveObjects]
                                    var mapRemoveObject
                                    var objectJS = {}
                                    if(mapRemoveObjects){
                                        for(var k = 0; k < mapRemoveObjects.length; k++){
                                            if(mapRemoveObjects[k]['KEY_ID'] == firstObjects[i]['KEY_ID']){
                                                mapRemoveObject = mapRemoveObjects[k]
                                            }
                                        }
                                        if(mapRemoveObject){
                                            objectJS.deleteType = mapRemoveObject.deleteType
                                            objectJS.deleteTime = mapRemoveObject.deleteTime
                                            objectJS.deleteUser =  mapRemoveObject.deleteUser
                                        }
                                    }

                                    var exhibitors = [];
                                    var prices = [];
                                    saveColumns.push({
                                        // BOOTH_NO: firstObjects[i].booth_number,
                                        // FABRICJS:JSON.stringify(firstObjects[i]),
                                        OBJECTJS: JSON.stringify(objectJS),
                                        ACTIVE: 1,
                                        STATUS: 3,
                                        // SIZE_X: getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                        // SIZE_Y: getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                        KEY_ID:firstObjects[i].KEY_ID,
                                        // OBJECT_TYPE:'rect_booth',
                                        // SUB_TYPE:1,
                                        // TYPE:619,
                                        // EXHIBITORS:exhibitors,
                                        // PRICE:[],
                                        // INSERT_POINT:`${getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                    })
                                }
                                else if(firstObjects[i].class=="table"){
                                    saveTables.push({
                                        ACTIVE: 0,
                                        STATUS: 1,
                                        KEY_ID:firstObjects[i].KEY_ID,
                                    })
                                }
                                else if(firstObjects[i].layer=="annotation"){
                                    // if(firstObjects[i].uniq_id)
                                    {
                                        var exhibitors = [];									
                                        saveAnnotations.push({
                                            ACTIVE: 0,
                                            STATUS: 1,
                                            KEY_ID:firstObjects[i].KEY_ID,
                                        })
                                        
                                        // console.log("objectContent.booth_number", objectContent.booth_number)
                                        // break;
                                    }
                                }
                                else if(firstObjects[i].layer=="sponsorships"){
                                    // if(firstObjects[i].uniq_id)
                                    {
                                        var exhibitors = [];									
                                        saveSponsorships.push({
                                            ACTIVE: 0,
                                            STATUS: 1,
                                            KEY_ID:firstObjects[i].KEY_ID,
                                        })
                                        
                                        // console.log("objectContent.booth_number", objectContent.booth_number)
                                        // break;
                                    }
                                }
                            }
                        }
                    }
                    for(var i = 0; i < objects.length; i++){ //new
                        myWorker.postMessage([i]);
                        if(checkIfSelectable(objects[i])){
                                if(!containsObject(objects[i], firstObjects, 'KEY_ID')){
                                    objects[i].new_created = false;
                                    objects[i].zIndex = i;
                                    objects[i].modified = false;
                                    objects[i].left -=mapOffsetX;
                                    objects[i].top -=mapOffsetY;                                
                                    newObjects.push(objects[i])
                                    if(objects[i].class=="booth"){
                                        var exhibitors = [];
                                        var prices = [];
                                        if(objects[i].exhibitors){
                                            exhibitors = objects[i].exhibitors
                                        }
                                        var saveBooth = {
                                            BOOTH_NO: objects[i].booth_number,
                                            FABRICJS:JSON.stringify(objects[i]),
                                            OBJECTJS:"",
                                            ACTIVE: 1,
                                            STATUS: objects[i].holdBooth?2:1,
                                            SIZE_X: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                            SIZE_Y: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                            KEY_ID:objects[i].KEY_ID,
                                            OBJECT_TYPE:'rect_booth',
                                            SUB_TYPE:1,
                                            TYPE:619,
                                            GROUP_KEY: objects[i].boothGroup,
                                            INVITE_CODE: objects[i].invite_code?objects[i].invite_code:"",
                                            // PRICE:prices,
                                            INSERT_POINT:`${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                        }
                                        var subObjects = objects[i].objects;
                                        var OBJECTJS = {}
                                        for(var ind = 0; ind< subObjects?.length; ind++){
                                            var subObject = subObjects[ind];
                                            if(subObject['type'] == 'polygon'){
                                                var points = [...subObject['points']]; 
                                                var newPonts = [];
                                                if(points.length > 5){
                                                    for(var pathInd = 0; pathInd< points?.length; pathInd++){
                                                        var point = {}
                                                        point['x'] = px2unit(points[pathInd]['x']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)
                                                        point['y'] = px2unit(points[pathInd]['y']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)    
                                                        newPonts.push(point)
                                                    }                                            
                                                    OBJECTJS = {poly: newPonts}
                                                    saveBooth.SUB_TYPE = 2
                                                }
                                                break;
                                            }
                                        }  
                                        OBJECTJS = {...OBJECTJS, privateNote:objects[i].privateNote, publicNote:objects[i].publicNote}
                                        saveBooth.OBJECTJS = JSON.stringify(OBJECTJS)
                                        if(exhibitors && exhibitors.length){
                                            saveBooth.EXHIBITORS = exhibitors
                                        }
                                        if(objects[i].priceCheck && objects[i].priceCheck.length){
                                            saveBooth.PRICE = objects[i].priceCheck
                                        }
                                        saveBooths.push(saveBooth)
                                    }
                                    if(objects[i].class=="venue"){
                                        var exhibitors = [];
                                        var prices = [];
                                        if(objects[i].exhibitors){
                                            exhibitors = objects[i].exhibitors
                                        }
                                        var saveBooth = {
                                            BOOTH_NO: objects[i].booth_number,
                                            FABRICJS:JSON.stringify(objects[i]),
                                            OBJECTJS:"",
                                            ACTIVE: 1,
                                            STATUS: objects[i].holdBooth?2:1,
                                            SIZE_X: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                            SIZE_Y: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                            KEY_ID:objects[i].KEY_ID,
                                            OBJECT_TYPE:'rect_booth',
                                            SUB_TYPE:17,
                                            TYPE:619,
                                            GROUP_KEY: objects[i].boothGroup,
                                            INVITE_CODE: objects[i].invite_code?objects[i].invite_code:"",
                                            // PRICE:prices,
                                            INSERT_POINT:`${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                        }
                                        var subObjects = objects[i].objects;
                                        var OBJECTJS = {}
                                        for(var ind = 0; ind< subObjects?.length; ind++){
                                            var subObject = subObjects[ind];
                                            if(subObject['type'] == 'polygon'){
                                                var points = [...subObject['points']]; 
                                                var newPonts = [];
                                                if(points.length > 5){
                                                    for(var pathInd = 0; pathInd< points?.length; pathInd++){
                                                        var point = {}
                                                        point['x'] = px2unit(points[pathInd]['x']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)
                                                        point['y'] = px2unit(points[pathInd]['y']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)    
                                                        newPonts.push(point)
                                                    }                                            
                                                    OBJECTJS = {poly: newPonts}
                                                    saveBooth.SUB_TYPE = 2
                                                }
                                                break;
                                            }
                                        }  
                                        OBJECTJS = {...OBJECTJS, privateNote:objects[i].privateNote, publicNote:objects[i].publicNote}
                                        saveBooth.OBJECTJS = JSON.stringify(OBJECTJS)
                                        if(exhibitors && exhibitors.length){
                                            saveBooth.EXHIBITORS = exhibitors
                                        }
                                        if(objects[i].priceCheck && objects[i].priceCheck.length){
                                            saveBooth.PRICE = objects[i].priceCheck
                                        }
                                        saveColumns.push(saveBooth)
                                    }
                                    else if(objects[i].class=="table"){
                                        var sub_type = 0;
                                        if(objects[i].class1 == "table_8"){
                                            sub_type = 1;
                                        }
                                        else if(objects[i].class1 == "table_10"){
                                            sub_type = 2;
                                        }
                                        else if(objects[i].class1 == "table_6"){
                                            sub_type = 3;
                                        }
                                        saveTables.push({
                                            TABLE_NO: objects[i].booth_number,
                                            FABRICJS:JSON.stringify(objects[i]),
                                            OBJECTJS:"",
                                            ACTIVE: 1,
                                            STATUS: 1,
                                            SIZE_X: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                            SIZE_Y: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                            KEY_ID:objects[i].KEY_ID,
                                            OBJECT_TYPE:(objects[i].class1?objects[i].class1:'table'),
                                            SUB_TYPE:sub_type,
                                            TYPE:186,
                                            EXHIBITORS:[],
                                            INSERT_POINT:`${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                        })
                                    }
                                    else if(objects[i].layer=="annotation"){
                                        // if(objects[i].uniq_id)
                                        {
                                            var object_type = ""	
                                            var sub_type = ""
                                            var img_url = ""
                                            if(objects[i].class1 == 'rect'){
                                                object_type = 'draw_box'
                                                sub_type = '70'
                                            }
                                            else if(objects[i].class1 == 'circle'){
                                                object_type = 'draw_circle'
                                                sub_type = '71'
                                            }
                                            else if(objects[i].class1 == 'line'){
                                                object_type = 'draw_line'
                                                sub_type = '72'
                                            }
                                            else if(objects[i].class1 == 'text'){
                                                object_type = 'draw_text'
                                                sub_type = '73'
                                            }
                                            else if(objects[i].class1 == 'pen'){
                                                object_type = 'draw_pen'
                                                sub_type = '75'
                                            }		
                                            else if(objects[i].class1 == 'arrow'){
                                                object_type = 'draw_arrow'
                                                sub_type = '76'
                                            }
                                            else if(objects[i].class1 == 'toilet-location-icon'){
                                                object_type = 'draw_toilet'
                                                sub_type = '77'
                                            }		
                                            else if(objects[i].class1 == 'food-location-icon'){
                                                object_type = 'draw_food'
                                                sub_type = '78'
                                            }			
                                            else if(objects[i].class1 == 'female-toilet-location-icon'){
                                                object_type = 'draw_female_toilet'
                                                sub_type = '79'
                                            }			
                                            else if(objects[i].class1 == 'food-restaurant-icon'){
                                                object_type = 'draw_food_restaurant'
                                                sub_type = '80'
                                            }	
                                            else if(objects[i].class1 == 'arrow-round-right-black-icon'){
                                                object_type = 'draw_arrow_round_right'
                                                sub_type = '81'
                                            }	
                                            else if(objects[i].class1 == 'coffee-icon'){
                                                object_type = 'draw_coffee'
                                                sub_type = '82'
                                            }	
                                            else if(objects[i].class1 == 'glass-martini-icon'){
                                                object_type = 'draw_glass_martini'
                                                sub_type = '83'
                                            }	
                                            else if(objects[i].class1 == 'camera-icon'){
                                                object_type = 'draw_camera'
                                                sub_type = '84'
                                            }	
                                            else if(objects[i].class1 == 'charging-station-icon'){
                                                object_type = 'draw_charging_station'
                                                sub_type = '85'
                                            }	
                                            else if(objects[i].class1 == 'star-sharp-icon'){
                                                object_type = 'draw_star_sharp'
                                                sub_type = '86'
                                            }	
                                            else if(objects[i].class1 == 'shirt-icon'){
                                                object_type = 'draw_shirt'
                                                sub_type = '87'
                                            }	
                                            else if(objects[i].class1 == 'water-icon'){
                                                object_type = 'draw_water'
                                                sub_type = '88'
                                            }	
                                            else if(objects[i].class1 == 'microphone-icon'){
                                                object_type = 'draw_microphone'
                                                sub_type = '89'
                                            }	
                                            var subObjects = objects[i].objects;
                                            for(var ind = 0; ind< subObjects?.length; ind++){
                                                var subObject = subObjects[ind];
                                                if(subObject['type'] == 'image'){
                                                    img_url = subObject['src'];
                                                }
                                            }
                                            saveAnnotations.push({
                                                BOOTH_NO: "",
                                                FABRICJS:JSON.stringify(objects[i]),
                                                OBJECTJS:JSON.stringify({img_url:img_url}),
                                                ACTIVE: 1,
                                                STATUS: 1,
                                                SIZE_X: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                                SIZE_Y: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                                KEY_ID:objects[i].KEY_ID,
                                                OBJECT_TYPE:object_type,
                                                SUB_TYPE:sub_type,
                                                TYPE:619,
                                                INSERT_POINT:`${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                            })
                                            
                                            // console.log("objectContent.booth_number", objectContent.booth_number)
                                            // break;
                                        }
                                    }
                                    else if(objects[i].layer=="sponsorships"){
                                        // if(objects[i].uniq_id)
                                        {
                                            var object_type = ""	
                                            var sub_type = ""
                                            var img_url = ""
                                            if(objects[i].class1 == 'rect'){
                                                object_type = 'draw_box'
                                                sub_type = '2'
                                            }
                                            else if(objects[i].class1 == 'circle'){
                                                object_type = 'draw_circle'
                                                sub_type = '1'
                                            }
                                            else if(objects[i].class1 == 'line'){
                                                object_type = 'draw_line'
                                                sub_type = '4'
                                            }
                                            else if(objects[i].class1 == 'text'){
                                                object_type = 'draw_text'
                                                sub_type = '6'
                                            }
                                            else if(objects[i].class1 == 'pen'){
                                                object_type = 'draw_pen'
                                                sub_type = '3'
                                            }		
                                            else if(objects[i].class1 == 'arrow'){
                                                object_type = 'draw_arrow'
                                                sub_type = '5'
                                            }
                                            var subObjects = objects[i].objects;
                                            for(var ind = 0; ind< subObjects?.length; ind++){
                                                var subObject = subObjects[ind];
                                                if(subObject['type'] == 'image'){
                                                    img_url = subObject['src'];
                                                }
                                            }
                                            var saveBooth = {
                                                BOOTH_NO: objects[i].sponsorshipId,
                                                FABRICJS:JSON.stringify(objects[i]),
                                                OBJECTJS:JSON.stringify({img_url:img_url}),
                                                ACTIVE: 1,
                                                STATUS: 1,
                                                SIZE_X: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                                SIZE_Y: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                                KEY_ID:objects[i].KEY_ID,
                                                OBJECT_TYPE:object_type,
                                                SUB_TYPE:sub_type,
                                                TYPE:357,
                                                INSERT_POINT:`${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                            }
                                            if(objects[i].priceCheck && objects[i].priceCheck.length){
                                                saveBooth.PRICE = objects[i].priceCheck
                                            }

                                            saveSponsorships.push(saveBooth)
                                            
                                            // console.log("objectContent.booth_number", objectContent.booth_number)
                                            // break;
                                        }
                                    }
                                }
                        }
                    }
                    for(var i = 0; i < objects.length; i++){ //update
                        myWorker.postMessage([i]);
                        if(checkIfSelectable(objects[i])){
                            if(objects[i].modified || objects[i].new_created){
                                objects[i].new_created = false;
                                objects[i].modified = false;
                                objects[i].zIndex = i;
                                objects[i].left -=mapOffsetX;
                                objects[i].top -=mapOffsetY;
                                updateObjects.push(objects[i])
                                if(objects[i].class=="booth"){
                                    var exhibitors = [];
                                    var prices = [];
                                    if(objects[i].exhibitors){
                                        exhibitors = objects[i].exhibitors
                                    }
                                    var firstObject = containsObject(objects[i], firstObjects, 'KEY_ID')
                                    var firstGeometry;
                                    if(firstObject){
                                        firstObject.left -=mapOffsetX;
                                        firstObject.top -=mapOffsetY;
                                        firstGeometry = getGeometry(mainCanvas, firstObject, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)
                                    }
                                    var lastGeometry = getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)

                                    var saveBooth = {
                                        BOOTH_NO: objects[i].booth_number,
                                        FABRICJS:JSON.stringify(objects[i]),
                                        OBJECTJS:"",
                                        ACTIVE: 1,
                                        STATUS: objects[i].holdBooth?2:1,
                                        KEY_ID:objects[i].KEY_ID,
                                        GROUP_KEY: objects[i].boothGroup,
                                        INVITE_CODE: objects[i].invite_code?objects[i].invite_code:"",
                                        OBJECT_TYPE:'rect_booth',
                                        SUB_TYPE:1,
                                        TYPE:619,
                                    }
                                    if(firstGeometry?.left != lastGeometry?.left || firstGeometry?.top != lastGeometry?.top){
                                        saveBooth.INSERT_POINT = `${lastGeometry.left},${lastGeometry.top}`
                                    }
                                    if(firstGeometry?.width != lastGeometry?.width){
                                        saveBooth.SIZE_X = `${lastGeometry.width}`
                                    }
                                    if(firstGeometry?.height != lastGeometry?.height){
                                        saveBooth.SIZE_Y = `${lastGeometry.height}`
                                    }
                                    if(exhibitors && exhibitors.length){
                                        saveBooth.EXHIBITORS = exhibitors
                                    }
                                    else{
                                        saveBooth.EXHIBITORS = []
                                    }
                                    var subObjects = [...objects[i].objects];
                                    var OBJECTJS = {}
                                    for(var ind = 0; ind< subObjects?.length; ind++){
                                        var subObject = subObjects[ind];
                                        if(subObject['type'] == 'polygon'){
                                            var points = [...subObject['points']]; 
                                            var newPonts = [];
                                            if(points.length > 5){
                                                for(var pathInd = 0; pathInd< points?.length; pathInd++){
                                                    var point = {}
                                                    point['x'] = px2unit(points[pathInd]['x']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)
                                                    point['y'] = px2unit(points[pathInd]['y']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)    
                                                    newPonts.push(point)
                                                }                                            
                                                OBJECTJS = {poly: newPonts}
                                                saveBooth.SUB_TYPE = 2
                                            }
                                            break;
                                        }
                                    }  
                                    // mainCanvas.renderAll();
                                    OBJECTJS = {...OBJECTJS, privateNote:objects[i].privateNote, publicNote:objects[i].publicNote}
                                    saveBooth.OBJECTJS = JSON.stringify(OBJECTJS)
                                    // if(objects[i].priceCheck && objects[i].priceCheck.length){
                                    //     saveBooth.PRICE = objects[i].priceCheck
                                    // }
                                    // else{
                                    //     saveBooth.PRICE = []
                                    // }
                                    var prices = [];
                                    var firstPrices = firstObject.priceCheck;
                                    var newPrices = objects[i].priceCheck                                    
                                    for(var priceInd = 0; priceInd< newPrices?.length; priceInd++){
                                        prices.push(newPrices[priceInd])
                                    }
                                    for(var priceInd = 0; priceInd< firstPrices?.length; priceInd++){
                                        var oldPrice = containsObject(firstPrices[priceInd], newPrices, 'PRICE_KEY')
                                        if(!oldPrice){
                                            prices.push({...firstPrices[priceInd], ACTIVE:0})
                                        }
                                    }
                                    saveBooth.PRICE = prices
                                    saveBooths.push(saveBooth)
                                }
                                if(objects[i].class=="venue"){
                                    var exhibitors = [];
                                    var prices = [];
                                    if(objects[i].exhibitors){
                                        exhibitors = objects[i].exhibitors
                                    }
                                    var firstObject = containsObject(objects[i], firstObjects, 'KEY_ID')
                                    var firstGeometry;
                                    if(firstObject){
                                        firstObject.left -=mapOffsetX;
                                        firstObject.top -=mapOffsetY;
                                        firstGeometry = getGeometry(mainCanvas, firstObject, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)
                                    }
                                    var lastGeometry = getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)

                                    var saveBooth = {
                                        BOOTH_NO: objects[i].booth_number,
                                        FABRICJS:JSON.stringify(objects[i]),
                                        OBJECTJS:"",
                                        ACTIVE: 1,
                                        STATUS: objects[i].holdBooth?2:1,
                                        KEY_ID:objects[i].KEY_ID,
                                        GROUP_KEY: objects[i].boothGroup,
                                        INVITE_CODE: objects[i].invite_code?objects[i].invite_code:"",
                                        OBJECT_TYPE:'rect_booth',
                                        SUB_TYPE:17,
                                        TYPE:619,
                                    }
                                    if(firstGeometry?.left != lastGeometry?.left || firstGeometry?.top != lastGeometry?.top){
                                        saveBooth.INSERT_POINT = `${lastGeometry.left},${lastGeometry.top}`
                                    }
                                    if(firstGeometry?.width != lastGeometry?.width){
                                        saveBooth.SIZE_X = `${lastGeometry.width}`
                                    }
                                    if(firstGeometry?.height != lastGeometry?.height){
                                        saveBooth.SIZE_Y = `${lastGeometry.height}`
                                    }
                                    if(exhibitors && exhibitors.length){
                                        saveBooth.EXHIBITORS = exhibitors
                                    }
                                    else{
                                        saveBooth.EXHIBITORS = []
                                    }
                                    var subObjects = [...objects[i].objects];
                                    var OBJECTJS = {}
                                    for(var ind = 0; ind< subObjects?.length; ind++){
                                        var subObject = subObjects[ind];
                                        if(subObject['type'] == 'polygon'){
                                            var points = [...subObject['points']]; 
                                            var newPonts = [];
                                            if(points.length > 5){
                                                for(var pathInd = 0; pathInd< points?.length; pathInd++){
                                                    var point = {}
                                                    point['x'] = px2unit(points[pathInd]['x']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)
                                                    point['y'] = px2unit(points[pathInd]['y']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)    
                                                    newPonts.push(point)
                                                }                                            
                                                OBJECTJS = {poly: newPonts}
                                                saveBooth.SUB_TYPE = 2
                                            }
                                            break;
                                        }
                                    }  
                                    // mainCanvas.renderAll();
                                    OBJECTJS = {...OBJECTJS, privateNote:objects[i].privateNote, publicNote:objects[i].publicNote}
                                    saveBooth.OBJECTJS = JSON.stringify(OBJECTJS)
                                    // if(objects[i].priceCheck && objects[i].priceCheck.length){
                                    //     saveBooth.PRICE = objects[i].priceCheck
                                    // }
                                    // else{
                                    //     saveBooth.PRICE = []
                                    // }
                                    var prices = [];
                                    var firstPrices = firstObject.priceCheck;
                                    var newPrices = objects[i].priceCheck                                    
                                    for(var priceInd = 0; priceInd< newPrices?.length; priceInd++){
                                        prices.push(newPrices[priceInd])
                                    }
                                    for(var priceInd = 0; priceInd< firstPrices?.length; priceInd++){
                                        var oldPrice = containsObject(firstPrices[priceInd], newPrices, 'PRICE_KEY')
                                        if(!oldPrice){
                                            prices.push({...firstPrices[priceInd], ACTIVE:0})
                                        }
                                    }
                                    saveBooth.PRICE = prices
                                    saveColumns.push(saveBooth)
                                }
                                else if(objects[i].class=="table"){
                                    var sub_type = 0;
                                    if(objects[i].class1 == "table_8"){
                                        sub_type = 1;
                                    }
                                    else if(objects[i].class1 == "table_10"){
                                        sub_type = 2;
                                    }
                                    else if(objects[i].class1 == "table_6"){
                                        sub_type = 3;
                                    }
                                    var firstObject = containsObject(objects[i], firstObjects, 'KEY_ID')
                                    var firstGeometry;
                                    if(firstObject){
                                        firstObject.left -=mapOffsetX;
                                        firstObject.top -=mapOffsetY;
                                        firstGeometry = getGeometry(mainCanvas, firstObject, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)
                                    }
                                    var lastGeometry = getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)

                                    var saveTable = {
                                        TABLE_NO: objects[i].booth_number,
                                        FABRICJS:JSON.stringify(objects[i]),
                                        OBJECTJS:"",
                                        ACTIVE: 1,
                                        STATUS: 1,
                                        // SIZE_X: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                        // SIZE_Y: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                        KEY_ID:objects[i].KEY_ID,
                                        OBJECT_TYPE:(objects[i].class1?objects[i].class1:'table'),
                                        SUB_TYPE:sub_type,
                                        TYPE:186,
                                        EXHIBITORS:[],
                                        // INSERT_POINT:`${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                    }
                                    if(firstGeometry?.left != lastGeometry?.left || firstGeometry?.top != lastGeometry?.top){
                                        saveTable.INSERT_POINT = `${lastGeometry.left},${lastGeometry.top}`
                                    }
                                    if(firstGeometry?.width != lastGeometry?.width){
                                        saveTable.SIZE_X = `${lastGeometry.width},${lastGeometry.width}`
                                    }
                                    if(firstGeometry?.height != lastGeometry?.height){
                                        saveTable.SIZE_Y = `${lastGeometry.height},${lastGeometry.height}`
                                    }
                                    saveTables.push(saveTable)
                                }
                                else if(objects[i].layer=="annotation"){
                                    // if(objects[i].uniq_id)
                                    {
                                        var object_type = ""	
                                        var sub_type = ""
                                        var img_url = ""
                                        if(objects[i].class1 == 'rect'){
                                            object_type = 'draw_box'
                                            sub_type = '70'
                                        }
                                        else if(objects[i].class1 == 'circle'){
                                            object_type = 'draw_circle'
                                            sub_type = '71'
                                        }
                                        else if(objects[i].class1 == 'line'){
                                            object_type = 'draw_line'
                                            sub_type = '72'
                                        }
                                        else if(objects[i].class1 == 'text'){
                                            object_type = 'draw_text'
                                            sub_type = '73'
                                        }
                                        else if(objects[i].class1 == 'pen'){
                                            object_type = 'draw_pen'
                                            sub_type = '75'
                                        }
                                        else if(objects[i].class1 == 'arrow'){
                                            object_type = 'draw_arrow'
                                            sub_type = '76'
                                        }
                                        else if(objects[i].class1 == 'toilet-location-icon'){
                                            object_type = 'draw_toilet'
                                            sub_type = '77'
                                        }		
                                        else if(objects[i].class1 == 'food-location-icon'){
                                            object_type = 'draw_food'
                                            sub_type = '78'
                                        }			
                                        else if(objects[i].class1 == 'female-toilet-location-icon'){
                                            object_type = 'draw_emale_toilet'
                                            sub_type = '79'
                                        }			
                                        else if(objects[i].class1 == 'food-restaurant-icon'){
                                            object_type = 'draw_food_restaurant'
                                            sub_type = '80'
                                        }	
                                        else if(objects[i].class1 == 'arrow-round-right-black-icon'){
                                            object_type = 'draw_arrow_round_right'
                                            sub_type = '81'
                                        }	
                                        else if(objects[i].class1 == 'coffee-icon'){
                                            object_type = 'draw_coffee'
                                            sub_type = '82'
                                        }	
                                        else if(objects[i].class1 == 'glass-martini-icon'){
                                            object_type = 'draw_glass_martini'
                                            sub_type = '83'
                                        }	
                                        else if(objects[i].class1 == 'camera-icon'){
                                            object_type = 'draw_camera'
                                            sub_type = '84'
                                        }	
                                        else if(objects[i].class1 == 'charging-station-icon'){
                                            object_type = 'draw_charging_station'
                                            sub_type = '85'
                                        }	
                                        else if(objects[i].class1 == 'star-sharp-icon'){
                                            object_type = 'draw_star_sharp'
                                            sub_type = '86'
                                        }	
                                        else if(objects[i].class1 == 'shirt-icon'){
                                            object_type = 'draw_shirt'
                                            sub_type = '87'
                                        }	
                                        else if(objects[i].class1 == 'water-icon'){
                                            object_type = 'draw_water'
                                            sub_type = '88'
                                        }	
                                        else if(objects[i].class1 == 'microphone-icon'){
                                            object_type = 'draw_microphone'
                                            sub_type = '89'
                                        }	
                                        var subObjects = objects[i].objects;
                                        for(var ind = 0; ind< subObjects?.length; ind++){
                                            var subObject = subObjects[ind];
                                            if(subObject['type'] == 'image'){
                                                img_url = subObject['src'];
                                            }
                                        }
                                        var firstObject = containsObject(objects[i], firstObjects, 'KEY_ID')
                                        var firstGeometry;
                                        if(firstObject){
                                            firstObject.left -=mapOffsetX;
                                            firstObject.top -=mapOffsetY;
                                            firstGeometry = getGeometry(mainCanvas, firstObject, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)
                                        }
                                        var lastGeometry = getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)
                                
                                        var saveAnnotation = {
                                            BOOTH_NO: "",
                                            FABRICJS:JSON.stringify(objects[i]),
                                            OBJECTJS:JSON.stringify({img_url:img_url}),
                                            ACTIVE: 1,
                                            STATUS: 1,
                                            // SIZE_X: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                            // SIZE_Y: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                            KEY_ID:objects[i].KEY_ID,
                                            OBJECT_TYPE:object_type,
                                            SUB_TYPE:sub_type,
                                            TYPE:619,
                                            // INSERT_POINT:`${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                        }
                                        if(firstGeometry?.left != lastGeometry?.left || firstGeometry?.top != lastGeometry?.top){
                                            saveAnnotation.INSERT_POINT = `${lastGeometry.left},${lastGeometry.top}`
                                        }
                                        if(firstGeometry?.width != lastGeometry?.width){
                                            saveAnnotation.SIZE_X = `${lastGeometry.width},${lastGeometry.width}`
                                        }
                                        if(firstGeometry?.height != lastGeometry?.height){
                                            saveAnnotation.SIZE_Y = `${lastGeometry.height},${lastGeometry.height}`
                                        }
                                        saveAnnotations.push(saveAnnotation)
                                        
                                        // console.log("objectContent.booth_number", objectContent.booth_number)
                                        // break;
                                    }
                                }
                                else if(objects[i].layer=="sponsorships"){
                                    // if(objects[i].uniq_id)
                                    {
                                        var object_type = ""	
                                        var sub_type = ""
                                        var img_url = ""
                                        if(objects[i].class1 == 'rect'){
                                            object_type = 'draw_box'
                                            sub_type = '2'
                                        }
                                        else if(objects[i].class1 == 'circle'){
                                            object_type = 'draw_circle'
                                            sub_type = '1'
                                        }
                                        else if(objects[i].class1 == 'line'){
                                            object_type = 'draw_line'
                                            sub_type = '4'
                                        }
                                        else if(objects[i].class1 == 'text'){
                                            object_type = 'draw_text'
                                            sub_type = '6'
                                        }
                                        else if(objects[i].class1 == 'pen'){
                                            object_type = 'draw_pen'
                                            sub_type = '3'
                                        }		
                                        else if(objects[i].class1 == 'arrow'){
                                            object_type = 'draw_arrow'
                                            sub_type = '5'
                                        }
                                        var subObjects = objects[i].objects;
                                        for(var ind = 0; ind< subObjects?.length; ind++){
                                            var subObject = subObjects[ind];
                                            if(subObject['type'] == 'image'){
                                                img_url = subObject['src'];
                                            }
                                        }

                                        var firstObject = containsObject(objects[i], firstObjects, 'KEY_ID')
                                        var firstGeometry;
                                        if(firstObject){
                                            firstObject.left -=mapOffsetX;
                                            firstObject.top -=mapOffsetY;
                                            firstGeometry = getGeometry(mainCanvas, firstObject, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)
                                        }
                                        var lastGeometry = getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)
                                
                                        var saveBooth = {
                                            BOOTH_NO: objects[i].sponsorshipId,
                                            FABRICJS:JSON.stringify(objects[i]),
                                            OBJECTJS:JSON.stringify({img_url:img_url}),
                                            ACTIVE: 1,
                                            STATUS: 1,
                                            SIZE_X: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                            SIZE_Y: getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                            KEY_ID:objects[i].KEY_ID,
                                            OBJECT_TYPE:object_type,
                                            SUB_TYPE:sub_type,
                                            TYPE:357,
                                            INSERT_POINT:`${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                        }
                                        var prices = [];
                                        var firstPrices = firstObject.priceCheck;
                                        var newPrices = objects[i].priceCheck                                    
                                        for(var priceInd = 0; priceInd< newPrices?.length; priceInd++){
                                            prices.push(newPrices[priceInd])
                                        }
                                        for(var priceInd = 0; priceInd< firstPrices?.length; priceInd++){
                                            var oldPrice = containsObject(firstPrices[priceInd], newPrices, 'PRICE_KEY')
                                            if(!oldPrice){
                                                prices.push({...firstPrices[priceInd], ACTIVE:0})
                                            }
                                        }
                                        saveBooth.PRICE = prices
                                        
                                        if(firstGeometry?.left != lastGeometry?.left || firstGeometry?.top != lastGeometry?.top){
                                            saveBooth.INSERT_POINT = `${lastGeometry.left},${lastGeometry.top}`
                                        }
                                        if(firstGeometry?.width != lastGeometry?.width){
                                            saveBooth.SIZE_X = `${lastGeometry.width},${lastGeometry.width}`
                                        }
                                        if(firstGeometry?.height != lastGeometry?.height){
                                            saveBooth.SIZE_Y = `${lastGeometry.height},${lastGeometry.height}`
                                        }

                                        saveSponsorships.push(saveBooth)
                                        
                                        // console.log("objectContent.booth_number", objectContent.booth_number)
                                        // break;
                                    }
                                }
                            }
                        }
                    }
                    mainContent.objects = [];
                    objects = mainCanvas.getObjects();   
                    for(var i = 0; i < objects.length; i++){
                            objects[i].zIndex = i;
                    }
                    mainCanvas.renderAll()

                    props.setRemoveObjects([])
                    // props.setMapRemoveObjects([])
                    // console.log("canvasJson", canvasJson)
                    // console.log("newObjects", newObjects)
                    // console.log("updateObjects", updateObjects)
                    // console.log("removeObjects", removeObjects)
                    // console.log("saveBooths", saveBooths)
                    // console.log("deleteBooths", deleteBooths)
                    // console.log("saveTables", saveTables)
                    // console.log("saveBackbrounds", saveBackbrounds)
                    // console.log("saveAnnotations", saveAnnotations)
                    // console.log("zIndexArray", zIndexArray)
                    var newLogs = [];
                    for(var i = 0; i < props.log.logs?.length; i++){
                        var log = props.log.logs[i]
                        if(!log.old){
                            newLogs.push({
                                BOOTH_KEY: log.BOOTH_KEY?log.BOOTH_KEY:"",
                                KEY_ID: log.KEY_ID?log.KEY_ID:uuidv4(),
                                ACTIVE: 1,
                                TYPE: 2012,
                                LOG_TEXT: (log),
                                USER_NAME: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                            })
                        }
                    }
                    // console.log("logs", newLogs)
                    myWorker.terminate();

                    // props.setLoading(false)
                    // return;
                    if(canvasJson){
                        props.setLoading(true)
                        // var url = API_URL()+`uploadboothfile?ax-file-path=/content/drawings/boothfile/`

                        // var xhr = new XMLHttpRequest();
                        // xhr.open("POST", url);

                        // // xhr.setRequestHeader("Accept", "image/apng");
                    
                        // xhr.onreadystatechange = function () {
                        // if (xhr.readyState === 4) {
                        //     console.log(xhr.responseText);
                        setTimeout(function(){
                            var oldMAPJS = {...active_booth_file};
                            delete oldMAPJS.MAPJS
                            delete oldMAPJS.DISPLAY_ORDER
                            delete oldMAPJS.STATUS
                            delete oldMAPJS.KEY_ID
                            delete oldMAPJS.TITLE
                            delete oldMAPJS.ACTIVE
                            var MAPJS = 
                            {
                                // ...oldMAPJS,
                                mainContent: JSON.stringify({...canvasJson, objects:[]}),
                                convertRateX: mainCanvas.convertRateX,
                                convertRateY: mainCanvas.convertRateY,
                                convertOffsetX: mainCanvas.convertOffsetX,
                                convertOffsetY: mainCanvas.convertOffsetY, 
                                startX:mainCanvas.startX, 
                                startY:mainCanvas.startY, 
                                endX:mainCanvas.endX, 
                                endY:mainCanvas.endY,
                                mapWidth: mainCanvas.mapWidth,
                                mapHeight: mainCanvas.mapHeight,
                                mapUnit: mainCanvas.mapUnit,
                                convertUnit: mainCanvas.convertUnit, 
                                gridSize: mainCanvas.gridSize, 
                                showGrid: mainCanvas.showGrid, 
                                snapToGrid: mainCanvas.snapToGrid,
                                gridOffsetX: mainCanvas.gridOffsetX,
                                gridOffsetY: mainCanvas.gridOffsetY,
                                gridOffsetXDefault: mainCanvas.gridOffsetXDefault,
                                gridOffsetYDefault: mainCanvas.gridOffsetYDefault ,
                                mapOffsetX: mainCanvas.mapOffsetX,          
                                mapOffsetY: mainCanvas.mapOffsetY   ,          
                                boothfile_brand: mainCanvas.boothfile_brand   ,          
                                contract_pdf: mainCanvas.contract_pdf   ,
                                sales_form: mainCanvas.sales_form   ,   
                                event_home: mainCanvas.event_home,         
                                // log: JSON.stringify(props.log.logs)  ,
                                // mapRemoveObjects: JSON.stringify([...props.main.mapRemoveObjects])   
                                mapRemoveObjects: []            
                            }
                            var postData = [{
                                DISPLAY_ORDER: active_booth_file.DISPLAY_ORDER,
                                STATUS: active_booth_file.STATUS,
                                TITLE: active_booth_file.TITLE,
                                KEY_ID: active_booth_file.KEY_ID,
                                MAPJS:JSON.stringify(MAPJS)
                            }]
                            var formdata = {
                                query:`${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`,
                                postData: postData
                            }
                            var data = qs.stringify({
                                request: JSON.stringify(formdata)
                            });
                            const options = {
                                headers: {
                                    'body': '[{"company:","test"}]', 
                                    'Content-Type': 'application/json'
                                }
                            }
                            var url = `${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`
                            axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
                            var eshowResponse = res.data;
                            if(eshowResponse.SUCCESS == true){
                                var postData = {
                                    // BOOTHS: saveBooths,
                                    // DELETED: deleteBooths,
                                    // BACKGROUND: saveBackbrounds,
                                    // TABLES: saveTables,
                                    // ANNOTATIONS: saveAnnotations,
                                    SUCCESS: true,
                                }
                                if(saveBooths && saveBooths.length){
                                    postData.BOOTHS = saveBooths
                                }
                                if(saveColumns && saveColumns.length){
                                    postData.COLUMNS = saveColumns
                                }
                                if(saveSponsorships && saveSponsorships.length){
                                    postData.SPONSORSHIPS = saveSponsorships
                                }
                                if(deleteBooths && deleteBooths.length){
                                    postData.DELETED = deleteBooths
                                }
                                if(saveBackbrounds && saveBackbrounds.length){
                                    postData.BACKGROUND = saveBackbrounds
                                }
                                if(saveTables && saveTables.length){
                                    postData.TABLES = saveTables
                                }
                                if(saveAnnotations && saveAnnotations.length){
                                    postData.ANNOTATIONS = saveAnnotations
                                }
                                var formdata = {
                                    query:`${ESHOW_URL()}?method=putMapData&map_key=${active_booth_file.KEY_ID}&token=${getTokenCookie()}`,
                                    postData: postData,
                                    returnResponse: true
                                }
                                var data = qs.stringify({
                                    request: JSON.stringify(formdata)
                                });
                                const options = {
                                    headers: {
                                        'body': '[{"company:","test"}]', 
                                        'Content-Type': 'application/json'
                                    }
                                }
                                // var param = `${ESHOW_URL()}?method=putMapData&map_key=${active_booth_file.KEY_ID}&token=${getTokenCookie()}`
                                // var url = 
                                // `https://dev.goeshow.com/webservices/eshow/floor_space.cfc?${param}`
                                // const options = {
                                //     headers: {
                                //         'body': '[{"company:","test"}]', 
                                //         'Content-Type': 'application/json'
                                //     }
                                // }
                                var url = `${ESHOW_URL()}?method=putMapData&map_key=${active_booth_file.KEY_ID}&token=${getTokenCookie()}`
                                axiosInstance.post(url,JSON.stringify(postData), options).then(res=>{
                                    const eshowResponse = res.data;
                                    if(eshowResponse.SUCCESS == true){
                                        saveLog(newLogs);
                                        setOpenSnack(true);
                                        setSnackMsg("Updated successfully");
                                        for(var i = 0; i < objects.length; i++){
                                            objects[i].new_created = false;
                                            objects[i].modified = false;
                                        }
                                        mainCanvas.renderAll()
                                        props.setFirstMapContent(mainCanvas.toDatalessObject())   
                                        props.setFirstBgContent(bgImgCanvas.toDatalessObject())
                                        props.setLoading(false)
                                        props.onClearHistory();
                                        setTimeout(function(){
                                            props.setBoothHistory({content: (mainCanvas.toDatalessObject()), action:{type:'load'}})
                                            props.fetchmaplist(active_booth_file.KEY_ID)
                                        },100)
                                    }
                                    else{
                                        setOpenSnack(true);
                                        setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to update");
                                        props.setLoading(false)
                                    }
                                }).catch(error => {
                                    console.log("error", error);
                                })
                            }
                            else{
                                setOpenSnack(true);
                                setSnackMsg("Failed to update");
                                props.setLoading(false)
                            }
                            }).catch(error => {
                                //console.log("error", error);
                                props.setLoading(false)
                            })  
                            
                        }, 200)
                        // }};

                        // xhr.send((data));
                    }
                }
            }
            else{
                setSaveFileName(null)
                confirmSaveFile()
                props.setLoading(false)
                // props.setOpenFilenameModal(true);            
            }
        }, 300)
    }

    const onSaveIndividuals = async (addedObjects, updatedObjects, removedObjects, method = false, getFlag = false)=>{
        // console.log("updatedObjects", updatedObjects);
        // return;
        var activeFile = props.main.active_booth_file
        if(activeFile){
            
            if(mainCanvas){
                var convertRateX= Math.abs(mainCanvas.convertRateX)
                var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
                var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
                
                const canvasJson = (mainCanvas.toDatalessObject());
                const bgImgJson = (bgImgCanvas.toDatalessObject());
                var newObjects = [];
                var updateObjects = [];
                var saveBooths = [];
                var saveSponsorships = [];
                var deleteBooths = [];
                var saveTables = [];
                var saveAnnotations = [];
                var saveBackbrounds = [];
                var zIndexArray = [];
                // var removeObjects = props.main.removeObjects;
                var removeObjects = [];
                var objects
                var firstObjects = props.main.firstMapContent&&props.main.firstMapContent.objects?props.main.firstMapContent.objects:[]
                objects = removedObjects?removedObjects:[]
                for(var i = 0; i < objects.length; i++){ //delete
                    {
                        var object = objects[i].toDatalessObject()
                        if(object){                            
                            removeObjects.push(object.KEY_ID)
                            if(object.class=="booth"){  
                                var mapRemoveObjects = [...props.main.mapRemoveObjects]
                                var mapRemoveObject
                                var objectJS = {}
                                if(mapRemoveObjects){
                                    for(var k = 0; k < mapRemoveObjects.length; k++){
                                        if(mapRemoveObjects[k]['KEY_ID'] == object['KEY_ID']){
                                            mapRemoveObject = mapRemoveObjects[k]
                                        }
                                    }
                                    if(mapRemoveObject){
                                        objectJS.deleteType = mapRemoveObject.deleteType
                                        objectJS.deleteTime = mapRemoveObject.deleteTime
                                        objectJS.deleteUser =  mapRemoveObject.deleteUser
                                    }
                                }

                                var exhibitors = [];
                                var prices = [];
                                saveBooths.push({
                                    OBJECTJS: JSON.stringify(objectJS),
                                    ACTIVE: 1,
                                    STATUS: 3,
                                    // SIZE_X: getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                    // SIZE_Y: getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                    KEY_ID:object.KEY_ID,
                                    // OBJECT_TYPE:'rect_booth',
                                    // SUB_TYPE:1,
                                    // TYPE:619,
                                    // EXHIBITORS:exhibitors,
                                    // PRICE:[],
                                    // INSERT_POINT:`${getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, firstObjects[i], convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                })
                            }
                        }
                    }
                }
                objects = addedObjects?addedObjects:[]
                for(var i = 0; i < objects.length; i++){ //new
                    var object = objects[i].toDatalessObject()
                    object.new_created = false;
                    // object.zIndex = i;
                    object.modified = false;
                    object.left -=mapOffsetX;
                    object.top -=mapOffsetY;                                
                    newObjects.push(object)
                    if(object.class=="booth"){
                        var exhibitors = [];
                        var prices = [];
                        if(object.exhibitors){
                            exhibitors = object.exhibitors
                        }
                        var saveBooth = {
                            BOOTH_NO: object.booth_number,
                            FABRICJS:JSON.stringify(object),
                            OBJECTJS:"",
                            ACTIVE: 1,
                            STATUS: object.holdBooth?2:1,
                            SIZE_X: getGeometry(mainCanvas, object, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                            SIZE_Y: getGeometry(mainCanvas, object, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                            KEY_ID:object.KEY_ID,
                            OBJECT_TYPE:'rect_booth',
                            SUB_TYPE:1,
                            TYPE:619,
                            GROUP_KEY: object.boothGroup,
                            INVITE_CODE: object.invite_code?object.invite_code:"",
                            // PRICE:prices,
                            INSERT_POINT:`${getGeometry(mainCanvas, object, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, object, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                        }
                        var subObjects = object.objects;
                        var OBJECTJS = {}
                        for(var ind = 0; ind< subObjects?.length; ind++){
                            var subObject = subObjects[ind];
                            if(subObject['type'] == 'polygon'){
                                var points = [...subObject['points']]; 
                                var newPonts = [];
                                if(points.length > 5){
                                    for(var pathInd = 0; pathInd< points?.length; pathInd++){
                                        var point = {}
                                        point['x'] = px2unit(points[pathInd]['x']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)
                                        point['y'] = px2unit(points[pathInd]['y']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)    
                                        newPonts.push(point)
                                    }                                            
                                    OBJECTJS = {poly: newPonts}
                                    saveBooth.SUB_TYPE = 2
                                }
                                break;
                            }
                        }  
                        OBJECTJS = {...OBJECTJS, privateNote:object.privateNote, publicNote:object.publicNote}
                        saveBooth.OBJECTJS = JSON.stringify(OBJECTJS)
                        if(exhibitors && exhibitors.length){
                            saveBooth.EXHIBITORS = exhibitors
                        }
                        if(object.priceCheck && object.priceCheck.length){
                            saveBooth.PRICE = object.priceCheck
                        }
                        saveBooths.push(saveBooth)
                    }
                }
                objects = updatedObjects?updatedObjects:[]
                for(var i = 0; i < objects.length; i++){ //update
                    // zIndexArray.push({uniq_id: objects[i].uniq_id, zIndex: i});
                    if(checkIfSelectable(objects[i])){
                        // if(objects[i].new_created){
                        //     objects[i].new_created = false;
                        //     // updateObjects.push(objects[i])
                        // }
                        // else 
                        {                            
                            var object = objects[i].toDatalessObject()
                            object.new_created = false;
                            object.modified = false;
                            object.left -=mapOffsetX;
                            object.top -=mapOffsetY;
                            updateObjects.push(object)
                            if(object.class=="booth"){
                                var exhibitors = [];
                                var prices = [];
                                if(object.exhibitors){
                                    exhibitors = object.exhibitors
                                }
                                var firstObject = containsObject(object, firstObjects, 'KEY_ID')
                                var firstGeometry;
                                if(firstObject){
                                    firstObject.left -=mapOffsetX;
                                    firstObject.top -=mapOffsetY;
                                    firstGeometry = getGeometry(mainCanvas, firstObject, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)
                                }
                                var lastGeometry = getGeometry(mainCanvas, object, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY)

                                var saveBooth = {
                                    BOOTH_NO: object.booth_number,
                                    FABRICJS:JSON.stringify(object),
                                    OBJECTJS:"",
                                    ACTIVE: 1,
                                    STATUS: object.holdBooth?2:1,
                                    KEY_ID:object.KEY_ID,
                                    GROUP_KEY: object.boothGroup,
                                    INVITE_CODE: object.invite_code?object.invite_code:"",
                                    OBJECT_TYPE:'rect_booth',
                                    SUB_TYPE:1,
                                    TYPE:619,
                                }
                                if(firstGeometry?.left != lastGeometry?.left || firstGeometry?.top != lastGeometry?.top){
                                    saveBooth.INSERT_POINT = `${lastGeometry.left},${lastGeometry.top}`
                                }
                                if(firstGeometry?.width != lastGeometry?.width){
                                    saveBooth.SIZE_X = `${lastGeometry.width}`
                                }
                                if(firstGeometry?.height != lastGeometry?.height){
                                    saveBooth.SIZE_Y = `${lastGeometry.height}`
                                }
                                if(exhibitors && exhibitors.length){
                                    saveBooth.EXHIBITORS = exhibitors
                                }
                                else{
                                    saveBooth.EXHIBITORS = []
                                }
                                var subObjects = object.objects;
                                var OBJECTJS = {}
                                for(var ind = 0; ind< subObjects?.length; ind++){
                                    var subObject = subObjects[ind];
                                    if(subObject['type'] == 'polygon'){
                                        var points = [...subObject['points']]; 
                                        var newPonts = [];
                                        if(points.length > 5){
                                            for(var pathInd = 0; pathInd< points?.length; pathInd++){
                                                var point = {}
                                                point['x'] = px2unit(points[pathInd]['x']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)
                                                point['y'] = px2unit(points[pathInd]['y']-50, convertRateX, props.main.mainCanvas.mapUnit).toFixed(1)    
                                                newPonts.push(point)
                                            }                                            
                                            OBJECTJS = {poly: newPonts}
                                            saveBooth.SUB_TYPE = 2
                                        }
                                        break;
                                    }
                                }  
                                OBJECTJS = {...OBJECTJS, privateNote:object.privateNote, publicNote:object.publicNote}
                                saveBooth.OBJECTJS = JSON.stringify(OBJECTJS)
                                // if(object.priceCheck && object.priceCheck.length){
                                //     saveBooth.PRICE = object.priceCheck
                                // }
                                // else{
                                //     saveBooth.PRICE = []
                                // }
                                var prices = [];
                                var firstPrices = firstObject.priceCheck;
                                var newPrices = object.priceCheck                                    
                                for(var priceInd = 0; priceInd< newPrices?.length; priceInd++){
                                    prices.push(newPrices[priceInd])
                                }
                                for(var priceInd = 0; priceInd< firstPrices?.length; priceInd++){
                                    var oldPrice = containsObject(firstPrices[priceInd], newPrices, 'PRICE_KEY')
                                    if(!oldPrice){
                                        prices.push({...firstPrices[priceInd], ACTIVE:0})
                                    }
                                }
                                saveBooth.PRICE = prices
                                saveBooths.push(saveBooth)
                            }
                        }
                    }
                }
                if(getFlag){
                    return saveBooths;
                }
                props.setRemoveObjects([])
                // props.setMapRemoveObjects([])
                // console.log("canvasJson", canvasJson)
                // console.log("newObjects", newObjects)
                // console.log("updateObjects", updateObjects)
                // console.log("removeObjects", removeObjects)
                // console.log("saveBooths", saveBooths)
                // console.log("deleteBooths", deleteBooths)
                // console.log("saveTables", saveTables)
                // console.log("saveBackbrounds", saveBackbrounds)
                // console.log("saveAnnotations", saveAnnotations)
                // return;      
                //test
                // if(g_groupedItem){                            
                //     unGroupItems(true, g_groupedItem)
                // }
                // return
                //test
                if(canvasJson){
                    props.setLoading(true)
                    setTimeout(function(){
                        var postData = {
                            // BOOTHS: saveBooths,
                            // DELETED: deleteBooths,
                            // BACKGROUND: saveBackbrounds,
                            // TABLES: saveTables,
                            // ANNOTATIONS: saveAnnotations,
                            SUCCESS: true,
                        }
                        if(saveBooths && saveBooths.length){
                            postData.BOOTHS = saveBooths
                        }
                        if(deleteBooths && deleteBooths.length){
                            postData.DELETED = deleteBooths
                        }
                        if(saveBackbrounds && saveBackbrounds.length){
                            postData.BACKGROUND = saveBackbrounds
                        }
                        if(saveTables && saveTables.length){
                            postData.TABLES = saveTables
                        }
                        if(saveAnnotations && saveAnnotations.length){
                            postData.ANNOTATIONS = saveAnnotations
                        }
                        if(method){
                            postData.METHOD=method
                        }
                        var formdata = {
                            query:`${ESHOW_URL()}?method=${method=='COMBINE'?'CombineExhibitor':'putMapData'}&map_key=${active_booth_file.KEY_ID}&token=${getTokenCookie()}`,
                            postData: postData,
                            returnResponse: true
                        }
                        var data = qs.stringify({
                            request: JSON.stringify(formdata)
                        });
                        const options = {
                            headers: {
                                'body': '[{"company:","test"}]', 
                                'Content-Type': 'application/json'
                              }
                        }
                        var url = `${ESHOW_URL()}?method=${method=='COMBINE'?'CombineExhibitor':'putMapData'}&map_key=${active_booth_file.KEY_ID}&token=${getTokenCookie()}`
                        axiosInstance.post(url,JSON.stringify(postData), options).then(res=>{
                            props.setLoading(false)
                            const eshowResponse = res.data;
                            if(eshowResponse.SUCCESS == true){
                                // setOpenSnack(true);
                                // setSnackMsg("Updated successfully");
                            }
                            else{                                
                                if(g_groupedItem){                            
                                    unGroupItems(true, g_groupedItem)
                                }
                                setOpenSnack(true);
                                setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to update");
                                props.setLoading(false)
                            }
                            g_groupedItem = false
                        }).catch(error => {
                            props.setLoading(false)
                            console.log("error", error);
                        })                        
                    }, 200)
                }
            }
        }
        else{
            setSaveFileName(null)
            confirmSaveFile()
        }
    }



    const onSaveShowSetting = (settingData)=>{
        
        var boothFileList = props.main.boothFileList;
        var activeFile = props.main.active_booth_file
        if(activeFile){
            
            if(mainCanvas){
                const canvasJson = (mainCanvas.toDatalessObject());
                // console.log("canvasJson", canvasJson)
                if(canvasJson){
                    canvasJson.objects = []
                    var postData = {
                        SUCCESS: true,
                        ...settingData
                    }
                    var formdata = {
                        query:`${ESHOW_URL()}?method=putShowSetup&map_key=${active_booth_file.KEY_ID}&token=${getTokenCookie()}`,
                        postData: postData,
                        returnResponse: true
                    }
                    var data = qs.stringify({
                        request: JSON.stringify(formdata)
                    });
                    const options = {
                        headers: {
                            'body': '[{"company:","test"}]', 
                            'Content-Type': 'application/json'
                          }
                    }
                    props.setLoading(true)
                    var url = `${ESHOW_URL()}?method=putShowSetup&map_key=${active_booth_file.KEY_ID}&token=${getTokenCookie()}`
                    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
                        var eshowResponse = res.data;
                        if(eshowResponse.SUCCESS == true){
                        }
                        else{
                            setOpenSnack(true);
                            setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to update");
                        }
                        props.setLoading(false)
                    }).catch(error => {
                        console.log("error", error);
                    })
                }
            }
        }
        else{
            setSaveFileName(null)
            props.setOpenFilenameModal(true);            
        }
    }

    const onSaveSetting = (settingData)=>{
        
        var boothFileList = props.main.boothFileList;
        var activeFile = props.main.active_booth_file
        if(activeFile){
            
            if(mainCanvas){
                const canvasJson = (mainCanvas.toDatalessObject());
                // console.log("canvasJson", canvasJson)
                if(canvasJson){
                    canvasJson.objects = []
                    var postData = {
                        SUCCESS: true,
                        ...settingData
                    }
                    var formdata = {
                        query:`${ESHOW_URL()}?method=putSetup&map_key=${active_booth_file.KEY_ID}&token=${getTokenCookie()}`,
                        postData: postData,
                        returnResponse: true
                    }
                    var data = qs.stringify({
                        request: JSON.stringify(formdata)
                    });
                    const options = {
                        headers: {
                            'body': '[{"company:","test"}]', 
                            'Content-Type': 'application/json'
                          }
                    }
                    props.setLoading(true)
                    var url = `${ESHOW_URL()}?method=putSetup&map_key=${active_booth_file.KEY_ID}&token=${getTokenCookie()}`
                    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
                        const eshowResponse = res.data;
                        if(eshowResponse.SUCCESS == true){
                            setOpenSnack(true);
                            setSnackMsg("Updated successfully");
                            setTimeout(function(){
                                props.fetchmaplist(active_booth_file.KEY_ID)
                            },100)
                        }
                        else{
                            setOpenSnack(true);
                            setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to update");
                        }
                        props.setLoading(false)
                    }).catch(error => {
                        console.log("error", error);
                    })
                }
            }
        }
        else{
            setSaveFileName(null)
            props.setOpenFilenameModal(true);            
        }
    }

    const confirmSaveFile = async()=>{    
        if(!props.main.saveFileName) {
            alert("Enter file name first.")
            return false;
        }   
        props.setOpenFilenameModal(false);   
        return;        
    }

    const firstMigrate = async ()=>{   
        
        // console.log("end import", Math.floor(Date.now() / 1000))
        //test code start
        // g_fileSaved = true       
        // props.setLoading(false)
        // return;         
        //test code end
        console.log("active_booth_file", active_booth_file)
        if(!active_booth_file?.KEY_ID){
            alert("Select a map to migrate")
            return;
        }
        props.setLoading(false)
        var show_key = authInfo.show_key;
        var user_key = authInfo.user_key;
        props.setOpenFilenameModal(false);   
        var mainCanvas = props.main.mainCanvas;
        if(mainCanvas){
            mainCanvas.setZoom(mainCanvas.getZoom()*1.00000000001)
            mainCanvas.renderAll();
            const boothJson = (props.main.mainCanvas.toDatalessObject());
            const bgImgJson = (props.main.bgImgCanvas.toDatalessObject());
            var groups = mainCanvas.getObjects('group');
            var gridOffsetX
            var gridOffsetY
            for(var i = 0; i < groups.length; i++){
                var group = groups[i]
                if(group.get('class') == 'booth' || group.get('class') == 'booth-group'){
                    if(gridOffsetX == undefined || gridOffsetY == undefined){
                        gridOffsetX = group.left;  gridOffsetY = group.top
                    }
                    if(group.top < gridOffsetY && group.left < gridOffsetX){
                        gridOffsetX = group.left
                        gridOffsetY = group.top
                    }
                    if(group.left < gridOffsetX){
                        gridOffsetX = group.left
                        gridOffsetY = group.top
                    }
                }
            }

            
            var objects = props.main.mainCanvas.getObjects('group');
            var convertRateX= 1
            if(props.main.mainCanvas.convertRateX) {
                var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
            }
            var tmp_totalOrgQty = 0;
            var tmp_totalOrgSqft = 0;
            for(var i = 0; i< objects.length; i++){
                var object = objects[i]
                var row = {}                      
                if(object.get('class')=="original-booth"){
                var booth_width = unit2Px(object.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
                var booth_height = unit2Px(object.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
                var sqrt = 0;
                if(object.class1 == "custom-booth"){
                    var objects = object.getObjects('polyline');
                    if(objects && objects.length > 0 ){
                        var points = objects[0].points;
                        var polyline = [];
                        for(var i = 0; i < points.length; i++){
                            polyline.push([px2unit(points[i].x, convertRateX, props.main.mainCanvas.mapUnit), px2unit(points[i].y, convertRateX, props.main.mainCanvas.mapUnit)])
                        }
                        sqrt = area(polyline)
                    }
                }
                else{
                    sqrt = (booth_width*booth_height)
                }
                tmp_totalOrgQty++;
                tmp_totalOrgSqft += (sqrt*1).toFixed(0)*1;
                }
            }
            //console.log("boothJson", boothJson)
            if(boothJson){
                props.setLoading(true)
                props.setFirstMapContent(boothJson)       
                props.setFirstBgContent(bgImgCanvas.toDatalessObject())         
                setTimeout(function(){
                    // props.setLoading(true)
                    var save_id = parseInt(Math.random()*10000000)
                    var mainContent = {...boothJson}
                    var bgImgContent = {...bgImgJson}
                    mainContent.objects = [];
                    bgImgContent.objects = []
                    var MAPJS = 
                    {
                        mainContent: JSON.stringify(mainContent),
                        filename:props.main.saveFileName,
                        content:[],
                        boothList:[],
                        save_id:save_id,
                        show_key: show_key,
                        user_key: user_key,
                        convertRateX: mainCanvas.convertRateX,
                        convertRateY: mainCanvas.convertRateY,
                        convertOffsetX: mainCanvas.convertOffsetX,
                        convertOffsetY: mainCanvas.convertOffsetY, 
                        startX:mainCanvas.startX, 
                        startY:mainCanvas.startY, 
                        endX:mainCanvas.endX, 
                        endY:mainCanvas.endY,
                        mapWidth: mainCanvas.mapWidth,
                        mapHeight: mainCanvas.mapHeight,
                        mapUnit: mainCanvas.mapUnit?mainCanvas.mapUnit:"feet",
                        convertUnit: mainCanvas.convertUnit, 
                        mapLock: mainCanvas.mapLock,
                        gridSize: mainCanvas.gridSize, 
                        showGrid:true, 
                        snapToGrid: true,
                        gridAngle: mainCanvas.gridAngle,
                        gridOffsetX: gridOffsetX,
                        gridOffsetY: gridOffsetY,
                        gridOffsetXDefault: gridOffsetX,
                        gridOffsetYDefault: gridOffsetY,
                        mapOffsetX: mainCanvas.mapOffsetX,          
                        mapOffsetY: mainCanvas.mapOffsetY,
                        org_booth_cnt: tmp_totalOrgQty,
                        org_booth_sqrt: tmp_totalOrgSqft,
                        MIGRATE:""
                    }
                    var mapKEY_ID = active_booth_file.KEY_ID;
                    var postData = [{
                        // DISPLAY_ORDER: 0,
                        // STATUS: "",
                        // TITLE: props.main.saveFileName,
                        MAPJS: JSON.stringify(MAPJS),
                        KEY_ID: mapKEY_ID,
                        MIGRATE:""
                    }]
                    // console.log("postData", postData); 
                    var formdata = {
                        query:`${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`,
                        postData: postData
                    }
                    var data = qs.stringify({
                        request: JSON.stringify(formdata)
                    });
                    const options = {
                        headers: {
                            'body': '[{"company:","test"}]', 
                            'Content-Type': 'application/json'
                          }
                    }
                    var url = `${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`
                    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
                        var eshowResponse = res.data
                        if(eshowResponse.SUCCESS == true){
                            var booths = [];
                            var tables = [];
                            var annotations = [];
                            var backgrounds = [];
                            if(bgImgContent?.backgroundImage){
                                backgrounds.push({
                                    FABRICJS:(bgImgContent.backgroundImage?JSON.stringify(bgImgContent.backgroundImage):""),
                                    OBJECTJS:"",
                                    ACTIVE: 1,
                                    STATUS: 1,
                                    // SIZE_X: getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).width,
                                    // SIZE_Y: getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).height,
                                    KEY_ID:bgImgContent.backgroundImage.KEY_ID,
                                    OBJECT_TYPE:'background',
                                    // SUB_TYPE:10,
                                    // TYPE:619,
                                    // EXHIBITORS:[],
                                    // URL:(bgImgContent.backgroundImage&&bgImgContent.backgroundImage.src?bgImgContent.backgroundImage.src:""),
                                    // INSERT_POINT:`${getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).left},${getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).top}`
                                })
                            }

                            var objects = boothJson['objects']
                            for(var i = 0; i < objects.length; i++){
                                var objectContent = objects[i];
                                if(objectContent.class=="booth"){
                                    if(objectContent.booth_number){
                                        booths.push({
                                            FABRICJS:JSON.stringify(objectContent),
                                            KEY_ID:objectContent.KEY_ID,
                                            ACTIVE: 1
                                        })
                                        
                                        // console.log("objectContent.booth_number", objectContent.booth_number)
                                        // break;
                                    }
                                }
                                else if(objectContent.class=="table"){
                                    if(objectContent.booth_number){
                                        tables.push({
                                            FABRICJS:JSON.stringify(objectContent),
                                            KEY_ID:objectContent.KEY_ID,
                                            ACTIVE: 1
                                        })                                        
                                    }
                                }
                            }
                            var postData = {
                                BOOTHS: booths,
                                BACKGROUND: backgrounds,
                                TABLES: tables,
                                ANNOTATIONS: annotations,
                                SUCCESS: true,
                            }
                            url = `${ESHOW_URL()}?method=putMapData&map_key=${mapKEY_ID}&token=${getTokenCookie()}`
                            var formdata = {
                                query:`${ESHOW_URL()}?method=putMapData&map_key=${mapKEY_ID}&token=${getTokenCookie()}`,
                                postData: postData,
                                returnResponse: true
                            }
                            var data = qs.stringify({
                                request: JSON.stringify(formdata)
                            });
                            const options = {
                                headers: {
                                    'body': '[{"company:","test"}]', 
                                    'Content-Type': 'application/json'
                                  }
                            }
                            // var param = `${ESHOW_URL()}?method=putMapData&map_key=${mapKEY_ID}&token=${getTokenCookie()}`
                            // var url = 
		                    // `https://dev.goeshow.com/webservices/eshow/floor_space.cfc?${param}`
                            // const options = {
                            //     headers: {
                            //         'body': '[{"company:","test"}]', 
                            //         'Content-Type': 'application/json'
                            //     }
                            // }
                            axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
                                var eshowResponse = res.data
                                if(eshowResponse.SUCCESS == true){
                                    g_fileSaved = true  
                                    var convertRateX= 1
                                    var convertRateY= 1
                                    if(props.main.mainCanvas.convertRateX) {
                                        convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
                                        convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
                                    }
                                    convertRateX= Math.abs(mainCanvas.convertRateX)
                                    
                                    const boothJson = (props.main.mainCanvas.toDatalessObject());
                                    var booths = [];
                                    var objects = boothJson['objects']
                                    for(var i = 0; i < objects.length; i++){
                                        var objectContent = objects[i];
                                        if(objectContent.class=="booth"){
                                            if(objectContent.booth_number){
                                                booths.push({
                                                    BOOTH_NO: objectContent.booth_number,
                                                    FABRICJS:JSON.stringify(objectContent),
                                                    OBJECTJS:"",
                                                    ACTIVE: 1,
                                                    STATUS: 1,
                                                    SIZE_X: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                                                    SIZE_Y: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                                                    KEY_ID:objectContent.KEY_ID?objectContent.KEY_ID:uuidv4(),
                                                    OBJECT_TYPE:'rect_booth',
                                                    SUB_TYPE:1,
                                                    TYPE:619,
                                                    EXHIBITORS:[],
                                                    INSERT_POINT:`${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                                                })                    
                                            }
                                        }
                                    }
                                    const options = {
                                        headers: {
                                            'body': '[{"company:","test"}]', 
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                    var param = `?method=putOriginalLayout&map_key=${mapKEY_ID}&token=${getTokenCookie()}`
                                    var url = 
                                    `${ESHOW_URL()}${param}`
                                    var postData = {
                                        LAYOUT: booths,
                                        SUCCESS: true,
                                    }
                                    axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{                                        
                                        props.fetchmaplist(mapKEY_ID)
                                        alert("migrated successfully")
                                        window.location.reload()
                                    }).catch(error => {
                                    })  
                                }
                                else{
                                    props.setLoading(false)
                                }
                                props.setLoading(false)
                            }).catch(error => {
                                //console.log("error", error);
                            })  
                        }
                        else{
                            props.setLoading(false)
                        }
                    }).catch(error => {
                        props.setLoading(false)
                        alert("something went wrong")
                        //console.log("error", error);
                    })  
                },100)          
            }
        }
    }

    const onChangeSaveFileName = (e)=>{
        setSaveFileName(e.target.value)
    }

    const getNearLeftObject = (target, objects)=>{
        var nearObject = null
        for(var i = 0; i < objects.length; i++) {
            var object = objects[i]
            if(object.id != target.id){
                if(!nearObject){
                    nearObject = object;
                    continue;
                }
                else{
                    if(object.left < target.left){
                        if(object.left > nearObject.left){
                            nearObject = object;
                        }
                    }
                }
            }
        }
        return nearObject
    }

    
    const getNearRightObject = (target, objects)=>{
        var nearObject = null
        for(var i = 0; i < objects.length; i++) {
            var object = objects[i]
            if(object.id != target.id){
                if(!nearObject){
                    nearObject = object;
                    continue;
                }
                else{
                    if(object.left > target.left){
                        if(object.left < nearObject.left){
                            nearObject = object;
                        }
                    }
                }
            }
        }
        return nearObject
    }

    const getNearTopObject = (target, objects)=>{
        var nearObject = null
        for(var i = 0; i < objects.length; i++) {
            var object = objects[i]
            if(object.id != target.id){
                if(!nearObject){
                    nearObject = object;
                    continue;
                }
                else{
                    if(object.top < target.top){
                        if(object.top > nearObject.top){
                            nearObject = object;
                        }
                    }
                }
            }
        }
        return nearObject
    }

    const getNearBottomObject = (target, objects)=>{
        var nearObject = null
        for(var i = 0; i < objects.length; i++) {
            var object = objects[i]
            if(object.id != target.id){
                if(!nearObject){
                    nearObject = object;
                    continue;
                }
                else{
                    if(object.top > target.top){
                        if(object.top < nearObject.top){
                            nearObject = object;
                        }
                    }
                }
            }
        }
        return nearObject
    }

    const checkifApart = (groupobject)=>{
        
        var objects = groupobject.getObjects();
        for(var i = 0; i< objects.length; i++){
            for(var j = i+1; j < objects.length; j++){
                if(objects[j].width > objects[i].width*1.5 || 
                    objects[i].width > objects[j].width*1.5 || 
                    objects[j].height > objects[i].height*1.5 || 
                    objects[i].height > objects[j].height*1.5)
                return true;
            }
        }
        objects.sort(
            function(a,b){
               return a.left>b.left ? 1:-1
            }
        )
        var apartFlag = 0;

        var beforeLeft = -1000000; var beforeWidth = 0; 
        var totalWidth = 0; var objectCnt = 0
        var foundNext = true; var beforeObject = null;
        while(foundNext){
            foundNext = false;
            var minLeft = 100000000000
            for(var i = 0; i< objects.length; i++){
                if(objects[i].get('class') == 'booth-group' || objects[i].get('class') == 'booth'){                   
                    var objectLeft = objects[i].left; 
                    var objectWidth = objects[i].width;
                    if(objectLeft >= beforeLeft + beforeWidth - 2){
                        if(objectLeft <= minLeft){
                            beforeLeft = objectLeft; beforeWidth = objectWidth;
                            minLeft = beforeLeft
                            foundNext = true;
                            beforeObject = objects[i]
                        }
                    }
                }
            }
            if(foundNext){
                totalWidth+=beforeObject.width
                objectCnt++;
            }
            else {
                break;
            }
        }
        if(groupobject.width > totalWidth+2){
            apartFlag = true;
        }
        // console.log("objectCnt",objectCnt);
        // console.log("totalWidth",totalWidth);

        objects.sort(
            function(a,b){
               return a.top>b.top ? 1:-1
            }
        )
        var beforeTop = -1000000; var beforeHeight = 0; 
        var totalHeight = 0; var objectCnt = 0
        var foundNext = true; var beforeObject = null;
        while(foundNext){
            foundNext = false;
            var minTop = 100000000000
            for(var i = 0; i< objects.length; i++){
                if(objects[i].get('class') == 'booth-group' || objects[i].get('class') == 'booth'){                   
                    var objectTop = objects[i].top; 
                    var objectHeight = objects[i].height;
                    if(objectTop >= beforeTop + beforeHeight - 2){
                        if(objectTop <= minTop){
                            beforeTop = objectTop; beforeHeight = objectHeight;
                            minTop = beforeTop
                            foundNext = true;
                            beforeObject = objects[i]
                        }
                    }
                    else{
                        if(objectHeight > beforeHeight){
                            beforeTop = objectTop; beforeHeight = objectHeight;
                            minTop = beforeTop
                            foundNext = true;
                            beforeObject = objects[i]
                        }
                    }
                }
            }
            if(foundNext){
                totalHeight+=beforeObject.height
                objectCnt++;
            }
            else {
                break;
            }
        }
        if(groupobject.height > totalHeight+2){
            apartFlag = true;
        }
        // console.log("objectCnt",objectCnt);
        // console.log("totalWidth",totalHeight);
        return apartFlag
    }


    const groupItems = (newBoothNumber, fromUndo, org_id, historyFlag = true)=>{
        var orgObjects = []
        var groupobject = mainCanvas.getActiveObject();
        var objects = groupobject.getObjects();
        if(!checkCombinable(objects)){
            setSnackMsg("Failed to combine");
            setOpenSnack(true);
            return 
        }
        for(var i = 0; i< objects.length; i++){
            objects[i].clone(function(cloned) {                    
                cloned.set(buildObjectProperty(objects[i]))
                orgObjects.push(cloned)                                                    
            });
        }
        var orgObject;
        orgObjects.forEach(function(object) {
            if(object.booth_number == newBoothNumber){
                orgObject = object; return;
            }
        });
        
        var selectedNumbers = [];
        for(var i = 0; i < objects.length; i++){
            if(objects[i].booth_number != orgObject.booth_number){
                selectedNumbers.push(objects[i].booth_number)
            }
        }

        if(selectedNumbers.includes(newBoothNumber)){ 
            setOpenSnack(true);           
            setSnackMsg("Booth Number exists already");
            return;
        }     

        var firstObjects = props.main.firstMapContent&&props.main.firstMapContent.objects?props.main.firstMapContent.objects:[]
        var check_objects = [...firstObjects]
        for(var i = 0; i < check_objects.length; i++){
            if(check_objects[i].booth_number != orgObject.booth_number){
                if(check_objects[i].booth_number == newBoothNumber && newBoothNumber && check_objects[i].class=="booth"){ 
                    setOpenSnack(true);           
                    setSnackMsg("Booth Number exists already");
                    return;
                }
            }
        }      
        check_objects = [...props.main.mapRemoveObjects] 
        for(var i = 0; i < check_objects.length; i++){
            if(check_objects[i].booth_number != orgObject.booth_number){
                if(checkIfSelectable(check_objects[i])&&check_objects[i].booth_number == newBoothNumber && newBoothNumber && check_objects[i].class=="booth"){ 
                    setOpenSnack(true);           
                    setSnackMsg("Booth Number exists already");
                    return;
                }
            }
        }
        check_objects = mainCanvas.getObjects();
        for(var i = 0; i < check_objects.length; i++){
            if(check_objects[i].booth_number != orgObject.booth_number){
                if(checkIfSelectable(check_objects[i]) && check_objects[i].class=="booth"){
                    if(check_objects[i].booth_number == newBoothNumber && newBoothNumber){ 
                        setOpenSnack(true);           
                        setSnackMsg("Booth Number exists already");
                        return;
                    }
                }
            }
        }


        var convertRateX= 1
        if(mainCanvas.convertRateX) {
            var convertRateX= Math.abs(mainCanvas.convertRateX)
        }
        setGroupedItem(null);
        // console.log("type", mainCanvas.getActiveObject().toClipPathSVG());
        if (mainCanvas && !mainCanvas.getActiveObject()) {
            return;
        }
        if (mainCanvas.getActiveObject().type !== 'activeSelection') {
            return;
        }
        var newBooths = [];
        var groupobject = mainCanvas.getActiveObject();
        var apartFlag = false;//checkifApart(groupobject);        
        
        {
            canvasApp.helperCanvas = mainCanvas
            var group = canvasApp.combineHelper.openCombineWindow(false, grouppingBooth);
            if(group == false){
                apartFlag = true;
            }
            if(!apartFlag){
                var orgNames = ""
                for(var i = 0; i < orgObjects.length; i++){
                    orgNames+=((orgNames == ""?"":",") + orgObjects[i].booth_number)
                }
                props.addLog({
                    BOOTH_KEY:group.KEY_ID,
                    KEY_ID:uuidv4(),
                    action: 'combine',
                    date: new Date().toLocaleString(),
                    description: "combined "+orgNames+" to "+newBoothNumber,
                    user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                })
                
                if(!fromUndo){      
                    if(historyFlag)          
                        props.setBoothHistory({content: (mainCanvas.toDatalessObject()), action:{type:'combine', input:orgObjects, output: group}})
                    var removeObjects = [...props.main.removeObjects]
                    var mapRemoveObjects = [...props.main.mapRemoveObjects]
                    for(var i = 0; i < orgObjects.length; i++){
                        removeObjects = [...removeObjects, orgObjects[i].uniq_id]
                        if(orgObjects[i].KEY_ID != group.KEY_ID){
                            orgObjects[i].deleteType = 'combined'
                            orgObjects[i].deleteTime = new Date().toLocaleString()
                            orgObjects[i].deleteUser =  (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                            mapRemoveObjects = [...mapRemoveObjects, orgObjects[i].toDatalessObject()]
                        }
                    }
                    props.setRemoveObjects(removeObjects)
                    props.setMapRemoveObjects(mapRemoveObjects)
                }
                mainCanvas.requestRenderAll();
                setGroupedItem(group);
                setOpenSnack(true);
                setSnackMsg("Combined");
                setOpenGrouppingModal(false)
                group.moveTo(group.zIndex)
                props.setCanvasRefresh(true)
                // setGrouppingBooth(null);
                return group
            }
        }
        if(checkContainSold(objects) && apartFlag){
            setSnackMsg("Failed to combine");
            setOpenSnack(true);
            return 
        }
        if(apartFlag){
            var group = groupApartObjects(groupobject, mainCanvas, props.main.groupOptions, grouppingBooth?grouppingBooth:newBoothNumber, org_id)
            group.set({
                left:groupobject.left,                
                top:groupobject.top
            });
            if(false){
                var objectsInGroup = groupobject.getObjects();
                let masterLeftOffset=0;
                let masterTopOffset=0;
                var minObject = objectsInGroup[0];
                for(let ii=0;(ii<objectsInGroup.length);ii++){
                                if(objectsInGroup[ii].left < minObject.left){
                                    minObject = objectsInGroup[ii]
                                }
                                if(objectsInGroup[ii].top < minObject.top){
                                    minObject = objectsInGroup[ii]
                                }
                }
                let coords = [];
                let pointArray = [];
                let masterLeft=0;
                let masterTop=0;
                masterLeftOffset = minObject.left
                masterTopOffset = minObject.top
                var centerPoint = new fabric.Point(masterLeftOffset, masterTopOffset);

                let  newElements = [];
                for(let i=0;(i<objectsInGroup.length);i++){
                var objectOrigin = new fabric.Point(objectsInGroup[i].left, objectsInGroup[i].top);
                angle = minObject.angle
                var new_loc = fabric.util.rotatePoint(objectOrigin, centerPoint, angle*2*Math.PI/360*(-1))
                masterTop=new_loc.y  - Number(masterTopOffset);
                masterLeft=new_loc.x  - Number(masterLeftOffset);
                        
                if (typeof objectsInGroup[i]._objects !=='undefined'){
                    var angle = objectsInGroup[i].angle?objectsInGroup[i].angle:0
                    var childObjects = objectsInGroup[i]._objects.forEach(function(childobj){      
                        if (!childobj.type.toUpperCase().includes("TEXT")){
                        pointArray = [];
                        var points = childobj.points
                        var pathOffset = childobj.pathOffset
                        var paths = [];
                        let basicPoint = points[0]
                        for(var j = 0; j < points?.length; j++){      
                            var point = points[j];
                            if((point['x'] + point['y']) < (basicPoint['x'] + basicPoint['y'])){
                            basicPoint = point
                            }
                        }

                        for(var j = 0; j < points?.length; j++){
                            var objectOrigin = false
                            // if(points[0]['x'] == 0 ){
                            objectOrigin = new fabric.Point((points[j]['x']) + Number(masterLeft), (points[j]['y']) +  Number(masterTop));
                            // }
                            // else{
                            objectOrigin = new fabric.Point((points[j]['x'] - basicPoint['x']) + Number(masterLeft) + Number(childobj.left)+(pathOffset.x - basicPoint['x']), (points[j]['y'] - basicPoint['y']) +  Number(masterTop) + Number(childobj.top)+(pathOffset.y- basicPoint['y']));
                            // }
                            // paths.push(
                            // {x:objectOrigin.x, y:objectOrigin.y }
                            // )
                            
                            paths.push(
                                {x:Math.round((objectOrigin.x)*10)/10, y:Math.round((objectOrigin.y)*10)/10 }
                            )
                        }
                        // coords.push([paths]);
                        let newElement = new fabric.Polygon(
                            paths
                            , {
                                strokeWidth: 0
                        });

                        newElements.push(newElement)
                        }
                    });
                }
                
                }
                var measureGroup = new fabric.ActiveSelection(newElements, {canvas:mainCanvas});
                // var measureGroup = new fabric.Group(newElements).set({
                //     class: 'booth',
                //     class1 : 'booth-group',
                //     lockRotation: true,
                //     lockScalingX: true,
                //     lockScalingY: true,
                //     strokeWidth:0
                // });	
                let tests = []
                let test1 = new fabric.Polygon(
                    [
                        {x:0, y:0},
                        {x:100, y:0},
                        {x:100, y:100},
                        {x:0, y:100},
                        {x:0, y:0},
                    ]
                    , {
                        left:0,
                        top:0,
                        strokeWidth: 0
                });

                tests.push(test1)

                let test2 = new fabric.Polygon(
                    [
                        {x:0, y:200},
                        {x:100, y:200},
                        {x:100, y:300},
                        {x:0, y:300},
                        {x:0, y:200},
                    ]
                    , {
                        left:0,
                        top:0,
                        strokeWidth: 0
                });

                tests.push(test2)
                var testsGroup = new fabric.ActiveSelection(tests, {canvas:mainCanvas});

                var boothId = "G_"+parseInt(Math.random()*(10000))

                var bgColor = "#dadada"
                var element = new fabric.Polygon(
                    [
                        {x: 0, y:0},
                        {x: measureGroup.width, y:0},
                        {x: measureGroup.width, y:measureGroup.height},
                        {x: 0, y:measureGroup.height},
                        {x: 0, y:0}
                    ], {
                    class:"outline",
                    left:0,
                    top: 0,
                    fill: bgColor, 
                    stroke: 'black',
                    strokeWidth: getStrokeWidth(mainCanvas),
                    borderColor: 'black',
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                });
                
                element.set({
                    borderColor: '#ff7777',
                    cornerColor: 'red',
                    cornerSize: 6,
                    transparentCorners: false
                });

                var group = new fabric.Group([element]).set({
                    id: org_id?org_id:'booth-'+boothId,
                    class: 'booth',
                    boothGroup: getDefaultGroup(props.main.groupOptions),
                    class1 : 'booth-group',
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    angle: minObject.angle,
                    left:groupobject.left + groupobject.width/2 + minObject.left-3/Math.max(convertRateX, 1)/2,
                    top: groupobject.top + groupobject.height/2 + minObject.top-3/Math.max(convertRateX, 1)/2,
                });	
                group.set({
                    borderColor: '#ff7777',
                    cornerColor: 'red',
                    cornerSize: 6,
                    transparentCorners: false,
                    layer:'booth',
                    booth_number: grouppingBooth?grouppingBooth:newBoothNumber
                });
            }


            for(var i = 0; i < objects.length; i++){
                mainCanvas.remove(objects[i])
            } 

            var orgObject;
			// var sorted = orgObjects.sort((a, b) =>a.booth_number*1-b.booth_number*1);
            // orgObject=sorted[0];
            orgObjects.forEach(function(object) {
				if(object.booth_number == newBoothNumber){
					orgObject = object; return;
				}
			});

            var removeObjects = [...props.main.removeObjects]
            var mapRemoveObjects = [...props.main.mapRemoveObjects]
            for(var i = 0; i < orgObjects.length; i++){
                removeObjects = [...removeObjects, orgObjects[i].uniq_id]
                
                if(orgObjects[i].KEY_ID != orgObject.KEY_ID){
                    orgObjects[i].deleteType = 'combined'
                    orgObjects[i].deleteTime = new Date().toLocaleString()
                    orgObjects[i].deleteUser =  (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                    mapRemoveObjects = [...mapRemoveObjects, orgObjects[i].toDatalessObject()]
                }
            }
            props.setRemoveObjects(removeObjects)
            props.setMapRemoveObjects(mapRemoveObjects)

            
            // element.clone(function(cloned) {
            //     group.clipPath = cloned                                    
            // });
            mainCanvas.add(group).renderAll();
            
            mainCanvas.requestRenderAll();
            setGroupedItem(group);
            handleConfirmGrouppingModal(group, newBoothNumber)
            // setGrouppingBooth(null);
            var tmp = [];
            var active_object = mainCanvas.getActiveObject();
			mainCanvas.discardActiveObject();
            for(var i = 0; i < objects.length; i++){
                var json = objects[i].toDatalessObject()
                json.left = json.left - active_object.left
                json.top = json.top - active_object.top
                tmp.push(json)
            }   
            group.child_booths = tmp
            group.moveTo(group.zIndex)
            mainCanvas.requestRenderAll();

            if(!fromUndo){
                if(historyFlag)      
                    props.setBoothHistory({content: (mainCanvas.toDatalessObject()), action:{type:'combine', input:objects, output: group}})
            }
            var orgNames = ""
            for(var i = 0; i < objects.length; i++){
                orgNames+=((orgNames == ""?"":",") + objects[i].booth_number)
            }
            props.addLog({
                BOOTH_KEY:group.KEY_ID,
                KEY_ID:uuidv4(),
                action: 'combine',
                date: new Date().toLocaleString(),
                description: "combined "+orgNames+" to "+newBoothNumber,
                user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
            })
            
            return group
        }
        
    }
    

    const unGroupItems = (fromUndo = false, activeObject=false)=>{
        
        var convertRateX= 1
        if(mainCanvas.convertRateX) {
            var convertRateX= Math.abs(mainCanvas.convertRateX)
        }
        if(!activeObject)
            activeObject = mainCanvas.getActiveObject();
        if (!activeObject) {
            return;
        }
        if (activeObject.type !== 'group' || (activeObject.get('class') !== 'booth-group' && activeObject.get('class') !== 'booth')) {
            return;
        }
        var ungroupedItems = [];
        if (activeObject.child_booths) {
            var objects = activeObject.child_booths;
            if(objects.length > 0){
                if(objects[0]._cacheCanvas == undefined && objects[0] !== null)
                {
                    fabric.util.enlivenObjects(objects, function(objects) {
                        // var origRenderOnAddRemove = mainCanvas.renderOnAddRemove;
                        // mainCanvas.renderOnAddRemove = false;
                      
                        objects.forEach(function(o) {
                            o.new_created = true
                            o.left += activeObject.left
                            o.top += activeObject.top
                            // o.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
                            mainCanvas.add(o);
                        });
                      
                        // mainCanvas.renderOnAddRemove = origRenderOnAddRemove;
                        mainCanvas.renderAll();
                        
                        mainCanvas.remove(activeObject)      
                        var removeObjects = [...props.main.removeObjects, activeObject.uniq_id]
                        props.setRemoveObjects(removeObjects) 
                        var mapRemoveObjects = [...props.main.mapRemoveObjects, activeObject.toDatalessObject()]
                        
                        for(var i = 0; i < objects.length; i++){
                            for(var j = 0; j < mapRemoveObjects.length; j++){
                                if(objects[i].booth_number == mapRemoveObjects[j].booth_number){
                                    mapRemoveObjects.splice(j, 1)
                                }
                            }
                         }
                         props.setMapRemoveObjects(mapRemoveObjects)

                    });
                }
                else{
                    for(var i = 0; i < objects.length; i++){
                        var objectA  = objects[i]; 
                        objectA.modified = true
                        objectA.left += activeObject.left
                        objectA.top += activeObject.top
                        // objectA.uniq_id = Date.now() + parseInt(Math.random()*10000000000)
                        mainCanvas.add(objectA)
                    }
                    mainCanvas.renderAll();
                    mainCanvas.remove(activeObject).renderAll();
                    var removeObjects = [...props.main.removeObjects, activeObject.uniq_id]
                    props.setRemoveObjects(removeObjects)
                    var mapRemoveObjects = [...props.main.mapRemoveObjects, activeObject.toDatalessObject()]
                    for(var i = 0; i < objects.length; i++){
                        for(var j = 0; j < mapRemoveObjects.length; j++){
                            if(objects[i].booth_number == mapRemoveObjects[j].booth_number){
                                mapRemoveObjects.splice(j, 1)
                            }
                        }
                     }
                     props.setMapRemoveObjects(mapRemoveObjects)
                }
    
            }
            // if(objects){
            //     for(var i = 0; i < objects.length; i++){
            //         var objectA  = objects[i]; 
            //         mainCanvas.add(objectA)
            //     }
            // }
            // var objects = activeObject.getObjects();
            // var cnt = 0;
            // for(var i = 0; i < objects.length; i++){
            //     var objectA  = objects[i]; 
            //     ungroupedItems.push(objectA)

            //     if(objectA.get('class') != 'booth') continue;            
            //     var pathAs = objectA.getObjects('path')
            //     var obj_type = objectA.get('type')
            //     if(pathAs.length > 0){
            //         var pathA = pathAs[1];           
            //         pathA.path[1][0] = 'L';                       
            //         pathA.path[2][0] = 'L';                     
            //         pathA.path[3][0] = 'L';                       
            //         pathA.path[4][0] = 'L';
            //         cnt++;
            //     }
            //     var element = new fabric.Text((objectA.get('booth_number')?objectA.get('booth_number'):"B"), {
            //         left: 0 - objectA.width/2+Math.max((5/(zoom/convertRateX > 1? zoom: zoom*convertRateX)),1), 
            //         top:0 - objectA.height/2+Math.max((5/(zoom/convertRateX > 1? zoom: zoom*convertRateX)),1),
            //         fontSize:40/convertRateX,
            //         class:'boothName',
            //         layer: 'booth_number'
            //     });
            //     objectA.add(element);
            // }
            // var result = activeObject.toActiveSelection();

            // for(var i = 0; i < ungroupedItems.length; i++){
            //     var objectA  = ungroupedItems[i]; 
            //     var obj_type = objectA.get('type')
            //     if(objectA.get('type') =='text' || objectA.get('type') =='textbox') {
            //         mainCanvas.remove(objectA)
            //         mainCanvas.requestRenderAll();
            //     }
            // }

            // console.log("ungroup",result)
            mainCanvas.requestRenderAll();
            setUngrouppingBooth({})
            var orgName = activeObject.booth_number
            var newBoothNumber = ""
            for(var i = 0; i < objects.length; i++){
                if(objects[i].class == "booth"){
                    if(objects[i].booth_number)
                    newBoothNumber+=((newBoothNumber == ""?"":",") + objects[i].booth_number)
                }
            }
            if(!fromUndo){
                props.addLog({
                    BOOTH_KEY:activeObject.KEY_ID,
                    KEY_ID:uuidv4(),
                    action: 'uncombine',
                    date: new Date().toLocaleString(),
                    description: "uncombined "+orgName+" to "+newBoothNumber,
                    user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                })
                props.setBoothHistory({content: (mainCanvas.toDatalessObject()), action:{type:'uncombine', input:activeObject, output: objects}})
            }
        }
        else if (activeObject.get('class') == 'booth') 
        {      
            return;      
        }
    }

    
    useEffect(() => {
        if(mainCanvas){
            redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid && mainCanvas.gridSize) , mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
            props.setCanvasRefresh(true)
        }
    }, [props.main.mainLayers])

        

    // function rotateCanvasAndElements(angleChange) {
    //     var mainCanvas = mainCanvas
    //     if(mainCanvas){
    //       var objs = mainCanvas.getObjects('line');
    //       var canvasCenter = new fabric.Point(mainCanvas.getWidth()/2, mainCanvas.getHeight()/2);
    //       objs.forEach(function(obj){
    //         // var canvasCenter = new fabric.Point(obj.width/2, obj.height/2);
    //         if(obj.get('class') == 'line' && obj.get('class1')!="outline"){
    //             var objectOrigin = new fabric.Point(obj.left, obj.top);
    //             var val = angleChange-obj.get('angle');
    //             var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, val*2*Math.PI/360)
    //             obj.set('angle', angleChange);
    //             obj.top = new_loc.y;
    //             obj.left = new_loc.x;
    //         }            
    //       });
    //       mainCanvas.setZoom(mainCanvas.getZoom()*1.000001)
    //       mainCanvas.requestRenderAll();
    //     }    
    // }
    
    
     

     
    // const removeGrid = ()=>{
    //     var mainCanvas = mainCanvas
    //     if(mainCanvas){
    //       var objs = mainCanvas.getObjects('line');
    //       objs.forEach(function(obj){
    //         var objClass = obj.get('class');
    //         if(objClass == "line"){
    //           mainCanvas.remove(obj);
    //         }
    //       })
    //       mainCanvas.renderAll();
    //       mainCanvas.set('showgrid', false)
    //     }
    // }
    const tableCount = ()=>{
        var count= 0;    
        if(props.main.mainCanvas){
          props.main.mainCanvas.forEachObject(function(object) {
            if(object.class=="table"){
              count++
            }
          });
        }
        return count;
    }
    var SHOW_NAME = props.main.showSetup?.SHOW_NAME?props.main.showSetup?.SHOW_NAME:"";
    var map_name = props.main.saveFileName?props.main.saveFileName:(active_booth_file?active_booth_file.TITLE:"")
    map_name = SHOW_NAME+(map_name && map_name?(" - "):"")+(map_name?(map_name):"")
    var booth_show = props.main.showSetup?.PRODUCTS?.BOOTHS;
    var banquet_show = props.main.showSetup?.PRODUCTS?.BANQUET;
    var sponorships_show = props.main.showSetup?.PRODUCTS?.SPONSORSHIPS;
    var cart_show = false
    if(props.main.authInfo?.SHOPPING_CART  == 'show' || props.main.authInfo?.SALES?.SHOPPING_CART == 'show'){
        if(props.main.cartList?.length)
            cart_show = true
    }
    // cart_show = true
    return (
        <Box sx={{ flexGrow: 1, zIndex:2}}>
            <AppBar position="fixed" >
                <Toolbar>
                    <Box>
                        <Box style={{textAlign:'left', paddingLeft: 0, paddingBottom:3, fontSize:13, fontWeight:'bold'}}>{map_name}</Box>
                        <Box display="flex" pl={1}>
                            {props.main.boothFileList?.length > 1 &&
                            <ColorButton
                                id="dropdown-booth-import-button"                                
                                ref={boothOpenDropdownRef}
                                size="small"
                                edge="start"
                                color="success"
                                aria-label="menu"
                                aria-controls={openBoothImportModal ? 'dropdown-boot-open' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openBoothImportModal ? 'true' : undefined}
                                onMouseOver={handleClickDropdownBoothOpen}
                                onMouseLeave={handleCloseBoothImportModal}
                                style={{ zIndex: 5001 }}
                                // sx={{ mr: 2 }}
                            >
                                <FolderOpenIcon />
                            </ColorButton>
                            }
                            {/* <Tooltip title="Import">
                                <ColorButton
                                    size="Small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{openRef.current.click()}}
                                    // sx={{ mr: 2 }}
                                >
                                    <BackupIcon />
                                </ColorButton>
                            </Tooltip> */}
                            {!active_booth_file?.overview &&
                            <>
                            <Tooltip title="Save">
                                <ColorButton                                    
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{onSaveBooth()}}
                                    disabled={!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID)}
                                    // sx={{ mr: 2 }}
                                >
                                    <SaveIcon className={props.canUndo?"pulse":""}/>
                                </ColorButton>
                            </Tooltip>
                            <ColorButton
                                id="dropdown-setup-button"
                                ref = {setupDropdownRef}
                                size="small"
                                edge="start"
                                color="success"
                                aria-label="menu"
                                aria-controls={openDropdownSetup ? 'dropdown-menu-setup' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openDropdownSetup ? 'true' : undefined}
                                onMouseOver={handleClickDropdownSetup}
                                onMouseLeave={handleCloseDropdownSetup}
                                style={{ zIndex: 5001 }}
                                // sx={{ mr: 2 }}
                            >
                                <SettingsIcon />
                            </ColorButton>
                            <Menu
                                id="dropdown-menu-setup"
                                anchorEl={setupDropdownRef.current}
                                open={menuShowingDropdown === 'setup'}
                                onClose={handleCloseDropdownSetup}
                                MenuListProps={{
                                'aria-labelledby': 'dropdown-setup-button',
                                }}
                                PaperProps={{
                                    onMouseEnter: () => {
                                        handleClickDropdownSetup();
                                    },
                                    onMouseLeave: () => {
                                        handleCloseDropdownSetup();
                                    }
                                }}
                                sx={{zIndex:1005}}
                            >
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownSetup(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={()=>{setOpenNewNameModal(true)}}
                                    >
                                        <InsertDriveFileOutlinedIcon/>&nbsp;&nbsp;Blank Map
                                    </MenuButton>
                                </MenuItem>
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownSetup(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={()=>{openRef.current.click()}}
                                    >
                                        <BackupIcon/>&nbsp;&nbsp;Import
                                    </MenuButton>
                                </MenuItem>
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownSetup(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={()=>{setOpenSetup(true)}}
                                        boothFileList
                                        disabled={!boothFileList || boothFileList?.length == 0}
                                    >
                                        <SettingsIcon/>&nbsp;&nbsp;Setup
                                    </MenuButton>
                                </MenuItem>
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownSetup(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={handleOpenExportModal}
                                        disabled={!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID)}
                                    >
                                        <SaveAltIcon/>&nbsp;&nbsp;Export
                                    </MenuButton>
                                </MenuItem>
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownSetup(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={handleOpenBackgroundModal}
                                        disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    >
                                        <InsertPhotoOutlinedIcon/>&nbsp;&nbsp;Background
                                    </MenuButton>
                                </MenuItem>
                                {props.main.showSetup?.MAP_OVERVIEW == 'show' &&
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownSetup(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={handleOpenOverviewModal}
                                        disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    >
                                        <InsertPhotoOutlinedIcon/>&nbsp;&nbsp;Map Overview
                                    </MenuButton>
                                </MenuItem>
                                }
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownSetup(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={resetGridAlign}
                                        disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    >
                                        <FormatIndentIncreaseIcon/>&nbsp;&nbsp;Reset Grid Alignment
                                    </MenuButton>
                                </MenuItem>
                                
                            </Menu> 
                            <Tooltip title="Search Booth">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{props.setOpenBoothList(true); props.setLeftPage('search'); props.setOpenUnassignedProfile(false); props.setOpenCompanyProfile(false)}}
                                    // sx={{ mr: 2 }}
                                >
                                    <SearchIcon />
                                </ColorButton>
                            </Tooltip>      
                                                  
                            <Tooltip title="Undo">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={setUndoFlag} 
                                    disabled={!props.canUndo}
                                >
                                    <UndoIcon />
                                </ColorButton>
                            </Tooltip>
                            <Tooltip title="Lock">                    
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{
                                        if(props.ui.lockFlag){
                                            unlockMap(mainCanvas)
                                        }
                                        else{
                                            lockMap(mainCanvas)
                                        }
                                        props.setLockFlag(!props.ui.lockFlag)
                                    }}
                                    disabled={!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID)}
                                    // sx={{ mr: 2 }}
                                >
                                    {
                                        props.ui.lockFlag?
                                        <LockIcon />:<LockOpenIcon />
                                    }
                                </ColorButton>
                            </Tooltip>  
                            <ColorButton
                                id="dropdown-view-button"                                
                                ref = {viewDropdownRef}
                                size="small"
                                edge="start"
                                color="success"
                                aria-label="menu"
                                aria-controls={openDropdownView ? 'dropdown-menu-view' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openDropdownView ? 'true' : undefined}
                                onMouseOver={handleClickDropdownView}
                                onMouseLeave={handleCloseDropdownView}
                                style={{ zIndex: 5001 }}
                                // sx={{ mr: 2 }}
                            >
                                <span style={{fontSize:15}}>View</span>
                            </ColorButton>
                            <Menu
                                id="dropdown-menu-view"
                                anchorEl={viewDropdownRef.current}
                                open={menuShowingDropdown === 'view'}
                                onClose={handleCloseDropdownView}
                                MenuListProps={{
                                'aria-labelledby': 'dropdown-view-button',
                                }}
                                PaperProps={{
                                    onMouseEnter: () => {
                                        handleClickDropdownView();
                                    },
                                    onMouseLeave: () => {
                                        handleCloseDropdownView();
                                    }
                                }}
                                sx={{zIndex:1005}}
                            >
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownView(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={setOpenLayerModal}
                                        disabled={!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID)}
                                    >
                                        <VisibilityOffIcon/>&nbsp;&nbsp;Layer
                                    </MenuButton>
                                </MenuItem>
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownView(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        target = "_blank"
                                        href={`${props.main.showSetup?.PUBLIC?.URL_PATH?props.main.showSetup.PUBLIC.URL_PATH:''}`}
                                        // href={`/?map=${active_booth_file?active_booth_file.KEY_ID:""}&show_key=${authInfo.show_key?authInfo.show_key:''}`}
                                        disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    >
                                        <LaunchOutlinedIcon/>&nbsp;&nbsp;Public View
                                    </MenuButton>
                                </MenuItem>
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownView(false)}>
                                    {/* <Tooltip title="Booth List"> */}
                                        <MenuButton
                                            id="dropdown1-button"
                                            size="small"
                                            edge="start"
                                            color="success"
                                            aria-label="assign"
                                            onClick={handleOpenBoothListModal}
                                            disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                        >
                                            <ListIcon/>&nbsp;&nbsp;Booth Inventory
                                        </MenuButton>
                                    {/* </Tooltip> */}
                                </MenuItem>
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownView(false)}>
                                    {/* <Tooltip title="Booth List"> */}
                                        <MenuButton
                                            id="dropdown1-button"
                                            size="small"
                                            edge="start"
                                            color="success"
                                            aria-label="assign"
                                            onClick={handleOpenCoExhibitorsModal}
                                            disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                        >
                                            <AssignmentIndOutlinedIcon/>&nbsp;&nbsp;Co-Exhibitors
                                        </MenuButton>
                                    {/* </Tooltip> */}
                                </MenuItem>
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownView(false)}>     
                                    {/* <Tooltip title="Sponsor"> */}
                                        <MenuButton
                                            id="dropdown2-button"
                                            size="small"
                                            edge="start"
                                            color="success"
                                            aria-label="assign"
                                            onClick={handleOpenSponsorModal}
                                            disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                        >
                                            <AssignmentIndOutlinedIcon/>&nbsp;&nbsp;Sponsors
                                        </MenuButton>
                                    {/* </Tooltip>  */}
                                </MenuItem>  
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownView(false)}>     
                                    {/* <Tooltip title="Sponsor"> */}
                                        <MenuButton
                                            id="dropdown2-button"
                                            size="small"
                                            edge="start"
                                            color="success"
                                            aria-label="assign"
                                            onClick={()=>{
                                                props.setOpenBoothList(true);
                                                props.setLeftPage('all')
                                            }}
                                            disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                        >
                                            <BallotOutlinedIcon/>&nbsp;&nbsp;All Exhibitors
                                        </MenuButton>
                                    {/* </Tooltip>  */}
                                </MenuItem>  
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownView(false)}>     
                                    {/* <Tooltip title="Sponsor"> */}
                                        <MenuButton
                                            id="dropdown2-button"
                                            size="small"
                                            edge="start"
                                            color="success"
                                            aria-label="assign"
                                            onClick={()=>{
                                                props.setOpenBoothList(true);
                                                props.setLeftPage('boothgroup')
                                            }}
                                            disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                        >
                                            <TableViewOutlinedIcon/>&nbsp;&nbsp;Booth Groups
                                        </MenuButton>
                                    {/* </Tooltip>  */}
                                </MenuItem>  
                                {tableCount() > 0 &&
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownView(false)}>     
                                    {/* <Tooltip title="Sponsor"> */}
                                        <MenuButton
                                            id="dropdown2-button"
                                            size="small"
                                            edge="start"
                                            color="success"
                                            aria-label="assign"
                                            onClick={()=>{
                                                props.setOpenBoothList(true);
                                                props.setLeftPage('tables')
                                            }}
                                            disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                        >
                                            <TableBarOutlinedIcon/>&nbsp;&nbsp;Table Inventory
                                        </MenuButton>
                                    {/* </Tooltip>  */}
                                </MenuItem>  
                                }
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdownView(false)}>     
                                    {/* <Tooltip title="Sponsor"> */}
                                        <MenuButton
                                            id="dropdown2-button"
                                            size="small"
                                            edge="start"
                                            color="success"
                                            aria-label="assign"
                                            onClick={()=>{
                                                props.setOpenBoothList(true);
                                                props.setLeftPage('log')
                                            }}
                                            disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                        >
                                            <HistoryToggleOffIcon/>&nbsp;&nbsp;Audit Log
                                        </MenuButton>
                                    {/* </Tooltip>  */}
                                </MenuItem>  
                            </Menu>
                            {/* <Tooltip title="Layer">                    
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={setOpenLayerModal}
                                    disabled={!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID)}
                                    // sx={{ mr: 2 }}
                                >
                                    <VisibilityOffIcon />
                                </ColorButton>
                            </Tooltip> */}
                                                      
                            {/* <Tooltip title="Combine">  
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={(e)=>handleOpenGrouppingModal()}
                                    disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                >
                                    <WindowOutlinedIcon />
                                </ColorButton>
                            </Tooltip>
                            <Tooltip title="Uncombine">  
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{setOpenUnGrouppingModal(true)}}
                                    disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                >
                                    <GridViewOutlinedIcon />
                                </ColorButton>
                            </Tooltip> */}
                            <input type="file" ref={openRef} style={{display:"none"}} onChange={(e)=>onChangeFile(e)} accept=".dwg,.dxf"/>
                            {/* <Tooltip title="Export">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={handleOpenExportModal}
                                    disabled={!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID)}
                                >
                                    <SaveAltIcon/>
                                </ColorButton>
                            </Tooltip>     */}
                            {/* <Tooltip title="Background">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={handleOpenBackgroundModal}
                                    disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    // disabled = {!active_booth_file}
                                >
                                    <InsertPhotoOutlinedIcon/>
                                </ColorButton>
                            </Tooltip>     */}
                               
                            <Tooltip title="Assign">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="assign"
                                    onClick={handleOpenAttachModal}
                                    disabled={!enableAttach || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                >
                                    <span style={{fontSize:15}}>Assign</span>
                                </ColorButton>
                            </Tooltip>    
                            {/* <Tooltip title="Booth setup">
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="assign"
                                    onClick={handleOpenPriceModal}
                                    disabled={!(searchedBooths.length>0)}
                                >
                                    <MonetizationOnOutlinedIcon/>
                                </ColorButton>
                            </Tooltip>       */}
                                {/* <ColorButton
                                    id="dropdown1-button"
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="assign"
                                    onClick={handleOpenBoothListModal}
                                    disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                >
                                    <ListIcon/>
                                </ColorButton> */}
                            <ColorButton
                                id="dropdown1-button"
                                ref={drawDropdownRef}
                                size="small"
                                edge="start"
                                color="success"
                                aria-label="assign"
                                aria-controls={openDropdown1 ? 'dropdown-menu1' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openDropdown1 ? 'true' : undefined}
                                onMouseOver={handleClickDropdown1}
                                onMouseLeave={handleCloseDropdown1}
                                style={{ zIndex: 5001 }}
                                disabled={!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID)}
                            >
                                <span style={{fontSize:15, pading: '0 10px'}}>Insert</span>
                            </ColorButton>
                            <Menu
                                id="dropdown-menu1"
                                anchorEl={drawDropdownRef.current}
                                // anchorOrigin={{
                                //     vertical: "bottom",
                                //     horizontal: "center"
                                // }}
                                // transformOrigin={{
                                //     vertical: "top",
                                //     horizontal: "center"
                                // }}
                                open={menuShowingDropdown === 'draw'}
                                onClose={handleCloseDropdown1}
                                MenuListProps={{
                                'aria-labelledby': 'dropdown1-button',
                                }}
                                PaperProps={{
                                    onMouseEnter: () => {
                                        handleClickDropdown1();
                                    },
                                    onMouseLeave: () => {
                                        handleCloseDropdown1();
                                    }
                                }}
                                sx={{zIndex:1005}}
                            >
                                {booth_show &&
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdown1(false)}>
                                    <MenuButton
                                        id="dropdown1-button"
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={()=>{setOpenElementBoothBox(true)}}
                                        disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    >
                                        Booths
                                    </MenuButton>
                                </MenuItem>
                                }
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdown1(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={()=>{handleOpenDraw()}}
                                        disabled={props.ui.lockFlag }
                                    >
                                        Draw
                                    </MenuButton>  
                                    
                                </MenuItem>
                                {banquet_show && 
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdown1(false)}>
                                    <MenuButton
                                        id="dropdown1-button"
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={()=>{setOpenElementTableBox(true)}}
                                        disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    >
                                        Tables
                                    </MenuButton>
                                </MenuItem>
                                }
                                
                                {sponorships_show &&
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdown1(false)}>
                                    <MenuButton
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={()=>{handleOpenSponsorship()}}
                                        disabled={props.ui.lockFlag }
                                    >
                                        Sponsorships
                                    </MenuButton>  
                                    
                                </MenuItem>
                                }
                                <MenuItem sx={{padding:0}} onClick={()=>handleCloseDropdown1(false)}>
                                    <MenuButton
                                        id="dropdown1-button"
                                        size="small"
                                        edge="start"
                                        color="success"
                                        aria-label="assign"
                                        onClick={()=>{setOpenElementSymbolBox(true); toggleDrawMode(props.main.mainCanvas, "draw")}}
                                        disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    >
                                        Symbols
                                    </MenuButton>
                                </MenuItem>
                            </Menu> 
                            </>
                            }
                            {/* <Tooltip title="Reset Grid Alignment">                    
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={resetGridAlign}
                                    disabled={props.ui.lockFlag || (!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    // sx={{ mr: 2 }}
                                >
                                    <FormatIndentIncreaseIcon />
                                </ColorButton>
                            </Tooltip> */}
                            {/* <Tooltip title="Pan">                    
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{
                                        if(!props.ui.panFlag){
                                            mainCanvas.defaultCursor = 'grab';
                                            mainCanvas.hoverCursor= 'grab';
                                        }
                                        else{
                                            mainCanvas.defaultCursor = 'default';
                                            mainCanvas.hoverCursor= 'pointer';
                                        }
                                        mainCanvas.renderAll();

                                        props.setPanFlag(!props.ui.panFlag)
                                    }}
                                    disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    // sx={{ mr: 2 }}
                                >
                                    {
                                        props.ui.panFlag?
                                        <PanToolIcon />:<PanToolOutlinedIcon />
                                    }
                                </ColorButton>
                            </Tooltip> */}
                            <Tooltip title="Auto Fit on Screen">                    
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{ 
                                        if(!active_booth_file?.overview)
                                            props.centerFocus() 
                                        else
                                            swichFullOverView(props.main.overviewObject)
                                    }}
                                    disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    // sx={{ mr: 2 }}
                                >
                                    <CenterFocusWeakIcon/>
                                </ColorButton>
                            </Tooltip>
                            {/* <Paper
                                style={{marginLeft:20, marginRight:20}}
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 200 }}
                                >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search Booth"
                                    inputProps={{ 'aria-label': 'search booth' }}
                                    value={searchVal}
                                    onChange={
                                        (e) => {
                                            setSearchVal(e.target.value)
                                        }
                                    }
                                    onKeyDown={
                                        (e)=>{
                                            if(e.keyCode == 13){
                                                e.preventDefault();
                                                doSearch()
                                            }
                                        }
                                    }
                                />
                                <IconButton size="small" type="button" onClick = {doSearch} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper> */}
                            {authInfo.show_three_icon =='show' && !active_booth_file?.overview && !active_booth_file?.overview &&
                            <Tooltip title="3D View"> 
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{navigate(`/admin/three?show_key=${query.get('show_key')?query.get('show_key'):''}${query.get('sales_map')?"&sales_map=1":""}`); }}
                                    disabled={!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID)}
                                    // sx={{ mr: 2 }}
                                >
                                    <ThreeDRotationIcon/>
                                </ColorButton>
                            </Tooltip>
                            }
                            {/* <Tooltip title="Public View">                    
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"               
                                    disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    target="_blink"
                                    href={`/?map=${active_booth_file?active_booth_file._id:""}&token=${getTokenCookie()?getTokenCookie():''}`}
                                    // onClick={()=>{ navigate('/admin/three'); }}
                                    // sx={{ mr: 2 }}
                                >
                                    <LaunchOutlinedIcon/>
                                </ColorButton>
                            </Tooltip> */}
                            {/* <Tooltip title="Export to JSON">                    
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"             
                                    disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    onClick={()=>{ exportJson() }}
                                    // sx={{ mr: 2 }}
                                >
                                    <GetAppIcon/>
                                </ColorButton>
                            </Tooltip> */}
                            <Tooltip title="Legend">                    
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"             
                                    disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    onClick={()=>{
                                        props.setOpenLegend(true)
                                    }}
                                    // sx={{ mr: 2 }}
                                >
                                    <SvgIcon  component={LegendIcon} inheritViewBox />
                                    {/* <PriorityHighIcon/> */}
                                </ColorButton>
                            </Tooltip>
                            {active_booth_file?.KEY_ID && !active_booth_file?.overview &&
                            <>
                            <FormControlLabel
                                value="start"
                                control={<Switch size="small" color="default" checked={active_booth_file?.snapToGrid} 
                                onChange={(event)=>{
                                    mainCanvas.set({
                                        snapToGrid:event.target.checked
                                    })
                                    props.setActiveBoothFile(
                                        {...active_booth_file,
                                            snapToGrid: event.target.checked
                                        }
                                    )
                                    var settingData = {}
                                    if(!settingData.CANVAS){
                                        settingData.CANVAS = {}
                                        settingData.CANVAS.MAP_KEY = active_booth_file.KEY_ID
                                    }
                                    settingData.CANVAS.GRID_SNAP = event.target.checked?1:0
                                    onSaveSetting(settingData)
                                }}/>}
                                label={<span style={{fontSize:'14px'}}>Grid Snap</span>}
                                labelPlacement="start"
                            />
                            {active_booth_file?.KEY_ID && 
                            <FormControlLabel
                                value="start"
                                control={<Switch size="small" color="default" checked={props.ui.mouseHoverEnable}
                                onChange={(event)=>{
                                    props.setMouseHoverEnable(event.target.checked)
                                    props.setOpenCompanyProfile(false)
                                    props.setOpenSponsorshipProfile(false)
                                    var settingData = {}
                                    if(!settingData.CANVAS){
                                        settingData.CANVAS = {}
                                        settingData.CANVAS.MAP_KEY = active_booth_file.KEY_ID
                                    }
                                    settingData.CANVAS.HOVER_ENABLE = event.target.checked?1:0
                                    onSaveSetting(settingData)
                                }}/>}
                                label={<span style={{fontSize:'14px'}}>Profile Display</span>}
                                labelPlacement="start"
                            />
                            }
                            {
                                cart_show&&
                                <ColorButton
                                    style={{marginLeft:'20px'}}
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{ 
                                        props.setOpenBoothList(true);
                                        props.setLeftPage('sponsor_cart') 
                                    }}
                                    disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    // sx={{ mr: 2 }}
                                >
                                    Cart ({Math.max(props.main.cartList?.length-2,0)})
                                </ColorButton>
                            }
                            </>
                            }

                            {/* <Tooltip title="Test">                    
                                <ColorButton
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"             
                                    disabled={(!active_booth_file || (active_booth_file && !active_booth_file.KEY_ID))}
                                    onClick={()=>{ onSaveBooth1() }}
                                    // sx={{ mr: 2 }}
                                >
                                    T
                                </ColorButton>
                            </Tooltip> */}
                            
                        </Box>
                        {/* <Box display="flex">      
                            <Tooltip title="">  
                                <Button3D
                                    size="small"
                                    edge="start"
                                    color="success"
                                    aria-label="menu"
                                    onClick={()=>{ navigate('/admin/three');}}
                                >
                                    3D
                                </Button3D>
                            </Tooltip>
                        </Box> */}
                        {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            News
                        </Typography>
                        <Button color="inherit">Login</Button> */}
                        
                        {(active_booth_file && active_booth_file.KEY_ID) &&
                            <DrawTool openDraw={openDraw} handleOpenDraw={handleOpenDraw} handleCloseDraw={handleCloseDraw}/>
                        }
                        {(active_booth_file && active_booth_file.KEY_ID) &&
                            <SponsorTool openDraw={openSponsorship} handleOpenDraw={handleOpenSponsorship} handleCloseDraw={handleCloseSponsorship}/>
                        }
                    </Box>    
                        
                </Toolbar>
            </AppBar>
            {openLayerModal&&
            <LayerModal
                open={openLayerModal}
                onClose={handleCloseLayerModal}
            />
            }
            {boothFileList && boothFileList?.length > 0 &&
            <BoothImportModal
                anchorEl = {boothOpenDropdownRef.current}
                open={menuShowingDropdown==='boothopen'}
                onOpen={handleClickDropdownBoothOpen}
                onClose={handleCloseBoothImportModal}
            />
            }
            {openNewNameModal&&
            <FilenameModal
                open={openNewNameModal}
                onClose={()=>setOpenNewNameModal(false)}
                onOk={()=>{setOpenNewNameModal(false); props.createNewMap();}}
                onChange = {(e)=>props.setSaveFileName(e.target.value)}
                title={"New Map Name"}
            />
            }
            {openSetup&&
            <SetupModal
                open={openSetup}
                onClose={handleCloseSetup}
                onOk={confirmSetup}                
                booths = {searchedBooths}
                boothTexts = {searchedBoothTexts}
                handleSetBoothGroup = {handleSetBoothGroup}
                redrawRulers = {props.redrawRulers}
                firstMigrate = {firstMigrate}
            />
            }
            {openAttachModal&&
            <AttachModal
                open={openAttachModal}
                onClose={handleCloseAttachModal}
                onOk={handleOkAttachModal}
                boothObjects = {searchedBooths}
                boothTexts = {searchedBoothTexts}
                grouppingBooth = {grouppingBooth}
                groupItems = {(val)=>groupItems(val, false, false, false)}
                setGrouppingBooth={setGrouppingBooth}
                onSaveIndividuals = {onSaveIndividuals}
            />
            }
            {openPriceModal&&
            <PriceModal
                open={openPriceModal}
                onClose={handleClosePriceModal}
                onOk={handleOkPriceModal}
                booths = {searchedBooths}
                boothTexts = {searchedBoothTexts}
                handleSetBoothGroup = {handleSetBoothGroup}
            />
            }
            {openBoothListeModal&&
            <BoothListModal
                open={openBoothListeModal}
                onClose={handleCloseBoothListModal}
                onOk={handleOkBoothListModal}
            />
            }
            {openCoExhibitorsModal&&
            <CoExhibitors
                open={openCoExhibitorsModal}
                onClose={handleCloseCoExhibitorsModal}
                onOk={handleOkCoExhibitorsModal}
            />
            }
            {openSponsorModal&&
            <SponsorModal
                open={openSponsorModal}
                onClose={handleCloseSponsorModal}
                onOk={handleOkSponsorModal}
                booths = {searchedBooths}
                // boothTexts = {searchedBoothTexts}
                // handleSetBoothGroup = {handleSetBoothGroup}
            />
            }
            {openBackgroundmodal&&
            <BackgroundModal
                open={openBackgroundmodal}
                onClose={handleCloseBackgroundModal}
                onOk={handleOkBackgroundModal}
            />
            }
            {openOverviewmodal&&
            <OverviewModal
                open={openOverviewmodal}
                onClose={handleCloseOverviewModal}
                onOk={handleOkOverviewModal}
            />
            }
            {props.ui.openImportModal&&
            <ImportModal
                open={props.ui.openImportModal}
                onClose={handleCloseImportModal}
                onOk={handleOkImportModal}
            />
            }
            {openGrouppingModal&&
            <GrouppingModal
                boothObjects = {searchedBooths}
                boothTexts = {searchedBoothTexts}
                grouppingBooth = {grouppingBooth}
                open={openGrouppingModal}
                onClose={
                    ()=>{
                        handleCloseGrouppingModal();
                    }
                }
                onOk={
                    ()=>{
                        var soldCombined = true;
                        for(var i = 0; i < searchedBooths.length; i++){
                            if(!searchedBooths[i].sold){
                                soldCombined = false;
                            }
                        }
                        g_groupedItem = groupItems(grouppingBooth, false, false, !soldCombined)
                        if(soldCombined){
                            onSaveIndividuals([g_groupedItem], null, searchedBooths, "COMBINE", false)
                        }
                    }
                }
                setGrouppingBooth={setGrouppingBooth}
            />
            }
            {openUnGrouppingModal&&
            <UnGrouppingModal
                open={openUnGrouppingModal}
                onClose={handleCloseUnGrouppingModal}
                onOk={()=>{unGroupItems(false); handleCloseUnGrouppingModal()}}
                ungroupCnt={ungroupCnt}
                ungrouppingBooth={ungrouppingBooth}
                setUngrouppingBooth={setUngrouppingBooth}
            />
            }
            {openExportModal&&
            <ExportFile
                open={openExportModal}
                onClose={handleCloseExportModal}
                mainSvg={exportSvg}
            />
            }
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={snackMsg}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
            <RightMenu
                handleOpenGrouppingModal = {handleOpenGrouppingModal}
                handleOpenAttachModal = {handleOpenAttachModal}
                unGroupItems = {()=>unGroupItems(false)}
                setOpenUnGrouppingModal = {setOpenUnGrouppingModal}
                setOpenBoothGroupModal = {setOpenBoothGroupModal}
                onSaveIndividuals = {onSaveIndividuals}
            />

            {props.ui.openSetBoothModal&&
            <SetBoothModal
                open={props.ui.openSetBoothModal}
                onClose={handleCloseSetBoothModal}
                onOk={handleOkSetBoothModal}
                groupOptions = {groupOptions}
            />
            }
            {props.ui.openSetTableModal&&
            <SetTableModal
                open={props.ui.openSetTableModal}
                onClose={handleCloseSetTableModal}
                onOk={handleOkSetTableModal}
                groupOptions = {groupOptions}
            />
            }
            {openElementTableBox &&
                <ElementTableBox
                    open = {openElementTableBox}
                    onClose={()=>{setOpenElementTableBox(false)}}
                    onOk={()=>{setOpenElementTableBox(false)}}
                />
            }
            {openElementBoothBox &&
                <ElementBoothBox
                    open = {openElementBoothBox}
                    onClose={()=>{setOpenElementBoothBox(false)}}
                    onOk={()=>{setOpenElementBoothBox(false)}}
                />
            }
            {openElementSymbolBox &&
                <ElementSymbolBox
                    open = {openElementSymbolBox}
                    onClose={()=>{setOpenElementSymbolBox(false);  toggleDrawMode(props.main.mainCanvas, "general")}}
                    onOk={()=>{setOpenElementSymbolBox(false); toggleDrawMode(props.main.mainCanvas, "general")}}
                />
            }
            {openElementStructureBox &&
                <ElementStructureBox
                    open = {openElementStructureBox}
                    onClose={()=>{setOpenElementStructureBox(false)}}
                    onOk={()=>{setOpenElementStructureBox(false)}}
                />
            }
            {openBoothGroupModal&&
                <SetBoothGroupModal
                    open = {openBoothGroupModal}
                    onClose={()=>{setOpenBoothGroupModal(false)}}
                    onOk={()=>{
                        props.setBoothHistory({content: (props.main.mainCanvas.toDatalessObject())})
                        setOpenBoothGroupModal(false)}
                    }
                />
            }
            {props.ui.openCompanyModal&&
            <CompanyEditModal
                open={props.ui.openCompanyModal}
                onClose={()=>{props.setOpenCompanyModal(false)}}
                onOk={()=>{props.setOpenCompanyModal(false)}}
                boothItem={props.ui.editCompany}
            />
          }
        </Box>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
      log: state.log,
      booth_history: state.booth_history.present,
      canUndo: state.booth_history.past.length > 1,
      canRedo: state.booth_history.future.length > 0
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {    
        setViewData: data=>dispatch(setViewData(data)),
        setViewFile: filename=>dispatch(setViewFile(filename)),
        setBoothList: data=>dispatch(setBoothList(data)),
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        onUndo: () => dispatch(UndoActionCreators.undo()),
        onRedo: () => dispatch(UndoActionCreators.redo()),
        onClearHistory: () => dispatch(UndoActionCreators.clearHistory()),        
        setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),      
        setLoading:  data=>dispatch(setLoading(data)),
        setPanFlag:  data=>dispatch(setPanFlag(data)),
        setLockFlag:  data=>dispatch(setLockFlag(data)),
        resetUI:  data=>dispatch(resetUI(data)),
        resetMain:  data=>dispatch(resetMain(data)),
        setOpenFilenameModal:  data=>dispatch(setOpenFilenameModal(data)),
        setOpenSetBoothModal:  data=>dispatch(setOpenSetBoothModal(data)),
        setLog:  data=>dispatch(setLog(data)),
        addLog:  data=>dispatch(addLog(data)),
        setSaveFileName:  data=>dispatch(setSaveFileName(data)),
        setRemoveObjects:  data=>dispatch(setRemoveObjects(data)),
        setMapRemoveObjects:  data=>dispatch(setMapRemoveObjects(data)),
        setFirstMapContent:  data=>dispatch(setFirstMapContent(data)),
        setFirstBgContent:  data=>dispatch(setFirstBgContent(data)),
        setOpenAssignmentModal:  data=>dispatch(setOpenAssignmentModal(data)),
        setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
        setLeftPage: value=>dispatch(setLeftPage(value)) ,
        setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
        setOpenSponsorshipProfile:  data=>dispatch(setOpenSponsorshipProfile(data)),
        setOpenUnassignedProfile:  data=>dispatch(setOpenUnassignedProfile(data)),
        handleGlobalCloseSnack:  data=>dispatch(handleGlobalCloseSnack(data)),
        setOpenImportmodal:  data=>dispatch(setOpenImportmodal(data)),
        setOpenCompanyModal:  data=>dispatch(setOpenCompanyModal(data)),
        setEditCompany:  data=>dispatch(setEditCompany(data)),
        setMouseHoverEnable:  data=>dispatch(setMouseHoverEnable(data))
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(ToolBox);

  



