import * as React from 'react';
import { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { setOpenCompanyProfile, setOpenBoothList, setLeftPage } from '../../actions/ui'
import { grey } from '@mui/material/colors';
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import HomePanel from './AppLeftPages/Home';
import SearchPanel from  './AppLeftPages/Search';
import Bookmarks from './AppLeftPages/Bookmarks';
import Booth3D from './AppLeftPages/Booth3D';
import Features from './AppLeftPages/Features';
import All from './AppLeftPages/All';
import Searched from './AppLeftPages/Searched';
import Category from './AppLeftPages/Category';
import CategoryList from './AppLeftPages/CategoryList';
import BoothList from './AppLeftPages/BoothList';
import BoothInventory from './AppLeftPages/BoothInventory';
import TableList from './AppLeftPages/TableList';
import BoothDeletedList from './AppLeftPages/BoothDeletedList';
import BoothLogList from './AppLeftPages/BoothLogList';
import CartList  from './AppLeftPages/CartList';
import SponsorCartList  from './AppLeftPages/SponsorCartList';
import BoothGroupList from './AppLeftPages/BoothGroupList'
import { getTokenCookie, getTokenPlanner, getUrlToken } from '../../utils/Common'
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import { Global } from '@emotion/react';
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import HomeIcon from '@mui/icons-material/HomeOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ThreeDRotationOutlinedIcon from '@mui/icons-material/ThreeDRotationOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { setOpenLogin, setOpenSignup, setOpenResetPassword, setSnackMsg, setOpenSnack,setBookmardUpdated } from '../../actions/ui'
import { setBookmarkList, setExhibitorList } from '../../actions/main'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';

const drawerWidth = Math.min(Math.max(440, Math.round(window.innerWidth*0.33)), window.innerWidth);
var g_props

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const drawerBleeding = 32;
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  cursor:'pointer'
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  flexShrink: 0,
  overflowX: 'hidden',
  overflowY:g_props?.ui?.leftPage=="search" || g_props?.ui?.leftPage=="bookmarks" || g_props?.ui?.leftPage=="three" || g_props?.ui?.leftPage=="features" || g_props?.ui?.leftPage=="all" || g_props?.ui?.leftPage=="searched" || g_props?.ui?.leftPage=="booths"?'hidden':'auto',
  [theme.breakpoints.down('md')]: {
    width: `100%`,
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  overflowY:g_props?.ui?.leftPage=="search" || g_props?.ui?.leftPage=="bookmarks" || g_props?.ui?.leftPage=="three" || g_props?.ui?.leftPage=="features" || g_props?.ui?.leftPage=="all" || g_props?.ui?.leftPage=="searched" || g_props?.ui?.leftPage=="booths"?'hidden':'auto',
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      backgroundColor:'inherit'
    },
    [theme.breakpoints.up('md')]: {
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      })
    },
    [theme.breakpoints.down('md')]: {
      width: `100%`,
    },
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function AppLeftPanel(props) {
  const [open, setOpen] = React.useState(false);  
  const [zIndex, setzIndex] = React.useState(-1);
  const [exhibitorCnt, setExhibitorCnt] = React.useState(0);  
  const [exhibitorFeaturedCnt, setExhibitorFeaturedCnt] = React.useState(0);  
  const [bookmarkCnt, setBookmarkCnt] = React.useState(0);  
  const [anchor, setAnchor] = React.useState('left');  
  const theme = useTheme();
  let query = useQuery();
  const useStyles = makeStyles((theme) => ({
    navItem: {
      color: props.ui?.customStyles?.['left-drawer-icon-color']?(props.ui?.customStyles['left-drawer-icon-color']+"!important"):'rgba(0, 0, 0, 0.54)',
      "&:hover": {
        color: props.ui?.customStyles?.['left-drawer-icon-color']?(props.ui?.customStyles['left-drawer-icon-color']+"!important"):'rgba(0, 0, 0, 0.54)',
      },
      "&.active": {
        color: props.ui?.customStyles?.['left-drawer-icon-color']?(props.ui?.customStyles['left-drawer-icon-color']+"!important"):'rgba(0, 0, 0, 0.54)',
      },
    },
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();
  const fetchBookmarkList = ()=>{
    {
      var token = false
      try{
        token =  getTokenPlanner();
        if(props.role=='admin'){
          token = getTokenCookie()
        }
      }
      catch(e){

      }
      if(token){
        var url = `${ESHOW_URL()}?method=getPlanner&token=${token}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getPlanner&token=${token}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            if(result.SUCCESS){
              props.setBookmarkList(result.BOOKMARKS)
            }
            else{

            }
        }).catch(error => {
        })  
      }
    }
  }

  const getExhibitorList = ()=>{
    var active_booth_file = props.main.active_booth_file
    var token = getTokenCookie();  
    var map_key;   
    if(props.main.authInfo?.role != 'admin') {
      try{     
          var _token = getTokenPlanner();
          if(_token){
            token = _token
          }
      }
      catch(e){
        console.log("token parse", e)
      }
    }
    map_key = active_booth_file?.KEY_ID
    if(query.get("map")){
      map_key = query.get("map");
    }
    map_key=false
    var url = `${ESHOW_URL()}?method=getExhibitorList&token=${token}${map_key?('&map_key='+map_key):''}`
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            props.setExhibitorList(result)
            if(result.EXHIBITORS){
              setExhibitorCnt(result.EXHIBITORS.length)
              var bookmarkList = result.EXHIBITORS.filter((EXHIBITOR)=>{
                return EXHIBITOR.BOOKMARKED
              }) 
              var featuredList = result.EXHIBITORS.filter((EXHIBITOR)=>{
                return EXHIBITOR.FEATURED === true
              }) 
              setExhibitorFeaturedCnt(featuredList?featuredList.length:0)
              setBookmarkCnt(bookmarkList.length)
            }
        }
    }).catch(error => {
    })  
  }

  const handleDrawerOpen = () => {
    props.setOpenBoothList(true);
  };
  const handleDrawerClose = () => {
    props.setOpenBoothList(false);
  };
  const handleDrawerToggle = () => {
    props.setOpenBoothList(!props.ui.openBoothList);
  };
  useEffect(() => {
    g_props = props
  }, [props])
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var show_key = urlParams.get('show_key')  
  var urlToken = getUrlToken()   
  useEffect(() => {
    if(props.ui.openBoothList && props.main.authInfo.authenticated == 3){
      setzIndex(1004)
      if(props.ui.leftPage == "home"){
        getExhibitorList();
      }
      // if(props.role=='admin'){
      //   fetchBookmarkList();
      // }
      // else{
      //   if(props.main[`planner-${urlToken}`]){
      //     fetchBookmarkList();
      //   }
      // }
    }
    else{
      setTimeout(()=>{
        props.setLeftPage('home')
        setzIndex(-1)
      }, 100)
    }
  }, [props.ui.openBoothList])

  useEffect(() => {    
    if(props.main[`planner-${urlToken}`]){
      fetchBookmarkList();
    }
  }, [props.main[`planner-${urlToken}`]])

  useEffect(() => {
    if(props.ui.openBoothList && props.ui.leftPage == "home" && props.main.authInfo.authenticated == 3){
      getExhibitorList();
    }
  }, [props.ui.leftPage])

  useEffect(()=>{
    if(props.role!='admin' && props.ui.leftPage && props.ui.leftPage!= 'home' && props.main.authInfo.authenticated == 3){
      props.setOpenBoothList(true)
    }
  },[props.ui.leftPage])
  
  useEffect(() => {
    if(props.ui.openBoothList && props.ui.leftPage == "home" && props.ui.openBoothList && props.main.authInfo.authenticated == 3)
      getExhibitorList();
  }, [query, props.main.authInfo.authenticated])

  const handleResize = () => {
    if(window.innerWidth <= 900 && props.main.authInfo?.SALES?.MAP_TYPE != "sales"){
      setAnchor('bottom')
    }
    else{
      setAnchor('left')
    }
  }
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize, false);
  }, []);

  // useEffect(() => {
  //   if(props.main.mainCanvas && props.main.boothLoaded)
  //     fetchBookmarkList();
  // }, [props.main.mainCanvas, props.main.boothLoaded])

  var sales_left_drawer_show = true
  if(props.main.authInfo && props.main.authInfo.SALES){
    if(props.main.authInfo.SALES.LEFT_DRAWER == 'hide'){
      sales_left_drawer_show = false
    }
  }
  var sales_shopping_cart_show = false
  var sales_booth_list_show = true
  var sales_3d_booths_show = true
  var sales_featured_exhibitors_show = true
  var sales_bookmarks_show = true
  var category_show = true
  var cart_show = false
  if(props.main.authInfo?.SHOPPING_CART  == 'show' || props.main.authInfo?.SALES?.SHOPPING_CART == 'show'){
      if(props.main.cartList?.length)
          cart_show = true
  }
  // cart_show = true

  if(1){
    if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'hide'){
      sales_shopping_cart_show = false
    }
    else if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'show'){
      sales_shopping_cart_show = true
    }
    if(props.main.authInfo.SALES?.BOOTH_LIST == 'hide'){
      sales_booth_list_show = false
    }
    if(props.main.authInfo.SALES?.['3D_BOOTHS'] == 'hide'){
      sales_3d_booths_show = false
    }
    if(props.main.authInfo.SALES?.FEATURED_EXHIBITORS == 'hide'){
      sales_featured_exhibitors_show = false
    }
    if(props.main.showSetup?.PUBLIC?.FEATURED_EXHIBITORS == 'hide'){
      sales_featured_exhibitors_show = false
    }
    if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
      sales_bookmarks_show = false
    }
    if(props.main.authInfo.SALES?.CATEGORY == 'hide'){
      category_show = false
    }
    if(props.main.showSetup?.PUBLIC?.PRODUCT_CATEGORIES == 'hide'){
      category_show = false
    }
  }

  var categoryCnt = 0;
  if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
    // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
    categoryCnt = props.main.categoryOptions.length
  }

  // console.log("anchor", anchor)
  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <>
    {sales_left_drawer_show&&
      <>
      {props.role=='admin'?
      
        <Box className={`sideview company-profile-viewer leftpanel xhbt-company-profile-viewer`} sx={{ display: 'flex', zIndex:zIndex}}>
            
        {props.ui.openBoothList &&
          <MuiDrawer
            sx={{
              width: drawerWidth,
              overflowX:'hidden',
              overflowY:props.ui.leftPage=="search" || props.ui.leftPage=="bookmarks" || props.ui.leftPage=="three" || props.ui.leftPage=="features" || props.ui.leftPage=="all" || props.ui.leftPage=="searched" || props.ui.leftPage=="booths"?'hidden':'auto',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                background: props.ui?.customStyles?.['left-bg-color']?props.ui?.customStyles['left-bg-color']:'white',
                width: drawerWidth,
                boxSizing: 'border-box',
                overflowX:'hidden',
                overflowY:props.ui.leftPage=="search" || props.ui.leftPage=="bookmarks" || props.ui.leftPage=="three" || props.ui.leftPage=="features" || props.ui.leftPage=="all" || props.ui.leftPage=="searched"|| props.ui.leftPage=="booths"?'hidden':'auto',
              },
            }}
            variant="persistent"
            anchor="left"
            // open={props.role=='admin'?props.ui.openBoothList:true}
            open={props.ui.openBoothList}
            className="booth-list-slider xhbt-MuiDrawer-root"
          >
            {/* {props.role=='admin' ?
            <></>
            :
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(props.ui.openBoothList && { display: 'none' }) }}
              className="menu-btn"
            >
              <MenuIcon style={{display:'block', margin:'auto', fontSize: 26}}/>
              <label style={{display:'block', fontSize:15, padding: "0px 3px 1px"}}>Menu</label>
            </IconButton>
            } */}
            <div className='preview-image' style={{width: '100%', height:'auto', maxHeight: '63px', margin:0}}>
            {/* {props.main.mainCanvas && props.main.mainCanvas.boothfile_brand &&
              <img style={{ width: "100%" }} src={props.main.mainCanvas.boothfile_brand}  />
            } */}
            </div>
            {props.main.authInfo.authenticated == 3 && sales_left_drawer_show && props.main.mainCanvas &&
            <Box>
              <Box>
                {
                  props.ui.leftPage == 'home'&&
                  <HomePanel role={props.role} exhibitorCnt={exhibitorCnt} exhibitorFeaturedCnt={exhibitorFeaturedCnt} bookmarkCnt={props.main.bookmarkList?.length} open={props.ui.leftPage == 'home'} openDraw={props.ui.openBoothList}/>
                }
                {
                  props.ui.leftPage == 'search'&&
                  <SearchPanel role={props.role}/>
                }
                {
                  props.ui.leftPage == 'bookmarks'&&
                  <Bookmarks role={props.role} bookmarkCnt={props.main.bookmarkList?.length}/>
                }
                {
                  props.ui.leftPage == 'three'&&
                  <Booth3D role={props.role}/>
                }
                {
                  props.ui.leftPage == 'features'&&
                  <Features role={props.role}/>
                }
                {
                  props.ui.leftPage == 'all'&&
                  <All role={props.role}/>
                }
                {
                  props.ui.leftPage == 'searched'&&
                  <Searched role={props.role}/>
                }
                {
                  props.ui.leftPage == 'booths'&&
                  <BoothList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'inventory'&&
                  <BoothInventory role={props.role}/>
                }
                {
                  props.ui.leftPage == 'tables'&&
                  <TableList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'deletedbooths'&&
                  <BoothDeletedList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'log'&&
                  <BoothLogList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'categorylist'&&
                  <CategoryList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'category'&&
                  <Category role={props.role}/>
                }
                {
                  props.ui.leftPage == 'cart'&&
                  <CartList role={props.role} open = {props.ui.leftPage == 'cart'}/>
                }
                {
                  props.ui.leftPage == 'sponsor_cart'&&
                  <SponsorCartList role={props.role} open = {props.ui.leftPage == 'sponsor_cart'}/>
                }
                {
                  props.ui.leftPage == 'boothgroup'&&
                  <BoothGroupList role={props.role} open = {props.ui.leftPage == 'boothgroup'}/>
                }         
              </Box>
            </Box>
            }
          </MuiDrawer>
          }
        </Box>:
        <Box className={`company-profile-viewer leftpanel xhbt-company-profile-viewer`} sx={{ display: 'flex', paddingTop:'0' }}>
            {anchor == 'bottom'?
            <SwipeableDrawer
              // sx={{
              //   width: drawerWidth,
              //   overflowX:'hidden',
              //   flexShrink: 0,
              //   '& .MuiDrawer-paper': {
              //     width: drawerWidth,
              //     boxSizing: 'border-box',
              //     overflowX:'hidden',
              //   },
              // }}
              sx={{
                '& .MuiDrawer-paper': {
                  background: props.ui?.customStyles?.['left-bg-color']?props.ui?.customStyles['left-bg-color']:'white',
                  height: `calc(50% - ${drawerBleeding}px)`,
                  overflow: 'visible',
                  top:'unset'
                },
                '& .MuiBackdrop-root': {
                  top:'unset'
                },
                top:'unset'
              }}
              container={container}
              swipeAreaWidth={drawerBleeding}
              props={props}
              variant={anchor=='left'?"permanent":''}
              anchor={anchor}
              // open={props.role=='admin'?props.ui.openBoothList:true}
              open={props.ui.openBoothList}
              onOpen={handleDrawerOpen}
              onClose={handleDrawerClose}
              disableSwipeToOpen={false}
              ModalProps={{
                keepMounted: true,
              }}
              className='xhbt-MuiDrawer-root'
              // open={open}
              // className="booth-list-slider"
            >
              {/* {props.role=='admin' ?
              <></>
              :
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(props.ui.openBoothList && { display: 'none' }) }}
                className="menu-btn"
              >
                <MenuIcon style={{display:'block', margin:'auto', fontSize: 26}}/>
                <label style={{display:'block', fontSize:15, padding: "0px 3px 1px"}}>Menu</label>
              </IconButton>
              } */}
              {/* <div className='preview-image' style={{width: '100%', height:'auto', maxHeight: '63px', margin:0}}>
              {props.main.mainCanvas && props.main.mainCanvas.boothfile_brand &&
                <img style={{ width: "100%" }} src={props.main.mainCanvas.boothfile_brand}  />
              }
              </div> */}
              <StyledBox
                sx={{
                  position: 'absolute',
                  top: -drawerBleeding,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  visibility: 'visible',
                  right: 0,
                  left: 0,
                }}
              >
                <Puller onClick={handleDrawerOpen}/>
                {props.ui.openBoothList?
                <Typography  sx={{ pt: 1,pb: 1,pl: 2,pr: 2, color: 'text.secondary',  textAlign:'right'}}>
                  <CloseIcon sx = {{cursor:'pointer'}} onClick={handleDrawerClose} fontSize="small" />
                </Typography>:
                <Typography onClick={handleDrawerToggle} sx={{ p:2, color: 'text.secondary', cursor:'pointer', textAlign:'right'}}>
                </Typography>
                }
              </StyledBox>
              
              {props.main.authInfo.authenticated == 3 && sales_left_drawer_show && props.main.mainCanvas &&
              <Box
                sx={{
                  px: 2,
                  pb: 2,
                  height: '100%',
                  overflow: 'auto',
                }}
              >

                {
                  props.ui.leftPage == 'home'&&
                  <HomePanel role={props.role} exhibitorCnt={exhibitorCnt} exhibitorFeaturedCnt={exhibitorFeaturedCnt}  bookmarkCnt={props.main.bookmarkList?.length} open={props.ui.leftPage == 'home'} openDraw={props.ui.openBoothList}/>
                }
                {
                  props.ui.leftPage == 'search'&&
                  <SearchPanel role={props.role}/>
                }
                {
                  props.ui.leftPage == 'bookmarks'&&
                  <Bookmarks role={props.role} bookmarkCnt={props.main.bookmarkList?.length}/>
                }
                {
                  props.ui.leftPage == 'three'&&
                  <Booth3D role={props.role}/>
                }
                {
                  props.ui.leftPage == 'features'&&
                  <Features role={props.role}/>
                }
                {
                  props.ui.leftPage == 'all'&&
                  <All role={props.role}/>
                }            
                {
                  props.ui.leftPage == 'searched'&&
                  <Searched role={props.role}/>
                }
                {
                  props.ui.leftPage == 'booths'&&
                  <BoothList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'inventory'&&
                  <BoothInventory role={props.role}/>
                }
                {
                  props.ui.leftPage == 'tables'&&
                  <TableList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'deletedbooths'&&
                  <BoothDeletedList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'log'&&
                  <BoothLogList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'categorylist'&&
                  <CategoryList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'category'&&
                  <Category role={props.role}/>
                }
                {
                  props.ui.leftPage == 'cart'&&
                  <CartList role={props.role} open = {props.ui.leftPage == 'cart'}/>
                }
                {
                  props.ui.leftPage == 'sponsor_cart'&&
                  <SponsorCartList role={props.role} open = {props.ui.leftPage == 'sponsor_cart'}/>
                }
                {
                  props.ui.leftPage == 'boothgroup'&&
                  <BoothGroupList role={props.role} open = {props.ui.leftPage == 'boothgroup'}/>
                }     
              </Box>  
              }
            </SwipeableDrawer>:
            <Drawer
              props={props}
              sx={{
                borderRight: 'none',
                boxShadow:'0 1px 2px rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15)',
                background: props.ui?.customStyles?.['left-bg-color']?props.ui?.customStyles['left-bg-color']:'inherit',
              }}
              variant={anchor=='left'?"permanent":''}
              anchor={anchor}
              open={props.ui.openBoothList}
              onClose={handleDrawerClose}
              className='xhbt-MuiDrawer-root'
            >
              
              {props.main.authInfo.authenticated == 3 && sales_left_drawer_show && props.main.mainCanvas && 
              <Box display="flex">
                <Box className="left-menubar">
                  <List className='xhbt-MuiList-root'>
                    <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        onClick={handleDrawerToggle}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={classes.navItem}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          {props.ui.openBoothList?
                          <CloseIcon style={{display:'block', margin:'auto', fontSize: 26}}/>:
                          <MenuIcon style={{display:'block', margin:'auto', fontSize: 26}}/>
                          }
                        </ListItemIcon>

                        
                        <ListItemText className={classes.navItem}  primary={'Home'} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
                      </ListItemButton>
                    </ListItem>
                    {/* <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        onClick = {()=>{
                          if(!props.openDraw){                
                            if(props.role!='admin' && props.ui.leftPage && props.ui.leftPage== 'home'){
                              props.setOpenBoothList(true)
                            }
                          }
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={classes.navItem}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          <SearchIcon style={{display:'block', margin:'auto', fontSize: 26}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.navItem}  primary={'Home'} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
                      </ListItemButton>
                    </ListItem> */}
                    {props.main.active_booth_file?.loaded &&
                    <>
                    {props.main.active_booth_file?.event_home && props.main.active_booth_file?.event_home!="" &&
                    <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        href={props.main.active_booth_file? `${(props.main.active_booth_file?.event_home?.includes("http")?"":"https://")+props.main.active_booth_file.event_home}`:"#"} target="_blank"
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={classes.navItem}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          <HomeIcon />
                          <div>Home</div>
                        </ListItemIcon>
                        <ListItemText className={classes.navItem}  primary={'Home'} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
                      </ListItemButton>
                    </ListItem>
                    }
                    {sales_bookmarks_show && props.main.bookmarkList?.length>0 && getTokenPlanner() &&
                    <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        onClick={()=>props.setLeftPage('bookmarks')}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={`${classes.navItem}  xhbt-button-bookmark`}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          <BookmarksOutlinedIcon />
                          <div>Bookmarks</div>
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    }
                    {props.role != 'admin' && cart_show &&
                    <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        onClick={()=>{
                          props.setLeftPage('sponsor_cart') 
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={classes.navItem}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          <ShoppingCartOutlinedIcon />
                          <div>Cart</div>
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    }
                    {props.role != 'admin' && sales_shopping_cart_show &&
                    <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        onClick={()=>{
                          const _token = getTokenPlanner();
                          if(!_token){
                            props.setOpenLogin(true)
                            return false;
                          }
                          props.setLeftPage('cart')
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={classes.navItem}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          <ShoppingCartOutlinedIcon />
                          <div>Cart</div>
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    }
                    {sales_3d_booths_show &&
                    <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        onClick={()=>props.setLeftPage('three')}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={classes.navItem}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          <ThreeDRotationOutlinedIcon />
                          <div>3D</div>
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    }
                    {sales_featured_exhibitors_show&&
                    <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        onClick={()=>props.setLeftPage('features')}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={`${classes.navItem}  xhbt-button-featured`}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          <FavoriteBorderOutlinedIcon />              
                          <div>Featured</div>
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    }
                    <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        onClick={()=>props.setLeftPage('all')}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={`${classes.navItem}   xhbt-button-list`}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          <ListAltOutlinedIcon />
                          <div>Exhibitors</div>
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    {category_show&&
                    <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
                      <ListItemButton
                        onClick={()=>props.setLeftPage('categorylist')}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 0.5,
                        }}
                      >
                        <ListItemIcon
                          className={`${classes.navItem}   xhbt-button-categories`}
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display:'block',
                            textAlign:'center',
                            width:66
                          }}
                        >
                          <CategoryOutlinedIcon />
                          <div>Categories</div>
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>   
                    }    
                    </> 
                    }
                  </List>
                </Box>
                <Box className="left-menucontent">
                {
                  props.ui.leftPage == 'home'&&
                  <HomePanel role={props.role} exhibitorCnt={exhibitorCnt} exhibitorFeaturedCnt={exhibitorFeaturedCnt}  bookmarkCnt={props.main.bookmarkList?.length} open={props.ui.leftPage == 'home'} openDraw={props.ui.openBoothList}/>
                }
                {
                  props.ui.leftPage == 'search'&&
                  <SearchPanel role={props.role}/>
                }
                {
                  props.ui.leftPage == 'bookmarks'&&
                  <Bookmarks role={props.role} bookmarkCnt={props.main.bookmarkList?.length}/>
                }
                {
                  props.ui.leftPage == 'three'&&
                  <Booth3D role={props.role}/>
                }
                {
                  props.ui.leftPage == 'features'&&
                  <Features role={props.role}/>
                }
                {
                  props.ui.leftPage == 'all'&&
                  <All role={props.role}/>
                }            
                {
                  props.ui.leftPage == 'searched'&&
                  <Searched role={props.role}/>
                }
                {
                  props.ui.leftPage == 'booths'&&
                  <BoothList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'inventory'&&
                  <BoothInventory role={props.role}/>
                }
                {
                  props.ui.leftPage == 'tables'&&
                  <TableList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'deletedbooths'&&
                  <BoothDeletedList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'log'&&
                  <BoothLogList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'categorylist'&&
                  <CategoryList role={props.role}/>
                }
                {
                  props.ui.leftPage == 'category'&&
                  <Category role={props.role}/>
                }
                {
                  props.ui.leftPage == 'cart'&&
                  <CartList role={props.role} open = {props.ui.leftPage == 'cart'}/>
                }
                {
                  props.ui.leftPage == 'sponsor_cart'&&
                  <SponsorCartList role={props.role} open = {props.ui.leftPage == 'sponsor_cart'}/>
                }
                {
                  props.ui.leftPage == 'boothgroup'&&
                  <BoothGroupList role={props.role} open = {props.ui.leftPage == 'boothgroup'}/>
                }       
                </Box>
              </Box>
              }
            </Drawer>
            }
        </Box>
        }
      </>
    }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
    setBookmardUpdated:  data=>dispatch(setBookmardUpdated(data)),
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setBookmarkList:  data=>dispatch(setBookmarkList(data)),
    setExhibitorList:  data=>dispatch(setExhibitorList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(AppLeftPanel);
