/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "../fabrictool";
import { v4 as uuidv4 } from 'uuid';

const fabric = require("fabric").fabric;

// function findTopPaddingForRoof(roofPoints) {
//   var result = 999999;
//   for (var f = 0; f < lineCounter; f++) {
//       if (roofPoints[f].y < result) {
//           result = roofPoints[f].y;
//       }
//   }
//   return Math.abs(result);
// }

// function findLeftPaddingForRoof(roofPoints) {
//   var result = 999999;
//   for (var i = 0; i < lineCounter; i++) {
//       if (roofPoints[i].x < result) {
//           result = roofPoints[i].x;
//       }
//   }
//   return Math.abs(result);
// }

class Wall extends FabricCanvasTool {
  lines = [];
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    this._width = 3;
    this._color = props.lineColor;
    this.props = props
  }

  doMouseDown(o) {
    this.isDown = !this.isDown;
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);
    if(this.isDown){
      var points = [pointer.x, pointer.y, pointer.x, pointer.y];
      for(var i = 0; i< this.lines.length; i++){
        var x1 = this.lines[i].x1
        var y1 = this.lines[i].y1
        
        var a = this.lines[i].x1 - pointer.x;
        var b = this.lines[i].y1 - pointer.y;
        var d1 = Math.sqrt( a*a + b*b );
  
        if(d1< 10){
          points = [this.lines[i].x1, this.lines[i].y1, this.lines[i].x1, this.lines[i].y1];
          break;
        }
        
        a = this.lines[i].x2 - pointer.x;
        b = this.lines[i].y2 - pointer.y;
        var d2 = Math.sqrt( a*a + b*b );
        if(d2< 10){
          points = [this.lines[i].x2, this.lines[i].y2, this.lines[i].x2, this.lines[i].y2];
          break;
        }      
      }
  
      this.line = new fabric.Line(points, {
        strokeWidth: this._width,
        fill: this._color,
        stroke: this._color,
        originX: "center",
        originY: "center",
        selectable: false,
        evented: false,
      });
      this.lines.push(this.line)
      canvas.add(this.line);
    }
    else
    {
      for(var i = 0; i< this.lines.length; i++){
        var x1 = this.lines[i].x1
        var y1 = this.lines[i].y1
        
        var a = this.lines[i].x1 - pointer.x;
        var b = this.lines[i].y1 - pointer.y;
        var d1 = Math.sqrt( a*a + b*b );

        if(d1< 10){
          this.line.set({ x2: this.lines[i].x1, y2: this.lines[i].y1 });
          canvas.renderAll();
          break;
        }
        
        a = this.lines[i].x2 - pointer.x;
        b = this.lines[i].y2 - pointer.y;
        var d2 = Math.sqrt( a*a + b*b );
        if(d2< 10){
          this.line.set({ x2: this.lines[i].x2, y2: this.lines[i].y2 });
          canvas.renderAll();
          break;
        }      
      }
    }
  }

  doMouseMove(o) {
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);
    // if (!this.isDown) 
    {
      for(var i = 0; i< (this.isDown?this.lines.length-1:this.lines.length); i++){
        var x1 = this.lines[i].x1
        var y1 = this.lines[i].y1
        
        var a = this.lines[i].x1 - pointer.x;
        var b = this.lines[i].y1 - pointer.y;
        var d1 = Math.sqrt( a*a + b*b );

        if(d1< 10){
          if(this.circlePoint){
              canvas.remove(this.circlePoint);
              console.log("remove existing")
          }
          this.circlePoint = new fabric.Circle({
            radius: 10,
            stroke: "black",
            fill:'transparent',
            left: this.lines[i].x1,
            top: this.lines[i].y1,
            selectable: false,
            originX: "center",
            originY: "center",
            hoverCursor: "auto"
          });
          canvas.add(this.circlePoint);
          console.log("add")
          canvas.renderAll();
          break;
        }
        else{
          if(this.circlePoint){
            if(this.circlePoint.left==this.lines[i].x1 && this.circlePoint.top==this.lines[i].y1){
              canvas.remove(this.circlePoint);
              console.log("remove")
              this.circlePoint = null;
              canvas.renderAll();
            }
          }
        }
        
        a = this.lines[i].x2 - pointer.x;
        b = this.lines[i].y2 - pointer.y;
        var d2 = Math.sqrt( a*a + b*b );
        if(d2< 10){
          if(this.circlePoint){
              canvas.remove(this.circlePoint);
              console.log("remove existing")

          }
          this.circlePoint = new fabric.Circle({
            radius: 10,
            stroke: "black",
            fill:'transparent',
            left: this.lines[i].x2,
            top: this.lines[i].y2,
            selectable: false,
            originX: "center",
            originY: "center",
            hoverCursor: "auto"
          });
          canvas.add(this.circlePoint);
          console.log("add")
          canvas.renderAll();
          break;
        }
        else{
          if(this.circlePoint){
            if(this.circlePoint.left==this.lines[i].x2 && this.circlePoint.top==this.lines[i].y2){
              canvas.remove(this.circlePoint);
              console.log("remove")
              this.circlePoint = null;
              canvas.renderAll();
            }
          }
        }
        
      }

    }
    // else
    if (this.isDown)
    {
      this.line.set({ x2: pointer.x, y2: pointer.y });
      this.line.setCoords()
    };
    canvas.renderAll();
  }

  doMouseUp(o) {
    // this.isDown = false;
  }

  doMouseOut(o) {
    // this.isDown = false;
  }

  endDraw(){
    let canvas = this._canvas;
    var points = [];
    if(this.lines.length >0){
      for(var i = 0; i < this.lines.length; i++){
        points.push(
          {
            x:this.lines[i].x1,
            y:this.lines[i].y1
          }
        )
        points.push(
          {
            x:this.lines[i].x2,
            y:this.lines[i].y2
          }
        )
        canvas.remove(this.lines[i])
      }
      this.lines = [];
      var polyline = new fabric.Polyline(points,{fill:'transparent', stroke:'black', strokeWidth:this._width});
      var rect = polyline.getBoundingRect();
      var wallId = "G_"+parseInt(Math.random()*(10000))
      var group = new fabric.Group().set({
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
        id: 'wall-'+wallId,
        class: 'wall',
        preserveObjectStacking: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
      });	
      canvas.add(group);
      polyline.set({
        left:-rect.width/2,
        top:-rect.height/2
      })
  
      group.add(polyline);
          
      group.set({
        borderColor: '#ff7777',
        cornerColor: 'red',
        cornerSize: 6,
        transparentCorners: false
      });
      canvas.setActiveObject(group)
      group.new_created = true;
      group.uniq_id = Date.now() + parseInt(Math.random()*10000000000) 
      group.KEY_ID = uuidv4()
      canvas.renderAll();
      this.props.setBoothHistory({boothList:this.props.main.boothList, content: (this.props.main.mainCanvas.toDatalessObject()), action:{type:'new', objects:[group]}})
    }

  }

}

export default Wall;
