import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux'

function FeaturedLabel(props) {

  return (
   <div className={`featured-label ${props.moreClass?props.moreClass:''}`} {...props}>
      FEATURED
   </div>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(FeaturedLabel);