import * as React from 'react';
import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { setMainLayers, setGridSize, setGridAngle, setShowGrid, setUnit,  setLoading, setExhibitorList } from '../../actions/main'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { SwatchesPicker , PhotoshopPicker  } from 'react-color'
import rgbHex from "rgb-hex";
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import CropModal from './CropModal'
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputBase from '@mui/material/InputBase';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import Divider from '@mui/material/Divider';
import { getTokenCookie, assetUrl } from '../../utils/Common'
import {PaperComponent} from '../../utils/Common'

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#b71c1cff',
  '&:hover': {
    backgroundColor: 'red',
  },
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function descendingComparator(a, b, orderBy) {
  var a_val = (a[orderBy])?a[orderBy]:''
  var b_val = (b[orderBy])?b[orderBy]:''
  return a_val.toString().localeCompare(b_val.toString(), 'en', { numeric: true })
  // a_val = a[orderBy]
  // b_val = b[orderBy]
  
  // if(a_val) a_val = a_val.trim()
  // if(b_val) b_val = b_val.trim()

  // var returnVal = 0
  // if (b_val < a_val) {
  //   returnVal = -1;
  // }
  // if (b_val > a_val) {
  //   returnVal = 1;
  // }
  
  // a_val = !isNaN(a_val)?a_val*1:a_val*1
  // b_val = !isNaN(b_val)?b_val*1:b_val*1
  // if (b_val < a_val) {
  //   returnVal = -1;
  // }
  // if (b_val > a_val) {
  //   returnVal = 1;
  // }
  // return returnVal;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    // if (order !== 0) {
      return order;
    // }
    // return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function SponsorModal(props) {
  const { onClose,  onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [sponsorList , setSponsorList] = React.useState([]);
  const [openAddSponsorItem, setOpenAddSponsorItem]  = React.useState(false);
  const [openEditSponsorItem, setOpenEditSponsorItem]  = React.useState(false);
  const [editSponsorItem, setEditSponsorItem] = React.useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [cropLogoPreviewData, setCropLogoPreviewData] = useState(null)
  const [openCropModal, setOpenCropModal] = useState('')  
  const [imgSrc, setImgSrc] = useState('')
  const [cropLogoData, setCropLogoData] = useState(null)
  const [keySelect, setKeySelect] = useState('title')
  const [keyword, setKeyword] = useState('')
  const [filteredItems, setFilteredItems] = useState([])
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  let query = useQuery();

  useEffect(() => {
    if(open){
      fetchSponsorList();
      fetchExhibitorList();
    }
  }, [open])

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }

  const handleCloseCropModal=()=>{
    setOpenCropModal(false)
  }
  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const confirmCropModal=(dataImg, dataUrl)=>{
    setCropLogoData(dataImg)
    setCropLogoPreviewData(dataUrl)
    setOpenCropModal(false)
  }

  const onSelectLogo = (e)=> {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        setImgSrc(reader.result.toString() || '')
        setOpenCropModal(true)
        e.target.value=null;
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const fetchExhibitorList =async ()=>{
    var url = `${ESHOW_URL()}?method=getExhibitorList&token=${getTokenCookie()}`;
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getExhibitorList&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        var result = res.data;            
        props.setExhibitorList(result)
    }).catch(error => {
    })  
}

  const fetchSponsorList = ()=>{
    
    props.setLoading(true)
    var url = `${ESHOW_URL()}?method=getSponsors&token=${getTokenCookie()}` 
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getSponsors&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            if(result.SPONSORS){
              var buffer = [];
              for(var i = 0; i< result.SPONSORS.length; i++){
                var item = result.SPONSORS[i]
                buffer.push(
                  {
                    exhibitor_key:item['EXHIBITOR_KEY']?item['EXHIBITOR_KEY']:"",
                    description:item['DESCRIPTION']?item['DESCRIPTION']:"",
                    url:item['LINK']?item['LINK']:"",
                    KEY_ID:item['KEY_ID']?item['KEY_ID']:"",
                    name:item['TITLE']?item['TITLE']:"",
                    logo:item['LOGO']&&item['LOGO']!=""?item['LOGO']:null,
                  }
                )
              }              
              setSponsorList(buffer)
              setFilteredItems(buffer)
            }
            props.setLoading(false)
        }
        else{
          props.setLoading(false)
        }
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  const handleOpenEditSponsorItem = (row)=>{
    setEditSponsorItem(row);    
    setOpenAddSponsorItem(false)
    setOpenEditSponsorItem(true)
    if(row['logo'])
      setCropLogoPreviewData(assetUrl(row['logo']))
  }

  const handleOpenAddSponsorItem = ()=>{
    // if(!openAddSponsorItem){
      setEditSponsorItem({
        name:'', description:'', logo:false
      });
    // }
    setOpenAddSponsorItem(true)
    setOpenEditSponsorItem(false)
    setCropLogoPreviewData(false)
  }
  const handleEditSponsorItemValue = (key, value)=>{
    setEditSponsorItem({...editSponsorItem, [key]:value});
  }
  const saveSponsorItem = async ()=>{
    if(openAddSponsorItem){
      var postData = {
        EXHIBITOR_KEY:editSponsorItem.exhibitor_key?editSponsorItem.exhibitor_key:"",
        DESCRIPTION:editSponsorItem.description?editSponsorItem.description:"",
        LINK:editSponsorItem.url?editSponsorItem.url:"",
        KEY_ID:"",
        TITLE:editSponsorItem.name?editSponsorItem.name:"",
        LOGO:editSponsorItem.logo?editSponsorItem.logo:"",
        ACTIVE: 1
      }
      

      if(cropLogoData) {
        var filename = "bg.png"
          // const reader = new FileReader();
        var data = cropLogoData.slice(0, cropLogoData.size);
        var filePath = `sponsor/${uuidv4()}`
        var url = `${S3_URL()}uploadfile?filePath=${filePath}`
        props.setLoading(true)
        const headers = {headers: {'Content-Type': 'multipart/form-data'}};
        const response = await axiosInstance.post(url, data, headers);
        if(response.status == 200){
          postData.LOGO = `${S3_URL()}s3/${filePath}`
        }
      }

      var url = `${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`
      var formdata = {
        query:`${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`,
        postData: [postData],
        returnResponse: true
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }
      axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
          const eshowResponse = res.data;
          if(eshowResponse.SUCCESS == true){
            setOpenSnack(true);
            setSnackMsg("Added successfully");
            fetchSponsorList();
          }
          else{
              setOpenSnack(true);
              setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to add");
              props.setLoading(false)
          }
          setOpenAddSponsorItem(false)
          setOpenEditSponsorItem(false)
          // props.setViewFile(filename);
      }).catch(error => {
          console.log("error", error);
      })
    }
    else if(openEditSponsorItem){
      var postData = {
        EXHIBITOR_KEY:editSponsorItem.exhibitor_key?editSponsorItem.exhibitor_key:"",
        DESCRIPTION:editSponsorItem.description?editSponsorItem.description:"",
        LINK:editSponsorItem.url?editSponsorItem.url:"",
        KEY_ID:editSponsorItem.KEY_ID?editSponsorItem.KEY_ID:"",
        TITLE:editSponsorItem.name?editSponsorItem.name:"",
        LOGO:editSponsorItem.logo?editSponsorItem.logo:"",
        ACTIVE: 1
      }
      
      if(cropLogoData) {
        var filename = "bg.png"
          // const reader = new FileReader();
        var data = cropLogoData.slice(0, cropLogoData.size);
        var filePath = `sponsor/${uuidv4()}`
        var url = `${S3_URL()}uploadfile?filePath=${filePath}`
        props.setLoading(true)
        const headers = {headers: {'Content-Type': 'multipart/form-data'}};
        const response = await axiosInstance.post(url, data, headers);
        if(response.status == 200){
          postData.LOGO = `${S3_URL()}s3/${filePath}`
        }
      }
      var formdata = {
        query:`${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`,
        postData: [postData],
        returnResponse: true
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }
      var url = `${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`
      axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
          const eshowResponse = res.data;
          if(eshowResponse.SUCCESS == true){
            setOpenSnack(true);
            setSnackMsg("Updated successfully");
            fetchSponsorList();
          }
          else{
              setOpenSnack(true);
              setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to update");
              props.setLoading(false)
          }
          setOpenAddSponsorItem(false)
          setOpenEditSponsorItem(false)
          // props.setViewFile(filename);
      }).catch(error => {
          console.log("error", error);
      })
    }
  }
  
  const saveSponsorItem1 = async ()=>{
    if(openAddSponsorItem){
      var postData = {
        EXHIBITOR_KEY:editSponsorItem.exhibitor_key?editSponsorItem.exhibitor_key:"",
        DESCRIPTION:editSponsorItem.description?editSponsorItem.description:"",
        LINK:editSponsorItem.url?editSponsorItem.url:"",
        KEY_ID:"",
        TITLE:editSponsorItem.name?editSponsorItem.name:"",
        LOGO:editSponsorItem.logo?editSponsorItem.logo:"",
        ACTIVE: 1
      }
      

      if(cropLogoData) {
        var filename = "bg.png"
          // const reader = new FileReader();
        var data = cropLogoData.slice(0, cropLogoData.size);
        var url = API_URL()+`uploadasset?ax-file-name=${filename}&&start=0`
        props.setLoading(true)
        const headers = {headers: {'Content-Type': 'multipart/form-data'}};
        const response = await axiosInstance.post(url, data, headers);
        if(response.status == 200){
          postData.LOGO = API_URL()+"bg_imgs/"+response.data
        }
      }

      var url = `${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`
      var formdata = {
        query:`${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`,
        postData: [postData],
        returnResponse: true
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }
      axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
          const eshowResponse = res.data;
          if(eshowResponse.SUCCESS == true){
            setOpenSnack(true);
            setSnackMsg("Added successfully");
            fetchSponsorList();
          }
          else{
              setOpenSnack(true);
              setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to add");
              props.setLoading(false)
          }
          setOpenAddSponsorItem(false)
          setOpenEditSponsorItem(false)
          // props.setViewFile(filename);
      }).catch(error => {
          console.log("error", error);
      })
    }
    else if(openEditSponsorItem){
      var postData = {
        EXHIBITOR_KEY:editSponsorItem.exhibitor_key?editSponsorItem.exhibitor_key:"",
        DESCRIPTION:editSponsorItem.description?editSponsorItem.description:"",
        LINK:editSponsorItem.url?editSponsorItem.url:"",
        KEY_ID:editSponsorItem.KEY_ID?editSponsorItem.KEY_ID:"",
        TITLE:editSponsorItem.name?editSponsorItem.name:"",
        LOGO:editSponsorItem.logo?editSponsorItem.logo:"",
        ACTIVE: 1
      }
      
      if(cropLogoData) {
        var filename = "bg.png"
          // const reader = new FileReader();
        var data = cropLogoData.slice(0, cropLogoData.size);
        var url = API_URL()+`uploadasset?ax-file-name=${filename}&&start=0`
        props.setLoading(true)
        const headers = {headers: {'Content-Type': 'multipart/form-data'}};
        const response = await axiosInstance.post(url, data, headers);
        if(response.status == 200){
          postData.LOGO = API_URL()+"bg_imgs/"+response.data
        }
      }

      var url = `${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`
      var formdata = {
        query:`${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`,
        postData: [postData],
        returnResponse: true
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }
      axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
          const eshowResponse = res.data;
          if(eshowResponse.SUCCESS == true){
            setOpenSnack(true);
            setSnackMsg("Updated successfully");
            fetchSponsorList();
          }
          else{
              setOpenSnack(true);
              setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to update");
              props.setLoading(false)
          }
          setOpenAddSponsorItem(false)
          setOpenEditSponsorItem(false)
          // props.setViewFile(filename);
      }).catch(error => {
          console.log("error", error);
      })
    }
  }
  
  const deleteSponsorItem= (item)=>{
    var postData = {
      KEY_ID:item.KEY_ID?item.KEY_ID:"",
      ACTIVE: 0
    }
    var url = `${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`
      var formdata = {
        query:`${ESHOW_URL()}?method=putSponsors&token=${getTokenCookie()}`,
        postData: [postData],
        returnResponse: true
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }
      axiosInstance.post(url, JSON.stringify([postData]), options).then(res=>{
          const eshowResponse = res.data;
          if(eshowResponse.SUCCESS == true){
            setOpenSnack(true);
            setSnackMsg("Deleted successfully");
            fetchSponsorList();
          }
          else{
              setOpenSnack(true);
              setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to delete");
              props.setLoading(false)
          }
          setOpenAddSponsorItem(false)
          setOpenEditSponsorItem(false)
          // props.setViewFile(filename);
      }).catch(error => {
          console.log("error", error);
      })
  }

  const onOk = (e)=>{
    onClose()
  }

  const renderExhibitorOptions = ()=>{
    let element = [];
    element.push(<MenuItem value={''}>&nbsp;</MenuItem>)
    let exhibitors = props.main.exhibitorList?.EXHIBITORS
    for(let i = 0; i < exhibitors?.length; i++){
      let exhibitor = exhibitors[i]
      element.push(<MenuItem value={exhibitor.EXHIBITOR_KEY}>{exhibitor.COMPANY_NAME}</MenuItem>)
    }
    return element;
  }

  const searchBox = ()=>{
    return (
      <Paper
        className='search-bar'
        component="form"
        sx={{ p: '0px 0px', display: 'flex', alignItems: 'center' }}
      >
        <FormControl variant="outlined" style={{width:'120px', border:'none'}}>
          <Select
            className='key-select'
            style={{border:'none', outline:'none'}}
            // displayEmpty
            value={keySelect?keySelect:''}
            onChange={(e)=>setKeySelect(e.target.value)}
          >
            <MenuItem value='title'>Title</MenuItem>
            <MenuItem value='description'>Description</MenuItem>
          </Select>
        </FormControl>
        
        <Divider sx={{ height: '100%', m: 0.5 }} orientation="vertical" />
        
        {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search"> */}
          <SearchIcon sx={{ p: '5px' }} />
        {/* </IconButton> */}
        <InputBase
          sx={{ flex: 1, width:300  }}
          placeholder="Search by keyword"
          inputProps={{ 'aria-label': 'Search by keyword' }}
          val={keyword}
          onChange={(e)=>setKeyword(e.target.value)}
        />
        <Button onClick={doSearch} className='search-attach' variant="contained" color="primary" sx={{ p: '10px' }} aria-label="directions">
          Search
        </Button>
      </Paper>
    )
  }
  const editForm = ()=>{
    return (
      <>
      {(openAddSponsorItem || openEditSponsorItem) && 
        <Box mt={0} >
            
            <Paper  elevation={3} style={{padding:'20px'}}>
              {openAddSponsorItem&&
              <h4 style={{marginTop:0}}>New Sponsor</h4>
              }
              {openEditSponsorItem&&
              <h4 style={{marginTop:0}}>Edit Sponsor({editSponsorItem.name})</h4>
              }
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField        
                    style={{marginTop:'7px'}}        
                    name="name"
                    label="Title"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={editSponsorItem.name?editSponsorItem.name:''}
                    onChange={(e)=>handleEditSponsorItemValue('name', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>          
                <Grid item xs={6}>
                  <TextField        
                    style={{marginTop:'7px'}}        
                    name="url"
                    label="Link"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={editSponsorItem.url?editSponsorItem.url:''}
                    onChange={(e)=>handleEditSponsorItemValue('url', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>                     
                <Grid item xs={6}>
                  <TextField        
                    style={{marginTop:'7px'}}        
                    name="description"
                    label="Description"
                    size="small"
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    value={editSponsorItem.description?editSponsorItem.description:''}
                    onChange={(e)=>handleEditSponsorItemValue('description', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Box mt={3}>
                    <FormControl variant="outlined" fullWidth style={{width:'100%'}}>
                      <InputLabel shrink={true}>Exhibitor</InputLabel>
                      <Select
                        input={<OutlinedInput notched label="Exhibitor" />}
                        value={editSponsorItem.exhibitor_key?editSponsorItem.exhibitor_key:''}
                        onChange={(e)=>handleEditSponsorItemValue('exhibitor_key', e.target.value)}
                      >
                        {renderExhibitorOptions()}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid> 
                <Grid item xs={6}>
                  <Box mb={2}><label><b>Logo</b></label></Box>  
                  <FormControl variant="outlined">
                    <input type="file" accept="image/*" onChange={onSelectLogo} />
                  </FormControl>
                      
                  {cropLogoPreviewData&&
                    <div className='preview-image'>
                      <img style={{ width: "100%" }} src={cropLogoPreviewData} alt="cropped" />
                    </div>
                  }
                </Grid>
                
                <Grid item xs={12} style={{textAlign:'right', marginTop: 10}}>
                  <Button variant="contained" onClick={saveSponsorItem} style={{marginRight: 10}}>Save</Button>                      
                  {openEditSponsorItem&&
                  <ColorButton variant="contained" onClick={()=>deleteSponsorItem(editSponsorItem)} style={{marginRight: 10}}>Delete</ColorButton>
                  }
                  <Button variant="text" onClick={()=>{setOpenAddSponsorItem(false); setOpenEditSponsorItem(false)}}>Cancel</Button>
                </Grid>                         
              </Grid>
            </Paper>
        </Box>
      }
      </>
    )
  }
  const doSearch=()=>{
    var filteredItems = sponsorList;
    if((keySelect == 'title' || keySelect == 'description') && keyword) {
      filteredItems = sponsorList.filter((item)=>{
        var str = ''
        if(keySelect == 'title')
          str = item.name
        else
          str = item.description
        return (str.toLowerCase().includes(keyword.toLowerCase()))
      })
    }
    setFilteredItems(filteredItems)
  }

  return (
    <Dialog 
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'sponsor-dialog-title'}}
      aria-labelledby="sponsor-dialog-title" 
      className='sponsor-modal'
      fullWidth={true}
      maxWidth={"md"}
      onClose={onClose} open={open}>
      <DialogTitle id='sponsor-dialog-title' style={{display:'flex', alignItems:'center'}}><AssignmentIndOutlinedIcon style={{marginRight: 10}}/>Sponsor Setup</DialogTitle>
      <DialogContent>
        <Box mt={3}>   
            <Box style={{display:'flex', justifyContent:'space-between'}}>
              {searchBox()}
              <Button variant="text" onClick={()=>{handleOpenAddSponsorItem()}}  startIcon={<AddIcon />}><b>Create New Sponsor</b></Button>
            </Box>
            {openAddSponsorItem &&
              <Box mt={2}>
                    <>
                      {editForm()}
                    </>
              </Box>
            }

            <Table className="color-table" sx={{ minWidth: 650 , marginTop:'15px'}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Logo</TableCell>
                  <TableCell
                      align={'center'}
                      sortDirection={orderBy === 'name' ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={()=>createSortHandler('name')}
                      >
                        Title
                        {orderBy === 'name' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                  </TableCell>
                  <TableCell>Link</TableCell>
                  <TableCell
                      align={'center'}
                      sortDirection={orderBy === 'description' ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === 'description'}
                        direction={orderBy === 'description' ? order : 'asc'}
                        onClick={()=>createSortHandler('description')}
                      >
                        Description
                        {orderBy === 'description' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                  </TableCell>
                  {/* <TableCell>Created At</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(filteredItems, getComparator(order, orderBy)).map((row, index)=> (
                  <>
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        {row.logo&&
                          <img style={{ width: "60px", height:'auto' }} src={assetUrl(row.logo)} alt="cropped" />
                        }
                      </TableCell>
                      <TableCell>                      
                        <a href="javascript:void(0)" onClick={()=>handleOpenEditSponsorItem(row)}>{row.name}</a>
                      </TableCell>
                      <TableCell>{row.url}</TableCell>
                      <TableCell>{row.description}</TableCell>
                    </TableRow>
                    {
                      ((openEditSponsorItem) && editSponsorItem.KEY_ID == row.KEY_ID) &&
                      <TableRow>
                        <TableCell colSpan={4}>
                            <>
                              {editForm()}
                            </>
                        </TableCell>
                      </TableRow>
                    }
                  </>
                ))}
              </TableBody>
            </Table>
            
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
      <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={snackMsg}
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                  <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
          }
      />
      <CropModal
          open={openCropModal}
          onClose={handleCloseCropModal}
          onOk={confirmCropModal}
          imgSrc={imgSrc}
      />
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)), 
        setShowGrid: value=>dispatch(setShowGrid(value)),
        setGridAngle: value=>dispatch(setGridAngle(value)),
        setGridSize: value=>dispatch(setGridSize(value)),
        setUnit : value=>dispatch(setUnit(value)),
        setLoading:  data=>dispatch(setLoading(data)),
        setExhibitorList:  data=>dispatch(setExhibitorList(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SponsorModal);